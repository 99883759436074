import React, { useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export const useElementDomRect = (ref: React.RefObject<HTMLElement>) => {
  const [domRect, setDomRect] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (ref.current != null) {
      setDomRect(ref.current.getBoundingClientRect());
    }
  }, [ref]);

  useResizeObserver(ref, (entry) => setDomRect(entry.contentRect));

  return domRect;
};
