import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { FieldInputProps, FormikContextType, useField, useFormikContext } from 'formik';
import { FieldValidity, getFieldValidity } from './FieldValidity';
import { FieldLabel } from './FieldLabel';
import { FieldHelpText } from './FieldHelpText';
import { FieldError } from './FieldError';
import { spacing16 } from '../../../styling/design/spacing';
import { usePrevious } from '../../hooks/usePrevious';
import { FieldWarning } from './FieldWarning';

export type FormFieldChildProps<TField extends unknown> = {
  field: FieldInputProps<TField>;
  fieldValidity: FieldValidity;
  formikContext: FormikContextType<TField>;
};

type FormFieldProps<TField extends unknown> = {
  className?: string;
  fieldName: string;
  label?: string;
  helpText?: string;
  warningText?: string;
  onFieldValueChange?: (value: TField) => void;
  children: (props: FormFieldChildProps<TField>) => React.ReactNode;
};

export const FormField = <TField extends unknown>({
  className,
  fieldName,
  label,
  helpText,
  warningText,
  onFieldValueChange,
  children,
}: FormFieldProps<TField>) => {
  const [field, meta] = useField(fieldName);
  const previousValue = usePrevious(field.value);

  useEffect(() => {
    if (field.value !== previousValue && onFieldValueChange != null) {
      onFieldValueChange(field.value);
    }
  }, [field.value, previousValue, onFieldValueChange]);

  const fieldValidity = getFieldValidity(meta);
  const showError = fieldValidity === 'Invalid';

  const formikContext = useFormikContext<TField>();

  return (
    <FieldContainer className={className}>
      {label != null && <FieldLabel>{label}</FieldLabel>}
      {children({ field, fieldValidity, formikContext })}
      {helpText && <FieldHelpText>{helpText}</FieldHelpText>}
      {warningText && <FieldWarning fieldName={fieldName}>{warningText}</FieldWarning>}
      {showError && <FieldError fieldName={fieldName}>{meta.error}</FieldError>}
    </FieldContainer>
  );
};

export const FieldContainer = styled.div`
  &:not(:last-of-type) {
    margin-bottom: ${spacing16};
  }
`;
