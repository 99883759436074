import React from 'react';
import { includes } from 'lodash';
import {
  FileIcon as DefaultFileIcon,
  ExcelFileIcon,
  ImageFileIcon,
  PdfFileIcon,
  PowerPointFileIcon,
  WordFileIcon,
} from '../../icons/icons';

type Props = {
  fileName: string;
};

export const FileIcon = ({ fileName }: Props) => {
  const parts = fileName.split('.');
  const fileExtension = parts[parts.length - 1];

  if (includes(imageFileExtensions, fileExtension)) {
    return <ImageFileIcon />;
  }

  if (includes(wordFileExtensions, fileExtension)) {
    return <WordFileIcon />;
  }

  if (includes(excelFileExtensions, fileExtension)) {
    return <ExcelFileIcon />;
  }

  if (includes(powerPointFileExtensions, fileExtension)) {
    return <PowerPointFileIcon />;
  }

  if (fileExtension === 'pdf') {
    return <PdfFileIcon />;
  }

  return <DefaultFileIcon />;
};

const imageFileExtensions = [
  'jpg',
  'jpeg',
  'jpe',
  'jif',
  'jfif',
  'jfi',
  'png',
  'gif',
  'webp',
  'tiff',
  'tif',
  'bmp',
  'svg',
  'svgz',
];

const wordFileExtensions = ['doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'odt'];

const excelFileExtensions = [
  'csv',
  'ods',
  'xla',
  'xlam',
  'xls',
  'xlsb',
  'xlsm',
  'xlsx',
  'xlt',
  'xltm',
  'xltx',
  'xlw',
];

const powerPointFileExtensions = [
  'odp',
  'pot',
  'potm',
  'potx',
  'ppa',
  'ppam',
  'pps',
  'ppsm',
  'ppsx',
  'ppt',
  'pptm',
  'pptx',
];
