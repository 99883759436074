import { camelCase } from 'lodash';
import { useEffect } from 'react';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { AppLink } from '../../../infrastructure/interface/components/AppLink';
import { Table, TBody, Td, Tr } from '../../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { ReportParameterName } from '../../reports/parameters/ReportParameterName';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { useDashboardFilters } from '../DashboardFiltersContext';
import queryString from 'query-string';
import { ReportId } from '../../reports/ReportId';
import { autoRunReportQueryParameterName } from '../../reports/parameters/ReportParameterFieldsContext';
import { ComponentResponse } from '../DashboardComponent';

export const privateEquityFundSummaryComponentName = 'PE Summary';

export const PrivateEquityFundSummary = () => {
  const endpointUrl = 'api/dashboards/GetPrivateEquityFundSummary';
  const getRequest = useGetJson<
    GetPrivateEquityFundSummaryQuery,
    GetPrivateEquityFundSummaryResponse
  >(endpointUrl);

  const { companyId, fromDate } = useDashboardFilters();

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <PrivateEquityFundSummaryComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetPrivateEquityFundSummaryResponse;
  showLoadingOverlay: boolean;
};

const PrivateEquityFundSummaryComponent = ({ response, showLoadingOverlay }: ComponentProps) => {
  const { translate } = useInternationalisation();
  const { companyId } = useDashboardFilters();

  const investorCommitmentReportParameters = {
    [ReportParameterName.CompanyId]: companyId,
    [autoRunReportQueryParameterName]: true,
  };

  return (
    <DashboardComponentLayout
      headerText={translate('pages.dashboard.components.privateEquityFundSummary.title')}
      showLoadingOverlay={showLoadingOverlay}
      showNoDataMessage={response.noDataMessage}
    >
      <Table compact={true}>
        <TBody>
          {response.summaryRows.map((row) => (
            <Tr key={row.fieldName}>
              <Td>
                {fieldNamesWithTranslations.includes(row.fieldName)
                  ? translate(
                      `pages.dashboard.components.privateEquityFundSummary.fieldNames.${camelCase(
                        row.fieldName
                      )}`
                    )
                  : row.fieldName}
              </Td>
              <Td>
                {row.fieldName === 'Commitment' ? (
                  <AppLink
                    to={`/reports/${ReportId.InvestorCommitment}?${queryString.stringify(
                      investorCommitmentReportParameters
                    )}`}
                  >
                    {row.value}
                  </AppLink>
                ) : (
                  row.value
                )}
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </DashboardComponentLayout>
  );
};

const fieldNamesWithTranslations = [
  'Inception Date',
  'Vintage Year',
  'Strategy',
  'Region',
  'Investments',
  'Investors',
  'Fund Currency',
  'Commitment',
  'Paid In Capital',
  'Recallable Distributions',
  'Unfunded Commitment',
  'Paid In Capital Other',
  'Total Distributions',
  'Fund Valuation',
  'Total Value',
  'Gross IRR',
  'Net IRR',
  'Fund Multiple',
];

type GetPrivateEquityFundSummaryQuery = {
  companyId: number;
  runDate: IsoDatestamp;
};

export type GetPrivateEquityFundSummaryResponse = ComponentResponse & {
  summaryRows: Array<SummaryRow>;
};

type SummaryRow = {
  fieldName: string;
  value: string | null;
};
