import React from 'react';
import {
  GetShareholderDropdownOptionsResponse,
  mapShareholderDropdownOptionsResponseToSelectOptions,
} from './GetShareholderDropdownOptionsResponse';
import { ShareholderSelectSettings } from './ShareholderSelectSettings';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  cannotMapSettingsToValidQueryParameters,
  MetadataSingleSelect,
} from '../MetadataSingleSelect';

export type ShareholderSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  ShareholderSelectOptionValue,
  ShareholderSelectSettings,
  GetShareholderDropdownOptionsResponse
>;

export const ShareholderSelect = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('pages.reports.parameters.shareholderSelect.placeholder');
  const loadingPlaceholder = translate('form.defaultLoadingPlaceholder');

  return (
    <MetadataSingleSelect<
      ShareholderSelectOptionValue,
      ShareholderSelectSettings,
      GetShareholderDropdownOptionsRequest,
      GetShareholderDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetShareholderDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={({ companyId, companyReportGroupId, ...otherSettings }) =>
        companyId != null
          ? {
              companyId,
              companyReportGroupId: null,
              ...otherSettings,
            }
          : companyReportGroupId != null
          ? {
              companyId: null,
              companyReportGroupId,
              ...otherSettings,
            }
          : cannotMapSettingsToValidQueryParameters
      }
      mapResponseToOptions={mapShareholderDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={250}
      {...metadataSingleSelectProps}
    />
  );
};

export type GetShareholderDropdownOptionsRequest =
  | {
      companyId: number;
      companyReportGroupId: null;
      employeeCounterpartId: number | null;
    }
  | {
      companyId: null;
      companyReportGroupId: number;
      employeeCounterpartId: number | null;
    };
