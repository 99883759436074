import React, { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { CentredPaddedPage } from '../../styling/layout/PaddedPage';
import styled, { css } from 'styled-components/macro';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { spacing16, spacing32, spacing8 } from '../../styling/design/spacing';
import { Checkbox } from '../../infrastructure/interface/forms/Checkbox';
import { WaitingButton } from '../../infrastructure/interface/buttons/WaitingButton';
import { usePostJson } from '../../infrastructure/api/usePostJson';
import { fontWeightBold } from '../../styling/design/fonts';
import { borderColours } from '../../styling/design/colours';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { includes } from 'lodash';
import { onDesktop, onMobile } from '../../styling/layout/screenBreakpoints';
import { BaseButton } from '../../infrastructure/interface/buttons/BaseButton';
import { Logo } from '../../styling/branding/Logo';
import { LanguageSelect } from '../../internationalisation/LanguageSelect';
import { ErrorSplashScreen, LoadingSplashScreen } from '../SplashScreen';
import { TermsAndConditionsHtml } from './TermsAndConditionsHtml';
import { useGetTermsAndConditionsRequest } from './useGetTermsAndConditionsRequest';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { Modal } from '../../infrastructure/interface/components/Modal';
import { ContactUs } from '../contactUs/ContactUs';
import { linkStyles } from '../../infrastructure/interface/components/ExternalLink';

type Props = {
  children?: React.ReactNode;
};

export const AcceptTermsAndConditions = ({ children }: Props) => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.termsAndConditions.title'));

  const authenticationContext = useContext(AuthenticationContext);
  const user = authenticationContext.getUser();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(
    user.hasAcceptedTermsAndConditions
  );

  const getTermsAndConditionsRequest = useGetTermsAndConditionsRequest();
  const getTermsResponse = getTermsAndConditionsRequest.state.response;
  const getTermsError = getTermsAndConditionsRequest.state.error;
  const getTermsInProgress = getTermsAndConditionsRequest.state.inProgress;

  const acceptTermsAndConditionsRequest = usePostJson<{}, {}>(
    '/api/termsAndConditions/AcceptTermsAndConditions'
  );
  const acceptTermsError = acceptTermsAndConditionsRequest.state.error;
  const acceptTermsInProgress = acceptTermsAndConditionsRequest.state.inProgress;

  const shouldShowTermsAndConditions =
    !hasAcceptedTermsAndConditions && includes(allNonAdminUserRoles, user.role);

  useEffect(() => {
    if (shouldShowTermsAndConditions) {
      getTermsAndConditionsRequest.makeRequest();
    }
  }, [shouldShowTermsAndConditions]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!shouldShowTermsAndConditions) {
    return <>{children}</>;
  }

  if (getTermsError) {
    return <ErrorSplashScreen error={getTermsError} translate={translate} />;
  }

  if (getTermsResponse == null || getTermsInProgress) {
    return <LoadingSplashScreen translate={translate} />;
  }

  const acceptTermsAndConditions = () => {
    // "If I am impersonating a user that hasn't yet accepted the T&Cs, I will be
    //  able to dismiss the T&Cs message so that I can view the rest of the site,
    //  but this will not update the database to mark the terms as accepted."
    if (user.isImpersonated) {
      setHasAcceptedTermsAndConditions(true);
    } else {
      acceptTermsAndConditionsRequest.makeRequest({
        requestBody: {},
        onSuccess: () => setHasAcceptedTermsAndConditions(true),
      });
    }
  };

  return (
    <Container>
      <CentredPaddedPage>
        <Logo />
        <Header1>{translate('pages.termsAndConditions.header')}</Header1>
        <TermsAndConditionsHtml html={getTermsResponse.termsAndConditionsHtml} />
        <Question>{getTermsResponse.termsAndConditionsQuestion}</Question>
        <ActionsRow>
          <CheckboxLabel>
            <Checkbox
              checked={isCheckboxChecked}
              onChange={setIsCheckboxChecked}
              disabled={acceptTermsInProgress}
              data-testid={termsAndConditionsCheckboxTestId}
            />
            {translate('pages.termsAndConditions.acceptTermsCheckbox')}
          </CheckboxLabel>
          <WaitingButton
            onClick={acceptTermsAndConditions}
            disabled={!isCheckboxChecked}
            waitingText={translate('pages.termsAndConditions.continueButton')}
            isWaiting={acceptTermsInProgress}
            data-testid={termsAndConditionsContinueButtonTestId}
          >
            {translate('pages.termsAndConditions.continueButton')}
          </WaitingButton>
        </ActionsRow>
        {acceptTermsError != null && (
          <Alert alertType="negative" withMarginTop={true} header={translate('errors.apology')}>
            {acceptTermsError}
          </Alert>
        )}
      </CentredPaddedPage>
      <Footer>
        <FooterDivider />
        <FooterContent>
          <FooterContentLeft>
            <LanguageSelect />
            <ContactUsLink onClick={(event) => setIsContactModalOpen(true)}>
              {translate('footer.contactUs')}
            </ContactUsLink>
          </FooterContentLeft>
          <FooterContentRight>
            <div>{translate('footer.copyright')}</div>
          </FooterContentRight>
        </FooterContent>
      </Footer>

      {/* contact us modal*/}
      <Modal
        isOpen={isContactModalOpen}
        onRequestClose={() => {
          setIsContactModalOpen(false);
        }}
      >
        <Header1>{translate('pages.contactUs.header')}</Header1>
        <ContactUs isInModal={true} />
      </Modal>
    </Container>
  );
};

export const termsAndConditionsCheckboxTestId = 'terms-and-conditions-checkbox';
export const termsAndConditionsContinueButtonTestId = 'terms-and-conditions-continue-button';

const Container = styled.div`
  margin: ${spacing32} 0;

  ${Header1} {
    margin-top: ${spacing16};
  }
`;

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing32};
  margin-top: ${spacing32};

  ${onMobile(css`
    flex-direction: column;

    ${BaseButton} {
      width: 100%;
    }
  `)};
`;

const Question = styled.div`
  font-weight: ${fontWeightBold};
  margin: ${spacing32} 0;
`;

const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing8};
`;

const Footer = styled.footer`
  ${onDesktop(css`
    max-width: 714px;
    margin-left: auto;
    margin-right: auto;
  `)};

  ${onMobile(css`
    padding: 0 ${spacing32};
  `)};
`;

const ContactUsLink = styled.a`
  ${linkStyles};
  cursor: pointer;
`;

const FooterDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${borderColours.default};
  margin-bottom: ${spacing32};
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${onMobile(css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${spacing16};
    padding-right: ${spacing32};
  `)};
`;

const FooterContentLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${spacing32};
  font-weight: ${fontWeightBold};
`;

const FooterContentRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
