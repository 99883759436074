import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { CompanySelectSettings } from './CompanySelectSettings';
import {
  GetCompanyDropdownOptionsResponse,
  mapCompanyDropdownOptionsResponseToSelectOptions,
} from './GetCompanyDropdownOptionsResponse';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';
import {
  clearSessionStorage,
  sessionStorageCodes,
  writeSessionStorage,
} from '../../../infrastructure/hooks/useSessionStorage';
import { useSessionCompanyId } from '../../../infrastructure/hooks/useSessionCompanyId';

export type CompanySelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  CompanySelectOptionValue,
  CompanySelectSettings,
  GetCompanyDropdownOptionsResponse
>;

export const CompanySelect = (props: Props) => {
  const { settings, onChange, ...metadataSingleSelectProps } = props;
  const { setStoredCompanyId } = useSessionCompanyId();
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.companySelect.placeholder');
  const loadingPlaceholder = translate('metadata.companySelect.loadingPlaceholder');

  const onChangeInternal = (companyId: number | null) => {
    if (settings.rememberCompanyId) setStoredCompanyId(companyId);
    onChange(companyId);
  };

  return (
    <MetadataSingleSelect<
      CompanySelectOptionValue,
      CompanySelectSettings,
      GetCompanyDropdownOptionsQuery,
      GetCompanyDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetCompanyDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapCompanyDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={350}
      onChange={onChangeInternal}
      {...metadataSingleSelectProps}
    />
  );
};

export type GetCompanyDropdownOptionsQuery = {
  includeBlank: boolean;
  includeAllFunds: boolean;
  includeAllInvestedFunds: boolean;
  isForReports: boolean;
  excludeClosed: boolean;
  documentCategoryId: number | null;
  amlCheckId: number | null;
  employeeCounterpartId: number | null;
};
