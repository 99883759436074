import {
  clearSessionStorage,
  readSessionStorageNumber,
  sessionStorageCodes,
  writeSessionStorage,
} from './useSessionStorage';

export const useSessionTimeout = () => {
  const generalTimeout: number | null = readSessionStorageNumber(sessionStorageCodes.generalTimeout);

  const setGeneralTimeout = (generalTimeout: number | null | undefined): void => {
    if (generalTimeout != null && generalTimeout > 0) {
      writeSessionStorage(sessionStorageCodes.generalTimeout, generalTimeout.toString());
    } else {
      clearSessionStorage(sessionStorageCodes.generalTimeout);
    }
  };

  return { generalTimeout, setGeneralTimeout};
};
