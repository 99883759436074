import { BankingProductCode } from '../../../metadata/bankingDetails/BankingProduct';
import { useGetJson } from '../../../../infrastructure/api/useGetJson';

export const useCheckIfBankingDetailsAreDuplicatedRequest = () =>
  useGetJson<CheckIfBankingDetailsAreDuplicatedQuery, CheckIfBankingDetailsAreDuplicatedResponse>(
    '/api/banking-details/CheckIfBankingDetailsAreDuplicated'
  );

export type CheckIfBankingDetailsAreDuplicatedQuery = {
  productCode: BankingProductCode;
  companyId: number;
  currencyId: number;
  investorId: number | null;
};

export type CheckIfBankingDetailsAreDuplicatedResponse = {
  isDuplicate: boolean;
};
