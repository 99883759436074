import React, { useCallback, useState } from 'react';
import { useWindowTitle } from '../../../../../infrastructure/hooks/useWindowTitle';
import { useInternationalisation } from '../../../../../internationalisation/hooks/useInternationalisation';
import { useOnMount } from '../../../../../infrastructure/hooks/useOnMount';
import { RequiresUserRole } from '../../../../authentication/UserRoles';
import { investorUserRoles } from '../../../../authentication/UserRole';
import { Alert } from '../../../../../infrastructure/interface/components/Alert';
import { ApiRequestStateWrapper } from '../../../../../infrastructure/api/ApiRequestStateWrapper';
import { Header1 } from '../../../../../infrastructure/interface/components/Headers';
import styled from 'styled-components/macro';
import { BackButton } from '../../../../../infrastructure/interface/components/BackButton';
import { spacing32 } from '../../../../../styling/design/spacing';
import { useInvestorIdUrlParam } from '../../../useInvestorIdUrlParam';
import { CreateBankingDetailsForm } from './CreateBankingDetailsForm';
import { useGetJson } from '../../../../../infrastructure/api/useGetJson';
import {
  BankingDetailsActionStatusCode,
  bankingDetailsActionStatusesByCode,
} from '../../BankingDetailsActionStatus';
import { GetCreateBankingDetailsDefaultsResponse } from './GetCreateBankingDetailsDefaultsResponse';
import { CentredPaddedPage } from '../../../../../styling/layout/PaddedPage';
import { useLocalClearingDetails } from '../useLocalClearingDetails';
import { ShowLocalClearingDetailsToggleSwitch } from '../ShowLocalClearingDetailsToggleSwitch';

export const CreateBankingDetails = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.bankingDetails.create.title'));

  return (
    <CentredPaddedPage data-testid={createBankingDetailsPageTestId}>
      <RequiresUserRole userRole={investorUserRoles}>
        <CreateBankingDetailsComponent />
      </RequiresUserRole>
    </CentredPaddedPage>
  );
};

export const createBankingDetailsPageTestId = 'create-banking-details-page';

const CreateBankingDetailsComponent = () => {
  const { translate } = useInternationalisation();

  const investorId = useInvestorIdUrlParam();
  const isOwnBankingDetails = investorId == null;

  const {
    localClearingDetailsOnly,
    setLocalClearingDetailsOnly,
    showLocalClearingDetailsToggle,
    updateLocalClearingSettingsFromDefaultCode,
  } = useLocalClearingDetails();

  const [formLoadError, setFormLoadError] = useState<string | null>(null);

  const [successStatusCode, setSuccessStatusCode] = useState<BankingDetailsActionStatusCode | null>(
    null
  );

  const getFormDefaultsRequest = useGetJson<
    GetCreateBankingDetailsDefaultsQuery,
    GetCreateBankingDetailsDefaultsResponse
  >('/api/banking-details/GetCreateBankingDetailsDefaults');

  const makeGetFormDefaultsRequest = useCallback(() => {
    return getFormDefaultsRequest.makeRequest({
      queryParameters: { investorId },
      onSuccess: (response) =>
        updateLocalClearingSettingsFromDefaultCode(response.localBankDetailsDefaultCode),
    });
  }, [investorId]); // eslint-disable-line react-hooks/exhaustive-deps

  useOnMount(() => {
    makeGetFormDefaultsRequest();
  });

  return (
    <ApiRequestStateWrapper
      apiRequestState={getFormDefaultsRequest.state}
      retry={makeGetFormDefaultsRequest}
    >
      {(defaultsResponse: GetCreateBankingDetailsDefaultsResponse) => (
        <>
          <Header1>{`${translate('pages.bankingDetails.create.header')}${
            isOwnBankingDetails ? '' : `: ${defaultsResponse.investorName}`
          }`}</Header1>
          <HeaderActionsContainer>
            <BackButton />
            {successStatusCode == null && showLocalClearingDetailsToggle && (
              <ShowLocalClearingDetailsToggleSwitch
                checked={localClearingDetailsOnly}
                onChange={setLocalClearingDetailsOnly}
              />
            )}
          </HeaderActionsContainer>
          {formLoadError != null ? (
            <Alert alertType="negative" header={translate('errors.apology')}>
              {formLoadError}
            </Alert>
          ) : successStatusCode != null ? (
            <Alert
              alertType="positive"
              header={translate('pages.bankingDetails.create.successMessage.header')}
            >
              {successStatusCode === bankingDetailsActionStatusesByCode.Authorised
                ? translate('pages.bankingDetails.create.successMessage.authorised')
                : translate('pages.bankingDetails.create.successMessage.pending')}
            </Alert>
          ) : (
            <CreateBankingDetailsForm
              formDefaults={defaultsResponse}
              localClearingDetailsOnly={localClearingDetailsOnly}
              onFormLoadError={setFormLoadError}
              onSuccess={setSuccessStatusCode}
            />
          )}
        </>
      )}
    </ApiRequestStateWrapper>
  );
};

const HeaderActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing32};
`;

type GetCreateBankingDetailsDefaultsQuery = {
  investorId: number | null;
};
