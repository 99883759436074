export const reverseRecord = <T extends PropertyKey, U extends PropertyKey>(
  input: Record<T, U>
) => {
  return Object.fromEntries(Object.entries(input).map(([key, value]) => [value, key])) as Record<
    U,
    T
  >;
};

export const dictionaryToArray = <ObjectType>(dictionary: any): Array<ObjectType> =>
  Object.keys(dictionary).map((key) => (dictionary as any)[key] as ObjectType);
