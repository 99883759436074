export type SortDirection = 'up' | 'down';

export const toggleSort = (column: string,
                           sort: string | null,
                           dir: SortDirection | null,
                           setSort: (sort: string) => void,
                           setDir: (dir: SortDirection) => void) => {
  if (sort === column) {
    if (dir === 'up') {
      setDir('down');
    } else {
      setDir('up');
    }
  } else {
    setSort(column);
    setDir('up');
  }
};
