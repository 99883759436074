import React, { useContext, useEffect, useState } from 'react';
import { ReportParameterResponse } from '../../Report';
import { CompanyReportGroupSelectField } from '../../../metadata/companyReportGroup/CompanyReportGroupSelectField';
import { ReportContext } from '../../ReportContext';
import {
  CompanyReportGroupDropdownOptionResponse,
  GetCompanyReportGroupDropdownOptionsResponse,
} from '../../../metadata/companyReportGroup/GetCompanyReportGroupDropdownOptionsResponse';
import { CompanyReportGroupSelectOptionValue } from '../../../metadata/companyReportGroup/CompanyReportGroupSelect';
import { defaultCompanyReportGroupSelectSettings } from '../../../metadata/companyReportGroup/CompanyReportGroupSelectSettings';
import { useReportParameterFieldsContext } from '../ReportParameterFieldsContext';

type Props = {
  companyReportGroupParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsCompanyReportGroupSelectField = ({
  companyReportGroupParameter,
  onError,
}: Props) => {
  const { setSelectedCompanyReportGroupOption } = useContext(ReportContext);
  const { setFieldLoaded } = useReportParameterFieldsContext();
  const [options, setOptions] = useState<Array<CompanyReportGroupDropdownOptionResponse>>([]);
  const [selectedValue, setSelectedValue] = useState<CompanyReportGroupSelectOptionValue | null>(
    null
  );

  const onFieldValueChange = (newValue: CompanyReportGroupSelectOptionValue) => {
    setSelectedValue(newValue);
  };

  const onLoaded = (response: GetCompanyReportGroupDropdownOptionsResponse) => {
    setOptions(response.options);
    setFieldLoaded(companyReportGroupParameter.name);
  };

  useEffect(() => {
    const selectedOption = options.find((option) => option.companyReportGroupId === selectedValue);
    setSelectedCompanyReportGroupOption(selectedOption ?? null);
  }, [selectedValue, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CompanyReportGroupSelectField
      fieldName={companyReportGroupParameter.name}
      label={companyReportGroupParameter.displayText}
      settings={{ ...defaultCompanyReportGroupSelectSettings, isForReports: true }}
      onFieldValueChange={onFieldValueChange}
      onError={onError}
      onLoaded={onLoaded}
    />
  );
};
