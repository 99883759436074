import React from 'react';
import { CompanyValueDateSelectSettings } from './CompanyValueDateSelectSettings';
import { GetCompanyValueDateDropdownOptionsResponse } from './GetCompanyValueDateDropdownOptionsResponse';
import {
  CompanyValueDateSelect,
  CompanyValueDateSelectOptionValue,
} from './CompanyValueDateSelect';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  CompanyValueDateSelectOptionValue,
  CompanyValueDateSelectSettings,
  GetCompanyValueDateDropdownOptionsResponse
>;

export const CompanyValueDateSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    CompanyValueDateSelectOptionValue,
    CompanyValueDateSelectSettings,
    GetCompanyValueDateDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <CompanyValueDateSelect {...metadataSingleSelectProps} />
    )}
  />
);
