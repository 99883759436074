import React from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import { SelectOption } from './BaseSelect';

export const CustomSelectOption = <
  Option extends SelectOption<unknown>,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  children,
  innerProps,
  ...props
}: OptionProps<Option, IsMulti, Group>) => {
  let modifiedInnerProps = {
    ...innerProps,
    'aria-label': props.data.ariaLabel,
  };

  return (
    <components.Option innerProps={modifiedInnerProps} {...props}>
      {children}
    </components.Option>
  );
};
