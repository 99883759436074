import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { ChevronDownIcon } from '../../../icons/icons';
import { fontSizeCss, fontWeightBold } from '../../../styling/design/fonts';
import { spacing16 } from '../../../styling/design/spacing';

type ExpandableProps = {
  isExpanded: boolean;
  header: React.ReactNode;
  children: React.ReactNode;
};

export const Expandable = ({ isExpanded, header, children }: ExpandableProps) => {
  const [contentHeight, setContentHeight] = useState<number | 'auto'>(isExpanded ? 'auto' : 0);
  const contentContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (contentContainerRef.current !== null) {
      const contentHasAlreadyExpanded = contentHeight === 'auto';
      if (isExpanded && !contentHasAlreadyExpanded) {
        // When expanding, we first set the content height to its scroll height in px, then
        // after the animation is finished we set it to auto, so it can dynamically adjust its size
        // to fit its content while expanded. (css cannot animate a transition to auto from 0).
        setContentHeight(contentContainerRef.current.scrollHeight);
      }

      const contentHasAlreadyShrunk = contentHeight === 0;
      if (!isExpanded && !contentHasAlreadyShrunk) {
        // When closing, we first set the card content height to its scroll height in px (from auto),
        // then the following effect will set it to 0 after a small delay. We need to do this as
        // css can't animate a transition from auto to 0.
        setContentHeight(contentContainerRef.current.scrollHeight);
      }
    }
  }, [isExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeoutId: number | undefined;
    const transitionHasBeenInitiated = contentHeight !== 0 && contentHeight !== 'auto';

    if (!isExpanded && transitionHasBeenInitiated) {
      // Unfortunatly we cannot change the content height synchronously here, as even though it
      // would happen on the render following setting the content height to scroll height, the DOM
      // does not update quickly enough and no animation occurs.
      timeoutId = window.setTimeout(() => setContentHeight(0), 50);
    }

    if (isExpanded && transitionHasBeenInitiated) {
      timeoutId = window.setTimeout(
        () => setContentHeight('auto'),
        expandTransitionDurationInMilliseconds
      );
    }

    return () => window.clearTimeout(timeoutId);
  }, [contentHeight, isExpanded]);

  return (
    <div>
      {header}
      <ContentContainer ref={contentContainerRef} height={contentHeight}>
        {children}
      </ContentContainer>
    </div>
  );
};

type ExpandableHeaderProps = {
  isExpanded: boolean;
  onClick: () => void;
  children: string;
  contentIsLoading: boolean;
};

export const ExpandableHeader = (props: ExpandableHeaderProps) => (
  <HeaderContainer onClick={props.onClick} contentIsLoading={props.contentIsLoading}>
    {props.children}
    <ChevronIcon $componentIsExpanded={props.isExpanded} />
  </HeaderContainer>
);

type ExpandableWithHeaderProps = {
  headerText: string;
  children: React.ReactNode;
  startExpanded?: boolean;
  contentIsLoading?: boolean;
};

// A default implementation of the Expandable component
export const ExpandableWithHeader = (props: ExpandableWithHeaderProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(props.startExpanded ?? true);

  const toggleIsExpanded = () => {
    if (!props.contentIsLoading) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Expandable
      header={
        <ExpandableHeader
          isExpanded={isExpanded}
          onClick={toggleIsExpanded}
          contentIsLoading={props.contentIsLoading ?? false}
        >
          {props.headerText}
        </ExpandableHeader>
      }
      isExpanded={isExpanded}
    >
      {props.children}
    </Expandable>
  );
};

const expandTransitionDurationInMilliseconds = 250;

const ContentContainer = styled.div<{ height: number | 'auto' }>`
  height: ${(props) => (props.height === 'auto' ? 'auto' : `${props.height}px`)};
  transition: height ${expandTransitionDurationInMilliseconds}ms linear;
  overflow: ${(props) => (props.height === 'auto' ? 'visible' : 'hidden')};
`;

const HeaderContainer = styled.div<{ contentIsLoading: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${fontWeightBold};
  ${fontSizeCss('large')};

  user-select: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.75;
  }

  ${(props) =>
    props.contentIsLoading &&
    css`
      cursor: wait;
      opacity: 0.75;
    `}
`;

export const ChevronIcon = styled(ChevronDownIcon)<{ $componentIsExpanded: boolean }>`
  ${(props) =>
    props.$componentIsExpanded &&
    css`
      transform: rotate(180deg);
    `}
  transition: transform ${expandTransitionDurationInMilliseconds}ms linear;
  height: ${spacing16};
  width: ${spacing16};
`;
