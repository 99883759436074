import React from 'react';
import { ReportParameterResponse } from '../../../Report';
import { useReportParameterFieldsContext } from '../../ReportParameterFieldsContext';
import { GeneralLedgerSubclassSelectField } from '../../../../metadata/generalLedger/subclass/GeneralLedgerSubclassSelectField';

type Props = {
  generalLedgerSubclassReportParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsGeneralLedgerSubclassSelectField = ({
  generalLedgerSubclassReportParameter,
  onError,
}: Props) => {
  const { setFieldLoaded } = useReportParameterFieldsContext();

  return (
    <GeneralLedgerSubclassSelectField
      fieldName={generalLedgerSubclassReportParameter.name}
      label={generalLedgerSubclassReportParameter.displayText}
      onError={onError}
      settings={{ includeAll: true, includeBlank: false }}
      defaultToFirstOption={true}
      onLoaded={() => setFieldLoaded(generalLedgerSubclassReportParameter.name)}
    />
  );
};
