import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import React, { useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import {
  Modal,
  ModalButtonRow,
  ModalHeader,
} from '../../../infrastructure/interface/components/Modal';
import {
  AuthenticationResult,
  isMultiFactorAuthenticationRequired,
  isMultiFactorAuthenticationSetupRequired,
} from '../AuthenticationResult';
import { SetUpMultiFactorAuthentication } from './SetUpMultiFactorAuthentication';
import { MultiFactorAuthenticationPrompt } from './MultiFactorAuthenticationPrompt';
import { Form } from '../../../infrastructure/forms/common/Form';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { GhostButton } from '../../../infrastructure/interface/buttons/GhostButton';
import { SubmitButton } from '../../../infrastructure/forms/common/SubmitButton';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { Validator } from 'fluentvalidation-ts';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { notBeEmpty } from '../../validation/stringValidation';
import { ApiRequestState } from '../../../infrastructure/api/ApiRequestState';
import { MinimalCountdownButton } from '../../../infrastructure/interface/buttons/MinimalCountdownButton';
import { useInitiateMfaFlowForLoggedInUserRequest } from './useInitiateMfaFlowForLoggedInUserRequest';

type MultiFactorAuthenticationModalProps<TMfaRequiredActionResponse> = {
  mfaAuthenticationResult: AuthenticationResult | null;
  setMfaAuthenticationResult: (result: AuthenticationResult | null) => void;
  onSubmit: (formModel: MfaModalFormModel, formikHelpers: FormikHelpers<MfaModalFormModel>) => void;
  onCancel: () => void;
  mfaRequiredActionRequestState: ApiRequestState<TMfaRequiredActionResponse>;
  'data-testid'?: string;
};

export const MultiFactorAuthenticationModal = <TMfaRequiredActionResponse extends unknown>(
  props: MultiFactorAuthenticationModalProps<TMfaRequiredActionResponse>
) => {
  const {
    mfaAuthenticationResult,
    setMfaAuthenticationResult,
    onCancel,
    onSubmit,
    mfaRequiredActionRequestState,
  } = props;

  const { translate } = useInternationalisation();

  const mfaFormValidator = useMemo(() => new MfaFormValidator(translate), [translate]);

  const resendOtpRequestForLoggedInUser = useInitiateMfaFlowForLoggedInUserRequest();

  const resendOtpRequest = () => {
    resendOtpRequestForLoggedInUser.makeRequest({requestBody: {}})
  };


  return (
    <Modal
      isOpen={isMultiFactorAuthenticationRequired(mfaAuthenticationResult)}
      onRequestClose={onCancel}
    >
      {mfaAuthenticationResult == null ? null : isMultiFactorAuthenticationSetupRequired(
          mfaAuthenticationResult
        ) ? (
        <SetUpMultiFactorAuthentication
          authenticationResult={mfaAuthenticationResult}
          onTwilioAuthySetupCompleted={() => setMfaAuthenticationResult('TwilioAuthyMfaRequired')}
          onGoogleAuthenticatorSetupCompleted={() =>
            setMfaAuthenticationResult('GoogleAuthenticatorMfaRequired')
          }
          cancel={onCancel}
        />
      ) : (
        <>
          <ModalHeader title={translate('authentication.mfaModal.header')} />
          <MultiFactorAuthenticationPrompt authenticationResult={mfaAuthenticationResult} />
          <Formik<MfaModalFormModel>
            onSubmit={onSubmit}
            validate={mfaFormValidator.validate}
            initialValues={{ mfaCode: '' }}
          >
            <Form data-testid={props['data-testid'] ?? mfaFormTestId}>
              <InputField
                fieldName="mfaCode"
                label={translate('authentication.mfaModal.mfaCodeLabel')}
                autoFocus={true}
              />
              <ModalButtonRow withMarginTop={true}>
                <MinimalCountdownButton
                  buttonText={translate('authentication.resendOTP')}
                  countdownInSeconds={30}
                  clickRequest={resendOtpRequest}
                ></MinimalCountdownButton>
                <GhostButton onClick={onCancel} disabled={mfaRequiredActionRequestState.inProgress}>
                  {translate('authentication.mfaModal.cancelButton')}
                </GhostButton>
                <SubmitButton submittingText={translate('authentication.mfaModal.submitButton')}>
                  {translate('authentication.mfaModal.submitButton')}
                </SubmitButton>
              </ModalButtonRow>
            </Form>
          </Formik>
          {mfaRequiredActionRequestState.error != null && (
            <Alert alertType="negative" withMarginTop={true}>
              {mfaRequiredActionRequestState.error}
            </Alert>
          )}
        </>
      )}
    </Modal>
  );
};

export const mfaFormTestId = 'mfa-form';

export type MfaModalFormModel = {
  mfaCode: string;
};

class MfaFormValidator extends Validator<MfaModalFormModel> {
  constructor(translate: TranslateFunction) {
    super();

    this.ruleFor('mfaCode').must(notBeEmpty(translate));
  }
}
