import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { colourPrimary03 } from '../../../styling/design/colours';
import { spacing8 } from '../../../styling/design/spacing';

type Props = {
  hasFinished: boolean;
};

const chunkDurationInSeconds = 0.5;

const getJitterMultiplier = (jitterPercentage: number) => {
  const minimumMultiplier = 1 - jitterPercentage / 100;
  const maximumMultiplier = 1 + jitterPercentage / 100;
  return minimumMultiplier + Math.random() * (maximumMultiplier - minimumMultiplier);
};

export const LoadingIndicator = ({ hasFinished }: Props) => {
  const [progressPercentage, setProgressPercentage] = useState(0);

  const incrementProgressBar = () => {
    setProgressPercentage(
      (previousPercentage) =>
        previousPercentage + 0.5 * getJitterMultiplier(50) * (100 - previousPercentage)
    );
  };

  const timeoutId = useRef<number>();

  const clearTimeout = () => {
    if (timeoutId.current != null) {
      window.clearTimeout(timeoutId.current);
    }
  };

  useEffect(() => {
    if (progressPercentage < 100) {
      timeoutId.current = window.setTimeout(
        incrementProgressBar,
        getJitterMultiplier(20) * chunkDurationInSeconds * 1000
      );
    }
    return () => clearTimeout();
  }, [progressPercentage]);

  useEffect(() => {
    if (hasFinished) {
      clearTimeout();
      setProgressPercentage(100);
    }
  }, [hasFinished]);

  return (
    <Container>
      <ProgressBar progressPercentage={progressPercentage} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: ${spacing8};
`;

const ProgressBar = styled.div<{ progressPercentage: number }>`
  display: block;
  height: 100%;
  width: ${(props) => props.progressPercentage}%;
  background-color: ${colourPrimary03};
  transition: width ${chunkDurationInSeconds}s ease;
`;
