import React from 'react';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../../MetadataSingleSelectField';
import {
  GeneralLedgerSubclassSelect,
  GeneralLedgerSubclassSelectOptionValue,
} from './GeneralLedgerSubclassSelect';
import { GeneralLedgerSubclassSelectSettings } from './GeneralLedgerSubclassSelectSettings';
import { GetGeneralLedgerSubclassDropdownOptionsResponse } from './GetGeneralLedgerSubclassDropdownOptionsResponse';

type Props = MetadataSingleSelectFieldComponentProps<
  GeneralLedgerSubclassSelectOptionValue,
  GeneralLedgerSubclassSelectSettings,
  GetGeneralLedgerSubclassDropdownOptionsResponse
>;

export const GeneralLedgerSubclassSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    GeneralLedgerSubclassSelectOptionValue,
    GeneralLedgerSubclassSelectSettings,
    GetGeneralLedgerSubclassDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <GeneralLedgerSubclassSelect {...metadataSingleSelectProps} />
    )}
  />
);
