import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { ClearingCodeTypeSelectOptionValue } from './ClearingCodeTypeSelect';

export type GetClearingCodeTypeDropdownOptionsResponse = {
  options: Array<ClearingCodeTypeDropdownOptionResponse>;
};

export type ClearingCodeTypeDropdownOptionResponse = {
  clearingCodeTypeId: number;
  clearingCodeType: string;
};

export const mapClearingCodeTypeDropdownOptionsResponseToSelectOptions = (
  response: GetClearingCodeTypeDropdownOptionsResponse | null
): SelectOptions<ClearingCodeTypeSelectOptionValue> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.clearingCodeTypeId,
        label: option.clearingCodeType,
      }));
