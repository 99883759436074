import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useUrlState } from '../../../infrastructure/hooks/useUrlState';
import { defaultInitialResultsPerPageOption } from '../../../infrastructure/interface/tables/PaginatedTable';
import { GetAdminDashboardListResponse } from './GetAdminDashboardListResponse';

type DashboardsStateContextValue = {
  pageNumber: number;
  setPageNumber: (value: number) => void;
  resultsPerPage: number;
  setResultsPerPage: (value: number) => void;
  error: string | null;
  requestInProgress: boolean;
  latestResponse: GetAdminDashboardListResponse | null;
  loadData: () => void;
};

const DashboardsStateContext = createContext<DashboardsStateContextValue | undefined>(undefined);

export const useDashboardsState = () => {
  const contextValue = useContext(DashboardsStateContext);

  if (!contextValue) {
    throw new Error('useDashboardsState must be used within a DashboardsStateContextProvider');
  }

  return contextValue;
};

type Props = {
  children?: React.ReactNode;
};

export const DashboardsStateContextProvider = ({ children }: Props) => {
  const { state: paginationState, useUrlStateValue } = useUrlState<PaginationState>(
    defaultPaginationState,
    {
      pageNumber: { type: 'number' },
      resultsPerPage: { type: 'number' },
    }
  );

  const [pageNumber, setPageNumber] = useUrlStateValue('pageNumber');
  const [resultsPerPage, setResultsPerPage] = useUrlStateValue('resultsPerPage');
  const [latestResponse, setLatestResponse] = useState<GetAdminDashboardListResponse | null>(null);

  const getRequest = useGetJson<PaginationState, GetAdminDashboardListResponse>(
    '/api/dashboards/GetAdminDashboardList'
  );

  const { error, inProgress: requestInProgress } = getRequest.state;

  const loadData = () =>
    getRequest.makeRequest({
      queryParameters: paginationState,
      onSuccess: (response) => setLatestResponse(response),
    });

  useEffect(() => {
    loadData();
  }, [pageNumber, resultsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue: DashboardsStateContextValue = {
    pageNumber,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    error,
    requestInProgress,
    latestResponse,
    loadData,
  };

  return (
    <DashboardsStateContext.Provider value={contextValue}>
      {children}
    </DashboardsStateContext.Provider>
  );
};

type PaginationState = {
  pageNumber: number;
  resultsPerPage: number;
};

const defaultPaginationState = {
  pageNumber: 1,
  resultsPerPage: defaultInitialResultsPerPageOption,
};
