import React from 'react';
import styled from 'styled-components/macro';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { ErrorMessage } from '../../../infrastructure/interface/components/ErrorMessage';
import { CentredSpinner } from '../../../infrastructure/interface/components/Spinner';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import { SecondaryButton } from '../../../infrastructure/interface/buttons/SecondaryButton';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { spacing16 } from '../../../styling/design/spacing';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { WaitingButton } from '../../../infrastructure/interface/buttons/WaitingButton';

type Props = {
  onCancel: () => void;
  onActivated: () => void;
};

export const ActivateTwilioAuthy = ({ onCancel, onActivated }: Props) => {
  const { translate } = useInternationalisation();

  const getDataApiRequest = useGetJson<undefined, GetDataForTwilioAuthyActivationPageResponse>(
    '/api/authentication/GetDataForTwilioAuthyActivationPage'
  );

  const activateTwilioAuthyApiRequest = usePostJson<{}, {}>(
    '/api/authentication/ActivateTwilioAuthyMultiFactorAuthentication'
  );

  useOnMount(() => {
    getDataApiRequest.makeRequest();
  });

  const { response, inProgress, error } = getDataApiRequest.state;

  if (error != null) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (inProgress || response == null) {
    return <CentredSpinner size="xlarge" />;
  }

  const onSubmit = () => {
    activateTwilioAuthyApiRequest.makeRequest({
      requestBody: {},
      onSuccess: onActivated,
    });
  };

  const activateInProgress = activateTwilioAuthyApiRequest.state.inProgress;
  const activateError = activateTwilioAuthyApiRequest.state.error;

  return (
    <>
      <Prompt>{translate('authentication.twilioAuthy.activationScreen.prompt')}</Prompt>
      <ValuesContainer>
        <FieldAndValue
          fieldLabel={translate('authentication.twilioAuthy.activationScreen.labels.emailAddress')}
          type="text"
          value={response.maskedEmailAddress}
        />
        <FieldAndValue
          fieldLabel={translate('authentication.twilioAuthy.activationScreen.labels.mobileNumber')}
          type="text"
          value={response.maskedMobileNumber}
        />
      </ValuesContainer>
      <ButtonRow withMarginTop={true} rightAligned={true}>
        <SecondaryButton onClick={onCancel} disabled={activateInProgress}>
          {translate('authentication.twilioAuthy.activationScreen.cancelButtonText')}
        </SecondaryButton>
        <WaitingButton
          onClick={onSubmit}
          isWaiting={activateInProgress}
          waitingText={translate(
            'authentication.twilioAuthy.activationScreen.activateButtonWaitingText'
          )}
        >
          {translate('authentication.twilioAuthy.activationScreen.activateButtonText')}
        </WaitingButton>
      </ButtonRow>
      {activateError != null && <ErrorMessage>{activateError}</ErrorMessage>}
    </>
  );
};

const Prompt = styled.div`
  margin-bottom: ${spacing16};
`;

const ValuesContainer = styled.div`
  padding-top: ${spacing16};
`;

export type GetDataForTwilioAuthyActivationPageResponse = {
  maskedEmailAddress: string;
  maskedMobileNumber: string;
};
