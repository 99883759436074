import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { DocumentTypeSelectOptionValue } from './DocumentTypeSelect';

export type GetDocumentTypesResponse = {
  documentTypes: Array<DocumentTypeResponse>;
};

export type DocumentTypeResponse = {
  documentTypeCheckId: number;
  documentTypeCheckCode: string;
};

export const mapGetDocumentTypeDropdownOptionsResponseToSelectOptions = (
  response: GetDocumentTypesResponse | null
): SelectOptions<DocumentTypeSelectOptionValue> =>
  response == null
    ? []
    : response.documentTypes.map((documentType) => ({
        label: documentType.documentTypeCheckCode,
        value: documentType.documentTypeCheckId,
      }));
