import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetCurrencyDropdownOptionsResponse = {
  options: Array<CurrencyDropdownOptionResponse>;
};

export type CurrencyDropdownOptionResponse = {
  currencyId: number;
  currencyCode: string;
};

export const mapCurrencyDropdownOptionsResponseToSelectOptions = (
  response: GetCurrencyDropdownOptionsResponse | null
): SelectOptions<number> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.currencyId,
        label: option.currencyCode,
      }));
