import { BankingProductCode } from '../../../metadata/bankingDetails/BankingProduct';
import { CreateEditBankingDetailsFormModel } from './CreateEditBankingDetailsFormFields';
import { assertNotNull } from '../../../../helpers/nullHelpers';
import { nullIfBlank } from '../../../../helpers/stringHelpers';

export type CreateEditBankingDetailsRequest = {
  mfaToken: string;
  localClearingDetailsOnly: boolean;
  productCode: BankingProductCode;
  companyId: number;
  currencyId: number;
  counterpartId: number | null;
  accountName: string | null;
  bankId: number | null;
  bankName: string;
  bankAccountNumber: string;
  clearingCodeTypeId: number | null;
  clearingCode: string | null;
  iban: string | null;
  otherIdentifier: string | null;
  beneficiaryAddress: string | null;
  paymentReference: string | null;
  intermediaryBankId: number | null;
  intermediaryBankName: string | null;
};
export const toCreateEditBankingDetailsRequest = (
  formModel: CreateEditBankingDetailsFormModel,
  mfaToken: string,
  counterpartId: number | null,
  localClearingDetailsOnly: boolean
): CreateEditBankingDetailsRequest => ({
  mfaToken,
  localClearingDetailsOnly,
  productCode: assertNotNull(formModel.productCode, 'formModel.productCode'),
  companyId: assertNotNull(formModel.companyId, 'formModel.companyId'),
  currencyId: assertNotNull(formModel.currencyId, 'formModel.currencyId'),
  counterpartId,
  accountName: localClearingDetailsOnly ? null : formModel.accountName,
  bankId: formModel.bankId,
  bankName: formModel.bankName,
  bankAccountNumber: formModel.bankAccountNumber,
  clearingCodeTypeId: formModel.clearingCodeTypeId,
  clearingCode: nullIfBlank(formModel.clearingCode),
  iban: localClearingDetailsOnly ? null : nullIfBlank(formModel.iban),
  otherIdentifier: nullIfBlank(formModel.otherIdentifier),
  beneficiaryAddress: localClearingDetailsOnly ? null : nullIfBlank(formModel.beneficiaryAddress),
  paymentReference: nullIfBlank(formModel.paymentReference),
  intermediaryBankId: formModel.intermediaryBankId,
  intermediaryBankName: localClearingDetailsOnly
    ? null
    : nullIfBlank(formModel.intermediaryBankName),
});
