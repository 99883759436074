import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';

export const useTraditionalHoldingFormattedData = (holding: any, fund: any): any => {
  const { formatNumber } = useInternationalisation();
  if (holding === null || fund === null) return null;

  const units = formatNumber(holding.units, { decimalPlaces: holding.shareDecimals });
  const price = formatNumber(holding.price, { decimalPlaces: holding.priceDecimals });
  const value = formatNumber(holding.value, { decimalPlaces: holding.currencyDecimals });
  const laterTransactions = formatNumber(holding.laterTransactions, {
    decimalPlaces: holding.currencyDecimals,
  });
  const total = formatNumber(holding.total, { decimalPlaces: holding.currencyDecimals });
  return { units, price, value, laterTransactions, total };
};
