import { getIsoDatestampFromUnknownFormatDateTimeString } from '../../../helpers/dateTimeHelpers';
import queryString from 'query-string';
import { TraditionalFund, TraditionalHolding } from '../types';

export const useTraditionalFundHoldingUrls = (
  holding: TraditionalHolding,
  fund: TraditionalFund
): any => {
  if (holding === null || fund === null) {
    return null;
  }

  const totalsLinkQueryParams = {
    companyId: holding.companyId,
    equityAttributionId: holding.equityAttributionId,
    investorId: fund.investorId,
  };

  const totalUrl: string = `/transactions?${queryString.stringify(totalsLinkQueryParams)}`;

  //add an end date for value links.
  const valueQueryParams = {
    ...totalsLinkQueryParams,
    endDate: getIsoDatestampFromUnknownFormatDateTimeString(fund.navDate),
  };
  const valueUrl: string = `/transactions?${queryString.stringify(valueQueryParams)}`;

  //add a start date for later transactions links (nav date +1 calendar day)
  const laterTransactionsParams = {
    ...totalsLinkQueryParams,
    startDate: getIsoDatestampFromUnknownFormatDateTimeString(fund.navDate, 1),
  };

  const laterTransactionsUrl: string = `/transactions?${queryString.stringify(
    laterTransactionsParams
  )}`;

  return { valueUrl, laterTransactionsUrl, totalUrl };
};
