export type CompanyValueDateSelectSettings = {
  companyId: number | null;
  plusOne: boolean;
  reportId: number | null;
  employeeCounterpartId: number | null;
  raiseError: boolean;
};

export const defaultCompanyValueDateSelectSettings: CompanyValueDateSelectSettings = {
  companyId: 0,
  plusOne: false,
  raiseError: false,
  reportId: null,
  employeeCounterpartId: null,
};
