import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import {
  GetAccessGroupDropdownOptionsResponse,
  mapAccessGroupDropdownOptionsResponseToSelectOptions,
} from './GetAccessGroupDropdownOptionsResponse';
import { MetadataSingleSelect } from '../MetadataSingleSelect';

export type AccessGroupSelectOptionValue = number | null;

export type AccessGroupSelectSettings = {
  includeBlank: boolean;
};

export const defaultAccessGroupSelectSettings: AccessGroupSelectSettings = {
  includeBlank: true,
};

type Props = MetadataSingleSelectComponentProps<
  AccessGroupSelectOptionValue,
  AccessGroupSelectSettings,
  GetAccessGroupDropdownOptionsResponse
>;

export const AccessGroupSelect = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.accessGroupSelect.placeholder');
  const loadingPlaceholder = translate('metadata.accessGroupSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      AccessGroupSelectOptionValue,
      AccessGroupSelectSettings,
      GetAccessGroupDropdownOptionsQuery,
      GetAccessGroupDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetAccessGroupDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={(response) =>
        mapAccessGroupDropdownOptionsResponseToSelectOptions(response, translate)
      }
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={350}
      {...metadataSingleSelectProps}
    />
  );
};

type GetAccessGroupDropdownOptionsQuery = {
  includeBlank: boolean;
};
