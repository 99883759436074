import React, { useState } from 'react';
import { ButtonSize } from '../../../../infrastructure/interface/buttons/BaseButton';
import { ActionAlert } from '../../../../infrastructure/interface/components/ActionAlert';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { AuthenticationResult } from '../../../authentication/AuthenticationResult';
import { useInitiateMfaFlowForLoggedInUserRequest } from '../../../authentication/multiFactorAuthentication/useInitiateMfaFlowForLoggedInUserRequest';
import { MinimalWaitingButton } from '../../../../infrastructure/interface/buttons/MinimalWaitingButton';
import { DeleteIcon } from '../../../../icons/icons';
import { BankingDetail } from '../GetInvestorBankingDetailsResponse';
import { ApiRequestErrorModal } from '../../../../infrastructure/api/ApiRequestErrorModal';
import { useDeleteJson } from '../../../../infrastructure/api/useDeleteJson';
import { BankingDetailsActionResponse } from '../BankingDetailsActionResponse';
import { useInvestorIdUrlParam } from '../../useInvestorIdUrlParam';
import {
  MfaModalFormModel,
  MultiFactorAuthenticationModal,
} from '../../../authentication/multiFactorAuthentication/MultiFactorAuthenticationModal';
import { FormikHelpers } from 'formik';
import { useInvestorBankingDetailsContext } from '../InvestorBankingDetailsContext';
import { useLogoutDueToLockOut } from '../../../../infrastructure/hooks/useLogoutDueToLockOut';

type Props = {
  bankingDetail: BankingDetail;
  size?: ButtonSize;
};

export const DeleteBankingDetailsButton = ({ bankingDetail, size }: Props) => {
  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();

  const { onDeleteSuccess } = useInvestorBankingDetailsContext();

  const [deletionStatus, setDeletionStatus] = useState<DeletionStatus>('NotStarted');

  const [confirmationAlertIsOpen, setConfirmationAlertIsOpen] = useState(false);
  const [mfaRequestErrorAlertIsOpen, setMfaRequestErrorAlertIsOpen] = useState(false);

  const { logoutDueToLockOut } = useLogoutDueToLockOut();

  const deleteRequest = useDeleteJson<DeleteBankingDetailsRequest, BankingDetailsActionResponse>(
    '/api/banking-details/DeleteBankingDetails'
  );

  const [mfaAuthenticationResult, setMfaAuthenticationResult] =
    useState<AuthenticationResult | null>(null);

  const onMfaFormSubmit = (
    mfaToken: string,
    mfaFormFormikHelpers: FormikHelpers<MfaModalFormModel>
  ) => {
    setDeletionStatus('DeleteRequestInProgress');

    deleteRequest.makeRequest({
      requestBody: { mfaToken, counterpartId: investorId, bankingDetailId: bankingDetail.id },
      onSuccess: (response) => {
        setDeletionStatus('Done');
        mfaFormFormikHelpers.setSubmitting(false);
        setMfaAuthenticationResult(null);
        onDeleteSuccess(response);
      },
      onFailure: (error, response) => {
        mfaFormFormikHelpers.setSubmitting(false);

        if (response.translationKey === 'errors.authentication.accountIsLockedOut') {
          //user has been locked out, so force logout
          logoutDueToLockOut(response.translationKey);
        }
      },
    });
  };

  const initiateMfaFlowRequest = useInitiateMfaFlowForLoggedInUserRequest();
  const makeInitiateMfaFlowRequest = () =>
    initiateMfaFlowRequest.makeRequest({
      requestBody: {},
      onSuccess: (initiateMfaFlowResponse) => {
        setMfaAuthenticationResult(initiateMfaFlowResponse.authenticationResult);
      },
      onFailure: () => setMfaRequestErrorAlertIsOpen(true),
    });

  const onCancelMfaRequest = () => {
    setDeletionStatus('NotStarted');
    setMfaRequestErrorAlertIsOpen(false);
    setMfaAuthenticationResult(null);
  };

  const onCancelDelete = () => {
    setDeletionStatus('NotStarted');
    setConfirmationAlertIsOpen(false);
  };

  const onConfirmDelete = () => {
    setDeletionStatus('PendingMfaKey');
    setConfirmationAlertIsOpen(false);
    setMfaAuthenticationResult(null);
    makeInitiateMfaFlowRequest();
  };

  const onDeleteButtonClick = () => {
    setDeletionStatus('PendingConfirm');
    setConfirmationAlertIsOpen(true);
  };

  return (
    <>
      <MinimalWaitingButton
        data-testid={deleteBankingDetailButtonTestId(bankingDetail)}
        waitingText={translate('actionButtons.deleting')}
        isWaiting={deletionInProgressStatuses.includes(deletionStatus)}
        size={size}
        icon={<DeleteIcon />}
        buttonStyle="negative"
        onClick={onDeleteButtonClick}
      >
        {translate('actionButtons.delete')}
      </MinimalWaitingButton>
      <ActionAlert
        confirmButtonTestId={confirmDeleteBankingDetailButtonTestId(bankingDetail)}
        alertType="warning"
        isOpen={confirmationAlertIsOpen}
        title={translate('pages.bankingDetails.delete.confirmationAlert.title')}
        message={translate('pages.bankingDetails.delete.confirmationAlert.message')}
        cancelButtonText={translate('actionButtons.cancel')}
        confirmButtonText={translate('actionButtons.delete')}
        onRequestClose={onCancelDelete}
        onInitiateAction={onConfirmDelete}
        actionInProgress={false}
        actionError={null}
      />
      <ApiRequestErrorModal
        isOpen={mfaRequestErrorAlertIsOpen}
        apiRequestState={initiateMfaFlowRequest.state}
        onRequestClose={onCancelMfaRequest}
        retry={makeInitiateMfaFlowRequest}
      />
      <MultiFactorAuthenticationModal
        data-testid={deleteBankingDetailsMfaFormTestId}
        mfaAuthenticationResult={mfaAuthenticationResult}
        setMfaAuthenticationResult={setMfaAuthenticationResult}
        onSubmit={(mfaFormModel, mfaFormFormikHelpers) =>
          onMfaFormSubmit(mfaFormModel.mfaCode, mfaFormFormikHelpers)
        }
        onCancel={onCancelMfaRequest}
        mfaRequiredActionRequestState={deleteRequest.state}
      />
    </>
  );
};

export type DeleteBankingDetailsRequest = {
  mfaToken: string;
  counterpartId: number | null;
  bankingDetailId: string;
};

type DeletionStatus =
  | 'NotStarted'
  | 'PendingConfirm'
  | 'PendingMfaKey'
  | 'DeleteRequestInProgress'
  | 'Done';

const deletionInProgressStatuses: Array<DeletionStatus> = [
  'PendingConfirm',
  'PendingMfaKey',
  'DeleteRequestInProgress',
];

export const deleteBankingDetailButtonTestId = (bankingDetail: BankingDetail) =>
  `delete-banking-detail-button-${bankingDetail.id}`;

export const confirmDeleteBankingDetailButtonTestId = (bankingDetail: BankingDetail) =>
  `confirm-delete-banking-detail-button-${bankingDetail.id}`;

export const deleteBankingDetailsMfaFormTestId = 'delete-banking-details-mfa-form';
