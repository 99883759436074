import styled from 'styled-components/macro';
import { Link, LinkProps } from 'react-router-dom';
import { spacing16, spacing8 } from '../../../styling/design/spacing';
import React from 'react';
import { linkStyles } from './ExternalLink';

export const AppLink = styled(Link)`
  ${linkStyles};

  svg {
    margin-right: ${spacing8};
    height: ${spacing16};
    width: ${spacing16};
  }
`;

type AppLinkWithIconProps = LinkProps & {
  icon: React.ReactNode;
};

export const AppLinkWithIcon = ({ icon, children, ...linkProps }: AppLinkWithIconProps) => {
  return (
    <StyledAppLink {...linkProps}>
      {icon}
      {children}
    </StyledAppLink>
  );
};

const StyledAppLink = styled(AppLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    position: relative;
    margin-right: ${spacing8};
    height: ${spacing16};
    width: ${spacing16};
  }
`;
