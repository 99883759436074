import React from 'react';
import styled from 'styled-components/macro';
import { fontWeightBold } from '../../../styling/design/fonts';
import { spacing16, spacing8 } from '../../../styling/design/spacing';
import { colourBlack, textColours } from '../../../styling/design/colours';
import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  InfoCircleSolidIcon,
  TimesCircleSolidIcon,
} from '../../../icons/icons';

type StatusType = 'positive' | 'negative' | 'warning' | 'notice' | 'pending';

const statusColours: Record<StatusType, string> = {
  positive: textColours.positive,
  negative: textColours.negative,
  warning: textColours.warning,
  notice: textColours.notice,
  pending: colourBlack,
};

const statusIcons: Record<StatusType, React.ReactNode> = {
  positive: <CheckCircleIcon />,
  negative: <TimesCircleSolidIcon />,
  warning: <ExclamationCircleIcon />,
  notice: <InfoCircleSolidIcon />,
  pending: <ClockIcon />,
};

type Props = {
  type: StatusType;
  text: string;
};

export const StatusIndicator = ({ type, text }: Props) => (
  <StatusWrapper colour={statusColours[type]}>
    {statusIcons[type]}
    {text}
  </StatusWrapper>
);

const StatusWrapper = styled.span<{ colour: string }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: ${(props) => props.colour};
  font-weight: ${fontWeightBold};

  svg {
    height: ${spacing16};
    width: ${spacing16};
    margin-right: ${spacing8};
  }
`;
