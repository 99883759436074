import styled from 'styled-components/macro';
import { MinimalButton } from '../../../infrastructure/interface/buttons/MinimalButton';
import { MailToLink } from '../../../infrastructure/interface/components/ExternalLink';
import { ExpandableWithHeader } from '../../../infrastructure/interface/components/Expandable';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import { ItemDetailsCard } from '../../../infrastructure/interface/components/ItemDetailsCard';
import { LoadingOverlay } from '../../../infrastructure/interface/components/LoadingOverlay';
import { MobilePaginationControls } from '../../../infrastructure/interface/components/MobilePaginationControls';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { spacing16 } from '../../../styling/design/spacing';
import { getAgentOrBeneficialOwnerDetailsOptions } from '../AgentOrBeneficialOwnerDetailsModal';
import { investorDetailsComponentResultsPerPageOptions } from '../InvestorDetails';
import { useInvestorDetailsContext } from '../InvestorDetailsContext';
import { useBeneficialOwners } from './BeneficialOwnersContext';
import { NoBeneficialOwnersContainer } from './DesktopBeneficialOwnersList';

export const MobileBeneficialOwnersList = () => {
  const { translate } = useInternationalisation();
  const { latestResponse, requestInProgress } = useBeneficialOwners();

  const initialRequestInProgress = latestResponse == null && requestInProgress;

  return (
    <ExpandableWithHeader
      headerText={translate('pages.investorDetailsPage.beneficialOwners.header')}
      startExpanded={false}
      contentIsLoading={initialRequestInProgress}
    >
      <ExpandableContentContainer>
        <BeneficialOwnersCards />
      </ExpandableContentContainer>
    </ExpandableWithHeader>
  );
};

const BeneficialOwnersCards = () => {
  const { translate } = useInternationalisation();
  const translateFieldName = (fieldName: string) =>
    translate(`pages.investorDetailsPage.beneficialOwners.fieldNames.${fieldName}`);

  const {
    latestResponse,
    resultsPerPage,
    setResultsPerPage,
    pageNumber,
    setPageNumber,
    requestInProgress,
  } = useBeneficialOwners();

  const { setAgentOrBeneficialOwnerDetailsModalState } = useInvestorDetailsContext();

  if (latestResponse == null) {
    return null;
  }

  if (latestResponse.totalBeneficialOwnersCount === 0) {
    return (
      <NoBeneficialOwnersContainer>
        {translate('pages.investorDetailsPage.beneficialOwners.noBeneficialOwners')}
      </NoBeneficialOwnersContainer>
    );
  }

  return (
    <>
      <LoadingOverlay showOverlay={requestInProgress}>
        {latestResponse.beneficialOwners.map((beneficialOwner, index) => (
          <ItemDetailsCard key={index}>
            <FieldAndValue
              type="text"
              fieldLabel={translateFieldName('username')}
              value={beneficialOwner.username}
            />
            <FieldAndValue
              type="custom"
              fieldLabel={translateFieldName('counterpartName')}
              value={
                <MinimalButton
                  textAlign="right"
                  onClick={() =>
                    setAgentOrBeneficialOwnerDetailsModalState({
                      isOpen: true,
                      counterpartId: beneficialOwner.counterpartId,
                      optionCode: getAgentOrBeneficialOwnerDetailsOptions.beneficialOwner,
                    })
                  }
                >
                  {beneficialOwner.counterpartName}
                </MinimalButton>
              }
            />
            <FieldAndValue
              type="custom"
              fieldLabel={translateFieldName('emailAddress')}
              value={
                beneficialOwner.emailAddress == null ? null : (
                  <MailToLink emailAddress={beneficialOwner.emailAddress} />
                )
              }
            />
          </ItemDetailsCard>
        ))}
      </LoadingOverlay>
      <MobilePaginationControls
        currentPageNumber={pageNumber}
        onChangeCurrentPageNumber={setPageNumber}
        resultsPerPage={resultsPerPage}
        onChangeResultsPerPage={setResultsPerPage}
        totalResultsCount={latestResponse.totalBeneficialOwnersCount}
        resultsPerPageOptions={investorDetailsComponentResultsPerPageOptions}
      />
    </>
  );
};

const ExpandableContentContainer = styled.div`
  padding-top: ${spacing16};
`;
