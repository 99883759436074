import React, { useCallback, useContext } from 'react';
import { ReportParameterResponse } from '../../Report';
import { FormikValues, useFormikContext } from 'formik';
import { CompanyValueDateSelectField } from '../../../metadata/companyValueDate/CompanyValueDateSelectField';
import {
  CompanyValueDateDropdownOptionsDefaultValueDatesResponse,
  convertCompanyValueDateToIsoTimestamp,
  GetCompanyValueDateDropdownOptionsResponse,
} from '../../../metadata/companyValueDate/GetCompanyValueDateDropdownOptionsResponse';
import { ReportResponse } from '../../Reports';
import { defaultCompanyValueDateSelectSettings } from '../../../metadata/companyValueDate/CompanyValueDateSelectSettings';
import { CompanyReportGroupValueDateSelectField } from '../../../metadata/companyReportGroupValueDate/CompanyReportGroupValueDateSelectField';
import { ReportParameterName } from '../ReportParameterName';
import { useReportParameterFieldsContext } from '../ReportParameterFieldsContext';
import { ReportContext } from '../../ReportContext';
import { defaultCompanyReportGroupValueDateSelectSettings } from '../../../metadata/companyReportGroupValueDate/CompanyReportGroupValueDateSelectSettings';

type Props = {
  report: ReportResponse;
  plusOne: boolean;
  valueDateParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsCompanyValueDateSelectField = <TValues extends FormikValues>({
  report,
  plusOne,
  valueDateParameter,
  onError,
}: Props) => {
  const { setFieldValue, initialValues: initialFormValues } = useFormikContext<TValues>();
  const { setFieldLoaded } = useReportParameterFieldsContext();
  const { selectedCompanyOption, selectedCompanyReportGroupOption } = useContext(ReportContext);

  const setFieldValueFromDefaults = useCallback(
    (defaultValueDates: CompanyValueDateDropdownOptionsDefaultValueDatesResponse) => {
      if (
        valueDateParameter.name === ReportParameterName.RunDate &&
        defaultValueDates.runDate != null
      ) {
        setFieldValue(
          valueDateParameter.name,
          convertCompanyValueDateToIsoTimestamp(defaultValueDates.runDate)
        );
      }

      if (
        valueDateParameter.name === ReportParameterName.PriorDate &&
        defaultValueDates.priorDate != null
      ) {
        setFieldValue(
          valueDateParameter.name,
          convertCompanyValueDateToIsoTimestamp(defaultValueDates.priorDate)
        );
      }
    },
    [valueDateParameter, setFieldValue]
  );

  const onValueDatesResponse = (response: GetCompanyValueDateDropdownOptionsResponse) => {
    if (initialFormValues[valueDateParameter.name] == null) {
      setFieldValueFromDefaults(response.defaultValueDates);
    }
    setFieldLoaded(valueDateParameter.name);
  };

  const companyId = selectedCompanyOption?.companyId ?? null;
  const companyReportGroupId = selectedCompanyReportGroupOption?.companyReportGroupId ?? null;

  return companyReportGroupId != null ? (
    <CompanyReportGroupValueDateSelectField
      fieldName={valueDateParameter.name}
      settings={{
        ...defaultCompanyReportGroupValueDateSelectSettings,
        companyReportGroupId,
        plusOne,
        reportId: report.id,
        employeeCounterpartId: null,
      }}
      label={valueDateParameter.displayText}
      onLoaded={onValueDatesResponse}
      onError={onError}
    />
  ) : (
    <CompanyValueDateSelectField
      fieldName={valueDateParameter.name}
      settings={{
        ...defaultCompanyValueDateSelectSettings,
        companyId,
        plusOne,
        raiseError: false,
        reportId: report.id,
        employeeCounterpartId: null,
      }}
      label={valueDateParameter.displayText}
      onLoaded={onValueDatesResponse}
      onError={onError}
    />
  );
};
