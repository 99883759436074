import { AxiosResponse } from 'axios';

export type SuccessResponse<TResponse> = {
  success: true;
  result: TResponse;
  error: null;
  statusCode: number;
};

export const getSuccessResponse = <TResponse>(
  response: AxiosResponse<TResponse>
): SuccessResponse<TResponse> => ({
  success: true,
  result: response.data,
  error: null,
  statusCode: response.status,
});
