import { usePostJson } from '../../infrastructure/api/usePostJson';
import React from 'react';
import { ManagerApproval } from './types';
import { ToggleSwitch } from '../../infrastructure/interface/components/ToggleSwitch';

type TransactionApproveToggleSwitchProps = {
  dealNumber: number | null;
  managerApproval: ManagerApproval;
  onApprovalToggled: () => void;
};
export const TransactionApproveToggleSwitch = ({
  dealNumber,
  managerApproval,
  onApprovalToggled,
}: TransactionApproveToggleSwitchProps) => {
  const apiRequest = usePostJson<ApproveTransactionCommand, {}>('api/transactions/approve');

  if (
    [ManagerApproval.Hide, ManagerApproval.NotApplicable].includes(managerApproval) ||
    dealNumber === null
  ) {
    return <>{ManagerApproval.NotApplicable}</>;
  }
  const approve = managerApproval === ManagerApproval.Approved;

  const toggleTransactionApproval = (value: boolean) => {
    apiRequest.makeRequest({
      requestBody: {
        dealNumber,
        approve: value,
      },
      onSuccess: () => onApprovalToggled(),
    });
  };

  return <ToggleSwitch checked={approve} onChange={toggleTransactionApproval} />;
};

type ApproveTransactionCommand = {
  dealNumber: number;
  approve: boolean;
};
