import {
  makeMultipartRequest,
  MultipartRequest,
  MultipartRequestParameters,
} from './makeMultipartRequest';
import { useApiRequest } from './useApiRequest';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';

type MakeMultipartRequestArguments<TRequest extends MultipartRequest, TResponse extends object> = {
  request: TRequest;
  onSuccess?: (response: TResponse) => void;
  onFailure?: (error: string) => void;
};

export const useMultipartApiRequest = <
  TRequest extends MultipartRequest,
  TResponse extends object = {}
>(
  endpointUrl: string
) => {
  const { translate } = useInternationalisation();

  return useApiRequest<
    MakeMultipartRequestArguments<TRequest, TResponse>,
    MultipartRequestParameters<TRequest>,
    TResponse
  >(endpointUrl, translate, makeMultipartRequest);
};
