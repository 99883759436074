import { useContext } from 'react';
import { AuthenticationContext } from '../../features/authentication/AuthenticationContext';
import { groupIdQueryParam, useGroupIdQueryParam } from './useGroupIdQueryParam';
import { siteIdQueryParameterKey, useSiteContext } from '../../features/multiTenancy/SiteContext';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

export const isAuthenticatedQueryParameterKey = 'IsAuthenticated';
export const DefaultRootGroupId: number = 1;
export const useBrandingResourceUrl = (baseUrl: string) => {
  const isAuthenticated = useContext(AuthenticationContext).isAuthenticated();
  const groupId = useGroupIdQueryParam() ?? DefaultRootGroupId;
  const siteId = useSiteContext().siteId;

  let queryParameters: Record<string, any> = {};

  // Add this key so that logging in or out will change the URL and trigger a
  // new API request. This is especially important for img tags (e.g. in Logo.tsx)
  // as they are not removed and re-added to the DOM on change, so will only
  // trigger a new request to fetch the image when the 'src' attribute changes.
  queryParameters[isAuthenticatedQueryParameterKey] = isAuthenticated;

  if (!isAuthenticated && groupId != null) {
    queryParameters[groupIdQueryParam] = groupId;
  }

  if (siteId != null) {
    queryParameters[siteIdQueryParameterKey] = siteId;
  }

  return isEmpty(queryParameters)
    ? baseUrl
    : `${baseUrl}?${queryString.stringify(queryParameters)}`;
};
