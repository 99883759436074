import { RequestFailedAlert } from '../../../../infrastructure/api/RequestFailedAlert';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { ButtonColumn } from '../../../../infrastructure/interface/buttons/ButtonColumn';
import { ButtonRow } from '../../../../infrastructure/interface/buttons/ButtonRow';
import { SecondaryButton } from '../../../../infrastructure/interface/buttons/SecondaryButton';
import { WaitingButton } from '../../../../infrastructure/interface/buttons/WaitingButton';
import { Alert } from '../../../../infrastructure/interface/components/Alert';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { DesktopOnly } from '../../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../../styling/layout/MobileOnly';

type Props = {
  investorId: number | null;
  uploadId: number;
  dealNumber: number;
  onCancelClick: () => void;
  onConfirmationSuccessful: () => void;
};

export const ConfirmTransactionActionButtons = (props: Props) => {
  const { translate } = useInternationalisation();

  const {
    makeRequest,
    state: { inProgress, error, response },
  } = usePostJson<ConfirmTransactionComand, {}>('/api/transactions/ConfirmTransaction');

  const confirmationSuccessful = response != null;

  const onConfirmClick = () =>
    makeRequest({
      requestBody: {
        investorId: props.investorId,
        uploadId: props.uploadId,
        dealNumber: props.dealNumber,
      },
      onSuccess: props.onConfirmationSuccessful,
    });

  return (
    <>
      {error && <RequestFailedAlert error={error} withMarginTop={true} />}
      {confirmationSuccessful && (
        <Alert alertType="positive" withMarginTop={true}>
          {translate('pages.confirmTransaction.successMessage', { dealNumber: props.dealNumber })}
        </Alert>
      )}
      <DesktopOnly>
        {!confirmationSuccessful && (
        <ButtonRow rightAligned={true} withMarginTop={true}>
          <SecondaryButton
            onClick={props.onCancelClick}
            disabled={inProgress || confirmationSuccessful}
          >
            {translate('actionButtons.cancel')}
          </SecondaryButton>
          <WaitingButton
            waitingText={translate('pages.confirmTransaction.submitButtonWaitingText')}
            isWaiting={inProgress}
            buttonStyle="primary"
            disabled={confirmationSuccessful}
            onClick={onConfirmClick}
          >
            {translate('pages.confirmTransaction.submitButtonText')}
          </WaitingButton>
        </ButtonRow>
          )}
      </DesktopOnly>
      <MobileOnly>
        {!confirmationSuccessful && (
        <ButtonColumn withMarginTop={true}>
          <WaitingButton
            waitingText={translate('pages.confirmTransaction.submitButtonWaitingText')}
            isWaiting={inProgress}
            buttonStyle="primary"
            stretch={true}
            onClick={onConfirmClick}
            disabled={confirmationSuccessful}
          >
            {translate('pages.confirmTransaction.submitButtonText')}
          </WaitingButton>
          <SecondaryButton
            onClick={props.onCancelClick}
            disabled={inProgress || confirmationSuccessful}
            stretch={true}
          >
            {translate('actionButtons.cancel')}
          </SecondaryButton>
        </ButtonColumn>
        )}
      </MobileOnly>
    </>
  );
};

type ConfirmTransactionComand = {
  uploadId: number;
  dealNumber: number;
  investorId: number | null;
};
