import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Th } from './Table';
import { SortDownIcon, SortIcon, SortUpIcon } from '../../../icons/icons';
import { spacing16 } from '../../../styling/design/spacing';
import { Column } from '@adobe/react-spectrum';
import { SortDirection } from './SortDirection';

type Props = {
  children?: React.ReactNode;
  sort?: string | null;
  dir?: SortDirection | null;
  onClickSortIcon: (column: string) => void;
  columnName: string;
  align?: 'left' | 'right' | 'center';
  style?: CSSProperties | undefined;
};

export const SortableTh = ({ children, dir,sort, columnName, onClickSortIcon, align, style }: Props) => (
  <Th align={align} style={style}>
    <Container>
      <div>{children}</div>
      <SortButton onClick={() => onClickSortIcon(columnName)}>
        {sort === columnName && dir === 'up' ? <SortUpIcon /> : sort === columnName && dir === 'down' ? <SortDownIcon /> : <SortIcon />}
      </SortButton>
    </Container>
  </Th>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SortButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  opacity: 1;
  transition: all 0.25s ease;

  &:hover {
    opacity: 0.5;
  }

  svg {
    height: ${spacing16};
    width: ${spacing16};
  }
`;
