import styled from 'styled-components/macro';
import { BaseButton } from './BaseButton';
import { spacing16, spacing32 } from '../../../styling/design/spacing';

export type Props = {
  withMarginTop?: boolean;
  withMarginBottom?: boolean;
};

export const ButtonColumn = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.withMarginTop ? spacing32 : 0)};
  margin-bottom: ${(props) => (props.withMarginBottom ? spacing32 : 0)};

  ${BaseButton} {
    margin-top: 0;
    margin-bottom: 0;

    &:not(:last-of-type) {
      margin-bottom: ${spacing16};
    }
  }
`;
