import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useOnMountAfterDelay } from '../hooks/useOnMountAfterDelay';
import { fadeIn } from '../../styling/design/transitions';
import { LoadingIndicator } from '../interface/components/LoadingIndicator';
import { Spinner } from '../interface/components/Spinner';
import { colourPrimary05 } from '../../styling/design/colours';
import { spacing64 } from '../../styling/design/spacing';
import {
  ApiRequestState,
  requestFailed,
  requestIsInProgress,
  requestNotInitialised,
} from './ApiRequestState';
import { RequestFailedPage } from './RequestFailedPage';

// We have to use `interface` rather than `type` to get React to work with the generics.
interface Props<TResponse> {
  apiRequestState: ApiRequestState<TResponse>;
  children: (response: TResponse) => React.ReactElement;
  retry?: () => void;
}

type State = {
  pausePeriodHasElapsed: boolean;
  loadingIndicatorOpacity: 0 | 1;
  shouldWaitForLoadingIndicatorToFadeOut: boolean;
};

const initialState: State = {
  pausePeriodHasElapsed: false,
  loadingIndicatorOpacity: 1,
  shouldWaitForLoadingIndicatorToFadeOut: true,
};

// This component is intended to act as a common handler for API request state.
// It takes care of displaying a loading indicator, an error message, or the child
// content depending on the state of the API request. The `children` prop uses
// the `render prop` technique so that the calling code only needs to specify
// what should be rendered once the API request has returned successfully.
// See https://reactjs.org/docs/render-props.html for more on render props.
export const ApiRequestStateWrapper = <TResponse extends unknown>(props: Props<TResponse>) => {
  const requestState = props.apiRequestState;
  const response = requestState.response;

  const [state, setState] = useState<State>(initialState);

  const { pausePeriodHasElapsed, loadingIndicatorOpacity, shouldWaitForLoadingIndicatorToFadeOut } =
    state;

  // Don't render anything for a short period of time - that way we can choose to
  // only render a loading indicator if the request takes more than short period of
  // time to come back. If it comes back during the pause period we can just go
  // straight to displaying the children. This will make the UI feel quicker.
  useOnMountAfterDelay(() => {
    setState((previousState) => ({ ...previousState, pausePeriodHasElapsed: true }));
  }, pausePeriodDurationInMilliseconds);

  useEffect(() => {
    // If the response has been received, but we've passed the pause period, then
    // we need to gracefully fade out the loading indicator (which is now visible).
    if (response != null) {
      if (pausePeriodHasElapsed && shouldWaitForLoadingIndicatorToFadeOut) {
        setState((previousState) => ({
          ...previousState,
          loadingIndicatorOpacity: 0,
        }));

        const timeoutId = window.setTimeout(
          () =>
            setState((previousState) => ({
              ...previousState,
              shouldWaitForLoadingIndicatorToFadeOut: false,
            })),
          loadingIndicatorTransitionDurationInMilliseconds
        );

        return () => window.clearTimeout(timeoutId);
      } else {
        setState((previousState) => ({
          ...previousState,
          shouldWaitForLoadingIndicatorToFadeOut: false,
        }));
      }
    }
  }, [response, pausePeriodHasElapsed, shouldWaitForLoadingIndicatorToFadeOut, setState]);

  // We always show an error if there is one present.
  if (requestFailed(requestState)) {
    return <RequestFailedPage retry={props.retry} error={requestState.error} />;
  }

  if (requestNotInitialised(requestState) || !pausePeriodHasElapsed) {
    return null;
  }

  if (requestIsInProgress(requestState) || shouldWaitForLoadingIndicatorToFadeOut) {
    return (
      <FadeIn key="loading-indicator">
        <LoadingIndicatorContainer opacity={loadingIndicatorOpacity}>
          <LoadingIndicator hasFinished={requestState.response != null} />
        </LoadingIndicatorContainer>
        <SpinnerContainer>
          <Spinner size="xlarge" colour={colourPrimary05} />
        </SpinnerContainer>
      </FadeIn>
    );
  }

  return <FadeIn key="children">{props.children(requestState.response)}</FadeIn>;
};

const pausePeriodDurationInMilliseconds = 50;

const loadingIndicatorTransitionDurationInMilliseconds = 200;

const LoadingIndicatorContainer = styled.div<{ opacity: number }>`
  width: 100%;
  transition: opacity ${loadingIndicatorTransitionDurationInMilliseconds}ms ease;
  opacity: ${(props) => props.opacity};
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${spacing64} 0;
  animation: ${fadeIn} 3s ease;
`;

const FadeIn = styled.div`
  width: 100%;
  animation: ${fadeIn} 0.25s ease;
`;
