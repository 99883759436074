import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { spacing64 } from '../../styling/design/spacing';
import { useDashboardFilters } from './DashboardFiltersContext';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import { RequestFailedAlert } from '../../infrastructure/api/RequestFailedAlert';
import { Disclaimer } from '../disclaimers/Disclaimer';
import {
  GetDisclaimersForCompanyResponse,
  useGetDisclaimersForCompanyRequest,
} from '../disclaimers/useGetDisclaimersForCompanyRequest';
import { isNullUndefinedOrBlank } from '../../helpers/stringHelpers';
import { assertNotNull } from '../../helpers/nullHelpers';

export const DashboardDisclaimer = () => {
  const { companyId } = useDashboardFilters();
  const [latestResponse, setLatestResponse] = useState<GetDisclaimersForCompanyResponse | null>(
    null
  );

  const getDisclaimersRequest = useGetDisclaimersForCompanyRequest();
  const { inProgress, error } = getDisclaimersRequest.state;

  const makeRequest = () =>
    getDisclaimersRequest.makeRequest({
      queryParameters: { companyId },
      onSuccess: setLatestResponse,
    });

  useEffect(() => {
    makeRequest();
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (latestResponse == null) {
    return (
      <SpinnerContainer>
        <CentredSpinner size="large" />
      </SpinnerContainer>
    );
  }

  if (error != null) {
    return <RequestFailedAlert retry={makeRequest} error={error} />;
  }

  if (isNullUndefinedOrBlank(latestResponse.investorDashboardDisclaimer)) {
    return null;
  }

  return (
    <Disclaimer
      disclaimerTextAsHtml={assertNotNull(
        latestResponse.investorDashboardDisclaimer,
        'latestResponse.investorDashboardDisclaimer'
      )}
      inProgress={inProgress}
    />
  );
};

const SpinnerContainer = styled.div`
  height: ${spacing64};
`;
