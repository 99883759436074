import React from 'react';
import { EquityAttributionSelectForTransactionsSettings } from './EquityAttributionSelectForTransactionsSettings';
import {
  EquityAttributionSelectForTransactions,
  TransactionEquityAttributionSelectOptionValue,
} from './EquityAttributionSelectForTransactions';
import { GetTransactionEquityAttributionDropdownOptionsResponse } from './GetTransactionEquityAttributionDropdownOptionsResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  TransactionEquityAttributionSelectOptionValue,
  EquityAttributionSelectForTransactionsSettings,
  GetTransactionEquityAttributionDropdownOptionsResponse
>;

export const EquityAttributionSelectForTransactionsField = (props: Props) => {
  return (
    <MetadataSingleSelectField<
      TransactionEquityAttributionSelectOptionValue,
      EquityAttributionSelectForTransactionsSettings,
      GetTransactionEquityAttributionDropdownOptionsResponse
    >
      {...props}
      renderSelect={(metadataSingleSelectProps) => (
        <EquityAttributionSelectForTransactions {...metadataSingleSelectProps} />
      )}
    />
  );
};
