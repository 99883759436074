import React from 'react';
import styled from 'styled-components/macro';
import {
  dealingTransactionType,
  DealingTransactionTypeCode,
} from '../../../metadata/transactions/DealingTransactionType';
import { FieldAndValue } from '../../../../infrastructure/interface/components/FieldAndValue';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';

type Props = {
  transactionType: DealingTransactionTypeCode;
  shareHolding?: number | null;
  shareHoldingDecimals?: number | null;
  netAssetValue?: number | null;
  netAssetValueDecimals?: number | null;
  netAssetValueCurrencyCode?: string | null;
};

export const PlaceTransactionShareClassSeriesSubFields = ({
  transactionType,
  shareHolding,
  shareHoldingDecimals,
  netAssetValue,
  netAssetValueDecimals,
  netAssetValueCurrencyCode,
}: Props) => {
  const { translate, formatNumberOrDefault } = useInternationalisation();

  return (
    <ShareClassSeriesSubFields>
      {shouldShowShareHoldingField(transactionType) && (
        <ShareClassSeriesSubField
          data-testid={placeTransactionShareHoldingFieldTestId}
          fieldLabel={translate('pages.placeTransaction.labels.shareHolding')}
          type="custom"
          value={formatNumberOrDefault(shareHolding, { decimalPlaces: shareHoldingDecimals })}
        />
      )}
      {shouldShowNetAssetValueField(transactionType) && (
        <ShareClassSeriesSubField
          data-testid={placeTransactionNetAssetValueFieldTestId}
          fieldLabel={translate('pages.placeTransaction.labels.netAssetValue')}
          type="custom"
          value={`${netAssetValueCurrencyCode} ${formatNumberOrDefault(netAssetValue, {
            decimalPlaces: netAssetValueDecimals,
          })}`}
        />
      )}
    </ShareClassSeriesSubFields>
  );
};

const ShareClassSeriesSubFields = styled.div`
  display: flex;
  flex-direction: row;
`;

const ShareClassSeriesSubField = styled(FieldAndValue)`
  flex-grow: 1;
`;

export const shouldShowShareHoldingField = (transactionType: DealingTransactionTypeCode) => {
  const validTypes: Array<DealingTransactionTypeCode> = [
    dealingTransactionType.FullRedemption,
    dealingTransactionType.Redeem,
    dealingTransactionType.Switch,
    dealingTransactionType.SwitchFull,
  ];

  return validTypes.includes(transactionType);
};

export const shouldShowNetAssetValueField = (transactionType: DealingTransactionTypeCode) => {
  const validTypes: Array<DealingTransactionTypeCode> = [
    dealingTransactionType.FullRedemption,
    dealingTransactionType.Redeem,
    dealingTransactionType.Switch,
    dealingTransactionType.SwitchFull,
  ];

  return validTypes.includes(transactionType);
};

export const placeTransactionShareHoldingFieldTestId = 'place-transaction-share-holding-field';
export const placeTransactionNetAssetValueFieldTestId = 'place-transaction-net-asset-value-field';
