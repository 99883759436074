import React from 'react';
import {
  GetDocumentTypesResponse,
  mapGetDocumentTypeDropdownOptionsResponseToSelectOptions,
} from './GetDocumentTypesResponse';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import {
  cannotMapSettingsToValidQueryParameters,
  MetadataSingleSelect,
} from '../MetadataSingleSelect';
import { DocumentTypeSelectSettings } from './DocumentTypeSelectSettings';

export type DocumentTypeSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  DocumentTypeSelectOptionValue,
  DocumentTypeSelectSettings,
  GetDocumentTypesResponse
>;

export const DocumentTypeSelect = ({ settings, ...metadataSingleSelectProps }: Props) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.documentTypeSelect.placeholder');
  const loadingPlaceholder = translate('metadata.documentTypeSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      DocumentTypeSelectOptionValue,
      DocumentTypeSelectSettings,
      {},
      GetDocumentTypesResponse
    >
      endpointUrl="/api/documents/GetDocumentTypes"
      settings={settings}
      mapSettingsToQueryParameters={({ investorId, ...otherSettings }) =>
        investorId != null
          ? {
              investorId,
              ...otherSettings,
            }
          : investorId == null
          ? {
              investorId: null,
              ...otherSettings,
            }
          : cannotMapSettingsToValidQueryParameters
      }
      mapResponseToOptions={mapGetDocumentTypeDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={350}
      {...metadataSingleSelectProps}
    />
  );
};
