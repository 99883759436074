import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  GetCompanyValueDateDropdownOptionsResponse,
  mapGetCompanyValueDateDropdownOptionsResponseToSelectOptions,
} from './GetCompanyValueDateDropdownOptionsResponse';
import { CompanyValueDateSelectSettings } from './CompanyValueDateSelectSettings';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import {
  cannotMapSettingsToValidQueryParameters,
  MetadataSingleSelect,
} from '../MetadataSingleSelect';

export type CompanyValueDateSelectOptionValue = IsoDatestamp | null;

type Props = MetadataSingleSelectComponentProps<
  CompanyValueDateSelectOptionValue,
  CompanyValueDateSelectSettings,
  GetCompanyValueDateDropdownOptionsResponse
>;

export const CompanyValueDateSelect = ({ settings, ...metadataSingleSelectProps }: Props) => {
  const { translate, formatDate } = useInternationalisation();

  const placeholder = translate('metadata.companyValueDateSelect.placeholder');
  const loadingPlaceholder = translate('metadata.companyValueDateSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      CompanyValueDateSelectOptionValue,
      CompanyValueDateSelectSettings,
      GetCompanyValueDateDropdownOptionsRequest,
      GetCompanyValueDateDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetCompanyValueDateDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={({ companyId, ...otherSettings }) =>
        companyId == null
          ? cannotMapSettingsToValidQueryParameters
          : { companyId, ...otherSettings }
      }
      mapResponseToOptions={(response) =>
        mapGetCompanyValueDateDropdownOptionsResponseToSelectOptions(response, formatDate)
      }
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={200}
      {...metadataSingleSelectProps}
    />
  );
};

export type GetCompanyValueDateDropdownOptionsRequest = {
  companyId: number;
  plusOne: boolean;
  raiseError: boolean;
  reportId: number | null;
  employeeCounterpartId: number | null;
};
