import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useDashboardFilters } from '../DashboardFiltersContext';
import { useEffect } from 'react';
import { AumHistoryGraph } from './AumHistoryGraph';
import { TopTenPositionsChart } from './TopTenPositionsChart';
import { HideManagerApproval } from './AumHistoryTable';
export const ManagerGraphComponentName = 'Manager Graph';

export const ManagerGraph = () => {
  const endpointUrl = 'api/dashboards/GetDataForManagerGraph';
  const getRequest = useGetJson<GetMangerGraphQuery, GetManagerGraphResponse>(endpointUrl);
  const { companyId } = useDashboardFilters();
  const makeRequest = () => {
    if (companyId != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId]);

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <ManagerGraphComponent response={response} showLoadingOverlay={showLoadingOverlay} />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetManagerGraphResponse;
  showLoadingOverlay: boolean;
};

/**
 * Note: this component behaves a little differently to other components.
 * It acts as a switch between TopTenPositionsChart and AumHistoryGraph components.
 * For further detail, see JIRA PC-345: https://pacific-fund-systems.atlassian.net/browse/PC-345*
 *
 **/
const ManagerGraphComponent = ({ response }: ComponentProps) => {
  if (response.managerGraph === 'H') {
    return <TopTenPositionsChart />;
  }

  return <AumHistoryGraph />;
};

type GetMangerGraphQuery = {
  companyId: number;
};

type GetManagerGraphResponse = {
  managerGraph: string | null;
  shouldLimitWebMenu: boolean;
  hideManagerApproval: HideManagerApproval;
  shouldDisplayUsername: boolean;
  webDobDisplay: string | null;
};
