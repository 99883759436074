export type AuthenticationResult =
  | 'SignedIn'
  | 'PasswordResetRequired'
  | 'FirstTimePasswordResetRequired'
  | 'EmailToSmsMfaRequired'
  | 'TwilioAuthyRegistrationRequired'
  | 'TwilioAuthyMfaRequired'
  | 'GoogleAuthenticatorSetupRequired'
  | 'GoogleAuthenticatorMfaRequired'
  | 'CitiMfaRequired'
  | 'UsernameChangedRemovedSpaces';

export const isMultiFactorAuthenticationRequired = (
  authenticationResult: AuthenticationResult | null
) => {
  switch (authenticationResult) {
    case null:
    case 'SignedIn':
    case 'PasswordResetRequired':
    case 'UsernameChangedRemovedSpaces':
      return false;
    case 'EmailToSmsMfaRequired':
    case 'TwilioAuthyRegistrationRequired':
    case 'TwilioAuthyMfaRequired':
    case 'GoogleAuthenticatorSetupRequired':
    case 'GoogleAuthenticatorMfaRequired':
    case 'CitiMfaRequired':
      return true;
    default:
      throw new Error(`Unrecognised authentication result: ${authenticationResult}`);
  }
};

export const isMultiFactorAuthenticationSetupRequired = (
  authenticationResult: AuthenticationResult | null
) => {
  switch (authenticationResult) {
    case null:
      return false;
    case 'TwilioAuthyRegistrationRequired':
    case 'GoogleAuthenticatorSetupRequired':
      return true;
    default:
      return false;
  }
};
