import { FormikValues, useFormikContext } from 'formik';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { parse } from 'query-string';
import { parseQueryValue } from '../../../infrastructure/hooks/useUrlState';

type ReportParameterFieldsContextValue = {
  setFieldLoaded: (fieldName: string) => void;
};

const ReportParameterFieldsContext = createContext<ReportParameterFieldsContextValue | undefined>(
  undefined
);

export const useReportParameterFieldsContext = () => {
  const contextValue = useContext(ReportParameterFieldsContext);

  if (!contextValue) {
    throw new Error(
      'useReportParameterFieldsContext must be used within a ReportParameterFieldsContextProvider'
    );
  }

  return contextValue;
};

type ContextProviderProps = {
  children: React.ReactNode;
};

export const ReportParameterFieldsContextProvider = ({ children }: ContextProviderProps) => {
  const location = useLocation();
  const { initialValues, submitForm, submitCount } = useFormikContext<FormikValues>();

  const initialLoadingState: ParameterFieldsLoadingState = useMemo(
    () =>
      Object.keys(initialValues).reduce(
        (accumulator, fieldName) => ({ ...accumulator, [fieldName]: false }),
        {}
      ),
    [initialValues]
  );

  const [parameterFieldsLoadingState, setParameterFieldsLoadingState] =
    useState<ParameterFieldsLoadingState>(initialLoadingState);

  const setFieldLoaded = (fieldName: string) =>
    setParameterFieldsLoadingState((previous) => ({ ...previous, [fieldName]: true }));

  useEffect(() => {
    const allFieldsAreLoaded = Object.values(parameterFieldsLoadingState).every(
      (fieldIsLoaded) => fieldIsLoaded
    );

    const autoRunReport = parseQueryValue(
      parse(location.search)[autoRunReportQueryParameterName] as string | null,
      { type: 'boolean', isNullable: true }
    );

    if (allFieldsAreLoaded && submitCount === 0 && autoRunReport === true) {
      submitForm();
    }
  }, [parameterFieldsLoadingState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReportParameterFieldsContext.Provider value={{ setFieldLoaded }}>
      {children}
    </ReportParameterFieldsContext.Provider>
  );
};

type ParameterFieldsLoadingState = Record<string, boolean>;

export const autoRunReportQueryParameterName = 'autoRun';
