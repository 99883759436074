import React from 'react';
import { useDevice } from '../../infrastructure/hooks/useDevice';

type Props = { children?: React.ReactNode };

export const MobileOnly = ({ children }: Props) => {
  const { isMobile } = useDevice();

  if (!isMobile) {
    return null;
  }

  return <>{children}</>;
};
