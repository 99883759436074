import React from 'react';
import styled from 'styled-components/macro';
import { spacing16 } from '../../../../styling/design/spacing';
import { fontWeightMedium } from '../../../../styling/design/fonts';

type Props = {
  errorMessage: string | null | undefined;
};

export const BatchCreateErrorMessage = ({ errorMessage }: Props) => {
  return (
    <>
      <ErrorMessageContainer key={errorMessage}>
        <>{errorMessage}</>
      </ErrorMessageContainer>
    </>
  );
};

const ErrorMessageContainer = styled.div`
  font-weight: ${fontWeightMedium};
  text-align: left;

  &:not(:last-child) {
    margin-bottom: ${spacing16};
  }
`;
