import { BankingDetailsActionStatusCode } from '../../BankingDetailsActionStatus';
import { GetDataForEditBankingDetailsResponse } from './GetDataForEditBankingDetailsResponse';
import { useInvestorIdUrlParam } from '../../../useInvestorIdUrlParam';
import {
  CreateEditBankingDetailsRequest,
  toCreateEditBankingDetailsRequest,
} from '../CreateEditBankingDetailsRequest';
import { BankingDetailsActionResponse } from '../../BankingDetailsActionResponse';
import { FormikHelpers, FormikProps } from 'formik';
import { CreateEditBankingDetailsFormModel } from '../CreateEditBankingDetailsFormFields';
import { MfaModalFormModel } from '../../../../authentication/multiFactorAuthentication/MultiFactorAuthenticationModal';
import { CreateEditBankingDetailsForm } from '../CreateEditBankingDetailsForm';
import React from 'react';
import { clearingCodeValidationStateType } from '../ClearingCodeValidationState';
import { usePutJson } from '../../../../../infrastructure/api/usePutJson';
import { useLogoutDueToLockOut } from '../../../../../infrastructure/hooks/useLogoutDueToLockOut';

type Props = {
  dataForInitialValues: GetDataForEditBankingDetailsResponse;
  localClearingDetailsOnly: boolean;
  onFormLoadError: (error: string) => void;
  onSuccess: (statusCode: BankingDetailsActionStatusCode) => void;
};

export const EditBankingDetailsForm = ({
  dataForInitialValues,
  localClearingDetailsOnly,
  onFormLoadError,
  onSuccess,
}: Props) => {
  const { logoutDueToLockOut } = useLogoutDueToLockOut();

  const investorId = useInvestorIdUrlParam();

  const editBankingDetailsRequest = usePutJson<
    CreateEditBankingDetailsRequest,
    BankingDetailsActionResponse
  >('/api/banking-details/EditBankingDetails');

  const onMfaFormSubmit = (
    createEditBankingDetailsFormikProps: FormikProps<CreateEditBankingDetailsFormModel>,
    mfaToken: string,
    mfaFormFormikHelpers: FormikHelpers<MfaModalFormModel>
  ) => {
    createEditBankingDetailsFormikProps.setSubmitting(true);

    editBankingDetailsRequest.makeRequest({
      requestBody: toCreateEditBankingDetailsRequest(
        createEditBankingDetailsFormikProps.values,
        mfaToken,
        investorId,
        localClearingDetailsOnly
      ),
      onSuccess: (response) => {
        createEditBankingDetailsFormikProps.setSubmitting(false);
        mfaFormFormikHelpers.setSubmitting(false);
        onSuccess(response.statusCode);
      },
      onFailure: (error, response) => {
        if (response.translationKey === 'errors.authentication.accountIsLockedOut') {
          //user has been locked out, so force logout
          logoutDueToLockOut(response.translationKey);
        }

        createEditBankingDetailsFormikProps.setSubmitting(false);
        mfaFormFormikHelpers.setSubmitting(false);
      },
    });
  };

  return (
    <CreateEditBankingDetailsForm<BankingDetailsActionResponse>
      formAction="Edit"
      initialFormValues={getInitialFormValues(dataForInitialValues)}
      localClearingDetailsOnly={localClearingDetailsOnly}
      onFormLoadError={onFormLoadError}
      onMfaFormSubmit={onMfaFormSubmit}
      createEditRequestState={editBankingDetailsRequest.state}
    />
  );
};

const getInitialFormValues = (
  dataForInitialValues: GetDataForEditBankingDetailsResponse
): CreateEditBankingDetailsFormModel => ({
  productCode: dataForInitialValues.productCode,
  companyId: dataForInitialValues.companyId,
  currencyId: dataForInitialValues.currencyId,
  accountName: dataForInitialValues.accountName,
  bankId: dataForInitialValues.bankId,
  bankName: dataForInitialValues.bankName,
  bankSwiftCode: dataForInitialValues.bankSwiftCode,
  bankAccountNumber: dataForInitialValues.bankAccountNumber,
  clearingCodeTypeId: dataForInitialValues.clearingCodeTypeId,
  clearingCode: dataForInitialValues.clearingCode,
  clearingCodeValidationState: clearingCodeValidationStateType.checkCodeRequired,
  iban: dataForInitialValues.iban,
  otherIdentifier: dataForInitialValues.otherIdentifier,
  beneficiaryAddress: dataForInitialValues.beneficiaryAddress,
  paymentReference: dataForInitialValues.paymentReference,
  intermediaryBankId: dataForInitialValues.intermediaryBankId,
  intermediaryBankName: dataForInitialValues.intermediaryBankName,
  intermediaryBankSwiftCode: dataForInitialValues.intermediaryBankSwiftCode,
});
