import React from 'react';
import { createContext, useContext, useState } from 'react';
import { GetAgentOrBeneficialOwnerDetailsOptionCode } from './AgentOrBeneficialOwnerDetailsModal';

type InvestorDetailsContextValue = {
  innerComponentApiError: string | null;
  setInnerComponentApiError: (newError: string | null) => void;
  agentOrBeneficialOwnerDetailsModalState: AgentOrBeneficialOwnerDetailsModalState;
  setAgentOrBeneficialOwnerDetailsModalState: React.Dispatch<
    React.SetStateAction<AgentOrBeneficialOwnerDetailsModalState>
  >;
};

type AgentOrBeneficialOwnerDetailsModalState = {
  isOpen: boolean;
  counterpartId: number | null;
  optionCode: GetAgentOrBeneficialOwnerDetailsOptionCode | null;
};

const InvestorDetailsContext = createContext<InvestorDetailsContextValue | undefined>(undefined);

export const useInvestorDetailsContext = () => {
  const contextValue = useContext(InvestorDetailsContext);

  if (!contextValue) {
    throw new Error(
      'useInvestorDetailsContext must be used within a InvestorDetailsContextProvider'
    );
  }

  return contextValue;
};

type Props = {
  children?: React.ReactNode;
};

export const InvestorDetailsContextProvider = ({ children }: Props) => {
  const [innerComponentApiError, setInnerComponentApiError] = useState<string | null>(null);
  const [agentOrBeneficialOwnerDetailsModalState, setAgentOrBeneficialOwnerDetailsModalState] =
    useState<AgentOrBeneficialOwnerDetailsModalState>(
      initialAgentOrBeneficialOwnerDetailsModalState
    );

  const contextValue: InvestorDetailsContextValue = {
    innerComponentApiError,
    setInnerComponentApiError,
    agentOrBeneficialOwnerDetailsModalState,
    setAgentOrBeneficialOwnerDetailsModalState,
  };

  return (
    <InvestorDetailsContext.Provider value={contextValue}>
      {children}
    </InvestorDetailsContext.Provider>
  );
};

const initialAgentOrBeneficialOwnerDetailsModalState: AgentOrBeneficialOwnerDetailsModalState = {
  isOpen: false,
  counterpartId: null,
  optionCode: null,
};
