import React from 'react';
import pfsLogo from '../../images/pfs-logo.svg';
import styled from 'styled-components/macro';
import { useBrandingResourceUrl } from './useBrandingResourceUrl';

export const logoTestId = 'logo';

type Props = {
  className?: string;
};

export const Logo = (props: Props) => {
  const logoResourceUrl = useBrandingResourceUrl('/api/branding/GetLogo');

  const onErrorOrNoContentResponse = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.onerror = null; // prevent endless loop if fallback also fails.
    event.currentTarget.src = pfsLogo;
  };

  return (
    <Container className={props.className}>
      <img
        data-testid={logoTestId}
        src={logoResourceUrl}
        alt="logo"
        onError={onErrorOrNoContentResponse}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: var(--logo-padding-vertical, 0) var(--logo-padding-horizontal, 0);
  max-width: 300px;
  img {
    max-width: 100%;
  }
`;
