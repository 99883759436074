import { SelectOptions } from '../../infrastructure/interface/forms/BaseSelect';

export type GetAgentDealerDropdownOptionsResponse = {
  options: Array<AgentDealerDropdownOptionResponse>;
};

export type AgentDealerDropdownOptionResponse = {
  counterpartId: number;
  longName: string;
};

export const mapAgentDealerDropdownOptionsResponseToSelectOptions = (
  response: GetAgentDealerDropdownOptionsResponse | null
): SelectOptions<number> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.counterpartId,
        label: option.longName,
      }));
