import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { useDashboardFilters } from '../DashboardFiltersContext';
import { BarChart } from '../../../infrastructure/charts/BarChart';
import { ChartDataPoint } from '../../../infrastructure/charts/Chart';
import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { FieldLabel } from '../../../infrastructure/forms/common/FieldLabel';
import { SingleSelect } from '../../../infrastructure/interface/forms/SingleSelect';
import { ComponentResponse } from '../DashboardComponent';

export const securityCostChartComponentName = 'Securities Cost';

export const SecurityCostChart = () => {
  const endpointUrl = 'api/dashboards/GetDataForSecurityCostChart';
  const getRequest = useGetJson<
    GetDataForSecurityCostChartQuery,
    GetDataForSecurityCostChartResponse
  >(endpointUrl);

  const { companyId, fromDate } = useDashboardFilters();
  const [filterType, setFilterType] = useState<SecurityCostChartFilterType>('currency');

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
          filterType: filterType,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate, filterType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <SecurityCostChartComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
          filterType={filterType}
          setFilterType={setFilterType}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForSecurityCostChartResponse;
  showLoadingOverlay: boolean;
  filterType: SecurityCostChartFilterType;
  setFilterType: (value: SecurityCostChartFilterType) => void;
};

const SecurityCostChartComponent = (props: ComponentProps) => {
  const { translate, formatNumber } = useInternationalisation();

  const graphData: Array<ChartDataPoint> = props.response.dataPoints.map((dataPoint) => ({
    label: dataPoint.label,
    value: dataPoint.totalCost,
  }));

  return (
    <DashboardComponentLayout
      headerText={
        translate('pages.dashboard.components.securityCostChart.title') +
        translate(`pages.dashboard.components.securityCostChart.filterType.${props.filterType}`)
      }
      showLoadingOverlay={props.showLoadingOverlay}
      showNoDataMessage={props.response.noDataMessage}
      headerOrientation="row"
      filterSet={<FilterTypeSelect value={props.filterType} onChange={props.setFilterType} />}
    >
      <BarChart
        exportFilename={
          translate('pages.dashboard.components.securityCostChart.exportName') +
          translate(`pages.dashboard.components.securityCostChart.filterType.${props.filterType}`)
        }
        dataPoints={graphData}
        height="stretch"
        tooltipOptions={{
          headerFormat: `${translate(
            `pages.dashboard.components.securityCostChart.filterType.${props.filterType}`
          )} <br />`,
          pointFormatter() {
            return `<b><span style="color: ${this.color}">${
              this.name
            }&nbsp;</span>:&nbsp;${formatNumber(this.y ?? 0, { decimalPlaces: 2 })}</b>`;
          },
        }}
      />
    </DashboardComponentLayout>
  );
};

type FilterTypeProps = {
  value: SecurityCostChartFilterType;
  onChange: (value: SecurityCostChartFilterType) => void;
};

const FilterTypeSelect = (props: FilterTypeProps) => {
  const { translate } = useInternationalisation();

  const translateOptionLabel = useCallback(
    (optionValue: string) =>
      translate(`pages.dashboard.components.securityCostChart.filterType.${optionValue}`),
    [translate]
  );

  const options: SelectOptions<SecurityCostChartFilterType> = useMemo(
    () => [
      { label: translateOptionLabel('currency'), value: 'currency' },
      { label: translateOptionLabel('counterpart'), value: 'counterpart' },
      { label: translateOptionLabel('securityType'), value: 'securityType' },
      { label: translateOptionLabel('position'), value: 'position' },
      { label: translateOptionLabel('equity'), value: 'equity' },
    ],
    [translateOptionLabel]
  );

  return (
    <div>
      <FieldLabel>
        {translate(`pages.dashboard.components.securityCostChart.fieldLabel`)}
      </FieldLabel>
      <SingleSelect
        options={options}
        value={props.value}
        onChange={(value) => props.onChange(value!)}
      />
    </div>
  );
};

type GetDataForSecurityCostChartQuery = {
  companyId: number;
  runDate: string;
  filterType: string;
};

export type GetDataForSecurityCostChartResponse = ComponentResponse & {
  dataPoints: Array<{
    totalCost: number;
    label: string;
  }>;
};

type SecurityCostChartFilterType =
  | 'currency'
  | 'counterpart'
  | 'securityType'
  | 'position'
  | 'equity';
