import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetFileUploadRoleDropdownOptionsResponse = {
  options: Array<FileUploadRoleDropdownOptionResponse>;
};

export type FileUploadRoleDropdownOptionResponse = {
  codeCharacter: string;
  description: string;
};

export const mapGetFileUploadRoleDropdownOptionsResponseToDropdownOptions = (
  response: GetFileUploadRoleDropdownOptionsResponse | null
): SelectOptions<string | null> => {
  if (response == null) return [];

  return response.options.map((option) => ({
    value: option.codeCharacter,
    label: option.description,
  }));
};
