import React, { createContext, useContext, useEffect, useState } from 'react';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useInvestorIdUrlParam } from '../useInvestorIdUrlParam';
import { DistributionReinvestmentActionResponse } from './DistributionReinvestmentActionResponse';
import { RequestFailedAlert } from '../../../infrastructure/api/RequestFailedAlert';

type DistributionReinvestmentsContextValue = {
  distributionReinvestmentsRequestInProgress: boolean;
  distributionReinvestmentsRequestError: string | null;
  latestDistributionReinvestmentsResponse: GetDistributionReinvestmentsResponse | null;
  makeDistributionReinvestmentsRequest: () => void;
  permissionsRequestError: string | null;
  permissionsResponse: GetDistributionReinvestmentsPermissionsResponse | null;
  makePermissionsRequest: () => void;
  deleteDistributionReinvestmentResponse: DistributionReinvestmentActionResponse | null;
  setDeleteDistributionReinvestmentResponse: (
    value: DistributionReinvestmentActionResponse | null
  ) => void;
};

const DistributionReinvestmentsContext = createContext<
  DistributionReinvestmentsContextValue | undefined
>(undefined);

export const useDistributionReinvestmentsContext = () => {
  const contextValue = useContext(DistributionReinvestmentsContext);

  if (!contextValue) {
    throw new Error(
      'useDistributionReinvestments must be used within a DistributionReinvestmentsContextProvider'
    );
  }

  return contextValue;
};

export const DistributionReinvestmentsError = () => {
  const {
    distributionReinvestmentsRequestError,
    makeDistributionReinvestmentsRequest,
    permissionsRequestError,
    makePermissionsRequest,
  } = useDistributionReinvestmentsContext();

  if (permissionsRequestError) {
    return <RequestFailedAlert error={permissionsRequestError} retry={makePermissionsRequest} />;
  }
  if (distributionReinvestmentsRequestError) {
    return (
      <RequestFailedAlert error={distributionReinvestmentsRequestError} retry={makeDistributionReinvestmentsRequest} />
    );
  }
  return (<>
  </>);
};

type Props = {
  children: React.ReactNode;
};

export const DistributionReinvestmentsContextProvider = ({ children }: Props) => {
  const investorId = useInvestorIdUrlParam();

  const [latestDistributionReinvestmentsResponse, setLatestDistributionReinvestmentsResponse] =
    useState<GetDistributionReinvestmentsResponse | null>(null);

  const [deleteDistributionReinvestmentResponse, setDeleteDistributionReinvestmentResponse] =
    useState<DistributionReinvestmentActionResponse | null>(null);

  const getDistributionReinvestmentsRequest = useGetJson<
    GetDistributionReinvestmentsQuery,
    GetDistributionReinvestmentsResponse
  >('/api/distribution-reinvestments/GetDistributionReinvestments');

  const {
    inProgress: distributionReinvestmentsRequestInProgress,
    error: distributionReinvestmentsRequestError,
  } = getDistributionReinvestmentsRequest.state;

  const getDistributionReinvestmentsPermissionsRequest = useGetJson<
    GetDistributionReinvestmentsPermissionsQuery,
    GetDistributionReinvestmentsPermissionsResponse
  >('/api/distribution-reinvestments/GetDistributionReinvestmentsPermissions');

  const { error: permissionsRequestError, response: permissionsResponse } =
    getDistributionReinvestmentsPermissionsRequest.state;

  const makeDistributionReinvestmentsRequest = () => {
    getDistributionReinvestmentsRequest.makeRequest({
      queryParameters: {
        counterpartId: investorId,
      },
      onSuccess: (response) => setLatestDistributionReinvestmentsResponse(response),
    });
  };

  const makePermissionsRequest = () => {
    getDistributionReinvestmentsPermissionsRequest.makeRequest({
      queryParameters: {
        counterpartId: investorId,
      },
    });
  };

  useEffect(() => {
    makeDistributionReinvestmentsRequest();
    makePermissionsRequest();
    setDeleteDistributionReinvestmentResponse(null);
  }, [investorId]); // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue: DistributionReinvestmentsContextValue = {
    distributionReinvestmentsRequestInProgress,
    distributionReinvestmentsRequestError,
    latestDistributionReinvestmentsResponse,
    makeDistributionReinvestmentsRequest,
    permissionsRequestError,
    permissionsResponse,
    makePermissionsRequest,
    deleteDistributionReinvestmentResponse,
    setDeleteDistributionReinvestmentResponse,
  };

  return (
    <DistributionReinvestmentsContext.Provider value={contextValue}>
      {children}
    </DistributionReinvestmentsContext.Provider>
  );
};

type GetDistributionReinvestmentsQuery = {
  counterpartId: number | null;
};

export type GetDistributionReinvestmentsResponse = {
  distributionReinvestments: Array<DistributionReinvestment>;
  reinvestmentDefaultIsNo: boolean;
};

type GetDistributionReinvestmentsPermissionsQuery = {
  counterpartId: number | null;
};

export type GetDistributionReinvestmentsPermissionsResponse = {
  canViewDistributionReinvestments: boolean;
  canEditDistributionReinvestments: boolean;
};

export type DistributionReinvestment = {
  distributionReinvestmentId: number;
  fundName: string;
  validFrom: IsoDatestamp;
  reinvestmentPreference: ReinvestmentPreference;
  editable: boolean;
};

export type ReinvestmentPreference = 'Fully reinvest' | "Don't reinvest" | 'Partially reinvest';
