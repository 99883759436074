import { useParams } from 'react-router';
import { parseIntOrNull } from '../../helpers/stringHelpers';

export const dealNumberUrlParamName = 'dealNumber';
type DealNumberUrlParam = typeof dealNumberUrlParamName;

export const useDealNumberUrlParam = (): number | null => {
  const { dealNumber } = useParams<DealNumberUrlParam>();
  return parseIntOrNull(dealNumber);
};
