import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';

export const useQueryParam = (queryParam: string) => {
  const location = useLocation();

  const getQueryParamValue = useCallback(() => {
    const parsedQuery = queryString.parse(location.search);
    return decodeURIComponent((parsedQuery[queryParam] as string) || '');
  }, [location.search, queryParam]);

  const [queryParamValue, setQueryParamValue] = useState<string>(getQueryParamValue);

  useEffect(() => {
    const newQueryParamValue = getQueryParamValue();
    if (newQueryParamValue !== queryParamValue) {
      setQueryParamValue(newQueryParamValue);
    }
  }, [getQueryParamValue, queryParamValue]);

  return queryParamValue;
};
