import React from 'react';
import { ReportParameterResponse } from '../../../Report';
import { useReportParameterFieldsContext } from '../../ReportParameterFieldsContext';
import { GeneralLedgerClassSelectField } from '../../../../metadata/generalLedger/class/GeneralLedgerClassSelectField';

type Props = {
  generalLedgerClassReportParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsGeneralLedgerClassSelectField = ({
  generalLedgerClassReportParameter,
  onError,
}: Props) => {
  const { setFieldLoaded } = useReportParameterFieldsContext();

  return (
    <GeneralLedgerClassSelectField
      fieldName={generalLedgerClassReportParameter.name}
      label={generalLedgerClassReportParameter.displayText}
      onError={onError}
      settings={{ includeAll: true, includeBlank: false }}
      defaultToFirstOption={true}
      onLoaded={() => setFieldLoaded(generalLedgerClassReportParameter.name)}
    />
  );
};
