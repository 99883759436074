import React, { useContext, useEffect } from 'react';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { groupIdQueryParam, useGroupIdQueryParam } from '../branding/useGroupIdQueryParam';
import { AuthenticationContext } from '../../features/authentication/AuthenticationContext';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

type GetGoogleAnalyticsKeyQuery = {};
export type GetGoogleAnalyticsKeyResponse = { key: string };

export const GoogleAnalyticsProvider = () => {
  const baseUrl = '/api/analytics/GetGoogleAnalyticsKey';

  //get group id if passed in URL
  const isAuthenticated = useContext(AuthenticationContext).isAuthenticated();
  const groupId = useGroupIdQueryParam();
  let queryParameters: Record<string, any> = {};
  if (!isAuthenticated && groupId != null) {
    queryParameters[groupIdQueryParam] = groupId;
  }

  //define the request object.
  const GetGoogleAnalyticsKeyEndpont: string = isEmpty(queryParameters)
    ? baseUrl
    : `${baseUrl}?${queryString.stringify(queryParameters)}`;
  const getAnalticsKeyRequest = useGetJson<
    GetGoogleAnalyticsKeyQuery,
    GetGoogleAnalyticsKeyResponse
  >(GetGoogleAnalyticsKeyEndpont);

  useOnMount(() => {
    //Go and get the key to use.
    getAnalticsKeyRequest.makeRequest({
      onSuccess: (response: GetGoogleAnalyticsKeyResponse) => {
        //key will be returned here on the 'response' object.
        const gaKey: string | null = response.key;

        ReactGA.reset();

        if (gaKey != null && !isEmpty(gaKey)) {
          ReactGA.initialize(gaKey);
        }
      },
      onFailure: (error) => {
        const err = error;
      },
    });
  });

  const location = useLocation();

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.send('pageview'); // Record a pageview for the given page
    }
  }, [location.pathname]);

  return <div></div>;
};
