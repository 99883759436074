import { useParams } from 'react-router';
import { parseIntOrNull } from '../../helpers/stringHelpers';

export const investorIdUrlParamName = 'investorId';
type InvestorIdUrlParam = typeof investorIdUrlParamName;

export const useInvestorIdUrlParam = (): number | null => {
  const { investorId } = useParams<InvestorIdUrlParam>();
  return parseIntOrNull(investorId);
};
