import React, { useState } from 'react';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { RequiresUserRole } from '../authentication/UserRoles';
import { ViewUsers } from './ViewUsers';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { IsoDatestamp } from '../../helpers/dateTimeHelpers';
import styled from 'styled-components/macro';
import { spacing16 } from '../../styling/design/spacing';
import { AppLinkWithIcon } from '../../infrastructure/interface/components/AppLink';
import { PlusIcon } from '../../icons/icons';
import { NoMobileContentAlert } from '../../infrastructure/interface/components/NoMobileContentAlert';
import { TenantSelector } from '../multiTenancy/TenantSelector';
import { BatchCreateUsers } from './batch/create/BatchCreateUsers';
import { BatchDeleteUsers } from './batch/delete/BatchDeleteUsers';

export const usersCreateUserLinkTestId = 'users-createUser-link';

export const Users = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.users.title'));

  return (
    <RequiresUserRole userRole={'Administrator'}>
      <DesktopOnly>
        <UsersComponent />
      </DesktopOnly>
      <MobileOnly>
        <MobileAndTabletUsersComponent />
      </MobileOnly>
    </RequiresUserRole>
  );
};

const MobileAndTabletUsersComponent = () => {
  const { translate } = useInternationalisation();

  return (
    <PaddedPage>
      <Header1>{translate('pages.users.header')}</Header1>
      <NoMobileContentAlert header={translate('pages.users.mobileAlertHeader')} />
    </PaddedPage>
  );
};

const UsersComponent = () => {
  const { translate } = useInternationalisation();

  const [tenantSelectorLoaded, setTenantSelectorLoaded] = useState<boolean>(false);

  const onTenantSelectorLoaded = () => {
    setTenantSelectorLoaded(true);
  };

  return (
    <PaddedPage>
      <HeaderBar>
        <HeaderBarGroup>
          <Header1>{translate('pages.users.header')}</Header1>
          <TenantSelector onLoaded={onTenantSelectorLoaded} />
        </HeaderBarGroup>
        <AppLinkWithIcon data-testid={usersCreateUserLinkTestId} icon={<PlusIcon />} to="new">
          {translate('pages.users.createUserLink')}
        </AppLinkWithIcon>
      </HeaderBar>
      <ViewUsers />
      <HeaderBar>
        <Header1>{translate('pages.users.batchCreate.header')}</Header1>
      </HeaderBar>
      <BatchCreateUsers />
      <HeaderBar>
        <Header1>{translate('pages.users.batchDelete.header')}</Header1>
      </HeaderBar>
      <BatchDeleteUsers />
    </PaddedPage>
  );
};

const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing16};
  margin-top: ${spacing16};

  ${Header1} {
    margin-bottom: 0;
  }
`;

const HeaderBarGroup = styled.div`
  display: flex;
  align-items: end;
  * {
    margin-right: 10px;
  }
`;
export type GetUsersQuery = {
  pageNumber: number;
  resultsPerPage: number;
  filter: string;
};

export type GetUsersResponse = {
  users: Array<UserResponse>;
  total: number;
};

export type UserResponse = {
  userId: string;
  username: string;
  counterpartId: number;
  counterpart: string;
  role: string;
  accessGroup: string;
  creationDate: IsoDatestamp;
  lastLogin: IsoDatestamp | null;
  isLockedOut: boolean;
};
