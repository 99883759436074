import React from 'react';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { CommunicationCopiesContextProvider } from './CommunicationCopiesContext';
import { DesktopCommunicationCopiesList } from './DesktopCommunicationCopiesList';
import { MobileCommunicationCopiesList } from './MobileCommunicationCopiesList';

type Props = {
  blocked: boolean;
};

export const CommunicationCopies = ({ blocked }: Props) => (
  <CommunicationCopiesContextProvider blocked={blocked}>
    <DesktopOnly>
      <DesktopCommunicationCopiesList />
    </DesktopOnly>
    <MobileOnly>
      <MobileCommunicationCopiesList />
    </MobileOnly>
  </CommunicationCopiesContextProvider>
);
