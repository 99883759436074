import { AppLink } from '../../../infrastructure/interface/components/AppLink';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import { PaginatedTable } from '../../../infrastructure/interface/tables/PaginatedTable';
import { Table, TBody, Th, THead, Td, Tr } from '../../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardDeleteButton } from './DashboardDeleteButton';
import { useDashboardsState } from './DashboardsStateContext';
import { DashboardStatus } from './GetAdminDashboardListResponse';
import { StatusIndicator } from '../../../infrastructure/interface/components/StatusIndicator';
import React from 'react';

export const DesktopDashboardsList = () => {
  const { translate, formatDate } = useInternationalisation();

  const {
    pageNumber,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    latestResponse,
    requestInProgress,
    loadData,
  } = useDashboardsState();

  if (latestResponse === null) {
    return null;
  }

  return (
    <Panel>
      <PaginatedTable
        showLoadingOverlay={requestInProgress}
        currentPageNumber={pageNumber}
        onChangeCurrentPageNumber={setPageNumber}
        resultsPerPage={resultsPerPage}
        onChangeResultsPerPage={setResultsPerPage}
        totalResultsCount={latestResponse.totalResultsCount}
      >
        <Table>
          <THead>
            <Tr>
              <Th>{translate('pages.dashboards.fieldNames.dashboardName')}</Th>
              <Th>{translate('pages.dashboards.fieldNames.loginRole')}</Th>
              <Th>{translate('pages.dashboards.fieldNames.accessGroup')}</Th>
              <Th>{translate('pages.dashboards.fieldNames.status')}</Th>
              <Th>{translate('pages.dashboards.fieldNames.lastChanged')}</Th>
              <Th>{translate('pages.dashboards.fieldNames.lastAuthorised')}</Th>
              <Th />
            </Tr>
          </THead>
          <TBody>
            {latestResponse.dashboards.map((dashboard) => (
              <Tr key={dashboard.dashboardId}>
                <Td>
                  <AppLink to={`/dashboards/edit/${dashboard.dashboardId}`}>
                    {dashboard.description}
                  </AppLink>
                </Td>
                <Td>{dashboard.loginRole}</Td>
                <Td>{dashboard.accessGroup}</Td>
                <Td>
                  <DashboardStatusComponent status={dashboard.status} />
                </Td>
                <Td>
                  {`${formatDate(dashboard.lastChanged)} ${translate('pages.dashboards.by')} ${
                    dashboard.maker
                  }`}
                </Td>
                <Td>
                  {`${formatDate(dashboard.lastAuthorised)} ${translate('pages.dashboards.by')} ${
                    dashboard.authoriser
                  }`}
                </Td>
                <Td>
                  <DashboardDeleteButton
                    dashboardId={dashboard.dashboardId}
                    onDashboardDeleted={loadData}
                    size="medium"
                  />
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      </PaginatedTable>
    </Panel>
  );
};

type DashboardStatusComponentProps = {
  status: DashboardStatus;
};

export const DashboardStatusComponent = ({ status }: DashboardStatusComponentProps) => {
  const { translate } = useInternationalisation();

  switch (status) {
    case 'Authorised':
      return (
        <StatusIndicator type="positive" text={translate('pages.dashboards.status.authorised')} />
      );
    case 'Deleted':
      return (
        <StatusIndicator type="negative" text={translate('pages.dashboards.status.deleted')} />
      );
    case 'Updated':
      return <StatusIndicator type="warning" text={translate('pages.dashboards.status.updated')} />;
    case 'Input':
      return <StatusIndicator type="notice" text={translate('pages.dashboards.status.input')} />;
    default:
      return null;
  }
};
