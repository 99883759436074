import { FormikValues, useFormikContext } from 'formik';
import { usePrevious } from '../../hooks/usePrevious';
import { useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';

/**
 * Can be used when a set of fields cannot exist together - when one is set, all
 * other field values associated with the fields names in the array are set to null.
 * @param mutuallyExclusiveFieldNames the set of field names that are mutually exclusive
 */
export const useMutuallyExclusiveFields = <TFormModel extends FormikValues>(
  mutuallyExclusiveFieldNames: Array<keyof TFormModel>
) => {
  const { setFieldValue, values } = useFormikContext<TFormModel>();
  const previousValues = usePrevious(values);

  const fieldValueChangedToNonNull = useCallback(
    (fieldName: keyof TFormModel): boolean => {
      const newValue = values[fieldName];
      const previousValue = previousValues != null ? previousValues[fieldName] : null;
      return newValue !== null && !isEqual(previousValue, newValue);
    },
    [values, previousValues]
  );

  const setOtherFieldValuesToNull = useCallback(
    (fieldNameToRemainNonNull: keyof TFormModel) => {
      const otherFieldsNames: Array<keyof TFormModel> = mutuallyExclusiveFieldNames.filter(
        (fieldName) => fieldName !== fieldNameToRemainNonNull
      );
      for (let otherFieldName of otherFieldsNames) {
        const otherFieldValue = values[otherFieldName];
        if (otherFieldValue !== null) {
          setFieldValue(otherFieldName as string, null);
        }
      }
    },
    [mutuallyExclusiveFieldNames, values, setFieldValue]
  );

  useEffect(() => {
    if (!isEqual(previousValues, values)) {
      for (let fieldName of mutuallyExclusiveFieldNames) {
        if (fieldValueChangedToNonNull(fieldName)) {
          setOtherFieldValuesToNull(fieldName);
          break;
        }
      }
    }
  }, [
    previousValues,
    values,
    mutuallyExclusiveFieldNames,
    fieldValueChangedToNonNull,
    setOtherFieldValuesToNull,
  ]);
};
