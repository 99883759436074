import { ApiRequestStateWrapper } from '../../../../infrastructure/api/ApiRequestStateWrapper';
import { usePutJson } from '../../../../infrastructure/api/usePutJson';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { useWindowTitle } from '../../../../infrastructure/hooks/useWindowTitle';
import { Header1 } from '../../../../infrastructure/interface/components/Headers';
import { PageHeaderActions } from '../../../../infrastructure/interface/components/PageHeaderActions';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { CentredPaddedPage } from '../../../../styling/layout/PaddedPage';
import { RequiresUserRole } from '../../../authentication/UserRoles';
import { useInvestorIdUrlParam } from '../../useInvestorIdUrlParam';
import { DistributionReinvestmentActionResponse } from '../DistributionReinvestmentActionResponse';
import { ReinvestmentPreference } from '../DistributionReinvestmentsContext';
import {
  CreateEditDistributionReinvestmentForm,
  CreateEditDistributionReinvestmentFormModel,
} from './CreateEditDistributionReinvestmentForm';
import { useDistributionReinvestmentIdUrlParam } from './useDistributionReinvestmentIdUrlParam';
import {
  GetCreateEditDistributionReinvestmentFormDefaultsResponse,
  useGetCreateEditDistributionReinvestmentFormDefaults,
} from './useGetCreateEditDistributionReinvestmentFormDefaults';

export const EditDistributionReinvestment = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.createEditDistributionReinvestment.editTitle'));

  return (
    <RequiresUserRole userRole={['Investor', 'Consolidated Investor', 'Advisor']}>
      <EditDistributionReinvestmentApiRequestStateWrapper />
    </RequiresUserRole>
  );
};

const EditDistributionReinvestmentApiRequestStateWrapper = () => {
  const investorId = useInvestorIdUrlParam();
  const distributionReinvestmentId = useDistributionReinvestmentIdUrlParam();

  const getFormDefaultsRequest = useGetCreateEditDistributionReinvestmentFormDefaults();

  const makeRequest = () =>
    getFormDefaultsRequest.makeRequest({
      queryParameters: {
        counterpartId: investorId,
        distributionReinvestmentId: distributionReinvestmentId,
      },
    });

  useOnMount(() => {
    makeRequest();
  });

  return (
    <ApiRequestStateWrapper apiRequestState={getFormDefaultsRequest.state} retry={makeRequest}>
      {(response) => <EditDistributionReinvestmentComponent formDefaultsResponse={response} />}
    </ApiRequestStateWrapper>
  );
};

type EditDistributionReinvestmentComponentProps = {
  formDefaultsResponse: GetCreateEditDistributionReinvestmentFormDefaultsResponse;
};

const EditDistributionReinvestmentComponent = (
  props: EditDistributionReinvestmentComponentProps
) => {
  const { reinvestmentDefaultIsAll, ...initialFormValues } = props.formDefaultsResponse;

  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();
  const distributionReinvestmentId = useDistributionReinvestmentIdUrlParam();

  const editDistributionReinvestmentRequest = usePutJson<
    EditDistributionReinvestmentCommand,
    DistributionReinvestmentActionResponse
  >('/api/distribution-reinvestments/EditDistributionReinvestment');

  const onConfirmAction = (
    formModel: CreateEditDistributionReinvestmentFormModel,
    closeConfirmationModal: () => void
  ) => {
    editDistributionReinvestmentRequest.makeRequest({
      requestBody: {
        counterpartId: investorId,
        distributionReinvestmentId: distributionReinvestmentId!,
        companyId: formModel.companyId,
        reinvestmentPreference: formModel.reinvestmentPreference,
      },
      onSuccess: () => closeConfirmationModal(),
      onFailure: () => closeConfirmationModal(),
    });
  };

  return (
    <CentredPaddedPage>
      <Header1>{translate('pages.createEditDistributionReinvestment.editHeader')}</Header1>
      <PageHeaderActions withBackButton={true} />
      <CreateEditDistributionReinvestmentForm
        initialValues={initialFormValues}
        reinvestmentDefaultIsAll={reinvestmentDefaultIsAll}
        onConfirmAction={onConfirmAction}
        actionRequestState={editDistributionReinvestmentRequest.state}
      />
    </CentredPaddedPage>
  );
};

export type EditDistributionReinvestmentCommand = {
  distributionReinvestmentId: number;
  counterpartId: number | null;
  companyId: number;
  reinvestmentPreference: ReinvestmentPreference;
};
