import { AvailableCurrency, Investment, TotalInvestment } from './types';
import { find, intersectionWith, isEqual, map } from 'lodash';
import { SelectOptions } from '../../infrastructure/interface/forms/BaseSelect';

export const findCurrencyIdByCode = (
  currencyCode: string,
  availableCurrencies: Array<AvailableCurrency>
): number | undefined => {
  const foundCurrency = find(
    availableCurrencies,
    (currency) => currency.currencyCode === currencyCode
  );
  return foundCurrency?.currencyId ?? availableCurrencies[0]?.currencyId;
};

export const getAvailableCurrencySelectOptions = (
  availableCurrencies: Array<AvailableCurrency>
): SelectOptions<number> =>
  availableCurrencies.map((availableCurrency) => ({
    label: availableCurrency.currencyCode,
    value: availableCurrency.currencyId,
  }));

export const hideTotalInvestments = (
  totalInvestments: Array<TotalInvestment>,
  investments: Array<Investment>
) => {
  const totalInvestmentsCurrencies = new Set(map(totalInvestments, 'currency'));
  const investmentsCurrencies = new Set(map(investments, 'currency'));

  const allInvestmentsInPreferredCurrency =
    totalInvestmentsCurrencies.size === 1 &&
    investmentsCurrencies.size === 1 &&
    isEqual(totalInvestmentsCurrencies, investmentsCurrencies);

  return allInvestmentsInPreferredCurrency;
};

export const getAvailableCurrencies = (
  availableCurrencies: Array<AvailableCurrency>,
  totalInvestments: Array<TotalInvestment>
) => {
  return intersectionWith(
    availableCurrencies,
    totalInvestments,
    ({ currencyCode }, { currency }) => currencyCode === currency
  );
};

export const getDefaultTotalInvestment = (
  totalInvestments: Array<TotalInvestment>,
  availableCurrencies: Array<AvailableCurrency>
): TotalInvestment => {
  return (
    find(
      totalInvestments,
      (totalInvestment) => totalInvestment.currency === availableCurrencies[0]?.currencyCode
    ) || totalInvestments[0]
  );
};
