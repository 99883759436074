import { keys } from 'lodash';
import * as locales from 'date-fns/locale';

export const findLocale = (localeCode: string): Locale | null => {
  let matches = keys(locales).filter((key) => {
    let locale: Locale = (locales as any)[key];
    return locale.code?.toLowerCase() === localeCode.toLowerCase();
  });

  if (matches.length > 0) {
    return (locales as any)[matches[0]];
  }

  if (localeCode.includes('-')) {
    // We can't find the exact locale (e.g. 'de-DE')

    // but we might get a match without the dash (e.g. 'en-GB' -> locales.enGB)
    const withoutDash = localeCode.replace('-', '');
    const localeWithoutDash = findLocale(withoutDash);

    if (localeWithoutDash != null) {
      return localeWithoutDash;
    } else {
      // or from just the first part (e.g. 'de-DE' -> locales.de).
      const firstPart = localeCode.split('-')[0];
      return findLocale(firstPart);
    }
  }

  return null;
};
