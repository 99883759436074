import { useParams } from 'react-router';
import { parseIntOrNull } from '../../helpers/stringHelpers';

export const uploadIdUrlParamName = 'uploadId';
type UploadIdUrlParam = typeof uploadIdUrlParamName;

export const useUploadIdUrlParam = (): number | null => {
  const { uploadId } = useParams<UploadIdUrlParam>();
  return parseIntOrNull(uploadId);
};
