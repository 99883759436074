import React from 'react';
import { isEmpty } from 'lodash';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { useInvestorBankingDetailsContext } from './InvestorBankingDetailsContext';
import { CentredSpinner } from '../../../infrastructure/interface/components/Spinner';
import styled from 'styled-components/macro';
import { spacing32 } from '../../../styling/design/spacing';
import {
  ItemDetailsCard,
  ItemDetailsCardButtonsContainer,
  MobileNoResultsCard,
} from '../../../infrastructure/interface/components/ItemDetailsCard';
import { BankingDetail } from './GetInvestorBankingDetailsResponse';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import { LoadingOverlay } from '../../../infrastructure/interface/components/LoadingOverlay';
import { EditBankingDetailsButton } from './createEdit/edit/EditBankingDetailsButton';
import { DeleteBankingDetailsButton } from './delete/DeleteBankingDetailsButton';
import { bankingDetailsActionButtonsContainerTestId } from './DesktopInvestorBankingDetails';
import { Alert } from '../../../infrastructure/interface/components/Alert';

type Props = {
  canEdit: boolean;
};

export const MobileInvestorBankingDetails = ({ canEdit }: Props) => {
  const { translate } = useInternationalisation();
  
  const {
    bankingDetailsRequestInProgress,
    latestBankingDetailsResponse,
    permissionsRequestInProgress,
    permissionsResponse,
  } = useInvestorBankingDetailsContext();

  if (permissionsResponse?.canViewBankingDetails === false) {
    return (
      <Alert alertType="warning" header={translate('pages.bankingDetails.permissionDeniedTitle')}>
        {translate('pages.bankingDetails.permissionDenied')}
      </Alert>
    );
  }

  return (
    <Container
      data-testid={bankingDetailsActionButtonsContainerTestId(permissionsResponse != null)}
    >
      {latestBankingDetailsResponse == null ? (
        bankingDetailsRequestInProgress ? (
          <CentredSpinner size="xlarge" />
        ) : null
      ) : isEmpty(latestBankingDetailsResponse.bankingDetails) ? (
        <MobileNoResultsCard />
      ) : (
        <LoadingOverlay
          showOverlay={bankingDetailsRequestInProgress || permissionsRequestInProgress}
        >
          {latestBankingDetailsResponse.bankingDetails.map((bankingDetail, index) => (
            <BankingDetailCard
              key={index}
              bankingDetail={bankingDetail}
              canEdit={canEdit}
            />
          ))}
        </LoadingOverlay>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${spacing32};
`;

type BankingDetailCardProps = {
  bankingDetail: BankingDetail;
  canEdit: boolean;
};

const BankingDetailCard = ({ bankingDetail, canEdit }: BankingDetailCardProps) => {
  const { translate } = useInternationalisation();

  return (
    <ItemDetailsCard title={bankingDetail.product}>
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.bankingDetails.fieldNames.bankName')}
        value={bankingDetail.bankName}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.bankingDetails.fieldNames.accountName')}
        value={bankingDetail.accountName}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.bankingDetails.fieldNames.accountNumber')}
        value={bankingDetail.accountNumber}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.bankingDetails.fieldNames.fundName')}
        value={bankingDetail.fundName}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.bankingDetails.fieldNames.currency')}
        value={bankingDetail.currencyCode}
      />
      {canEdit && (
        <ItemDetailsCardButtonsContainer>
          <EditBankingDetailsButton bankingDetail={bankingDetail} />
          <DeleteBankingDetailsButton bankingDetail={bankingDetail} size="small" />
        </ItemDetailsCardButtonsContainer>
      )}
    </ItemDetailsCard>
  );
};
