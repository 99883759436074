import React from 'react';
import ReactSelect, {
  createFilter,
  DropdownIndicatorProps,
  GroupBase,
  SelectComponentsConfig as ReactSelectComponentsConfig,
  OnChangeValue,
  MenuPlacement,
  components,
} from 'react-select';
import { spacing16 } from '../../../styling/design/spacing';
import { colourGrey03, colourGrey05, colourGrey08 } from '../../../styling/design/colours';
import styled, { css } from 'styled-components/macro';
import { ChevronDownIcon, IconOptions } from '../../../icons/icons';
import { baseSelectStyles, BaseSelectStylesConfigFactory } from './baseSelectStyles';
import { CustomSelectOption } from './CustomSelectOption';

export const baseSelectTestId = 'base-select';

export type BaseSelectProps<TValue extends unknown, IsMulti extends boolean> = {
  'data-testid'?: string;
  placeholder?: string;
  options: SelectOptions<TValue> | GroupedSelectOptions<TValue>;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  name?: string;
  highlight?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  dropdownIcon?: IconOptions;
  customStyles?: BaseSelectStylesConfigFactory<TValue, IsMulti>;
  customComponents?: BaseSelectComponentsConfig<TValue, IsMulti>;
  className?: string;
  menuPlacement?: MenuPlacement;
};

type Props<TValue extends unknown, IsMulti extends boolean> = BaseSelectProps<TValue, IsMulti> & {
  isMulti: IsMulti;
  selectedOption: SelectOption<TValue> | Array<SelectOption<TValue>> | null;
  onChange: (newValue: OnChangeValue<SelectOption<TValue>, IsMulti>) => void;
};

export const BaseSelect = <TValue extends unknown, IsMulti extends boolean>(
  props: Props<TValue, IsMulti>
) => {
  const {
    placeholder,
    options,
    onBlur,
    name,
    highlight,
    disabled,
    clearable,
    searchable,
    dropdownIcon,
    customStyles,
    customComponents,
    className,
    isMulti,
    selectedOption,
    onChange,
    menuPlacement,
  } = props;

  return (
    <SelectContainer
      className={className}
      data-testid={props['data-testid'] ?? baseSelectTestId}
      onBlur={onBlur}
    >
      <ReactSelect
        isMulti={isMulti}
        placeholder={placeholder}
        options={options}
        value={selectedOption}
        name={name}
        onChange={onChange}
        isDisabled={disabled}
        isClearable={clearable}
        isSearchable={searchable}
        styles={
          customStyles != null
            ? customStyles(highlight)
            : baseSelectStyles<TValue, IsMulti>(highlight)
        }
        components={getComponents({
          dropdownIcon,
          customComponents,
        })}
        filterOption={createFilter({
          ignoreCase: true,
          matchFrom: 'any',
          stringify: (option) => option.label,
        })}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMulti}
        menuPlacement={menuPlacement ?? 'auto'}
        blurInputOnSelect={false} // prevents validation race conditions
      />
    </SelectContainer>
  );
};

export const SelectContainer = styled.div`
  width: 100%;
`;

export type SelectOption<TValue> = {
  value: TValue;
  label: string;
  ariaLabel?: string;
};

export type SelectOptions<TValue> = Array<SelectOption<TValue>>;

export type GroupedSelectOption<TValue> = GroupBase<SelectOption<TValue>>;

export type GroupedSelectOptions<TValue> = Array<GroupedSelectOption<TValue>>;

export const isGroupedOptions = <TValue extends unknown>(
  options: SelectOptions<TValue> | GroupedSelectOptions<TValue>
): options is GroupedSelectOptions<TValue> =>
  !!options.length && (options as GroupedSelectOptions<TValue>)[0].options !== undefined;

export type BaseSelectComponentsConfig<
  TValue extends unknown,
  IsMulti extends boolean
> = ReactSelectComponentsConfig<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>;

const getComponents = <TValue extends unknown, IsMulti extends boolean>(parameters: {
  dropdownIcon: IconOptions | undefined;
  customComponents: BaseSelectComponentsConfig<TValue, IsMulti> | undefined;
}): BaseSelectComponentsConfig<TValue, IsMulti> => {
  const { dropdownIcon, customComponents } = parameters;

  const DropdownIndicator = (
    props: DropdownIndicatorProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => (
    <components.DropdownIndicator {...props}>
      <IconContainer
        colour={dropdownIcon?.colour ?? colourGrey05}
        isFocused={props.isFocused}
        isDisabled={props.isDisabled}
      >
        {dropdownIcon?.icon ?? <ChevronDownIcon />}
      </IconContainer>
    </components.DropdownIndicator>
  );

  return {
    Option: CustomSelectOption,
    IndicatorSeparator: () => null,
    DropdownIndicator,
    ...customComponents,
  };
};

const IconContainer = styled.div<{ colour: string; isFocused: boolean; isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.isDisabled ? colourGrey03 : props.isFocused ? colourGrey08 : props.colour};

  ${(props) =>
    !props.isDisabled &&
    css`
      &:hover {
        opacity: ${props.isFocused ? 1 : 0.75};
      }
    `}

  transition: opacity 0.125s ease;

  svg {
    height: ${spacing16};
    width: ${spacing16};
  }
`;
