import React from 'react';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';
import { CapitalCallSelect, CapitalCallSelectOptionValue } from './CapitalCallSelect';
import { CapitalCallSelectSettings } from './CapitalCallSelectSettings';
import { GetCapitalCallDropdownOptionsResponse } from './GetCapitalCallDropdownOptionsResponse';

type Props = MetadataSingleSelectFieldComponentProps<
  CapitalCallSelectOptionValue,
  CapitalCallSelectSettings,
  GetCapitalCallDropdownOptionsResponse
>;

export const CapitalCallSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    CapitalCallSelectOptionValue,
    CapitalCallSelectSettings,
    GetCapitalCallDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <CapitalCallSelect {...metadataSingleSelectProps} />
    )}
  />
);
