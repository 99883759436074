import React from 'react';
import styled from 'styled-components/macro';
import { LoadingOverlay } from '../../../infrastructure/interface/components/LoadingOverlay';
import { ItemDetailsCard } from '../../../infrastructure/interface/components/ItemDetailsCard';
import { MobilePaginationControls } from '../../../infrastructure/interface/components/MobilePaginationControls';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { spacing24 } from '../../../styling/design/spacing';
import { useDashboardsState } from './DashboardsStateContext';
import { DashboardStatusComponent } from './DesktopDashboardsList';
import { AdminDashboardResponse } from './GetAdminDashboardListResponse';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import { DashboardDeleteButton } from './DashboardDeleteButton';

export const MobileDashboardsList = () => {
  const {
    pageNumber,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    latestResponse,
    requestInProgress,
  } = useDashboardsState();

  if (latestResponse === null) {
    return null;
  }

  return (
    <>
      <LoadingOverlay showOverlay={requestInProgress}>
        {latestResponse.dashboards.map((dashboard) => (
          <DashboardCard key={dashboard.dashboardId} dashboard={dashboard} />
        ))}
      </LoadingOverlay>
      <MobilePaginationControls
        currentPageNumber={pageNumber}
        onChangeCurrentPageNumber={setPageNumber}
        resultsPerPage={resultsPerPage}
        onChangeResultsPerPage={setResultsPerPage}
        totalResultsCount={latestResponse.totalResultsCount}
      />
    </>
  );
};

type DashboardCardProps = {
  dashboard: AdminDashboardResponse;
};

const DashboardCard = ({ dashboard }: DashboardCardProps) => {
  const { translate, formatDate } = useInternationalisation();
  const { loadData } = useDashboardsState();

  return (
    <ItemDetailsCard title={dashboard.description}>
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.dashboards.fieldNames.loginRole')}
        value={dashboard.loginRole}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.dashboards.fieldNames.accessGroup')}
        value={dashboard.accessGroup}
      />
      <FieldAndValue
        type="custom"
        fieldLabel={translate('pages.dashboards.fieldNames.status')}
        value={<DashboardStatusComponent status={dashboard.status} />}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.dashboards.fieldNames.lastChanged')}
        value={`
          ${formatDate(dashboard.lastChanged)}
          ${translate('pages.dashboards.by')}
          ${dashboard.maker}
        `}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.dashboards.fieldNames.lastAuthorised')}
        value={`
          ${formatDate(dashboard.lastAuthorised)}
          ${translate('pages.dashboards.by')}
          ${dashboard.authoriser}
        `}
      />
      <DeleteButtonContainer>
        <DashboardDeleteButton
          size="small"
          onDashboardDeleted={loadData}
          dashboardId={dashboard.dashboardId}
        />
      </DeleteButtonContainer>
    </ItemDetailsCard>
  );
};

const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spacing24};
`;
