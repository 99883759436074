export type CompanySelectForTransactionsSettings = {
  investorId?: number | null;
  context?: CompanySelectForTransactionsContextCode;
};

export const defaultCompanySelectForTransactionsSettings: CompanySelectForTransactionsSettings = {};

export const companySelectForTransactionsContextCode = {
  Individual: 'I',
  Switch: 'S',
} as const;

type CompanySelectForTransactionsContext = keyof typeof companySelectForTransactionsContextCode;

type CompanySelectForTransactionsContextCode =
  typeof companySelectForTransactionsContextCode[CompanySelectForTransactionsContext];
