import { ReportParameterResponse } from '../../Report';
import React, { useContext } from 'react';
import { CapitalCallSelectField } from '../../../metadata/capitalCall/CapitalCallSelectField';
import { useReportParameterFieldsContext } from '../ReportParameterFieldsContext';
import { ReportContext } from '../../ReportContext';

type Props = {
  capitalCallParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsCapitalCallSelectField = ({ capitalCallParameter, onError }: Props) => {
  const { setFieldLoaded } = useReportParameterFieldsContext();
  const { selectedCompanyOption } = useContext(ReportContext);

  return (
    <CapitalCallSelectField
      settings={{
        companyId: selectedCompanyOption?.companyId ?? null,
        includeBlank: false,
        employeeCounterpartId: null,
      }}
      fieldName={capitalCallParameter.name}
      label={capitalCallParameter.displayText}
      onError={onError}
      onLoaded={() => setFieldLoaded(capitalCallParameter.name)}
    />
  );
};
