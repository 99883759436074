import React from 'react';
import { ApiRequestStateWrapper } from '../../infrastructure/api/ApiRequestStateWrapper';
import { useGetTermsAndConditionsRequest } from './useGetTermsAndConditionsRequest';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { CentredPaddedPage } from '../../styling/layout/PaddedPage';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { TermsAndConditionsHtml } from './TermsAndConditionsHtml';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { RequiresUserRole } from '../authentication/UserRoles';
import { allNonAdminUserRoles } from '../authentication/UserRole';

export const ViewTermsAndConditions = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.termsAndConditions.title'));

  return (
    <RequiresUserRole userRole={allNonAdminUserRoles}>
      <ViewTermsAndConditionsApiRequestStateWrapper />
    </RequiresUserRole>
  );
};

const ViewTermsAndConditionsApiRequestStateWrapper = () => {
  const { translate } = useInternationalisation();

  const getTermsAndConditionsRequest = useGetTermsAndConditionsRequest();

  useOnMount(() => {
    getTermsAndConditionsRequest.makeRequest();
  });

  return (
    <ApiRequestStateWrapper
      apiRequestState={getTermsAndConditionsRequest.state}
      retry={getTermsAndConditionsRequest.makeRequest}
    >
      {(response) => (
        <CentredPaddedPage>
          <Header1>{translate('pages.termsAndConditions.header')}</Header1>
          <TermsAndConditionsHtml html={response.termsAndConditionsHtml} />
        </CentredPaddedPage>
      )}
    </ApiRequestStateWrapper>
  );
};
