import React from 'react';
import { BaseButton, ButtonSize, ButtonStyle, NativeButtonProps } from './BaseButton';
import { ButtonIcon } from './ButtonIcon';

type Props = NativeButtonProps & {
  icon: React.ReactNode;
  position?: 'left' | 'right';
  buttonStyle?: ButtonStyle;
  size?: ButtonSize;
  stretch?: boolean;
};

export const ButtonWithIcon = ({
  icon,
  position,
  buttonStyle,
  size,
  stretch,
  children,
  ...rest
}: Props) => (
  <BaseButton
    buttonStyle={buttonStyle || 'primary'}
    size={size || 'medium'}
    stretch={stretch}
    {...rest}
  >
    <ButtonIcon icon={icon} position={position || 'left'} size={size || 'medium'} />
    {children}
  </BaseButton>
);
