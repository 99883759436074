import React from 'react';

import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { Card } from '../../../infrastructure/interface/components/Card';
import { ExpandableWithHeader } from '../../../infrastructure/interface/components/Expandable';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DesktopDistributionReinvestmentsList } from './DesktopDistributionReinvestmentsList';
import { MobileDistributionReinvestmentsList } from './MobileDistributionReinvestmentsList';
import {
  DistributionReinvestmentsError,
  useDistributionReinvestmentsContext
} from './DistributionReinvestmentsContext';

export const DistributionReinvestmentsManagerView = () => {
  const { translate } = useInternationalisation();

  const { distributionReinvestmentsRequestError,permissionsRequestError } =
    useDistributionReinvestmentsContext();
  
  if (distributionReinvestmentsRequestError || permissionsRequestError) {
    return <DistributionReinvestmentsError />;
  }

  return (
    <>
        <DesktopOnly>
          <Card
            header={translate('pages.distributionReinvestments.title')}
            cardType="expandable"
            startExpanded={false}
          >
            <DesktopDistributionReinvestmentsList canEdit={false}/>
          </Card>
        </DesktopOnly>
        <MobileOnly>
          <ExpandableWithHeader
            headerText={translate('pages.distributionReinvestments.title')}
            startExpanded={false}
          >
            <MobileDistributionReinvestmentsList canEdit={false}/>
          </ExpandableWithHeader>
        </MobileOnly>
    </>
  );
};
