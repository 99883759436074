import React from 'react';
import { Highcharts } from './Highcharts';
import { PointOptionsObject, TooltipOptions } from 'highcharts';
import {
  colourChart01,
  colourChart02,
  colourChart03,
  colourChart04,
  colourChart05,
  colourChart06,
  colourChart07,
  colourChart08,
  colourChart09,
  colourChart10,
  colourChart11,
  colourChart12,
  colourChart13,
  colourChart14,
  colourChart15,
  colourChart16,
  colourChart17,
  colourChart18,
  colourChart19,
  colourChart20,
  colourGrey01,
} from '../../styling/design/colours';
import styled from 'styled-components/macro';
import { spacing32 } from '../../styling/design/spacing';
import HighchartsReact from 'highcharts-react-official';

type Props = {
  options: Highcharts.Options;
  className?: string;
  'data-testid'?: string;
};

// This is intended to be a low-level wrapper component around Highcharts.
// As more charts are introduced we should refine the abstraction to reduce
// duplication as much as possible, and introduce higher-level wrappers if
// possible.
export const Chart = (props: Props) => {
  const { className, options } = props;
  return (
    <span data-testid={props['data-testid'] ?? 'chart'}>
      <HighchartsReact
        highcharts={Highcharts}
        className={className}
        options={options}
        data-testid={props['data-testid'] ?? 'chart'}
      />
    </span>
  );
};

export type ChartDataPoint = {
  label: string;
  value: number;
};

export type BaseChartProps = {
  title?: string;
  subtitle?: string;
  exportFilename: string;
  height?: number | 'stretch';
  marginLeft? : number;
  tooltipOptions?: TooltipOptions;
  onClickDataPoint?: (dataPoint: ChartDataPoint) => void;
  'data-testid'?: string;
  responsiveOptions?: Highcharts.ResponsiveRulesOptions;
};

const defaultChartHeightInPixels = 350;

export const baseChartOptions = (props: BaseChartProps): Highcharts.Options => ({
  title: { text: props.title },
  subtitle: { text: props.subtitle },
  chart: {
    height: props.height === 'stretch' ? null : props.height ?? defaultChartHeightInPixels,
    marginLeft: props.marginLeft,
  },
  credits: { enabled: false },
  exporting: {
    enabled: true,
    filename: props.exportFilename,
    fallbackToExportServer: false,
  },
  colors: [
    colourChart01,
    colourChart02,
    colourChart03,
    colourChart04,
    colourChart05,
    colourChart06,
    colourChart07,
    colourChart08,
    colourChart09,
    colourChart10,
    colourChart11,
    colourChart12,
    colourChart13,
    colourChart14,
    colourChart15,
    colourChart16,
    colourChart17,
    colourChart18,
    colourChart19,
    colourChart20,
  ],
  responsive: {
    rules: props.responsiveOptions !== undefined ? [props.responsiveOptions] : [],
  },
  tooltip: {
    ...props.tooltipOptions,
    formatter(tooltip) {
      //if the series has been hidden via click on the legend. do not show a tooltip.
      if (this.series.visible === false) {
        return false;
      }
      //otherwise fallback to the the default tooltip formatter.
      return tooltip.defaultFormatter.call(this, tooltip);
    },
  },
});

export const mapChartDataPointToPointOptions = (
  dataPoint: ChartDataPoint,
  onClickDataPoint?: (dataPoint: ChartDataPoint) => void
): PointOptionsObject => ({
  name: dataPoint.label,
  y: dataPoint.value,
  events: {
    click: ({ point }) => {
      if (onClickDataPoint != null) {
        onClickDataPoint({
          label: point.name,
          value: point.y!,
        });
      }
    },
  },
});

export const ChartsSpacer = styled.div`
  margin: ${spacing32} 0;
  border-top: 1px solid ${colourGrey01};
`;
