import {
  HttpMethod,
  QueryParameters,
  makeRequest as makeApiRequest,
  MakeRequestParameters,
} from './makeRequest';
import { useApiRequest } from './useApiRequest';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { useSiteContext } from '../../features/multiTenancy/SiteContext';
import { useSessionTimeout } from '../hooks/useSessionTimeout';

type UseJsonApiRequestParameters = {
  method: HttpMethod;
  endpointUrl: string;
  translate: TranslateFunction | null;
};

type MakeJsonRequestArguments<TRequestBody, TResponse> = {
  requestBody?: TRequestBody;
  appendPath?: string;
  queryParameters?: QueryParameters;
  onSuccess?: (response: TResponse) => void;
  onFailure?: (error: string) => void;
};

export const useJsonApiRequest = <TRequestBody, TResponse>({
  method,
  endpointUrl,
  translate,
}: UseJsonApiRequestParameters) => {
  const siteContext = useSiteContext();
  const {generalTimeout} = useSessionTimeout();
  return useApiRequest<
    MakeJsonRequestArguments<TRequestBody, TResponse>,
    MakeRequestParameters<TRequestBody>,
    TResponse
  >(
    /* endpointUrl: */ endpointUrl,
    /* translate: */ translate,
    /* makeApiRequest: */ (args, translate) =>
      makeApiRequest({ ...args, timeoutInSeconds:generalTimeout, method }, translate, siteContext.siteId)
  );
};
