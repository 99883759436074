import React from 'react';
import styled from 'styled-components/macro';
import { fontSizeCss } from '../../../styling/design/fonts';
import { spacing4 } from '../../../styling/design/spacing';
import { textColours } from '../../../styling/design/colours';

export const fieldWarningTestId = (fieldName: string) => `field-warning-${fieldName}`;

type Props = {
  fieldName: string;
  children: React.ReactNode;
};

export const FieldWarning = ({ fieldName, children }: Props) => (
  <Container data-testid={fieldWarningTestId(fieldName)}>{children}</Container>
);

const Container = styled.div`
  display: block;
  ${fontSizeCss('xsmall')};
  margin-top: ${spacing4};
  color: ${textColours.warning};
`;
