import React, { createContext } from 'react';
import { CategoryResponse, GetDocumentsResponse } from './Documents';
import { IsoDatestamp } from '../../helpers/dateTimeHelpers';
import { GroupedSelectOptions } from '../../infrastructure/interface/forms/BaseSelect';
import { SortDirection } from '../../infrastructure/interface/tables/SortDirection';

type DocumentsPageContextValue = {
  latestResponse: GetDocumentsResponse | null;
  inProgress: boolean;
  error: string | null;

  documentCategoryId: number | null;
  setDocumentCategoryId: (documentCategoryId: number | null) => void;

  categoryResponse: CategoryResponse | null;
  setCategoryResponse: (categoryResponse: CategoryResponse | null) => void;

  employeeId: number | null;
  setEmployeeId(employeeId: number | null): void;
  
  investorId: number | null;
  setInvestorId: (investorId: number | null) => void;

  companyId: number | null;
  setCompanyId: (companyId: number | null) => void;

  runDate: IsoDatestamp | null;
  setRunDate: (runDate: IsoDatestamp | null) => void;

  inputBoundSearchString: string;
  onChangeSearchInput: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;

  resetFilters: () => void;

  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;

  resultsPerPage: number;
  setResultsPerPage: (resultsPerPage: number) => void;

  sort: string | null;
  setSort(sort: string): void;
  dir: SortDirection | null;
  setDir(dir: SortDirection): void;
  
  setFilterLoadError: (error: string) => void;

  loadData: () => void;

  documentCategoryGroupsOptions: GroupedSelectOptions<CategoryResponse>;
};

const throwNotInitialisedError = () => {
  throw new Error('Documents Page Context is not yet initialised');
};

export const DocumentsPageContext = createContext<DocumentsPageContextValue>({
  latestResponse: null,
  inProgress: false,
  error: null,
  documentCategoryId: null,
  setDocumentCategoryId: throwNotInitialisedError,
  categoryResponse: null,
  setCategoryResponse: throwNotInitialisedError,
  employeeId: null,
  setEmployeeId: throwNotInitialisedError,
  investorId: null,
  setInvestorId: throwNotInitialisedError,
  companyId: null,
  setCompanyId: throwNotInitialisedError,
  runDate: null,
  setRunDate: throwNotInitialisedError,
  inputBoundSearchString: '',
  onChangeSearchInput: throwNotInitialisedError,
  resetFilters: throwNotInitialisedError,
  pageNumber: 1,
  setPageNumber: throwNotInitialisedError,
  resultsPerPage: 1,
  setResultsPerPage: throwNotInitialisedError,
  sort: null,
  setSort: throwNotInitialisedError,
  dir: null,
  setDir: throwNotInitialisedError,
  setFilterLoadError: throwNotInitialisedError,
  loadData: throwNotInitialisedError,
  documentCategoryGroupsOptions: [],
});
