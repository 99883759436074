import React from 'react';
import { EquityAttributionSelectForTransactionsField } from '../../../metadata/equityAttribution/forTransactions/EquityAttributionSelectForTransactionsField';
import {
  dealingTransactionType,
  DealingTransactionTypeCode,
} from '../../../metadata/transactions/DealingTransactionType';
import {
  EquityAttributionSelectForSwitchToTransactionsSettings
} from '../../../metadata/equityAttribution/forTransactions/EquityAttributionSelectForTransactionsSettings';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';

type Props = {
  transactionType: DealingTransactionTypeCode;
  settings: EquityAttributionSelectForSwitchToTransactionsSettings;
  onError: (error: string) => void;
};

export const PlaceTransactionSwitchToShareClassSeriesField = ({
  transactionType,
  settings,
  onError,
}: Props) => {
  const { translate } = useInternationalisation();

  return shouldShowSwitchToShareClassSeriesField(transactionType) ? (
    <EquityAttributionSelectForTransactionsField
      data-testid={placeTransactionSwitchToShareClassSeriesSelectTestId}
      fieldName="switchToEquityAttributionId"
      label={translate('pages.placeTransaction.labels.switchToShareClassSeries')}
      onError={onError}
      settings={settings}
    />
  ) : null;
};

export const shouldShowSwitchToShareClassSeriesField = (
  transactionType: DealingTransactionTypeCode
) => {
  const validTypes: Array<DealingTransactionTypeCode> = [
    dealingTransactionType.Switch,
    dealingTransactionType.SwitchFull,
  ];

  return validTypes.includes(transactionType);
};

export const placeTransactionSwitchToShareClassSeriesSelectTestId =
  'place-transaction-switch-to-share-class-series-select';
