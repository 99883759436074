import React, { useEffect, useState } from 'react';
import { InputField } from '../../../../infrastructure/forms/fields/InputField';
import styled, { css } from 'styled-components/macro';
import { onDesktop, onMobile } from '../../../../styling/layout/screenBreakpoints';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { spacing16 } from '../../../../styling/design/spacing';
import { BookIcon } from '../../../../icons/icons';
import { BankSearchModal, BankSearchResult } from '../../../search/BankSearchModal';
import { FormikValues, useFormikContext } from 'formik';
import { usePrevious } from '../../../../infrastructure/hooks/usePrevious';

type Props = {
  label?: string;
  bankIdFieldName: string;
  bankNameFieldName: string;
  swiftCodeFieldName: string;
  currencyIdFieldName: string;
  readonly?: boolean;
  bankNameInputTestId?: string;
  swiftCodeInputTestId?: string;
};

export const BankInputField = ({
  label,
  bankIdFieldName,
  bankNameFieldName,
  swiftCodeFieldName,
  currencyIdFieldName,
  readonly,
  bankNameInputTestId,
  swiftCodeInputTestId,
}: Props) => {
  const { translate } = useInternationalisation();

  const formikContext = useFormikContext<FormikValues>();
  const currencyId = formikContext.values[currencyIdFieldName];
  const previousCurrencyId = usePrevious(currencyId);

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const [cachedSearchResult, setCachedSearchResult] = useState<BankSearchResult | null>(null);

  useEffect(() => {
    if (previousCurrencyId != null && currencyId !== previousCurrencyId) {
      clearSearchResultValues();
      formikContext.setFieldValue(bankNameFieldName, '');
    }
  }, [currencyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearSearchResultValues = () => {
    setCachedSearchResult(null);
    formikContext.setFieldValue(bankIdFieldName, null);
    formikContext.setFieldValue(swiftCodeFieldName, '');
  };

  const onInputChange = (value: string) => {
    if (
      value === formikContext.initialValues[bankNameFieldName] &&
      formikContext.values[swiftCodeFieldName] === formikContext.initialValues[swiftCodeFieldName]
    ) {
      //initial setting of values so don't clear them
      return;
    }

    if (value !== cachedSearchResult?.bankName) {
      // Bank is manually typed, so clear the search result values
      clearSearchResultValues();
    }
  };

  const onSelectBankId = (searchResult: BankSearchResult) => {
    setCachedSearchResult(searchResult);
    formikContext.setFieldValue(bankIdFieldName, searchResult.counterpartId);
    formikContext.setFieldValue(bankNameFieldName, searchResult.bankName);
    formikContext.setFieldValue(swiftCodeFieldName, searchResult.externalCode);
    setIsSearchModalOpen(false);
  };

  return (
    <>
      <BankNameWithSwiftContainer>
        <InputField
          data-testid={bankNameInputTestId ?? bankInputFieldTestId}
          label={label}
          fieldName={bankNameFieldName}
          onFieldValueChange={onInputChange}
          icon={{
            icon: <BookIcon />,
            onClick: () => setIsSearchModalOpen(true),
            disabled: currencyId == null || readonly,
          }}
          readOnly={readonly}
        />
        <SwiftCodeInputField
          data-testid={swiftCodeInputTestId ?? bankInputSwiftCodeInputTestId}
          label={translate('pages.bankingDetails.createEdit.labels.swiftCode')}
          fieldName={swiftCodeFieldName}
          readOnly={true}
          tabIndex={-1}
        />
      </BankNameWithSwiftContainer>
      {isSearchModalOpen && currencyId != null && (
        <BankSearchModal
          isOpen={isSearchModalOpen}
          onRequestClose={() => setIsSearchModalOpen(false)}
          currencyId={currencyId}
          onSelectResult={onSelectBankId}
        />
      )}
    </>
  );
};

const BankNameWithSwiftContainer = styled.div`
  display: flex;

  ${onDesktop(css`
    flex-direction: row;
    justify-content: space-between;

    > * {
      flex-grow: 1;
    }
  `)}

  ${onMobile(css`
    flex-direction: column;
  `)}
`;

const SwiftCodeInputField = styled(InputField)`
  ${onDesktop(css`
    margin-left: ${spacing16};
    max-width: 200px;
  `)}

  ${onMobile(css`
    margin-bottom: ${spacing16};
  `)};
`;

export const bankInputFieldTestId = 'bank-input-field';
export const bankInputSwiftCodeInputTestId = 'bank-input-swift-code-input';
