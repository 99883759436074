import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { AuthenticationResult } from '../AuthenticationResult';
import styled from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';

type Props = {
  authenticationResult: AuthenticationResult;
  className?: string;
};

export const MultiFactorAuthenticationPrompt = ({ authenticationResult, className }: Props) => {
  const { translate } = useInternationalisation();

  let message = '';

  switch (authenticationResult) {
    case 'EmailToSmsMfaRequired':
      message = translate('authentication.emailToSmsMfaPrompt');
      break;
    case 'CitiMfaRequired':
      message = translate('authentication.citiMfaPrompt');
      break;
    case 'TwilioAuthyMfaRequired':
      message = translate('authentication.twilioAuthy.mfaPrompt');
      break;
    case 'GoogleAuthenticatorMfaRequired':
      message = translate('authentication.google.mfaPrompt');
      break;
    default:
      throw new Error(`Invalid authentication result for MFA prompt: ${authenticationResult}`);
  }

  return <Prompt className={className}>{message}</Prompt>;
};

const Prompt = styled.div`
  margin-top: 0;
  margin-bottom: ${spacing16};
`;
