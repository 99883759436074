import { useGetJson } from '../../../infrastructure/api/useGetJson';

import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { useDashboardFilters } from '../DashboardFiltersContext';
import { useEffect } from 'react';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { Table, TBody, Th, Td, Tr } from '../../../infrastructure/interface/tables/Table';
import { AppLink } from '../../../infrastructure/interface/components/AppLink';
import { ComponentResponse } from '../DashboardComponent';

export const TopTenInvestorsComponentName = 'Top 10 Investors';
export const TopTenInvestors = () => {
  const endpointUrl = 'api/dashboards/GetDataForTop10InvestorsTable';
  const getRequest = useGetJson<GetTopTenInvestorsQuery, GetTopTenInvestorsResponse>(endpointUrl);

  const { companyId, fromDate } = useDashboardFilters();
  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <PrivateTopTenInvestorsComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetTopTenInvestorsResponse;
  showLoadingOverlay: boolean;
};

const PrivateTopTenInvestorsComponent = ({ response, showLoadingOverlay }: ComponentProps) => {
  const { translate } = useInternationalisation();
  const { companyId } = useDashboardFilters();

  return (
    <DashboardComponentLayout
      headerText={translate('pages.dashboard.components.topTenInvestors.title')}
      showLoadingOverlay={showLoadingOverlay}
      showNoDataMessage={response.noDataMessage}
    >
      <Table compact={true}>
        <TBody>
          <Tr>
            <Th>
              {translate('pages.dashboard.components.topTenInvestors.headerNames.investorName')}
            </Th>
            <Th>{translate('pages.dashboard.components.topTenInvestors.headerNames.value')}</Th>
          </Tr>

          {response.investorRows.map((row, index) => (
            <Tr key={index}>
              <Td>
                {row.shareholderId != null ? (
                  <AppLink
                    to={`/investor-details/${row.shareholderId}                
                        `}
                  >
                    {row.investorName}
                  </AppLink>
                ) : (
                  row.investorName
                )}
              </Td>
              <Td>{row.value}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </DashboardComponentLayout>
  );
};

type GetTopTenInvestorsQuery = {
  companyId: number;
  runDate: IsoDatestamp;
};

type GetTopTenInvestorsResponse = ComponentResponse & {
  investorRows: Array<InvestorRow>;
};

type InvestorRow = {
  investorName: string | null;
  value: string | null;
  shareholderId: number | null;
};
