import React from 'react';
import {
  AddressBookIcon,
  BookIcon,
  BuildingColumnsIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckSolidIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CircleSolidIcon,
  ClockIcon,
  CogIcon,
  CompressIcon,
  DashboardIcon,
  DeleteIcon,
  DocumentsIcon,
  DownloadIcon,
  EllipsisIcon,
  EnvelopeSolidIcon,
  ExcelFileIcon,
  ExclamationCircleIcon,
  ExpandIcon,
  FileIcon,
  FilterIcon,
  FloppyDiskIcon,
  HoldingsIcon,
  ImageFileIcon,
  InfoCircleSolidIcon,
  KeyIcon,
  LockClosedIcon,
  LockOpenIcon,
  MenuIcon,
  MoneyWithArrowsIcon,
  NotificationsIcon,
  PdfFileIcon,
  PencilIcon,
  PieChartIcon,
  PiggyBankIcon,
  PlusIcon,
  PowerPointFileIcon,
  ReportsIcon,
  ShieldIcon,
  SignOutIcon,
  SortDownIcon,
  SortIcon,
  SortUpIcon,
  TimesCircleSolidIcon,
  TimesSolidIcon,
  TransactionsIcon,
  UploadIcon,
  UserCircleSolidIcon,
  UserIcon,
  UserSlashIcon,
  WordFileIcon,
} from './icons';

const IconMap: any = {
  AddressBook: AddressBookIcon,
  Book: BookIcon,
  BuildingColumns: BuildingColumnsIcon,
  Calendar: CalendarIcon,
  CheckCircle: CheckCircleIcon,
  CheckSolid: CheckSolidIcon,
  ChevronDown: ChevronDownIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  ChevronUp: ChevronUpIcon,
  CircleSolid: CircleSolidIcon,
  Clock: ClockIcon,
  Cog: CogIcon,
  Compress: CompressIcon,
  Dashboard: DashboardIcon,
  Delete: DeleteIcon,
  Documents: DocumentsIcon,
  Download: DownloadIcon,
  Ellipsis: EllipsisIcon,
  EnvelopeSolid: EnvelopeSolidIcon,
  ExclamationCircle: ExclamationCircleIcon,
  Expand: ExpandIcon,
  ExcelFile: ExcelFileIcon,
  File: FileIcon,
  Filter: FilterIcon,
  FloppyDisk: FloppyDiskIcon,
  Holdings: HoldingsIcon,
  ImageFile: ImageFileIcon,
  InfoCircleSolid: InfoCircleSolidIcon,
  Key: KeyIcon,
  LockClosed: LockClosedIcon,
  LockOpen: LockOpenIcon,
  Menu: MenuIcon,
  MoneyWithArrows: MoneyWithArrowsIcon,
  Notifications: NotificationsIcon,
  PdfFile: PdfFileIcon,
  Pencil: PencilIcon,
  PieChart: PieChartIcon,
  PiggyBank: PiggyBankIcon,
  Plus: PlusIcon,
  PowerPointFile: PowerPointFileIcon,
  Reports: ReportsIcon,
  SignOut: SignOutIcon,
  Shield: ShieldIcon,
  SortDown: SortDownIcon,
  Sort: SortIcon,
  SortUp: SortUpIcon,
  TimesCircleSolid: TimesCircleSolidIcon,
  TimesSolid: TimesSolidIcon,
  Transactions: TransactionsIcon,
  Upload: UploadIcon,
  UserCircleSolid: UserCircleSolidIcon,
  User: UserIcon,
  UserSlash: UserSlashIcon,
  WordFile: WordFileIcon,
};
export const DynamicIcon = (props: { name: string }) => {
  return React.createElement(IconMap[props.name], {});
};
