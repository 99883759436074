import React, { useContext } from 'react';
import { ReportParameterResponse } from '../../../Report';
import { useReportParameterFieldsContext } from '../../ReportParameterFieldsContext';
import { GeneralLedgerCounterpartSelectField } from '../../../../metadata/generalLedger/counterpart/GeneralLedgerCounterpartSelectField';
import { ReportContext } from '../../../ReportContext';

type Props = {
  generalLedgerCounterpartReportParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsGeneralLedgerCounterpartSelectField = ({
  generalLedgerCounterpartReportParameter,
  onError,
}: Props) => {
  const { setFieldLoaded } = useReportParameterFieldsContext();
  const { selectedCompanyOption, selectedCompanyReportGroupOption } = useContext(ReportContext);

  return (
    <GeneralLedgerCounterpartSelectField
      fieldName={generalLedgerCounterpartReportParameter.name}
      label={generalLedgerCounterpartReportParameter.displayText}
      onError={onError}
      settings={{
        companyId: selectedCompanyOption?.companyId ?? null,
        companyReportGroupId: selectedCompanyReportGroupOption?.companyReportGroupId ?? null,
        includeAll: true,
        includeBlank: false,
      }}
      defaultToFirstOption={true}
      onLoaded={() => setFieldLoaded(generalLedgerCounterpartReportParameter.name)}
    />
  );
};
