import { useDeleteJson } from '../../../infrastructure/api/useDeleteJson';
import { useIsOpen } from '../../../infrastructure/hooks/useIsOpen';
import { ButtonSize } from '../../../infrastructure/interface/buttons/BaseButton';
import { DeleteButton } from '../../../infrastructure/interface/buttons/DeleteButton';
import { ActionAlert } from '../../../infrastructure/interface/components/ActionAlert';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { useInvestorIdUrlParam } from '../useInvestorIdUrlParam';
import { DistributionReinvestmentActionResponse } from './DistributionReinvestmentActionResponse';
import { useDistributionReinvestmentsContext } from './DistributionReinvestmentsContext';

type Props = {
  size: ButtonSize;
  distributionReinvestmentId: number;
};

export const DeleteDistributionReinvestmentButton = (props: Props) => {
  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();
  const confirmationAlertState = useIsOpen(false);

  const {
    permissionsResponse,
    setDeleteDistributionReinvestmentResponse,
    makeDistributionReinvestmentsRequest,
  } = useDistributionReinvestmentsContext();

  const deleteRequest = useDeleteJson<
    DeleteDistributionReinvestmentCommand,
    DistributionReinvestmentActionResponse
  >('/api/distribution-reinvestments/DeleteDistributionReinvestment');

  const makeRequest = () => {
    deleteRequest.makeRequest({
      requestBody: {
        distributionReinvestmentId: props.distributionReinvestmentId,
        counterpartId: investorId,
      },
      onSuccess: (response) => {
        setDeleteDistributionReinvestmentResponse(response);
        makeDistributionReinvestmentsRequest();
        confirmationAlertState.close();
      },
    });
  };

  const onDeleteButtonClick = () => {
    setDeleteDistributionReinvestmentResponse(null);
    confirmationAlertState.open();
  };

  if (permissionsResponse?.canEditDistributionReinvestments !== true) {
    return null;
  }

  return (
    <>
      <DeleteButton size={props.size} onClick={onDeleteButtonClick} />
      <ActionAlert
        alertType="warning"
        isOpen={confirmationAlertState.isOpen}
        title={translate('pages.distributionReinvestments.deleteConfirmationModal.title')}
        message={translate('pages.distributionReinvestments.deleteConfirmationModal.message')}
        cancelButtonText={translate('actionButtons.cancel')}
        onRequestClose={confirmationAlertState.close}
        confirmButtonText={translate('actionButtons.delete')}
        confirmButtonTestId={confirmDeleteDistributionReinvestmentButtonTestId}
        onInitiateAction={makeRequest}
        actionInProgress={deleteRequest.state.inProgress}
        actionError={deleteRequest.state.error}
      />
    </>
  );
};

export const confirmDeleteDistributionReinvestmentButtonTestId =
  'confirm-delete-distribution-reinvestment-button';

export type DeleteDistributionReinvestmentCommand = {
  distributionReinvestmentId: number;
  counterpartId: number | null;
};
