import React from 'react';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { CreateBankingDetailsLink } from './createEdit/create/CreateBankingDetailsLink';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { BackButton } from '../../../infrastructure/interface/components/BackButton';
import styled from 'styled-components/macro';
import { spacing12, spacing48, spacing8 } from '../../../styling/design/spacing';
import { fontSizes, fontWeightBold } from '../../../styling/design/fonts';
import { InvestorDetailsCounterpartDropdownOption } from '../GetInvestorDetailsCounterpartDropdownOptions';
import { InvestorDetailsCounterpartDropdown } from '../InvestorDetailsCounterpartDropdown';
import { useInvestorIdUrlParam } from '../useInvestorIdUrlParam';

type Props = {
  investorOptions: Array<InvestorDetailsCounterpartDropdownOption>;
  onInvestorIdChange: (newInvestorId: number | null) => void;
};

export const InvestorBankingDetailsHeader = (props: Props) => (
  <>
    <DesktopOnly>
      <DesktopHeader {...props} />
    </DesktopOnly>
    <MobileOnly>
      <MobileHeader {...props} />
    </MobileOnly>
  </>
);

const DesktopHeader = ({ investorOptions, onInvestorIdChange }: Props) => {
  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();

  return (
    <>
      <DesktopHeaderContainer>
        <DesktopTitleContainer>
          <DesktopTitlePrefix>{translate('pages.bankingDetails.headerPrefix')}</DesktopTitlePrefix>
          <InvestorDetailsCounterpartDropdown
            options={investorOptions}
            onChange={onInvestorIdChange}
            value={investorId}
          />
        </DesktopTitleContainer>
        <DesktopActionsContainer>
          <CreateBankingDetailsLink />
        </DesktopActionsContainer>
      </DesktopHeaderContainer>
      <BackButton />
    </>
  );
};

const DesktopHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing8};
`;

const DesktopTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitlePrefix = styled.div`
  font-weight: ${fontWeightBold};
  white-space: nowrap;
  margin-right: ${spacing8};
`;

const DesktopTitlePrefix = styled(TitlePrefix)`
  font-size: ${fontSizes.xxlarge.desktop};
`;

const DesktopActionsContainer = styled.div``;

const MobileHeader = ({ investorOptions, onInvestorIdChange }: Props) => {
  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();

  return (
    <>
      <MobileHeaderContainer>
        <MobileTitlePrefix>{translate('pages.bankingDetails.headerPrefix')}</MobileTitlePrefix>
        <InvestorDetailsCounterpartDropdown
          options={investorOptions}
          onChange={onInvestorIdChange}
          value={investorId}
        />
      </MobileHeaderContainer>
      <BackButton />
      <MobileActionsContainer>
        <CreateBankingDetailsLink />
      </MobileActionsContainer>
    </>
  );
};

const MobileHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing12};
`;

const MobileTitlePrefix = styled(TitlePrefix)`
  font-size: ${fontSizes.large.mobile};
`;

const MobileActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${spacing48} 0;
`;
