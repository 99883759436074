import React, { useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import {
  BaseSelect,
  BaseSelectProps,
  GroupedSelectOptions,
  isGroupedOptions,
  SelectOption,
  SelectOptions,
} from './BaseSelect';
import {
  applyCustomStylesToDefaults,
  BaseSelectCustomStyleOptions,
  BaseSelectStylesConfig,
  BaseSelectStylesConfigFactory,
  getCustomBaseSelectStyles,
} from './baseSelectStyles';

export const singleSelectTestId = 'single-select';

export type SingleSelectProps<TValue extends unknown> = BaseSelectProps<TValue, false> & {
  value: TValue | null;
  onChange: (newValue: TValue | null) => void;
};

export const SingleSelect = <TValue extends unknown>(props: SingleSelectProps<TValue>) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption<TValue> | null>(() =>
    getOptionFromValue(props.value, props.options)
  );

  useEffect(() => {
    setSelectedOption(getOptionFromValue(props.value, props.options));
  }, [props.value, props.options]);

  const onChange = (newOption: OnChangeValue<SelectOption<TValue>, false>) => {
    props.onChange(newOption?.value ?? null);
  };

  return (
    <BaseSelect
      {...props}
      data-testid={props['data-testid'] ?? singleSelectTestId}
      customStyles={props.customStyles ?? defaultSingleSelectStyles<TValue>()}
      isMulti={false}
      selectedOption={selectedOption}
      onChange={onChange}
    />
  );
};

const getOptionFromValue = <TValue extends unknown>(
  value: TValue | null,
  options: SelectOptions<TValue> | GroupedSelectOptions<TValue>
): SelectOption<TValue> | null => {
  if (isGroupedOptions(options)) {
    const groupedOptions = options as GroupedSelectOptions<TValue>;
    const allOptionsFromGroups: SelectOptions<TValue> = groupedOptions.flatMap(
      (group) => group.options
    );
    return allOptionsFromGroups.find((option) => option.value === value) ?? null;
  }
  return options.find((option) => option.value === value) ?? null;
};

export type SingleSelectCustomStyleOptions<TValue extends unknown> = BaseSelectCustomStyleOptions<
  TValue,
  false
>;

export type SingleSelectStylesConfig<TValue extends unknown> = BaseSelectStylesConfig<
  TValue,
  false
>;

export type SingleSelectStylesConfigFactory<TValue> = BaseSelectStylesConfigFactory<TValue, false>;

const defaultSingleSelectStyles = <TValue extends unknown>() =>
  getCustomBaseSelectStyles<TValue, false>({});

export const getCustomSingleSelectStyles =
  <TValue extends unknown>(
    customStyles: SingleSelectCustomStyleOptions<TValue>
  ): SingleSelectStylesConfigFactory<TValue> =>
  (highlight?: boolean): SingleSelectStylesConfig<TValue> =>
    applyCustomStylesToDefaults(
      customStyles,
      defaultSingleSelectStyles<TValue>()(highlight),
      highlight
    );
