import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useDashboardFilters } from '../DashboardFiltersContext';
import React, { ReactNode, useEffect, useState } from 'react';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import {
  IsoDatestamp,
  toDateFromIsoDatestamp,
  toIsoDatestampFromDate,
} from '../../../helpers/dateTimeHelpers';
import {
  EquityAttributionSelect,
  EquityAttributionSelectOptionValue,
} from '../../metadata/equityAttribution/EquityAttributionSelect';
import { mapEquityAttributionDropdownOptionsResponseToSelectOptions } from '../../metadata/equityAttribution/GetEquityAttributionDropdownOptionsResponse';
import { InvalidEquityAttributionId } from '../../metadata/idConstants';
import { FieldError } from '../../../infrastructure/forms/common/FieldError';
import { ComponentResponse } from '../DashboardComponent';
import {
  MultiLineGraph,
  MultiLineGraphDataPoint,
} from '../../../infrastructure/charts/MultiLineGraph';
import { Checkbox } from '../../../infrastructure/interface/forms/Checkbox';
import { FieldLabel } from '../../../infrastructure/forms/common/FieldLabel';
import styled from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';

export const fundPerformanceGraphComponentName = 'Fund Performance';

export const FundPerformanceGraph = () => {
  const getRequest = useGetJson<
    GetDataForFundPerformanceGraphQuery,
    GetDataForFundPerformanceGraphResponse
  >('api/dashboards/GetDataForFundPerformanceGraph');

  const { companyId, fromDate } = useDashboardFilters();
  const [equityAttributionId, setEquityAttributionId] =
    useState<EquityAttributionSelectOptionValue>(null as EquityAttributionSelectOptionValue);
  const [selectError, setSelectError] = useState<string | null>(null);
  const [investorInception, setInvestorInception] = useState<boolean>(false);

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
          equityAttributionId: equityAttributionId ?? InvalidEquityAttributionId,
          investorInception: investorInception,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate, equityAttributionId, investorInception]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <FundPerformanceGraphComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
          equityAttributionId={equityAttributionId}
          companyId={companyId}
          setEquityAttributionId={setEquityAttributionId}
          selectError={selectError}
          setSelectError={setSelectError}
          investorInception={investorInception}
          setInvestorInception={setInvestorInception}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForFundPerformanceGraphResponse;
  showLoadingOverlay: boolean;
  equityAttributionId: EquityAttributionSelectOptionValue;
  setEquityAttributionId: (value: EquityAttributionSelectOptionValue) => void;
  companyId: number | null;
  selectError: string | null;
  setSelectError: (value: string | null) => void;
  investorInception: boolean;
  setInvestorInception: (value: boolean) => void;
};

const FundPerformanceGraphComponent = (props: ComponentProps) => {
  const { translate, formatNumber, formatDate } = useInternationalisation();

  const PerformanceData: Array<MultiLineGraphDataPoint> = props.response.performanceDataPoints.map(
    (dataPoint) => ({
      x: toDateFromIsoDatestamp(dataPoint.date),
      y: dataPoint.value,
    })
  );

  const Benchmark1GraphData: Array<MultiLineGraphDataPoint> =
    props.response.benchmark1DataPoints.map((dataPoint) => ({
      x: toDateFromIsoDatestamp(dataPoint.date),
      y: dataPoint.value,
    }));

  const Benchmark2GraphData: Array<MultiLineGraphDataPoint> =
    props.response.benchmark2DataPoints.map((dataPoint) => ({
      x: toDateFromIsoDatestamp(dataPoint.date),
      y: dataPoint.value,
    }));

  // get performance name from first raw ea-code column
  const [performanceNameFirstElement] = props.response.performanceDataPoints;
  const performanceName =
    typeof performanceNameFirstElement != 'undefined' ? performanceNameFirstElement.name : '';

  //get Benchmark1 name  form first raw benchmark_1_sname column
  const [Benchmark1NameFirstElement] = props.response.benchmark1DataPoints;
  const Benchmark1Name =
    typeof Benchmark1NameFirstElement != 'undefined' ? Benchmark1NameFirstElement.name : '';

  //get Benchmark2 name from first raw benchmark_2_sname column
  const [Benchmark2NameFirstElement] = props.response.benchmark2DataPoints;
  const Benchmark2Name =
    typeof Benchmark2NameFirstElement != 'undefined' ? Benchmark2NameFirstElement.name : '';

  const filterSetContent: ReactNode = (
    <FilterSetContainer>
      <EquityAttributionSelect
        settings={{
          employeeCounterpartId: null,
          companyId: props.companyId,
          includeBlank: false,
        }}
        value={props.equityAttributionId}
        onChange={props.setEquityAttributionId}
        onError={props.setSelectError}
        onLoaded={(response) => {
          props.setSelectError(null);
          props.setEquityAttributionId(
            mapEquityAttributionDropdownOptionsResponseToSelectOptions(response)[0]?.value ?? null
          );
        }}
      />
      {props.selectError && (
        <FieldError fieldName={'FundPerformanceGraph'} children={props.selectError} />
      )}
      <FieldLabel>
        {translate('pages.dashboard.components.fundPerformanceGraph.filterType.investorInception')}
      </FieldLabel>
      <Checkbox
        checked={props.investorInception ?? false}
        onChange={props.setInvestorInception}
        fieldAlignment={'column'}
      />
    </FilterSetContainer>
  );

  return (
    <DashboardComponentLayout
      headerText={translate('pages.dashboard.components.fundPerformanceGraph.title')}
      showLoadingOverlay={props.showLoadingOverlay}
      filterSet={filterSetContent}
      showNoDataMessage={props.response.noDataMessage}
      headerOrientation={'column'}
    >
      <MultiLineGraph
        exportFilename={translate('pages.dashboard.components.fundPerformanceGraph.exportName')}
        companyID={props.companyId}
        dataPoints={PerformanceData}
        dataPoints1={Benchmark1GraphData}
        dataPoints2={Benchmark2GraphData}
        dataPointsName={performanceName}
        dataPoints1Name={Benchmark1Name}
        dataPoints2Name={Benchmark2Name}
        height="stretch"
        xAxisType="datetime"
        yAxisOptions={{
          labels: {
            formatter() {
              return `${this.value}`;
            },
          },
        }}
        tooltipOptions={{
          headerFormat: '',
          pointFormatter() {
            const formattedX = formatDate(toIsoDatestampFromDate(new Date(this.x)));
            const formattedY =
              this.y === undefined ? '' : formatNumber(this.y, { decimalPlaces: 2 });
            return `<b>${formattedX}</b><br/>${formattedY}%`;
          },
        }}
        fundComponent={true}
      />
    </DashboardComponentLayout>
  );
};

type GetDataForFundPerformanceGraphQuery = {
  companyId: number;
  runDate: string;
  equityAttributionId: number;
  investorInception: boolean;
};

export type GetDataForFundPerformanceGraphResponse = ComponentResponse & {
  performanceDataPoints: Array<{
    name: string;
    value: number;
    date: IsoDatestamp;
  }>;
  benchmark1DataPoints: Array<{
    name: string;
    value: number;
    date: IsoDatestamp;
  }>;
  benchmark2DataPoints: Array<{
    name: string;
    value: number;
    date: IsoDatestamp;
  }>;
};

const FilterSetContainer = styled.div`
  display: flex;
  gap: ${spacing16};
  align-items: center;
`;
