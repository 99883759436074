import { SuccessResponse } from './SuccessResponse';
import { cancelledRequestErrorMessage, ErrorResponse } from './ErrorResponse';

export type ApiResponse<TResponse> = SuccessResponse<TResponse> | ErrorResponse;

export const isSuccessResponse = <TResponse>(
  response: ApiResponse<TResponse>
): response is SuccessResponse<TResponse> => response.success;

export const isErrorResponse = <TResponse>(
  response: ApiResponse<TResponse>
): response is ErrorResponse => !response.success;

export const isCancelledRequestResponse = <TResponse>(response: ApiResponse<TResponse>) =>
  isErrorResponse(response) && response.error === cancelledRequestErrorMessage;
