import { Header1 } from '../../../infrastructure/interface/components/Headers';
import { PageHeaderActions } from '../../../infrastructure/interface/components/PageHeaderActions';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import {
  Table,
  TableLoadingOverlay,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../../infrastructure/interface/tables/Table';
import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { IfUserHasRole } from '../../authentication/UserRoles';
import styled from 'styled-components/macro';
import { spacing24, spacing8 } from '../../../styling/design/spacing';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { GetBulkTransactionsDetailsResponse } from './useGetBulkTransactionsDetailsRequest';
import { transactionLockCode } from '../../metadata/transactions/TransactionLock';

export type DesktopConfirmationUploadTransactionsProps = {
  transactionsDetails: GetBulkTransactionsDetailsResponse;
};

export const DesktopConfirmUploadTransactions = ({
  transactionsDetails,
}: DesktopConfirmationUploadTransactionsProps) => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.confirmTransaction.title'));

  return (
    <>
      <Header1>{translate('pages.uploadTransactions.confirmation.header')}</Header1>
      <PageHeaderActions withBackButton={true}></PageHeaderActions>
      <Panel>
        <TextContainer>{translate('pages.uploadTransactions.confirmation.text')}</TextContainer>
        <TableLoadingOverlay showOverlay={false}>
          <Table>
            <UploadConfirmationTableHeaderComponent transactionsDetails={transactionsDetails} />
            <UploadConfirmationTableBodyComponent transactionsDetails={transactionsDetails} />
          </Table>
        </TableLoadingOverlay>
      </Panel>
    </>
  );
};

const UploadConfirmationTableHeaderComponent = ({
  transactionsDetails,
}: UploadConfirmationTableProps) => {
  const { translate } = useInternationalisation();

  return (
    <THead>
      <Tr>
        <Th>{translate('pages.uploadTransactions.confirmation.transaction.fileRow')}</Th>
        <IfUserHasRole userRole={['Advisor', 'Consolidated Investor']}>
          <Th>{translate('pages.uploadTransactions.confirmation.transaction.investor')}</Th>
        </IfUserHasRole>
        <Th>{translate('pages.uploadTransactions.confirmation.transaction.fund')}</Th>
        <Th>{translate('pages.uploadTransactions.confirmation.transaction.transactionType')}</Th>
        <Th>{translate('pages.uploadTransactions.confirmation.transaction.dealingDate')}</Th>
        <Th>{translate('pages.uploadTransactions.confirmation.transaction.shareClassOrSeries')}</Th>
        <Th>{translate('pages.uploadTransactions.confirmation.transaction.lock')}</Th>
        <Th>{translate('pages.uploadTransactions.confirmation.transaction.amountOrUnits')}</Th>
        {transactionsDetails.transactions.some((transaction) => transaction.feesString !== '') && (
          <Th>{translate('pages.uploadTransactions.confirmation.transaction.fees')}</Th>
        )}
        <Th>{translate('pages.uploadTransactions.confirmation.transaction.switchToFund')}</Th>
        <Th>
          {translate(
            'pages.uploadTransactions.confirmation.transaction.switchToShareClassOrSeries'
          )}
        </Th>
        <Th>{translate('pages.uploadTransactions.confirmation.transaction.yourReference')}</Th>
      </Tr>
    </THead>
  );
};

type UploadConfirmationTableProps = {
  transactionsDetails: GetBulkTransactionsDetailsResponse;
};

const UploadConfirmationTableBodyComponent = ({
  transactionsDetails,
}: UploadConfirmationTableProps) => {
  const { formatDate, formatNumberOrDefault } = useInternationalisation();

  return (
    <TBody>
      {transactionsDetails.transactions?.map((transaction, index) => (
        <Tr>
          <Td>{index + 2}</Td>
          <IfUserHasRole userRole={['Advisor', 'Consolidated Investor']}>
            <Td>{transaction.investorName}</Td>
          </IfUserHasRole>
          <Td>{transaction.fundName}</Td>
          <Td>{transaction.transactionTypeCode}</Td>
          <Td>{formatDate(transaction.dealingDate)}</Td>
          <Td>{transaction.shareClassSeries}</Td>
          <Td>{transaction.transactionLock}</Td>
          <Td>
            {transaction.transactionLock === transactionLockCode.Amount
              ? formatNumberOrDefault(transaction.amount)
              : formatNumberOrDefault(transaction.shares)}
          </Td>
          {transactionsDetails.transactions.some(
            (transaction) => transaction.feesString !== ''
          ) && <Td>{transaction.feesString}</Td>}
          <Td>{transaction.companySwitch}</Td>
          <Td>{transaction.equityAttributionSwitch}</Td>
          <Td>{transaction.transactionReference}</Td>
        </Tr>
      ))}
    </TBody>
  );
};

const TextContainer = styled.div`
  margin-top: ${spacing8};
  margin-bottom: ${spacing24};
`;
