import { keyframes } from 'styled-components/macro';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;
