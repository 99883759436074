import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import {
  cannotMapSettingsToValidQueryParameters,
  MetadataSingleSelect,
} from '../MetadataSingleSelect';
import {
  GetClearingCodeTypeDropdownOptionsResponse,
  mapClearingCodeTypeDropdownOptionsResponseToSelectOptions,
} from './GetClearingCodeTypeDropdownOptionsResponse';
import { ClearingCodeTypeSelectSettings } from './ClearingCodeTypeSelectSettings';
import { assertNotNull } from '../../../helpers/nullHelpers';

export type ClearingCodeTypeSelectOptionValue = number | null;

export type ClearingCodeTypeSelectProps = MetadataSingleSelectComponentProps<
  ClearingCodeTypeSelectOptionValue,
  ClearingCodeTypeSelectSettings,
  GetClearingCodeTypeDropdownOptionsResponse
>;

export const ClearingCodeTypeSelect = (props: ClearingCodeTypeSelectProps) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.clearingCodeTypeSelect.placeholder');
  const loadingPlaceholder = translate('metadata.clearingCodeTypeSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      ClearingCodeTypeSelectOptionValue,
      ClearingCodeTypeSelectSettings,
      GetClearingCodeTypeDropdownOptionsQuery,
      GetClearingCodeTypeDropdownOptionsResponse
    >
      {...props}
      endpointUrl="/api/metadata/GetClearingCodeTypeDropdownOptions"
      mapSettingsToQueryParameters={({ currencyId, ...otherSettings }) =>
        currencyId == null
          ? cannotMapSettingsToValidQueryParameters
          : {
              ...otherSettings,
              currencyId: assertNotNull(currencyId, 'settings.currencyId'),
            }
      }
      mapResponseToOptions={mapClearingCodeTypeDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={200}
    />
  );
};

type GetClearingCodeTypeDropdownOptionsQuery = {
  currencyId: number;
  includeBlank: boolean;
};
