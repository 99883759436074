import { useState } from 'react';
import {
  LocalBankDetailsDefaultCode,
  localBankDetailsDefaultCodesByName,
} from '../LocalBankDetailsDefault';

export const useLocalClearingDetails = () => {
  const [localClearingDetailsOnly, setLocalClearingDetailsOnly] = useState(false);
  const [showLocalClearingDetailsToggle, setShowLocalClearingDetailsToggle] = useState(true);

  const updateLocalClearingSettingsFromDefaultCode = (defaultCode: LocalBankDetailsDefaultCode) => {
    switch (defaultCode) {
      case localBankDetailsDefaultCodesByName.Full:
        setLocalClearingDetailsOnly(false);
        setShowLocalClearingDetailsToggle(true);
        break;
      case localBankDetailsDefaultCodesByName.LocalEdit:
        setLocalClearingDetailsOnly(true);
        setShowLocalClearingDetailsToggle(true);
        break;
      case localBankDetailsDefaultCodesByName.LocalRestrict:
        setLocalClearingDetailsOnly(true);
        setShowLocalClearingDetailsToggle(false);
        break;
      default:
        throw new Error(
          `Encountered unrecognised LocalBankDetailsDefaultCode value: ${defaultCode}`
        );
    }
  };

  return {
    localClearingDetailsOnly,
    setLocalClearingDetailsOnly,
    showLocalClearingDetailsToggle,
    updateLocalClearingSettingsFromDefaultCode,
  };
};
