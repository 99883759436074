import { FormikValues, useField, useFormikContext } from 'formik';
import styled from 'styled-components/macro';
import { LockClosedIcon, LockOpenIcon } from '../../../icons/icons';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { colourBlack, colourGrey02 } from '../../../styling/design/colours';
import { spacing24, spacing4 } from '../../../styling/design/spacing';

type Props = {
  fieldName: string;
};

export const LockedOutField = ({ fieldName }: Props) => {
  const { translate } = useInternationalisation();
  const [field] = useField<boolean>(fieldName);
  const formikContext = useFormikContext<FormikValues>();

  const isInitiallyLockedOut = formikContext.initialValues[fieldName];

  const onIconClick = () => {
    if (isInitiallyLockedOut) {
      formikContext.setFieldValue(fieldName, !field.value);
    }
  };

  return (
    <FieldAndValue
      type="custom"
      fieldLabel={translate('pages.editUser.fieldLabels.lockedOut')}
      value={
        <LockIconContainer
          disabled={!isInitiallyLockedOut}
          onClick={onIconClick}
          data-testid={lockedOutFieldIconTestId(!isInitiallyLockedOut)}
        >
          {field.value ? <LockClosedIcon /> : <LockOpenIcon />}
        </LockIconContainer>
      }
    />
  );
};

const LockIconContainer = styled.div<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? colourGrey02 : colourBlack)};
  margin-top: ${spacing4};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  svg {
    height: ${spacing24};
    width: ${spacing24};
  }
`;

export const lockedOutFieldIconTestId = (disabled: boolean) =>
  `locked-out-field-icon-${disabled ? 'disabled' : ''}`;
