import { PaginatedTable } from '../../../infrastructure/interface/tables/PaginatedTable';
import { Table, TBody, THead } from '../../../infrastructure/interface/tables/Table';
import React from 'react';
import styled from 'styled-components/macro';
import { spacing4 } from '../../../styling/design/spacing';

export const BatchTableComponent = ({
  pageNumber,
  setPageNumber,
  resultsPerPage,
  setResultsPerPage,
  totalResultsCount,
  response,
  inProgress,
  headers,
  body,
}: BatchTableComponentProps<any>) => {
  return (
    <PaginatedTable
      showLoadingOverlay={inProgress}
      resultsPerPage={resultsPerPage}
      totalResultsCount={totalResultsCount ?? 0}
      onChangeResultsPerPage={setResultsPerPage}
      currentPageNumber={pageNumber}
      onChangeCurrentPageNumber={setPageNumber}
    >
      <BatchStripedTable>
        <THead>{headers}</THead>
        <TBody>{body}</TBody>
      </BatchStripedTable>
    </PaginatedTable>
  );
};

export type BatchTableComponentProps<TResponse> = {
  pageNumber: number;
  setPageNumber(pageNumber: number): void;
  resultsPerPage: number;
  setResultsPerPage(resultsPerPage: number): void;
  totalResultsCount: number | undefined;
  inProgress: boolean;
  response: TResponse | null;
  loadData(): void;
  headers: any;
  body: any;
};

const BatchStripedTable = styled(Table)`
  tbody {
    tr {
      td {
        padding-top: ${spacing4};
        padding-bottom: ${spacing4};
      }
      &:nth-of-type(even) {
        td {
          background-color: #f1f1f1;
        }
      }
    }
  }
`;
