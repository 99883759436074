import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { GetTransactionTypeDropdownOptionsResponse } from './GetTransactionTypeDropdownOptionsResponse';

export type TransactionTypeSelectOptionValue = string | null;

export type TransactionTypeSelectOptionCode = 'L' | 'D';

export const transactionTypeSelectOptionCode: Record<string, TransactionTypeSelectOptionCode> = {
  Listing: 'L',
  Dealing: 'D',
};

export type TransactionTypeSelectSettings = {
  optionCode: TransactionTypeSelectOptionCode;
  companyId: number | null;
  includeBlank: boolean;
  employeeCounterpartId: number | null;
};

export const defaultTransactionTypeSelectSettings: TransactionTypeSelectSettings = {
  optionCode: transactionTypeSelectOptionCode.Listing,
  companyId: null,
  includeBlank: false,
  employeeCounterpartId: null,
};

export const useGetTransactionTypeDropdownOptionsRequest = () =>
  useGetJson<GetTransactionTypeDropdownOptionsQuery, GetTransactionTypeDropdownOptionsResponse>(
    '/api/transactions/GetTransactionTypeDropdownOptions'
  );

export type GetTransactionTypeDropdownOptionsQuery = {
  optionCode: TransactionTypeSelectOptionCode;
  companyId: number | null;
  includeBlank: boolean;
  employeeCounterpartId: number | null;
};

export const defaultGetTransactionTypeDropdownOptionsRequest: GetTransactionTypeDropdownOptionsQuery =
  {
    optionCode: transactionTypeSelectOptionCode.Listing,
    companyId: null,
    includeBlank: false,
    employeeCounterpartId: null,
  };
