import React, { useMemo } from 'react';
import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { mapTransactionTypeDropdownOptionsResponseToSelectOptions } from './GetTransactionTypeDropdownOptionsResponse';
import styled, { css } from 'styled-components/macro';
import { onDesktop } from '../../../styling/layout/screenBreakpoints';
import { MetadataMultiSelectProps } from '../MetadataSelectProps';
import { MultiSelect } from '../../../infrastructure/interface/forms/MultiSelect';
import {
  defaultGetTransactionTypeDropdownOptionsRequest,
  TransactionTypeSelectOptionValue,
  TransactionTypeSelectSettings,
  useGetTransactionTypeDropdownOptionsRequest,
} from './TransactionTypeSelectBase';

type Props = MetadataMultiSelectProps<TransactionTypeSelectOptionValue> & {
  settings?: TransactionTypeSelectSettings;
};

export const TransactionTypeMultiSelect = (props: Props) => {
  const { settings, onError, disabled, ...selectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.transactionTypeSelect.placeholder');
  const loadingPlaceholder = translate('metadata.transactionTypeSelect.loadingPlaceholder');

  const getTransactionTypeDropdownOptions = useGetTransactionTypeDropdownOptionsRequest();

  const { response, inProgress } = getTransactionTypeDropdownOptions.state;
  const responseNotLoadedOrInProgress = response == null || inProgress;

  useOnMount(() =>
    getTransactionTypeDropdownOptions.makeRequest({
      queryParameters: {
        ...defaultGetTransactionTypeDropdownOptionsRequest,
        ...(settings || {}),
      },
      onFailure: onError,
    })
  );

  const options: SelectOptions<TransactionTypeSelectOptionValue> = useMemo(
    () => mapTransactionTypeDropdownOptionsResponseToSelectOptions(response),
    [response]
  );

  return (
    <StyledMultiSelect<TransactionTypeSelectOptionValue>
      {...selectProps}
      options={options}
      placeholder={responseNotLoadedOrInProgress ? loadingPlaceholder : placeholder}
      disabled={disabled || responseNotLoadedOrInProgress}
    />
  );
};

const StyledMultiSelect = styled(MultiSelect)`
  width: auto;

  ${onDesktop(css`
    min-width: 350px;
  `)};
` as typeof MultiSelect;
