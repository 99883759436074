import React, { ReactNode } from 'react';
import { DesktopModalSize, Modal, ModalButtonRow, ModalHeader } from './Modal';
import { SubmitButton } from '../../forms/common/SubmitButton';
import { SecondaryButton } from '../buttons/SecondaryButton';

type Props = {
  isOpen: boolean;
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  closeButtonText: string;
  onRequestClose: () => void;
  submittingText: string;
  formId: string;
  submitButtonText?: string;
  desktopSize?: DesktopModalSize;
  closeButtonTestId?: string;
  children?: ReactNode;
};

export const ConfirmationModal = (props: Props) => {
  const {
    isOpen,
    title,
    message,
    closeButtonText,
    onRequestClose,
    submittingText,
    submitButtonText,
    desktopSize,
    formId,
  } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} desktopSize={desktopSize}>
      <ModalHeader title={title} withCloseButton={true} onRequestClose={onRequestClose} />
      <div>{message}</div>
      <ModalButtonRow>
        <SecondaryButton onClick={onRequestClose} disabled={false}>
          {closeButtonText}
        </SecondaryButton>
        <SubmitButton submittingText={submittingText} formId={formId}>
          {submitButtonText}
        </SubmitButton>
      </ModalButtonRow>
      {props.children}
    </Modal>
  );
};
