import React, { useContext, useEffect, useState } from 'react';
import { ReportParameterResponse } from '../../Report';
import { CompanySelectField } from '../../../metadata/company/CompanySelectField';
import {
  CompanyDropdownOptionResponse,
  GetCompanyDropdownOptionsResponse,
} from '../../../metadata/company/GetCompanyDropdownOptionsResponse';
import { CompanySelectOptionValue } from '../../../metadata/company/CompanySelect';
import { ReportContext } from '../../ReportContext';
import { useReportParameterFieldsContext } from '../ReportParameterFieldsContext';
import { CompanySelectSettings } from '../../../metadata/company/CompanySelectSettings';

type CompanySelectProps = {
  companyParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsCompanySelectField = ({ companyParameter, onError }: CompanySelectProps) => {
  const { setSelectedCompanyOption } = useContext(ReportContext);
  const { setFieldLoaded } = useReportParameterFieldsContext();
  const [options, setOptions] = useState<Array<CompanyDropdownOptionResponse>>([]);
  const [selectedValue, setSelectedValue] = useState<CompanySelectOptionValue | null>(null);

  const onFieldValueChange = (newValue: CompanySelectOptionValue) => {
    setSelectedValue(newValue);
  };

  const onLoaded = (response: GetCompanyDropdownOptionsResponse) => {
    setOptions(response.options);
    setFieldLoaded(companyParameter.name);
  };

  useEffect(() => {
    const selectedOption = options.find((option) => option.companyId === selectedValue);
    setSelectedCompanyOption(selectedOption ?? null);
  }, [selectedValue, options]); // eslint-disable-line react-hooks/exhaustive-deps

  const settings: CompanySelectSettings = {
    includeBlank: false,
    includeAllFunds: companyParameter.includeAllRecord,
    includeAllInvestedFunds: false,
    isForReports: true,
    excludeClosed: false,
    documentCategoryId: null,
    amlCheckId: null,
    employeeCounterpartId: null,
    rememberCompanyId: false,
  };

  return (
    <CompanySelectField
      fieldName={companyParameter.name}
      label={companyParameter.displayText}
      settings={settings}
      onFieldValueChange={onFieldValueChange}
      onError={onError}
      onLoaded={onLoaded}
      defaultToFirstOption={true}
    />
  );
};
