import React from 'react';
import styled from 'styled-components/macro';
import { fontSizeCss, fontWeightBold } from '../../../styling/design/fonts';
import { spacing16, spacing24, spacing8 } from '../../../styling/design/spacing';
import { Panel } from './Panel';
import { Header3 } from './Headers';
import { FieldAndValueContainer, FieldAndValueLabel, FieldAndValueValue } from './FieldAndValue';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';

type Props = {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  className?: string;
};

export const ItemDetailsCard = (props: Props) => {
  return (
    <CardContainer className={props.className}>
      {props.subtitle && <SubtitleContainer>{props.subtitle}</SubtitleContainer>}
      {props.title && <Title>{props.title}</Title>}
      {props.children}
    </CardContainer>
  );
};

const CardContainer = styled(Panel)`
  margin-bottom: ${spacing16};
  padding: ${spacing24};

  ${FieldAndValueContainer} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${spacing24};

    ${FieldAndValueLabel} {
      white-space: nowrap;
    }

    ${FieldAndValueValue} {
      text-align: right;
      font-weight: ${fontWeightBold};
      word-break: break-word;
    }
  }
`;

const SubtitleContainer = styled.div`
  ${fontSizeCss('xsmall')};
`;

const Title = styled(Header3)`
  margin-bottom: ${spacing8};
`;

export const ItemDetailsCardButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing16};
  margin-top: ${spacing16};
`;

type MobileNoResultsCardProps = {
  message?: string;
};

export const MobileNoResultsCard = ({ message }: MobileNoResultsCardProps) => {
  const { translate } = useInternationalisation();

  return <MobileNoResultsPanel>{message ?? translate('tables.noResults')}</MobileNoResultsPanel>;
};

const MobileNoResultsPanel = styled(Panel)`
  margin-top: ${spacing16};
  text-align: center;
`;

type MobileThresholdLimitCardProps = {
  message?: string;
};

export const MobileThresholdLimitCard = ({ message }: MobileThresholdLimitCardProps) => {
  const { translate } = useInternationalisation();

  return (
    <MobileThresholdLimitPanel>
      {message ?? translate('tables.thresholdLimit')}
    </MobileThresholdLimitPanel>
  );
};

const MobileThresholdLimitPanel = styled(Panel)`
  margin-top: ${spacing16};
  text-align: center;
`;
