import React from 'react';
import { ErrorMessage } from '../infrastructure/interface/components/ErrorMessage';
import { Alert } from '../infrastructure/interface/components/Alert';
import styled from 'styled-components/macro';
import { colourWhite } from '../styling/design/colours';
import { Header1 } from '../infrastructure/interface/components/Headers';
import { Spinner } from '../infrastructure/interface/components/Spinner';
import { TranslateFunction } from '../internationalisation/types/InternationalisationContextValue';

export const splashScreenErrorTestId = 'splash-screen-error';

type ErrorSplashScreenProps = {
  error: string;
  translate: TranslateFunction | null;
};

export const ErrorSplashScreen = ({ error, translate }: ErrorSplashScreenProps) => (
  <SplashScreen>
    <ErrorMessage data-testid={splashScreenErrorTestId}>
      <ErrorContainer>
        <Alert
          alertType="negative"
          header={translate == null ? 'Sorry, something went wrong' : translate('errors.apology')}
        >
          {error}
        </Alert>
      </ErrorContainer>
    </ErrorMessage>
  </SplashScreen>
);

const ErrorContainer = styled.div`
  width: 500px;
  max-width: 90vw;
`;

export const splashScreenSpinnerTestId = 'splash-screen-spinner';

type LoadingSplashScreenProps = {
  translate: TranslateFunction | null;
};

export const LoadingSplashScreen = ({ translate }: LoadingSplashScreenProps) => (
  <SplashScreen>
    <Header1>{translate == null ? 'Loading...' : translate('loading')}</Header1>
    <Spinner size="xlarge" data-testid={splashScreenSpinnerTestId} />
  </SplashScreen>
);

const SplashScreen = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colourWhite};
`;
