import { AuthenticationContextProvider } from './features/authentication/AuthenticationContext';
import { AppRoutes } from './AppRoutes';
import { InternationalisationContextProvider } from './internationalisation/InternationalisationContextProvider';
import { NotificationsContextProvider } from './styling/layout/sidebar/notifications/NotificationsContext';
import { UserActivityContextProvider } from './features/authentication/UserActivityContext';
import { StartupDataContextProvider } from './startup/StartupDataContext';
import { SiteContextProvider } from './features/multiTenancy/SiteContext';

export const App = () => {
  return (
    <SiteContextProvider>
      <UserActivityContextProvider>
        <StartupDataContextProvider>
          <InternationalisationContextProvider>
            <AuthenticationContextProvider>
              <NotificationsContextProvider>
                <AppRoutes />
              </NotificationsContextProvider>
            </AuthenticationContextProvider>
          </InternationalisationContextProvider>
        </StartupDataContextProvider>
      </UserActivityContextProvider>
    </SiteContextProvider>
  );
};
