import React from 'react';
import { CompanyReportGroupValueDateSelectSettings } from './CompanyReportGroupValueDateSelectSettings';
import {
  CompanyReportGroupValueDateSelect,
  CompanyReportGroupValueDateSelectOptionValue,
} from './CompanyReportGroupValueDateSelect';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';
import { GetCompanyValueDateDropdownOptionsResponse } from '../companyValueDate/GetCompanyValueDateDropdownOptionsResponse';

type Props = MetadataSingleSelectFieldComponentProps<
  CompanyReportGroupValueDateSelectOptionValue,
  CompanyReportGroupValueDateSelectSettings,
  GetCompanyValueDateDropdownOptionsResponse
>;

export const CompanyReportGroupValueDateSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    CompanyReportGroupValueDateSelectOptionValue,
    CompanyReportGroupValueDateSelectSettings,
    GetCompanyValueDateDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <CompanyReportGroupValueDateSelect {...metadataSingleSelectProps} />
    )}
  />
);
