import React, { useCallback, useMemo, useState } from 'react';
import { find } from 'lodash';
import { Validator } from 'fluentvalidation-ts';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { haveAllowedFileExtension, notExceedSizeInBytes } from '../../validation/fileValidation';
import { notBeNull } from '../../validation/commonValidation';
import { notBeEmpty } from '../../validation/stringValidation';
import {
  DocumentTypeResponse,
  GetDocumentTypesResponse,
} from '../../metadata/documentType/GetDocumentTypesResponse';
import { Form } from '../../../infrastructure/forms/common/Form';
import { TextAreaField } from '../../../infrastructure/forms/fields/TextAreaField';
import { DocumentTypeSelectField } from '../../metadata/documentType/DocumentTypeSelectField';
import { CompanySelectField } from '../../metadata/company/CompanySelectField';
import { FilePickerField } from '../../../infrastructure/forms/fields/FilePickerField';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { SubmitButton } from '../../../infrastructure/forms/common/SubmitButton';
import { Formik, FormikHelpers } from 'formik';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import { useMultipartApiRequest } from '../../../infrastructure/api/useMultipartApiRequest';
import { assertNotNull } from '../../../helpers/nullHelpers';
import { defaultCompanySelectSettings } from '../../metadata/company/CompanySelectSettings';
import { DocumentTypeSelectContainer } from './DocumentTypeSelectContainer';
import { GroupedSelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { CategoryResponse } from '../UploadDocument';
import styled from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';
import { SingleSelectField } from '../../../infrastructure/forms/fields/SingleSelectField';

type Props = {
  allowedFileExtensions: Array<string>;
  maximumUploadSizeInBytes: number;
  documentCategoryGroupsOptions: GroupedSelectOptions<CategoryResponse>;
  categoryResponse: CategoryResponse[];
};

export const InvestorUploadDocumentForm = ({
  allowedFileExtensions,
  maximumUploadSizeInBytes,
  documentCategoryGroupsOptions,
  categoryResponse,
}: Props) => {
  const { translate } = useInternationalisation();

  const [filterLoadError, setFilterLoadError] = useState<string | null>(null);

  const [success, setSuccess] = useState(false);

  const findDocumentCategoryInResponse = useCallback(
    (documentCategoryId: number | null): CategoryResponse | null =>
      findDocumentCategory(categoryResponse || [], documentCategoryId),
    [categoryResponse]
  );

  const [documentTypesResponse, setDocumentTypesResponse] =
    useState<GetDocumentTypesResponse | null>(null);

  const shouldShowDocumentTypeDropdown = (documentCategoryId: number | null): boolean => {
    const documentCategory = findDocumentCategoryInResponse(documentCategoryId);

    return (
      documentCategory != null &&
      documentCategory.categoryType === 'AML' &&
      documentTypesResponse != null &&
      documentTypesResponse.documentTypes.length > 0
    );
  };

  const shouldShowCompanyDropdown = (documentCategoryId: number | null): boolean => {
    const documentCategory = findDocumentCategoryInResponse(documentCategoryId || null);
    return documentCategory == null || documentCategory.categoryType !== 'AML';
  };

  const documentTypes = documentTypesResponse?.documentTypes;

  const validator = useMemo(
    () =>
      new InvestorUploadDocumentFormValidator(
        translate,
        allowedFileExtensions,
        maximumUploadSizeInBytes,
        categoryResponse || [],
        documentTypes || []
      ),
    [translate, allowedFileExtensions, maximumUploadSizeInBytes, categoryResponse, documentTypes]
  );

  const uploadRequest = useMultipartApiRequest<InvestorUploadDocumentCommand>(
    '/api/documents/InvestorUploadDocument'
  );

  const onSubmit = (
    formModel: InvestorUploadDocumentFormModel,
    formikHelpers: FormikHelpers<InvestorUploadDocumentFormModel>
  ) => {
    const command: InvestorUploadDocumentCommand = {
      documentCategoryId: assertNotNull(
        formModel.documentCategory?.documentCategoryId || null,
        'documentCategory'
      ),
      description: formModel.description,
      documentTypeCheckId: shouldShowDocumentTypeDropdown(
        formModel.documentCategory?.documentCategoryId || null
      )
        ? assertNotNull(formModel.documentTypeCheckId, 'documentTypeCheckId')
        : null,
      companyId: shouldShowCompanyDropdown(formModel.documentCategory?.documentCategoryId || null)
        ? assertNotNull(formModel.companyId, 'companyId')
        : null,
      documentFile: assertNotNull(formModel.documentFile[0], 'documentFile'),
    };

    uploadRequest.makeRequest({
      request: command,
      onSuccess: () => setSuccess(true),
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  const DocumentCategoryGroupSelect = styled(SingleSelectField)`
    margin-right: ${spacing16};
    margin-bottom: ${spacing16};
    min-width: 350px;
  ` as typeof SingleSelectField;

  if (filterLoadError != null) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')}>
        {filterLoadError}
      </Alert>
    );
  }

  if (success) {
    return (
      <Alert alertType="positive" header={translate('pages.uploadDocument.successMessage.header')}>
        {translate('pages.uploadDocument.successMessage.body')}
      </Alert>
    );
  }

  return (
    <Panel>
      <Formik<InvestorUploadDocumentFormModel>
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={validator.validate}
      >
        {(formikProps) => (
          <Form>
            <DocumentCategoryGroupSelect
              options={documentCategoryGroupsOptions}
              fieldName="documentCategory"
              label={translate('pages.uploadDocument.labels.documentCategory')}
              placeholder={translate(
                'pages.uploadDocument.documentCategoryGroupsSelectPlaceholder'
              )}
            />
            <TextAreaField
              fieldName="description"
              label={translate('pages.uploadDocument.labels.description')}
            />
            <DocumentTypeSelectContainer
              visibility={
                shouldShowDocumentTypeDropdown(
                  formikProps.values.documentCategory?.documentCategoryId || null
                )
                  ? 'visible'
                  : 'hidden'
              }
            >
              <DocumentTypeSelectField
                fieldName="documentTypeCheckId"
                label={translate('pages.uploadDocument.labels.documentType')}
                onLoaded={setDocumentTypesResponse}
                onError={setFilterLoadError}
                settings={{}}
              />
            </DocumentTypeSelectContainer>
            {shouldShowCompanyDropdown(
              formikProps.values.documentCategory?.documentCategoryId || null
            ) && (
              <CompanySelectField
                fieldName="companyId"
                label={translate('pages.uploadDocument.labels.company')}
                onError={setFilterLoadError}
                settings={{
                  ...defaultCompanySelectSettings,
                  documentCategoryId:
                    formikProps.values.documentCategory?.documentCategoryId || null,
                  amlCheckId: null,
                }}
                defaultToFirstOption={true}
              />
            )}
            <FilePickerField
              fieldName="documentFile"
              allowMultiple={false}
              allowedFileTypes={allowedFileExtensions}
              label={translate('pages.uploadDocument.labels.documentFile')}
              disabled={allowedFileExtensions.length === 0 || maximumUploadSizeInBytes === 0}
            />
            <ButtonRow rightAligned={true} withMarginTop={true}>
              <SubmitButton
                submittingText={translate('pages.uploadDocument.submitButton.submittingText')}
              >
                {translate('pages.uploadDocument.submitButton.text')}
              </SubmitButton>
            </ButtonRow>
            {uploadRequest.state.error && (
              <Alert alertType="negative" header={translate('errors.apology')} withMarginTop={true}>
                {uploadRequest.state.error}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </Panel>
  );
};

const findDocumentCategory = (
  documentCategories: Array<CategoryResponse>,
  documentCategoryId: number | null
): CategoryResponse | null =>
  find(
    documentCategories,
    (documentCategory) => documentCategory.documentCategoryId === documentCategoryId
  ) || null;

export type InvestorUploadDocumentFormModel = {
  documentCategory: CategoryResponse | null;
  description: string;
  documentTypeCheckId: number | null;
  companyId: number | null;
  documentFile: Array<File>; // Needs to be an array as that's what the file picker exposes
};

export type InvestorUploadDocumentCommand = {
  documentCategoryId: number;
  description: string;
  documentTypeCheckId: number | null;
  companyId: number | null;
  documentFile: File;
};

const initialFormValues: InvestorUploadDocumentFormModel = {
  documentCategory: null,
  description: '',
  documentTypeCheckId: null,
  companyId: null,
  documentFile: [],
};

class InvestorUploadDocumentFormValidator extends Validator<InvestorUploadDocumentFormModel> {
  constructor(
    translate: TranslateFunction,
    allowedFileExtensions: Array<string>,
    maximumUploadSizeInBytes: number,
    categoryResponse: Array<CategoryResponse>,
    documentTypes: Array<DocumentTypeResponse>
  ) {
    super();

    this.ruleFor('documentCategory').must(notBeNull(translate));

    this.ruleFor('description').must(notBeEmpty(translate));

    this.ruleFor('documentTypeCheckId')
      .must(notBeNull(translate))
      .when(
        (formModel) =>
          documentTypes.length > 0 &&
          formModel.documentCategory?.documentCategoryId != null &&
          findDocumentCategory(categoryResponse, formModel.documentCategory.documentCategoryId)
            ?.categoryType === 'AML'
      );

    this.ruleFor('companyId')
      .must(notBeNull(translate))
      .when(
        (formModel) =>
          formModel.documentCategory?.documentCategoryId == null ||
          findDocumentCategory(categoryResponse, formModel.documentCategory.documentCategoryId)
            ?.categoryType !== 'AML'
      );

    this.ruleFor('documentFile')
      .must((files) => files.length === 1)
      .withMessage(translate('validation.requiredField'))
      .must(haveAllowedFileExtension(translate, allowedFileExtensions))
      .must(notExceedSizeInBytes(translate, maximumUploadSizeInBytes));
  }
}
