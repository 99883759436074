import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components/macro';
import { isEmpty } from 'lodash';
import { RequestFailedAlert } from '../../../../infrastructure/api/RequestFailedAlert';
import { Header2 } from '../../../../infrastructure/interface/components/Headers';
import { defaultInitialResultsPerPageOption } from '../../../../infrastructure/interface/tables/PaginatedTable';
import {
  GetUsersForBatchCreateRequest,
  GetUsersForBatchCreateResponse,
  UserToCreateResponse,
} from './GetUsersForBatchCreateRequest';
import { spacing16, spacing32 } from '../../../../styling/design/spacing';
import { Panel } from '../../../../infrastructure/interface/components/Panel';
import { BatchTableComponent } from '../BatchTableComponent';
import {
  AccessGroupSelect,
  defaultAccessGroupSelectSettings,
} from '../../../metadata/accessGroup/AccessGroupSelect';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { NoResultsRow, Td, Th, Tr } from '../../../../infrastructure/interface/tables/Table';
import { BatchCreateUsersRequest, UsersCreated, UserToCreate } from './BatchCreateUsersRequest';
import { Form, Formik, FormikProps } from 'formik';
import { Checkbox } from '../../../../infrastructure/interface/forms/Checkbox';
import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import { SingleSelect } from '../../../../infrastructure/interface/forms/SingleSelect';
import { ConfirmationModal } from '../../../../infrastructure/interface/components/ConfirmationModal';
import { PrimaryButton } from '../../../../infrastructure/interface/buttons/PrimaryButton';
import { RequiresUserRole } from '../../../authentication/UserRoles';
import { roleCodeCharactersByRoleName } from '../../../authentication/UserRole';
import { dictionaryToArray } from '../../../../helpers/typeHelpers';
import { BatchCreateErrorMessage } from './BatchCreateErrorMessage';
import { Tooltip } from '../../../../infrastructure/interface/components/Tooltip';
import { StatusIndicator } from '../../../../infrastructure/interface/components/StatusIndicator';

export const BatchCreateUsers = () => (
  <RequiresUserRole userRole="Administrator">
    <BatchCreateUsersComponent />
  </RequiresUserRole>
);

const BatchCreateUsersComponent = () => {
  const { translate } = useInternationalisation();
  const formRef = useRef<FormikProps<BatchCreateUserFormModel>>(null);
  // data states
  const [pageNumber, setPageNumber] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(defaultInitialResultsPerPageOption);
  const [latestResponse, setLatestResponse] = useState<GetUsersForBatchCreateResponse | null>(null);
  const [accessGroupId, setAccessGroupId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<BatchCreateUserFormModel>({ users: [] });
  const [createUsersResponses, setCreateUsersResponses] = useState<Array<UsersCreated>>([]);

  const getLatestStatus = (createdUsers: UsersCreated[]) => {
    return createdUsers[createdUsers.length - 1].status;
  };

  const getStatusForUser = (user: UserToCreateModel) => {
    const createUsersResponsesForUser = createUsersResponses.filter(
      (createdUser) => createdUser?.counterpartId === user.readonlyData?.counterpartId
    );
    return createUsersResponsesForUser.length > 0
      ? getLatestStatus(createUsersResponsesForUser)
      : null;
  };

  const getLatestError = (createdUsers: UsersCreated[]) => {
    return createdUsers[createdUsers.length - 1].errorMessage;
  };

  const getErrorForUser = (user: UserToCreateModel) => {
    const createUsersResponsesForUser = createUsersResponses.filter(
      (createdUser) => createdUser?.counterpartId === user.readonlyData?.counterpartId
    );
    return createUsersResponsesForUser.length > 0
      ? getLatestError(createUsersResponsesForUser)
      : null;
  };

  const changeAccessGroup = (id: number) => {
    setAccessGroupId(id);
    createUsersResponses.splice(0);
  };

  const onUsersLoaded = (response: GetUsersForBatchCreateResponse) => {
    const users: any = {};
    response.users.map((user) => {
      users[user.counterpartId] = {
        username: user.suggestedUsername,
        shouldCreate: false,
        readonlyData: user,
      } as UserToCreateModel;
    });

    setFormValues({ users: { ...users } });
    setLatestResponse(response);
  };

  //API Request to get list of users available to create.
  const usersToCreateRequest = GetUsersForBatchCreateRequest(onUsersLoaded);
  const getUsers = () => {
    if (accessGroupId === null) return;

    usersToCreateRequest.get({
      pageNumber,
      resultsPerPage,
      accessGroupId,
    });
  };

  useEffect(() => getUsers(), [pageNumber, resultsPerPage, accessGroupId]);
  const resultsWereEmpty: boolean = isEmpty(formValues.users);

  //form field value helpers
  const usersArray = (): UserToCreateModel[] => dictionaryToArray<UserToCreateModel>(users);

  const isUserChecked = (counterpartId: number): boolean =>
    usersArray().find(
      (user) => user.readonlyData.counterpartId === counterpartId && user.shouldCreate
    ) != null;

  const usersToCreate = (): UserToCreateModel[] =>
    usersArray().filter((user) => user.shouldCreate && !hasUserBeenCreated(user));

  const hasUserBeenCreated = (user: UserToCreateModel) =>
    createUsersResponses != null &&
    createUsersResponses.some(
      (createdUser) =>
        createdUser?.status === 'Success' &&
        createdUser?.counterpartId === user.readonlyData?.counterpartId
    );

  const fieldPathForUser = (user: UserToCreateModel, fieldName: string) =>
    `users[${user.readonlyData.counterpartId}].${fieldName}`;

  const getFieldValue = (user: UserToCreateModel, fieldName: string) =>
    formRef.current?.getFieldProps(fieldPathForUser(user, fieldName)).value;

  const setFieldValue = (user: UserToCreateModel, fieldName: string, fieldValue: any) =>
    formRef.current?.setFieldValue(fieldPathForUser(user, fieldName), fieldValue);

  let users = formValues.users as any;

  const handleCheckboxChanged = (isChecked: boolean, counterpartId: number): void => {
    (users[counterpartId] as UserToCreateModel).shouldCreate = isChecked;
    setFormValues({ users: { ...formValues.users, ...users } });
  };

  const handleCheckAllButton = () => {
    if (users != null)
      for (const [key] of Object.entries(users)) {
        users[key].shouldCreate = true;
      }

    setFormValues({ users: { ...users } });
  };

  const handleUncheckAllButton = () => {
    if (users != null)
      for (const [key] of Object.entries(users)) {
        if (!hasUserBeenCreated(users[key])) {
          users[key].shouldCreate = false;
        }
      }

    setFormValues({ users: { ...users } });
  };

  //Form Submission
  const batchCreateUsersRequest = BatchCreateUsersRequest(accessGroupId ?? 1);
  const submitForm = (values: BatchCreateUserFormModel): void => {
    const usersToCreate = dictionaryToArray<UserToCreateModel>(values.users)
      .filter((user) => user.shouldCreate && !hasUserBeenCreated(user))
      .map((user) => {
        return {
          counterpartId: user.readonlyData.counterpartId,
          username: user.username,
        } as UserToCreate;
      });
    batchCreateUsersRequest.submit(usersToCreate, formRef.current!.setSubmitting, (response) => {
      setCreateUsersResponses([...createUsersResponses, ...response.createdUsers]);
      setIsModalOpen(false);
    });
  };

  if (usersToCreateRequest.state.error) {
    return <RequestFailedAlert error={usersToCreateRequest.state.error} retry={getUsers} />;
  }

  const roleOptions: SelectOptions<string> = [
    { value: roleCodeCharactersByRoleName.Investor, label: 'Investor' },
    {
      value: roleCodeCharactersByRoleName['Consolidated Investor'],
      label: 'Consolidated Investor',
    },
    { value: roleCodeCharactersByRoleName.Manager, label: 'Manager' },
    { value: 'A', label: 'Advisor' },
  ];

  return (
    <Panel>
      {/*filters*/}
      <PanelHeaderRow>
        <AccessGroupSelect
          value={accessGroupId}
          onChange={(value) => changeAccessGroup(value!)}
          onError={() => null}
          settings={{ ...defaultAccessGroupSelectSettings, includeBlank: false }}
        />
        <ButtonContainer>
          <SelectButton onClick={handleCheckAllButton}>
            {translate('pages.users.batchCreate.checkAll')}
          </SelectButton>
          <PrimaryButton onClick={handleUncheckAllButton}>
            {translate('pages.users.batchCreate.uncheckAll')}
          </PrimaryButton>
        </ButtonContainer>
      </PanelHeaderRow>
      <div>
        {createUsersResponses.some((user) => user.status === 'Error')
          ? translate('pages.users.batchCreate.error')
          : null}
      </div>
      {/*table*/}
      <Formik<BatchCreateUserFormModel>
        initialValues={formValues}
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={submitForm}
      >
        {({ values }) => (
          <Form id="batchCreateUsers">
            <BatchTableComponent
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              resultsPerPage={resultsPerPage}
              setResultsPerPage={setResultsPerPage}
              totalResultsCount={latestResponse?.count}
              inProgress={usersToCreateRequest.state.inProgress}
              response={latestResponse}
              loadData={getUsers}
              headers={
                <Tr>
                  <Th>{translate('pages.users.table.createUsers.headings.create')}</Th>
                  <Th>{translate('pages.users.table.createUsers.headings.username')}</Th>
                  <Th>{translate('pages.users.table.createUsers.headings.counterpartId')}</Th>
                  <Th>{translate('pages.users.table.createUsers.headings.counterpart')}</Th>
                  <Th>{translate('pages.users.table.createUsers.headings.emailAddress')}</Th>
                  <Th>{translate('pages.users.table.createUsers.headings.role')}</Th>
                  <Th>{translate('pages.users.table.createUsers.headings.status')}</Th>
                </Tr>
              }
              body={
                resultsWereEmpty ? (
                  <NoResultsRow colSpan={7} />
                ) : (
                  <>
                    {usersArray().map((user: UserToCreateModel) => (
                      <Tr key={user.readonlyData.counterpartId}>
                        <Td>
                          <Checkbox
                            checked={isUserChecked(user.readonlyData.counterpartId)}
                            onChange={(isChecked) =>
                              handleCheckboxChanged(isChecked, user.readonlyData.counterpartId)
                            }
                            disabled={hasUserBeenCreated(user)}
                          />
                        </Td>
                        <Td>
                          <input
                            required={isUserChecked(user.readonlyData.counterpartId)}
                            disabled={
                              !isUserChecked(user.readonlyData.counterpartId) ||
                              hasUserBeenCreated(user)
                            }
                            name={fieldPathForUser(user, 'username')}
                            type="text"
                            value={
                              getFieldValue(user, 'username') ??
                              user.readonlyData?.suggestedUsername
                            }
                            onChange={(e: any) => {
                              setFieldValue(user, 'username', e.target.value);
                            }}
                          />
                        </Td>
                        <Td>{user.readonlyData?.counterpartId}</Td>
                        <Td>{user.readonlyData?.counterpart}</Td>
                        <Td>{user.readonlyData?.emailAddress}</Td>
                        <Td>
                          <SingleSelect
                            name={fieldPathForUser(user, 'role')}
                            value={getFieldValue(user, 'role') || user.readonlyData?.role || ''}
                            disabled={hasUserBeenCreated(user)}
                            options={roleOptions}
                            onChange={(value: string | null) => {
                              setFieldValue(user, 'role', value);
                            }}
                          />
                        </Td>
                        <Td>
                          {getStatusForUser(user) === 'Error' ? (
                            <>
                              <Tooltip
                                content={
                                  <BatchCreateErrorMessage errorMessage={getErrorForUser(user)} />
                                }
                                horizontalAlignment="right"
                              >
                                <StatusIndicator
                                  type="negative"
                                  text={translate('pages.users.batchCreate.status.error')}
                                />
                              </Tooltip>
                            </>
                          ) : getStatusForUser(user) === 'Success' ? (
                            <>
                              <StatusIndicator
                                type="positive"
                                text={translate('pages.users.batchCreate.status.success')}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </>
                )
              }
            />
            <ConfirmationModal
              isOpen={isModalOpen}
              title={translate('pages.users.batchCreate.modal.title')}
              message={translate('pages.users.batchCreate.modal.message')}
              closeButtonText={translate('actionButtons.cancel')}
              onRequestClose={() => setIsModalOpen(false)}
              submittingText={translate('pages.users.batchCreate.buttonSubmittingText')}
              submitButtonText={translate('pages.users.batchCreate.buttonSubmitText', {
                noOfUsers: usersToCreate().length,
              })}
              formId="batchCreateUsers"
            />
          </Form>
        )}
      </Formik>
      <StyledButton
        stretch={true}
        disabled={usersToCreate().length < 1}
        onClick={() => setIsModalOpen(true)}
      >
        {translate('pages.users.batchCreate.buttonSubmitText', {
          noOfUsers: usersToCreate().length,
        })}
      </StyledButton>
    </Panel>
  );
};

const PanelHeaderRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing32};

  ${Header2} {
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const SelectButton = styled(PrimaryButton)`
  margin-right: ${spacing16};
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: ${spacing16};
`;

type BatchCreateUserFormModel = {
  users: {};
};

type UserToCreateModel = {
  username: string;
  shouldCreate: boolean;
  readonlyData: UserToCreateResponse;
};
