import styled from 'styled-components/macro';
import { MinimalButton } from '../../../infrastructure/interface/buttons/MinimalButton';
import { MailToLink } from '../../../infrastructure/interface/components/ExternalLink';
import { Card } from '../../../infrastructure/interface/components/Card';
import { PaginatedTable } from '../../../infrastructure/interface/tables/PaginatedTable';
import { Table, TBody, Td, Th, THead, Tr } from '../../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { getAgentOrBeneficialOwnerDetailsOptions } from '../AgentOrBeneficialOwnerDetailsModal';
import { investorDetailsComponentResultsPerPageOptions } from '../InvestorDetails';
import { useInvestorDetailsContext } from '../InvestorDetailsContext';
import { useBeneficialOwners } from './BeneficialOwnersContext';

export const DesktopBeneficialOwnersList = () => {
  const { translate } = useInternationalisation();
  const { latestResponse, requestInProgress } = useBeneficialOwners();

  const initialRequestInProgress = latestResponse == null && requestInProgress;

  return (
    <Card
      header={translate('pages.investorDetailsPage.beneficialOwners.header')}
      cardType="expandable"
      startExpanded={false}
      contentIsLoading={initialRequestInProgress}
    >
      <BeneficialOwnersTable />
    </Card>
  );
};

const BeneficialOwnersTable = () => {
  const { translate } = useInternationalisation();
  const translateFieldName = (fieldName: string) =>
    translate(`pages.investorDetailsPage.beneficialOwners.fieldNames.${fieldName}`);

  const {
    latestResponse,
    resultsPerPage,
    setResultsPerPage,
    pageNumber,
    setPageNumber,
    requestInProgress,
  } = useBeneficialOwners();

  const { setAgentOrBeneficialOwnerDetailsModalState } = useInvestorDetailsContext();

  if (latestResponse == null) {
    return null;
  }

  if (latestResponse.totalBeneficialOwnersCount === 0) {
    return (
      <NoBeneficialOwnersContainer>
        {translate('pages.investorDetailsPage.beneficialOwners.noBeneficialOwners')}
      </NoBeneficialOwnersContainer>
    );
  }

  return (
    <PaginatedTable
      currentPageNumber={pageNumber}
      onChangeCurrentPageNumber={setPageNumber}
      resultsPerPage={resultsPerPage}
      onChangeResultsPerPage={setResultsPerPage}
      resultsPerPageOptions={investorDetailsComponentResultsPerPageOptions}
      totalResultsCount={latestResponse.totalBeneficialOwnersCount}
      showLoadingOverlay={requestInProgress}
    >
      <Table>
        <THead>
          <Tr>
            <Th>{translateFieldName('username')}</Th>
            <Th>{translateFieldName('counterpartName')}</Th>
            <Th>{translateFieldName('emailAddress')}</Th>
          </Tr>
        </THead>
        <TBody>
          {latestResponse.beneficialOwners.map((beneficialOwner, index) => (
            <Tr key={index}>
              <Td>{beneficialOwner.username}</Td>
              <Td>
                <MinimalButton
                  textAlign="left"
                  onClick={() => {
                    setAgentOrBeneficialOwnerDetailsModalState({
                      isOpen: true,
                      counterpartId: beneficialOwner.counterpartId,
                      optionCode: getAgentOrBeneficialOwnerDetailsOptions.beneficialOwner,
                    });
                  }}
                >
                  {beneficialOwner.counterpartName}
                </MinimalButton>
              </Td>
              <Td>
                {beneficialOwner.emailAddress == null ? null : (
                  <MailToLink emailAddress={beneficialOwner.emailAddress} />
                )}
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </PaginatedTable>
  );
};

export const NoBeneficialOwnersContainer = styled.div`
  text-align: center;
`;
