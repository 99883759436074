import { FormikHelpers, FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components/macro';
import { FloppyDiskIcon, TimesSolidIcon } from '../../../icons/icons';
import { ApiRequestStateWrapper } from '../../../infrastructure/api/ApiRequestStateWrapper';
import { RequestFailedPage } from '../../../infrastructure/api/RequestFailedPage';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { ActionAlert } from '../../../infrastructure/interface/components/ActionAlert';
import { Header1 } from '../../../infrastructure/interface/components/Headers';
import { NoMobileContentAlert } from '../../../infrastructure/interface/components/NoMobileContentAlert';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { spacing16 } from '../../../styling/design/spacing';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { PaddedPage } from '../../../styling/layout/PaddedPage';
import { RequiresUserRole } from '../../authentication/UserRoles';
import {
  BaseCreateEditDashboardCommand,
  CreateEditDashboardForm,
  CreateEditDashboardFormModel,
  mapCreateEditDashboardFormModelToBaseCommand,
} from './CreateEditDashboardForm';
import { DashboardDeleteButton } from './DashboardDeleteButton';
import { MinimalButton } from '../../../infrastructure/interface/buttons/MinimalButton';
import { GetDashboardResponse, useGetDashboard } from '../useGetDashboard';

export const EditDashboard = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.editDashboard.title'));

  return (
    <RequiresUserRole userRole="Administrator">
      <MobileOnly>
        <MobileEditDashboardComponent />
      </MobileOnly>
      <DesktopOnly>
        <DesktopEditDashboardApiRequestStateWrapper />
      </DesktopOnly>
    </RequiresUserRole>
  );
};

const MobileEditDashboardComponent = () => {
  const { translate } = useInternationalisation();

  return (
    <PaddedPage>
      <Header1>{translate('pages.editDashboard.header')}</Header1>
      <NoMobileContentAlert header={translate('pages.editDashboard.mobileAlertHeader')} />
    </PaddedPage>
  );
};

const DesktopEditDashboardApiRequestStateWrapper = () => {
  const { dashboardId: dashboardIdFromRouteParams } = useParams<'dashboardId'>();

  const getInitialDataRequest = useGetDashboard();

  const fetchInitialData = () => {
    if (dashboardIdFromRouteParams != null) {
      getInitialDataRequest.makeRequest({
        queryParameters: { dashboardId: parseInt(dashboardIdFromRouteParams) },
      });
    }
  };

  useOnMount(() => {
    fetchInitialData();
  });

  return (
    <ApiRequestStateWrapper apiRequestState={getInitialDataRequest.state} retry={fetchInitialData}>
      {(initialDashboardResponse) => (
        <DesktopEditDashboardComponent initialDashboardResponse={initialDashboardResponse} />
      )}
    </ApiRequestStateWrapper>
  );
};

type DesktopEditDashboardComponentProps = {
  initialDashboardResponse: GetDashboardResponse;
};

const DesktopEditDashboardComponent = ({
  initialDashboardResponse,
}: DesktopEditDashboardComponentProps) => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();

  const [innerComponentApiError, setInnerComponentApiError] = useState<string | null>(null);
  const [navigationGuardModalIsOpen, setNavigationGuardModalIsOpen] = useState<boolean>(false);
  const formRef = useRef<FormikProps<CreateEditDashboardFormModel> | null>(null);

  const returnToDashboards = () => navigate('/dashboards');
  const closeNavigationGuardModal = () => setNavigationGuardModalIsOpen(false);

  const editDashboardRequest = usePostJson<EditDashboardCommand, {}>(
    '/api/dashboards/EditDashboard'
  );

  const initialFormModel: CreateEditDashboardFormModel = {
    name: initialDashboardResponse.description,
    loginRole: initialDashboardResponse.loginRole,
    accessGroupId: initialDashboardResponse.accessGroupId,
    components: initialDashboardResponse.components,
  };

  const onFormSubmit = (
    formModel: CreateEditDashboardFormModel,
    formikHelpers: FormikHelpers<CreateEditDashboardFormModel>
  ) => {
    const command: EditDashboardCommand = {
      ...mapCreateEditDashboardFormModelToBaseCommand(formModel),
      dashboardId: initialDashboardResponse.dashboardId,
    };

    editDashboardRequest.makeRequest({
      requestBody: command,
      onSuccess: () => returnToDashboards(),
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  const onCancelClick = () => {
    if (isEqual(formRef.current?.values, initialFormModel)) {
      returnToDashboards();
    } else {
      setNavigationGuardModalIsOpen(true);
    }
  };

  const onSaveClick = () => {
    formRef.current?.submitForm();
  };

  if (innerComponentApiError != null) {
    return <RequestFailedPage error={innerComponentApiError} />;
  }

  return (
    <>
      <PaddedPage>
        <HeaderContainer>
          <Header1>{translate('pages.editDashboard.header')}</Header1>
          <ActionButtonsContainer>
            <DashboardDeleteButton
              dashboardId={initialDashboardResponse.dashboardId}
              size="medium"
              onDashboardDeleted={returnToDashboards}
            />
            <MinimalButton icon={<TimesSolidIcon />} buttonStyle="black" onClick={onCancelClick}>
              {translate('actionButtons.cancel')}
            </MinimalButton>
            <MinimalButton icon={<FloppyDiskIcon />} onClick={onSaveClick}>
              {translate('pages.createDashboard.saveDashboard')}
            </MinimalButton>
          </ActionButtonsContainer>
        </HeaderContainer>
        <CreateEditDashboardForm
          onSubmit={onFormSubmit}
          initialValues={initialFormModel}
          onInnerComponentApiError={setInnerComponentApiError}
          error={editDashboardRequest.state.error}
          ref={formRef}
        />
      </PaddedPage>
      <ActionAlert
        alertType="negative"
        isOpen={navigationGuardModalIsOpen}
        onRequestClose={closeNavigationGuardModal}
        title={translate('pages.editDashboard.navigationGuardModal.title')}
        message={translate('pages.editDashboard.navigationGuardModal.message')}
        cancelButtonText={translate('actionButtons.cancel')}
        confirmButtonText={translate('pages.editDashboard.navigationGuardModal.confirmText')}
        onInitiateAction={returnToDashboards}
        actionInProgress={false}
        actionError={null}
      />
    </>
  );
};

type EditDashboardCommand = BaseCreateEditDashboardCommand & {
  dashboardId: number;
};

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: ${spacing16};
`;
