import React, { useMemo } from 'react';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import {
  GroupedBarChart,
  GroupedBarChartDataPoint,
} from '../../../../infrastructure/charts/GroupedBarChart';
import { getIsoDatestampFromUnknownFormatDateTimeString } from '../../../../helpers/dateTimeHelpers';
import { isBlank } from '../../../../helpers/stringHelpers';

export const portfolioExposureSectorSummaryChartTestId = 'portfolio-exposure-sector-summary-chart';

type Props = {
  sectorChartResponse: PortfolioExposureSectorSummaryChartResponse;
};

export const PortfolioExposureSectorSummaryChart = ({ sectorChartResponse }: Props) => {
  const { formatDate, translate, formatNumber } = useInternationalisation();

  const title = useMemo(() => {
    const prefix = translate('pages.reports.charts.portfolioExposureSummary.sector.titlePrefix');
    const dateInLocalFormat = isBlank(sectorChartResponse.valueDate)
      ? ''
      : formatDate(sectorChartResponse.valueDate);

    return `${prefix} ${dateInLocalFormat}`;
  }, [translate, formatDate, sectorChartResponse]);

  const exportFilename = useMemo(() => {
    const prefix = translate(
      'pages.reports.charts.portfolioExposureSummary.sector.exportFilenamePrefix'
    );
    const dateInIsoDateFormat = isBlank(sectorChartResponse.valueDate)
      ? ''
      : getIsoDatestampFromUnknownFormatDateTimeString(sectorChartResponse.valueDate);

    return `${prefix}_${dateInIsoDateFormat}`;
  }, [translate, sectorChartResponse]);

  const groupCategories = sectorChartResponse.dataPoints.map(
    (dataPoint) => dataPoint.groupDescription
  );

  const dataPoints: Array<GroupedBarChartDataPoint> = useMemo(
    () => [
      {
        label: translate(
          'pages.reports.charts.portfolioExposureSummary.sector.groupCategories.mvPortfolio'
        ),
        dataPoints: sectorChartResponse.dataPoints.map((dp) => ({
          label: dp.groupDescription,
          value: dp.navPercent,
        })),
      },
      {
        label: translate(
          'pages.reports.charts.portfolioExposureSummary.sector.groupCategories.exposureLong'
        ),
        dataPoints: sectorChartResponse.dataPoints.map((dp) => ({
          label: dp.groupDescription,
          value: dp.exposurePercentLong,
        })),
      },
      {
        label: translate(
          'pages.reports.charts.portfolioExposureSummary.sector.groupCategories.exposureShort'
        ),
        dataPoints: sectorChartResponse.dataPoints.map((dp) => ({
          label: dp.groupDescription,
          value: dp.exposurePercentShort,
        })),
      },
      {
        label: translate(
          'pages.reports.charts.portfolioExposureSummary.sector.groupCategories.exposureNet'
        ),
        dataPoints: sectorChartResponse.dataPoints.map((dp) => ({
          label: dp.groupDescription,
          value: dp.exposurePercent,
        })),
      },
    ],
    [translate, sectorChartResponse]
  );

  return (
    <GroupedBarChart
      data-testid={portfolioExposureSectorSummaryChartTestId}
      title={title}
      subtitle={sectorChartResponse.companyName}
      exportFilename={exportFilename}
      yAxisOptions={{
        labels: {
          formatter() {
            return `${formatNumber(this.value, { decimalPlaces: 2 })}%`;
          },
        },
      }}
      tooltipOptions={{
        useHTML: true,
        headerFormat: '<span style="font-size: 10px">{point.key}</span>',
        pointFormatter() {
          const formattedExposurePercent =
            this.y === undefined ? '' : formatNumber(this.y, { decimalPlaces: 4 });
          return `
            <table>
              <tr>
                <td><span style="color:${this.color}">●</span><span> ${this.series.name}</span>: </td>
                <td><b>${formattedExposurePercent}%</b></td>
              </tr>
            </table>
          `;
        },
      }}
      groupCategories={groupCategories}
      dataPoints={dataPoints}
    />
  );
};

export type PortfolioExposureSectorSummaryChartResponse = {
  companyName: string;
  valueDate: string;
  dataPoints: Array<PortfolioExposureSectorSummaryChartDataPoint>;
};

type PortfolioExposureSectorSummaryChartDataPoint = {
  groupDescription: string;
  navPercent: number;
  exposurePercentLong: number;
  exposurePercentShort: number;
  exposurePercent: number;
};
