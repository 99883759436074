import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { DealingTransactionTypeCode } from '../../metadata/transactions/DealingTransactionType';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { TransactionLockCode } from '../../metadata/transactions/TransactionLock';
import { ErrorRow } from './UploadTransactions';

export const useGetBulkTransactionsDetailsRequest = () =>
  useGetJson<GetBulkTransactionsDetailsQuery, GetBulkTransactionsDetailsResponse>(
    '/api/transactions/GetBulkTransactionsDetails'
  );

type GetBulkTransactionsDetailsQuery = {
  dealNumbers: number[];
  uploadId: number;
};

export type GetBulkTransactionsDetailsResponse = {
  transactions: BulkTransactionDetailResponse[];
  errors: ErrorRow[];
  shareRegistryTradingMessageDisclaimer: string;
};

export type BulkTransactionDetailResponse = {
  investorName: string;
  investorId: number | null;
  fundName: string;
  transactionTypeCode: DealingTransactionTypeCode;
  dealingDate: IsoDatestamp;
  shareClassSeries: string;
  transactionLock: TransactionLockCode;
  shareClassSeriesCurrencyCode: string;
  amount: number | null;
  shares: number | null;
  shareDecimals: number | null;
  fees: number;
  feesString: string;
  transactionReference: string;
  companySwitch: string | null;
  equityAttributionSwitch: string | null;
  warnings: Array<string>;
};
