import React from 'react';
import styled from 'styled-components/macro';
import { applyCustomStylesToDefaults, defaultSelectBorderWidthAndType } from './baseSelectStyles';
import { spacing4 } from '../../../styling/design/spacing';
import {
  getCustomSingleSelectStyles,
  SingleSelect,
  SingleSelectCustomStyleOptions,
  SingleSelectProps,
  SingleSelectStylesConfig,
  SingleSelectStylesConfigFactory,
} from './SingleSelect';

type Props<TValue extends unknown> = Omit<SingleSelectProps<TValue>, 'searchable'>;

export const MiniSelect = <TValue extends unknown>({
  customStyles,
  ...selectProps
}: Props<TValue>) => (
  <AutoWidthSelect
    {...selectProps}
    customStyles={customStyles ?? defaultMiniSelectStyles<TValue>()}
    searchable={false}
  />
);

const AutoWidthSelect = styled(SingleSelect)`
  width: max-content;
` as typeof SingleSelect;

const defaultMiniSelectStyles = <TValue extends unknown>() =>
  getCustomSingleSelectStyles<TValue>({
    control: (state, highlight) => {
      const disabledStyles = state.isDisabled
        ? {
            border: `${defaultSelectBorderWidthAndType} transparent`,
            backgroundColor: 'transparent',
            cursor: 'not-allowed',
          }
        : {};

      const notHighlightedStyles = !highlight
        ? {
            border: `${defaultSelectBorderWidthAndType} transparent`,
          }
        : {};

      return {
        padding: `0 ${spacing4}`,
        height: 'unset',
        minHeight: 0,
        ...notHighlightedStyles,
        ...disabledStyles,
      };
    },
    valueContainer: () => ({
      padding: 0,
      marginRight: `${spacing4}`,
    }),
    input: () => ({
      padding: 0,
      margin: 0,
    }),
    dropdownIndicator: () => ({
      padding: 0,
      paddingTop: '2px',
    }),
    menu: () => ({
      margin: 0,
    }),
    option: () => ({
      display: 'flex',
      justifyContent: 'center',
    }),
  });

export const getCustomMiniSelectStyles =
  <TValue extends unknown>(
    customStyles: SingleSelectCustomStyleOptions<TValue>
  ): SingleSelectStylesConfigFactory<TValue> =>
  (highlight?: boolean): SingleSelectStylesConfig<TValue> =>
    applyCustomStylesToDefaults(
      customStyles,
      defaultMiniSelectStyles<TValue>()(highlight),
      highlight
    );
