import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import { TransactionEquityAttributionSelectOptionValue } from './EquityAttributionSelectForTransactions';

export type GetTransactionEquityAttributionDropdownOptionsResponse = {
  options: Array<TransactionEquityAttributionDropdownOption>;
};

export type TransactionEquityAttributionDropdownOption = {
  equityAttributionId: number;
  description: string;
  currencyCode: string | null;
  shareHolding: number | null;
  netAssetValue: number | null;
  shareDecimals: number | null;
  currencyDecimals: number | null;
};

export const mapTransactionEquityAttributionDropdownOptionsResponseToSelectOptions = (
  response: GetTransactionEquityAttributionDropdownOptionsResponse | null
): SelectOptions<TransactionEquityAttributionSelectOptionValue> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.equityAttributionId,
        label: option.description,
      }));
