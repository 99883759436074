import React from 'react';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import {
  GetAgentDealerDropdownOptionsResponse,
  mapAgentDealerDropdownOptionsResponseToSelectOptions,
} from './GetAgentDealerDropdownOptionsResponse';
import { AgentDealerSelectSettings } from './AgentDealerSelectSettings';
import { MetadataSingleSelectComponentProps } from './MetadataSelectProps';
import { MetadataSingleSelect } from './MetadataSingleSelect';

export type AgentDealerSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  AgentDealerSelectOptionValue,
  AgentDealerSelectSettings,
  GetAgentDealerDropdownOptionsResponse
>;

export const AgentDealerSelect = ({ settings, ...metadataSingleSelectProps }: Props) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.agentDealerSelect.placeholder');
  const loadingPlaceholder = translate('metadata.agentDealerSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      AgentDealerSelectOptionValue,
      AgentDealerSelectSettings,
      GetAgentDealerDropdownOptionsQuery,
      GetAgentDealerDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetAgentDealerDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapAgentDealerDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={300}
      {...metadataSingleSelectProps}
    />
  );
};

type GetAgentDealerDropdownOptionsQuery = {
  isAgent: boolean;
  employeeCounterpartId: number | null;
};
