import React from 'react';
import { EquityAttributionSelectForTransactionsField } from '../../../metadata/equityAttribution/forTransactions/EquityAttributionSelectForTransactionsField';
import {
  dealingTransactionType,
  DealingTransactionTypeCode,
} from '../../../metadata/transactions/DealingTransactionType';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { EquityAttributionSelectForTransactionsSettings } from '../../../metadata/equityAttribution/forTransactions/EquityAttributionSelectForTransactionsSettings';
import { GetTransactionEquityAttributionDropdownOptionsResponse } from '../../../metadata/equityAttribution/forTransactions/GetTransactionEquityAttributionDropdownOptionsResponse';
import {
  CompanyEqualizationTypeCode,
  companyEqualizationTypes,
} from '../useGetCompanyTransacitonDetails';

type Props = {
  companyEqualisationType?: CompanyEqualizationTypeCode;
  transactionType: DealingTransactionTypeCode;
  settings: EquityAttributionSelectForTransactionsSettings;
  onLoaded: (response: GetTransactionEquityAttributionDropdownOptionsResponse) => void;
  onError: (error: string) => void;
};

export const PlaceTransactionShareClassSeriesField = ({
  companyEqualisationType,
  transactionType,
  settings,
  onLoaded,
  onError,
}: Props) => {
  const { translate } = useInternationalisation();

  return shouldShowShareClassSeriesField(companyEqualisationType, transactionType) ? (
    <EquityAttributionSelectForTransactionsField
      data-testid={placeTransactionShareClassSeriesSelectTestId}
      fieldName="equityAttributionId"
      label={translate('pages.placeTransaction.labels.shareClassSeries')}
      onError={onError}
      settings={settings}
      onLoaded={onLoaded}
      defaultToOnlyOption={true}
    />
  ) : null;
};

export const shouldShowShareClassSeriesField = (
  companyEqualisationType: CompanyEqualizationTypeCode | undefined | null,
  transactionType: DealingTransactionTypeCode
) => {
  const validTypes: Array<DealingTransactionTypeCode> = [
    dealingTransactionType.FullRedemption,
    dealingTransactionType.Issue,
    dealingTransactionType.Redeem,
    dealingTransactionType.Switch,
    dealingTransactionType.SwitchFull,
  ];

  return (
    companyEqualisationType !== companyEqualizationTypes.limitedPartnership &&
    validTypes.includes(transactionType)
  );
};

export const placeTransactionShareClassSeriesSelectTestId =
  'place-transaction-share-class-series-select';
