import React from 'react';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import styled from 'styled-components/macro';
import { spacing16 } from '../../styling/design/spacing';
import { fontWeightMedium } from '../../styling/design/fonts';

type Props = {
  translationKeys: Array<string>;
};

export const PendingTransactionChaserMessage = ({ translationKeys }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <>
      {translationKeys.map((key) => (
        <MessageContainer key={key.split(':')[0]}>
          {validTranslationKeys.includes(key.split(':')[0]) ? (
            translate(`pages.transactions.status.pendingChaserMessage.${key}`)
          ) : (
            <>{key.split(':')[1]}</>
          )}
        </MessageContainer>
      ))}
    </>
  );
};

const MessageContainer = styled.div`
  font-weight: ${fontWeightMedium};
  text-align: left;

  &:not(:last-child) {
    margin-bottom: ${spacing16};
  }
`;

const validTranslationKeys: Array<string> = [
  'WF1',
  'WF2',
  'WF3',
  'WF4',
  'WF5',
  'WF6',
  'WF7',
  'WF8',
  'WF9',
  'WF10',
  'WF11',
  'WF12',
  'WF13',
  'WF14',
  'WF15',
  'WF16',
  'WF101',
  'WF102',
  'WF103',
  'WF104',
  'WF105',
  'WF106',
  'WF107',
  'WF108',
  'WF109',
  'WF110',
  'WF111',
  'WF112',
  'AML1',
  'AML2',
  'AML23',
  'AML3',
  'AML4',
  'AML5',
  'AML30',
  'AML31',
  'AML6',
  'AML7',
  'AML8',
  'AML9',
  'AML10',
  'AML11',
  'AML13',
  'AML26',
  'AML27',
  'AML12',
  'AML14',
  'AML15',
  'AML16',
  'AML17',
  'AML18',
  'AML24',
  'AML25',
  'AML19',
  'AML20',
  'AML21',
  'AML22',
  'AML28',
];
