import React from 'react';
import { BaseSearchModalQueryParameters, GenericSearchModal } from './GenericSearchModal';
import { Tr, Td } from '../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';
import { ItemDetailsCard } from '../../infrastructure/interface/components/ItemDetailsCard';
import { FieldAndValue } from '../../infrastructure/interface/components/FieldAndValue';
import styled from 'styled-components/macro';
import { spacing16 } from '../../styling/design/spacing';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  currencyId: number;
  onSelectResult: (result: BankSearchResult) => void;
};

export const BankSearchModal = ({ isOpen, onRequestClose, currencyId, onSelectResult }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <GenericSearchModal<GetBankSearchResultsQuery, BankSearchResult>
      title={translate('searchModals.bank.title')}
      inputPlaceholder={translate('searchModals.bank.placeholder')}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      dataEndpointUrl="/api/banking-details/GetBankSearchResults"
      getQueryParameters={(inputs) => ({ currencyId, ...inputs })}
      desktopHeaders={[
        translate('searchModals.bank.headings.bankName'),
        translate('searchModals.bank.headings.bankCode'),
        translate('searchModals.bank.headings.swiftCode'),
      ]}
      renderDesktopRow={(result) => (
        <DesktopRow key={result.counterpartId} result={result} onSelectResult={onSelectResult} />
      )}
      renderMobileItem={(result) => (
        <MobileItemCard
          key={result.counterpartId}
          result={result}
          onSelectResult={onSelectResult}
        />
      )}
    />
  );
};

type ResultProps = {
  result: BankSearchResult;
  onSelectResult: (result: BankSearchResult) => void;
};

const DesktopRow = ({ result, onSelectResult }: ResultProps) => (
  <Tr>
    <Td>
      <MinimalButton
        data-testid={bankSearchModalResultTestId(result.counterpartId)}
        onClick={() => onSelectResult(result)}
        textAlign={'left'}
      >
        {result.bankName}
      </MinimalButton>
    </Td>
    <Td>{result.bankCode}</Td>
    <Td>{result.externalCode}</Td>
  </Tr>
);

const MobileItemCard = ({ result, onSelectResult }: ResultProps) => {
  const { translate } = useInternationalisation();

  return (
    <ItemDetailsCard>
      <MobileBankNameButton
        data-testid={bankSearchModalResultTestId(result.counterpartId)}
        onClick={() => onSelectResult(result)}
        size="large"
        textAlign="left"
      >
        {result.bankName}
      </MobileBankNameButton>
      <FieldAndValue
        fieldLabel={translate('searchModals.bank.headings.bankCode')}
        type="text"
        value={result.bankCode}
      />
      <FieldAndValue
        fieldLabel={translate('searchModals.bank.headings.swiftCode')}
        type="text"
        value={result.externalCode}
      />
    </ItemDetailsCard>
  );
};

const MobileBankNameButton = styled(MinimalButton)`
  margin-bottom: ${spacing16};
`;

type GetBankSearchResultsQuery = BaseSearchModalQueryParameters & {
  currencyId: number;
};

export type BankSearchResult = {
  counterpartId: number;
  bankName: string | null;
  bankCode: string | null;
  externalCode: string | null;
};

export const bankSearchModalResultTestId = (counterpartId: number) =>
  `bank-search-result-${counterpartId}`;
