import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { TextDirection } from '../enums/TextDirection';
import { i18n } from '../i18n';
import { InternationalisationContextValue } from '../types/InternationalisationContextValue';
import { TranslationFile } from '../translations/translationFiles';
import { StringMap } from 'i18next';
import { assertNotBlank } from '../../helpers/stringHelpers';
import { parseDateFromUnknownFormatDateTimeString } from '../../helpers/dateTimeHelpers';
import { assertNotNull } from '../../helpers/nullHelpers';
import { includes } from 'lodash';
import { useIsMounted } from '../../infrastructure/hooks/useIsMounted';
import { useGetJsonWithoutTranslation } from '../../infrastructure/api/useGetJson';
import { useStartupData } from '../../startup/StartupDataContext';

export const useInternationalisationContextValue = (): InternationalisationContextValue => {
  const isMounted = useIsMounted();

  // Unfortunately we can't use the `useQueryParam` hook here because it relies on being called within a routing context.
  const languageFromUrl = decodeURIComponent(
    (queryString.parse(window.location.search)['language'] as string) || ''
  );

  const [selectedLanguageName, setSelectedLanguageName] = useState<string | null>(
    languageFromUrl !== '' ? languageFromUrl : getSelectedLanguageNameFromLocalStorage()
  );

  const { availableLanguageNames, defaultLanguageName, translations} = useStartupData();

  useEffect(() => {
    if (availableLanguageNames != null && isMounted.current) {
      setSelectedLanguageName((currentSelectedLanguageName) =>
        currentSelectedLanguageName != null &&
        availableLanguageNames.includes(currentSelectedLanguageName)
          ? currentSelectedLanguageName
          : defaultLanguageName
      );
    }
  }, [availableLanguageNames, defaultLanguageName]); // eslint-disable-line react-hooks/exhaustive-deps

  const [translationFilesByLanguageName, setTranslationFilesByLanguageName] = useState<
    Record<string, TranslationFile>
  >({});

  const translationFile: TranslationFile | null =
    selectedLanguageName == null
      ? null
      : translationFilesByLanguageName[selectedLanguageName] || null;

  const [isChangingLanguage, setIsChangingLanguage] = useState(false);

  const getTranslationFileRequest = useGetJsonWithoutTranslation<
    GetTranslationFileQueryParameters,
    TranslationFile
  >('/translations');

  useEffect(() => {
    if (selectedLanguageName != null) {
      saveSelectedLanguageNameToLocalStorage(selectedLanguageName);

      if (!translationFilesByLanguageName.hasOwnProperty(selectedLanguageName)) {
        getTranslationFileRequest.makeRequest({
          queryParameters: { timestamp: Date.now() },
          appendPath: encodeURI(`${selectedLanguageName}.json`),
          onSuccess: (response) => {
            if (!isMounted.current) {
              return;
            }

            setTranslationFilesByLanguageName((previous) => {
              return {
                ...previous,
                [selectedLanguageName]: response,
              };
            });

            setIsChangingLanguage(true);

            i18n
              .addResourceBundle(selectedLanguageName, 'translation', response.translation)
              .changeLanguage(selectedLanguageName)
              .finally(() => {
                setIsChangingLanguage(false);
              });
          },
        });
      } else if (isMounted.current) {
        setIsChangingLanguage(true);

        i18n.changeLanguage(selectedLanguageName).finally(() => {
          setIsChangingLanguage(false);
        });
      }
    }
  }, [selectedLanguageName]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatNumber = (value: string | number, options?: FormatNumberOptions): string => {
    const amount = assertAndGetValidNumber(value);
    return new Intl.NumberFormat(getLocale(), {
      minimumFractionDigits: options?.decimalPlaces ?? 0,
      maximumFractionDigits: options?.decimalPlaces ?? 20,
    }).format(amount);
  };

  const formatNumberOrDefault = (
    value?: string | number | null,
    options?: FormatNumberOrDefaultOptions
  ): string => {
    if (value == null) {
      return options?.defaultValue ?? '—';
    }

    return formatNumber(value, { decimalPlaces: options?.decimalPlaces ?? undefined });
  };

  const formatCurrency = (value: string | number, options?: FormatCurrencyOptions): string => {
    const amount = assertAndGetValidNumber(value);
    return new Intl.NumberFormat(getLocale(), {
      style: 'currency',
      currency:
        options?.currencyCode ??
        assertNotNull(translationFile, 'formatCurrency.translationFile').currency,
    }).format(amount);
  };

  const assertAndGetValidNumber = (value: string | number) => {
    if (typeof value === 'string') {
      assertNotBlank(value, `Expected a non-empty numeric string, got ${value}`);
    }

    const amount = Number(value);
    if (isNaN(amount)) {
      throw new Error(`Expected a numeric value, got ${value}`);
    }

    return amount;
  };

  const formatTime = (timestamp: string): string => {
    const date = parseDateFromUnknownFormatDateTimeString(timestamp);

    return new Intl.DateTimeFormat(getLocale(), {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date);
  };

  const formatDate = (timestamp: string, options?: FormatDateOptions): string => {
    const date = parseDateFromUnknownFormatDateTimeString(timestamp);

    if (options != null) {
      return new Intl.DateTimeFormat(getLocale(), options).format(date);
    } else {
      return new Intl.DateTimeFormat(getLocale(), { dateStyle: 'short' }).format(date);
    }
  };

  const formatDateTime = (timestamp: string): string =>
    `${formatDate(timestamp)} ${formatTime(timestamp)}`;

  const formatPercentage = (value: string | number): string => {
    if (typeof value === 'string') {
      assertNotBlank(value, `Expected a non-empty numeric string, got ${value}`);
    }

    const amount = Number(value);

    if (isNaN(amount)) {
      throw new Error(`Expected a numeric value, got ${value}`);
    }

    return new Intl.NumberFormat(getLocale(), {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const translate = (key: string, interpolatedValues: StringMap = {}): string => {
    assertNotBlank(key);
    let translated = i18n.t(key, { replace: interpolatedValues });
    if(translations){
      translated = translations[translated.toLowerCase()] ?? translated;
    }
    
    //Translation for {{{Tags}}}
    while (translated.includes('{{{') && translated.includes('}}}')) {
      let tag = translated.substring(translated.indexOf('{{{')+3, translated.indexOf('}}}'));
      let tagTranslation = tag;
      if (translations && translations[tag.toLowerCase()]) {
        tagTranslation = translations[tag.toLowerCase()];
      }
      translated = translated.replace('{{{' + tag + '}}}', tagTranslation);
    }
    return translated;
  };

  const getLocale = useCallback(
    (): string => assertNotNull(translationFile, 'getLocale.translationFile').locale,
    [translationFile]
  );

  const getTextDirection = (): TextDirection =>
    assertNotNull(translationFile, 'getTextDirection.translationFile').textDirection;

  const getAvailableLanguages = (): Array<string> =>
    assertNotNull(availableLanguageNames, 'getAvailableLanguages.availableLanguages');

  const getSelectedLanguage = () =>
    assertNotNull(selectedLanguageName, 'getSelectedLanguage.selectedLanguageName');

  const setSelectedLanguage = async (languageName: string): Promise<void> => {
    assertNotBlank(languageName);

    if (
      !includes(
        assertNotNull(availableLanguageNames, 'setSelectedLanguage.availableLanguages'),
        languageName
      )
    ) {
      throw new Error(`The specified language (${languageName}) is not supported`);
    }

    setSelectedLanguageName(languageName);

    return Promise.resolve();
  };

  const parseNumber = (numberString: string, locale?: string): number => {
    assertNotBlank(numberString);

    const formattingLocale = locale ?? getLocale();

    const thousandSeparator = Intl.NumberFormat(formattingLocale)
      .format(11111)
      .replace(/\p{Number}/gu, '');

    const decimalSeparator = Intl.NumberFormat(formattingLocale)
      .format(1.1)
      .replace(/\p{Number}/gu, '');

    return parseFloat(
      numberString
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
    );
  };

  const hasLoaded =
    availableLanguageNames != null &&
    translationFile != null &&
    !getTranslationFileRequest.state.inProgress &&
    !isChangingLanguage;

  const failedToLoad = getTranslationFileRequest.state.error != null;

  return {
    hasLoaded,
    failedToLoad,
    formatNumber,
    formatNumberOrDefault,
    formatCurrency,
    formatTime,
    formatDate,
    formatDateTime,
    formatPercentage,
    getLocale,
    getTextDirection,
    getSelectedLanguage,
    setSelectedLanguage,
    getAvailableLanguages,
    translate,
    parseNumber,
  };
};

type GetTranslationFileQueryParameters = {
  timestamp: number; // To prevent browser caching.
};

export type GetAvailableLanguagesResponse = {
  languageNames: Array<string>;
  defaultLanguage: string;
};

export type GetTranslationsResponse = {
    translations: Record<string,string>;
}

export const selectedLanguageNameLocalStorageKey = 'pfs_connect_selected_language_name';

const getSelectedLanguageNameFromLocalStorage = (): string | null => {
  return localStorage.getItem(selectedLanguageNameLocalStorageKey) || null;
};

const saveSelectedLanguageNameToLocalStorage = (languageName: string) => {
  localStorage.setItem(selectedLanguageNameLocalStorageKey, languageName);
};

export type FormatDateOptions = {
  dateStyle?: 'full' | 'long' | 'medium' | 'short';
  day?: 'numeric';
  month?: 'numeric' | 'narrow' | 'short' | 'long';
  year?: 'numeric';
};

export type FormatNumberOptions = {
  decimalPlaces?: number | null;
};

export type FormatNumberOrDefaultOptions = FormatNumberOptions & {
  defaultValue?: string;
};

export type FormatCurrencyOptions = {
  currencyCode?: string;
};
