import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import { GeneralLedgerClassSelectOptionValue } from './GeneralLedgerClassSelect';

export type GetGeneralLedgerClassDropdownOptionsResponse = {
  options: Array<GeneralLedgerClassDropdownOption>;
};

type GeneralLedgerClassDropdownOption = {
  generalLedgerClassId: number;
  label: string;
};

export const mapGeneralLedgerClassDropdownOptionsResponseToSelectOptions = (
  response: GetGeneralLedgerClassDropdownOptionsResponse | null
): SelectOptions<GeneralLedgerClassSelectOptionValue> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.generalLedgerClassId,
        label: option.label,
      }));
