import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { useRef, useState } from 'react';
import axios from 'axios';
import { HttpMethod, makeRequest } from './makeRequest';
import { isErrorResponse, isSuccessResponse } from './ApiResponse';
import { useIsMounted } from '../hooks/useIsMounted';
import { useOnUnmount } from '../hooks/useOnUnmount';
import { useUserActivityContext } from '../../features/authentication/UserActivityContext';
import { useSiteContext } from '../../features/multiTenancy/SiteContext';

type State = {
  inProgress: boolean;
  error: string | null;
};

type Parameters = {
  endpointUrl: string;
  method: HttpMethod;
};

type MakeRequestParameters<TRequestBody, TQueryParameters> = {
  fileName: string;
  requestBody: TRequestBody;
  queryParameters: TQueryParameters;
  onSuccess?: (blob: Blob) => void;
  onError?: (error: string) => void;
};

export const useFileDownload = <
  TRequestBody extends object | undefined = undefined,
  TQueryParameters extends Record<string, any> | undefined = undefined
>({
  endpointUrl,
  method,
}: Parameters) => {
  const userActivityContext = useUserActivityContext();
  const siteContext = useSiteContext();

  const isMounted = useIsMounted();
  const { translate } = useInternationalisation();

  const cancelTokenSource = useRef(axios.CancelToken.source());

  const [state, setState] = useState<State>({
    inProgress: false,
    error: null,
  });

  const downloadFile = ({
    fileName,
    requestBody,
    queryParameters,
    onSuccess,
    onError,
  }: MakeRequestParameters<TRequestBody, TQueryParameters>) => {
    setState({ inProgress: true, error: null });

    makeRequest<object | undefined, Blob>(
      {
        endpointUrl,
        method,
        requestBody: requestBody,
        queryParameters: queryParameters,
        cancelTokenSource: cancelTokenSource.current,
        responseType: 'blob',
      },
      translate,
      siteContext.siteId
    ).then((response) => {
      if (!isMounted.current) {
        // Do nothing
      } else if (isSuccessResponse(response)) {
        const data = response.result;

        if (onSuccess != null) {
          onSuccess(data);
        }

        const linkElement = document.createElement('a');
        const url = window.URL.createObjectURL(data);
        linkElement.href = url;
        linkElement.target = '_blank';
        linkElement.download = fileName;
        linkElement.click();
        setTimeout(() => window.URL.revokeObjectURL(url));

        setState({ inProgress: false, error: null });
      } else {
        const error = isErrorResponse(response) ? response.error : translate('errors.generic');

        if (onError != null) {
          onError(error);
        }

        setState({ inProgress: false, error: error });
      }
    });

    userActivityContext.recordUserActivity();
  };

  const cancelRequest = () => {
    cancelTokenSource.current.cancel();
    cancelTokenSource.current = axios.CancelToken.source();
  };

  useOnUnmount(() => {
    cancelRequest();
  });

  return {
    downloadFile,
    inProgress: state.inProgress,
    error: state.error,
    cancelRequest,
  };
};
