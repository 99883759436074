import React from 'react';
import styled from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';
import { fontSizeCss } from '../../../styling/design/fonts';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { TrueOrFalseIcon } from './TrueOrFalseIcon';
import { colourGrey01, colourGrey07 } from '../../../styling/design/colours';
import {
  FormatCurrencyOptions,
  FormatDateOptions,
  FormatNumberOptions,
} from '../../../internationalisation/hooks/useInternationalisationContextValue';

type Props = { 'data-testid'?: string; className?: string; fieldLabel: string } & (
  | { type: 'text'; value: string | Array<string | null> | null; options?: undefined }
  | { type: 'number'; value: number; options?: FormatNumberOptions }
  | { type: 'boolean'; value: boolean; options?: undefined }
  | { type: 'currency'; value: string | number; options?: FormatCurrencyOptions }
  | { type: 'date'; value: string | null; options?: FormatDateOptions }
  | { type: 'custom'; value: React.ReactNode; options?: undefined }
);

export const FieldAndValue = (props: Props) => {
  const { className, fieldLabel, type, value, options } = props;
  const { formatNumber, formatCurrency, formatDate } = useInternationalisation();

  let displayValue: React.ReactNode = '';

  switch (type) {
    case 'text':
      if (typeof value == 'string' || value === null) {
        displayValue = value;
      } else {
        if (value === undefined) break;
        displayValue = (value as Array<string | null>).map((addressLine, index) => (
          <div key={index}>{addressLine}</div>
        ));
      }
      break;
    case 'number':
      displayValue = formatNumber(value as number, options as FormatNumberOptions);
      break;
    case 'boolean':
      displayValue = (
        <IconContainer>
          <TrueOrFalseIcon value={value as boolean} />
        </IconContainer>
      );
      break;
    case 'currency':
      displayValue = formatCurrency(value as string | number, options as FormatCurrencyOptions);
      break;
    case 'date':
      displayValue =
        value === null ? value : formatDate(value as string, options as FormatDateOptions);
      break;
    case 'custom':
      displayValue = value;
      break;
    default:
      throw new Error(`Invalid field type '${type}'`);
  }

  return (
    <FieldAndValueContainer data-testid={props['data-testid']} className={className}>
      <FieldAndValueLabel>{fieldLabel}</FieldAndValueLabel>
      <FieldAndValueValue>{displayValue}</FieldAndValueValue>
    </FieldAndValueContainer>
  );
};

export const FieldAndValueContainer = styled.div`
  &:not(:last-of-type) {
    margin-bottom: ${spacing16};
  }
`;

export const FieldAndValueLabel = styled.div`
  color: ${colourGrey07};
  ${fontSizeCss('xsmall')};
`;

export const FieldAndValueValue = styled.div`
  &:empty::before {
    content: '\\00a0'; //Inserts non-breaking space to ensure minimum height of one line equivalent
  }
`;

const IconContainer = styled.div`
  svg {
    height: ${spacing16};
    width: ${spacing16};
  }
`;

export const FieldAndValueWithSeparatorsContainer = styled.div`
  > {
    &:not(:last-of-type) {
      padding-bottom: ${spacing16};
      border-bottom: 1px solid ${colourGrey01};
    }
  }
`;
