import React from 'react';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';
import { BankingProductsSelect, BankingProductsSelectOptionValue } from './BankingProductsSelect';
import { GetBankingProductsDropdownOptionsResponse } from './GetBankingProductsDropdownOptionsResponse';

type Props = Omit<
  MetadataSingleSelectFieldComponentProps<
    BankingProductsSelectOptionValue,
    {},
    GetBankingProductsDropdownOptionsResponse
  >,
  'settings'
>;

export const BankingProductsSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    BankingProductsSelectOptionValue,
    {},
    GetBankingProductsDropdownOptionsResponse
  >
    {...props}
    settings={{}}
    renderSelect={(metadataSingleSelectProps) => (
      <BankingProductsSelect {...metadataSingleSelectProps} />
    )}
  />
);
