import styled from 'styled-components/macro';
import { textColours } from '../../../styling/design/colours';
import { fontSizeCss, fontWeightBold } from '../../../styling/design/fonts';
import { spacing16, spacing24 } from '../../../styling/design/spacing';

export const SuccessMessage = styled.div`
  color: ${textColours.positive};
  font-weight: ${fontWeightBold};
  ${fontSizeCss('small')};
  margin-top: ${spacing16};
  margin-bottom: ${spacing24};
`;
