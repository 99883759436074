import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Header2 } from '../../infrastructure/interface/components/Headers';
import { LoadingOverlay as LoadingOverlayComponent } from '../../infrastructure/interface/components/LoadingOverlay';
import { spacing16, spacing24, spacing32, spacing8 } from '../../styling/design/spacing';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { MessageContainer } from './components/MessageContainer';
import { onMobile } from '../../styling/layout/screenBreakpoints';

type Props = {
  headerText: string;
  showLoadingOverlay?: boolean;
  filterSet?: React.ReactNode;
  children?: React.ReactNode;
  headerOrientation?: Orientation;
  showNoDataMessage?: string | null;
};

export const DashboardComponentLayout = ({ headerOrientation = 'row', ...props }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <LayoutContainer>
      <Header orientation={headerOrientation}>
        <HeaderText>{props.headerText}</HeaderText>
        {props.filterSet}
      </Header>
      <LoadingOverlay showOverlay={props.showLoadingOverlay === true}>
        {props.showNoDataMessage && !props.showLoadingOverlay && (
          <MessageContainer>{translate(props.showNoDataMessage)}</MessageContainer>
        )}
        {!props.showNoDataMessage && <>{props.children}</>}
      </LoadingOverlay>
    </LayoutContainer>
  );
};

type Orientation = 'row' | 'column';

const HeaderText = styled(Header2)`
  margin-bottom: 0;
`;

const Header = styled.div<{ orientation: Orientation }>`
  display: flex;
  margin-bottom: ${spacing24};

  ${(props) =>
    props.orientation === 'row' &&
    css`
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `};

  ${(props) =>
    props.orientation === 'column' &&
    css`
      flex-direction: column;
      gap: ${spacing8};
    `}
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing32};
  height: 100%;

  ${onMobile(css`
    padding: ${spacing16};
  `)}
`;

const LoadingOverlay = styled(LoadingOverlayComponent)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
