import React from 'react';
import styled from 'styled-components/macro';
import { FieldValidity } from '../common/FieldValidity';
import { borderColours } from '../../../styling/design/colours';
import { Input, InputIconProps } from '../../interface/forms/Input';
import { FormField } from '../common/FormField';

export const inputFieldTestId = (fieldName: string) => `input-field-${fieldName}`;

type Props = {
  'data-testid'?: string;
  className?: string;
  fieldName: string;
  label?: string;
  helpText?: string;
  warningText?: string;
  disabled?: boolean;
  type?: 'text' | 'number' | 'password' | 'email' | 'phone';
  highlight?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onFieldValueChange?: (value: string) => void;
  tabIndex?: number;
  autoFocus?: boolean;
} & InputIconProps;

export const InputField = (props: Props) => {
  const {
    className,
    fieldName,
    label,
    helpText,
    warningText,
    disabled,
    type,
    highlight,
    readOnly,
    placeholder,
    onFieldValueChange,
    tabIndex,
    autoFocus,
    icon,
  } = props;

  return (
    <FormField<string>
      className={className}
      fieldName={fieldName}
      label={label}
      helpText={helpText}
      warningText={warningText}
      onFieldValueChange={onFieldValueChange}
    >
      {({ field, fieldValidity, formikContext }) => (
        <FieldValidityInput
          {...field}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          data-testid={props['data-testid'] ?? inputFieldTestId(fieldName)}
          fieldValidity={fieldValidity}
          withWarning={warningText != null}
          disabled={disabled || formikContext.isSubmitting}
          type={type}
          highlight={highlight}
          readOnly={readOnly}
          placeholder={placeholder}
          icon={icon}
        />
      )}
    </FormField>
  );
};

export const FieldValidityInput = styled(Input)<{
  fieldValidity: FieldValidity;
  withWarning?: boolean;
}>`
  border-color: ${(props) =>
    props.fieldValidity === 'Valid'
      ? props.withWarning
        ? borderColours.warning
        : borderColours.positive
      : props.fieldValidity === 'Invalid'
      ? borderColours.negative
      : borderColours.disabled};
`;
