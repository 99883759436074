import React, { ChangeEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { spacing12, spacing32 } from '../../../styling/design/spacing';
import {
  backgroundColours,
  borderColours,
  colourGrey03,
  colourWhite,
} from '../../../styling/design/colours';
import { shadow2 } from '../../../styling/design/shadows';

type Props = {
  checked?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  'data-testid'?: string;
};
export const ToggleSwitch = (props: Props) => {
  const { checked, onChange, disabled } = props;

  // If the `checked` prop is provided then the component is "controlled", otherwise it is "uncontrolled" and must
  // maintain its own internal state of whether or not the checkbox is checked.
  const [checkedInternal, setCheckedInternal] = useState(checked ?? false);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;

    if (onChange) {
      onChange(newValue);
    }

    if (checked == null) {
      setCheckedInternal(newValue);
    }
  };

  useEffect(() => {
    if (checked != null) {
      setCheckedInternal(checked);
    }
  }, [checked, setCheckedInternal]);

  const isChecked = checked ?? checkedInternal;

  return (
    <>
      <StyledLabel>
        <input
          data-testid={props['data-testid'] ?? toggleSwitchTestId}
          type="checkbox"
          checked={isChecked}
          onChange={onChangeHandler}
          disabled={disabled}
        />
        <Slider disabled={disabled} />
      </StyledLabel>
    </>
  );
};

const toggleSwitchWidth = '34px';
const toggleSwitchHeight = '22px';

const Slider = styled.span<{ disabled?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: ${spacing32};
  background-color: ${colourGrey03};
  transition: background-color 0.25s ease, border-color 0.25s ease;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${backgroundColours.disabled};
    `}

  &::before {
    position: absolute;
    content: '';
    height: ${spacing12};
    width: ${spacing12};
    left: 5px;
    bottom: 5px;
    border-radius: 50%;
    background-color: ${colourWhite};
    transition: transform 0.25s ease, box-shadow 0.25s ease;
  }
`;

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  height: ${toggleSwitchHeight};
  width: ${toggleSwitchWidth};

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${Slider} {
      background-color: ${borderColours.positive};
    }

    &:checked + ${Slider}:before {
      transform: translateX(${spacing12});
    }

    &:hover + ::before,
    &:focus + ::before {
      box-shadow: ${shadow2};
    }
  }
`;

const toggleSwitchTestId = 'toggle-switch';
