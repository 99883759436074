import React, { useContext } from 'react';
import { every, find, map } from 'lodash';
import { DesktopSummary } from './DesktopSummary';
import { PaginatedTable } from '../../infrastructure/interface/tables/PaginatedTable';
import {
  NoResultsRow,
  Table,
  TBody,
  Th,
  THead,
  Tr,
} from '../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { CompressIcon, ExpandIcon } from '../../icons/icons';
import styled from 'styled-components/macro';
import { fontSizeCss } from '../../styling/design/fonts';
import { colourPrimary05 } from '../../styling/design/colours';
import * as spacing from '../../styling/design/spacing';
import { spacing16, spacing24, spacing32 } from '../../styling/design/spacing';
import { IfUserHasRole } from '../authentication/UserRoles';
import { FieldLabel } from '../../infrastructure/forms/common/FieldLabel';
import { CompanySelect } from '../metadata/company/CompanySelect';
import { HoldingsContext } from './HoldingsContext';
import { ResetFiltersButton } from '../filters/ResetFiltersButton';
import { EquityAttributionSelect } from '../metadata/equityAttribution/EquityAttributionSelect';
import { AgentDealerSelect } from '../metadata/AgentDealerSelect';
import { DatePicker } from '../../infrastructure/interface/forms/DatePicker';
import { EmployeeSelect } from '../metadata/employees/EmployeeSelect';
import { DesktopFund } from './DesktopFund';
import { Panel } from '../../infrastructure/interface/components/Panel';
import { Header1, Header2 } from '../../infrastructure/interface/components/Headers';
import {
  holdingsPageAgentSelectTestId,
  holdingsPageCompanySelectTestId,
  holdingsPageDealerSelectTestId,
  holdingsPageEmployeeSelectTestId,
  holdingsPageEndDatePickerTestId,
  holdingsPageEquityAttributionSelectTestId,
  holdingsPageInvestorSelectTestId,
} from './Holdings';
import { InvestorSelectWithSearchModal } from '../metadata/investor/InvestorSelectWithSearchModal';
import { defaultAgentDealerSelectSettings } from '../metadata/AgentDealerSelectSettings';
import { defaultCompanySelectSettings } from '../metadata/company/CompanySelectSettings';
import { defaultEquityAttributionSelectSettings } from '../metadata/equityAttribution/EquityAttributionSelectSettings';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import { HoldingsDisclaimer } from './HoldingsDisclaimer';
import { ExportHoldingsToExcelButton } from './ExportHoldingsToExcelButton';
import { toggleSort } from '../../infrastructure/interface/tables/SortDirection';
import { SortableTh } from '../../infrastructure/interface/tables/SortableTh';

export const DesktopHoldings = () => (
  <>
    <DesktopHoldingsHeader />
    <FiltersComponent />
    <DesktopSummary />
    <SearchResultsTableComponent />
    <HoldingsDisclaimer />
  </>
);

const DesktopHoldingsHeader = () => {
  const { translate } = useInternationalisation();

  return (
    <HeaderContainer>
      <Header1>{translate('pages.holdings.header')}</Header1>
      <ExportHoldingsToExcelButton />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing16};

  ${Header1} {
    margin: 0;
  }
`;

const FiltersComponent = () => {
  const { translate } = useInternationalisation();
  const {
    companyId,
    setCompanyId,
    investorId,
    setInvestorId,
    equityAttributionId,
    setEquityAttributionId,
    agentId,
    setAgentId,
    dealerId,
    setDealerId,
    employeeId,
    setEmployeeId,
    endDate,
    setEndDate,
    setFilterLoadError,
    resetFilters,
    setCompanySelectLoaded,
    setEquityAttributionSelectLoaded,
    setInvestorSelectLoaded,
    setAgentSelectLoaded,
    setDealerSelectLoaded,
    setEmployeeSelectLoaded,
  } = useContext(HoldingsContext);

  const onCompanySelectLoaded = () => {
    setCompanySelectLoaded(true);
  };

  const onEmployeeSelectLoaded = () => {
    setEmployeeSelectLoaded(true);
  };

  const onInvestorSelectLoaded = () => {
    setInvestorSelectLoaded(true);
  };

  const onEquityAttributionSelectLoaded = () => {
    setEquityAttributionSelectLoaded(true);
  };

  const onAgentSelectLoaded = () => {
    setAgentSelectLoaded(true);
  };

  const onDealerSelectLoaded = () => {
    setDealerSelectLoaded(true);
  };

  return (
    <FilterComponentsContainer>
      <IfUserHasRole userRole="Manager">
        <CompanySelect
          fieldLabel={translate('pages.holdings.filters.company')}
          settings={{
            ...defaultCompanySelectSettings,
            includeAllFunds: true,
            includeBlank: true,
          }}
          value={companyId}
          onChange={setCompanyId}
          data-testid={holdingsPageCompanySelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onCompanySelectLoaded}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <IfUserHasRole userRole="Advisor">
        <EmployeeSelect
          fieldLabel={translate('pages.holdings.filters.advisor')}
          value={employeeId}
          onChange={setEmployeeId}
          data-testid={holdingsPageEmployeeSelectTestId}
          onError={setFilterLoadError}
          clearable={true}
          onLoaded={onEmployeeSelectLoaded}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <IfUserHasRole userRole={['Manager', 'Advisor', 'Consolidated Investor']}>
        <InvestorSelectWithSearchModal
          fieldLabel={translate('pages.holdings.filters.investor')}
          settings={{ forTransacting: false, employeeId }}
          value={investorId}
          onChange={setInvestorId}
          data-testid={holdingsPageInvestorSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onInvestorSelectLoaded}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <IfUserHasRole userRole="Manager">
        <EquityAttributionSelect
          fieldLabel={translate('pages.holdings.filters.equityAttribution')}
          settings={{ ...defaultEquityAttributionSelectSettings, companyId }}
          value={equityAttributionId}
          onChange={setEquityAttributionId}
          data-testid={holdingsPageEquityAttributionSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onEquityAttributionSelectLoaded}
          wrapInDiv={true}
        />

        <AgentDealerSelect
          fieldLabel={translate('pages.holdings.filters.agent')}
          settings={{ ...defaultAgentDealerSelectSettings, isAgent: true }}
          value={agentId}
          onChange={setAgentId}
          data-testid={holdingsPageAgentSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onAgentSelectLoaded}
          wrapInDiv={true}
        />

        <AgentDealerSelect
          fieldLabel={translate('pages.holdings.filters.dealer')}
          settings={{ ...defaultAgentDealerSelectSettings, isAgent: false }}
          value={dealerId}
          onChange={setDealerId}
          data-testid={holdingsPageDealerSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onDealerSelectLoaded}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <div>
        <FieldLabel>{translate('pages.holdings.filters.endDate')}</FieldLabel>
        <DatePicker
          value={endDate}
          onChange={setEndDate}
          data-testid={holdingsPageEndDatePickerTestId}
          clearable={true}
        />
      </div>

      <ResetFiltersButton resetFilters={resetFilters} />
    </FilterComponentsContainer>
  );
};

const SearchResultsTableComponent = () => {
  const { translate } = useInternationalisation();
  const {
    pageNumber,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    latestResponse,
    inProgress,
    showEqualisationAdjustmentMessage,
  } = useContext(HoldingsContext);

  if (latestResponse == null) {
    return <CentredSpinner size="xlarge" />;
  }

  return (
    <Panel>
      <PanelHeaderRow>
        <Header2>{translate('pages.holdings.cards.investmentDetails.header')}</Header2>
        <SecondaryHeaderComponent />
      </PanelHeaderRow>
      <PaginatedTable
        showLoadingOverlay={inProgress}
        resultsPerPage={resultsPerPage}
        totalResultsCount={latestResponse?.total || 0}
        onChangeResultsPerPage={setResultsPerPage}
        currentPageNumber={pageNumber}
        onChangeCurrentPageNumber={setPageNumber}
      >
        <Table>
          <SearchResultsTableHeaderComponent />
          <SearchResultsTableDataComponent />
        </Table>
      </PaginatedTable>
      {showEqualisationAdjustmentMessage &&
        translate('pages.holdings.tables.funds.equalisationAdjustmentMessage')}
    </Panel>
  );
};

const SearchResultsTableHeaderComponent = () => {
  const { translate } = useInternationalisation();
  const {sort,setSort,dir,setDir} =useContext(HoldingsContext);
  const sortClick = (column: string) => {
    toggleSort(column, sort, dir, setSort, setDir);
  };
  
  return (
    <THead>
      <Tr>
        <EmptyTh />
        <IfUserHasRole userRole={['Manager', 'Consolidated Investor', 'Advisor']}>
          <SortableTh dir={dir} sort={sort} columnName={'investor'} onClickSortIcon={sortClick}>{translate('pages.holdings.tables.funds.headings.investor')}</SortableTh>
        </IfUserHasRole>
        <SortableTh dir={dir} sort={sort} columnName={'fund'} onClickSortIcon={sortClick}>{translate('pages.holdings.tables.funds.headings.fund')}</SortableTh>
        <SortableTh dir={dir} sort={sort} columnName={'navDate'} onClickSortIcon={sortClick}>{translate('pages.holdings.tables.funds.headings.navDate')}</SortableTh>
        <SortableTh align="right" dir={dir} sort={sort} columnName={'total'} onClickSortIcon={sortClick}>{translate('pages.holdings.tables.funds.headings.total')}</SortableTh>
      </Tr>
    </THead>
  );
};

const SearchResultsTableDataComponent = () => {
  const { latestResponse, fundExpandedStates, setFundExpandedStates } = useContext(HoldingsContext);

  if (!latestResponse) {
    return null;
  }

  const toggleSingleFund = (fundIndex: number) => {
    const cloned = [...fundExpandedStates];
    const expandedFundState = find(
      cloned,
      (fundExpandedState) => fundExpandedState.id === fundIndex
    );
    if (expandedFundState) {
      expandedFundState.expand = !cloned[fundIndex].expand;
      setFundExpandedStates(cloned);
    }
  };

  const allFunds = [...latestResponse.privateEquityFunds, ...latestResponse.traditionalFunds].sort((n1,n2) => n1.index - n2.index);

  return (
    <TBody>
      {allFunds.map((fund, fundIndex) => {
        return (
          <DesktopFund
            key={fundIndex}
            fund={fund}
            expanded={fundExpandedStates[fundIndex]?.expand || false}
            onToggle={() => toggleSingleFund(fundIndex)}
          />
        );
      })}
      {allFunds.length === 0 && <NoResultsRow colSpan={5} />}
    </TBody>
  );
};

const SecondaryHeaderComponent = () => {
  const { translate } = useInternationalisation();
  const { fundExpandedStates, setFundExpandedStates } = useContext(HoldingsContext);

  if (!fundExpandedStates) {
    return null;
  }

  const setAllFundsExpanded = (expand: boolean) =>
    setFundExpandedStates([...fundExpandedStates].map((fund) => ({ id: fund.id, expand: expand })));

  const areAllFundsExpanded = every(map(fundExpandedStates, 'expand'));

  return (
    <>
      {areAllFundsExpanded ? (
        <SecondaryHeaderContainer onClick={() => setAllFundsExpanded(false)}>
          <CompressIcon />{' '}
          <span>
            {translate('pages.holdings.cards.investmentDetails.secondaryHeader.collapse')}
          </span>
        </SecondaryHeaderContainer>
      ) : (
        <SecondaryHeaderContainer onClick={() => setAllFundsExpanded(true)}>
          <ExpandIcon />{' '}
          <span>{translate('pages.holdings.cards.investmentDetails.secondaryHeader.expand')}</span>
        </SecondaryHeaderContainer>
      )}
    </>
  );
};

const FilterComponentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spacing16};
  margin-bottom: ${spacing32};
`;

const PanelHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing32};

  ${Header2} {
    margin: 0;
  }
`;

const SecondaryHeaderContainer = styled.span`
  ${fontSizeCss('xsmall')};
  cursor: pointer;
  color: ${colourPrimary05};

  svg {
    margin-right: ${spacing.spacing8};
    height: 14px;
    width: 14px;
  }

  span {
    vertical-align: text-bottom;
  }
`;

const EmptyTh = styled(Th)`
  width: ${spacing24};
`;
