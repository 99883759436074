import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  spacing12,
  spacing16,
  spacing256,
  spacing4,
  spacing8,
} from '../../../styling/design/spacing';
import {
  backgroundColours,
  borderColours,
  colourGrey01,
  textColours,
} from '../../../styling/design/colours';
import { fontSizeCss } from '../../../styling/design/fonts';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { MinimalButton } from '../buttons/MinimalButton';

type Props = {
  allowMultiple?: boolean;
  allowedFileTypes?: Array<string>;
  onChange?: (files: Array<File>) => void;
  disabled?: boolean;
  className?: string;
  hiddenInputTestId?: string;
};

export const FilePicker = (props: Props) => {
  const { allowMultiple, allowedFileTypes, onChange, disabled, className } = props;

  const { translate } = useInternationalisation();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);

  const openFileDialog = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };

  const hiddenInputChangeListener = useCallback(() => {
    const fileList = hiddenFileInput.current?.files;

    if (fileList != null) {
      let newSelectedFiles: Array<File> = [];

      for (let index = 0; index < fileList.length; index++) {
        let file = fileList[index];
        newSelectedFiles.push(file);
      }

      setSelectedFiles(newSelectedFiles);
    }
  }, [hiddenFileInput, setSelectedFiles]);

  useEffect(() => {
    const currentHiddenFileInput = hiddenFileInput.current;
    currentHiddenFileInput?.addEventListener('change', hiddenInputChangeListener);

    return () => {
      currentHiddenFileInput?.removeEventListener('change', hiddenInputChangeListener);
    };
  }, [hiddenFileInput, hiddenInputChangeListener]);

  useEffect(() => {
    if (onChange != null) {
      onChange(selectedFiles);
    }
  }, [selectedFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PickerContainer className={className} disabled={disabled ? 'disabled' : ''}>
        <div>{translate('form.filePicker.placeholder')}</div>
        {!disabled && (
          <MinimalButton buttonStyle="primary" onClick={openFileDialog} disabled={disabled}>
            {translate('form.filePicker.browseFiles')}
          </MinimalButton>
        )}
      </PickerContainer>
      <SelectedFiles>
        {selectedFiles.map((file) => (
          <SelectedFile key={file.name} title={file.name}>
            {file.name}
          </SelectedFile>
        ))}
      </SelectedFiles>
      <HiddenFileInput
        ref={hiddenFileInput}
        type="file"
        multiple={allowMultiple}
        accept={allowedFileTypes?.join(',')}
        data-testid={props.hiddenInputTestId}
      />
    </>
  );
};

const PickerContainer = styled.div<{ disabled: 'disabled' | '' }>`
  background: ${backgroundColours.default};
  width: 100%;
  outline: none;
  border: solid 1px ${borderColours.default};
  border-radius: 5px;
  padding: ${spacing12} ${spacing16};
  transition: all 0.25s ease;
  ${fontSizeCss('medium')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.disabled === 'disabled' &&
    css`
      background-color: ${backgroundColours.disabled};
      border-color: ${borderColours.disabled};
      color: ${textColours.disabled};
      cursor: not-allowed;
    `};
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const SelectedFiles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: ${spacing8};
  margin-top: ${spacing8};
`;

const SelectedFile = styled.div`
  padding: ${spacing4} ${spacing16};
  background: ${colourGrey01};
  border-radius: 100px;
  max-width: ${spacing256};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;
