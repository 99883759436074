import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';

const stringIsNumeric = (value: string) => !isNaN(Number(value));

export const beNumeric = (translate: TranslateFunction) => ({
  predicate: stringIsNumeric,
  message: translate('validation.numbers.numeric'),
});

export const beGreaterThan = (operand: number, translate: TranslateFunction) => ({
  predicate: (value: number | string | null) =>
    value != null &&
    (typeof value === 'string'
      ? stringIsNumeric(value) && Number(value) > operand
      : value > operand),
  message: translate('validation.numbers.greaterThan', { operand }),
});

export const bePositive = (translate: TranslateFunction) => beGreaterThan(0, translate);
