import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { EnterUsernameAndPasswordFormModel } from '../EnterUsernameAndPasswordForm';
import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { AuthenticationResult } from '../../AuthenticationResult';
import { UserDetails } from '../../UserDetails';
import { NotificationResponse } from '../../../../styling/layout/sidebar/notifications/NotificationResponse';

/**
 * Sends a SignIn POST Request to the API.
 * @param onSignedIn callback called when a successful sign response is returned from the API.
 */
export const useSignInApiRequest = (
  onSignedIn: (userDetails: UserDetails, notifications: Array<NotificationResponse>) => void
) => {
  const request = usePostJson<SignInCommand, SignInResponse>('/api/authentication/SignIn');
  /* states */
  const [requiresPasswordReset, setRequiresPasswordReset] = useState(false);
  const [requiresUsernameChange, setRequiresUsernameChange] = useState(false);
  const [requiresFirstTimePasswordReset, setRequiresFirstTimePasswordReset] = useState(false);
  const [authenticationResult, setAuthenticationResult] = useState<AuthenticationResult | null>(
    null
  );

  /* Submits request to api */
  const signInWithUsernameAndPassword = (
    formModel: EnterUsernameAndPasswordFormModel,
    formikHelpers: FormikHelpers<EnterUsernameAndPasswordFormModel>
  ) => {
    request.makeRequest({
      requestBody: getSignInCommand(formModel),
      onSuccess: (response) => {
        const authenticationResult = response.result;

        switch (authenticationResult) {
          case 'SignedIn':
            onSignedIn(response.user!, response.notificationsSinceLastLogin);
            break;
          case 'FirstTimePasswordResetRequired':
            formikHelpers.setSubmitting(false);
            setRequiresFirstTimePasswordReset(true);
            break;
          case 'PasswordResetRequired':
            formikHelpers.setSubmitting(false);
            setRequiresPasswordReset(true);
            break;
          case 'UsernameChangedRemovedSpaces':
            formikHelpers.setSubmitting(false);
            setRequiresUsernameChange(true);
            break;
          case 'EmailToSmsMfaRequired':
          case 'TwilioAuthyMfaRequired':
          case 'TwilioAuthyRegistrationRequired':
          case 'GoogleAuthenticatorSetupRequired':
          case 'GoogleAuthenticatorMfaRequired':
          case 'CitiMfaRequired':
            setAuthenticationResult(authenticationResult);
            break;
          default:
            throw new Error(`Unrecognised authentication result: ${authenticationResult}`);
        }
      },
      onFailure: (error, response: any) => {
        console.log(response);
        formikHelpers.setSubmitting(false);
      },
    });
  };

  return {
    signInWithUsernameAndPassword,
    requiresPasswordReset,
    requiresUsernameChange,
    requiresFirstTimePasswordReset,
    authenticationResult,
    setAuthenticationResult,
    signInRequestError: request.state.error,
  };
};

export type SignInCommand = {
  username: string;
  password: string;
};

const getSignInCommand = (formModel: EnterUsernameAndPasswordFormModel): SignInCommand => ({
  username: formModel.username,
  password: formModel.password,
});

export type SignInResponse =
  | {
      result: 'SignedIn';
      user: UserDetails;
      notificationsSinceLastLogin: Array<NotificationResponse>;
    }
  | {
      result: AuthenticationResult;
      user: null;
      notificationsSinceLastLogin: [];
    };
