import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { PrimaryButton } from '../../infrastructure/interface/buttons/PrimaryButton';
import { SecondaryButton } from '../../infrastructure/interface/buttons/SecondaryButton';
import {
  Modal,
  ModalButtonRow,
  ModalHeader,
} from '../../infrastructure/interface/components/Modal';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { borderColours, colourPrimary07 } from '../../styling/design/colours';
import { spacing24, spacing32, spacing4, spacing8 } from '../../styling/design/spacing';
import { UserRoleCodeCharacter } from '../authentication/UserRole';
import { DashboardLoginRole } from './admin/CreateEditDashboardForm';
import {
  DashboardComponentBarChartIcon,
  DashboardComponentGridIcon,
  DashboardComponentLineGraphIcon,
  DashboardComponentPieChartIcon,
  DashboardComponentTableIcon,
} from './componentIcons/dashboardComponentIcons';
import {
  DashboardComponentHeight,
  DashboardComponentWidth,
} from './GetDashboardsForCurrentUserResponse';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onAddComponents: (components: Array<DashboardComponentsModalOption>) => void;
  onError: (error: string) => void;
  componentIdsToIgnore: Array<number>;
  dashboardLoginRole?: DashboardLoginRole;
};

export const DashboardComponentsModal = (props: Props) => {
  const { translate } = useInternationalisation();
  const [selectedComponents, setSelectedComponents] = useState<
    Array<DashboardComponentsModalOption>
  >([]);

  const handleDeselect = (clickedComponent: DashboardComponentsModalOption) => {
    setSelectedComponents(selectedComponents.filter((component) => component !== clickedComponent));
  };

  const getComponentsRequest = useGetJson<
    GetDashboardComponentsModalOptionsQuery,
    GetDashboardComponentsModalOptionsResponse
  >('/api/dashboards/GetDashboardComponentOptions');

  const { response } = getComponentsRequest.state;

  useEffect(() => {
    getComponentsRequest.makeRequest({
      queryParameters: { dashboardLoginRole: props.dashboardLoginRole },
      onFailure: (error) => props.onError(error),
    });
  }, [props.dashboardLoginRole]); // eslint-disable-line react-hooks/exhaustive-deps

  const components = useMemo(
    () =>
      response == null
        ? null
        : response.options.filter(
            (option) => !props.componentIdsToIgnore.some((id) => option.dashboardComponentId === id)
          ),
    [response, props.componentIdsToIgnore]
  );

  useEffect(() => {
    setSelectedComponents(
      selectedComponents.filter((component) => components?.includes(component))
    );
  }, [components]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddComponentButtonClick = () => {
    if (selectedComponents != null) {
      props.onAddComponents(selectedComponents);
      props.onRequestClose();
    }
  };

  return (
    <StyledModal isOpen={props.isOpen} onRequestClose={props.onRequestClose}>
      <ModalHeader
        title={translate('pages.createDashboard.addComponentModalTitle')}
        withCloseButton={true}
        onRequestClose={props.onRequestClose}
      />
      {components == null ? (
        <CentredSpinner size="medium" />
      ) : components.length === 0 ? (
        <NoComponentsMessageContainer>
          {translate('pages.createDashboard.noComponentsAvailable')}
        </NoComponentsMessageContainer>
      ) : (
        <Grid>
          {components.map((component) => (
            <ComponentCard
              key={component.dashboardComponentId}
              component={component}
              onClick={() =>
                selectedComponents.includes(component)
                  ? handleDeselect(component)
                  : setSelectedComponents([...selectedComponents, component])
              }
              data-testid={
                dashboardComponentCardTestIdPrefix + component.dashboardComponentId.toString()
              }
              selected={selectedComponents.includes(component)}
            />
          ))}
        </Grid>
      )}
      <ModalButtonRow>
        <SecondaryButton onClick={props.onRequestClose}>
          {translate('actionButtons.cancel')}
        </SecondaryButton>
        <PrimaryButton
          onClick={onAddComponentButtonClick}
          disabled={!selectedComponents.length}
          data-testid={dashboardComponentsModalSubmitButtonTestId}
        >
          {translate('pages.createDashboard.addComponentButtonText')}
        </PrimaryButton>
      </ModalButtonRow>
    </StyledModal>
  );
};

type ComponentCardProps = {
  component: DashboardComponentsModalOption;
  selected: boolean;
  onClick: () => void;
  'data-testid'?: string;
};

const ComponentCard = (props: ComponentCardProps) => {
  let icon: React.ReactNode;

  switch (props.component.iconFileName) {
    case 'pie-chart.svg':
      icon = <DashboardComponentPieChartIcon />;
      break;
    case 'bar-chart.svg':
      icon = <DashboardComponentBarChartIcon />;
      break;
    case 'line-graph.svg':
      icon = <DashboardComponentLineGraphIcon />;
      break;
    case 'grid.svg':
      icon = <DashboardComponentGridIcon />;
      break;
    case 'table.svg':
      icon = <DashboardComponentTableIcon />;
      break;
    default:
      icon = <DashboardComponentPieChartIcon />;
      break;
  }

  return (
    <ComponentCardContainer
      onClick={props.onClick}
      selected={props.selected}
      data-testid={props['data-testid']}
    >
      {icon}
      {props.component.name}
    </ComponentCardContainer>
  );
};

const ComponentCardContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing8};
  padding: ${spacing24};
  cursor: pointer;

  border: 1px solid ${(props) => (props.selected ? colourPrimary07 : borderColours.default)};
  border-radius: ${spacing4};

  svg {
    height: ${spacing32};
    width: ${spacing32};
  }
`;

const NoComponentsMessageContainer = styled.div`
  text-align: center;
`;

export const dashboardComponentsModalSubmitButtonTestId =
  'dashboard-components-modal-submit-button';

export const dashboardComponentCardTestIdPrefix = 'dashboard-components-modal-component-';

export type GetDashboardComponentsModalOptionsResponse = {
  options: Array<DashboardComponentsModalOption>;
};

type GetDashboardComponentsModalOptionsQuery = {
  dashboardLoginRole?: UserRoleCodeCharacter;
};

export type DashboardComponentsModalOption = {
  dashboardComponentId: number;
  name: string;
  iconFileName: DashboardComponentIconFileName;
  width: DashboardComponentWidth;
  height: DashboardComponentHeight;
};

type DashboardComponentIconFileName =
  | 'bar-chart.svg'
  | 'pie-chart.svg'
  | 'line-graph.svg'
  | 'grid.svg'
  | 'table.svg';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${spacing24};
`;

const StyledModal = styled(Modal)`
  max-height: 60vh;
`;
