import { EditButton } from '../../../../../infrastructure/interface/buttons/EditButton';
import React from 'react';
import { BankingDetail } from '../../GetInvestorBankingDetailsResponse';
import { useInvestorIdUrlParam } from '../../../useInvestorIdUrlParam';
import { useNavigate } from 'react-router-dom';
import { DesktopOnly } from '../../../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../../../styling/layout/MobileOnly';
import { PencilIcon } from '../../../../../icons/icons';
import { ButtonWithIcon } from '../../../../../infrastructure/interface/buttons/ButtonWithIcon';
import { useInternationalisation } from '../../../../../internationalisation/hooks/useInternationalisation';

type Props = {
  bankingDetail: BankingDetail;
};

export const EditBankingDetailsButton = ({ bankingDetail }: Props) => {
  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();
  const navigate = useNavigate();

  const onEdit = () => {
    if (investorId == null) {
      navigate(`/banking-details/${bankingDetail.id}/edit`);
    } else {
      navigate(`/banking-details/${investorId}/${bankingDetail.id}/edit`);
    }
  };

  return (
    <>
      <DesktopOnly>
        <EditButton
          data-testid={editBankingDetailButtonTestId(bankingDetail)}
          onClick={() => onEdit()}
        />
      </DesktopOnly>
      <MobileOnly>
        <ButtonWithIcon
          data-testid={editBankingDetailButtonTestId(bankingDetail)}
          icon={<PencilIcon />}
          buttonStyle="secondary"
          stretch={true}
          size="small"
          onClick={() => onEdit()}
        >
          {translate('actionButtons.edit')}
        </ButtonWithIcon>
      </MobileOnly>
    </>
  );
};

export const editBankingDetailButtonTestId = (bankingDetail: BankingDetail) =>
  `edit-banking-detail-button-${bankingDetail.id}`;
