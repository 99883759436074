import {
  getIsoDatestampFromUnknownFormatDateTimeString,
  getUnixEpochIsoDateStamp,
} from '../../../helpers/dateTimeHelpers';
import queryString from 'query-string';
import { PrivateEquityFund, PrivateEquityHolding } from '../types';

export const usePrivateEquityHoldingUrls = (
  holding: PrivateEquityHolding,
  fund: PrivateEquityFund
): any => {
  if (holding === null || fund === null) return null;
  const valueQueryParams = {
    investorId: fund.investorId,
    companyId: holding.companyId,
    equityAttributionId: holding.equityAttributionId,
    endDate: getIsoDatestampFromUnknownFormatDateTimeString(fund.navDate),
  };
  const valueUrl = `/transactions?${queryString.stringify(valueQueryParams)}`;

  return { valueUrl };
};
