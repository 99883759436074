import React from 'react';
import { Highcharts } from './Highcharts';
import {
  BaseChartProps,
  Chart,
  ChartDataPoint,
  baseChartOptions,
  mapChartDataPointToPointOptions,
} from './Chart';

type Props = BaseChartProps & {
  dataPoints: Array<ChartDataPoint>;
};

export const PieChart = (props: Props) => {
  const chartOptions: Highcharts.Options = {
    ...baseChartOptions(props),
    title: { text: props.title },
    subtitle: { text: props.subtitle },
    chart: {
      ...baseChartOptions(props).chart,
      type: 'pie',
    },
    tooltip: { ...props.tooltipOptions },
    plotOptions: {
      pie: {
        showInLegend: true,
      },
    },
    series: [
      {
        type: 'pie',
        data: props.dataPoints.map((dataPoint) =>
          mapChartDataPointToPointOptions(dataPoint, props.onClickDataPoint)
        ),
      },
    ],
  };

  return <Chart data-testid={props['data-testid'] ?? 'pie-chart'} options={chartOptions} />;
};
