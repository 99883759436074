export type CompanySelectSettings = {
  includeBlank: boolean;
  includeAllFunds: boolean;
  includeAllInvestedFunds: boolean;
  isForReports: boolean;
  excludeClosed: boolean;
  documentCategoryId: number | null;
  amlCheckId: number | null;
  employeeCounterpartId: number | null;
  rememberCompanyId: boolean;
};

export const defaultCompanySelectSettings: CompanySelectSettings = {
  includeBlank: false,
  includeAllFunds: false,
  includeAllInvestedFunds: false,
  isForReports: false,
  excludeClosed: false,
  documentCategoryId: null,
  amlCheckId: null,
  employeeCounterpartId: null,
  rememberCompanyId: true,
};
