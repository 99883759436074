import React from 'react';
import { MetadataSingleSelectComponentProps } from './MetadataSelectProps';
import { FormField } from '../../infrastructure/forms/common/FormField';
import {
  defaultSingleSelectFieldStyles,
  singleSelectFieldTestId,
} from '../../infrastructure/forms/fields/SingleSelectField';
import { ValidMetadataSelectSettings } from './MetadataSingleSelect';

export type ReducedMetadataSingleSelectProps<
  TSelectOptionValue,
  TSettings extends ValidMetadataSelectSettings,
  TResponse
> = Omit<
  MetadataSingleSelectComponentProps<TSelectOptionValue, TSettings, TResponse>,
  'value' | 'onChange' | 'onBlur' | 'name' // We don't want the consumer providing these
>;

/**
 * The props of components that wrap <MetadataSingleSelectField /> should extend this type.
 */
export type MetadataSingleSelectFieldComponentProps<
  TSelectOptionValue,
  TSettings extends ValidMetadataSelectSettings,
  TResponse
> = Omit<
  MetadataSingleSelectFieldProps<TSelectOptionValue, TSettings, TResponse>,
  'renderSelect' | 'label'
> & {
  label?: string;
};

type MetadataSingleSelectFieldProps<
  TSelectOptionValue,
  TSettings extends ValidMetadataSelectSettings,
  TResponse
> = {
  fieldName: string;
  label?: string | null;
  onFieldValueChange?: (value: TSelectOptionValue | null) => void;
  renderSelect: (
    props: MetadataSingleSelectComponentProps<TSelectOptionValue, TSettings, TResponse>
  ) => React.ReactNode;
} & ReducedMetadataSingleSelectProps<TSelectOptionValue, TSettings, TResponse>;

export const MetadataSingleSelectField = <
  TSelectOptionValue,
  TSettings extends ValidMetadataSelectSettings,
  TResponse
>(
  props: MetadataSingleSelectFieldProps<TSelectOptionValue, TSettings, TResponse>
) => {
  const {
    fieldName,
    label,
    settings,
    onFieldValueChange,
    renderSelect,
    className,
    ...reducedMetadataSingleSelectProps
  } = props;

  return (
    <FormField<TSelectOptionValue | null>
      className={className}
      fieldName={fieldName}
      label={label ?? undefined}
      onFieldValueChange={onFieldValueChange}
    >
      {({ field, fieldValidity, formikContext }) => {
        const onChange = (newValue: TSelectOptionValue | null) =>
          formikContext.setFieldValue(field.name, newValue);

        const onBlur = () => formikContext.setFieldTouched(field.name);

        return renderSelect({
          ...reducedMetadataSingleSelectProps,
          settings: settings,
          name: fieldName,
          disabled: reducedMetadataSingleSelectProps.disabled || formikContext.isSubmitting,
          customStyles: defaultSingleSelectFieldStyles(fieldValidity),
          'data-testid':
            reducedMetadataSingleSelectProps['data-testid'] ?? singleSelectFieldTestId(fieldName),
          value: field.value,
          onChange,
          onBlur,
        });
      }}
    </FormField>
  );
};
