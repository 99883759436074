import React from 'react';
import { CompanySelect, CompanySelectOptionValue } from './CompanySelect';
import { CompanySelectSettings } from './CompanySelectSettings';
import { GetCompanyDropdownOptionsResponse } from './GetCompanyDropdownOptionsResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  CompanySelectOptionValue,
  CompanySelectSettings,
  GetCompanyDropdownOptionsResponse
>;

export const CompanySelectField = (props: Props) => (
  <MetadataSingleSelectField<
    CompanySelectOptionValue,
    CompanySelectSettings,
    GetCompanyDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => <CompanySelect {...metadataSingleSelectProps} />}
  />
);
