import { Route } from 'react-router-dom';
import { InvestorDetailsLayout } from './InvestorDetailsLayout';
import { InvestorDetails } from './InvestorDetails';
import { investorIdUrlParamName } from './useInvestorIdUrlParam';
import { EditInvestorDetails } from './editInvestorDetails/EditInvestorDetails';
import React from 'react';

export const InvestorDetailsRoutes = () => [
  <Route path="/investor-details" element={<InvestorDetailsLayout />}>
    <Route index element={<InvestorDetails />} />
    <Route path={`:${investorIdUrlParamName}`} element={<InvestorDetails />} />
  </Route>,
  <Route path="/investor-details/edit" element={<EditInvestorDetails />} />,
  <Route
    path={`/investor-details/:${investorIdUrlParamName}/edit`}
    element={<EditInvestorDetails />}
  />,
];
