import React, { useMemo } from 'react';
import { DashboardResponse } from './GetDashboardsForCurrentUserResponse';
import { SelectOptions } from '../../infrastructure/interface/forms/BaseSelect';
import { SingleSelect } from '../../infrastructure/interface/forms/SingleSelect';

type Props = {
  dashboards: Array<DashboardResponse>;
  selectedDashboardId: number | null;
  onSelectDashboard: (dashboardId: number | null) => void;
};

const getSelectOptions = (dashboards: Array<DashboardResponse>): SelectOptions<number> =>
  dashboards.map((dashboard) => ({
    label: dashboard.description,
    value: dashboard.dashboardId,
  }));

export const DashboardSelect = (props: Props) => {
  const selectOptions = useMemo(() => getSelectOptions(props.dashboards), [props.dashboards]);

  return (
    <SingleSelect
      options={selectOptions}
      value={props.selectedDashboardId}
      onChange={props.onSelectDashboard}
    />
  );
};
