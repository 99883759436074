import { createContext, useContext, useEffect, useState } from 'react';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { investorDetailsComponentResultsPerPageOptions } from '../InvestorDetails';
import { useInvestorDetailsContext } from '../InvestorDetailsContext';
import { useInvestorIdUrlParam } from '../useInvestorIdUrlParam';

type BeneficialOwnersContextValue = {
  pageNumber: number;
  setPageNumber: (value: number) => void;
  resultsPerPage: number;
  setResultsPerPage: (value: number) => void;
  latestResponse: GetInvestorBeneficialOwnersResponse | null;
  requestInProgress: boolean;
};

const BeneficialOwnersContext = createContext<BeneficialOwnersContextValue | undefined>(undefined);

export const useBeneficialOwners = () => {
  const contextValue = useContext(BeneficialOwnersContext);

  if (!contextValue) {
    throw new Error('useBeneficialOwners must be used within a BeneficialOwnersContextProvider');
  }

  return contextValue;
};

type Props = {
  children: React.ReactNode;
};

export const BeneficialOwnersContextProvider = ({ children }: Props) => {
  const { setInnerComponentApiError } = useInvestorDetailsContext();
  const investorId = useInvestorIdUrlParam();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(
    investorDetailsComponentResultsPerPageOptions[0]
  );

  const [latestResponse, setLatestResponse] = useState<GetInvestorBeneficialOwnersResponse | null>(
    null
  );

  const getRequest = useGetJson<
    GetInvestorBeneficialOwnersQuery,
    GetInvestorBeneficialOwnersResponse
  >('/api/investors/GetInvestorBeneficialOwners');

  const { inProgress: requestInProgress } = getRequest.state;

  useEffect(() => {
    if (investorId != null) {
      getRequest.makeRequest({
        queryParameters: {
          investorId,
          pageNumber,
          resultsPerPage,
        },
        onSuccess: (response) => setLatestResponse(response),
        onFailure: (error) => setInnerComponentApiError(error),
      });
    }
  }, [pageNumber, resultsPerPage, investorId]); // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue: BeneficialOwnersContextValue = {
    pageNumber,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    latestResponse,
    requestInProgress,
  };

  return (
    <BeneficialOwnersContext.Provider value={contextValue}>
      {children}
    </BeneficialOwnersContext.Provider>
  );
};

type GetInvestorBeneficialOwnersQuery = {
  investorId: number;
  pageNumber: number;
  resultsPerPage: number;
};

export type GetInvestorBeneficialOwnersResponse = {
  beneficialOwners: Array<BeneficialOwner>;
  totalBeneficialOwnersCount: number;
};

export type BeneficialOwner = {
  counterpartId: number;
  counterpartName: string;
  emailAddress: string | null;
  username: string;
};
