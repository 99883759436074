import { useGetJson } from '../../../../infrastructure/api/useGetJson';

export const GetUsersForBatchDeleteRequest = (
  setLatestResponse: React.Dispatch<
    React.SetStateAction<GetUsersForBatchDeleteResponse | any | null>
  >
) => {
  const request = useGetJson<GetUsersForBatchDeleteQuery, GetUsersForBatchDeleteResponse>(
    '/api/users/GetUsersForBatchDelete'
  );

  const get = (query: GetUsersForBatchDeleteQuery): void | undefined => {
    request.makeRequest({
      queryParameters: query,
      onSuccess: setLatestResponse,
    });
  };

  return {
    get,
    state: request.state,
  };
};

type GetUsersForBatchDeleteQuery = {
  pageNumber: number;
  resultsPerPage: number;
  accessGroupId: number;
};

export type GetUsersForBatchDeleteResponse = {
  users: Array<UsersToDeleteResponse>;
  count: number;
};

export type UsersToDeleteResponse = {
  delete: boolean;
  username: string;
  counterpart: string;
  counterpartId: number;
  emailAddress: string;
  role: string;
  accessGroup: string;
  status: string;
};
