import React, { useContext } from 'react';
import { ReportParameterResponse } from '../../Report';
import { ShareholderSelectField } from '../../../metadata/shareholder/ShareholderSelectField';
import { useReportParameterFieldsContext } from '../ReportParameterFieldsContext';
import { ReportContext } from '../../ReportContext';

type Props = {
  shareholderParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsShareholderSelectField = ({ shareholderParameter, onError }: Props) => {
  const { setFieldLoaded } = useReportParameterFieldsContext();
  const { selectedCompanyOption, selectedCompanyReportGroupOption } = useContext(ReportContext);

  return (
    <ShareholderSelectField
      fieldName={shareholderParameter.name}
      label={shareholderParameter.displayText}
      onError={onError}
      settings={{
        companyId: selectedCompanyOption?.companyId ?? null,
        companyReportGroupId: selectedCompanyReportGroupOption?.companyReportGroupId ?? null,
        employeeCounterpartId: null,
      }}
      defaultToFirstOption={true}
      onLoaded={() => setFieldLoaded(shareholderParameter.name)}
    />
  );
};
