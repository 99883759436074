import { useState } from 'react';
import { KeyIcon } from '../../../icons/icons';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { MinimalButton } from '../../../infrastructure/interface/buttons/MinimalButton';
import { ActionAlert } from '../../../infrastructure/interface/components/ActionAlert';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { AlertModal } from '../../../infrastructure/interface/components/AlertModal';

type Props = {
  username: string;
  emailAddress: string;
};

export const ResetPasswordButton = ({ username, emailAddress }: Props) => {
  const { translate } = useInternationalisation();
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [successIsOpen, setSuccessIsOpen] = useState(false);

  const resetPasswordRequest = usePostJson<ResetPasswordCommand, {}>(
    '/api/authentication/AdminRequestPasswordReset'
  );

  const onInitiateAction = () => {
    resetPasswordRequest.makeRequest({
      requestBody: { username },
      onSuccess: () => {
        setAlertIsOpen(false);
        setSuccessIsOpen(true);
      },
    });
  };

// Remove HTML formatting
  const parser = new DOMParser();
 
  var message = parser.parseFromString(translate('pages.editUser.resetPasswordConfirm.message', {
    username: username,
    emailAddress: emailAddress,
  }), 'text/html').documentElement.textContent;
 
  return (
    <>
      <MinimalButton icon={<KeyIcon />} onClick={() => setAlertIsOpen(true)}>
        {translate('pages.editUser.resetPassword')}
      </MinimalButton>
      <ActionAlert
        alertType="warning"
        isOpen={alertIsOpen}
        title={translate('pages.editUser.resetPasswordAlert.title')}
        message={translate('pages.editUser.resetPasswordAlert.message')}
        cancelButtonText={translate('actionButtons.cancel')}
        onRequestClose={() => setAlertIsOpen(false)}
        confirmButtonText={translate('pages.editUser.resetPasswordAlert.confirmButtonText')}
        onInitiateAction={onInitiateAction}
        actionInProgress={resetPasswordRequest.state.inProgress}
        actionError={resetPasswordRequest.state.error}
      />
      <AlertModal
        alertType="positive"
        isOpen={successIsOpen}
        title={translate('pages.editUser.resetPasswordConfirm.title')}
        message={message}
        closeButtonText={translate('actionButtons.close')}
        onRequestClose={() => setSuccessIsOpen(false)}
      />
    </>
  );
};

type ResetPasswordCommand = {
  username: string;
};
