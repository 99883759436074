import { Validator } from 'fluentvalidation-ts';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { UserDetails } from '../../authentication/UserDetails';
import { notBeNull } from '../../validation/commonValidation';
import { userHasRole } from '../../authentication/UserRoles';
import { bePositive } from '../../validation/numberValidation';
import { placeTransactionInvestorFieldUserRoles } from './placeTransactionUserRoles';
import { PlaceTransactionFormModel } from './PlaceTransaction';
import { shouldShowShareClassSeriesField } from './fields/PlaceTransactionShareClassSeriesField';
import { shouldShowSwitchToFundField } from './fields/PlaceTransactionSwitchToFundField';
import { shouldShowSwitchToShareClassSeriesField } from './fields/PlaceTransactionSwitchToShareClassSeriesField';
import { shouldShowAmountOrSharesField } from './fields/PlaceTransactionAmountOrSharesField';
import { CompanyEqualizationTypeCode } from './useGetCompanyTransacitonDetails';

export class PlaceTransactionFormValidator extends Validator<PlaceTransactionFormModel> {
  constructor(
    translate: TranslateFunction,
    user: UserDetails,
    companyEqualizationType: CompanyEqualizationTypeCode | null
  ) {
    super();

    this.ruleFor('investorId')
      .must(notBeNull(translate))
      .when(() => userHasRole(user, placeTransactionInvestorFieldUserRoles));

    this.ruleFor('companyId').must(notBeNull(translate));

    this.ruleFor('transactionType').must(notBeNull(translate));

    this.ruleFor('dealingDate').must(notBeNull(translate));

    this.ruleFor('equityAttributionId')
      .must(notBeNull(translate))
      .when(
        (formModel) =>
          formModel.transactionType != null &&
          shouldShowShareClassSeriesField(companyEqualizationType, formModel.transactionType)
      );

    this.ruleFor('switchToCompanyId')
      .must(notBeNull(translate))
      .when(
        (formModel) =>
          formModel.transactionType != null &&
          shouldShowSwitchToFundField(formModel.transactionType)
      );

    this.ruleFor('switchToEquityAttributionId')
      .must(notBeNull(translate))
      .when(
        (formModel) =>
          formModel.transactionType != null &&
          shouldShowSwitchToShareClassSeriesField(formModel.transactionType)
      );
    // Exclude Switch Full Transaction type
    this.ruleFor('amountOrShares')
      .must(notBeNull(translate))
      .must(bePositive(translate))
      .when(
        (formModel) =>
          formModel.transactionType != null &&
          formModel.transactionType !== 'X' &&
          shouldShowAmountOrSharesField(companyEqualizationType, formModel.transactionType)
      );
  }
}
