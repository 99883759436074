import styled from 'styled-components/macro';
import { fadeIn } from '../../../styling/design/transitions';
import { Spinner } from './Spinner';
import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
  showOverlay: boolean;
};

export const LoadingOverlay = ({ children, showOverlay, className }: Props) => (
  <LoadingOverlayContainer overlayIsShowing={showOverlay} className={className}>
    {children}
    {showOverlay && (
      <LoadingOverlayBlock>
        <Spinner size="xlarge" />
      </LoadingOverlayBlock>
    )}
  </LoadingOverlayContainer>
);

const LoadingOverlayContainer = styled.div<{ overlayIsShowing: boolean }>`
  position: relative;
  pointer-events: ${(props) => (props.overlayIsShowing ? 'none' : 'all')};
`;

const LoadingOverlayBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  animation: ${fadeIn} 0.5s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
