import React, { useContext, useState } from 'react';
import {
  PrivateEquityFund,
  PrivateEquityHolding,
  TraditionalFund,
  TraditionalHolding,
} from './types';
import { IsoDatestamp } from '../../helpers/dateTimeHelpers';
import { ItemDetailsCard } from '../../infrastructure/interface/components/ItemDetailsCard';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import {
  FieldAndValue,
  FieldAndValueLabel,
} from '../../infrastructure/interface/components/FieldAndValue';
import styled from 'styled-components/macro';
import { fontSizeCss } from '../../styling/design/fonts';
import { Header3 } from '../../infrastructure/interface/components/Headers';
import { spacing24, spacing4, spacing8 } from '../../styling/design/spacing';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { AppLink } from '../../infrastructure/interface/components/AppLink';
import { isNullUndefinedOrBlank } from '../../helpers/stringHelpers';
import { HoldingsContext } from './HoldingsContext';
import queryString from 'query-string';
import { PrimaryButton } from '../../infrastructure/interface/buttons/PrimaryButton';
import { SecondaryButton } from '../../infrastructure/interface/buttons/SecondaryButton';
import { ButtonRow } from '../../infrastructure/interface/buttons/ButtonRow';
import { isTraditionalFund } from './DesktopFund';
import { shadow1 } from '../../styling/design/shadows';
import { useTraditionalFundHoldingUrls } from './hooks/UseTraditionalFundHoldingUrls';
import { useTraditionalHoldingFormattedData } from './hooks/UseTraditionalHoldingFormattedData';
import { usePrivateEquityHoldingUrls } from './hooks/UsePrivateEquityHoldingUrls';
import { usePrivateEquityFormattedData } from './hooks/UsePrivateEquityFormattedData';

export const MobileFund = ({ fund }: MobileFundProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <>
      <CollapsedFundComponent
        fundName={fund.name}
        navDate={fund.navDate}
        total={fund.total}
        currency={fund.currency}
        currencyDecimals={fund.currencyDecimals}
        investorId={fund.investorId}
        investorName={fund.investorName}
        investorNumber={fund.investorNumber}
        currentInvestorHideId={fund.currentInvestorHideId}
        expanded={expanded}
        setExpanded={setExpanded}
        showInvestorDetailsLink={fund.showInvestorDetailsLink}
      />
      {expanded && <ExpandedFundComponent fund={fund} />}
    </>
  );
};

const CollapsedFundComponent = ({
  fundName,
  navDate,
  total,
  currency,
  currencyDecimals,
  investorId,
  investorName,
  investorNumber,
  currentInvestorHideId,
  expanded,
  setExpanded,
  showInvestorDetailsLink,
}: CollapsedFundProps) => {
  const { translate, formatDate, formatNumber } = useInternationalisation();
  const { companyId, equityAttributionId } = useContext(HoldingsContext);
  const { getUser } = useContext(AuthenticationContext);
  const isInvestor = getUser().role === 'Investor';
  const totalQueryParams = {
    companyId,
    equityAttributionId,
  };
  const totalUrl = `/transactions?${queryString.stringify(totalQueryParams)}`;

  return (
    <ItemDetailsCard>
      <FundTitle>
        {isInvestor ? (
          <>{fundName}</>
        ) : showInvestorDetailsLink ? (
          <AppLink to={currentInvestorHideId ? `/investor-details/` : `/investor-details/${investorId}`}>
            {investorName} {!isNullUndefinedOrBlank(investorNumber) && ` (${investorNumber})`}
          </AppLink>
        ) : (
          <>
            {investorName} {!isNullUndefinedOrBlank(investorNumber) && ` (${investorNumber})`}
          </>
        )}
      </FundTitle>
      <FundSubtitle>
        {translate('pages.holdings.tables.funds.headings.navDate')} {formatDate(navDate)}
      </FundSubtitle>
      {!isInvestor && (
        <FieldAndValue
          fieldLabel={translate('pages.holdings.tables.funds.headings.fund')}
          type="text"
          value={fundName}
        />
      )}
      <FieldAndValueLabel>
        {translate('pages.holdings.tables.funds.headings.total')}
      </FieldAndValueLabel>
      <FundTotal>
        <AppLink to={totalUrl}>
          {currency} {formatNumber(total, { decimalPlaces: currencyDecimals })}
        </AppLink>
      </FundTotal>
      <FundButtonRow>
        {expanded ? (
          <PrimaryButton onClick={() => setExpanded(!expanded)} stretch={true}>
            {translate('pages.holdings.tables.funds.buttons.lessDetails.labelText')}
          </PrimaryButton>
        ) : (
          <SecondaryButton onClick={() => setExpanded(!expanded)} stretch={true}>
            {translate('pages.holdings.tables.funds.buttons.moreDetails.labelText')}
          </SecondaryButton>
        )}
      </FundButtonRow>
    </ItemDetailsCard>
  );
};

const TraditionalHoldingMobileDetailComponent = ({
  fund,
  holding,
  index,
}: {
  fund: TraditionalFund;
  holding: TraditionalHolding;
  index: number;
}) => {
  const { translate } = useInternationalisation();
  const { valueUrl, laterTransactionsUrl, totalUrl } = useTraditionalFundHoldingUrls(holding, fund);
  const { units, price, value, laterTransactions, total } = useTraditionalHoldingFormattedData(
    holding,
    fund
  );

  return (
    <ExpandedItemDetailsCard key={index}>
      <FieldAndValue
        type="text"
        fieldLabel={translate(`pages.holdings.tables.traditionalHoldings.headings.series`)}
        value={holding.series}
      />
      <FieldAndValue
        type="custom"
        fieldLabel={translate(`pages.holdings.tables.traditionalHoldings.headings.units`)}
        value={units}
      />
      <FieldAndValue
        type="custom"
        fieldLabel={translate(`pages.holdings.tables.traditionalHoldings.headings.price`)}
        value={price}
      />
      <FieldAndValue
        type="custom"
        fieldLabel={translate(`pages.holdings.tables.traditionalHoldings.headings.value`)}
        value={
          <AppLink to={valueUrl}>
            {' '}
            {value} {holding.hasEqualisationAdjustment && '*'}
          </AppLink>
        }
      />
      <FieldAndValue
        type="custom"
        fieldLabel={translate(
          'pages.holdings.tables.traditionalHoldings.headings.laterTransactions'
        )}
        value={<AppLink to={laterTransactionsUrl}> {laterTransactions}</AppLink>}
      />
      <FieldAndValue
        type="custom"
        fieldLabel={translate(`pages.holdings.tables.traditionalHoldings.headings.total`)}
        value={<AppLink to={totalUrl}> {total}</AppLink>}
      />
    </ExpandedItemDetailsCard>
  );
};

const PrivateEquityHoldingMobileDetailComponent = ({
  privateEquityFund,
  holding,
  index,
}: {
  privateEquityFund: PrivateEquityFund;
  holding: PrivateEquityHolding;
  index: number;
}) => {
  const { translate } = useInternationalisation();
  const { valueUrl } = usePrivateEquityHoldingUrls(holding, privateEquityFund);
  const { committedCapital, accumulatedCashFlow, value, irrPercentage } =
    usePrivateEquityFormattedData(holding);

  return (
    <ExpandedItemDetailsCard key={index}>
      <FieldAndValue
        type="text"
        fieldLabel={translate(`pages.holdings.tables.privateEquityHoldings.headings.series`)}
        value={holding.series}
      />
      <FieldAndValue
        type="custom"
        fieldLabel={translate(
          `pages.holdings.tables.privateEquityHoldings.headings.committedCapital`
        )}
        value={committedCapital}
      />
      <FieldAndValue
        type="custom"
        fieldLabel={translate(
          `pages.holdings.tables.privateEquityHoldings.headings.accumulatedCashFlow`
        )}
        value={accumulatedCashFlow}
      />
      {privateEquityFund.displayIrr ? (
        <FieldAndValue
          type="custom"
          fieldLabel={translate(`pages.holdings.tables.privateEquityHoldings.headings.irr`)}
          value={irrPercentage}
        />
      ) : null}
      <FieldAndValue
        type="custom"
        fieldLabel={translate(`pages.holdings.tables.privateEquityHoldings.headings.value`)}
        value={<AppLink to={valueUrl}>{value}</AppLink>}
      />
    </ExpandedItemDetailsCard>
  );
};
const ExpandedFundComponent = ({ fund }: MobileFundProps) => {
  const TraditionalFundDetails = () => {
    const traditionalFund = fund as TraditionalFund;
    if (!traditionalFund) return null;

    return (
      <>
        {traditionalFund.holdings.map((holding, index) => {
          return (
            <TraditionalHoldingMobileDetailComponent
              key={index}
              fund={traditionalFund}
              holding={holding}
              index={index}
            />
          );
        })}
      </>
    );
  };
  const PrivateEquityFundDetails = () => {
    const privateEquityFund = fund as PrivateEquityFund;

    if (!privateEquityFund) return null;

    return (
      <>
        {privateEquityFund.holdings.map((holding, index) => {
          return (
            <PrivateEquityHoldingMobileDetailComponent
              key={index}
              privateEquityFund={privateEquityFund}
              holding={holding}
              index={index}
            />
          );
        })}
      </>
    );
  };

  return (
    <ExpandedFundComponentContainer>
      {isTraditionalFund(fund) ? <TraditionalFundDetails /> : <PrivateEquityFundDetails />}
    </ExpandedFundComponentContainer>
  );
};

/* Types */
type CollapsedFundProps = {
  fundName: string | null;
  navDate: IsoDatestamp;
  total: number;
  currency: string;
  currencyDecimals: number;
  investorId: number | null;
  investorName: string | null;
  investorNumber: string | null;
  currentInvestorHideId: boolean | false;
  expanded: boolean;
  setExpanded(expand: boolean): void;
  showInvestorDetailsLink: boolean;
};

type MobileFundProps = {
  fund: TraditionalFund | PrivateEquityFund;
};

/* Styled Divs */
const ExpandedFundComponentContainer = styled.div``;

const FundTitle = styled(Header3)`
  margin-bottom: ${spacing4};
`;

const FundSubtitle = styled.div`
  ${fontSizeCss('xsmall')};
  margin-bottom: ${spacing8};
`;

const FundTotal = styled.div`
  margin-top: ${spacing4};
  margin-bottom: ${spacing4};
`;

const FundButtonRow = styled(ButtonRow)`
  margin-top: ${spacing24};
`;

const ExpandedItemDetailsCard = styled(ItemDetailsCard)`
  box-shadow: ${shadow1};

  &:not(:last-child) {
    margin-bottom: ${spacing8};
  }
`;
