import React from 'react';
import { FileUploadRoleSelect, FileUploadRoleSelectOptionValue } from './FileUploadRoleSelect';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';
import { GetFileUploadRoleDropdownOptionsResponse } from './GetFileUploadRoleDropdownOptionsResponse';

type Props = Omit<
  MetadataSingleSelectFieldComponentProps<
    FileUploadRoleSelectOptionValue,
    {},
    GetFileUploadRoleDropdownOptionsResponse
  >,
  'settings'
>;

export const FileUploadRoleSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    FileUploadRoleSelectOptionValue,
    {},
    GetFileUploadRoleDropdownOptionsResponse
  >
    {...props}
    settings={{}}
    renderSelect={(metadataSingleSelectProps) => (
      <FileUploadRoleSelect {...metadataSingleSelectProps} />
    )}
  />
);
