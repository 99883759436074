import React, { useMemo, useState } from 'react';
import { Validator } from 'fluentvalidation-ts';
import { Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import { notBeNull } from '../../validation/commonValidation';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import { Form } from '../../../infrastructure/forms/common/Form';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { RoleSelectField } from '../../metadata/role/RoleSelectField';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { CounterpartSelectField } from './CounterpartSelectField';
import { CounterpartSelectOptionValue } from './CounterpartSelect';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { SubmitButton } from '../../../infrastructure/forms/common/SubmitButton';
import { SecondaryButton } from '../../../infrastructure/interface/buttons/SecondaryButton';
import { useNavigate } from 'react-router';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { RoleSelectOptionValue } from '../../metadata/role/RoleSelect';
import { haveMaxLength, notBeEmpty } from '../../validation/stringValidation';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { AccessGroupSelectField } from '../../metadata/accessGroup/AccessGroupSelectField';
import { defaultAccessGroupSelectSettings } from '../../metadata/accessGroup/AccessGroupSelect';
import { assertNotNull } from '../../../helpers/nullHelpers';
import { Input } from '../../../infrastructure/interface/forms/Input';
import {
  getRoleCodeCharacter,
  isAdminOrViewAsRoleCodeCharacter,
  UserRoleCodeCharacter,
} from '../../authentication/UserRole';
import { FieldLabel } from '../../../infrastructure/forms/common/FieldLabel';
import { MobileNumberInputField } from '../MobileNumberInputField';
import { userMobileNumberMaxLength } from '../userValidationConstants';
import { rootAccessGroupId } from '../../metadata/idConstants';

export const CreateUserForm = () => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();

  const [filterLoadError, setFilterLoadError] = useState<string | null>(null);

  const generateUsernameRequest = usePostJson<GenerateUsernameCommand, GenerateUsernameResponse>(
    '/api/users/GenerateUsername'
  );

  const createUserRequest = usePostJson<CreateUserCommand, CreateUserResponse>(
    '/api/users/CreateUser'
  );

  const generateUsername = (
    command: GenerateUsernameCommand,
    formikProps: FormikProps<CreateUserFormModel>
  ) => {
    generateUsernameRequest.makeRequest({
      requestBody: command,
      onSuccess: (response) => {
        formikProps.setFieldValue('username', response.username, true);
        formikProps.setFieldTouched('username', true);
      },
    });
  };

  const onRoleSelectChange = (
    newRoleCodeCharacter: RoleSelectOptionValue,
    formikProps: FormikProps<CreateUserFormModel>
  ) => {
    if (newRoleCodeCharacter != null) {
      if (isAdminOrViewAsRoleCodeCharacter(newRoleCodeCharacter)) {
        generateUsername(
          {
            counterpartId: null,
          },
          formikProps
        );
      }
    }
  };

  const validator = useMemo(() => new CreateUserFormValidator(translate), [translate]);

  const onSubmit = (
    formModel: CreateUserFormModel,
    formikHelpers: FormikHelpers<CreateUserFormModel>
  ) => {
    const selectedRoleCodeCharacter = formModel.role!;
    const selectedCounterpartId = formModel.counterpartId;
    const username = formModel.username;

    const command: CreateUserCommand = isAdminOrViewAsRoleCodeCharacter(selectedRoleCodeCharacter)
      ? {
          role: selectedRoleCodeCharacter,
          viewAsAccessGroupId:
            selectedRoleCodeCharacter === getRoleCodeCharacter('View As')
              ? assertNotNull(formModel.viewAsAccessGroupId, 'formModel.viewAsAccessGroupId')
              : null,
          counterpartId: selectedCounterpartId,
          username,
          emailAddress: formModel.emailAddress,
          mobileNumber: formModel.mobileNumber,
        }
      : {
          role: selectedRoleCodeCharacter,
          viewAsAccessGroupId: null,
          counterpartId: selectedCounterpartId,
          username,
          emailAddress: null,
          mobileNumber: null,
        };

    createUserRequest.makeRequest({
      requestBody: command,
      onSuccess: () => navigate('/users'),
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  if (filterLoadError != null) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')}>
        {filterLoadError}
      </Alert>
    );
  }

  return (
    <Panel>
      <Formik<CreateUserFormModel>
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={validator.validate}
      >
        {(formikProps) => (
          <Form>
            <RoleSelectField
              fieldName="role"
              label={translate('pages.createUser.labels.role')}
              onFieldValueChange={(selectedRole) => onRoleSelectChange(selectedRole, formikProps)}
              onError={setFilterLoadError}
            />
            {formikProps.values.role != null && (
              <>
                {isAdminOrViewAsRoleCodeCharacter(formikProps.values.role) ? (
                  <AdminFormFields
                    isGeneratingUsername={generateUsernameRequest.state.inProgress}
                    setFilterLoadError={setFilterLoadError}
                  />
                ) : (
                  <NonAdminFormFields
                    generateUsername={(command) => generateUsername(command, formikProps)}
                    isGeneratingUsername={generateUsernameRequest.state.inProgress}
                    setFilterLoadError={setFilterLoadError}
                  />
                )}
              </>
            )}
            <ButtonRow rightAligned={true} withMarginTop={true}>
              <SecondaryButton
                onClick={() => navigate('/users')}
                disabled={formikProps.isSubmitting}
              >
                {translate('pages.createUser.cancelButton.text')}
              </SecondaryButton>
              <SubmitButton
                submittingText={translate('pages.createUser.submitButton.submittingText')}
              >
                {translate('pages.createUser.submitButton.text')}
              </SubmitButton>
            </ButtonRow>
            {createUserRequest.state.error != null && (
              <Alert alertType="negative" withMarginTop={true}>
                {createUserRequest.state.error}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </Panel>
  );
};

type AdminFormFieldsProps = {
  isGeneratingUsername: boolean;
  setFilterLoadError: (error: string | null) => void;
};

const AdminFormFields = ({ isGeneratingUsername, setFilterLoadError }: AdminFormFieldsProps) => {
  const { translate } = useInternationalisation();
  const formikContext = useFormikContext<CreateUserFormModel>();

  return (
    <>
      {formikContext.values.role === getRoleCodeCharacter('View As') && (
        <AccessGroupSelectField
          settings={{ ...defaultAccessGroupSelectSettings, includeBlank: false }}
          fieldName="viewAsAccessGroupId"
          label={translate('pages.createUser.labels.accessGroup')}
          onError={setFilterLoadError}
        />
      )}

      <InputField
        fieldName="username"
        label={translate('pages.createUser.labels.username')}
        disabled={isGeneratingUsername}
      />

      <InputField
        fieldName="emailAddress"
        label={translate('pages.createUser.labels.emailAddress')}
      />

      <MobileNumberInputField
        fieldName="mobileNumber"
        fieldLabel={translate('pages.createUser.labels.mobileNumber')}
        accessGroupId={rootAccessGroupId}
      />
    </>
  );
};

type NonAdminFormFieldsProps = {
  generateUsername: (command: GenerateUsernameCommand) => void;
  isGeneratingUsername: boolean;
  setFilterLoadError: (error: string | null) => void;
};

const NonAdminFormFields = ({
  generateUsername,
  isGeneratingUsername,
  setFilterLoadError,
}: NonAdminFormFieldsProps) => {
  const { translate } = useInternationalisation();
  const formikContext = useFormikContext<CreateUserFormModel>();

  const getAccessGroupForCounterpartRequest = useGetJson<
    GetAccessGroupForCounterpartQuery,
    GetAccessGroupForCounterpartResponse
  >('/api/users/GetAccessGroupForCounterpart');

  const onCounterpartSelectChange = (selectedCounterpartId: CounterpartSelectOptionValue) => {
    if (selectedCounterpartId != null) {
      generateUsername({
        counterpartId: selectedCounterpartId,
      });

      getAccessGroupForCounterpartRequest.makeRequest({
        queryParameters: {
          counterpartId: selectedCounterpartId,
        },
        onSuccess: (response) => {
          formikContext.setFieldValue('noSpecialCharInUsername', response.noSpecialCharInUsername);
        },
      });
    }
  };

  return (
    <>
      <CounterpartSelectField
        fieldName="counterpartId"
        label={translate('pages.createUser.labels.counterpart')}
        onFieldValueChange={(value) => onCounterpartSelectChange(value)}
        onError={setFilterLoadError}
        settings={{ includeBlank: false, role: formikContext.values.role! }}
      />

      <InputField
        fieldName="username"
        label={translate('pages.createUser.labels.username')}
        disabled={isGeneratingUsername}
      />

      <FieldLabel>{translate('pages.createUser.labels.accessGroup')}</FieldLabel>
      <Input
        value={getAccessGroupForCounterpartRequest.state.response?.description}
        readOnly={true}
      />
    </>
  );
};

const initialFormValues: CreateUserFormModel = {
  role: null,
  viewAsAccessGroupId: null,
  counterpartId: null,
  username: '',
  emailAddress: '',
  mobileNumber: '',
  noSpecialCharInUsername: false,
};

export type CreateUserFormModel = {
  role: UserRoleCodeCharacter | null;
  viewAsAccessGroupId: number | null;
  counterpartId: number | null;
  username: string;
  emailAddress: string;
  mobileNumber: string;
  noSpecialCharInUsername: boolean;
};

export type CreateUserCommand = {
  role: string;
  viewAsAccessGroupId: number | null;
  counterpartId: number | null;
  username: string;
  emailAddress: string | null;
  mobileNumber: string | null;
};

export type CreateUserResponse = {
  username: string;
  counterpartId: number;
  counterpart: string;
  role: string;
  viewAsAccessGroupId: string;
  creationDate: IsoDatestamp;
  lastLogin: IsoDatestamp;
  isLockedOut: boolean;
};

export type GenerateUsernameCommand = {
  counterpartId: number | null;
};

export type GenerateUsernameResponse = {
  username: string;
};

type GetAccessGroupForCounterpartQuery = {
  counterpartId: number;
};

export type GetAccessGroupForCounterpartResponse = {
  accessGroupId: number;
  description: string;
  noSpecialCharInUsername: boolean;
};

type UserValidationRule = {
  predicate: (stringValue: string) => boolean;
  message: string;
};

const usernameNoSpaces = (translate: TranslateFunction): UserValidationRule => ({
  predicate: (stringValue: string) => !stringValue.includes(' '),
  message: translate('errors.createUser.invalidUsernameSpace'),
});

class CreateUserFormValidator extends Validator<CreateUserFormModel> {
  constructor(translate: TranslateFunction) {
    super();

    this.ruleFor('role').must(notBeNull(translate));

    this.ruleFor('username').must(notBeEmpty(translate)).must(usernameNoSpaces(translate));

    this.ruleFor('username')
      .emailAddress()
      .withMessage(translate('errors.createUser.invalidUsernameNoSpecial'))
      .when(
        (model) => model.noSpecialCharInUsername && model.username.match(/^[0-9A-Za-z]+$/) === null
      );

    this.ruleFor('viewAsAccessGroupId')
      .must(notBeNull(translate))
      .withMessage(translate('pages.createUser.validation.accessGroup'))
      .when((model) => model.role === getRoleCodeCharacter('View As'));

    this.ruleFor('emailAddress')
      .must(notBeEmpty(translate))
      .emailAddress()
      .withMessage(translate('validation.validEmail'))
      .when((model) => isAdminOrViewAsRoleCodeCharacter(model.role));

    this.ruleFor('mobileNumber')
      .must(notBeEmpty(translate))
      .must(haveMaxLength(userMobileNumberMaxLength, translate))
      .when((model) => isAdminOrViewAsRoleCodeCharacter(model.role));

    this.ruleFor('counterpartId')
      .must(notBeNull(translate))
      .when((model) => !isAdminOrViewAsRoleCodeCharacter(model.role));
  }
}
