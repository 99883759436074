import React, { ChangeEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { backgroundColours, borderColours, textColours } from '../../../styling/design/colours';
import { CheckSolidIcon } from '../../../icons/icons';

export const checkboxTestId = 'checkbox';

type FieldAlignment = 'row' | 'column';

type Props = {
  checked?: boolean;
  name?: string;
  onChange?: (isChecked: boolean) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
  fieldAlignment?: FieldAlignment;
};

export const Checkbox = (props: Props) => {
  const { checked, name, onChange, onBlur, disabled, className, fieldAlignment } = props;
  const [isFocused, setIsFocused] = useState(false);

  // If the `checked` prop is provided then the component is "controlled", otherwise it is "uncontrolled" and must
  // maintain its own internal state of whether or not the checkbox is checked.
  const [checkedInternal, setCheckedInternal] = useState(checked ?? false);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setCheckedInternal(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);

    if (onBlur) {
      onBlur(event);
    }
  };

  useEffect(() => {
    if (checked != null) {
      setCheckedInternal(checked);
    }
  }, [checked, setCheckedInternal]);

  const isChecked = checked ?? checkedInternal;

  return (
    <CheckboxContainer
      className={className}
      focused={isFocused}
      checked={isChecked}
      disabled={disabled}
      fieldAlignment={fieldAlignment}
    >
      <StyledCheckbox
        data-testid={props['data-testid'] ?? checkboxTestId}
        type="checkbox"
        checked={isChecked}
        name={name}
        onFocus={() => setIsFocused(true)}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        disabled={disabled}
      />
      {isChecked && <CheckSolidIcon />}
    </CheckboxContainer>
  );
};

const checkboxSize = '22px';

const CheckboxContainer = styled.div<{
  focused: boolean;
  checked: boolean;
  disabled?: boolean;
  fieldAlignment?: FieldAlignment;
}>`
  transition: all 0.125s ease;
  display: inline-block;
  position: relative;
  height: ${checkboxSize};
  width: ${checkboxSize};
  border-radius: 5px;

  ${(props) =>
    props.fieldAlignment === 'row'
      ? css`
          margin: 11px 0 5px 0;
        `
      : ''};

  outline: 1px solid ${(props) => (props.focused ? borderColours.focus : borderColours.default)};

  background-color: ${(props) =>
    props.checked
      ? props.disabled
        ? backgroundColours.selectedDisabled
        : backgroundColours.selected
      : props.disabled
      ? backgroundColours.disabled
      : backgroundColours.default};

  svg {
    position: absolute;
    height: ${checkboxSize};
    width: ${checkboxSize};
    top: 0;
    left: 0;
    color: ${(props) => (props.checked ? textColours.selected : 'transparent')};
    transform: scale(0.6);
  }
`;

const StyledCheckbox = styled.input`
  cursor: pointer;
  position: absolute;
  height: ${checkboxSize};
  width: ${checkboxSize};
  padding: 0;
  margin: 0;
  opacity: 0;
  top: 0;
  left: 0;
  outline: none;
  border: none;
  z-index: 10;

  &:disabled {
    cursor: not-allowed;
  }
`;
