import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  findCurrencyIdByCode,
  getAvailableCurrencies,
  getDefaultTotalInvestment,
  getAvailableCurrencySelectOptions,
  hideTotalInvestments,
} from './holdingsHelper';
import styled from 'styled-components/macro';
import { spacing16, spacing24, spacing32, spacing8 } from '../../styling/design/spacing';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { AvailableCurrency, Investment, TotalInvestment } from './types';
import { find, isEmpty } from 'lodash';
import { MiniSelect } from '../../infrastructure/interface/forms/MiniSelect';
import { Panel } from '../../infrastructure/interface/components/Panel';
import { fontSizeCss, fontWeightBold, fontWeightMedium } from '../../styling/design/fonts';
import { ChevronDownIcon, ChevronUpIcon } from '../../icons/icons';
import { textColours } from '../../styling/design/colours';
import { HoldingsContext } from './HoldingsContext';
import { LoadingOverlay } from '../../infrastructure/interface/components/LoadingOverlay';

type TotalInvestmentComponentProps = {
  defaultValue: TotalInvestment;
  totalInvestments: Array<TotalInvestment>;
  availableCurrencies: Array<AvailableCurrency>;
  loadingInProgress: boolean;
};
type InvestmentsComponentProps = {
  investments: Array<Investment>;
  loadingInProgress: boolean;
  useTotalLabel: boolean;
};
type InvestmentComponentProps = {
  investment: Investment;
  loadingInProgress: boolean;
  useTotalLabel: boolean;
};

export const DesktopSummary = () => {
  const { latestResponse, inProgress } = useContext(HoldingsContext);
  if (!latestResponse || isEmpty(latestResponse.totalInvestments)) {
    return null;
  }

  const availableCurrencies = getAvailableCurrencies(
    latestResponse.availableCurrencies,
    latestResponse.totalInvestments
  );
  const defaultTotalInvestment = getDefaultTotalInvestment(
    latestResponse.totalInvestments,
    latestResponse.availableCurrencies
  );

  // In cases where there is only a single currency, we hide the TotalInvestmentComponent and treat the
  // single InvestmentsComponent as the total (by changing the title).
  const hideTotal = hideTotalInvestments(
    latestResponse.totalInvestments,
    latestResponse.investments
  );

  return (
    <DesktopSummaryWrapper>
      {!hideTotal && (
        <TotalInvestmentComponent
          defaultValue={defaultTotalInvestment}
          totalInvestments={latestResponse.totalInvestments}
          availableCurrencies={availableCurrencies}
          loadingInProgress={inProgress}
        />
      )}
      <InvestmentsComponent
        investments={latestResponse.investments}
        loadingInProgress={inProgress}
        useTotalLabel={hideTotal}
      />
    </DesktopSummaryWrapper>
  );
};

const TotalInvestmentComponent = ({
  defaultValue,
  totalInvestments,
  availableCurrencies,
  loadingInProgress,
}: TotalInvestmentComponentProps) => {
  const { formatNumber, translate } = useInternationalisation();
  const [totalInvestment, setTotalInvestment] = useState<TotalInvestment>(defaultValue);

  const selectOptions = useMemo(
    () => getAvailableCurrencySelectOptions(availableCurrencies),
    [availableCurrencies]
  );

  const selectedCurrencyId = useMemo(
    () => findCurrencyIdByCode(totalInvestment.currency, availableCurrencies),
    [totalInvestment, availableCurrencies]
  );

  useEffect(() => {
    setTotalInvestment(defaultValue);
  }, [defaultValue]);

  const onChange = (newCurrencyId: number | null) => {
    const newCurrency = find(
      availableCurrencies,
      (availableCurrency) => availableCurrency.currencyId === newCurrencyId
    );

    const newTotalInvestment = find(
      totalInvestments,
      (totalInvestment) => totalInvestment.currency === newCurrency?.currencyCode
    );

    if (newCurrency && newTotalInvestment) {
      setTotalInvestment(newTotalInvestment);
    }
  };

  const renderCurrencySelect = () => {
    if (isEmpty(availableCurrencies) || !selectedCurrencyId) {
      return null;
    }

    if (availableCurrencies.length === 1) {
      return <LabelAndCurrency>{availableCurrencies[0].currencyCode}</LabelAndCurrency>;
    }

    return (
      <MiniSelect<number> options={selectOptions} value={selectedCurrencyId} onChange={onChange} />
    );
  };

  return (
    <InvestmentPanelContainer>
      <SummaryPanel>
        <LoadingOverlay showOverlay={loadingInProgress}>
          <LabelAndCurrency>
            <TotalInvestmentLabel>
              {translate('pages.holdings.cards.totalInvestments.label')}
            </TotalInvestmentLabel>
            {renderCurrencySelect()}
          </LabelAndCurrency>
          <TotalInvestmentAmount>
            {formatNumber(totalInvestment.total, { decimalPlaces: totalInvestment.decimalPlaces })}
          </TotalInvestmentAmount>
        </LoadingOverlay>
      </SummaryPanel>
    </InvestmentPanelContainer>
  );
};

const InvestmentsComponent = ({
  investments,
  loadingInProgress,
  useTotalLabel,
}: InvestmentsComponentProps) => {
  return (
    <>
      {investments &&
        investments.map((investment, index) => (
          <InvestmentComponent
            key={index}
            investment={investment}
            loadingInProgress={loadingInProgress}
            useTotalLabel={useTotalLabel}
          />
        ))}
    </>
  );
};

const InvestmentComponent = ({
  investment,
  loadingInProgress,
  useTotalLabel,
}: InvestmentComponentProps) => {
  const { formatNumber, translate } = useInternationalisation();
  const [laterTransactionsExpanded, setLaterTransactionsExpanded] = useState<boolean>(false);

  const canShowLaterTransactions = useMemo(() => investment.laterTransactions !== 0, [investment]);

  const handleLaterTransactionsClick = () => {
    setLaterTransactionsExpanded((prevState) => !prevState);
  };

  return (
    <InvestmentPanelContainer>
      <SummaryPanel>
        <LoadingOverlay showOverlay={loadingInProgress}>
          <LabelAndCurrency>
            <span>
              {translate(
                useTotalLabel
                  ? 'pages.holdings.cards.totalInvestments.label'
                  : 'pages.holdings.cards.investments.label'
              )}
            </span>
            {investment.currency}
          </LabelAndCurrency>
          <InvestmentAmount>
            {formatNumber(investment.total, { decimalPlaces: investment.decimalPlaces })}
          </InvestmentAmount>
          {canShowLaterTransactions && (
            <LaterTransactions onClick={handleLaterTransactionsClick}>
              <LaterTransactionsText>
                <span>{translate('pages.holdings.cards.investments.footer.prefix')}</span>
                <LaterTransactionsAmount>
                  {formatNumber(investment.laterTransactions, {
                    decimalPlaces: investment.decimalPlaces,
                  })}
                </LaterTransactionsAmount>
              </LaterTransactionsText>
              <LaterTransactionsIcon>
                {laterTransactionsExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </LaterTransactionsIcon>
            </LaterTransactions>
          )}
        </LoadingOverlay>
      </SummaryPanel>
      {canShowLaterTransactions && laterTransactionsExpanded && (
        <LaterTransactionsPanel>
          <LaterTransactionsPanelLabel>
            {translate('pages.holdings.cards.investments.expanded.value.label')}
          </LaterTransactionsPanelLabel>
          <LaterTransactionsPanelAmount>
            {formatNumber(investment.value, { decimalPlaces: investment.decimalPlaces })}
          </LaterTransactionsPanelAmount>
          <LaterTransactionsPanelLabel>
            {translate('pages.holdings.cards.investments.expanded.laterTransactions.label')}
          </LaterTransactionsPanelLabel>
          <LaterTransactionsPanelAmount>
            {formatNumber(investment.laterTransactions, {
              decimalPlaces: investment.decimalPlaces,
            })}
          </LaterTransactionsPanelAmount>
          <LaterTransactionsPanelLabel>
            {translate('pages.holdings.cards.investments.expanded.total.label')}
          </LaterTransactionsPanelLabel>
          <LaterTransactionsPanelAmount>
            {formatNumber(investment.total, { decimalPlaces: investment.decimalPlaces })}
          </LaterTransactionsPanelAmount>
        </LaterTransactionsPanel>
      )}
    </InvestmentPanelContainer>
  );
};

const DesktopSummaryWrapper = styled.div`
  display: grid;
  grid-gap: ${spacing32};
  // Fit components to page width, but wrap to next row when components widths
  // fall below 300px
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: ${spacing32};
`;

const SummaryPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LabelAndCurrency = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LaterTransactions = styled.div`
  line-height: initial;
  margin-top: ${spacing8};
  display: flex;
  cursor: pointer;
  justify-content: space-between;
`;

const LaterTransactionsText = styled.div`
  color: ${textColours.notice};
  ${fontSizeCss('xsmall')};
`;

const LaterTransactionsAmount = styled.div`
  font-weight: ${fontWeightBold};
`;

const LaterTransactionsIcon = styled.span`
  svg {
    width: ${spacing8};
    height: ${spacing8};
  }
`;

const TotalInvestmentAmount = styled.div`
  flex-grow: 1;
  margin-top: ${spacing16};
  font-weight: ${fontWeightBold};
  ${fontSizeCss('xlarge')};
`;

const InvestmentAmount = styled(TotalInvestmentAmount)`
  font-weight: ${fontWeightMedium};
`;

const TotalInvestmentLabel = styled.span`
  flex-grow: 1;
`;

const InvestmentPanelContainer = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const LaterTransactionsPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${spacing24};
  position: absolute;
  top: calc(100% + ${spacing8});
  z-index: 1;
`;

const LaterTransactionsPanelLabel = styled(LabelAndCurrency)`
  font-weight: ${fontWeightBold};

  &:not(:first-child) {
    margin-top: ${spacing8};
  }
`;

const LaterTransactionsPanelAmount = styled(TotalInvestmentAmount)`
  margin-top: ${spacing8};
  font-weight: ${fontWeightMedium};
`;
