import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { spacing12, spacing16, spacing32 } from '../../../styling/design/spacing';
import { backgroundColours, borderColours, textColours } from '../../../styling/design/colours';
import { fontSizeCss } from '../../../styling/design/fonts';

export type Tab = {
  title: string;
  content: React.ReactNode;
  disabled?: boolean;
};

type TabsProps = {
  tabs: Array<Tab>;
};

export const Tabs = ({ tabs }: TabsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  return (
    <>
      <TabHeadings>
        {tabs.map((tab, tabIndex) => (
          <TabHeading
            key={tabIndex}
            active={tabIndex === selectedTabIndex}
            title={tab.title}
            onSelect={() => setSelectedTabIndex(tabIndex)}
            disabled={tab.disabled}
          />
        ))}
      </TabHeadings>
      {tabs.map((tab, tabIndex) => (
        <TabContent
          data-testid={tabContentTestId(tabIndex)}
          key={tabIndex}
          hidden={tabIndex !== selectedTabIndex}
        >
          {tab.content}
        </TabContent>
      ))}
    </>
  );
};

export const TabHeadings = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type TabHeadingProps = {
  active: boolean;
  title: string;
  onSelect: () => void;
  disabled?: boolean;
};

export const activeTabHeaderTestId = `active-tab-header`;

const TabHeading = ({ active, title, onSelect, disabled }: TabHeadingProps) => (
  <TabHeadingContainer
    active={active}
    disabled={disabled}
    onClick={() => !disabled && onSelect()}
    data-testid={active ? activeTabHeaderTestId : null}
  >
    <TabTitle>{title}</TabTitle>
  </TabHeadingContainer>
);

const TabHeadingContainer = styled.div<{ active: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${spacing12} ${spacing32};
  margin-right: -1px;
  margin-bottom: -1px;

  border: solid 1px
    ${(props) =>
      props.disabled
        ? borderColours.disabled
        : props.active
        ? borderColours.selected
        : borderColours.default};
  border-radius: 5px 5px 0 0;

  color: ${(props) =>
    props.disabled
      ? textColours.disabled
      : props.active
      ? textColours.selected
      : textColours.default};
  background-color: ${(props) =>
    props.disabled
      ? backgroundColours.disabled
      : props.active
      ? backgroundColours.selected
      : backgroundColours.default};

  cursor: ${(props) => (props.disabled ? 'not-allowed' : props.active ? 'default' : 'pointer')};
  user-select: none;

  ${fontSizeCss('medium')};

  ${(props) =>
    !props.disabled &&
    !props.active &&
    css`
      &:hover {
        background-color: ${backgroundColours.defaultHover};
      }
    `};

  transition: background-color 0.25s ease;
`;

const TabTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const tabContentTestId = (tabIndex: number) => `tab-${tabIndex}`;

export const TabContent = styled.div<{ hidden: boolean }>`
  position: relative;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  padding: ${spacing32};
  border: solid 1px ${borderColours.default};
  border-radius: 0 10px 10px 10px;
  background-color: ${backgroundColours.default};
  margin-bottom: ${spacing16};
`;
