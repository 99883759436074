import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { spacing16, spacing8 } from '../../../styling/design/spacing';
import { backgroundColours, borderColours } from '../../../styling/design/colours';
import { TableLoadingOverlay, Td, Tr } from './Table';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { MiniSelect } from '../forms/MiniSelect';
import { SelectOptions } from '../forms/BaseSelect';
import { PaginationControl } from './PaginationControl';

export const defaultInitialResultsPerPageOption = 10;
export const defaultResultsPerPageOptions = [5, 10, 25, 50, 100];

type PaginationControlsProps = {
  resultsPerPageOptions?: Array<number>;
  resultsPerPage: number;
  totalResultsCount: number | undefined;
  onChangeResultsPerPage: (resultsPerPage: number) => void;
  currentPageNumber: number;
  onChangeCurrentPageNumber: (currentPageNumber: number) => void;
};

type Props = {
  children: React.ReactNode;
  showLoadingOverlay: boolean;
} & PaginationControlsProps;

export const PaginatedTable = (props: Props) => {
  return (
    <PaginatedTableBaseContainer>
      <TableLoadingOverlay showOverlay={props.showLoadingOverlay}>
        {props.children}
      </TableLoadingOverlay>
      <PaginationControls {...props} />
    </PaginatedTableBaseContainer>
  );
};

const PaginatedTableBaseContainer = styled.div`
  ${Tr}:last-of-type {
    ${Td} {
      border-radius: 0;
    }
  }
`;

const PaginationControls = ({
  resultsPerPageOptions,
  resultsPerPage,
  totalResultsCount,
  onChangeResultsPerPage,
  currentPageNumber,
  onChangeCurrentPageNumber,
}: PaginationControlsProps) => {
  const { translate } = useInternationalisation();

  const totalPageCount = Math.ceil(totalResultsCount! / resultsPerPage);

  const resultsPerPageSelectOptions: SelectOptions<number> = useMemo(
    () => resultsPerPageOptionsToSelectOptions(resultsPerPageOptions),
    [resultsPerPageOptions]
  );

  const onResultsPerPageSelectChange = (newValue: number) => {
    onChangeResultsPerPage(newValue);
    onChangeCurrentPageNumber(1);
  };

  return (
    <PaginationControlsContainer>
      <ResultsPerPageContainer>
        <ResultsPerPageText>{translate('tables.pagination.resultsPerPage')}</ResultsPerPageText>
        <MiniSelect<number>
          options={resultsPerPageSelectOptions}
          value={resultsPerPage}
          onChange={(newValue) => onResultsPerPageSelectChange(newValue!)}
          menuPlacement="top"
        />
      </ResultsPerPageContainer>
      <PaginationControl
        totalPages={totalPageCount}
        currentPage={currentPageNumber}
        setCurrentPage={onChangeCurrentPageNumber}
      />
    </PaginationControlsContainer>
  );
};

const PaginationControlsContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${backgroundColours.default};
  padding: ${spacing16};
  border: solid 1px ${borderColours.default};
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const ResultsPerPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ResultsPerPageText = styled.div`
  margin-right: ${spacing8};
`;

export const resultsPerPageOptionsToSelectOptions = (resultsPerPageOptions?: Array<number>) => {
  return (resultsPerPageOptions ?? defaultResultsPerPageOptions).map((option) => ({
    value: option,
    label: option.toString(),
  }));
};
