import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  GetCompanyReportGroupDropdownOptionsResponse,
  mapCompanyReportGroupDropdownOptionsResponseToSelectOptions,
} from './GetCompanyReportGroupDropdownOptionsResponse';
import { CompanyReportGroupSelectSettings } from './CompanyReportGroupSelectSettings';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';

export type CompanyReportGroupSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  CompanyReportGroupSelectOptionValue,
  CompanyReportGroupSelectSettings,
  GetCompanyReportGroupDropdownOptionsResponse
>;

export const CompanyReportGroupSelect = ({ settings, ...metadataSingleSelectProps }: Props) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.companyReportGroupSelect.placeholder');
  const loadingPlaceholder = translate('metadata.companyReportGroupSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      CompanyReportGroupSelectOptionValue,
      CompanyReportGroupSelectSettings,
      GetCompanyReportGroupDropdownOptionsRequest,
      GetCompanyReportGroupDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetCompanyReportGroupDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapCompanyReportGroupDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={350}
      {...metadataSingleSelectProps}
    />
  );
};

export type GetCompanyReportGroupDropdownOptionsRequest = {
  includeBlank: boolean;
  isForUpload: boolean;
  isForReports: boolean;
  employeeCounterpartId: number | null;
};
