import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetCompanyDropdownOptionsResponse = {
  options: Array<CompanyDropdownOptionResponse>;
};

export type CompanyDropdownOptionResponse = {
  companyId: number;
  companyName: string;
};

export const mapCompanyDropdownOptionsResponseToSelectOptions = (
  response: GetCompanyDropdownOptionsResponse | null
): SelectOptions<number> =>
  !response
    ? []
    : response.options.map((option) => ({
        value: option.companyId,
        label: option.companyName,
      }));
