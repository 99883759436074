import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetEmployeeDropdownOptionsResponse = {
  options: Array<EmployeeDropdownOptionResponse>;
};

export type EmployeeDropdownOptionResponse = {
  counterpartId: number;
  employeeName: string;
};

export const mapEmployeeDropdownOptionsResponseToSelectOptions = (
  response: GetEmployeeDropdownOptionsResponse | null
): SelectOptions<number> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.counterpartId,
        label: option.employeeName,
      }));
