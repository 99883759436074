import React from 'react';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from './MetadataSingleSelectField';
import { AgentDealerSelectSettings } from './AgentDealerSelectSettings';
import { GetAgentDealerDropdownOptionsResponse } from './GetAgentDealerDropdownOptionsResponse';
import { AgentDealerSelect, AgentDealerSelectOptionValue } from './AgentDealerSelect';

type Props = MetadataSingleSelectFieldComponentProps<
  AgentDealerSelectOptionValue,
  AgentDealerSelectSettings,
  GetAgentDealerDropdownOptionsResponse
>;

export const AgentDealerSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    AgentDealerSelectOptionValue,
    AgentDealerSelectSettings,
    GetAgentDealerDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <AgentDealerSelect {...metadataSingleSelectProps} />
    )}
  />
);
