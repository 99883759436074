import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { FilterIcon } from '../../icons/icons';
import { useOnClickOutside } from '../../infrastructure/hooks/useOnClickOutside';
import {
  PopoverMenu,
  PopoverMenuContainer,
} from '../../infrastructure/interface/components/PopoverMenu';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { borderColours } from '../../styling/design/colours';
import { fontFamily, fontSizeCss } from '../../styling/design/fonts';
import { spacing16 } from '../../styling/design/spacing';

type Props = {
  children?: React.ReactNode;
};

export const MobileFilterSet = (props: Props) => {
  const { translate } = useInternationalisation();
  const popoverMenuRef = useRef<HTMLDivElement>(null);
  const [popoverMenuIsOpen, setPopoverMenuIsOpen] = useState<boolean>(false);
  useOnClickOutside(popoverMenuRef, () => setPopoverMenuIsOpen(false));

  return (
    <PopoverMenuContainer ref={popoverMenuRef}>
      <FiltersButton
        menuIsOpen={popoverMenuIsOpen}
        onClick={() => setPopoverMenuIsOpen(!popoverMenuIsOpen)}
      >
        {translate('filters.mobileFilterSetText')}
        <FilterIcon />
      </FiltersButton>
      <StyledPopoverMenu isOpen={popoverMenuIsOpen}>{props.children}</StyledPopoverMenu>
    </PopoverMenuContainer>
  );
};

const FiltersButton = styled.button<{ menuIsOpen: boolean }>`
  outline: none;
  background: none;
  width: 100%;
  font-family: ${fontFamily};
  ${fontSizeCss('medium')};
  border-radius: 5px;
  border: solid 1px
    ${(props) => (props.menuIsOpen ? borderColours.selected : borderColours.default)};
  padding: ${spacing16};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    height: ${spacing16};
    width: ${spacing16};
  }
`;

const StyledPopoverMenu = styled(PopoverMenu)<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: ${spacing16};
  padding: ${spacing16};
  top: 61px;
  width: 100%;
`;
