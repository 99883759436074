import React, { createContext, useContext, useState } from 'react';
import queryString from 'query-string';
import { parseIntOrNull } from '../../helpers/stringHelpers';
import { useSiteIdQueryParam } from '../../styling/branding/useGroupIdQueryParam';

export const siteIdQueryParameterKey = 'SiteId';

type SiteContextValue = {
  siteId: number | null;
  setSiteId: (newSiteId: number | null) => void;
};

export const SiteContext = createContext<SiteContextValue | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const SiteContextProvider = (props: Props) => {
  let siteIdFromQuery = useSiteIdQueryParam();
  const [siteId, setSiteId] = useState<number | null>(siteIdFromQuery);

  return (
    <SiteContext.Provider value={{ siteId, setSiteId }}>{props.children}</SiteContext.Provider>
  );
};

export const useSiteContext = (): SiteContextValue => {
  const siteContext = useContext(SiteContext);

  if (siteContext == null) {
    throw new Error('useSiteContext must be called within a <SiteContextProvider />');
  }

  return siteContext;
};
