import { useGetJson } from '../../../infrastructure/api/useGetJson';

import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { useDashboardFilters } from '../DashboardFiltersContext';
import { useEffect } from 'react';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { ReportParameterName } from '../../reports/parameters/ReportParameterName';
import { autoRunReportQueryParameterName } from '../../reports/parameters/ReportParameterFieldsContext';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import {
  Table,
  TBody,
  Th,
  Tr,
  THeadMobile,
  TdMobile,
  MobileHeaderCell,
} from '../../../infrastructure/interface/tables/Table';
import { AppLink } from '../../../infrastructure/interface/components/AppLink';
import { ComponentResponse } from '../DashboardComponent';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
export const TopTwentyInvestorsComponentName = 'Top 20 Investors - PE';
export const TopTwentyInvestors = () => {
  const endpointUrl = 'api/dashboards/GetDataForTop20PrivateEquityInvestorsTable';
  const getRequest = useGetJson<GetTopTwentyInvestorsQuery, GetTopTwentyInvestorsResponse>(
    endpointUrl
  );

  const { companyId, fromDate } = useDashboardFilters();
  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <PrivateTopTwentyInvestorsComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};
/////
type ComponentProps = {
  response: GetTopTwentyInvestorsResponse;
  showLoadingOverlay: boolean;
};

const PrivateTopTwentyInvestorsComponent = ({ response, showLoadingOverlay }: ComponentProps) => {
  const { translate } = useInternationalisation();
  const { companyId } = useDashboardFilters();

  const investorCommitmentReportParameters = {
    [ReportParameterName.CompanyId]: companyId,
    [autoRunReportQueryParameterName]: true,
  };

  const headers = {
    investorName: translate(
      'pages.dashboard.components.topTwentyInvestors.headerNames.investorName'
    ),
    committedCapital: translate(
      'pages.dashboard.components.topTwentyInvestors.headerNames.committedCapital'
    ),
    accumulatedCashflow: translate(
      'pages.dashboard.components.topTwentyInvestors.headerNames.accumulatedCashflow'
    ),
    iRR: translate('pages.dashboard.components.topTwentyInvestors.headerNames.iRR'),
    value: translate('pages.dashboard.components.topTwentyInvestors.headerNames.value'),
    summary: translate('pages.dashboard.components.topTwentyInvestors.headerNames.summary'),
  };

  return (
    <DashboardComponentLayout
      headerText={translate('pages.dashboard.components.topTwentyInvestors.title')}
      showLoadingOverlay={showLoadingOverlay}
      showNoDataMessage={response.noDataMessage}
    >
      <Table compact={true}>
        <THeadMobile>
          <Tr>
            <Th>{headers.investorName}</Th>
            <Th>{headers.committedCapital}</Th>
            <Th>{headers.accumulatedCashflow}</Th>
            {response.displayIrr && <Th>{headers.iRR}</Th>}
            <Th>{headers.value}</Th>
          </Tr>
        </THeadMobile>

        <TBody>
          {response.investorRows.map((row, index) => (
            <Tr key={index}>
              <TdMobile data-label={headers.investorName}>
                {row.shareholderId != null ? (
                  <AppLink
                    to={`/investor-details/${row.shareholderId}                
                        `}
                  >
                    {row.investorName}
                  </AppLink>
                ) : (
                  row.investorName
                )}
              </TdMobile>
              <TdMobile data-label={headers.committedCapital}>{row.committedCapital}</TdMobile>
              <TdMobile data-label={headers.accumulatedCashflow}>
                {row.accumulatedCashflow}
              </TdMobile>
              {response.displayIrr && <TdMobile data-label={headers.iRR}>{row.irr}</TdMobile>}
              <TdMobile data-label={headers.value}>{row.value}</TdMobile>
            </Tr>
          ))}

          <Tr>
            <DesktopOnly>
              <Th></Th>
              <Th>{response.totals?.committedCapital}</Th>
              <Th>{response.totals?.accumulatedCashflow}</Th>
              {response.displayIrr && <Th></Th>}
              <Th>{response.totals?.value}</Th>
            </DesktopOnly>

            <MobileOnly>
              <MobileHeaderCell data-label={headers.summary}></MobileHeaderCell>
              <TdMobile data-label={headers.committedCapital}>
                {response.totals?.committedCapital}
              </TdMobile>
              <TdMobile data-label={headers.accumulatedCashflow}>
                {response.totals?.accumulatedCashflow}
              </TdMobile>
              <TdMobile data-label={headers.value}>{response.totals?.value}</TdMobile>
            </MobileOnly>
          </Tr>
        </TBody>
      </Table>
    </DashboardComponentLayout>
  );
};

type GetTopTwentyInvestorsQuery = {
  companyId: number;
  runDate: IsoDatestamp;
};

type GetTopTwentyInvestorsResponse = ComponentResponse & {
  investorRows: Array<InvestorRow>;
  totals: ComponentTotal;
  displayIrr: boolean;
};

type InvestorRow = {
  investorName: string | null;
  committedCapital: string | null;
  accumulatedCashflow: string | null;
  irr: string | null;
  value: string | null;
  shareholderId: number | null;
};

type ComponentTotal = {
  committedCapital: string | null;
  accumulatedCashflow: string | null;
  value: string | null;
};
