import React, { useEffect, useRef } from 'react';

type Props = {
  html: string | null;
};

export const TermsAndConditionsHtml = ({ html }: Props) => {
  const htmlContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (htmlContainer.current != null && html != null) {
      htmlContainer.current.innerHTML = html ?? '';
    }
  }, [htmlContainer, html]);

  return <div ref={htmlContainer} />;
};
