import React from 'react';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { ApiRequestStateWrapper } from '../../infrastructure/api/ApiRequestStateWrapper';
import { CentredPaddedPage } from '../../styling/layout/PaddedPage';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { Panel } from '../../infrastructure/interface/components/Panel';
import {
  FieldAndValue,
  FieldAndValueWithSeparatorsContainer,
} from '../../infrastructure/interface/components/FieldAndValue';
import { MailToLink } from '../../infrastructure/interface/components/ExternalLink';

export const ContactUs = ({ isInModal }: { isInModal?: boolean }) => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.contactUs.title'));

  const getContactUsDataRequest = useGetJson<{}, GetDataForContactUsPageResponse>(
    '/api/contacts/GetDataForContactUsPage'
  );

  useOnMount(() => {
    getContactUsDataRequest.makeRequest();
  });

  const ContactUsContent = () => (
    <ApiRequestStateWrapper
      apiRequestState={getContactUsDataRequest.state}
      retry={getContactUsDataRequest.makeRequest}
    >
      {(response) => (
        <>
          <FieldAndValueWithSeparatorsContainer>
            <FieldAndValue
              fieldLabel={translate('pages.contactUs.labels.address')}
              type="text"
              value={[
                response.longName,
                response.mailingAddressLine1,
                response.mailingAddressLine2,
                response.mailingAddressLine3,
                response.mailingAddressLine4,
              ]}
            />
            {response.telephoneNumber!= null && (
              <FieldAndValue
                fieldLabel={translate('pages.contactUs.labels.telephoneNumber')}
                type="text"
                value={response.telephoneNumber}
              />

            )}                     
            
            {response.facsimileNumber != null && (
              <FieldAndValue
                fieldLabel={translate('pages.contactUs.labels.fax')}
                type="text"
                value={response.facsimileNumber}
              />
            )}
            {response.emailAddress != null && (
              <FieldAndValue
                fieldLabel={translate('pages.contactUs.labels.email')}
                type="custom"
                value={<MailToLink emailAddress={response.emailAddress} />}
              />
            )}
          </FieldAndValueWithSeparatorsContainer>
        </>
      )}
    </ApiRequestStateWrapper>
  );

  if (isInModal) {
    return <ContactUsContent />;
  }

  return (
    <CentredPaddedPage>
      <Header1>{translate('pages.contactUs.header')}</Header1>
      <Panel>
        <ContactUsContent />
      </Panel>
    </CentredPaddedPage>
  );
};

export type GetDataForContactUsPageResponse = {
  longName: string;
  contactPerson: string | null;
  mailingAddressLine1: string | null;
  mailingAddressLine2: string | null;
  mailingAddressLine3: string | null;
  mailingAddressLine4: string | null;
  telephoneNumber: string;
  facsimileNumber: string | null;
  emailAddress: string | null;
};
