import { useEffect, useState } from 'react';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import styled from 'styled-components/macro';
import { Header2 } from '../../../infrastructure/interface/components/Headers';
import { spacing16, spacing48 } from '../../../styling/design/spacing';
import { useGroupIdQueryParam } from '../../../styling/branding/useGroupIdQueryParam';

export const LoginTitle = () => {
  const [loginTitleText, setLoginTitleText] = useState<string | null>(null);
  const [groupId] = useState(useGroupIdQueryParam());

  const request = useGetJson<GetLoginTitleQuery, GetLoginTitleResponse>(
    `api/branding/GetLoginTitle`
  );
  const { translate } = useInternationalisation();

  useEffect(() => {
    request.makeRequest({
      queryParameters:
        groupId != null
          ? {
              groupId: groupId,
            }
          : undefined,
      onSuccess: (response) => setLoginTitleText(response.title),
      onFailure: (error) => setLoginTitleText(translate('pages.login.welcome')),
    });
  }, [groupId]);

  return (
    <>
      <Header>{loginTitleText ?? ''}</Header>
    </>
  );
};
const Header = styled(Header2)`
  margin-top: ${spacing16};
  margin-bottom: ${spacing48};
`;

export type GetLoginTitleQuery = {
  groupId?: number | null | undefined;
};

export type GetLoginTitleResponse = {
  title: string;
};
