import React, { useState } from 'react';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { ActionAlert } from '../../infrastructure/interface/components/ActionAlert';
import { useDeleteJson } from '../../infrastructure/api/useDeleteJson';
import { DeleteButton } from '../../infrastructure/interface/buttons/DeleteButton';

type Props = {
  userId: string;
  onUserDeleted: () => void;
};

export const DeleteUserButton = ({ userId, onUserDeleted }: Props) => {
  const { translate } = useInternationalisation();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const openAlert = () => setIsAlertOpen(true);
  const closeAlert = () => setIsAlertOpen(false);

  const apiRequest = useDeleteJson<DeleteUserCommand>(`/api/users/DeleteUser`);

  const deleteUser = () => {
    apiRequest.makeRequest({
      requestBody: { userId },
      onSuccess: () => {
        closeAlert();
        onUserDeleted();
      },
    });
  };

  const { inProgress, error } = apiRequest.state;

  return (
    <>
      <DeleteButton onClick={openAlert} size="medium" />
      <ActionAlert
        alertType="warning"
        isOpen={isAlertOpen}
        onRequestClose={closeAlert}
        title={translate('pages.deleteUser.confirmationAlert.title')}
        message={translate('pages.deleteUser.confirmationAlert.message')}
        cancelButtonText={translate('pages.deleteUser.confirmationAlert.cancelButtonText')}
        confirmButtonText={translate('pages.deleteUser.confirmationAlert.confirmButtonText')}
        onInitiateAction={deleteUser}
        actionInProgress={inProgress}
        actionError={error}
      />
    </>
  );
};

type DeleteUserCommand = {
  userId: string;
};
