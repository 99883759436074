export type CompanyReportGroupValueDateSelectSettings = {
  companyReportGroupId: number;
  plusOne: boolean;
  reportId: number | null;
  employeeCounterpartId: number | null;
};

export const defaultCompanyReportGroupValueDateSelectSettings: CompanyReportGroupValueDateSelectSettings =
  {
    companyReportGroupId: 0,
    plusOne: false,
    reportId: null,
    employeeCounterpartId: null,
  };
