import React from 'react';
import { MetadataSingleSelectComponentProps } from '../../MetadataSelectProps';
import { GeneralLedgerSubclassSelectSettings } from './GeneralLedgerSubclassSelectSettings';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelect } from '../../MetadataSingleSelect';
import {
  GetGeneralLedgerSubclassDropdownOptionsResponse,
  mapGeneralLedgerSubclassDropdownOptionsResponseToSelectOptions,
} from './GetGeneralLedgerSubclassDropdownOptionsResponse';

export type GeneralLedgerSubclassSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  GeneralLedgerSubclassSelectOptionValue,
  GeneralLedgerSubclassSelectSettings,
  GetGeneralLedgerSubclassDropdownOptionsResponse
>;

export const GeneralLedgerSubclassSelect = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('form.select.defaultPlaceholder');
  const loadingPlaceholder = translate('form.defaultLoadingPlaceholder');

  return (
    <MetadataSingleSelect<
      GeneralLedgerSubclassSelectOptionValue,
      GeneralLedgerSubclassSelectSettings,
      GetGeneralLedgerSubclassDropdownOptionsQuery,
      GetGeneralLedgerSubclassDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetGeneralLedgerSubclassDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapGeneralLedgerSubclassDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={250}
      {...metadataSingleSelectProps}
    />
  );
};

type GetGeneralLedgerSubclassDropdownOptionsQuery = {
  includeAll: boolean;
  includeBlank: boolean;
};
