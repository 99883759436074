import { useStartupData } from '../../startup/StartupDataContext';
import { useSecondsSinceLastUserActivity } from './useSecondsSinceLastUserActivity';
import { useMemo } from 'react';

type SessionTimeoutState = {
  isInSessionTimeoutWarningPeriod: boolean;
  sessionHasExpired: boolean;
  totalSecondsUntilSessionExpires: number;
};

export const useSessionTimeoutState = (): SessionTimeoutState => {
  const { sessionTimeoutSettings } = useStartupData();
  const { sessionLifetimeInMinutes, sessionTimeoutWarningDurationInMinutes } =
    sessionTimeoutSettings;

  const sessionLifetimeInSeconds = sessionLifetimeInMinutes * 60;

  const sessionTimeoutWarningDurationInSeconds = useMemo(
    () => sessionTimeoutWarningDurationInMinutes * 60,
    [sessionTimeoutWarningDurationInMinutes]
  );

  const secondsSinceLastUserActivity = useSecondsSinceLastUserActivity();

  const totalSecondsUntilSessionExpires = useMemo(
    () => sessionLifetimeInSeconds - secondsSinceLastUserActivity,
    [sessionLifetimeInSeconds, secondsSinceLastUserActivity]
  );

  if (secondsSinceLastUserActivity > sessionLifetimeInSeconds) {
    return {
      isInSessionTimeoutWarningPeriod: false,
      sessionHasExpired: true,
      totalSecondsUntilSessionExpires,
    };
  }

  if (
    secondsSinceLastUserActivity >
    sessionLifetimeInSeconds - sessionTimeoutWarningDurationInSeconds
  ) {
    return {
      isInSessionTimeoutWarningPeriod: true,
      sessionHasExpired: false,
      totalSecondsUntilSessionExpires,
    };
  }

  return {
    isInSessionTimeoutWarningPeriod: false,
    sessionHasExpired: false,
    totalSecondsUntilSessionExpires,
  };
};
