import { useScreenWidth } from './useScreenWidth';
import { useEffect, useState } from 'react';
import { desktopMinScreenWidth, Device } from '../../styling/layout/screenBreakpoints';

export const useDevice = () => {
  const screenWidth = useScreenWidth();
  const [device, setDevice] = useState<Device>('desktop');

  useEffect(() => {
    if (screenWidth >= desktopMinScreenWidth) {
      setDevice('desktop');
    } else {
      setDevice('mobile');
    }
  }, [screenWidth]);

  return {
    device,
    isDesktop: device === 'desktop',
    isMobile: device === 'mobile',
  };
};
