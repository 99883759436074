import React from 'react';
import { PencilIcon } from '../../../icons/icons';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { ButtonSize, NativeButtonProps } from './BaseButton';
import { MinimalButton } from './MinimalButton';

type Props = NativeButtonProps & { size?: ButtonSize };

export const EditButton = ({ size = 'medium', ...nativeButtonProps }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <MinimalButton size={size} icon={<PencilIcon />} {...nativeButtonProps}>
      {translate('actionButtons.edit')}
    </MinimalButton>
  );
};
