import { useEffect } from 'react';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';

type Options = {
  removeDefaultPrefix?: boolean;
};

export const useWindowTitle = (title: string, options?: Options) => {
  const { translate } = useInternationalisation();
  const defaultWindowTitle = translate('windowTitle');

  useEffect(() => {
    document.title = options?.removeDefaultPrefix ? title : `${defaultWindowTitle} - ${title}`;

    return () => {
      document.title = defaultWindowTitle;
    };
  }, [options, title, defaultWindowTitle]);
};
