import React from 'react';
import { BaseButton, ButtonSize, ButtonStyle, NativeButtonProps } from './BaseButton';
import { ButtonSpinner } from './ButtonSpinner';

type Props = NativeButtonProps & {
  waitingText: string | React.ReactNode;
  isWaiting: boolean;
  buttonStyle?: ButtonStyle;
  size?: ButtonSize;
  stretch?: boolean;
};

export const WaitingButton = ({
  waitingText,
  isWaiting,
  buttonStyle,
  size,
  stretch,
  children,
  disabled,
  ...rest
}: Props) => (
  <BaseButton
    buttonStyle={buttonStyle || 'primary'}
    size={size || 'medium'}
    stretch={stretch}
    disabled={disabled || isWaiting}
    {...rest}
  >
    {isWaiting ? (
      <>
        <ButtonSpinner size={size || 'medium'} />
        <span data-testid={waitingTextTestId}>{waitingText}</span>
      </>
    ) : (
      children
    )}
  </BaseButton>
);

export const waitingTextTestId = 'waiting-text';
