import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import QRCode from 'react-qr-code';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { ErrorMessage } from '../../../infrastructure/interface/components/ErrorMessage';
import { CentredSpinner } from '../../../infrastructure/interface/components/Spinner';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { SecondaryButton } from '../../../infrastructure/interface/buttons/SecondaryButton';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { Validator } from 'fluentvalidation-ts';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { Formik, FormikHelpers } from 'formik';
import { Form } from '../../../infrastructure/forms/common/Form';
import { SubmitButton } from '../../../infrastructure/forms/common/SubmitButton';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { spacing16, spacing32 } from '../../../styling/design/spacing';
import { fontSizeCss } from '../../../styling/design/fonts';
import { UserDetails } from '../UserDetails';
import { notBeEmpty } from '../../validation/stringValidation';
import { NotificationResponse } from '../../../styling/layout/sidebar/notifications/NotificationResponse';

type Props = {
  onCancel: () => void;
  onSetupComplete: (
    user: UserDetails | null,
    notifications: Array<NotificationResponse> | null
  ) => void;
};

export const SetUpGoogleAuthenticator = ({ onCancel, onSetupComplete }: Props) => {
  const { translate } = useInternationalisation();

  const formValidator = useMemo(
    () => new SetUpGoogleAuthenticatorFormValidator(translate),
    [translate]
  );

  const getDataApiRequest = useGetJson<undefined, GetDataForGoogleAuthenticatorSetupPageResponse>(
    '/api/authentication/GetDataForGoogleAuthenticatorSetupPage'
  );

  useOnMount(() => {
    getDataApiRequest.makeRequest();
  });

  const completeSetupApiRequest = usePostJson<
    CompleteGoogleAuthenticatorSetupCommand,
    CompleteGoogleAuthenticatorSetupResponse
  >('/api/authentication/CompleteGoogleAuthenticatorSetup');

  const getDataError = getDataApiRequest.state.error;
  if (getDataError != null) {
    return <ErrorMessage>{getDataError}</ErrorMessage>;
  }

  const getDataInProgress = getDataApiRequest.state.inProgress;
  const getDataResponse = getDataApiRequest.state.response;

  if (getDataInProgress || getDataResponse == null) {
    return <CentredSpinner size="xlarge" />;
  }

  const setupInProgress = completeSetupApiRequest.state.inProgress;
  const setupError = completeSetupApiRequest.state.error;

  // The "secret" is the Base32 encoded MFA key we have generated for the user.
  // We show this below the QR code in case the user cannot scan the QR code and
  // must enter it manually instead. Note that the secret is passed as a query
  // parameter on the end of the QR code URL.
  const secret = getDataResponse.qrCodeUrl.split('secret=')[1];

  const onSubmit = (
    formModel: SetUpGoogleAuthenticatorFormModel,
    formikHelpers: FormikHelpers<SetUpGoogleAuthenticatorFormModel>
  ) => {
    completeSetupApiRequest.makeRequest({
      requestBody: {
        secret: secret,
        authenticatorCode: formModel.authenticatorCode,
      },
      onSuccess: (response) => onSetupComplete(response.user, response.notificationsSinceLastLogin),
      onFailure: () => {
        formikHelpers.setSubmitting(false);
      },
    });
  };

  return (
    <Formik<SetUpGoogleAuthenticatorFormModel>
      initialValues={{ authenticatorCode: '' }}
      validate={formValidator.validate}
      onSubmit={onSubmit}
    >
      <Form>
        <Prompt>{translate('authentication.google.setupScreen.prompt')}</Prompt>
        <QrContainer>
          <QRCode value={getDataResponse.qrCodeUrl} size={200} />
          <pre>{secret}</pre>
        </QrContainer>
        <InputField
          label={translate('authentication.google.setupScreen.authenticatorCodeLabel')}
          fieldName="authenticatorCode"
          type="phone" // Gives a better UX on mobile
        />
        <ButtonRow withMarginTop={true} rightAligned={true}>
          <SecondaryButton onClick={onCancel} disabled={setupInProgress}>
            {translate('authentication.google.setupScreen.cancelButtonText')}
          </SecondaryButton>
          <SubmitButton
            withMarginTop={false}
            submittingText={translate(
              'authentication.google.setupScreen.submitButtonSubmittingText'
            )}
          >
            {translate('authentication.google.setupScreen.submitButtonText')}
          </SubmitButton>
        </ButtonRow>
        {setupError != null && <ErrorMessage>{setupError}</ErrorMessage>}
      </Form>
    </Formik>
  );
};

const Prompt = styled.div`
  margin-bottom: ${spacing16};
`;

const QrContainer = styled.div`
  text-align: center;
  margin: ${spacing32} 0;
  ${fontSizeCss('small')};
`;

export type SetUpGoogleAuthenticatorFormModel = {
  authenticatorCode: string;
};

class SetUpGoogleAuthenticatorFormValidator extends Validator<SetUpGoogleAuthenticatorFormModel> {
  constructor(translate: TranslateFunction) {
    super();
    this.ruleFor('authenticatorCode').must(notBeEmpty(translate));
  }
}

export type GetDataForGoogleAuthenticatorSetupPageResponse = {
  qrCodeUrl: string;
};

export type CompleteGoogleAuthenticatorSetupCommand = {
  secret: string;
  authenticatorCode: string;
};

export type CompleteGoogleAuthenticatorSetupResponse = {
  user: UserDetails | null;
  notificationsSinceLastLogin: Array<NotificationResponse>;
};
