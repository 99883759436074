import React from 'react';
import DefaultModal from 'react-modal';
import styled, { css } from 'styled-components/macro';
import { colourWhite } from '../../../styling/design/colours';
import { spacing32 } from '../../../styling/design/spacing';
import { onDesktop, onMobile } from '../../../styling/layout/screenBreakpoints';
import { Header3 } from './Headers';
import { DismissButton } from '../buttons/DismissButton';
import { ButtonRow, ButtonRowProps } from '../buttons/ButtonRow';

const ReactModalWithStyledOverlay: React.FC<DefaultModal.Props> = (props) => (
  <DefaultModal
    ariaHideApp={false}
    style={{
      overlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.75)',
        zIndex: 100,
      },
    }}
    {...props}
  />
);

export type DesktopModalSize = 'small' | 'medium' | 'large';
const getDesktopSizePixels = (desktopSize: DesktopModalSize): number => {
  switch (desktopSize) {
    case 'small':
      return 566;
    case 'medium':
      return 800;
    case 'large':
      return 1000;
  }
};

export type ModalProps = {
  className?: string;
  isOpen: boolean;
  onRequestClose: () => void;
  desktopSize?: DesktopModalSize;
  contentLabel?: string;
  children: React.ReactNode;
};

export const Modal = ({
  className,
  isOpen,
  onRequestClose,
  desktopSize,
  contentLabel,
  children,
}: ModalProps) => (
  <ContentContainer
    className={className}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel={contentLabel ?? 'Modal'}
    desktopSize={desktopSize ?? 'medium'}
  >
    {children}
  </ContentContainer>
);

const ContentContainer = styled(ReactModalWithStyledOverlay)<{ desktopSize: DesktopModalSize }>`
  position: relative;
  margin: auto;
  background-color: ${colourWhite};
  padding: ${spacing32};
  border-radius: 10px;
  max-height: 90vh;
  max-width: 90vw;
  overflow-y: auto;

  ${(props) =>
    onDesktop(css`
      width: ${getDesktopSizePixels(props.desktopSize)}px;
    `)};

  ${onMobile(css`
    margin: ${spacing32};
    width: 90vw;
  `)}

  &:focus-visible {
    outline: none;
  }
`;

type ModalHeaderProps = {
  className?: string;
  title: string | React.ReactNode;
} & (
  | { withCloseButton?: false | undefined; onRequestClose?: undefined }
  | { withCloseButton: true; onRequestClose: () => void }
);

export const ModalHeader = ({
  className,
  title,
  withCloseButton,
  onRequestClose,
}: ModalHeaderProps) => (
  <HeaderContainer className={className}>
    <StyledHeader>{title}</StyledHeader>
    {withCloseButton && <DismissButton onClick={() => onRequestClose!()} />}
  </HeaderContainer>
);

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing32};
`;

const StyledHeader = styled(Header3)`
  margin: 0;
`;

type ModalButtonRowProps = {
  children: React.ReactNode;
} & ButtonRowProps;

export const ModalButtonRow = ({
  children,
  withMarginTop,
  withMarginBottom,
  rightAligned,
}: ModalButtonRowProps) => (
  <ButtonRow
    withMarginTop={withMarginTop ?? true}
    withMarginBottom={withMarginBottom}
    rightAligned={rightAligned ?? true}
  >
    {children}
  </ButtonRow>
);
