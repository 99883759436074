import styled from 'styled-components/macro';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { TimesSolidIcon } from '../../icons/icons';
import React from 'react';
import { EmptyFieldLabel } from '../../infrastructure/forms/common/FieldLabel';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';

type Props = {
  resetFilters: () => void;
  className?: string;
};

export const ResetFiltersButton = (props: Props) => {
  const { translate } = useInternationalisation();

  return (
    <Container className={props.className}>
      <DesktopOnly>
        <EmptyFieldLabel />
      </DesktopOnly>
      <StyledMinimalButton
        buttonStyle="negative"
        icon={<TimesSolidIcon />}
        onClick={props.resetFilters}
      >
        {translate('filters.resetFiltersButton')}
      </StyledMinimalButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledMinimalButton = styled(MinimalButton)`
  flex-grow: 1;
`;
