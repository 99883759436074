import React, { useMemo } from 'react';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { BarChart } from '../../../../infrastructure/charts/BarChart';
import { getIsoDatestampFromUnknownFormatDateTimeString } from '../../../../helpers/dateTimeHelpers';
import { ChartDataPoint } from '../../../../infrastructure/charts/Chart';
import { isBlank } from '../../../../helpers/stringHelpers';

type Props = {
  toDateChartResponse: PortfolioAttributionToDateChartResponse;
  translationKey: string;
  'data-testid'?: string;
};

export const PortfolioAttributionToDateChart = (props: Props) => {
  const { toDateChartResponse, translationKey } = props;

  const { formatDate, translate, formatNumber } = useInternationalisation();

  const title = useMemo(() => {
    const prefix = translate(
      `pages.reports.charts.portfolioAttribution.${translationKey}.titlePrefix`
    );
    const localFormatDate = isBlank(toDateChartResponse.valueDate)
      ? ''
      : formatDate(toDateChartResponse.valueDate);

    return `${prefix} ${localFormatDate}`;
  }, [translate, translationKey, formatDate, toDateChartResponse]);

  const exportFilename = useMemo(() => {
    const prefix = translate(
      `pages.reports.charts.portfolioAttribution.${translationKey}.exportFilenamePrefix`
    );
    const dateInIsoDateFormat = isBlank(toDateChartResponse.valueDate)
      ? ''
      : getIsoDatestampFromUnknownFormatDateTimeString(toDateChartResponse.valueDate);

    return `${prefix}_${dateInIsoDateFormat}`;
  }, [translate, translationKey, toDateChartResponse]);

  const dataPoints: Array<ChartDataPoint> = useMemo(
    () =>
      toDateChartResponse.dataPoints.map((dataPoint) => ({
        label: dataPoint.description,
        value: dataPoint.toDateValue,
      })),
    [toDateChartResponse]
  );

  return (
    <BarChart
      data-testid={props['data-testid'] ?? 'portfolio-attribution-to-date-chart'}
      title={title}
      subtitle={toDateChartResponse.companyName}
      exportFilename={exportFilename}
      yAxisOptions={{
        labels: {
          formatter() {
            return formatNumber(this.value, { decimalPlaces: 6 });
          },
        },
      }}
      tooltipOptions={{
        pointFormatter() {
          const formattedToDateValue =
            this.y === undefined ? '' : formatNumber(this.y, { decimalPlaces: 6 });
          return `<span style="color:${this.color}">●</span> <b>${formattedToDateValue}</b>`;
        },
      }}
      dataPoints={dataPoints}
    />
  );
};

export type PortfolioAttributionToDateChartResponse = {
  companyName: string;
  valueDate: string;
  dataPoints: Array<PortfolioAttributionToDateChartDataPoint>;
};

type PortfolioAttributionToDateChartDataPoint = {
  description: string;
  toDateValue: number;
};
