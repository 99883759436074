import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';
import {
  GetBankingProductsDropdownOptionsResponse,
  mapBankingProductsDropdownOptionsResponseToSelectOptions,
} from './GetBankingProductsDropdownOptionsResponse';
import { BankingProductCode } from './BankingProduct';

export type BankingProductsSelectOptionValue = BankingProductCode | null;

export type BankingProductsSelectProps = Omit<
  MetadataSingleSelectComponentProps<
    BankingProductsSelectOptionValue,
    {},
    GetBankingProductsDropdownOptionsResponse
  >,
  'settings'
>;

export const BankingProductsSelect = (props: BankingProductsSelectProps) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.bankingProductsSelect.placeholder');
  const loadingPlaceholder = translate('metadata.bankingProductsSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      BankingProductsSelectOptionValue,
      {},
      {},
      GetBankingProductsDropdownOptionsResponse
    >
      {...props}
      endpointUrl="/api/metadata/GetBankingProductsDropdownOptions"
      settings={{}}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapBankingProductsDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={300}
    />
  );
};
