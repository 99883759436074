import styled, { css } from 'styled-components/macro';
import { spacing16, spacing32 } from '../../../styling/design/spacing';

export type ButtonRowProps = {
  withMarginTop?: boolean;
  withMarginBottom?: boolean;
  rightAligned?: boolean;
};

export const ButtonRow = styled.div<ButtonRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.rightAligned ? 'flex-end' : 'flex-start')};
  align-items: center;
  margin-top: ${(props) => (props.withMarginTop ? spacing32 : 0)};
  margin-bottom: ${(props) => (props.withMarginBottom ? spacing32 : 0)};

  button {
    margin-top: 0;
    margin-bottom: 0;

    ${(props) =>
      props.rightAligned
        ? css`
            &:not(:first-of-type) {
              margin-left: ${spacing16};
            }
          `
        : css`
            &:not(:last-of-type) {
              margin-right: ${spacing16};
            }
          `}
  }
`;
