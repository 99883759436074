import { useEffect, useState } from 'react';
import { now } from '../../helpers/dateTimeHelpers';

export const useCurrentTime = (tickIntervalInMilliseconds: number = 1000) => {
  const [currentTime, setCurrentTime] = useState(now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(now());
    }, tickIntervalInMilliseconds);

    return () => clearInterval(interval);
  }, [setCurrentTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return currentTime;
};
