import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { PrivateEquityHolding } from '../types';

export const usePrivateEquityFormattedData = (holding: PrivateEquityHolding): any => {
  const { formatNumber, formatPercentage } = useInternationalisation();

  if (holding == null) return null;

  const committedCapital = formatNumber(holding.committedCapital, {
    decimalPlaces: holding.currencyDecimals,
  });

  const accumulatedCashFlow = formatNumber(holding.accumulatedCashFlow, {
    decimalPlaces: holding.currencyDecimals,
  });

  const value = formatNumber(holding.value, { decimalPlaces: holding.currencyDecimals });

  const irrPercentage = Number.isNaN(holding.irr)
    ? ''
    : formatPercentage((Number(holding.irr) / 100).toString());

  return { committedCapital, accumulatedCashFlow, value, irrPercentage };
};
