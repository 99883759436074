import React from 'react';
import styled from 'styled-components/macro';
import { spacing12, spacing16, spacing24, spacing8 } from '../../../styling/design/spacing';
import {
  borderColours,
  colourBlack,
  colourWhite,
  textColours,
} from '../../../styling/design/colours';
import { fontSizeCss, fontWeightBold } from '../../../styling/design/fonts';
import { SpinnerCircle } from '../components/Spinner';

export type ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'notice'
  | 'positive'
  | 'warning'
  | 'negative';

export type ButtonSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

export type BaseButtonProps = {
  buttonStyle?: ButtonStyle;
  size?: ButtonSize;
  stretch?: boolean;
};

// We remove the 'ref' attribute as it doesn't play well with the Styled Components types.
export type NativeButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'ref'
>;

export const BaseButton = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))<BaseButtonProps>`
  height: fit-content;
  width: ${(props) => (props.stretch ? '100%' : undefined)};
  white-space: nowrap;

  background-color: ${(props) => getColourSettings(props.buttonStyle).backgroundColour};
  border-color: ${(props) => getColourSettings(props.buttonStyle).borderColour};
  color: ${(props) => getColourSettings(props.buttonStyle).textColour};

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  outline: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;

  padding: ${(props) => getVerticalPadding(props.size || 'medium')}
    ${(props) => getSidePadding(props.size || 'medium')};

  ${(props) => fontSizeCss(props.size || 'medium')};
  font-weight: ${fontWeightBold};

  transition: all 0.25s ease;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:active {
    opacity: 0.8;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${(props) => getColourSettings(props.buttonStyle).hoverBackgroundColour};
    border-color: ${(props) => getColourSettings(props.buttonStyle).hoverBorderColour};
    color: ${(props) => getColourSettings(props.buttonStyle).hoverTextColour};

    ${SpinnerCircle}:before {
      background-color: ${(props) => getColourSettings(props.buttonStyle).hoverTextColour};
    }
  }

  ${SpinnerCircle}:before {
    background-color: ${(props) => getColourSettings(props.buttonStyle).textColour};
  }
`;

type ButtonStyleColourSettings = {
  textColour: string;
  hoverTextColour: string;
  backgroundColour: string;
  hoverBackgroundColour: string;
  borderColour: string;
  hoverBorderColour: string;
};

const getColourSettings = (style?: ButtonStyle) => colourSettingsByButtonStyle[style || 'primary'];

const colourSettingsByButtonStyle: Record<ButtonStyle, ButtonStyleColourSettings> = {
  primary: {
    textColour: colourWhite,
    hoverTextColour: colourWhite,
    backgroundColour: borderColours.primary,
    hoverBackgroundColour: borderColours.primaryHover,
    borderColour: borderColours.primary,
    hoverBorderColour: borderColours.primaryHover,
  },
  secondary: {
    textColour: textColours.primary,
    hoverTextColour: colourWhite,
    backgroundColour: colourWhite,
    hoverBackgroundColour: borderColours.primaryHover,
    borderColour: borderColours.primary,
    hoverBorderColour: borderColours.primaryHover,
  },
  ghost: {
    textColour: colourBlack,
    hoverTextColour: colourWhite,
    backgroundColour: 'transparent',
    hoverBackgroundColour: colourBlack,
    borderColour: 'transparent',
    hoverBorderColour: colourBlack,
  },
  notice: {
    textColour: colourWhite,
    hoverTextColour: colourWhite,
    backgroundColour: borderColours.notice,
    hoverBackgroundColour: borderColours.noticeHover,
    borderColour: borderColours.notice,
    hoverBorderColour: borderColours.noticeHover,
  },
  positive: {
    textColour: colourWhite,
    hoverTextColour: colourWhite,
    backgroundColour: borderColours.positive,
    hoverBackgroundColour: borderColours.positiveHover,
    borderColour: borderColours.positive,
    hoverBorderColour: borderColours.positiveHover,
  },
  warning: {
    textColour: colourWhite,
    hoverTextColour: colourWhite,
    backgroundColour: borderColours.warning,
    hoverBackgroundColour: borderColours.warningHover,
    borderColour: borderColours.warning,
    hoverBorderColour: borderColours.warningHover,
  },
  negative: {
    textColour: colourWhite,
    hoverTextColour: colourWhite,
    backgroundColour: borderColours.negative,
    hoverBackgroundColour: borderColours.negativeHover,
    borderColour: borderColours.negative,
    hoverBorderColour: borderColours.negativeHover,
  },
};

const getSidePadding = (size: ButtonSize) => {
  switch (size) {
    case 'xsmall':
    case 'small':
      return spacing12;
    case 'medium':
      return spacing16;
    case 'large':
    case 'xlarge':
      return spacing24;
    default:
      return spacing16;
  }
};

const getVerticalPadding = (size: ButtonSize) => {
  switch (size) {
    case 'xsmall':
    case 'small':
      return spacing8;
    case 'medium':
    case 'large':
    case 'xlarge':
      return spacing12;
    default:
      return spacing12;
  }
};
