import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  GetTransactionTypeDropdownOptionsResponse,
  mapTransactionTypeDropdownOptionsResponseToSelectOptions,
} from './GetTransactionTypeDropdownOptionsResponse';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import {
  GetTransactionTypeDropdownOptionsQuery,
  TransactionTypeSelectOptionValue,
  TransactionTypeSelectSettings,
} from './TransactionTypeSelectBase';
import { MetadataSingleSelect } from '../MetadataSingleSelect';

type Props = MetadataSingleSelectComponentProps<
  TransactionTypeSelectOptionValue,
  TransactionTypeSelectSettings,
  GetTransactionTypeDropdownOptionsResponse
>;

export const TransactionTypeSelect = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.transactionTypeSelect.placeholder');
  const loadingPlaceholder = translate('metadata.transactionTypeSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      TransactionTypeSelectOptionValue,
      TransactionTypeSelectSettings,
      GetTransactionTypeDropdownOptionsQuery,
      GetTransactionTypeDropdownOptionsResponse
    >
      endpointUrl="/api/transactions/GetTransactionTypeDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapTransactionTypeDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={350}
      {...metadataSingleSelectProps}
    />
  );
};
