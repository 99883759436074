import { QueryParameters } from './makeRequest';
import { useJsonApiRequest } from './useJsonApiRequest';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';

type MakeRequestParameters<
  TQueryParameters extends QueryParameters | undefined,
  TResponse extends object
> = {
  queryParameters?: TQueryParameters | undefined;
  appendPath?: string;
  onSuccess?: (response: TResponse) => void;
  onFailure?: (error: string, response?: any) => void;
};

export const useGetJson = <
  TQueryParameters extends QueryParameters | undefined,
  TResponse extends object
>(
  endpointUrl: string
) => {
  const { translate } = useInternationalisation();
  return useGetJsonCore<TQueryParameters, TResponse>(endpointUrl, translate);
};

export const useGetJsonWithoutTranslation = <
  TQueryParameters extends QueryParameters | undefined,
  TResponse extends object
>(
  endpointUrl: string
) => {
  const translate: TranslateFunction | null = null;
  return useGetJsonCore<TQueryParameters, TResponse>(endpointUrl, translate);
};

const useGetJsonCore = <
  TQueryParameters extends QueryParameters | undefined,
  TResponse extends object
>(
  endpointUrl: string,
  translate: TranslateFunction | null
) => {
  const apiRequest = useJsonApiRequest<undefined, TResponse>({
    method: 'get',
    endpointUrl,
    translate,
  });

  const makeRequest = (
    makeRequestParameters?: MakeRequestParameters<TQueryParameters, TResponse>
  ) => {
    const appendPath = makeRequestParameters?.appendPath;
    const queryParameters = makeRequestParameters?.queryParameters;
    const onSuccess = makeRequestParameters?.onSuccess;
    const onFailure = makeRequestParameters?.onFailure;

    apiRequest.makeRequest({
      appendPath,
      queryParameters,
      onSuccess,
      onFailure,
    });
  };

  return {
    makeRequest,
    cancelRequest: apiRequest.cancelRequest,
    state: apiRequest.state,
    resetState: apiRequest.resetState,
  };
};
