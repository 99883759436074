import { FormatDateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import {
  IsoDatestamp,
  getIsoDatestampFromUnknownFormatDateTimeString,
} from '../../../helpers/dateTimeHelpers';
import { isNullUndefinedOrBlank } from '../../../helpers/stringHelpers';

export type GetCompanyValueDateDropdownOptionsResponse = {
  options: Array<CompanyDropdownValueDateOptionResponse>;
  defaultValueDates: CompanyValueDateDropdownOptionsDefaultValueDatesResponse;
};

export type CompanyDropdownValueDateOptionResponse = {
  valueDate: string;
};

export type CompanyValueDateDropdownOptionsDefaultValueDatesResponse = {
  runDate: string | null;
  priorDate: string | null;
};

export const convertCompanyValueDateToIsoTimestamp = (
  companyValueDate: string | null
): IsoDatestamp | null =>
  companyValueDate == null
    ? null
    : getIsoDatestampFromUnknownFormatDateTimeString(companyValueDate);

export const mapGetCompanyValueDateDropdownOptionsResponseToSelectOptions = (
  response: GetCompanyValueDateDropdownOptionsResponse | null,
  labelFormatter: FormatDateFunction
): SelectOptions<IsoDatestamp | null> =>
  !response
    ? []
    : response.options
        .filter((option) => !isNullUndefinedOrBlank(option.valueDate))
        .map((option) => ({
          value: convertCompanyValueDateToIsoTimestamp(option.valueDate),
          label: labelFormatter(option.valueDate),
        }));
