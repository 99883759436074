import { DownloadIcon } from '../../../icons/icons';
import { MinimalButton } from '../../../infrastructure/interface/buttons/MinimalButton';
import { TransactionsUploadTemplateInfo } from './UploadTransactions';
import { useFileDownload } from '../../../infrastructure/api/useFileDownload';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { useState } from 'react';
import { ActionAlert } from '../../../infrastructure/interface/components/ActionAlert';

type Props = {
  templateInfo: TransactionsUploadTemplateInfo;
};

export const DownloadTransactionsUploadTemplateButton = ({ templateInfo }: Props) => {
  const { translate } = useInternationalisation();

  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState(false);
  const closeErrorAlert = () => setErrorAlertIsOpen(false);

  const { downloadFile, inProgress, error } = useFileDownload<
    undefined,
    DownloadTransactionsUploadTemplateQuery
  >({
    endpointUrl: '/api/transactions/DownloadTransactionsUploadTemplate',
    method: 'get',
  });

  const onClick = () => {
    downloadFile({
      fileName: templateInfo.templateName,
      requestBody: undefined,
      queryParameters: { templateId: templateInfo.templateId },
      onError: () => setErrorAlertIsOpen(true),
    });
  };

  return (
    <>
      <MinimalButton
        icon={<DownloadIcon />}
        onClick={onClick}
        disabled={inProgress}
        data-testid={downloadTransactionsUploadTemplateButtonTestId(templateInfo.templateName)}
      >
        {inProgress
          ? translate('pages.uploadTransactions.downloadButtonWaitingText')
          : templateInfo.templateName}
      </MinimalButton>
      <ActionAlert
        alertType="negative"
        isOpen={errorAlertIsOpen}
        title={translate('errors.apology')}
        message={error}
        cancelButtonText={translate('actionButtons.cancel')}
        onRequestClose={closeErrorAlert}
        confirmButtonText={translate('errors.tryAgain')}
        onInitiateAction={onClick}
        actionInProgress={inProgress}
        actionError={null}
      />
    </>
  );
};

type DownloadTransactionsUploadTemplateQuery = {
  templateId: number;
};

export const downloadTransactionsUploadTemplateButtonTestId = (templateName: string) =>
  `download-transactions-upload-template-${templateName}`;
