import React from 'react';
import styled from 'styled-components/macro';
import { shadow2 } from '../../../styling/design/shadows';
import { spacing32 } from '../../../styling/design/spacing';
import { colourWhite } from '../../../styling/design/colours';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const Panel = ({ className, children }: Props) => (
  <Container className={className}>{children}</Container>
);

const Container = styled.div`
  background-color: ${colourWhite};
  border-radius: 10px;
  box-shadow: ${shadow2};
  padding: ${spacing32};
`;
