import styled from 'styled-components/macro';
import { fontSizeCss, fontWeightBold } from '../../../styling/design/fonts';

export const Header1 = styled.h1`
  margin-top: 0;
  font-weight: ${fontWeightBold};
  ${fontSizeCss('xxlarge')};
`;

export const Header2 = styled.h2`
  margin-top: 0;
  font-weight: ${fontWeightBold};
  ${fontSizeCss('xlarge')};
`;

export const Header3 = styled.h3`
  margin-top: 0;
  font-weight: ${fontWeightBold};
  ${fontSizeCss('large')};
`;

export const Header4 = styled.h4`
  margin-top: 0;
  font-weight: ${fontWeightBold};
  ${fontSizeCss('medium')};
`;
