import React from 'react';
import styled from 'styled-components/macro';
import { fontSizeCss } from '../../../styling/design/fonts';
import { spacing4 } from '../../../styling/design/spacing';
import { textColours } from '../../../styling/design/colours';

export const fieldErrorTestId = (fieldName: string) => `field-error-${fieldName}`;

type FieldErrorProps = {
  fieldName: string;
  children: React.ReactNode;
};

export const FieldError = ({ fieldName, children }: FieldErrorProps) => (
  <Container data-testid={fieldErrorTestId(fieldName)}>{children}</Container>
);

const Container = styled.div`
  display: block;
  ${fontSizeCss('xsmall')};
  margin-top: ${spacing4};
  color: ${textColours.negative};
`;
