import { useGetJson } from '../../../infrastructure/api/useGetJson';

export const useGetCompanyTransactionDetails = () =>
  useGetJson<GetCompanyTransactionDetailsQuery, GetCompanyTransactionDetailsResponse>(
    '/api/transactions/GetCompanyTransactionDetails'
  );

type GetCompanyTransactionDetailsQuery = {
  companyId: number;
};

export const companyEqualizationTypes = {
  multiSeries: 'M',
  noEqualisationAbsolute: 'N',
  equalisationAbsoluteBasis: '1',
  equalisationPerShareBasis: '2',
  noEqualisationPerShareBasis: 'P',
  limitedPartnership: 'L',
} as const;

type CompanyEqualizationType = keyof typeof companyEqualizationTypes;

export type CompanyEqualizationTypeCode = typeof companyEqualizationTypes[CompanyEqualizationType];

export type GetCompanyTransactionDetailsResponse = {
  shareDecimals: number;
  canEnterTransactionReference: boolean;
  equalizationTypeCode: CompanyEqualizationTypeCode;
  currencyCode: string;
};
