import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { SubmitButton } from '../../../infrastructure/forms/common/SubmitButton';
import { Validator } from 'fluentvalidation-ts';
import { Form } from '../../../infrastructure/forms/common/Form';
import { ErrorMessage } from '../../../infrastructure/interface/components/ErrorMessage';
import { notBeEmpty } from '../../validation/stringValidation';
import {usePostJson} from "../../../infrastructure/api/usePostJson";
import {MinimalCountdownButton} from "../../../infrastructure/interface/buttons/MinimalCountdownButton";

type Props = {
  onSubmit: (
    formModel: EnterMultiFactorAuthenticationCodeFormModel,
    formikHelpers: FormikHelpers<EnterMultiFactorAuthenticationCodeFormModel>
  ) => void;
  error: string | null;
};

export const EnterMultiFactorAuthenticationCodeForm = ({ onSubmit, error }: Props) => {
  const { translate } = useInternationalisation();
  const formModelValidator = new EnterMultiFactorAuthenticationCodeFormModelValidator(translate);

  const resendOtpRequestParameter = usePostJson<{}, {}>('/api/authentication/ResendOtp');
  const resendOtpRequest = () => {
    resendOtpRequestParameter.makeRequest({requestBody: {}})
  };
  
  
  return (
    <Formik<EnterMultiFactorAuthenticationCodeFormModel>
      initialValues={initialFormModel}
      onSubmit={onSubmit}
      validate={formModelValidator.validate}
    >
      <Form data-testid={enterMultiFactorAuthenticationCodeFormTestId}>
        <InputField
          label={translate('pages.login.labels.multiFactorAuthenticationCode')}
          fieldName="multiFactorAuthenticationToken"
          type="phone" // Gives a better UX on mobile
        />
        <SubmitButton submittingText={translate('pages.login.button.waitingText')} stretch={true}>
          {translate('pages.login.button.text')}
        </SubmitButton>
        <MinimalCountdownButton
            buttonText={translate('authentication.resendOTP')}
            countdownInSeconds= {30}
            clickRequest={resendOtpRequest}
        ></MinimalCountdownButton>
        {error != null && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
    </Formik>
  );
};

export const enterMultiFactorAuthenticationCodeFormTestId = 'enter-mfa-code-form';

export type EnterMultiFactorAuthenticationCodeFormModel = {
  multiFactorAuthenticationToken: string;
};

const initialFormModel: EnterMultiFactorAuthenticationCodeFormModel = {
  multiFactorAuthenticationToken: '',
};

class EnterMultiFactorAuthenticationCodeFormModelValidator extends Validator<EnterMultiFactorAuthenticationCodeFormModel> {
  constructor(translate: TranslateFunction) {
    super();

    this.ruleFor('multiFactorAuthenticationToken').must(notBeEmpty(translate));
  }
}

export const CounddownButton = () =>{
  return {
    };
}
