import React from 'react';
import {
  defaultTheme,
  NumberField as ReactSpectrumNumberInput,
  Provider,
} from '@adobe/react-spectrum';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import styled, { css } from 'styled-components/macro';
import { inputHeightPixels } from './Input';
import { backgroundColours, borderColours, textColours } from '../../../styling/design/colours';
import { fontFamily, fontSizeCss } from '../../../styling/design/fonts';
import { spacing12, spacing16 } from '../../../styling/design/spacing';

export type NumberInputType = number | null;

type Props = {
  value: NumberInputType;
  onChange: (newValue: NumberInputType) => void;
  onBlur?: (event: React.FocusEvent) => void;
  step?: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  className?: string;
  'aria-label'?: string;
  'data-testid'?: string;
} & (
  | { decimalPlaces?: number; currencyCode?: undefined }
  | { decimalPlaces?: undefined; currencyCode?: string }
);

export const NumberInput = (props: Props) => {
  const { getLocale } = useInternationalisation();

  const onChange = (newValue: number) => {
    if (props.onChange != null) {
      props.onChange(isNaN(newValue) ? null : newValue);
    }
  };

  const formatOptions: Intl.NumberFormatOptions =
    props.currencyCode != null
      ? { style: 'currency', currency: props.currencyCode, currencyDisplay: 'code' }
      : {
          minimumFractionDigits: props.decimalPlaces ?? 0,
          maximumFractionDigits: props.decimalPlaces ?? 20,
        };

  return (
    <Container className={props.className} disabled={props.disabled}>
      <Provider locale={getLocale()} theme={defaultTheme} colorScheme="light">
        <ReactSpectrumNumberInput
          // The 'key' prop forces the latest value to always show after change.
          // (this occasionally doesn't happen on large forms, which appears to be a bug with the react-spectrum library)
          key={props.value}
          data-testid={props['data-testid'] ?? numberInputTestId}
          aria-label={props['aria-label'] ?? 'number input'}
          value={props.value ?? NaN} // Use 'NaN' instead of 'undefined' to allow the component to remain controlled but show no input
          step={props.step}
          minValue={props.minValue}
          maxValue={props.maxValue}
          onChange={onChange}
          onBlur={props.onBlur}
          isDisabled={props.disabled}
          formatOptions={formatOptions}
        />
      </Provider>
    </Container>
  );
};

const Container = styled.div<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled &&
    css`
      * {
        background-color: ${backgroundColours.disabled} !important;
        color: ${textColours.disabled} !important;
        cursor: not-allowed;
      }
    `};

  * {
    border-color: ${borderColours.default} !important;
    ${fontSizeCss('medium')};
    font-family: ${fontFamily};
  }

  &:focus-within {
    * {
      border-color: ${borderColours.focus} !important;
    }
  }

  [role='group'][class^='spectrum-Stepper_'] {
    width: 100%;
  }

  input {
    height: ${inputHeightPixels}px;
    padding: ${spacing12} ${spacing16};
  }

  [class^='spectrum-Stepper-button_'] {
    height: calc(${inputHeightPixels}px / 2) !important;
  }
`;

export const numberInputTestId = 'number-input';
