import React from 'react';
import {
  CompanySelectForTransactions,
  CompanySelectForTransactionsOptionValue,
} from './CompanySelectForTransactions';
import { GetCompanyDropdownOptionsForTransactionsResponse } from './GetCompanyDropdownOptionsForTransactionsResponse';
import { CompanySelectForTransactionsSettings } from './CompanySelectForTransactionsSettings';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  CompanySelectForTransactionsOptionValue,
  CompanySelectForTransactionsSettings,
  GetCompanyDropdownOptionsForTransactionsResponse
>;

export const CompanySelectForTransactionsField = (props: Props) => (
  <MetadataSingleSelectField<
    CompanySelectForTransactionsOptionValue,
    CompanySelectForTransactionsSettings,
    GetCompanyDropdownOptionsForTransactionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <CompanySelectForTransactions {...metadataSingleSelectProps} />
    )}
  />
);
