import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Sidebar } from './sidebar/Sidebar';
import { mobileNavbarHeight, onDesktop, onMobile } from './screenBreakpoints';
import { MenuIcon, TimesSolidIcon } from '../../icons/icons';
import { useDevice } from '../../infrastructure/hooks/useDevice';
import { sidebarWidth } from './sidebar/SidebarStyles';
import { Logo } from '../branding/Logo';
import { mobilePagePadding } from './PaddedPage';
import { LanguageSelect } from '../../internationalisation/LanguageSelect';
import { borderColours } from '../design/colours';
import { spacing16, spacing32, spacing48 } from '../design/spacing';
import { Link } from 'react-router-dom';
import { AutoLogoutModal } from '../../features/authentication/AutoLogoutModal';
import { AppLink } from '../../infrastructure/interface/components/AppLink';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { UserHyperlinks } from './UserHyperlinks';
import { IfUserHasRole } from '../../features/authentication/UserRoles';
import { allNonAdminUserRoles } from '../../features/authentication/UserRole';
import { GoogleAnalyticsProvider } from './GoogleAnalyticsProvider';

type Props = {
  children?: React.ReactNode;
};

export const AuthenticatedLayout = ({ children }: Props) => {
  const { translate } = useInternationalisation();

  const { isDesktop } = useDevice();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const toggleMobileSidebar = () => setIsMobileSidebarOpen(!isMobileSidebarOpen);
  const closeMobileSidebar = () => setIsMobileSidebarOpen(false);

  return (
    <LayoutContainer>
      <GoogleAnalyticsProvider></GoogleAnalyticsProvider>
      <AutoLogoutModal />
      <MobileNavbar>
        <Link to="/" onClick={closeMobileSidebar}>
          <MobileNavbarPfsLogo />
        </Link>
        {isMobileSidebarOpen ? (
          <MobileNavbarCloseSidebarIcon onClick={toggleMobileSidebar} />
        ) : (
          <MobileNavbarOpenSidebarIcon onClick={toggleMobileSidebar} />
        )}
      </MobileNavbar>
      {(isDesktop || isMobileSidebarOpen) && <Sidebar close={closeMobileSidebar} />}
      <Body isVisible={isDesktop || !isMobileSidebarOpen}>
        <BodyContent>{children}</BodyContent>
        <Footer>
          <LanguageSelect />
          <IfUserHasRole userRole={allNonAdminUserRoles}>
            <FooterAppLink to="/terms-and-conditions">
              {translate('footer.termsAndConditions')}
            </FooterAppLink>
            <FooterAppLink to="/contact-us">{translate('footer.contactUs')}</FooterAppLink>
          </IfUserHasRole>
          <UserHyperlinks />
        </Footer>
      </Body>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  ${onDesktop(css`
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  `)};

  ${onMobile(css`
    position: relative;
  `)};
`;

const Body = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${onDesktop(css`
    min-height: 100vh;
    width: calc(100vw - ${sidebarWidth}px);
    background-color: #f7f8f9;
    overflow-y: scroll;
  `)};

  ${(props) =>
    onMobile(css`
      min-height: calc(100vh - ${mobileNavbarHeight});
      display: ${props.isVisible ? 'flex' : 'none'};
    `)};
`;

const BodyContent = styled.div``;

const Footer = styled.footer`
  display: flex;

  ${onDesktop(css`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: ${spacing16} ${spacing32};
    padding: ${spacing32} ${spacing48};
  `)};

  ${onMobile(css`
    flex-direction: column;
    align-items: flex-start;
    gap: ${spacing32};
    padding: ${mobilePagePadding};
  `)};

  border-top: solid 2px ${borderColours.default};
`;

const MobileNavbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${mobileNavbarHeight};
  padding: 0 ${mobilePagePadding};

  ${onDesktop(css`
    display: none;
  `)};
`;

const MobileNavbarPfsLogo = styled(Logo)`
  height: 40px;
`;

const MobileNavbarOpenSidebarIcon = styled(MenuIcon)`
  height: 14px;
  width: 14px;
`;

const MobileNavbarCloseSidebarIcon = styled(TimesSolidIcon)`
  height: 14px;
  width: 14px;
`;

const FooterAppLink = styled(AppLink)`
  white-space: nowrap;
`;
