import React, { useState } from 'react';
import { NotificationResponse } from './NotificationResponse';
import { NotificationType } from './NotificationType';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';

type NotificationsContextValue = {
  notifications: Array<NotificationResponse>;
  setNotifications: (notifications: Array<NotificationResponse>) => void;
  clearNotificationById: (id: number) => void;
  clearAllNotificationsByType: (type: NotificationType) => void;
  clearRequestInProgress: boolean;
  clearRequestInProgressIds: Array<number>;
  clearRequestError: string | null;
};

const throwNotInitialisedError = () => {
  throw new Error('NotificationsContext has not yet been initialised');
};

export const NotificationsContext = React.createContext<NotificationsContextValue>({
  notifications: [],
  setNotifications: throwNotInitialisedError,
  clearNotificationById: throwNotInitialisedError,
  clearAllNotificationsByType: throwNotInitialisedError,
  clearRequestInProgress: false,
  clearRequestInProgressIds: [],
  clearRequestError: null,
});

type Props = {
  children: React.ReactNode;
};

export const NotificationsContextProvider = ({ children }: Props) => {
  const [notifications, setNotifications] = useState<Array<NotificationResponse>>([]);
  const [clearRequestInProgressIds, setClearRequestInProgressIds] = useState<Array<number>>([]);

  const clearRequest = usePostJson<ClearNotificationsRequest, {}>(
    '/api/notifications/ClearNotifications'
  );

  const clearNotificationById = (id: number) => {
    setClearRequestInProgressIds([id]);

    clearRequest.makeRequest({
      requestBody: { ids: [id] },
      onSuccess: () => {
        const remainingNotifications = notifications.filter(
          (notification) => notification.id !== id
        );
        setClearRequestInProgressIds([]);
        setNotifications(remainingNotifications);
      },
      onFailure: () => {
        setClearRequestInProgressIds([]);
      },
    });
  };

  const clearAllNotificationsByType = (type: NotificationType) => {
    const idsToClear = notifications
      .filter((notification) => notification.type === type)
      .map((notification) => notification.id);

    setClearRequestInProgressIds(idsToClear);

    clearRequest.makeRequest({
      requestBody: { ids: idsToClear },
      onSuccess: () => {
        const remainingNotifications = notifications.filter(
          (notification) => notification.type !== type
        );
        setClearRequestInProgressIds([]);
        setNotifications(remainingNotifications);
      },
      onFailure: () => {
        setClearRequestInProgressIds([]);
      },
    });
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
        clearNotificationById,
        clearAllNotificationsByType,
        clearRequestInProgress: clearRequest.state.inProgress,
        clearRequestInProgressIds: clearRequestInProgressIds,
        clearRequestError: clearRequest.state.error,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export type ClearNotificationsRequest = {
  ids: Array<number>;
};
