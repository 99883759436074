import React, { useState } from 'react';
import { FileIcon } from './FileIcon';
import { DocumentResponse } from './Documents';
import styled from 'styled-components/macro';
import { spacing12, spacing16 } from '../../styling/design/spacing';
import { AlertModal } from '../../infrastructure/interface/components/AlertModal';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';

type Props = {
  document: DocumentResponse;
};

export const DocumentLink = ({ document }: Props) => {
  const { translate } = useInternationalisation();
  const [warningIsOpen, setWarningIsOpen] = useState(false);

  const onCloseWarning = () => {
    setWarningIsOpen(false);

    window.open(
      '/api/documents/DownloadDocument?documentId=' + document.documentId.toString(),
      '_blank',
      'noopener noreferrer'
    );
  };

  return (
    <>
      <MinimalButton onClick={() => setWarningIsOpen(true)}>
        <FileIconContainer>
          <FileIcon fileName={document.fileName} />
        </FileIconContainer>
        {document.description}
      </MinimalButton>
      <AlertModal
        alertType="warning"
        isOpen={warningIsOpen}
        title={translate('pages.documents.downloadWarning.title')}
        message={translate('pages.documents.downloadWarning.message')}
        closeButtonText={translate('actionButtons.close')}
        onRequestClose={() => onCloseWarning()}
      />
    </>
  );
};

const FileIconContainer = styled.div`
  display: inline-block;
  margin-right: ${spacing12};
  position: relative;

  svg {
    position: relative;
    height: ${spacing16};
    width: ${spacing16};
    top: 1px;
  }
`;
