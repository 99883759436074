// Changes here should also be made in AppRole.cs
export const roleCodeCharactersByRoleName = {
  Investor: 'I',
  'Consolidated Investor': 'C',
  Manager: 'M',
  Administrator: 'A',
  Advisor: 'G',
  'View As': 'V',
} as const;

export type UserRole = keyof typeof roleCodeCharactersByRoleName;
export type UserRoleCodeCharacter = typeof roleCodeCharactersByRoleName[UserRole];

export const allUserRoles: Array<UserRole> = [
  'Administrator',
  'Consolidated Investor',
  'Investor',
  'Manager',
  'View As',
  'Advisor',
];

export const allNonAdminUserRoles: Array<UserRole> = [
  'Consolidated Investor',
  'Investor',
  'Manager',
  'Advisor',
];

export const allAdminUserRoles: Array<UserRole> = ['Administrator', 'View As'];

export const investorUserRoles: Array<UserRole> = ['Investor', 'Consolidated Investor'];

export const getRoleCodeCharacter = (role: UserRole): UserRoleCodeCharacter =>
  roleCodeCharactersByRoleName[role];

export const getRoleFromCodeCharacter = (char: UserRoleCodeCharacter): UserRole => {
  const role = allUserRoles.find((role) => roleCodeCharactersByRoleName[role] === char);

  if (role === undefined) {
    throw new Error(`Unrecognised user role code character: ${char}`);
  }

  return role;
};

export const isAdminOrViewAsRoleCodeCharacter = (char: UserRoleCodeCharacter | null) =>
  allAdminUserRoles.some((role) => char === roleCodeCharactersByRoleName[role]);
