export type CompanyReportGroupSelectSettings = {
  includeBlank: boolean;
  isForUpload: boolean;
  isForReports: boolean;
  employeeCounterpartId: number | null;
};

export const defaultCompanyReportGroupSelectSettings: CompanyReportGroupSelectSettings = {
  includeBlank: false,
  isForUpload: false,
  isForReports: false,
  employeeCounterpartId: null,
};
