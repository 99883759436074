import React, { useContext, useState } from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  GetEmployeeDropdownOptionsResponse,
  mapEmployeeDropdownOptionsResponseToSelectOptions,
} from './GetEmployeeDropdownOptionsResponse';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';
import { FieldLabel } from '../../../infrastructure/forms/common/FieldLabel';
import { useSessionEmployeeId } from '../../../infrastructure/hooks/useSessionEmployeeId';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { AuthenticationContext } from '../../authentication/AuthenticationContext';
import { useNavigate } from 'react-router-dom';

export type EmployeeSelectOptionValue = number | null;

type Props = Omit<
  MetadataSingleSelectComponentProps<
    EmployeeSelectOptionValue,
    {},
    GetEmployeeDropdownOptionsResponse
  >,
  'settings'
>;

export const EmployeeSelect = (props: Props) => {
  const { onChange } = props;
  const { translate } = useInternationalisation();
  const { setStoredEmployeeId, sessionEmployeeId } = useSessionEmployeeId();
  const [shouldShow, setShouldShow] = useState(true);
  const { onError } = props;
  const authenticationContext = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const handleOnLoaded = (response: GetEmployeeDropdownOptionsResponse) => {
    if (response.options.length <= 1) setShouldShow(false);
    if (props.onLoaded) props.onLoaded(response);
    if(sessionEmployeeId ==null && response.options.length>1){
      onChangeInternal(response.options[0].counterpartId);
    }
  };

  const placeholder = translate('metadata.employeeSelect.placeholder');
  const loadingPlaceholder = translate('metadata.employeeSelect.loadingPlaceholder');

  const setEmployeeRequest = usePostJson<SetEmployeeRequest, {}>(
    '/api/authentication/SetEmployee'
  );
  
  const onChangeInternal = (employeId: number | null) => {
    setStoredEmployeeId(employeId);
    onChange(employeId);
    if (employeId) {
      setEmployeeRequest.makeRequest({
        requestBody: { employeeId: employeId },
        onSuccess: () => {
          authenticationContext.refresh();
          navigate('/');
        },
        onFailure: onError,
      });
    }
  };
  
  const renderContent = () => {
    return (
      <>
        <MetadataSingleSelect<
          EmployeeSelectOptionValue,
          {},
          GetEmployeeDropdownOptionsRequest,
          GetEmployeeDropdownOptionsResponse
        >
          {...props}
          endpointUrl="/api/metadata/GetEmployeeDropdownOptions"
          settings={{}}
          mapSettingsToQueryParameters={(settings) => settings}
          mapResponseToOptions={mapEmployeeDropdownOptionsResponseToSelectOptions}
          placeholder={placeholder}
          loadingPlaceholder={loadingPlaceholder}
          minWidthInPixels={350}
          onLoaded={handleOnLoaded}
          onChange={onChangeInternal}
        />
      </>
    );
  };

  if (!shouldShow) return null;
  return <>{props.wrapInDiv ? <div>{renderContent()}</div> : renderContent()}</>;
};

type GetEmployeeDropdownOptionsRequest = {};

export type SetEmployeeRequest ={
  employeeId: number;
};
