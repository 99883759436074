import { useContext } from 'react';
import { InternationalisationContext } from '../InternationalisationContext';
import { InternationalisationContextValue } from '../types/InternationalisationContextValue';
import * as locales from 'date-fns/locale';
import { registerLocale as registerLocaleForDatePicker } from 'react-datepicker';
import { findLocale } from '../internationalisationHelpers';

export const useInternationalisation = (): InternationalisationContextValue => {
  const context = useContext(InternationalisationContext);
  if (!context) {
    throw new Error(
      'useInternationalisation must be used within InternationalisationContextProvider'
    );
  }

  if (context.hasLoaded) {
    const localeCode = context.getLocale();
    const locale = findLocale(localeCode);
    registerLocaleForDatePicker(localeCode, locale ?? locales.enGB);
  } else {
    registerLocaleForDatePicker('en-GB', locales.enGB);
  }

  return context;
};
