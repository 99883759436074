import React from 'react';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { ReportResponse } from '../../Reports';
import { CustomReportParameter } from '../../ReportViewer';
import { Alert } from '../../../../infrastructure/interface/components/Alert';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { CentredSpinner } from '../../../../infrastructure/interface/components/Spinner';
import {
  PortfolioExposureSectorSummaryChart,
  PortfolioExposureSectorSummaryChartResponse,
} from './PortfolioExposureSectorSummaryChart';
import {
  PortfolioExposureGeographicalSummaryChart,
  PortfolioExposureGeographicalSummaryChartResponse,
} from './PortfolioExposureGeographicalSummaryChart';
import { ReportChartsContainer } from '../ReportCharts';
import { ChartsSpacer } from '../../../../infrastructure/charts/Chart';

type Props = {
  report: ReportResponse;
  customReportParameters: Array<CustomReportParameter>;
};

export const PortfolioExposureSummaryCharts = ({ report, customReportParameters }: Props) => {
  const { translate } = useInternationalisation();

  const {
    makeRequest,
    state: { response, inProgress, error },
  } = usePostJson<
    DataForPortfolioExposureSummaryChartsRequest,
    DataForPortfolioExposureSummaryChartsResponse
  >('/api/reports/GetDataForPortfolioExposureSummaryCharts');

  useOnMount(() => makeRequest({ requestBody: { reportId: report.id, customReportParameters } }));

  if (error) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')}>
        {error}
      </Alert>
    );
  }

  if (response == null || inProgress) {
    return <CentredSpinner size="xlarge" />;
  }

  return (
    <ReportChartsContainer>
      <PortfolioExposureSectorSummaryChart sectorChartResponse={response.sectorChart} />
      <ChartsSpacer />
      <PortfolioExposureGeographicalSummaryChart
        geographicalChartResponse={response.geographicalChart}
      />
    </ReportChartsContainer>
  );
};

export type DataForPortfolioExposureSummaryChartsRequest = {
  reportId: number;
  customReportParameters: Array<CustomReportParameter>;
};

export type DataForPortfolioExposureSummaryChartsResponse = {
  sectorChart: PortfolioExposureSectorSummaryChartResponse;
  geographicalChart: PortfolioExposureGeographicalSummaryChartResponse;
};
