import { useGetJson } from '../../infrastructure/api/useGetJson';
import { GetInvestorDetailsResponse } from './GetInvestorDetailsResponse';

export const useGetInvestorDetailsRequest = () =>
  useGetJson<GetInvestorDetailsQuery, GetInvestorDetailsResponse>(
    '/api/investors/GetInvestorDetails'
  );

export type GetInvestorDetailsQuery = {
  investorId: number | null;
};
