import React, { useState, createContext, useContext } from 'react';
import { now } from '../../helpers/dateTimeHelpers';

export type UserActivityContextValue = {
  lastActivityDate: Date;
  recordUserActivity: () => void;
};

const UserActivityContext = createContext<UserActivityContextValue | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const UserActivityContextProvider = ({ children }: Props) => {
  const [lastActivityDate, setLastActivityDate] = useState(now());

  const recordUserActivity = () => setLastActivityDate(now());

  return (
    <UserActivityContext.Provider value={{ lastActivityDate, recordUserActivity }}>
      {children}
    </UserActivityContext.Provider>
  );
};

export const useUserActivityContext = () => {
  const userActivityContext = useContext(UserActivityContext);

  if (userActivityContext == null) {
    throw new Error(
      'useUserActivityContext must be called within a <UserActivityContextProvider />'
    );
  }

  return userActivityContext;
};
