import React, { useState } from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  GetTenantDropdownOptionsResponse,
  mapTenantDropdownOptionsResponseToSelectOptions,
} from './GetTenantDropdownOptionsResponse';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';

export type TenantSelectOptionValue = number | null;

type Props = Omit<
  MetadataSingleSelectComponentProps<TenantSelectOptionValue, {}, GetTenantDropdownOptionsResponse>,
  'settings'
>;

export const TenantSelect = (props: Props) => {
  const { translate } = useInternationalisation();
  const [shouldShow, setShouldShow] = useState(true);

  const handleOnLoaded = (response: GetTenantDropdownOptionsResponse) => {
    if (response.options.length <= 1) setShouldShow(false);
    if (props.onLoaded) props.onLoaded(response);
  };

  const placeholder = translate('metadata.tenantSelect.placeholder');
  const loadingPlaceholder = translate('metadata.tenantSelect.loadingPlaceholder');

  const renderContent = () => {
    return (
      <>
        <MetadataSingleSelect<
          TenantSelectOptionValue,
          {},
          GetTenantDropdownOptionsRequest,
          GetTenantDropdownOptionsResponse
        >
          {...props}
          endpointUrl="/api/metadata/GetTenantDropdownOptions"
          settings={{}}
          mapSettingsToQueryParameters={(settings) => settings}
          mapResponseToOptions={mapTenantDropdownOptionsResponseToSelectOptions}
          placeholder={placeholder}
          loadingPlaceholder={loadingPlaceholder}
          minWidthInPixels={350}
          onLoaded={handleOnLoaded}
        />
      </>
    );
  };

  if (!shouldShow) return null;
  return <>{props.wrapInDiv ? <div>{renderContent()}</div> : renderContent()}</>;
};

type GetTenantDropdownOptionsRequest = {};
