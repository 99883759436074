import React, { useEffect, useRef, useState } from 'react';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { Modal, ModalHeader } from '../../infrastructure/interface/components/Modal';
import styled, { css } from 'styled-components/macro';
import { spacing16, spacing32 } from '../../styling/design/spacing';
import {
  defaultInitialResultsPerPageOption,
  PaginatedTable,
} from '../../infrastructure/interface/tables/PaginatedTable';
import {
  NoResultsRow,
  Table,
  TBody,
  Th,
  THead,
  Tr,
} from '../../infrastructure/interface/tables/Table';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { Formik, FormikHelpers } from 'formik';
import { Form } from '../../infrastructure/forms/common/Form';
import { InputField } from '../../infrastructure/forms/fields/InputField';
import { SubmitButton } from '../../infrastructure/forms/common/SubmitButton';
import { TimesSolidIcon } from '../../icons/icons';
import { colourGrey07 } from '../../styling/design/colours';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { onDesktop, onMobile } from '../../styling/layout/screenBreakpoints';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { MobileNoResultsCard } from '../../infrastructure/interface/components/ItemDetailsCard';
import { LoadingOverlay } from '../../infrastructure/interface/components/LoadingOverlay';
import { MobilePaginationControls } from '../../infrastructure/interface/components/MobilePaginationControls';

export type BaseSearchModalQueryParameters = {
  searchTerm: string | null;
  pageNumber: number;
  resultsPerPage: number;
};

type QueryParameterFactory<TQueryParameters> = (
  baseParameters: BaseSearchModalQueryParameters
) => TQueryParameters;

type Props<TQueryParameters, TResult> = {
  title: string;
  inputPlaceholder: string;
  isOpen: boolean;
  onRequestClose: () => void;
  dataEndpointUrl: string;
  getQueryParameters: QueryParameterFactory<TQueryParameters>;
  desktopHeaders: Array<string>;
  renderDesktopRow: (result: TResult) => React.ReactNode;
  renderMobileItem: (result: TResult) => React.ReactNode;
};

export type SearchModalResponse<TResult extends object> = {
  results: Array<TResult>;
  totalPages: number;
};

export const GenericSearchModal = <
  TQueryParameters extends BaseSearchModalQueryParameters,
  TResult extends object
>(
  props: Props<TQueryParameters, TResult>
) => {
  const { translate } = useInternationalisation();

  const fetchDataRequest = useGetJson<TQueryParameters, SearchModalResponse<TResult>>(
    props.dataEndpointUrl
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(defaultInitialResultsPerPageOption);

  const [latestResponse, setLatestResponse] = useState<SearchModalResponse<TResult> | null>(null);

  const appliedFormModel = useRef<GenericSearchModalFormModel | null>(null);

  const onSubmit = (
    formModel: GenericSearchModalFormModel,
    formikHelpers: FormikHelpers<GenericSearchModalFormModel>
  ) => {
    appliedFormModel.current = { ...formModel };
    const { searchTerm } = formModel;

    const queryParameters = props.getQueryParameters({ searchTerm, pageNumber, resultsPerPage });

    fetchDataRequest.makeRequest({
      queryParameters,
      onSuccess: (response) => {
        setLatestResponse(response);
        formikHelpers.setSubmitting(false);
      },
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  useEffect(() => {
    if (appliedFormModel.current != null) {
      const { searchTerm } = appliedFormModel.current;

      const queryParameters = props.getQueryParameters({
        searchTerm,
        pageNumber,
        resultsPerPage,
      });

      fetchDataRequest.makeRequest({
        queryParameters,
        onSuccess: setLatestResponse,
      });
    }
  }, [pageNumber, resultsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = fetchDataRequest.state.inProgress;
  const error = fetchDataRequest.state.error;

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} desktopSize="large">
      <ModalHeader
        title={props.title}
        withCloseButton={true}
        onRequestClose={props.onRequestClose}
      />
      <Formik<GenericSearchModalFormModel> initialValues={{ searchTerm: '' }} onSubmit={onSubmit}>
        {(formikProps) => (
          <Form>
            <SearchBar>
              <InputField
                autoFocus={true}
                fieldName="searchTerm"
                placeholder={props.inputPlaceholder}
                icon={{
                  icon: <TimesSolidIcon />,
                  colour: colourGrey07,
                  onClick: () => formikProps.setFieldValue('searchTerm', ''),
                }}
              />
              <SubmitButton
                disabled={isLoading}
                submittingText={translate('searchModals.generic.searchButtonText')}
              >
                {translate('searchModals.generic.searchButtonText')}
              </SubmitButton>
            </SearchBar>
          </Form>
        )}
      </Formik>
      {latestResponse == null ? (
        isLoading ? (
          <SpinnerContainer>
            <CentredSpinner size="xlarge" />
          </SpinnerContainer>
        ) : error != null ? (
          <Alert alertType="negative" withMarginTop={true}>
            {error}
          </Alert>
        ) : null
      ) : (
        <>
          {error != null && (
            <Alert alertType="negative" withMarginBottom={true}>
              {error}
            </Alert>
          )}
          <DesktopOnly>
            <PaginatedTable
              showLoadingOverlay={isLoading}
              resultsPerPage={resultsPerPage}
              totalResultsCount={latestResponse.totalPages * resultsPerPage}
              onChangeResultsPerPage={setResultsPerPage}
              currentPageNumber={
                latestResponse.totalPages > pageNumber
                  ? pageNumber
                  : latestResponse.totalPages === 0
                  ? 1
                  : latestResponse.totalPages
              }
              onChangeCurrentPageNumber={setPageNumber}
            >
              <Table>
                <THead>
                  <Tr>
                    {props.desktopHeaders.map((header, index) => (
                      <Th key={index}>{header}</Th>
                    ))}
                  </Tr>
                </THead>
                <TBody>
                  {latestResponse.results.map(props.renderDesktopRow)}
                  {latestResponse.results.length === 0 && (
                    <NoResultsRow colSpan={props.desktopHeaders.length} />
                  )}
                </TBody>
              </Table>
            </PaginatedTable>
          </DesktopOnly>
          <MobileOnly>
            <LoadingOverlay showOverlay={isLoading}>
              {latestResponse.results.map(props.renderMobileItem)}
              {latestResponse.results.length === 0 && <MobileNoResultsCard />}
            </LoadingOverlay>
            <MobilePaginationControls
              resultsPerPage={resultsPerPage}
              totalResultsCount={latestResponse.totalPages * resultsPerPage}
              onChangeResultsPerPage={setResultsPerPage}
              currentPageNumber={
                latestResponse.totalPages > pageNumber
                  ? pageNumber
                  : latestResponse.totalPages === 0
                  ? 1
                  : latestResponse.totalPages
              }
              onChangeCurrentPageNumber={setPageNumber}
            />
          </MobileOnly>
        </>
      )}
    </Modal>
  );
};

export type GenericSearchModalFormModel = {
  searchTerm: string;
};

const SearchBar = styled.div`
  display: flex;

  ${onDesktop(css`
    flex-direction: row;
    align-items: center;
    margin-bottom: ${spacing16};

    input {
      width: auto;
    }

    button {
      margin-top: 0;
      margin-left: ${spacing16};
    }
  `)}

  ${onMobile(css`
    flex-direction: column;
    margin-bottom: ${spacing32};
  `)}
`;

const SpinnerContainer = styled.div`
  padding: ${spacing32} 0;
`;
