import { Route } from 'react-router-dom';
import { Transactions } from './Transactions';
import { PlaceTransaction } from './placeTransaction/PlaceTransaction';
import { UploadTransactions } from './bulkUploadTransactions/UploadTransactions';
import { uploadIdUrlParamName } from './useUploadIdUrlParam';
import { dealNumberUrlParamName } from './useDealNumberUrlParam';
import { ConfirmTransaction } from './placeTransaction/confirm/ConfirmTransaction';
import { ConfirmUploadTransactions } from './bulkUploadTransactions/ConfirmUploadTransactions';
import React from 'react';

export const TransactionRoutes = () => [
  <Route path="/transactions" element={<Transactions />} />,
  <Route path="/transactions/place-transaction" element={<PlaceTransaction />} />,
  <Route path="/transactions/upload" element={<UploadTransactions />} />,
  <Route
    path={`/transactions/:${uploadIdUrlParamName}/:${dealNumberUrlParamName}/confirm`}
    element={<ConfirmTransaction />}
  />,
  <Route
    path={`/transactions/:${uploadIdUrlParamName}/confirm`}
    element={<ConfirmUploadTransactions />}
  />,
];
