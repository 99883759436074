import React from 'react';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../../MetadataSingleSelectField';
import {
  GeneralLedgerCounterpartSelect,
  GeneralLedgerCounterpartSelectOptionValue,
} from './GeneralLedgerCounterpartSelect';
import { GeneralLedgerCounterpartSelectSettings } from './GeneralLedgerCounterpartSelectSettings';
import { GetGeneralLedgerCounterpartDropdownOptionsResponse } from './GetGeneralLedgerCounterpartDropdownOptionsResponse';

type Props = MetadataSingleSelectFieldComponentProps<
  GeneralLedgerCounterpartSelectOptionValue,
  GeneralLedgerCounterpartSelectSettings,
  GetGeneralLedgerCounterpartDropdownOptionsResponse
>;

export const GeneralLedgerCounterpartSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    GeneralLedgerCounterpartSelectOptionValue,
    GeneralLedgerCounterpartSelectSettings,
    GetGeneralLedgerCounterpartDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <GeneralLedgerCounterpartSelect {...metadataSingleSelectProps} />
    )}
  />
);
