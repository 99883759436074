import { useEffect } from 'react';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { InputField } from '../../infrastructure/forms/fields/InputField';

type Props = {
  fieldName: string;
  fieldLabel: string;
  accessGroupId: number | null;
};

export const MobileNumberInputField = (props: Props) => {
  const validateMobileNumberRequest = useGetJson<
    GetAccessGroupPhoneFormatRequest,
    GetAccessGroupPhoneFormatResponse
  >('/api/users/GetAccessGroupPhoneFormat');

  useEffect(() => {
    if (props.accessGroupId != null) {
      validateMobileNumberRequest.makeRequest({
        queryParameters: {
          accessGroupId: props.accessGroupId,
        },
      });
    }
  }, [props.accessGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InputField
      type="phone"
      fieldName={props.fieldName}
      label={props.fieldLabel}
      helpText={validateMobileNumberRequest.state.response?.formatText}
    />
  );
};

type GetAccessGroupPhoneFormatRequest = {
  accessGroupId: number;
};

export type GetAccessGroupPhoneFormatResponse = {
  format: string;
  formatText: string;
};
