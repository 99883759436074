import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import { GeneralLedgerBankSelectOptionValue } from './GeneralLedgerBankSelect';

export type GetGeneralLedgerBankDropdownOptionsResponse = {
  options: Array<GeneralLedgerBankDropdownOption>;
};

type GeneralLedgerBankDropdownOption = {
  counterpartId: number;
  label: string;
};

export const mapGeneralLedgerBankDropdownOptionsResponseToSelectOptions = (
  response: GetGeneralLedgerBankDropdownOptionsResponse | null
): SelectOptions<GeneralLedgerBankSelectOptionValue> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.counterpartId,
        label: option.label,
      }));
