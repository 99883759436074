import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';
import {
  GetCurrencyDropdownOptionsResponse,
  mapCurrencyDropdownOptionsResponseToSelectOptions,
} from './GetCurrencyDropdownOptionsResponse';
import { CurrencySelectSettings } from './CurrencySelectSettings';

export type CurrencySelectOptionValue = number | null;

export type CurrencySelectProps = MetadataSingleSelectComponentProps<
  CurrencySelectOptionValue,
  CurrencySelectSettings,
  GetCurrencyDropdownOptionsResponse
>;

export const CurrencySelect = (props: CurrencySelectProps) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.currencySelect.placeholder');
  const loadingPlaceholder = translate('metadata.currencySelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      CurrencySelectOptionValue,
      CurrencySelectSettings,
      GetCurrencyDropdownOptionsQuery,
      GetCurrencyDropdownOptionsResponse
    >
      {...props}
      endpointUrl="/api/metadata/GetCurrencyDropdownOptions"
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapCurrencyDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={100}
    />
  );
};

type GetCurrencyDropdownOptionsQuery = {
  includeBlank: boolean;
  investedOnly: boolean;
};
