import axios, { CancelTokenSource } from 'axios';
import { useRef, useState } from 'react';
import {
  ApiResponse,
  isCancelledRequestResponse,
  isErrorResponse,
  isSuccessResponse,
} from './ApiResponse';
import { useOnUnmount } from '../hooks/useOnUnmount';
import {
  ApiRequestState,
  buildFailedApiRequestState,
  buildSuccessApiRequestState,
  initialApiRequestState,
  inProgressApiRequestState,
} from './ApiRequestState';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { useIsMounted } from '../hooks/useIsMounted';
import { useUserActivityContext } from '../../features/authentication/UserActivityContext';

type CoreMakeRequestArguments<TResponse> = {
  onSuccess?: (response: TResponse) => void;
  onFailure?: (error: string, response?: any) => void;
};

type CoreApiRequestParameters = {
  endpointUrl: string;
  cancelTokenSource?: CancelTokenSource;
};

type ApiRequestInitiator<TApiRequestParameters extends CoreApiRequestParameters, TResponse> = (
  parameters: TApiRequestParameters,
  translate: TranslateFunction | null
) => Promise<ApiResponse<TResponse>>;

export const useApiRequest = <
  TMakeRequestArguments extends CoreMakeRequestArguments<TResponse>,
  TApiRequestParameters extends CoreApiRequestParameters,
  TResponse
>(
  endpointUrl: string,
  translate: TranslateFunction | null,
  makeApiRequest: ApiRequestInitiator<TApiRequestParameters, TResponse>
) => {
  const userActivityContext = useUserActivityContext();

  const [state, setState] = useState<ApiRequestState<TResponse>>(initialApiRequestState);

  const cancelTokenSource = useRef(axios.CancelToken.source());

  const isMounted = useIsMounted();

  const makeRequest = ({
    onSuccess,
    onFailure,
    ...makeRequestArguments
  }: TMakeRequestArguments) => {
    if (state.inProgress) {
      cancelRequest();
    }

    setState(inProgressApiRequestState);

    makeApiRequest(
      {
        ...makeRequestArguments,
        endpointUrl: endpointUrl,
        cancelTokenSource: cancelTokenSource.current,
      } as any,
      translate
    ).then((response) => {
      if (isSuccessResponse(response)) {
        setState(buildSuccessApiRequestState(response.result));

        if (onSuccess != null) {
          onSuccess(response.result);
        }
      } else if (isErrorResponse(response)) {
        if (response.statusCode === 401) {
          let currentRelativePath = window.location.pathname + window.location.search;
          let encodedCurrentRelativePath = encodeURIComponent(currentRelativePath);
          window.location.assign(
            `/login?sessionExpired=true&returnTo=${encodedCurrentRelativePath}`
          );
        }

        if (isCancelledRequestResponse(response)) {
          // If the request has been cancelled then we simply want to ignore the error.
          if (isMounted.current) {
            setState(initialApiRequestState);
          }

          return;
        }

        setState(buildFailedApiRequestState(response.error));

        if (onFailure != null) {
          onFailure(response.error, response);
        }
      }
    });

    userActivityContext.recordUserActivity();
  };

  const cancelRequest = () => {
    cancelTokenSource.current.cancel();
    cancelTokenSource.current = axios.CancelToken.source();
  };

  useOnUnmount(() => {
    cancelRequest();
  });

  const resetState = () => {
    setState(initialApiRequestState);
  };

  return { makeRequest, cancelRequest, state, resetState };
};
