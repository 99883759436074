import { useGetJson } from '../../infrastructure/api/useGetJson';

export const useGetTermsAndConditionsRequest = () =>
  useGetJson<undefined, GetTermsAndConditionsResponse>(
    '/api/termsAndConditions/GetTermsAndConditions'
  );

export type GetTermsAndConditionsResponse = {
  termsAndConditionsHtml: string | null;
  termsAndConditionsQuestion: string | null;
};
