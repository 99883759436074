import React from 'react';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../../MetadataSelectProps';
import {
  GetCompanyDropdownOptionsForTransactionsResponse,
  mapCompanyDropdownOptionsForTransactionsResponseToSelectOptions,
} from './GetCompanyDropdownOptionsForTransactionsResponse';
import {
  CompanySelectForTransactionsSettings,
  companySelectForTransactionsContextCode,
} from './CompanySelectForTransactionsSettings';
import { MetadataSingleSelect } from '../../MetadataSingleSelect';

export type CompanySelectForTransactionsOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  CompanySelectForTransactionsOptionValue,
  CompanySelectForTransactionsSettings,
  GetCompanyDropdownOptionsForTransactionsResponse
>;

export const CompanySelectForTransactions = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.companySelectForTransactions.placeholder');
  const loadingPlaceholder = translate('metadata.companySelectForTransactions.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      CompanySelectForTransactionsOptionValue,
      CompanySelectForTransactionsSettings,
      GetCompanyDropdownOptionsForTransactionsQuery,
      GetCompanyDropdownOptionsForTransactionsResponse
    >
      endpointUrl="/api/metadata/GetCompanyDropdownOptionsForTransactions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => ({
        ...defaultGetCompanyDropdownOptionsForTransactionsQuery,
        ...settings,
      })}
      mapResponseToOptions={mapCompanyDropdownOptionsForTransactionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={350}
      {...metadataSingleSelectProps}
    />
  );
};

export type GetCompanyDropdownOptionsForTransactionsQuery = {
  investorId?: number | null;
  context: string;
};

const defaultGetCompanyDropdownOptionsForTransactionsQuery: GetCompanyDropdownOptionsForTransactionsQuery =
  {
    investorId: null,
    context: companySelectForTransactionsContextCode.Individual,
  };
