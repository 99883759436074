import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { Alert } from './Alert';

type Props = {
  header: string;
};

export const NoMobileContentAlert = ({ header }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <Alert alertType="notice" header={header}>
      {translate('noMobileContentAlert.message')}
    </Alert>
  );
};
