import styled from 'styled-components/macro';
import { backgroundColours, borderColours, textColours } from '../../../styling/design/colours';
import { spacing12, spacing128, spacing16 } from '../../../styling/design/spacing';
import { fontSizeCss } from '../../../styling/design/fonts';

export const TextArea = styled.textarea`
  width: 100%;
  outline: none;
  border: solid 1px ${borderColours.default};
  border-radius: 5px;
  padding: ${spacing12} ${spacing16};
  transition: color 0.25s ease, border-color 0.25s ease, background-color 0.25s ease;
  ${fontSizeCss('medium')};
  resize: vertical;
  height: ${spacing128};

  &:focus {
    border-color: ${borderColours.focus};
  }

  &:disabled,
  &:read-only {
    background-color: ${backgroundColours.disabled};
    border-color: ${borderColours.disabled};
    color: ${textColours.disabled};
    cursor: not-allowed;
  }
`;
