import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { ExternalLink } from '../../infrastructure/interface/components/ExternalLink';
import { toAbsoluteLink } from '../../helpers/stringHelpers';
import { AuthenticationContext } from '../../features/authentication/AuthenticationContext';

export const UserHyperlinks = () => {
  const { getUser } = useContext(AuthenticationContext);
  const { hyperlinks } = getUser();

  return (
    <>
      {hyperlinks.map((hyperlink) => (
        <StyledExternalLink
          key={hyperlink.label}
          href={toAbsoluteLink(hyperlink.url)}
          target="_blank"
        >
          {hyperlink.label}
        </StyledExternalLink>
      ))}
    </>
  );
};

const StyledExternalLink = styled(ExternalLink)`
  white-space: nowrap;
`;
