import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
import styled from 'styled-components/macro';
import { EditButton } from '../../../infrastructure/interface/buttons/EditButton';
import {
  NoResultsRow,
  Table,
  TableLoadingOverlay,
  TBody,
  Td,
  Th,
  THead,
  Tr
} from '../../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { spacing16 } from '../../../styling/design/spacing';
import { DeleteDistributionReinvestmentButton } from './DeleteDistributionReinvestmentButton';
import { useDistributionReinvestmentsContext } from './DistributionReinvestmentsContext';
import React from 'react';

type Props = {
  canEdit: boolean;
};
export const DesktopDistributionReinvestmentsList = ({ canEdit }: Props) => {
  const { translate, formatDate } = useInternationalisation();
  const navigate = useNavigate();
  const colSpan = canEdit ? 4 : 3;

  const {
    latestDistributionReinvestmentsResponse,
    distributionReinvestmentsRequestInProgress
  } = useDistributionReinvestmentsContext();

  if (latestDistributionReinvestmentsResponse == null) {
    return null;
  }

  return (
    <TableLoadingOverlay showOverlay={distributionReinvestmentsRequestInProgress}>
      <Table>
        <THead>
          <Tr>
            <Th>{translate('pages.distributionReinvestments.fieldNames.fundName')}</Th>
            <Th>{translate('pages.distributionReinvestments.fieldNames.validFrom')}</Th>
            <Th>
              {translate('pages.distributionReinvestments.fieldNames.reinvestmentPreference')}
            </Th>
            {canEdit && (<Th />)}
          </Tr>
        </THead>
        <TBody>
          {isEmpty(latestDistributionReinvestmentsResponse.distributionReinvestments) ? (
            <NoResultsRow
              colSpan={colSpan}
              message={translate('pages.distributionReinvestments.noDistributionReinvestments')}
            />
          ) : (
            latestDistributionReinvestmentsResponse.distributionReinvestments.map(
              (distributionReinvestment, index) => (
                <Tr key={distributionReinvestment.distributionReinvestmentId}>
                  <Td>{distributionReinvestment.fundName}</Td>
                  <Td>{formatDate(distributionReinvestment.validFrom)}</Td>
                  <Td>{distributionReinvestment.reinvestmentPreference}</Td>
                  {canEdit && (
                    <Td align="right">
                      <ActionButtonsContainer>
                        {distributionReinvestment.editable && (
                          <EditButton
                            onClick={() =>
                              navigate(
                                `${distributionReinvestment.distributionReinvestmentId}/edit`
                              )
                            }
                          />
                        )}
                        <DeleteDistributionReinvestmentButton
                          distributionReinvestmentId={
                            distributionReinvestment.distributionReinvestmentId
                          }
                          size="medium"
                        />
                      </ActionButtonsContainer>
                    </Td>
                  )}
                </Tr>
              )
            )
          )}
        </TBody>
      </Table>
    </TableLoadingOverlay>
  );
};

const ActionButtonsContainer = styled.div`
  display: inline-flex;
  gap: ${spacing16};
`;
