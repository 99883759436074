import React from 'react';
import styled, { css } from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';
import { inputHeightPixels } from './Input';

// In certain situations we want to display an icon on the right-hand side of an
// input (such as a text field or a dropdown), but keep it separate from the
// actual input component so that we have greater control over things like
// disabled/enabled state and click event propagation.

type Props = {
  children: React.ReactNode;
  icon: React.ReactNode;
  onClickIcon: () => void;
  iconColour?: string;
  iconSize?: string;
  className?: string;
};

export const InputOrSelectOverlaidActionIcon = ({
  children,
  icon,
  onClickIcon,
  iconColour,
  iconSize,
  className,
}: Props) => (
  <Container className={className}>
    {children}
    <IconContainer onClick={onClickIcon} colour={iconColour} iconSize={iconSize ?? spacing16}>
      {icon}
    </IconContainer>
  </Container>
);

const Container = styled.div`
  position: relative;
`;

const IconContainer = styled.div<{ colour?: string; iconSize: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${spacing16};
  ${(props) =>
    css`
      bottom: calc((${inputHeightPixels}px - ${props.iconSize}) / 2);
    `};
  color: ${(props) => props.colour ?? 'inherit'};
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.75;
  }

  svg {
    height: ${(props) => props.iconSize};
    width: ${(props) => props.iconSize};
  }
`;
