import React, { useContext, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { RequiresUserRole } from '../../authentication/UserRoles';
import { Header1 } from '../../../infrastructure/interface/components/Headers';
import { CentredPaddedPage } from '../../../styling/layout/PaddedPage';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { PlaceTransactionFormFields } from './PlaceTransactionFormFields';
import { placeTransactionsUserRoles } from './placeTransactionUserRoles';
import { Formik, FormikHelpers } from 'formik';
import { PlaceTransactionFormValidator } from './PlaceTransactionFormValidator';
import { AuthenticationContext } from '../../authentication/AuthenticationContext';
import { DealingTransactionTypeCode } from '../../metadata/transactions/DealingTransactionType';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { PageHeaderActions } from '../../../infrastructure/interface/components/PageHeaderActions';
import { AppLinkWithIcon } from '../../../infrastructure/interface/components/AppLink';
import { UploadIcon } from '../../../icons/icons';
import {
  transactionLockCode,
  TransactionLockCode,
} from '../../metadata/transactions/TransactionLock';
import { useNavigate } from 'react-router-dom';
import { FormActionButtons } from '../../../infrastructure/forms/common/FormActionButtons';
import {
  toCreateTransactionRequest,
  useCreateTransactionRequest,
} from './useCreateTransactionRequest';
import { Form } from '../../../infrastructure/forms/common/Form';
import { CompanyEqualizationTypeCode } from './useGetCompanyTransacitonDetails';
import {
  GetTransactionDetailResponse,
  useGetTransactionDetailRequest,
} from '../useGetTransactionDetailRequest';
import { useQueryParam } from '../../../infrastructure/hooks/useQueryParam';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { ApiRequestStateWrapper } from '../../../infrastructure/api/ApiRequestStateWrapper';
import { useGetJson, useGetJsonWithoutTranslation } from '../../../infrastructure/api/useGetJson';
import {
  MfaModalFormModel,
  MultiFactorAuthenticationModal,
} from '../../authentication/multiFactorAuthentication/MultiFactorAuthenticationModal';
import { AuthenticationResult } from '../../authentication/AuthenticationResult';
import { useInitiateMfaFlowForLoggedInUserRequest } from '../../authentication/multiFactorAuthentication/useInitiateMfaFlowForLoggedInUserRequest';
import { RequiresMenuOption } from '../../authentication/UserMenuOptions';
import { useLogoutDueToLockOut } from '../../../infrastructure/hooks/useLogoutDueToLockOut';
import { DefaultTransactionsPageLink } from '../defaultTransactionsPageLink.function';

export const PlaceTransaction = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.placeTransaction.title'));

  const { getUser } = useContext(AuthenticationContext);
  const { permissions } = getUser();

  const { logoutDueToLockOut } = useLogoutDueToLockOut();
  const dealNumberQueryParam = parseInt(useQueryParam('dealNumber'));
  const uploadIdQueryParam = parseInt(useQueryParam('uploadId'));
  const getTransactionDetailRequest = useGetTransactionDetailRequest();

  const dealNumberAndUploadIdAreValid = !isNaN(dealNumberQueryParam) && !isNaN(uploadIdQueryParam);

  const [mfaTokenValidated, setMfaTokenValidation] = useState<boolean>(false);

  const validateMfaToken = (
    mfaToken: string,
    mfaFormFormikHelpers: FormikHelpers<MfaModalFormModel>
  ) => {
    validateMfaTokenRequest.makeRequest({
      queryParameters: { MultiFactorAuthenticationToken: mfaToken },
      onSuccess: (response) => {
        mfaFormFormikHelpers.setSubmitting(false);
        setMfaTokenValidation(response.validToken);
      },
      onFailure: (error, response) => {
        mfaFormFormikHelpers.setSubmitting(false);

        if (response.translationKey === 'errors.authentication.accountIsLockedOut') {
          //user has been locked out, so force logout
          logoutDueToLockOut(response.translationKey);
        }
      },
    });
  };

  const validateMfaTokenRequest = useGetJson<ValidateMfaTokenQuery, ValidateMfaTokenResponse>(
    '/api/authentication/ValidateMfaForLoggedInUser'
  );

  const onMfaFormSubmit = (
    mfaToken: string,
    mfaFormFormikHelpers: FormikHelpers<MfaModalFormModel>
  ) => {
    validateMfaToken(mfaToken, mfaFormFormikHelpers);
  };

  const initiateMfaFlowRequest = useInitiateMfaFlowForLoggedInUserRequest();

  const makeInitiateMfaFlowRequest = () =>
    initiateMfaFlowRequest.makeRequest({
      requestBody: {},
      onSuccess: (initiateMfaFlowResponse) => {
        setMfaAuthenticationResult(initiateMfaFlowResponse.authenticationResult);
      },
    });

  const onMfaFormCancel = () => {
    setMfaAuthenticationResult(null);
  };

  const [mfaAuthenticationResult, setMfaAuthenticationResult] =
    useState<AuthenticationResult | null>(null);

  const [mfaRequired, setMfaRequired] = useState<boolean>();

  const getMfaRequirementSuccess = (response: GetMfaRequirementResponse) => {
    setMfaRequired(response.multiFactorAuthenticationRequirement.requireOnTransactions);
    if (response.multiFactorAuthenticationRequirement.requireOnTransactions) {
      makeInitiateMfaFlowRequest();
    }
  };

  const getMfaRequirement = () => {
    getMfaRequirementRequest.makeRequest({
      onSuccess: getMfaRequirementSuccess,
    });
  };

  const getMfaRequirementRequest = useGetJsonWithoutTranslation<
    undefined,
    GetMfaRequirementResponse
  >('/api/authentication/GetMfaRequirement');

  useOnMount(() => {
    if (dealNumberAndUploadIdAreValid) {
      getTransactionDetailRequest.makeRequest({
        queryParameters: {
          dealNumber: dealNumberQueryParam,
          uploadId: uploadIdQueryParam,
        },
      });
    } else {
      getMfaRequirement();
    }
  });

  return permissions.canCreateTransactions ? (
    <RequiresUserRole userRole={placeTransactionsUserRoles}>
      <RequiresMenuOption menuOption={'transactions'}>
        <CentredPaddedPage>
          <Header1>{translate('pages.placeTransaction.header')}</Header1>
          <PageHeaderActions withBackButton={true}>
            <AppLinkWithIcon icon={<UploadIcon />} to="/transactions/upload">
              {translate('pages.transactions.uploadTransactionsButton')}
            </AppLinkWithIcon>
          </PageHeaderActions>
          {dealNumberAndUploadIdAreValid ? (
            <ApiRequestStateWrapper apiRequestState={getTransactionDetailRequest.state}>
              {(response) => (
                <PlaceTransactionComponent
                  allowWebInstructions={permissions.canAddTransactionWebInstructions}
                  initialFormValues={toPlaceTransactionFormValues(response)}
                />
              )}
            </ApiRequestStateWrapper>
          ) : (
            <>
              {mfaRequired && !mfaTokenValidated ? (
                <MultiFactorAuthenticationModal
                  mfaAuthenticationResult={mfaAuthenticationResult}
                  setMfaAuthenticationResult={setMfaAuthenticationResult}
                  onSubmit={(mfaFormModel, mfaFormFormikHelpers) =>
                    onMfaFormSubmit(mfaFormModel.mfaCode, mfaFormFormikHelpers)
                  }
                  onCancel={() => onMfaFormCancel()}
                  mfaRequiredActionRequestState={validateMfaTokenRequest.state}
                />
              ) : (
                <PlaceTransactionComponent
                  allowWebInstructions={permissions.canAddTransactionWebInstructions}
                  initialFormValues={initialPlaceTransactionFormValues}
                />
              )}
            </>
          )}
        </CentredPaddedPage>
      </RequiresMenuOption>
    </RequiresUserRole>
  ) : (
    <Alert alertType="negative" header={translate('errors.generic')}>
      {translate('pages.placeTransaction.accessError')}
    </Alert>
  );
};

type PlaceTransactionComponentProps = {
  allowWebInstructions: boolean;
  initialFormValues: PlaceTransactionFormModel;
};

const PlaceTransactionComponent = (props: PlaceTransactionComponentProps) => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();

  const { getUser } = useContext(AuthenticationContext);
  const user = getUser();

  const [formLoadError, setFormLoadError] = useState<string | null>(null);
  const [companyEqualizationType, setCompanyEqualizationType] =
    useState<CompanyEqualizationTypeCode | null>(null);

  const validator = useMemo(
    () => new PlaceTransactionFormValidator(translate, user, companyEqualizationType),
    [translate, user, companyEqualizationType]
  );

  const createTransactionRequest = useCreateTransactionRequest();
  const { error: createRequestError, response: createRequestResponse } =
    createTransactionRequest.state;

  if (formLoadError != null) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')}>
        {formLoadError}
      </Alert>
    );
  }

  const onCancel = () => {
    navigate(DefaultTransactionsPageLink());
  };

  const onSubmit = (
    formModel: PlaceTransactionFormModel,
    formikHelpers: FormikHelpers<PlaceTransactionFormModel>
  ) => {
    createTransactionRequest.makeRequest({
      requestBody: toCreateTransactionRequest(formModel),
      onSuccess: (response) => {
        formikHelpers.setSubmitting(false);
        if (isEmpty(response.errors)) {
          navigate(`/transactions/${response.uploadId}/${response.dealNumber}/confirm`);
        }
      },
      onFailure: () => {
        formikHelpers.setSubmitting(false);
      },
    });
  };

  return (
    <>
      <Formik<PlaceTransactionFormModel>
        onSubmit={onSubmit}
        initialValues={props.initialFormValues}
        validate={validator.validate}
      >
        <Form>
          <PlaceTransactionFormFields
            onFormLoadError={setFormLoadError}
            allowWebInstructions={props.allowWebInstructions}
            setCompanyEqualizationType={setCompanyEqualizationType}
          />
          <FormActionButtons
            onCancel={onCancel}
            cancelButtonText={translate('pages.placeTransaction.cancelButton.text')}
            submitButtonText={translate('pages.placeTransaction.submitButton.text')}
            submitButtonSubmittingText={translate(
              'pages.placeTransaction.submitButton.submittingText'
            )}
          />
        </Form>
      </Formik>
      {createRequestError != null && (
        <Alert alertType="negative" withMarginTop={true} header={translate('errors.apology')}>
          {createRequestError}
        </Alert>
      )}
      {createRequestResponse != null && !isEmpty(createRequestResponse.errors) && (
        <Alert
          alertType="negative"
          withMarginTop={true}
          header={translate('pages.placeTransaction.errors.header')}
        >
          {createRequestResponse.errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </Alert>
      )}
    </>
  );
};

export type PlaceTransactionFormModel = {
  investorId: number | null;
  companyId: number | null;
  transactionType: DealingTransactionTypeCode | null;
  dealingDate: IsoDatestamp | null;
  equityAttributionId: number | null;
  switchToCompanyId: number | null;
  switchToEquityAttributionId: number | null;
  transactionLock: TransactionLockCode;
  amountOrShares: number | null;
  transactionReference: string;
  additionalInstructions: string;
};

export const initialPlaceTransactionFormValues: PlaceTransactionFormModel = {
  investorId: null,
  companyId: null,
  transactionType: null,
  dealingDate: null,
  equityAttributionId: null,
  switchToCompanyId: null,
  switchToEquityAttributionId: null,
  transactionLock: transactionLockCode.Amount,
  amountOrShares: null,
  transactionReference: '',
  additionalInstructions: '',
};

const toPlaceTransactionFormValues = (
  transactionDetails: GetTransactionDetailResponse
): PlaceTransactionFormModel => ({
  investorId: transactionDetails.investorId,
  companyId: transactionDetails.fundId,
  transactionType: transactionDetails.transactionTypeCode,
  dealingDate: transactionDetails.dealingDate,
  equityAttributionId: transactionDetails.shareClassSeriesId,
  switchToCompanyId: transactionDetails.companyIdSwitch,
  switchToEquityAttributionId: transactionDetails.equityAttributionIdSwitch,
  transactionLock: transactionDetails.transactionLock,
  amountOrShares: transactionDetails.amount ?? transactionDetails.shares,
  transactionReference: transactionDetails.transactionReference,
  additionalInstructions: transactionDetails.additionalInstructions,
});

type GetMfaRequirementResponse = {
  multiFactorAuthenticationRequirement: MultiFactorAuthenticationRequirement;
};

type MultiFactorAuthenticationRequirement = {
  code: string;
  description: string;
  requireOnTransactions: boolean;
  requireOnLogin: boolean;
};

type ValidateMfaTokenQuery = {
  MultiFactorAuthenticationToken: string;
};

type ValidateMfaTokenResponse = {
  validToken: boolean;
};
