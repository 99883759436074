import React, { useMemo } from 'react';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { ReportResponse } from '../../Reports';
import { CustomReportParameter } from '../../ReportViewer';
import { Alert } from '../../../../infrastructure/interface/components/Alert';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { CentredSpinner } from '../../../../infrastructure/interface/components/Spinner';
import { BarChart } from '../../../../infrastructure/charts/BarChart';
import { getIsoDatestampFromUnknownFormatDateTimeString } from '../../../../helpers/dateTimeHelpers';
import { ChartDataPoint } from '../../../../infrastructure/charts/Chart';
import { isBlank } from '../../../../helpers/stringHelpers';

export const portfolioIrrChartTestId = 'portfolio-irr-chart';

type Props = {
  report: ReportResponse;
  customReportParameters: Array<CustomReportParameter>;
};

export const PortfolioIrrChart = ({ report, customReportParameters }: Props) => {
  const { formatDate, translate, formatNumber } = useInternationalisation();

  const {
    makeRequest,
    state: { response, inProgress, error },
  } = usePostJson<DataForPortfolioIrrChartRequest, DataForPortfolioIrrChartResponse>(
    '/api/reports/GetDataForPortfolioIrrChart'
  );

  useOnMount(() => makeRequest({ requestBody: { reportId: report.id, customReportParameters } }));

  const title = useMemo(() => {
    if (response != null) {
      const localFormatPriorDate = isBlank(response.priorDate)
        ? ''
        : formatDate(response.priorDate);
      const localFormatValueDate = isBlank(response.valueDate)
        ? ''
        : formatDate(response.valueDate);

      return translate('pages.reports.charts.portfolioIrr.title', {
        priorDate: localFormatPriorDate,
        valueDate: localFormatValueDate,
      });
    }
  }, [translate, formatDate, response]);

  const exportFilename = useMemo(() => {
    if (response != null) {
      const priorDateInIsoDateFormat = isBlank(response.priorDate)
        ? ''
        : getIsoDatestampFromUnknownFormatDateTimeString(response.priorDate);
      const valueDateInIsoDateFormat = isBlank(response.valueDate)
        ? ''
        : getIsoDatestampFromUnknownFormatDateTimeString(response.valueDate);

      return translate('pages.reports.charts.portfolioIrr.exportFilenamePrefix', {
        priorDate: priorDateInIsoDateFormat,
        valueDate: valueDateInIsoDateFormat,
      });
    }
  }, [translate, response]);

  const dataPoints: Array<ChartDataPoint> = useMemo(
    () =>
      response != null
        ? response.dataPoints.map((dataPoint) => ({
            label: dataPoint.securityName,
            value: dataPoint.irrValue,
          }))
        : [],
    [response]
  );

  if (error) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')}>
        {error}
      </Alert>
    );
  }

  if (response == null || inProgress) {
    return <CentredSpinner size="xlarge" />;
  }

  return (
    <BarChart
      data-testid={portfolioIrrChartTestId}
      title={title ?? ''}
      subtitle={response.companyName}
      exportFilename={exportFilename ?? ''}
      yAxisOptions={{
        labels: {
          formatter() {
            return `${formatNumber(this.value, { decimalPlaces: 2 })}%`;
          },
        },
      }}
      tooltipOptions={{
        pointFormatter() {
          const formattedIrrValue =
            this.y === undefined ? '' : formatNumber(this.y, { decimalPlaces: 4 });
          return `<span style="color:${this.color}">●</span> <b>${formattedIrrValue}%</b>`;
        },
      }}
      dataPoints={dataPoints}
    />
  );
};

export type DataForPortfolioIrrChartRequest = {
  reportId: number;
  customReportParameters: Array<CustomReportParameter>;
};

export type DataForPortfolioIrrChartResponse = {
  companyName: string;
  valueDate: string;
  priorDate: string;
  dataPoints: Array<PortfolioIrrChartDataPoint>;
};

type PortfolioIrrChartDataPoint = {
  securityName: string;
  irrValue: number;
};
