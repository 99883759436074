import React from 'react';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../../MetadataSingleSelectField';
import {
  GeneralLedgerBankSelect,
  GeneralLedgerBankSelectOptionValue,
} from './GeneralLedgerBankSelect';
import { GeneralLedgerBankSelectSettings } from './GeneralLedgerBankSelectSettings';
import { GetGeneralLedgerBankDropdownOptionsResponse } from './GetGeneralLedgerBankDropdownOptionsResponse';

type Props = MetadataSingleSelectFieldComponentProps<
  GeneralLedgerBankSelectOptionValue,
  GeneralLedgerBankSelectSettings,
  GetGeneralLedgerBankDropdownOptionsResponse
>;

export const GeneralLedgerBankSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    GeneralLedgerBankSelectOptionValue,
    GeneralLedgerBankSelectSettings,
    GetGeneralLedgerBankDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <GeneralLedgerBankSelect {...metadataSingleSelectProps} />
    )}
  />
);
