export type ApiRequestState<TResponse> =
  | InitialApiRequestState
  | InProgressApiRequestState
  | SuccessfulApiRequestState<TResponse>
  | FailedApiRequestState;

export type InitialApiRequestState = {
  response: null;
  inProgress: false;
  error: null;
};

export type InProgressApiRequestState = {
  response: null;
  inProgress: true;
  error: null;
};

export type SuccessfulApiRequestState<TResponse> = {
  response: TResponse;
  inProgress: false;
  error: null;
};

export type FailedApiRequestState = {
  response: null;
  inProgress: false;
  error: string;
};

export const initialApiRequestState: InitialApiRequestState = {
  response: null,
  inProgress: false,
  error: null,
};

export const inProgressApiRequestState: InProgressApiRequestState = {
  response: null,
  inProgress: true,
  error: null,
};

export const buildSuccessApiRequestState = <TResponse>(
  response: TResponse
): SuccessfulApiRequestState<TResponse> => ({
  response,
  inProgress: false,
  error: null,
});

export const buildFailedApiRequestState = (
  error: string,
  response: any = null
): FailedApiRequestState => ({
  response: response,
  inProgress: false,
  error,
});

export const requestNotInitialised = <TResponse>(
  state: ApiRequestState<TResponse>
): state is InitialApiRequestState =>
  state.response == null && !state.inProgress && state.error == null;

export const requestIsInProgress = <TResponse>(
  state: ApiRequestState<TResponse>
): state is InProgressApiRequestState =>
  state.response == null && state.inProgress && state.error == null;

export const requestSucceeded = <TResponse>(
  state: ApiRequestState<TResponse>
): state is SuccessfulApiRequestState<TResponse> =>
  state.response != null && !state.inProgress && state.error == null;

export const requestFailed = <TResponse>(
  state: ApiRequestState<TResponse>
): state is FailedApiRequestState =>
  state.response == null && !state.inProgress && state.error != null;
