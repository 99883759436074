import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { isNullUndefinedOrBlank } from '../../helpers/stringHelpers';

type StringValidationRule = {
  predicate: (stringValue: string) => boolean;
  message: string;
};

export const notBeEmpty = (translate: TranslateFunction): StringValidationRule => ({
  predicate: (stringValue: string | null) => !isNullUndefinedOrBlank(stringValue),
  message: translate('validation.requiredField'),
});

export const containMinimumUniqueCharacters = (
  minimumUniqueCharacters: number,
  translate: TranslateFunction
): StringValidationRule => ({
  predicate: (password: string): boolean => {
    const uniqueCharacters = new Set<string>();

    for (let characterIndex = 0; characterIndex < password.length; characterIndex++) {
      const character = password.charAt(characterIndex);
      uniqueCharacters.add(character);
    }

    return uniqueCharacters.size >= minimumUniqueCharacters;
  },
  message: translate('validation.text.requireUniqueCharacters', {
    minimumUniqueCharacters,
  }),
});

export const containNonAlphanumericCharacter = (
  isRequired: boolean,
  translate: TranslateFunction
): StringValidationRule => ({
  predicate: (password: string) => !isRequired || /.*[^a-zA-Z0-9].*/.test(password),
  message: translate('validation.text.requireNonAlphanumericCharacter'),
});

export const containLowercaseCharacter = (
  isRequired: boolean,
  translate: TranslateFunction
): StringValidationRule => ({
  predicate: (password: string) => !isRequired || /.*[a-z].*/.test(password),
  message: translate('validation.text.requireLowercaseCharacter'),
});

export const containUppercaseCharacter = (
  isRequired: boolean,
  translate: TranslateFunction
): StringValidationRule => ({
  predicate: (password: string) => !isRequired || /.*[A-Z].*/.test(password),
  message: translate('validation.text.requireUppercaseCharacter'),
});

export const containDigitCharacter = (
  isRequired: boolean,
  translate: TranslateFunction
): StringValidationRule => ({
  predicate: (password: string) => !isRequired || /.*[0-9].*/.test(password),
  message: translate('validation.text.requireDigit'),
});

export const haveMaxLength = (
  maxLength: number,
  translate: TranslateFunction
): StringValidationRule => ({
  predicate: (value: string) => value.length <= maxLength,
  message: translate('validation.text.maxLength', { maxLength }),
});
