import { useEffect, useState } from 'react';
import { ReportParameterResponse } from '../Report';
import { FormikValues, useFormikContext } from 'formik';
import { reportParameterTypeCodesByType } from './ReportParameterType';

export const useReportParameterInteractions = (parameters: Array<ReportParameterResponse>) => {
  const { setFieldValue, values: formValues } = useFormikContext<FormikValues>();
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [companyReportGroupId, setCompanyReportGroupId] = useState<number | null>(null);

  // When a form contains both a company (CO) parameter and a company report group (CG) parameter,
  // setting one should reset the other.
  useEffect(() => {
    const companyParameter = parameters.find(
      (p) => p.parameterTypeCode === reportParameterTypeCodesByType.company
    );
    const companyReportGroupParameter = parameters.find(
      (p) => p.parameterTypeCode === reportParameterTypeCodesByType.companyReportGroup
    );

    if (companyParameter != null && companyReportGroupParameter != null) {
      const newCompanyId = formValues[companyParameter.name];
      if (companyId !== newCompanyId) {
        setCompanyId(newCompanyId);
        if (newCompanyId != null) {
          setFieldValue(companyReportGroupParameter.name, null);
        }
        return;
      }

      const newCompanyReportGroupId = formValues[companyReportGroupParameter.name];
      if (companyReportGroupId !== newCompanyReportGroupId) {
        setCompanyReportGroupId(newCompanyReportGroupId);
        if (newCompanyReportGroupId != null) {
          setFieldValue(companyParameter.name, null);
        }
        return;
      }
    }
  }, [parameters, formValues, companyId, companyReportGroupId, setFieldValue]);
};
