import React from 'react';
import { PlusIcon } from '../../../../../icons/icons';
import { useInternationalisation } from '../../../../../internationalisation/hooks/useInternationalisation';
import { AppLinkWithIcon } from '../../../../../infrastructure/interface/components/AppLink';
import { useInvestorIdUrlParam } from '../../../useInvestorIdUrlParam';
import { useInvestorBankingDetailsContext } from '../../InvestorBankingDetailsContext';
import { InlineRequestFailedErrorMessage } from '../../../../../infrastructure/api/InlineRequestFailedErrorMessage';
import { CentredSpinner } from '../../../../../infrastructure/interface/components/Spinner';

export const CreateBankingDetailsLink = () => {
  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();

  const {
    permissionsRequestInProgress,
    permissionsRequestError,
    permissionsResponse,
    makePermissionsRequest,
  } = useInvestorBankingDetailsContext();

  if (permissionsRequestError) {
    return (
      <InlineRequestFailedErrorMessage
        error={permissionsRequestError}
        retry={makePermissionsRequest}
      />
    );
  }

  if (permissionsResponse == null || permissionsRequestInProgress) {
    return <CentredSpinner size="medium" />;
  }

  if (!permissionsResponse?.canEditBankingDetails) {
    return null;
  }

  const baseEndpoint = '/banking-details';
  const endpoint =
    investorId == null ? `${baseEndpoint}/create` : `${baseEndpoint}/${investorId}/create`;

  return (
    <AppLinkWithIcon data-testid={createBankingDetailsLinkTestId} icon={<PlusIcon />} to={endpoint}>
      {translate('pages.bankingDetails.createBankingDetailsLink')}
    </AppLinkWithIcon>
  );
};

export const createBankingDetailsLinkTestId = 'create-banking-details-link';
