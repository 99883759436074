import React from 'react';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { ReportResponse } from '../../Reports';
import { CustomReportParameter } from '../../ReportViewer';
import { Alert } from '../../../../infrastructure/interface/components/Alert';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { CentredSpinner } from '../../../../infrastructure/interface/components/Spinner';
import {
  PortfolioAttributionToDateChart,
  PortfolioAttributionToDateChartResponse,
} from './PortfolioAttributionToDateChart';
import { ReportChartsContainer } from '../ReportCharts';
import { ChartsSpacer } from '../../../../infrastructure/charts/Chart';

export const portfolioAttributionYearToDateChartTestId = 'portfolio-attribution-year-to-date-chart';
export const portfolioAttributionQuarterToDateChartTestId =
  'portfolio-attribution-quarter-to-date-chart';
export const portfolioAttributionMonthToDateChartTestId =
  'portfolio-attribution-month-to-date-chart';

type Props = {
  report: ReportResponse;
  customReportParameters: Array<CustomReportParameter>;
};

export const PortfolioAttributionCharts = ({ report, customReportParameters }: Props) => {
  const { translate } = useInternationalisation();

  const {
    makeRequest,
    state: { response, inProgress, error },
  } = usePostJson<
    DataForPortfolioAttributionChartsRequest,
    DataForPortfolioAttributionChartsResponse
  >('/api/reports/GetDataForPortfolioAttributionCharts');

  useOnMount(() => makeRequest({ requestBody: { reportId: report.id, customReportParameters } }));

  if (error) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')}>
        {error}
      </Alert>
    );
  }

  if (response == null || inProgress) {
    return <CentredSpinner size="xlarge" />;
  }

  return (
    <ReportChartsContainer>
      <PortfolioAttributionToDateChart
        data-testid={portfolioAttributionYearToDateChartTestId}
        toDateChartResponse={response.yearToDateChart}
        translationKey="yearToDate"
      />
      <ChartsSpacer />
      <PortfolioAttributionToDateChart
        data-testid={portfolioAttributionQuarterToDateChartTestId}
        toDateChartResponse={response.quarterToDateChart}
        translationKey="quarterToDate"
      />
      <ChartsSpacer />
      <PortfolioAttributionToDateChart
        data-testid={portfolioAttributionMonthToDateChartTestId}
        toDateChartResponse={response.monthToDateChart}
        translationKey="monthToDate"
      />
    </ReportChartsContainer>
  );
};

export type DataForPortfolioAttributionChartsRequest = {
  reportId: number;
  customReportParameters: Array<CustomReportParameter>;
};

export type DataForPortfolioAttributionChartsResponse = {
  yearToDateChart: PortfolioAttributionToDateChartResponse;
  quarterToDateChart: PortfolioAttributionToDateChartResponse;
  monthToDateChart: PortfolioAttributionToDateChartResponse;
};
