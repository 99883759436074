import { IsoDatestamp } from '../../../../helpers/dateTimeHelpers';
import { useGetJson } from '../../../../infrastructure/api/useGetJson';
import { ReinvestmentPreference } from '../DistributionReinvestmentsContext';

export const useGetCreateEditDistributionReinvestmentFormDefaults = () =>
  useGetJson<
    GetCreateEditDistributionReinvestmentFormDefaultsQuery,
    GetCreateEditDistributionReinvestmentFormDefaultsResponse
  >('/api/distribution-reinvestments/GetCreateEditDistributionReinvestmentFormDefaults');

type GetCreateEditDistributionReinvestmentFormDefaultsQuery = {
  counterpartId: number | null;
  distributionReinvestmentId: number | null;
};

export type GetCreateEditDistributionReinvestmentFormDefaultsResponse =
  | {
      companyId: 0;
      validFrom: IsoDatestamp;
      reinvestmentPreference: ReinvestmentPreference;
      reinvestmentDefaultIsAll: true;
    }
  | {
      companyId: number;
      validFrom: IsoDatestamp;
      reinvestmentPreference: ReinvestmentPreference;
      reinvestmentDefaultIsAll: false;
    };
