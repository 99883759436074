import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';
import { fontSizeCss, fontWeightBold } from '../../../styling/design/fonts';
import { Panel } from './Panel';
import { DismissButton } from '../buttons/DismissButton';
import { Spinner } from './Spinner';
import { ChevronIcon, Expandable } from './Expandable';

type CardType = 'fixed' | 'expandable' | 'dismissible';

type Props = {
  header: string;
  secondaryHeader?: string | React.ReactNode;
  children?: React.ReactNode;
  cardType?: CardType;
  startExpanded?: boolean;
  withMarginTop?: boolean;
  withMarginBottom?: boolean;
  contentIsLoading?: boolean;
  className?: string;
};

export const Card = (props: Props) => {
  const {
    header,
    secondaryHeader,
    children,
    withMarginTop,
    withMarginBottom,
    contentIsLoading,
    className,
  } = props;
  const cardType = props.cardType ?? 'fixed';

  const isExpandable = cardType === 'expandable';
  const isDismissible = cardType === 'dismissible';

  const [expanded, setExpanded] = useState(props.startExpanded ?? true);

  const toggleExpanded = () => {
    if (!contentIsLoading && isExpandable) {
      setExpanded(!expanded);
    }
  };

  const [isHidden, setIsHidden] = useState(false);
  const dismiss = () => {
    if (isDismissible) {
      setIsHidden(true);
    }
  };

  const [isDismissed, setIsDismissed] = useState(false);
  useEffect(() => {
    if (isHidden) {
      const timeoutId = window.setTimeout(
        () => setIsDismissed(true),
        cardDismissTransitionDurationInMilliseconds
      );
      return () => window.clearTimeout(timeoutId);
    }
  }, [isHidden, setIsDismissed]);

  if (isDismissed) {
    return null;
  }

  return (
    <CardContainer
      isHidden={isHidden}
      withMarginTop={withMarginTop}
      withMarginBottom={withMarginBottom}
      className={className}
    >
      <Expandable
        isExpanded={expanded}
        header={
          <CardHeader
            cardType={cardType}
            onClick={toggleExpanded}
            contentIsLoading={contentIsLoading ?? false}
          >
            <span>{header}</span>
            {isExpandable &&
              (contentIsLoading ? (
                <Spinner size="medium" />
              ) : (
                <ChevronIcon $componentIsExpanded={expanded} />
              ))}
            {isDismissible && <DismissButton onClick={dismiss} />}
            {secondaryHeader && <>{secondaryHeader}</>}
          </CardHeader>
        }
      >
        <CardContentContainer>{children}</CardContentContainer>
      </Expandable>
    </CardContainer>
  );
};

const cardDismissTransitionDurationInMilliseconds = 500;

const CardContainer = styled(Panel)<{
  isHidden: boolean;
  withMarginTop?: boolean;
  withMarginBottom?: boolean;
}>`
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  transform: scale(${(props) => (props.isHidden ? '90%' : '100%')});
  transition: transform ${cardDismissTransitionDurationInMilliseconds}ms ease,
    opacity ${cardDismissTransitionDurationInMilliseconds}ms ease;
  margin-top: ${(props) => (props.withMarginTop ? spacing16 : 0)};
  margin-bottom: ${(props) => (props.withMarginBottom ? spacing16 : 0)};
`;

const CardHeader = styled.div<{ cardType: CardType; contentIsLoading: boolean }>`
  position: relative;
  font-weight: ${fontWeightBold};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${fontSizeCss('large')};

  ${(props) =>
    props.cardType === 'expandable' &&
    css`
      user-select: none;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.25s ease;

      &:hover {
        opacity: 0.75;
      }
    `};

  ${(props) =>
    props.contentIsLoading &&
    css`
      cursor: wait;
      opacity: 0.75;
    `}
`;

const CardContentContainer = styled.div`
  padding-top: ${spacing16};
`;
