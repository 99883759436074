// Changes here should also be made in BankingDetailsAction.cs
export const bankingDetailsActionStatusesByCode = {
  Authorised: 'A',
  PendingDelete: 'D',
  PendingInsert: 'I',
  PendingUpdate: 'U',
} as const;

export type BankingDetailsActionStatus = keyof typeof bankingDetailsActionStatusesByCode;
export type BankingDetailsActionStatusCode =
  typeof bankingDetailsActionStatusesByCode[BankingDetailsActionStatus];
