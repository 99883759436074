import React from 'react';
import { useFormikContext } from 'formik';
import { WaitingButton } from '../../interface/buttons/WaitingButton';
import styled from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';

type Props = {
  submittingText: string;
  children: React.ReactNode;
  formId?: string;
  disabled?: boolean;
  stretch?: boolean;
  className?: string;
  withMarginTop?: boolean;
};

export const SubmitButton = (props: Props) => {
  const formikContext = useFormikContext<unknown>();
  const isSubmitting = formikContext.isSubmitting;

  return (
    <StyledWaitingButton
      className={props.className}
      waitingText={props.submittingText}
      isWaiting={isSubmitting}
      type="submit"
      disabled={props.disabled || isSubmitting}
      stretch={props.stretch}
      withMarginTop={props.withMarginTop == null ? true : props.withMarginTop}
      form={props.formId}
    >
      {props.children}
    </StyledWaitingButton>
  );
};

const StyledWaitingButton = styled(WaitingButton)<{ withMarginTop: boolean }>`
  margin-top: ${(props) => (props.withMarginTop ? spacing16 : '0')};
`;
