import React, { useMemo } from 'react';
import { ReportParameterResponse } from '../../Report';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { SingleSelectField } from '../../../../infrastructure/forms/fields/SingleSelectField';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import styled from 'styled-components/macro';
import { useGetJson } from '../../../../infrastructure/api/useGetJson';
import { useReportParameterFieldsContext } from '../ReportParameterFieldsContext';

type Props = {
  securityTypeParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const SecurityTypeSelectField = ({ securityTypeParameter, onError }: Props) => {
  const { translate } = useInternationalisation();
  const { setFieldLoaded } = useReportParameterFieldsContext();

  const getSecurityTypeDropdownOptionsRequest = useGetJson<
    GetSecurityTypeDropdownOptionsRequest,
    GetSecurityTypeDropdownOptionsResponse
  >('/api/metadata/GetSecurityTypeDropdownOptions');

  const { response, inProgress } = getSecurityTypeDropdownOptionsRequest.state;
  const responseNotLoadedOrInProgress = response == null || inProgress;

  useOnMount(() =>
    getSecurityTypeDropdownOptionsRequest.makeRequest({
      queryParameters: { includeBlank: false },
      onFailure: onError,
      onSuccess: () => setFieldLoaded(securityTypeParameter.name),
    })
  );

  const placeholder = useMemo(
    () =>
      responseNotLoadedOrInProgress
        ? translate('form.defaultLoadingPlaceholder')
        : translate('pages.reports.parameters.securityTypeSelect.placeholder'),
    [responseNotLoadedOrInProgress, translate]
  );

  const options: SelectOptions<string> = useMemo(
    () => responseToSelectOptions(response),
    [response]
  );

  return (
    <StyledSelectField<string>
      fieldName={securityTypeParameter.name}
      label={securityTypeParameter.displayText}
      placeholder={placeholder}
      options={options}
      disabled={responseNotLoadedOrInProgress}
    />
  );
};

const StyledSelectField = styled(SingleSelectField)`
  min-width: 250px;
` as typeof SingleSelectField;

export type GetSecurityTypeDropdownOptionsRequest = {
  includeBlank: boolean;
};

export type GetSecurityTypeDropdownOptionsResponse = {
  options: Array<SecurityTypeOptionResponse>;
};

export type SecurityTypeOptionResponse = {
  securityTypeCode: string;
  securityTypeName: string;
};

const responseToSelectOptions = (
  response: GetSecurityTypeDropdownOptionsResponse | null
): SelectOptions<string> =>
  !response
    ? []
    : response.options.map((option) => ({
        value: option.securityTypeCode,
        label: option.securityTypeName,
      }));
