import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import axios, { AxiosResponse } from 'axios';
import { ApiError } from './ApiError';
import { StringMap } from 'i18next';

export type ErrorResponse = {
  success: false;
  result: null;
  error: string;
  statusCode: number | null;
  translationKey: string | null;
};

export const getErrorResponse = (error: unknown, translate: TranslateFunction | null) => {
  if (isCancelledError(error)) {
    return buildErrorResponse(cancelledRequestErrorMessage, null, null);
  } else if (isHttpStatusCodeError(error) && error.response != null) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    console.error(error);

    const responseData = error.response.data;

    const errorMessage =
      translate == null
        ? 'An unexpected error has occurred'
        : isApiError(responseData) && responseData.userVisibleMessage != null
        ? responseData.userVisibleMessage
        : isApiError(responseData) && responseData.translationKey != null
        ? translate(
            responseData.translationKey,
            mapTranslationParametersToInterpolatedValues(responseData.translationParameters)
          )
        : translate('errors.generic');

    return buildErrorResponse(errorMessage, error.response.status, responseData.translationKey);
  } else if (isNoResponseReceivedError(error) && error.request != null) {
    // The request was made but no response was received. `error.request` is an instance of XMLHttpRequest
    console.error(error);

    return buildErrorResponse('The request was sent but no response was received', null, null);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error(error);

    return buildErrorResponse('Something went wrong while making the request', null, null);
  }
};

export const cancelledRequestErrorMessage = 'The request was cancelled';

const isCancelledError = (error: unknown): boolean => axios.isCancel(error);

const isHttpStatusCodeError = (error: unknown): error is { response: AxiosResponse } =>
  typeof error === 'object' && error != null && 'response' in error;

const isNoResponseReceivedError = (error: unknown): error is { request: XMLHttpRequest } =>
  typeof error === 'object' && error != null && 'request' in error;

const isApiError = (error: unknown): error is ApiError => {
  const isObject = typeof error === 'object';
  return (
    isObject &&
    error != null &&
    'message' in error &&
    'stackTrace' in error &&
    'translationKey' in error &&
    'translationParameters' in error
  );
};

const mapTranslationParametersToInterpolatedValues = (
  values: ApiError['translationParameters']
): StringMap => {
  let interpolatedValues: StringMap = {};

  if (values == null) {
    return interpolatedValues;
  }

  for (const keyValuePair of values) {
    const { placeholderKey, value } = keyValuePair;
    interpolatedValues[placeholderKey] = value;
  }

  return interpolatedValues;
};

export const buildErrorResponse = (
  error: string,
  statusCode: number | null,
  translationKey: string | null
): ErrorResponse => ({
  success: false,
  result: null,
  error,
  statusCode,
  translationKey,
});
