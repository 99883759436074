import React, { useMemo, useState } from 'react';
import {
  CreateEditBankingDetailsFormFields,
  CreateEditBankingDetailsFormModel,
  CreateEditBankingDetailsValidator,
} from './CreateEditBankingDetailsFormFields';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { assertNotNull } from '../../../../helpers/nullHelpers';
import { Form } from '../../../../infrastructure/forms/common/Form';
import { FormActionButtons } from '../../../../infrastructure/forms/common/FormActionButtons';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { useNavigate } from 'react-router-dom';
import {
  CheckIfBankingDetailsAreDuplicatedQuery,
  CheckIfBankingDetailsAreDuplicatedResponse,
  useCheckIfBankingDetailsAreDuplicatedRequest,
} from './useCheckIfBankingDetailsAreDuplicatedRequest';
import { useInvestorIdUrlParam } from '../../useInvestorIdUrlParam';
import { Alert } from '../../../../infrastructure/interface/components/Alert';
import { RequestFailedAlert } from '../../../../infrastructure/api/RequestFailedAlert';
import {
  AuthenticationResult,
  isMultiFactorAuthenticationRequired,
} from '../../../authentication/AuthenticationResult';
import { ApiRequestState } from '../../../../infrastructure/api/ApiRequestState';
import {
  MfaModalFormModel,
  MultiFactorAuthenticationModal,
} from '../../../authentication/multiFactorAuthentication/MultiFactorAuthenticationModal';
import {
  InitiateMfaFlowForLoggedInUserResponse,
  useInitiateMfaFlowForLoggedInUserRequest,
} from '../../../authentication/multiFactorAuthentication/useInitiateMfaFlowForLoggedInUserRequest';

export type CreateEditBankingDetailsFormAction = 'Create' | 'Edit';

type Props<TRequestResponse> = {
  formAction: CreateEditBankingDetailsFormAction;
  initialFormValues: CreateEditBankingDetailsFormModel;
  localClearingDetailsOnly: boolean;
  onFormLoadError: (error: string) => void;
  onMfaFormSubmit: (
    createEditBankingDetailsFormikProps: FormikProps<CreateEditBankingDetailsFormModel>,
    mfaToken: string,
    mfaFormFormikHelpers: FormikHelpers<MfaModalFormModel>
  ) => void;
  createEditRequestState: ApiRequestState<TRequestResponse>;
};

export const CreateEditBankingDetailsForm = <TRequestResponse extends unknown>({
  formAction,
  initialFormValues,
  localClearingDetailsOnly,
  onFormLoadError,
  onMfaFormSubmit,
  createEditRequestState,
}: Props<TRequestResponse>) => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();
  const investorId = useInvestorIdUrlParam();

  const [mfaAuthenticationResult, setMfaAuthenticationResult] =
    useState<AuthenticationResult | null>(null);

  const validator = useMemo(
    () => new CreateEditBankingDetailsValidator(translate, localClearingDetailsOnly),
    [translate, localClearingDetailsOnly]
  );

  const duplicateDetailsRequest = useCheckIfBankingDetailsAreDuplicatedRequest();

  const initiateMfaFlowRequest = useInitiateMfaFlowForLoggedInUserRequest();

  const makeInitiateMfaFlowRequest = () =>
    initiateMfaFlowRequest.makeRequest({
      requestBody: {},
      onSuccess: (initiateMfaFlowResponse) => {
        setMfaAuthenticationResult(initiateMfaFlowResponse.authenticationResult);
      },
    });

  const onCancel = () => navigate(`/banking-details/${investorId ?? ''}`);

  const onSubmitCreateEditBankingDetailsForm = (
    formModel: CreateEditBankingDetailsFormModel,
    formikHelpers: FormikHelpers<CreateEditBankingDetailsFormModel>
  ) => {
    setMfaAuthenticationResult(null);

    if (formAction === 'Create') {
      duplicateDetailsRequest.makeRequest({
        queryParameters: toCheckIfBankingDetailsAreDuplicatedQuery(formModel, investorId),
        onSuccess: (detailsAreDuplicatedResponse) => {
          if (!detailsAreDuplicatedResponse.isDuplicate) {
            makeInitiateMfaFlowRequest();
          } else {
            formikHelpers.setSubmitting(false);
          }
        },
        onFailure: () => {
          formikHelpers.setSubmitting(false);
        },
      });
    } else {
      makeInitiateMfaFlowRequest();
    }
  };

  const onMfaFormCancel = (
    createBankingDetailsFormikProps: FormikProps<CreateEditBankingDetailsFormModel>
  ) => {
    createBankingDetailsFormikProps.setSubmitting(false);
    setMfaAuthenticationResult(null);
  };

  return (
    <Formik<CreateEditBankingDetailsFormModel>
      onSubmit={onSubmitCreateEditBankingDetailsForm}
      initialValues={initialFormValues}
      validate={validator.validate}
    >
      {(formikProps) => (
        <>
          <Form>
            <CreateEditBankingDetailsFormFields
              formAction={formAction}
              localClearingDetailsOnly={localClearingDetailsOnly}
              onFormLoadError={onFormLoadError}
            />
            <FormSubmissionErrors
              duplicateDetailsRequestState={duplicateDetailsRequest.state}
              initiateMfaFlowRequestState={initiateMfaFlowRequest.state}
              mfaAuthenticationResult={mfaAuthenticationResult}
            />
            <FormActionButtons
              onCancel={onCancel}
              cancelButtonText={translate('pages.bankingDetails.createEdit.cancelButton.text')}
              submitButtonText={translate('pages.bankingDetails.createEdit.submitButton.text')}
              submitButtonSubmittingText={translate(
                'pages.bankingDetails.createEdit.submitButton.submittingText'
              )}
            />
          </Form>
          <MultiFactorAuthenticationModal
            data-testid={createEditBankingDetailsMfaFormTestId}
            mfaAuthenticationResult={mfaAuthenticationResult}
            setMfaAuthenticationResult={setMfaAuthenticationResult}
            onSubmit={(mfaFormModel, mfaFormFormikHelpers) =>
              onMfaFormSubmit(formikProps, mfaFormModel.mfaCode, mfaFormFormikHelpers)
            }
            onCancel={() => onMfaFormCancel(formikProps)}
            mfaRequiredActionRequestState={createEditRequestState}
          />
        </>
      )}
    </Formik>
  );
};

type FormSubmissionErrorsProps = {
  duplicateDetailsRequestState: ApiRequestState<CheckIfBankingDetailsAreDuplicatedResponse>;
  initiateMfaFlowRequestState: ApiRequestState<InitiateMfaFlowForLoggedInUserResponse>;
  mfaAuthenticationResult: AuthenticationResult | null;
};

const FormSubmissionErrors = ({
  duplicateDetailsRequestState,
  initiateMfaFlowRequestState,
  mfaAuthenticationResult,
}: FormSubmissionErrorsProps) => {
  const { translate } = useInternationalisation();

  return (
    <>
      {duplicateDetailsRequestState.error != null && (
        <RequestFailedAlert error={duplicateDetailsRequestState.error} withMarginTop={true} />
      )}
      {duplicateDetailsRequestState.response?.isDuplicate && (
        <Alert
          alertType="negative"
          withMarginTop={true}
          header={translate('pages.bankingDetails.createEdit.duplicateBankingDetailsError.header')}
        >
          {translate('pages.bankingDetails.createEdit.duplicateBankingDetailsError.text')}
        </Alert>
      )}
      {initiateMfaFlowRequestState.error != null && (
        <RequestFailedAlert error={initiateMfaFlowRequestState.error} withMarginTop={true} />
      )}
      {mfaAuthenticationResult != null &&
        !isMultiFactorAuthenticationRequired(mfaAuthenticationResult) && (
          <Alert
            alertType="negative"
            header={translate('pages.bankingDetails.createEdit.mfaNotConfiguredError.header')}
            withMarginTop={true}
          >
            {translate('pages.bankingDetails.createEdit.mfaNotConfiguredError.text')}
          </Alert>
        )}
    </>
  );
};

const toCheckIfBankingDetailsAreDuplicatedQuery = (
  formModel: CreateEditBankingDetailsFormModel,
  investorId: number | null
): CheckIfBankingDetailsAreDuplicatedQuery => ({
  productCode: assertNotNull(formModel.productCode, 'formModel.productCode'),
  companyId: assertNotNull(formModel.companyId, 'formModel.companyId'),
  currencyId: assertNotNull(formModel.currencyId, 'formModel.currencyId'),
  investorId: investorId,
});

export const createEditBankingDetailsMfaFormTestId = 'create-edit-banking-details-mfa-form';
