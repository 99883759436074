import { FormikProps, FormikValues } from 'formik';
import { parse, stringify } from 'query-string';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { parseQueryValue, UrlStateValueType } from '../../../infrastructure/hooks/useUrlState';
import { ReportParameterType, reportParameterTypesByCode } from './ReportParameterType';
import { ReportParameterResponse } from '../Report';
import { snakeOrKebabCaseToCamelCase } from '../../../helpers/stringHelpers';

export const useReportParametersInitialValues = (parameters: Array<ReportParameterResponse>) => {
  const reportFormRef = useRef<FormikProps<FormikValues>>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const parsedQueryParams = parse(location.search);

  const clearParameterValueQueryParams = () => {
    for (const param of parameters) {
      var camelCaseParameterName = snakeOrKebabCaseToCamelCase(param.name).replace('@', '');
      delete parsedQueryParams[param.name];
      delete parsedQueryParams[camelCaseParameterName];
    }
    navigate(location.pathname + '?' + stringify(parsedQueryParams), { replace: true });
  };

  useOnMount(() => {
    clearParameterValueQueryParams();
  });

  const initialValuesRef = useRef<FormikValues | null>(null);

  if (initialValuesRef.current == null) {
    initialValuesRef.current = parameters.reduce((accumulator, parameter) => {
      const parameterType = reportParameterTypesByCode[parameter.parameterTypeCode];

      let initialValue: any;

      var camelCaseParameterName = snakeOrKebabCaseToCamelCase(parameter.name).replace('@', '');
      var urlValue = parsedQueryParams[parameter.name] ?? parsedQueryParams[camelCaseParameterName];
      if (urlValue) {
        initialValue = parseQueryValue(
          urlValue as string | null,
          parameterUrlStateValueTypesByType[parameterType]
        );
      } else {
        initialValue = defaultParameterValuesByType[parameterType];
      }

      return { ...accumulator, [parameter.name]: initialValue };
    }, {});
  }

  return initialValuesRef.current;
};

const parameterUrlStateValueTypesByType: Record<ReportParameterType, UrlStateValueType> = {
  generalLedgerBank: { type: 'number', isNullable: true },
  capitalCall: { type: 'number', isNullable: true },
  companyReportGroup: { type: 'number', isNullable: true },
  company: { type: 'number', isNullable: true },
  generalLedgerCounterpart: { type: 'number', isNullable: true },
  genericDropdown: { type: 'string', isNullable: true },
  equityAttribution: { type: 'number', isNullable: true },
  freeDate: { type: 'string', isNullable: true },
  freeText: { type: 'string', isNullable: false },
  generalLedgerClass: { type: 'number', isNullable: true },
  generalLedgerSubclass: { type: 'number', isNullable: true },
  shareholder: { type: 'number', isNullable: true },
  securityType: { type: 'string', isNullable: true },
  valueDatePlusOne: { type: 'string', isNullable: true },
  valueDate: { type: 'string', isNullable: true },
  boolean: { type: 'boolean', isNullable: false },
  subLedger: { type: 'number', isNullable: true },
  currency: { type: 'number', isNullable: true },
};

const defaultParameterValuesByType: Record<ReportParameterType, null | '' | false> = {
  generalLedgerBank: null,
  capitalCall: null,
  companyReportGroup: null,
  company: null,
  generalLedgerCounterpart: null,
  genericDropdown: null,
  equityAttribution: null,
  freeDate: null,
  freeText: '',
  generalLedgerClass: null,
  generalLedgerSubclass: null,
  shareholder: null,
  securityType: null,
  valueDatePlusOne: null,
  valueDate: null,
  boolean: false,
  subLedger: null,
  currency: null,
};
