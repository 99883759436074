import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useGetCurrencyDetailsRequest } from '../../../metadata/currencies/useGetCurrencyDetailsRequest';
import { CentredSpinner } from '../../../../infrastructure/interface/components/Spinner';
import { isNullUndefinedOrBlank } from '../../../../helpers/stringHelpers';
import { InputField } from '../../../../infrastructure/forms/fields/InputField';
import { assertNotNull } from '../../../../helpers/nullHelpers';
import { FormikValues, useFormikContext } from 'formik';

type Props = {
  currencyId: number | null;
  currencyIdFieldName: string;
  fieldName: string;
  onError: (error: string) => void;
  'data-testid'?: string;
};

export const BankAccountOtherIdentifierInputField = (props: Props) => {
  const { currencyId, currencyIdFieldName, fieldName, onError } = props;
  const formikContext = useFormikContext<FormikValues>();

  const getCurrencyDetailsRequest = useGetCurrencyDetailsRequest();
  const {
    state: { inProgress, response },
  } = getCurrencyDetailsRequest;

  useEffect(() => {
    if (formikContext.initialValues[currencyIdFieldName] === null) {
      //only reset value when currency changes, not on initial setting
      formikContext.setFieldValue(fieldName, '');
    }

    if (currencyId == null) {
      getCurrencyDetailsRequest.resetState();
    } else {
      getCurrencyDetailsRequest.makeRequest({
        queryParameters: { currencyId },
        onFailure: (error) => {
          getCurrencyDetailsRequest.resetState();
          onError(error);
        },
      });
    }
  }, [currencyId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (inProgress) {
    return (
      <SpinnerContainer>
        <CentredSpinner size="medium" />
      </SpinnerContainer>
    );
  }

  return isNullUndefinedOrBlank(response?.bankAccountOtherIdentifierName ?? null) ? null : (
    <InputField
      data-testid={props['data-testid'] ?? bankAccountOtherIdentifierTestId}
      label={assertNotNull(response, 'GetCurrencyDetails.response').bankAccountOtherIdentifierName}
      fieldName={fieldName}
    />
  );
};

const SpinnerContainer = styled.div`
  height: 20px;
`;

export const bankAccountOtherIdentifierTestId = 'bank-account-other-identifier';
