import styled, { css } from 'styled-components/macro';
import { shadow1 } from '../../../styling/design/shadows';
import { fontFamily, fontSizeCss, lineHeightMedium } from '../../../styling/design/fonts';
import {
  borderColours,
  colourBlack,
  colourGrey08,
  colourWhite,
} from '../../../styling/design/colours';
import { spacing12, spacing16 } from '../../../styling/design/spacing';
import { fadeIn } from '../../../styling/design/transitions';

export const PopoverMenuContainer = styled.div`
  position: relative;
`;

export const PopoverMenu = styled.div<{
  verticalPlacement?: 'top' | 'bottom';
  horizontalPlacement?: 'left' | 'right' | 'middle';
}>`
  box-shadow: ${shadow1};
  position: absolute;
  background-color: ${colourWhite};
  ${(props) =>
    props.verticalPlacement === 'top'
      ? css`
          bottom: 40px;
        `
      : css`
          top: 40px;
        `};
  ${(props) =>
    props.horizontalPlacement === 'left'
      ? css`
          left: 0;
        `
      : props.horizontalPlacement === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 50%;
          transform: translateX(-50%);
        `};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  z-index: 10;
  border-radius: 10px;
  animation: ${fadeIn} 0.15s ease;
`;

export const PopoverMenuItem = styled.button`
  font-family: ${fontFamily};
  ${fontSizeCss('medium')};
  outline: none;
  border: none;
  background-color: transparent;
  text-align: left;
  color: ${colourGrey08};
  padding: ${spacing12} ${spacing16};
  transition: all 0.25s ease;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  line-height: ${lineHeightMedium};
  width: 100%;

  &:hover,
  &:focus {
    background-color: #f4f6f6;
    color: ${colourBlack};
  }

  svg {
    height: ${spacing16};
    width: ${spacing16};
    min-width: ${spacing16};
    margin-right: ${spacing16};
    top: 1px;
    position: relative;
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const PopoverMenuDivider = styled.div`
  height: 1px;
  background-color: ${borderColours.default};
  width: 100%;
`;
