import React from 'react';
import { ReportResponse } from '../Reports';
import { PortfolioExposureSummaryCharts } from './portfolioExposureSummary/PortfolioExposureSummaryCharts';
import { CustomReportParameter } from '../ReportViewer';
import styled from 'styled-components/macro';
import { PortfolioAttributionCharts } from './portfolioAttribution/PortfolioAttributionCharts';
import { PortfolioIrrChart } from './portfolioIrr/PortfolioIrrChart';
import { ShareholdingForAShareholderChart } from './shareholdingForAShareholder/ShareholdingForAShareholderChart';

type Props = {
  report: ReportResponse;
  customReportParameters: Array<CustomReportParameter>;
};

export const ReportCharts = ({ report, customReportParameters }: Props) => (
  <Container>
    <MaxWidthWrapper>
      <ReportChartsComponent report={report} customReportParameters={customReportParameters} />
    </MaxWidthWrapper>
  </Container>
);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MaxWidthWrapper = styled.div`
  flex-grow: 1;
  max-width: 1000px;
`;

type ReportWithChartsName =
  | 'PortfolioExposureSummary'
  | 'PortfolioAttribution'
  | 'PorfolioIRR' // Note: This typo is as per the DB
  | 'ShForSh';

const ReportChartsComponent = ({ report, customReportParameters }: Props) => {
  switch (report.name as ReportWithChartsName) {
    case 'PortfolioExposureSummary':
      return (
        <PortfolioExposureSummaryCharts
          report={report}
          customReportParameters={customReportParameters}
        />
      );
    case 'PortfolioAttribution':
      return (
        <PortfolioAttributionCharts
          report={report}
          customReportParameters={customReportParameters}
        />
      );
    case 'PorfolioIRR':
      return <PortfolioIrrChart report={report} customReportParameters={customReportParameters} />;
    case 'ShForSh':
      return (
        <ShareholdingForAShareholderChart
          report={report}
          customReportParameters={customReportParameters}
        />
      );
    default:
      throw new Error(`Charts not configured for report '${report.name}' (id: ${report.id}`);
  }
};

export const ReportChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
