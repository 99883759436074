import React from 'react';
import styled from 'styled-components/macro';
import { ButtonSize } from './BaseButton';
import {
  spacing12,
  spacing16,
  spacing24,
  spacing4,
  spacing8,
} from '../../../styling/design/spacing';

type Props = {
  icon: React.ReactNode;
  position: 'left' | 'right' | 'middle';
  size: ButtonSize;
};

export const ButtonIcon = (props: Props) => (
  <ButtonIconContainer {...props}>{props.icon}</ButtonIconContainer>
);

const ButtonIconContainer = styled.div<Props>`
  position: relative;

  margin-right: ${(props) =>
    props.position === 'left' ? getMarginSize(props.size) : getNegativeMarginSize(props.size)};

  margin-left: ${(props) =>
    props.position === 'right' ? getMarginSize(props.size) : getNegativeMarginSize(props.size)};

  svg {
    display: block;
    height: ${(props) => getButtonIconSize(props.size)};
    width: ${(props) => getButtonIconSize(props.size)};
  }
`;

const getMarginSize = (size: ButtonSize) => {
  switch (size) {
    case 'xsmall':
    case 'small':
      return spacing8;
    case 'medium':
      return spacing8;
    case 'large':
    case 'xlarge':
      return spacing12;
    default:
      return spacing8;
  }
};

const getNegativeMarginSize = (size: ButtonSize) => {
  switch (size) {
    case 'xsmall':
    case 'small':
    case 'medium':
      return 0;
    case 'large':
      return '-' + spacing4;
    case 'xlarge':
      return '-' + spacing8;
    default:
      return 0;
  }
};

const getButtonIconSize = (size: ButtonSize) => {
  switch (size) {
    case 'xsmall':
      return spacing12;
    case 'small':
    case 'medium':
      return spacing16;
    case 'large':
    case 'xlarge':
      return spacing24;
    default:
      return spacing16;
  }
};
