import styled from 'styled-components/macro';
import { FieldLabel } from '../../../../infrastructure/forms/common/FieldLabel';
import { spacing8 } from '../../../../styling/design/spacing';
import { ToggleSwitch } from '../../../../infrastructure/interface/components/ToggleSwitch';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';

type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
};

export const ShowLocalClearingDetailsToggleSwitch = ({ checked, onChange }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <Container>
      <FieldLabel>
        {translate('pages.bankingDetails.createEdit.labels.showLocalClearingDetailsOnly')}
      </FieldLabel>
      <ToggleSwitch
        data-testid={localClearingDetailsToggleSwitchTestId}
        checked={checked}
        onChange={onChange}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${FieldLabel} {
    margin-bottom: 0;
    margin-right: ${spacing8};
  }
`;

export const localClearingDetailsToggleSwitchTestId = 'local-clearing-details-toggle-switch';
