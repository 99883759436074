import React from 'react';
import { GenericSearchModal } from './GenericSearchModal';
import { Tr, Td } from '../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';
import { ItemDetailsCard } from '../../infrastructure/interface/components/ItemDetailsCard';
import { FieldAndValue } from '../../infrastructure/interface/components/FieldAndValue';
import styled from 'styled-components/macro';
import { spacing16 } from '../../styling/design/spacing';

type CounterpartIdValue = number;

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onSelectCounterpartId: (counterpartId: CounterpartIdValue) => void;
};

export const CounterpartDetailsSearchModal = ({
  isOpen,
  onRequestClose,
  onSelectCounterpartId,
}: Props) => {
  const { translate } = useInternationalisation();

  return (
    <GenericSearchModal<GetCounterpartDetailsSearchResultsQuery, CounterpartDetailsSearchResult>
      title={translate('searchModals.counterpartDetails.title')}
      inputPlaceholder={translate('searchModals.counterpartDetails.placeholder')}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      dataEndpointUrl="/api/investors/GetCounterpartDetailsSearchResults"
      getQueryParameters={(inputs) => inputs}
      desktopHeaders={[
        translate('searchModals.counterpartDetails.headings.counterpart'),
        translate('searchModals.counterpartDetails.headings.investorNumber'),
        translate('searchModals.counterpartDetails.headings.beneficialOwners'),
      ]}
      renderDesktopRow={(result) => (
        <DesktopRow
          key={result.counterpartId}
          result={result}
          onSelectCounterpartId={onSelectCounterpartId}
        />
      )}
      renderMobileItem={(result) => (
        <MobileItemCard
          key={result.counterpartId}
          result={result}
          onSelectCounterpartId={onSelectCounterpartId}
        />
      )}
    />
  );
};

type ResultProps = {
  result: CounterpartDetailsSearchResult;
  onSelectCounterpartId: (counterpartId: number) => void;
};

const DesktopRow = ({ result, onSelectCounterpartId }: ResultProps) => (
  <Tr>
    <Td>
      <MinimalButton onClick={() => onSelectCounterpartId(result.counterpartId)} textAlign={'left'}>
        {result.counterpartCode} {result.counterpartId !== 0 && "-"} {result.counterpartId !== 0 && result.counterpartId}
      </MinimalButton>
    </Td>
    <Td>{result.investorNumber}</Td>
    <Td dangerouslySetInnerHTML={{ __html: result.beneficialOwnersHtml ?? '' }} />
  </Tr>
);

const MobileItemCard = ({ result, onSelectCounterpartId }: ResultProps) => {
  const { translate } = useInternationalisation();

  return (
    <ItemDetailsCard>
      <MobileInvestorNameButton
        onClick={() => onSelectCounterpartId(result.counterpartId)}
        size="large"
        textAlign="left"
      >
        {result.counterpartCode} {result.counterpartId !== 0 && "-"} {result.counterpartId !== 0 && result.counterpartId}
      </MobileInvestorNameButton>
      <FieldAndValue
        fieldLabel={translate('searchModals.counterpartDetails.headings.investorNumber')}
        type="text"
        value={result.investorNumber}
      />
      <FieldAndValue
        fieldLabel={translate('searchModals.counterpartDetails.headings.beneficialOwners')}
        type="custom"
        value={<div dangerouslySetInnerHTML={{ __html: result.beneficialOwnersHtml ?? '' }} />}
      />
    </ItemDetailsCard>
  );
};

const MobileInvestorNameButton = styled(MinimalButton)`
  margin-bottom: ${spacing16};
`;

type GetCounterpartDetailsSearchResultsQuery = {
  searchTerm: string | null;
  pageNumber: number;
  resultsPerPage: number;
};

type CounterpartDetailsSearchResult = {
  counterpartId: number;
  counterpartCode: string | null;
  investorNumber: string | null;
  beneficialOwnersHtml: string | null;
};
