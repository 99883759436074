import styled from 'styled-components/macro';
import { fadeIn } from '../../styling/design/transitions';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { RequestFailedAlert } from './RequestFailedAlert';

type Props = {
  retry?: () => void;
  error: string;
};

export const RequestFailedPage = ({ retry, error }: Props) => (
  <PaddedPage>
    <FadeIn>
      <RequestFailedAlert retry={retry} error={error} />
    </FadeIn>
  </PaddedPage>
);

const FadeIn = styled.div`
  width: 100%;
  animation: ${fadeIn} 0.25s ease;
`;
