import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../features/authentication/AuthenticationContext';

export const useLogoutDueToLockOut = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const logoutDueToLockOut = (error: string) =>
    authenticationContext.logOut({
      onSuccess: () =>
        navigate({
          pathname: '/login',
          search: `?lockedOut=true&message=${error}`,
        }),
    });
  return { logoutDueToLockOut };
};
