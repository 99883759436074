import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { spacing16, spacing8 } from '../../../styling/design/spacing';
import { backgroundColours, borderColours } from '../../../styling/design/colours';
import { shadow1 } from '../../../styling/design/shadows';
import { useDevice } from '../../hooks/useDevice';
import { onDesktop } from '../../../styling/layout/screenBreakpoints';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useElementDomRect } from '../../hooks/useElementDomRect';

type HorizontalAlignment = 'left' | 'right' | 'middle';

type TooltipProps = {
  content: React.ReactNode;
  widthPixels?: number;
  horizontalAlignment?: HorizontalAlignment;
  children: React.ReactNode;
  className?: string;
};

export const Tooltip = ({
  content,
  widthPixels,
  horizontalAlignment,
  children,
  className,
}: TooltipProps) => {
  const { isMobile } = useDevice();
  const [showTooltipOnMobile, setShowTooltipOnMobile] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => setShowTooltipOnMobile(false));

  const tooltipWidthPixels = widthPixels ?? 200;

  const anchorElementRef = useRef<HTMLDivElement>(null);
  const anchorElementDomRect = useElementDomRect(anchorElementRef);

  const onTooltipAnchorContainerClick = () => {
    if (isMobile) {
      setShowTooltipOnMobile(!showTooltipOnMobile);
    }
  };

  return (
    <TooltipContainer ref={containerRef} className={className}>
      <TooltipAnchorContainer ref={anchorElementRef} onClick={onTooltipAnchorContainerClick}>
        {children}
      </TooltipAnchorContainer>
      {anchorElementDomRect?.width != null && (
        <TooltipPopup
          showTooltipOnMobile={showTooltipOnMobile}
          tooltipAnchorWidthPixels={anchorElementDomRect?.width}
          tooltipWidthPixels={tooltipWidthPixels}
          horizontalAlignment={horizontalAlignment ?? 'middle'}
        >
          {content}
        </TooltipPopup>
      )}
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  position: relative;
`;

type TooltipPopupProps = {
  showTooltipOnMobile: boolean;
  tooltipAnchorWidthPixels: number;
  tooltipWidthPixels: number;
  horizontalAlignment: HorizontalAlignment;
};

const TooltipPopup = styled.div<TooltipPopupProps>`
  opacity: ${(props) => (props.showTooltipOnMobile ? 1 : 0)};
  transition: opacity 0.25s ease;
  pointer-events: none;
  position: absolute;
  width: ${(props) => props.tooltipWidthPixels}px;
  padding: ${spacing16};
  background-color: ${backgroundColours.default};
  border: 1px solid ${borderColours.default};
  border-radius: 10px;
  box-shadow: ${shadow1};
  bottom: calc(100% + ${spacing8});

  ${(props) =>
    props.horizontalAlignment === 'left'
      ? css`
          left: 0;
        `
      : props.horizontalAlignment === 'middle'
      ? css`
          left: calc(((${props.tooltipAnchorWidthPixels}px - ${props.tooltipWidthPixels}px) / 2));
        `
      : css`
          right: 0;
        `};
`;

const TooltipAnchorContainer = styled.div`
  cursor: help;

  ${onDesktop(css`
    &:hover {
      + ${TooltipPopup} {
        opacity: 1;
      }
    }
  `)};
`;
