// Common scripts
import '@boldreports/javascript-reporting-controls/Scripts/common/bold.reports.common.min';
import '@boldreports/javascript-reporting-controls/Scripts/common/bold.reports.widgets.min';
// Report designer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css';
// Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
// Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';

import React from 'react';
import styled from 'styled-components/macro';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { ReportResponse } from './Reports';
import {
  UserDefinedReportParameterValues,
  userDefinedReportParameterValuesToArray,
} from './userDefinedReportHelpers';

export type ReportViewerProps = {
  className?: string;
  report: ReportResponse;
  customReportParameters: Array<CustomReportParameter>;
  customData?: CustomData | null;
  userDefinedReportParameters: UserDefinedReportParameterValues | null;
  onLoadingComplete?: () => void;
};

// https://help.boldreports.com/report-viewer-sdk/react-reporting/report-viewer/add-web-report-viewer-to-a-react-application/
export const ReportViewer = ({
  className,
  report,
  customReportParameters,
  customData,
  userDefinedReportParameters,
  onLoadingComplete,
}: ReportViewerProps) => {
  const { getLocale } = useInternationalisation();

  const onAjaxBeforeLoad = (customData?: CustomData | null) => (args: AjaxRequestArgsProps) => {
    const commonCustomData: CustomData = {
      reportId: report.id,
      reportDisplayName: report.displayName,
      reportIsUserDefined: report.isUserDefined,
      customReportParameters,
    };
    args.data = customData != null ? { ...customData, ...commonCustomData } : commonCustomData;

    let token = document.cookie.split(';').find(r=>r.trim().startsWith('XSRF-TOKEN'))?.split('=')[1];
    args.headers.push({Key:'X-XSRF-TOKEN',Value:token});
  };

  const onReportLoaded = () => onLoadingComplete != null && onLoadingComplete();
  const onReportError = () => onLoadingComplete != null && onLoadingComplete();

  return (
    <Container className={className}>
      <BoldReportViewerComponent
        id="reportviewer-container"
        reportServiceUrl="/ReportViewer"
        reportPath={report.filename}
        processingMode="Local"
        locale={getLocale()}
        ajaxBeforeLoad={onAjaxBeforeLoad(customData)}
        reportLoaded={onReportLoaded}
        reportError={onReportError}
        exportSettings={exportSettings}
        toolbarSettings={toolbarSettings}
        parameters={userDefinedReportParameterValuesToArray(userDefinedReportParameters)}
      />
    </Container>
  );
};

// https://help.boldreports.com/report-viewer-sdk/react-reporting/report-viewer/add-web-report-viewer-to-a-react-typescript-application/#adding-report-viewer-component
declare const BoldReportViewerComponent: any;

// https://help.boldreports.com/report-viewer-sdk/react-reporting/report-viewer/handle-post-actions/#pass-custom-data-in-ajax-request
export type CustomData = Record<string, any>;

type AjaxRequestArgsProps = {
  data: CustomData;
  headers: Array<{ Key: string; Value: string | undefined }>
};

export type CustomReportParameter = {
  name: string;
  value?: string;
};

declare const ej: any;

// https://help.syncfusion.com/api/js/ejreportviewer#members:exportsettings-exportoptions
const exportSettings = {
  exportOptions:
    ej.ReportViewer.ExportOptions.Pdf |
    ej.ReportViewer.ExportOptions.Excel |
    ej.ReportViewer.ExportOptions.CSV |
    ej.ReportViewer.ExportOptions.Word,
};

// https://help.boldreports.com/report-viewer-sdk/react-reporting/report-viewer/toolbar-customization/
const toolbarSettings = {
  items: ~ej.ReportViewer.ToolbarItems.Parameters,
};

// These are parameters that will appear inside the report viewer, as opposed to
// a 'CustomReportParameter' which is outside the report viewer and is actually
// used to determine what data is returned from the database.
// Currently this parameter type is only used in user defined reports to set
// some display values in the report header (fund name and dates) but has no
// affect on what data is actually fetched from the database.
// https://help.boldreports.com/report-viewer-sdk/react-reporting/report-viewer/report-parameters/
export type Parameter = {
  name: string;
  labels?: Array<string>;
  values: Array<string>;
  nullable?: boolean;
};

const Container = styled.div`
  height: 700px;
  width: 100%;
`;
