import { FormikValues } from 'formik';
import { CompanyDropdownOptionResponse } from '../metadata/company/GetCompanyDropdownOptionsResponse';
import { CompanyReportGroupDropdownOptionResponse } from '../metadata/companyReportGroup/GetCompanyReportGroupDropdownOptionsResponse';
import { FormatDateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import {
  ReportParameterTypeCode,
  reportParameterTypeCodesByType,
} from './parameters/ReportParameterType';
import { ReportParameterResponse } from './Report';
import { Parameter } from './ReportViewer';

// For user defined reports, we need to send parameters to populate the
// company / company report group name, and dates in the report header. These
// parameters are for display purposes only, and have no affect on what data is
// actually fetched from the database.

type UserDefinedReportParameterKey = 'company_crg_name' | 'value_date1' | 'value_date2';
const userDefinedReportParameterKeys: Record<
  UserDefinedReportParameterKey,
  UserDefinedReportParameterKey
> = {
  company_crg_name: 'company_crg_name',
  value_date1: 'value_date1',
  value_date2: 'value_date2',
};

export type UserDefinedReportParameterValues = {
  companyCrgName: string | null;
  valueDate1: string | null;
  valueDate2: string | null;
};

export const mapFormModelToUserDefinedReportParameters = (
  formModel: FormikValues,
  selectedCompanyOption: CompanyDropdownOptionResponse | null,
  selectedCompanyReportGroupOption: CompanyReportGroupDropdownOptionResponse | null,
  parameters: Array<ReportParameterResponse>,
  formatDate: FormatDateFunction
): UserDefinedReportParameterValues => {
  const parameterValues: UserDefinedReportParameterValues = {
    companyCrgName: null,
    valueDate1: null,
    valueDate2: null,
  };

  for (const [parameterName, parameterValue] of Object.entries(formModel)) {
    if (parameterValue != null) {
      const parameter: ReportParameterResponse | undefined = parameters.find(
        (p) => p.name === parameterName
      );

      if (parameter != null) {
        const parameterTypeCode: ReportParameterTypeCode = parameter.parameterTypeCode;

        switch (parameterTypeCode) {
          case reportParameterTypeCodesByType.company: {
            if (parameterValues.companyCrgName == null) {
              parameterValues.companyCrgName = selectedCompanyOption?.companyName ?? null;
            }
            break;
          }
          case reportParameterTypeCodesByType.companyReportGroup: {
            if (parameterValues.companyCrgName == null) {
              parameterValues.companyCrgName =
                selectedCompanyReportGroupOption?.companyReportGroupName ?? null;
            }
            break;
          }
          case reportParameterTypeCodesByType.freeDate: {
            if (parameterValues.valueDate1 == null) {
              parameterValues.valueDate1 = formatDate(parameterValue);
            } else if (parameterValues.valueDate2 == null) {
              parameterValues.valueDate2 = formatDate(parameterValue);
            }
            break;
          }
          case reportParameterTypeCodesByType.valueDate: {
            if (parameterValues.valueDate1 == null) {
              parameterValues.valueDate1 = formatDate(parameterValue);
            } else if (parameterValues.valueDate2 == null) {
              parameterValues.valueDate2 = formatDate(parameterValue);
            }
            break;
          }
          default:
            break;
        }
      }
    }
  }

  return parameterValues;
};

export const userDefinedReportParameterValuesToArray = (
  parameterValues: UserDefinedReportParameterValues | null
): Array<Parameter> => {
  const parameterArray: Array<Parameter> = [];

  if (parameterValues != null) {
    if (parameterValues.companyCrgName != null) {
      parameterArray.push({
        name: userDefinedReportParameterKeys.company_crg_name,
        values: [parameterValues.companyCrgName],
      });
    }
    if (parameterValues.valueDate1 != null) {
      parameterArray.push({
        name: userDefinedReportParameterKeys.value_date1,
        values: [parameterValues.valueDate1],
      });
    }
    if (parameterValues.valueDate2 != null) {
      parameterArray.push({
        name: userDefinedReportParameterKeys.value_date2,
        values: [parameterValues.valueDate2],
      });
    }
  }

  return parameterArray;
};
