import { useEffect, useRef } from 'react';

// This hook can be used to fire an action when a component mounts, but instead of
// firing immediately it fires after a specified delay.
export const useOnMountAfterDelay = (action: () => void, delayInMilliseconds: number) => {
  const timeoutId = useRef<number>();

  useEffect(() => {
    timeoutId.current = window.setTimeout(action, delayInMilliseconds);
    return () => window.clearTimeout(timeoutId.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
