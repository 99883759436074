import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useDashboardFilters } from '../DashboardFiltersContext';
import React, { useEffect } from 'react';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { ChartDataPoint } from '../../../infrastructure/charts/Chart';
import { useDevice } from '../../../infrastructure/hooks/useDevice';
import { StackedBarChart } from '../../../infrastructure/charts/StackedBarChart';
import { ComponentResponse } from '../DashboardComponent';

export const exposureChartComponentName = 'Exposure';

export const ExposureChart = () => {
  const getRequest = useGetJson<GetDataForExposureChartQuery, GetDataForExposureChartResponse>(
    'api/dashboards/GetDataForExposureChart'
  );

  const { companyId, fromDate } = useDashboardFilters();

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <ExposureChartComponent response={response} showLoadingOverlay={showLoadingOverlay} />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForExposureChartResponse;
  showLoadingOverlay: boolean;
};

const ExposureChartComponent = ({ response, showLoadingOverlay }: ComponentProps) => {
  const { translate, formatNumber } = useInternationalisation();
  const { isMobile } = useDevice();

  const graphData: Array<ChartDataPoint> = response.dataPoints.map((dataPoint) => ({
    label: dataPoint.label,
    value: dataPoint.value,
  }));
  return (
    <DashboardComponentLayout
      headerText={translate('pages.dashboard.components.exposureChart.title')}
      showLoadingOverlay={showLoadingOverlay}
      showNoDataMessage={response.noDataMessage}
    >
      <StackedBarChart
        exportFilename={translate('pages.dashboard.components.exposureChart.exportName')}
        dataPoints={graphData}
        height="stretch"
        responsiveOptions={{
          condition: {
            callback: () => isMobile,
          },
        }}
        yAxisOptions={{
          labels: {
            formatter() {
              return `${this.value}%`;
            },
          },
        }}
        tooltipOptions={{
          pointFormatter() {
            return `<b><span style="color: ${this.color}">${
              this.name
            }&nbsp;</span>:&nbsp;${formatNumber(this.y ?? 0, { decimalPlaces: 2 })}%</b>`;
          },
          headerFormat: `Currency <br/>`,
        }}
      />
    </DashboardComponentLayout>
  );
};
type GetDataForExposureChartQuery = {
  companyId: number;
  runDate: string;
};

export type GetDataForExposureChartResponse = ComponentResponse & {
  dataPoints: Array<{
    value: number;
    label: string;
  }>;
};
