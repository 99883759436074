export const assertNotNull = <TValue extends unknown>(
  value: TValue | null,
  identifier: string
): TValue => {
  if (value == null) {
    throw new Error(`Expected value of '${identifier}' to be non-null`);
  } else {
    return value;
  }
};
