import React, { useMemo, useState } from 'react';
import { CentredPaddedPage, PaddedPage } from '../../styling/layout/PaddedPage';
import { IfUserHasRole, RequiresUserRole } from '../authentication/UserRoles';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { ManagerUploadDocumentForm } from './upload/ManagerUploadDocumentForm';
import { ConsolidatedInvestorUploadDocumentForm } from './upload/ConsolidatedInvestorUploadDocumentForm';
import { InvestorUploadDocumentForm } from './upload/InvestorUploadDocumentForm';
import { BackButton } from '../../infrastructure/interface/components/BackButton';
import { spacing32 } from '../../styling/design/spacing';
import styled from 'styled-components/macro';
import {
  GroupedSelectOptions,
  SelectOption,
  SelectOptions,
} from '../../infrastructure/interface/forms/BaseSelect';
import { sortBy } from 'lodash';

export const UploadDocument = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.uploadDocument.title'));

  return (
    <RequiresUserRole userRole={['Manager', 'Investor', 'Consolidated Investor']}>
      <UploadDocumentComponent />
    </RequiresUserRole>
  );
};

const UploadDocumentComponent = () => {
  const { translate } = useInternationalisation();

  const [documentCategoryGroupsOptions, setDocumentCategoryGroupsOptions] = useState<
    GroupedSelectOptions<CategoryResponse>
  >([]);

  const [categoryResponse, setCategoryResponse] = useState<CategoryResponse[]>([]);

  const getValidationSettingsRequest = useGetJson<
    undefined,
    GetDocumentUploadValidationSettingsResponse
  >('/api/documents/GetDocumentUploadValidationSettings');

  const documentCategoryGroupsDetails = useGetJson<
    GetDocumentCategoryGroupsResultQuery,
    GetDocumentCategoryGroupsResultResponse
  >('/api/documents/GetDocumentCategoryGroups');

  const getDocumentCategoryGroupDetails = () => {
    documentCategoryGroupsDetails.makeRequest({
      queryParameters: { includeAllCategory: true, isForUpload: true },
      onSuccess: getDocumentCategoryGroupDetailsSuccess,
    });
  };

  const getDocumentCategoryGroupDetailsSuccess = (
    response: GetDocumentCategoryGroupsResultResponse
  ) => {
    setDocumentCategoryGroupsOptions(getDocumentCategoryGroupsOptions(response));
    setCategoryResponse(response.documentCategories);
  };

  const getDocumentCategoryGroupsOptions = ({
    documentCategories,
    documentCategoryGroups,
  }: GetDocumentCategoryGroupsResultResponse): GroupedSelectOptions<CategoryResponse> =>
    documentCategoryGroups
      .map((group) => {
        const groupSelectOptions: SelectOptions<CategoryResponse> = documentCategories
          .filter((category) => category.categoryGroupId === group.categoryGroupId)
          .map(categoryResponseToSelectOption);

        return {
          label: group.description ?? '',
          options: sortBy(groupSelectOptions, function (item) {
            return [item.value.sortOrder, item.label.toLowerCase()];
          }),
        };
      })
      .filter((groupedSelectOption) => groupedSelectOption.options.length > 0);

  const categoryResponseToSelectOption = (
    categoryResponse: CategoryResponse
  ): SelectOption<CategoryResponse> => ({
    value: categoryResponse,
    label: categoryResponse.description ?? '',
  });

  useOnMount(() => {
    getValidationSettingsRequest.makeRequest();
    getDocumentCategoryGroupDetails();
  });

  const validationSettingsLoadError = getValidationSettingsRequest.state.error;

  const allowedFileExtensions = useMemo(
    () => getValidationSettingsRequest.state.response?.allowedFileExtensions ?? [],
    [getValidationSettingsRequest]
  );

  const maximumUploadSizeInBytes = useMemo(
    () => getValidationSettingsRequest.state.response?.maximumUploadSizeInBytes ?? 0,
    [getValidationSettingsRequest]
  );

  if (validationSettingsLoadError != null) {
    return (
      <PaddedPage>
        <Alert alertType="negative" header={translate('errors.apology')}>
          {validationSettingsLoadError}
        </Alert>
      </PaddedPage>
    );
  }

  return (
    <CentredPaddedPage>
      <Header1>{translate('pages.uploadDocument.header')}</Header1>
      <BackButton />
      <FormContainer>
        <IfUserHasRole userRole="Manager">
          <ManagerUploadDocumentForm
            allowedFileExtensions={allowedFileExtensions}
            maximumUploadSizeInBytes={maximumUploadSizeInBytes}
            documentCategoryGroupsOptions={documentCategoryGroupsOptions}
          />
        </IfUserHasRole>
        <IfUserHasRole userRole="Consolidated Investor">
          <ConsolidatedInvestorUploadDocumentForm
            allowedFileExtensions={allowedFileExtensions}
            maximumUploadSizeInBytes={maximumUploadSizeInBytes}
            documentCategoryGroupsOptions={documentCategoryGroupsOptions}
            categoryResponse={categoryResponse}
          />
        </IfUserHasRole>
        <IfUserHasRole userRole="Investor">
          <InvestorUploadDocumentForm
            allowedFileExtensions={allowedFileExtensions}
            maximumUploadSizeInBytes={maximumUploadSizeInBytes}
            documentCategoryGroupsOptions={documentCategoryGroupsOptions}
            categoryResponse={categoryResponse}
          />
        </IfUserHasRole>
      </FormContainer>
    </CentredPaddedPage>
  );
};

const FormContainer = styled.div`
  padding: ${spacing32} 0;
`;

export type GetDocumentUploadValidationSettingsResponse = {
  allowedFileExtensions: Array<string>;
  maximumUploadSizeInBytes: number;
};

type GetDocumentCategoryGroupsResultQuery = {
  includeAllCategory: boolean | null;
  isForUpload: boolean | null;
};

export type GetDocumentCategoryGroupsResultResponse = {
  documentCategories: Array<CategoryResponse>;
  documentCategoryGroups: Array<CategoryGroupResponse>;
};

export type CategoryResponse = {
  documentCategoryId: number;
  description: string;
  InvestorUploadPermitted: boolean;
  categoryType: string | null;
  categoryGroupId: number | null;
  sortOrder: number | null;
};

export type CategoryGroupResponse = {
  categoryGroupId: number | null;
  description: string | null;
};
