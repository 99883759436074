import React from 'react';
import {
  EquityAttributionSelect,
  EquityAttributionSelectOptionValue,
} from './EquityAttributionSelect';
import { EquityAttributionSelectSettings } from './EquityAttributionSelectSettings';
import { GetEquityAttributionDropdownOptionsResponse } from './GetEquityAttributionDropdownOptionsResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  EquityAttributionSelectOptionValue,
  EquityAttributionSelectSettings,
  GetEquityAttributionDropdownOptionsResponse
>;

export const EquityAttributionSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    EquityAttributionSelectOptionValue,
    EquityAttributionSelectSettings,
    GetEquityAttributionDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <EquityAttributionSelect {...metadataSingleSelectProps} />
    )}
  />
);
