// These values should be kept in sync with `TransactionLock.cs`
export const transactionLockCode = {
  Amount: 'A',
  Shares: 'S',
  Units: 'U',
} as const;

export type TransactionLock = keyof typeof transactionLockCode;

export type TransactionLockCode = typeof transactionLockCode[TransactionLock];
