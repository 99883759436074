import React, { useCallback, useEffect } from 'react';
import { Validator } from 'fluentvalidation-ts';
import { TranslateFunction } from '../../../../internationalisation/types/InternationalisationContextValue';
import { Panel } from '../../../../infrastructure/interface/components/Panel';
import { useDevice } from '../../../../infrastructure/hooks/useDevice';
import styled from 'styled-components/macro';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { BankingProductsSelectField } from '../../../metadata/bankingDetails/BankingProductsSelectField';
import { CompanySelectField } from '../../../metadata/company/CompanySelectField';
import { defaultCompanySelectSettings } from '../../../metadata/company/CompanySelectSettings';
import { CurrencySelectField } from '../../../metadata/currencies/CurrencySelectField';
import { defaultCurrencySelectSettings } from '../../../metadata/currencies/CurrencySelectSettings';
import { BankingProductCode } from '../../../metadata/bankingDetails/BankingProduct';
import { InputField } from '../../../../infrastructure/forms/fields/InputField';
import { BankAccountOtherIdentifierInputField } from './BankAccountOtherIdentifierInputField';
import { TextAreaField } from '../../../../infrastructure/forms/fields/TextAreaField';
import { BankInputField } from './BankInputField';
import { notBeNull } from '../../../validation/commonValidation';
import { notBeEmpty } from '../../../validation/stringValidation';
import { useFormikContext } from 'formik';
import { isNullUndefinedOrBlank } from '../../../../helpers/stringHelpers';
import { ClearingCodeTypeAndValueInputField } from './ClearingCodeTypeAndValueInputField';
import { ClearingCodeValidationState } from './ClearingCodeValidationState';
import { CreateEditBankingDetailsFormAction } from './CreateEditBankingDetailsForm';
import { usePrevious } from '../../../../infrastructure/hooks/usePrevious';

type Props = {
  formAction: CreateEditBankingDetailsFormAction;
  localClearingDetailsOnly: boolean;
  onFormLoadError: (error: string) => void;
};

export const CreateEditBankingDetailsFormFields = ({
  formAction,
  localClearingDetailsOnly,
  onFormLoadError,
}: Props) => {
  const { translate } = useInternationalisation();
  const { isDesktop } = useDevice();
  const previousLocalClearingDetailsOnly = usePrevious(localClearingDetailsOnly);
  const formikContext = useFormikContext<CreateEditBankingDetailsFormModel>();

  useEffect(() => {
    if (
      previousLocalClearingDetailsOnly != null &&
      previousLocalClearingDetailsOnly !== localClearingDetailsOnly
    ) {
      formikContext.resetForm();
      if (localClearingDetailsOnly) {
        if (
          formikContext.values[createEditBankingDetailsFormModelField.bankId] ===
            formikContext.initialValues[createEditBankingDetailsFormModelField.bankId] &&
          formikContext.values[createEditBankingDetailsFormModelField.bankName] ===
            formikContext.initialValues[createEditBankingDetailsFormModelField.bankName] &&
          formikContext.values[createEditBankingDetailsFormModelField.bankSwiftCode] ===
            formikContext.initialValues[createEditBankingDetailsFormModelField.bankSwiftCode]
        ) {
          //initial setting of values so don't clear them
          return;
        }

        // Set all bank fields empty when local clearing details only, as these should
        // now only be set from the clearing code.
        formikContext.setFieldValue(createEditBankingDetailsFormModelField.bankId, null);
        formikContext.setFieldValue(createEditBankingDetailsFormModelField.bankName, '');
        formikContext.setFieldValue(createEditBankingDetailsFormModelField.bankSwiftCode, '');
      }
    }
  }, [localClearingDetailsOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFieldLabel = useCallback(
    (key: string) => translate(`pages.bankingDetails.createEdit.labels.${key}`),
    [translate]
  );

  const FieldsWrapper = isDesktop ? Panel : MobileFieldsWrapper;

  return (
    <FieldsWrapper>
      <BankingProductsSelectField
        data-testid={createEditBankingDetailsProductSelectTestId}
        label={getFieldLabel('product')}
        fieldName={createEditBankingDetailsFormModelField.productCode}
        onError={onFormLoadError}
        disabled={formAction === 'Edit'}
      />
      <CompanySelectField
        data-testid={createEditBankingDetailsFundSelectTestId}
        label={getFieldLabel('fund')}
        fieldName={createEditBankingDetailsFormModelField.companyId}
        onError={onFormLoadError}
        settings={{
          ...defaultCompanySelectSettings,
          includeAllInvestedFunds: true,
        }}
        defaultToFirstOption={true}
        disabled={formAction === 'Edit'}
      />
      <CurrencySelectField
        data-testid={createEditBankingDetailsCurrencySelectTestId}
        label={getFieldLabel('currency')}
        fieldName={createEditBankingDetailsFormModelField.currencyId}
        onError={onFormLoadError}
        settings={defaultCurrencySelectSettings}
        disabled={formAction === 'Edit'}
      />
      {!localClearingDetailsOnly && (
        <InputField
          data-testid={createEditBankingDetailsAccountNameInputTestId}
          label={getFieldLabel('accountName')}
          fieldName={createEditBankingDetailsFormModelField.accountName}
        />
      )}
      <BankInputField
        bankNameInputTestId={createEditBankingDetailsBankNameInputTestId}
        swiftCodeInputTestId={createEditBankingDetailsSwiftCodeInputTestId}
        label={getFieldLabel('bankName')}
        bankIdFieldName={createEditBankingDetailsFormModelField.bankId}
        bankNameFieldName={createEditBankingDetailsFormModelField.bankName}
        swiftCodeFieldName={createEditBankingDetailsFormModelField.bankSwiftCode}
        currencyIdFieldName={createEditBankingDetailsFormModelField.currencyId}
        readonly={localClearingDetailsOnly}
      />
      <InputField
        data-testid={createEditBankingDetailsBankAccountInputTestId}
        label={getFieldLabel('bankAccountNumber')}
        fieldName={createEditBankingDetailsFormModelField.bankAccountNumber}
      />
      <ClearingCodeTypeAndValueInputField
        codeFieldName={createEditBankingDetailsFormModelField.clearingCode}
        localClearingDetailsOnly={localClearingDetailsOnly}
        onLoadError={onFormLoadError}
        codeTypeSelectTestId={createEditBankingDetailsClearingCodeTypeSelectTestId}
        codeInputTestId={createEditBankingDetailsClearingCodeInputTestId}
      />
      {!localClearingDetailsOnly && (
        <InputField
          data-testid={createEditBankingDetailsIbanInputTestId}
          label={getFieldLabel('iban')}
          fieldName={createEditBankingDetailsFormModelField.iban}
        />
      )}
      <BankAccountOtherIdentifierInputField
        data-testid={createEditBankingDetailsOtherIdentifierInputTestId}
        fieldName={createEditBankingDetailsFormModelField.otherIdentifier}
        currencyId={formikContext.values.currencyId}
        currencyIdFieldName={createEditBankingDetailsFormModelField.currencyId}
        onError={onFormLoadError}
      />
      {!localClearingDetailsOnly && (
        <TextAreaField
          data-testid={createEditBankingDetailsBeneficiaryAddressInputTestId}
          label={getFieldLabel('beneficiaryAddress')}
          fieldName={createEditBankingDetailsFormModelField.beneficiaryAddress}
        />
      )}
      <InputField
        data-testid={createEditBankingDetailsPaymentRefInputTestId}
        label={getFieldLabel('paymentReference')}
        fieldName={createEditBankingDetailsFormModelField.paymentReference}
      />
      {!localClearingDetailsOnly && (
        <BankInputField
          bankNameInputTestId={createEditBankingDetailsIntermediaryBankNameInputTestId}
          swiftCodeInputTestId={createEditBankingDetailsIntermediarySwiftCodeInputTestId}
          label={getFieldLabel('intermediaryBankName')}
          bankIdFieldName={createEditBankingDetailsFormModelField.intermediaryBankId}
          bankNameFieldName={createEditBankingDetailsFormModelField.intermediaryBankName}
          swiftCodeFieldName={createEditBankingDetailsFormModelField.intermediaryBankSwiftCode}
          currencyIdFieldName={createEditBankingDetailsFormModelField.currencyId}
        />
      )}
    </FieldsWrapper>
  );
};

const MobileFieldsWrapper = styled.div``;

export type CreateEditBankingDetailsFormModel = {
  productCode: BankingProductCode | null;
  companyId: number | null;
  currencyId: number | null;
  accountName: string;
  bankId: number | null;
  bankName: string;
  bankSwiftCode: string;
  bankAccountNumber: string;
  clearingCodeTypeId: number | null;
  clearingCode: string;
  clearingCodeValidationState: ClearingCodeValidationState;
  iban: string;
  otherIdentifier: string;
  beneficiaryAddress: string;
  paymentReference: string;
  intermediaryBankId: number | null;
  intermediaryBankName: string;
  intermediaryBankSwiftCode: string;
};

export const createEditBankingDetailsFormModelField: Record<
  keyof CreateEditBankingDetailsFormModel,
  keyof CreateEditBankingDetailsFormModel
> = {
  productCode: 'productCode',
  companyId: 'companyId',
  currencyId: 'currencyId',
  accountName: 'accountName',
  bankId: 'bankId',
  bankName: 'bankName',
  bankSwiftCode: 'bankSwiftCode',
  bankAccountNumber: 'bankAccountNumber',
  clearingCodeTypeId: 'clearingCodeTypeId',
  clearingCode: 'clearingCode',
  clearingCodeValidationState: 'clearingCodeValidationState',
  iban: 'iban',
  otherIdentifier: 'otherIdentifier',
  beneficiaryAddress: 'beneficiaryAddress',
  paymentReference: 'paymentReference',
  intermediaryBankId: 'intermediaryBankId',
  intermediaryBankName: 'intermediaryBankName',
  intermediaryBankSwiftCode: 'intermediaryBankSwiftCode',
};

export class CreateEditBankingDetailsValidator extends Validator<CreateEditBankingDetailsFormModel> {
  constructor(translate: TranslateFunction, localClearingDetailsOnly: boolean) {
    super();

    this.ruleFor('productCode').must(notBeNull(translate));

    this.ruleFor('companyId').must(notBeNull(translate));

    this.ruleFor('currencyId').must(notBeNull(translate));

    this.ruleFor('accountName')
      .must(notBeEmpty(translate))
      .when(() => !localClearingDetailsOnly);

    this.ruleFor('bankName')
      .must((bankName) => !isNullUndefinedOrBlank(bankName))
      .withMessage(
        localClearingDetailsOnly
          ? translate('pages.bankingDetails.createEdit.validation.bankEmptyLocal')
          : translate('validation.requiredField')
      );

    this.ruleFor('bankAccountNumber').must(notBeEmpty(translate));

    this.ruleFor('clearingCodeTypeId')
      .must(notBeNull(translate))
      .when(() => localClearingDetailsOnly);

    this.ruleFor('clearingCodeTypeId')
      .notNull()
      .withMessage(translate('pages.bankingDetails.createEdit.validation.clearingCodeTypeIdNull'))
      .when(
        (formModel) => !localClearingDetailsOnly && !isNullUndefinedOrBlank(formModel.clearingCode)
      );

    this.ruleFor('clearingCode')
      .must(notBeEmpty(translate))
      .when(() => localClearingDetailsOnly);

    this.ruleFor('clearingCode')
      .notEmpty()
      .withMessage(translate('pages.bankingDetails.createEdit.validation.clearingCodeEmpty'))
      .when((formModel) => !localClearingDetailsOnly && formModel.clearingCodeTypeId != null);

    this.ruleFor('clearingCode')
      .must((clearingCode) => isNullUndefinedOrBlank(clearingCode))
      .withMessage(translate('pages.bankingDetails.createEdit.validation.checkCodeRequired'))
      .when(
        (formModel) =>
          localClearingDetailsOnly && formModel.clearingCodeValidationState === 'checkCodeRequired'
      );

    this.ruleFor('clearingCode')
      .must((clearingCode) => isNullUndefinedOrBlank(clearingCode))
      .withMessage(
        translate('pages.bankingDetails.createEdit.validation.clearingCodeInvalid.local')
      )
      .when(
        (formModel) =>
          localClearingDetailsOnly && formModel.clearingCodeValidationState === 'invalid'
      );
  }
}

export const createEditBankingDetailsProductSelectTestId =
  'create-edit-banking-details-product-select';
export const createEditBankingDetailsFundSelectTestId = 'create-edit-banking-details-fund-select';
export const createEditBankingDetailsCurrencySelectTestId =
  'create-edit-banking-details-currency-select';
export const createEditBankingDetailsAccountNameInputTestId =
  'create-edit-banking-details-account-name-input';
export const createEditBankingDetailsBankNameInputTestId =
  'create-edit-banking-details-bank-name-input';
export const createEditBankingDetailsSwiftCodeInputTestId =
  'create-edit-banking-details-swift-code-input';
export const createEditBankingDetailsBankAccountInputTestId =
  'create-edit-banking-details-bank-account-input';
export const createEditBankingDetailsClearingCodeTypeSelectTestId =
  'create-edit-banking-details-clearing-code-type-select';
export const createEditBankingDetailsClearingCodeInputTestId =
  'create-edit-banking-details-clearing-code-input';
export const createEditBankingDetailsIbanInputTestId = 'create-edit-banking-details-iban-input';
export const createEditBankingDetailsOtherIdentifierInputTestId =
  'create-edit-banking-details-other-identifier-input';
export const createEditBankingDetailsBeneficiaryAddressInputTestId =
  'create-edit-banking-details-beneficiary-address-input';
export const createEditBankingDetailsPaymentRefInputTestId =
  'create-edit-banking-details-payment-ref-input';
export const createEditBankingDetailsIntermediaryBankNameInputTestId =
  'create-edit-banking-details-intermediary-bank-name-input';
export const createEditBankingDetailsIntermediarySwiftCodeInputTestId =
  'create-edit-banking-details-intermediary-swift-code-input';
