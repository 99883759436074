import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as SearchPlaceholderImage } from '../../../images/search-placeholder.svg';
import { spacing32, spacing64 } from '../../../styling/design/spacing';

type Props = {
  message: string;
};

export const SearchPlaceholder = ({ message }: Props) => {
  return (
    <Container>
      <SearchPlaceholderImage />
      <Message>{message}</Message>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  margin-top: ${spacing64};
`;

const Message = styled.div`
  margin-top: ${spacing32};
`;
