import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  GetCounterpartDropdownOptionsResponse,
  mapCounterpartDropdownOptionsResponseToSelectOptions,
} from './GetCounterpartDropdownOptionsResponse';
import { CounterpartSelectSettings } from './CounterpartSelectSettings';
import { MetadataSingleSelectComponentProps } from '../../metadata/MetadataSelectProps';
import { MetadataSingleSelect } from '../../metadata/MetadataSingleSelect';

export type CounterpartSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  CounterpartSelectOptionValue,
  CounterpartSelectSettings,
  GetCounterpartDropdownOptionsResponse
>;

export const CounterpartSelect = ({ settings, ...metadataSingleSelectProps }: Props) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.counterpartSelect.placeholder');
  const loadingPlaceholder = translate('metadata.counterpartSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      CounterpartSelectOptionValue,
      CounterpartSelectSettings,
      GetCounterpartDropdownOptionsQuery,
      GetCounterpartDropdownOptionsResponse
    >
      endpointUrl="/api/users/GetCounterpartDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapCounterpartDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={300}
      {...metadataSingleSelectProps}
    />
  );
};

type GetCounterpartDropdownOptionsQuery = {
  role: string;
  includeBlank: boolean;
};
