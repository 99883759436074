import React from 'react';
import { ShareholderSelectSettings } from './ShareholderSelectSettings';
import { ShareholderSelect, ShareholderSelectOptionValue } from './ShareholderSelect';
import { GetShareholderDropdownOptionsResponse } from './GetShareholderDropdownOptionsResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  ShareholderSelectOptionValue,
  ShareholderSelectSettings,
  GetShareholderDropdownOptionsResponse
>;

export const ShareholderSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    ShareholderSelectOptionValue,
    ShareholderSelectSettings,
    GetShareholderDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <ShareholderSelect {...metadataSingleSelectProps} />
    )}
  />
);
