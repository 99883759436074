import React from 'react';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { ButtonRow } from '../../interface/buttons/ButtonRow';
import { SecondaryButton } from '../../interface/buttons/SecondaryButton';
import { SubmitButton } from './SubmitButton';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { ButtonColumn } from '../../interface/buttons/ButtonColumn';
import { useFormikContext } from 'formik';

type DesktopOptions = {
  rightAligned?: boolean;
  withMarginTop?: boolean;
};

type MobileOptions = {
  withMarginTop?: boolean;
};

type Props = {
  onCancel: () => void;
  cancelButtonText: string;
  cancelDisabled?: boolean;
  submitButtonText: string;
  submitButtonSubmittingText: string;
  submitDisabled?: boolean;
  desktopOptions?: DesktopOptions;
  mobileOptions?: MobileOptions;
};

export const FormActionButtons = ({
  onCancel,
  cancelButtonText,
  cancelDisabled,
  submitButtonText,
  submitButtonSubmittingText,
  submitDisabled,
  desktopOptions,
  mobileOptions,
}: Props) => {
  const formikContext = useFormikContext<unknown>();

  return (
    <>
      <DesktopOnly>
        <ButtonRow
          rightAligned={desktopOptions?.rightAligned ?? true}
          withMarginTop={desktopOptions?.withMarginTop ?? true}
        >
          <SecondaryButton
            onClick={onCancel}
            disabled={cancelDisabled || formikContext.isSubmitting}
          >
            {cancelButtonText}
          </SecondaryButton>
          <SubmitButton submittingText={submitButtonSubmittingText} disabled={submitDisabled}>
            {submitButtonText}
          </SubmitButton>
        </ButtonRow>
      </DesktopOnly>
      <MobileOnly>
        <ButtonColumn withMarginTop={mobileOptions?.withMarginTop ?? true}>
          <SubmitButton
            stretch={true}
            submittingText={submitButtonSubmittingText}
            disabled={submitDisabled}
          >
            {submitButtonText}
          </SubmitButton>
          <SecondaryButton
            stretch={true}
            onClick={onCancel}
            disabled={cancelDisabled || formikContext.isSubmitting}
          >
            {cancelButtonText}
          </SecondaryButton>
        </ButtonColumn>
      </MobileOnly>
    </>
  );
};
