import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { colourWhite } from '../design/colours';
import { spacing16, spacing24, spacing32, spacing48 } from '../design/spacing';
import { onDesktop, onMobile } from './screenBreakpoints';
import { shadow1 } from '../design/shadows';
import { DesktopOnly } from './DesktopOnly';
import { MobileOnly } from './MobileOnly';
import { LanguageSelect } from '../../internationalisation/LanguageSelect';
import { GoogleAnalyticsProvider } from './GoogleAnalyticsProvider';
import { useBrandingResourceUrl } from '../branding/useBrandingResourceUrl';
import { ExternalLink } from '../../infrastructure/interface/components/ExternalLink';
import { SiteDisclaimers } from './SiteDisclaimers';

type Props = {
  children: React.ReactNode;
};

export const UnauthenticatedLayout = ({ children }: Props) => {
  const loginImageResourceUrl = useBrandingResourceUrl('/api/branding/GetLoginImage');
  const [imagePaneCss] = useState<React.CSSProperties>({
    backgroundImage: `url('${loginImageResourceUrl}')`,
  });

  const languageSelect = LanguageSelect();

  return (
    <>
      <GoogleAnalyticsProvider></GoogleAnalyticsProvider>
      <DesktopOnly>
        <Panes>
          <FormPane>
            <PaneContent>{children}</PaneContent>
            <Footer>
              <LanguageSelectContainer>{languageSelect}</LanguageSelectContainer>
              <SiteDisclaimers />
            </Footer>
          </FormPane>
          <ImagePane style={imagePaneCss} />
        </Panes>
      </DesktopOnly>
      <MobileOnly>
        <Panes>
          <ImagePane style={imagePaneCss}>
            <PaneContent>
              {children}
              <Footer>
                {languageSelect.props.hasOwnProperty('children') ? (
                  <LanguageSelectContainer>{languageSelect}</LanguageSelectContainer>
                ) : (
                  <></>
                )}
                <SiteDisclaimers />
              </Footer>
            </PaneContent>
          </ImagePane>
        </Panes>
      </MobileOnly>
    </>
  );
};

const Panes = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  align-items: stretch;
  position: relative;
`;

const Pane = styled.div`
  flex: 1;
`;

const FormPane = styled(Pane)`
  background: ${colourWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > *:first-child {
    margin-top: auto;
  }
`;

const PaneContent = styled.div`
  max-width: 85vw;

  ${onDesktop(css`
    width: 300px;
    padding-bottom: ${spacing48};
  `)};

  ${onMobile(css`
    width: 350px;
    background-color: ${colourWhite};
    border-radius: 10px;
    box-shadow: ${shadow1};
    padding: ${spacing32} ${spacing16};
  `)};
`;

const ImagePane = styled.div`
  flex: 1;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: bottom;
  border-left: 1px solid #ddd;
`;

const LanguageSelectContainer = styled.div`
  padding: 5px;
  border-radius: 100px;
  background-color: ${colourWhite};
  box-shadow: ${shadow1};

  ${onMobile(css`
    bottom: ${spacing24};
    left: ${spacing32};
  `)};
`;

const Footer = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: ${spacing32};
  padding-left: ${spacing48};
  gap: 1rem;

  ${onMobile(css`
    flex-direction: column;
    padding: 0;
    padding-top: 16px;
  `)}
`;

const Footerlink = styled(ExternalLink)`
  white-space: nowrap;
`;
