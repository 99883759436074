import React, { useContext } from 'react';
import {
  CategoryResponse,
  DocumentResponse,
  documentsPageCategorySelectTestId,
  documentsPageCompanySelectTestId,
  documentsPageInvestorSelectTestId,
  documentsPageRunDateDatePickerTestId,
  documentsPageSearchInputTestId,
} from './Documents';
import { FieldLabel } from '../../infrastructure/forms/common/FieldLabel';
import { IfUserHasRole } from '../authentication/UserRoles';
import { without } from 'lodash';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { CompanySelect } from '../metadata/company/CompanySelect';
import { ResetFiltersButton } from '../filters/ResetFiltersButton';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import styled from 'styled-components/macro';
import { spacing16, spacing24, spacing64 } from '../../styling/design/spacing';
import { Input } from '../../infrastructure/interface/forms/Input';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { DocumentsPageContext } from './DocumentsPageContext';
import { LoadingOverlay } from '../../infrastructure/interface/components/LoadingOverlay';
import { MobilePaginationControls } from '../../infrastructure/interface/components/MobilePaginationControls';
import { SearchPlaceholder } from '../../infrastructure/interface/components/SearchPlaceholder';
import { DocumentLink } from './DocumentLink';
import { DocumentDeleteButton } from './DocumentDeleteButton';
import { MobileFilterSet } from '../filters/MobileFilterSet';
import {
  ItemDetailsCard,
  MobileNoResultsCard,
} from '../../infrastructure/interface/components/ItemDetailsCard';
import { FieldAndValue } from '../../infrastructure/interface/components/FieldAndValue';
import { DatePicker } from '../../infrastructure/interface/forms/DatePicker';
import { RequestFailedAlert } from '../../infrastructure/api/RequestFailedAlert';
import { InvestorSelectWithSearchModal } from '../metadata/investor/InvestorSelectWithSearchModal';
import { InvestorSelect } from '../metadata/investor/InvestorSelect';
import { defaultCompanySelectSettings } from '../metadata/company/CompanySelectSettings';
import { SingleSelect } from '../../infrastructure/interface/forms/SingleSelect';

export const MobileDocumentsList = () => {
  const { translate } = useInternationalisation();

  const {
    latestResponse,
    inProgress,
    error,
    inputBoundSearchString,
    onChangeSearchInput,
    pageNumber,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    loadData,
  } = useContext(DocumentsPageContext);

  return (
    <>
      <Filters />

      {error != null && (
        <RequestFailedAlert
          error={error}
          retry={loadData}
          withMarginTop={true}
          withMarginBottom={true}
        />
      )}

      {latestResponse == null ? (
        inProgress ? (
          <SpinnerContainer>
            <CentredSpinner size="xlarge" />
          </SpinnerContainer>
        ) : (
          <SearchPlaceholder message={translate('pages.documents.placeholderMessage')} />
        )
      ) : (
        <>
          <SearchInput
            onChange={onChangeSearchInput}
            value={inputBoundSearchString}
            placeholder={translate('pages.documents.filters.searchString')}
            data-testid={documentsPageSearchInputTestId}
          />

          <LoadingOverlay showOverlay={inProgress}>
            {latestResponse.documents.map((document) => (
              <DocumentCard key={document.documentId} document={document} />
            ))}
            {latestResponse.documents.length === 0 && <MobileNoResultsCard />}
          </LoadingOverlay>

          <MobilePaginationControls
            resultsPerPage={resultsPerPage}
            totalResultsCount={latestResponse?.totalResults || 0}
            onChangeResultsPerPage={setResultsPerPage}
            currentPageNumber={pageNumber}
            onChangeCurrentPageNumber={setPageNumber}
          />
        </>
      )}
    </>
  );
};

const Filters = () => {
  const { translate } = useInternationalisation();

  const {
    setDocumentCategoryId,
    categoryResponse,
    setCategoryResponse,
    investorId,
    setInvestorId,
    companyId,
    setCompanyId,
    runDate,
    setRunDate,
    resetFilters,
    setFilterLoadError,
    documentCategoryGroupsOptions,
  } = useContext(DocumentsPageContext);

  const onDocumentCategoryChange = (newCategory: CategoryResponse | null) => {
    if (newCategory != null) {
      setDocumentCategoryId(newCategory.documentCategoryId);
      setCategoryResponse(newCategory);
    }
  };

  const DocumentCategoryGroupSelect = styled(SingleSelect)`
    margin-right: ${spacing16};
    margin-bottom: ${spacing16};
    min-width: 350px;
  ` as typeof SingleSelect;

  return (
    <MobileFilterSet>
      <div>
        <FieldLabel>{translate('pages.documents.filters.documentCategory')}</FieldLabel>
        <DocumentCategoryGroupSelect
          options={documentCategoryGroupsOptions}
          onChange={onDocumentCategoryChange}
          value={categoryResponse}
          placeholder={translate('pages.documents.documentCategoryGroupsSelectPlaceholder')}
          data-testid={documentsPageCategorySelectTestId}
        />
      </div>
      <IfUserHasRole userRole={without(allNonAdminUserRoles, 'Investor')}>
        <div>
          <FieldLabel>{translate('pages.documents.filters.investor')}</FieldLabel>
          <IfUserHasRole userRole={['Manager', 'Advisor']}>
            <InvestorSelectWithSearchModal
              settings={{ forTransacting: false }}
              value={investorId}
              onChange={setInvestorId}
              data-testid={documentsPageInvestorSelectTestId}
              onError={setFilterLoadError}
              defaultToFirstOption={true}
            />
          </IfUserHasRole>
          <IfUserHasRole userRole="Consolidated Investor">
            <InvestorSelect
              settings={{ forTransacting: false }}
              value={investorId}
              onChange={setInvestorId}
              data-testid={documentsPageInvestorSelectTestId}
              onError={setFilterLoadError}
              defaultToFirstOption={true}
            />
          </IfUserHasRole>
        </div>
      </IfUserHasRole>
      <div>
        <FieldLabel>{translate('pages.documents.filters.company')}</FieldLabel>
        <CompanySelect
          value={companyId}
          onChange={setCompanyId}
          data-testid={documentsPageCompanySelectTestId}
          onError={setFilterLoadError}
          settings={{ ...defaultCompanySelectSettings, includeBlank: true, includeAllFunds: true }}
          defaultToFirstOption={true}
        />
      </div>
      <div>
        <FieldLabel>{translate('pages.documents.filters.runDate')}</FieldLabel>
        <DatePicker
          data-testid={documentsPageRunDateDatePickerTestId}
          value={runDate}
          onChange={setRunDate}
        />
      </div>
      <ResetFiltersButton resetFilters={resetFilters} />
    </MobileFilterSet>
  );
};

type DocumentCardProps = {
  document: DocumentResponse;
};

const DocumentCard = ({ document }: DocumentCardProps) => {
  const { formatDate } = useInternationalisation();
  const { loadData } = useContext(DocumentsPageContext);
  const { translate } = useInternationalisation();

  return (
    <ItemDetailsCard subtitle={formatDate(document.dateProduced)}>
      <div title={document.fileName}>
        {document.isDownloadBlocked ? (
          <>{document.description}</>
        ) : (
          <DocumentLink document={document} />
        )}
      </div>
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.documents.table.headings.fund')}
        value={document.fund}
      />
      <FieldAndValue
        type="date"
        fieldLabel={translate('pages.documents.table.headings.valueDate')}
        value={document.valueDate}
      />
      {document.canDelete && (
        <DeleteButtonContainer>
          <DocumentDeleteButton
            documentId={document.documentId}
            onDocumentDeleted={loadData}
            size="small"
          />
        </DeleteButtonContainer>
      )}
    </ItemDetailsCard>
  );
};

const SpinnerContainer = styled.div`
  margin-top: ${spacing64};
`;

const SearchInput = styled(Input)`
  width: 100%;
  margin: ${spacing16} 0;
`;

const DeleteButtonContainer = styled.div`
  margin-top: ${spacing24};
  display: flex;
  justify-content: center;
`;
