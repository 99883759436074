import React, { useContext } from 'react';
import { isArray } from 'lodash';
import { UserRole } from './UserRole';
import { AuthenticationContext } from './AuthenticationContext';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { UserDetails } from './UserDetails';

type HasUserRoleProps = {
  userRole: UserRole | Array<UserRole>;
  children: (hasUserRole: boolean) => React.ReactNode;
};

export const HasUserRole = ({ userRole, children }: HasUserRoleProps) => {
  const authenticationContext = useContext(AuthenticationContext);
  const hasUserRole = userHasRole(authenticationContext.getUser(), userRole);
  return <>{children(hasUserRole)}</>;
};

type UserRoleProps = {
  userRole: UserRole | Array<UserRole>;
  children: React.ReactNode;
};

export const IfUserHasRole = ({ userRole, children }: UserRoleProps) => (
  <HasUserRole userRole={userRole}>{(hasUserRole) => (hasUserRole ? children : null)}</HasUserRole>
);

export const RequiresUserRole = ({ userRole, children }: UserRoleProps) => (
  <HasUserRole userRole={userRole}>
    {(hasUserRole) =>
      hasUserRole ? (
        children
      ) : (
        <PaddedPage>
          <InvalidUserRoleError />
        </PaddedPage>
      )
    }
  </HasUserRole>
);

const InvalidUserRoleError = () => {
  const { translate } = useInternationalisation();
  return (
    <Alert alertType="negative" header={translate('errors.generic')}>
      {translate('errors.authentication.invalidUserRole')}
    </Alert>
  );
};

export const userHasRole = (user: UserDetails, userRole: UserRole | Array<UserRole>) => {
  const userRoles = isArray(userRole) ? userRole : [userRole];
  return userRoles.includes(user.role);
};
