import React, { useEffect } from 'react';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { BarChart } from '../../../infrastructure/charts/BarChart';
import { ChartDataPoint } from '../../../infrastructure/charts/Chart';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { useDevice } from '../../../infrastructure/hooks/useDevice';
import { useDashboardFilters } from '../DashboardFiltersContext';
import { ComponentResponse } from '../DashboardComponent';

export const TopTenPositionsChartComponentName = 'Top 10 Positions Graph';

export const TopTenPositionsChart = () => {
  const endpointUrl = 'api/dashboards/GetDataForTopTenPositionsChart';
  const getRequest = useGetJson<
    GetDataForTopTenPositionsChartQuery,
    GetDataForTopTenPositionsChartResponse
  >(endpointUrl);

  const { companyId, fromDate } = useDashboardFilters();

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
        },
      });
    }
  };

  useEffect(makeRequest, [companyId, fromDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <TopTenPositionsChartComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForTopTenPositionsChartResponse;
  showLoadingOverlay: boolean;
};

const TopTenPositionsChartComponent = ({ response, showLoadingOverlay }: ComponentProps) => {
  const { translate, formatNumber } = useInternationalisation();
  const { isMobile } = useDevice();

  const header = translate('pages.dashboard.components.topTenPositionsTitle', {
    currency: response.localCurrency,
  });

  const chartData: Array<ChartDataPoint> = response.positions.map((position) => ({
    label: position.positionName || '',
    value: position.marketValue,
  }));

  return (
    <DashboardComponentLayout
      headerText={header}
      showLoadingOverlay={showLoadingOverlay}
      showNoDataMessage={response.noDataMessage}
    >
      <BarChart
        dataPoints={chartData}
        exportFilename="Top10Positions"
        onClickDataPoint={({ label, value }) => {
          // TODO : PC-105 - Open a modal to drill down into the position
          console.log(`Clicked position ${label} with value ${value}`);
        }}
        height="stretch"
        responsiveOptions={{
          condition: {
            callback: () => isMobile,
          },
        }}
        tooltipOptions={{
          pointFormatter() {
            return this.y === undefined ? '' : `<span>${formatNumber(this.y)}</span>`;
          },
        }}
      />
    </DashboardComponentLayout>
  );
};

type GetDataForTopTenPositionsChartQuery = {
  companyId: number;
  runDate: string;
};

export type GetDataForTopTenPositionsChartResponse = ComponentResponse & {
  positions: Array<{
    positionId: number | null;
    positionName: string | null;
    marketValue: number;
  }>;
  localCurrency: string;
};
