import React from 'react';
import { DealingDateSelect, DealingDateSelectOptionValue } from './DealingDateSelect';
import { DealingDateSelectSettings } from './DealingDateSelectSettings';
import { GetTransactionDealingDateDropdownOptionsResponse } from './GetTransactionDealingDateDropdownOptionsResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  DealingDateSelectOptionValue,
  DealingDateSelectSettings,
  GetTransactionDealingDateDropdownOptionsResponse
>;

export const DealingDateSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    DealingDateSelectOptionValue,
    DealingDateSelectSettings,
    GetTransactionDealingDateDropdownOptionsResponse
  >
    {...props}
    renderSelect={({ ...metadataSingleSelectProps }) => (
      <DealingDateSelect {...metadataSingleSelectProps} />
    )}
  />
);
