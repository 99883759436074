import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { FieldLabel } from '../../infrastructure/forms/common/FieldLabel';
import { DatePicker } from '../../infrastructure/interface/forms/DatePicker';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { spacing16 } from '../../styling/design/spacing';
import { IfUserHasRole } from '../authentication/UserRoles';
import { CompanySelect } from '../metadata/company/CompanySelect';
import { GetCompanyDropdownOptionsResponse } from '../metadata/company/GetCompanyDropdownOptionsResponse';
import { InvestorSelect } from '../metadata/investor/InvestorSelect';
import { DashboardResponse } from './GetDashboardsForCurrentUserResponse';
import { DashboardSelect } from './DashboardSelect';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { MobileFilterSet } from '../filters/MobileFilterSet';
import { GetInvestorDropdownOptionsResponse } from '../metadata/investor/GetInvestorDropdownOptionsResponse';
import { useDashboardFilters } from './DashboardFiltersContext';
import { defaultCompanySelectSettings } from '../metadata/company/CompanySelectSettings';
import { EmployeeSelect } from '../metadata/employees/EmployeeSelect';
import { holdingsPageEmployeeSelectTestId } from '../holdings/Holdings';

type Props = {
  dashboardOptions: Array<DashboardResponse>;
};

export const DashboardFilters = (props: Props) => {
  return (
    <>
      <DesktopOnly>
        <DesktopOuterFieldsContainer>
          <DesktopInnerFiltersContainer>
            <FilterFields {...props} />
          </DesktopInnerFiltersContainer>
        </DesktopOuterFieldsContainer>
      </DesktopOnly>
      <MobileOnly>
        <MobileFilterSet>
          <FilterFields {...props} />
        </MobileFilterSet>
      </MobileOnly>
    </>
  );
};

const FilterFields = (props: Props) => {
  const { translate } = useInternationalisation();

  const {
    employeeId,
    setEmployeeId,
    investorId,
    setInvestorId,
    companyId,
    handleCompanyChanged,
    maxDate,
    fromDate,
    setFromDate,
    dashboardId,
    setDashboardId,
    setFilterLoadError,
  } = useDashboardFilters();

  const onInvestorSelectLoaded = (response: GetInvestorDropdownOptionsResponse) => {
    setInvestorId(response.options[0]?.counterpartId);
  };

  return (
    <>
      <IfUserHasRole userRole="Advisor">
        <EmployeeSelect
          fieldLabel={translate('pages.holdings.filters.advisor')}
          value={employeeId}
          onChange={setEmployeeId}
          onError={setFilterLoadError}
          clearable={true}
          wrapInDiv={true}
        />
      </IfUserHasRole>
      <IfUserHasRole userRole={['Advisor', 'Consolidated Investor']}>
        <div>
          <FieldLabel>{translate('pages.dashboard.filterLabels.investor')}</FieldLabel>
          <InvestorSelect
            settings={{ forTransacting: false, employeeId: employeeId }}
            value={investorId}
            onChange={setInvestorId}
            onError={setFilterLoadError}
            onLoaded={onInvestorSelectLoaded}
            data-testid="dashboard-investor-select"
          />
        </div>
      </IfUserHasRole>
      <div>
        <FieldLabel>{translate('pages.dashboard.filterLabels.fund')}</FieldLabel>
        <CompanySelect
          value={companyId}
          onChange={handleCompanyChanged}
          onError={setFilterLoadError}
          settings={{...defaultCompanySelectSettings, employeeCounterpartId:employeeId }}
          defaultToFirstOption={true}
        />
      </div>
      <div>
        <FieldLabel>{translate('pages.dashboard.filterLabels.fromDate')}</FieldLabel>
        <DatePicker value={fromDate} onChange={setFromDate} maxDate={maxDate as string} />
      </div>
      <div>
        <FieldLabel>{translate('pages.dashboard.filterLabels.dashboard')}</FieldLabel>
        <DashboardSelect
          dashboards={props.dashboardOptions}
          selectedDashboardId={dashboardId}
          onSelectDashboard={setDashboardId}
        />
      </div>
    </>
  );
};

// This CSS makes equal-width columns, of width equal to widest content of any of the columns
const DesktopOuterFieldsContainer = styled.div`
  display: flex;
`;

const DesktopInnerFiltersContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${spacing16};
`;
