import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { every, some, join } from 'lodash';
import { getHumanReadableFileSize } from '../../helpers/fileHelpers';

type FileValidationRule = {
  predicate: (files: Array<File>) => boolean;
  message: string;
};

export const haveAllowedFileExtension = (
  translate: TranslateFunction,
  allowedFileExtensions: Array<string>
): FileValidationRule => ({
  predicate: (files) =>
    every(files, (file) =>
      some(allowedFileExtensions, (extension) =>
        file.name.toLowerCase().endsWith(extension.toLowerCase())
      )
    ),
  message: translate('validation.files.invalidExtension', {
    validFileExtensions: join(allowedFileExtensions, ' '),
  }),
});

export const notExceedSizeInBytes = (
  translate: TranslateFunction,
  maximumAllowedFileSizeInBytes: number
): FileValidationRule => ({
  predicate: (files) => every(files, (file) => file.size <= maximumAllowedFileSizeInBytes),
  message: translate('validation.files.tooLarge', {
    maximumFileSize: getHumanReadableFileSize(maximumAllowedFileSizeInBytes),
  }),
});
