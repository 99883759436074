import React from 'react';
import styled, { css } from 'styled-components/macro';
import { FieldValidity } from '../common/FieldValidity';
import { borderColours } from '../../../styling/design/colours';
import { FormField } from '../common/FormField';
import { NumberInput, NumberInputType } from '../../interface/forms/NumberInput';

export type NumberInputFieldProps = {
  fieldName: string;
  label?: string;
  onFieldValueChange?: (newValue: NumberInputType) => void;
  helpText?: string;
  step?: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
} & (
  | { decimalPlaces?: number; currencyCode?: undefined }
  | { decimalPlaces?: undefined; currencyCode?: string }
);

export const NumberInputField = (props: NumberInputFieldProps) => {
  const { fieldName, label, onFieldValueChange, helpText, disabled, className, ...rest } = props;

  return (
    <FormField<NumberInputType>
      className={className}
      fieldName={fieldName}
      label={label}
      helpText={helpText}
      onFieldValueChange={onFieldValueChange}
    >
      {({ field, fieldValidity, formikContext }) => {
        const onChange = (newValue: NumberInputType) => {
          formikContext.setFieldValue(field.name, newValue);
        };

        const onBlur = () => {
          formikContext.setFieldTouched(field.name);
        };

        return (
          <FieldValidityNumberInput
            {...rest}
            data-testid={props['data-testid'] ?? numberInputFieldTestId(field.name)}
            value={field.value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled || formikContext.isSubmitting}
            fieldValidity={fieldValidity}
            aria-label={label}
          />
        );
      }}
    </FormField>
  );
};

export const FieldValidityNumberInput = styled(NumberInput)<{
  fieldValidity: FieldValidity;
}>`
  ${(props) =>
    !props.disabled &&
    css`
      * {
        border-color: ${props.fieldValidity === 'Valid'
          ? borderColours.positive
          : props.fieldValidity === 'Invalid'
          ? borderColours.negative
          : borderColours.default} !important;
      }
    `};
`;

export const numberInputFieldTestId = (fieldName: string) => `number-input-field-${fieldName}`;
