import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { RequestFailedAlert } from '../../../infrastructure/api/RequestFailedAlert';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { SecondaryButton } from '../../../infrastructure/interface/buttons/SecondaryButton';
import { WaitingButton } from '../../../infrastructure/interface/buttons/WaitingButton';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { ButtonColumn } from '../../../infrastructure/interface/buttons/ButtonColumn';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

type Props = {
  investorIds: (number | null)[];
  uploadId: number;
  dealNumbers: number[];
};

export const ConfirmUploadTransactionsActionButtons = (props: Props) => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();
  const {
    makeRequest,
    state: { inProgress, error },
  } = usePostJson<ConfirmUploadTransactionsCommand, {}>(
    '/api/transactions/ConfirmUploadTransactions'
  );

  const [confirmationSuccessful, setConfirmationSuccessful] = useState(false);

  const onConfirmClick = () =>
    makeRequest({
      requestBody: {
        investorIds: props.investorIds,
        uploadId: props.uploadId,
        dealNumbers: props.dealNumbers,
      },
      onSuccess: () => setConfirmationSuccessful(true),
    });

  const { makeRequest: deleteRequest } = usePostJson<DeleteBulkTransactionsCommand, {}>(
    '/api/transactions/DeleteBulkTransactions'
  );

  const onCancelClick = () =>
    deleteRequest({
      requestBody: {
        investorIds: props.investorIds,
        uploadId: props.uploadId,
        dealNumbers: props.dealNumbers,
      },
      onSuccess: () => navigate('/transactions/upload'),
      onFailure: () => navigate('/transactions/upload'),
    });

  return (
    <>
      {error && <RequestFailedAlert error={error} withMarginTop={true} />}
      {confirmationSuccessful && (
        <Alert alertType="positive" withMarginTop={true}>
          {translate('pages.confirmTransaction.successMessage', { dealNumber: props.dealNumbers })}
        </Alert>
      )}
      <DesktopOnly>
        <ButtonRow rightAligned={true} withMarginTop={true}>
          <SecondaryButton onClick={onCancelClick} disabled={inProgress || confirmationSuccessful}>
            {translate('actionButtons.cancel')}
          </SecondaryButton>
          <WaitingButton
            waitingText={translate('pages.confirmTransaction.submitButtonWaitingText')}
            isWaiting={inProgress}
            buttonStyle="primary"
            disabled={confirmationSuccessful}
            onClick={onConfirmClick}
          >
            {translate('pages.confirmTransaction.submitButtonText')}
          </WaitingButton>
        </ButtonRow>
      </DesktopOnly>
      <MobileOnly>
        <ButtonColumn withMarginTop={true}>
          <WaitingButton
            waitingText={translate('pages.confirmTransaction.submitButtonWaitingText')}
            isWaiting={inProgress}
            buttonStyle="primary"
            stretch={true}
            onClick={onConfirmClick}
            disabled={confirmationSuccessful}
          >
            {translate('pages.confirmTransaction.submitButtonText')}
          </WaitingButton>
          <SecondaryButton
            onClick={onCancelClick}
            disabled={inProgress || confirmationSuccessful}
            stretch={true}
          >
            {translate('actionButtons.cancel')}
          </SecondaryButton>
        </ButtonColumn>
      </MobileOnly>
    </>
  );
};

type ConfirmUploadTransactionsCommand = {
  uploadId: number;
  dealNumbers: number[];
  investorIds: (number | null)[];
};

type DeleteBulkTransactionsCommand = {
  uploadId: number;
  dealNumbers: number[];
  investorIds: (number | null)[];
};
