import React from 'react';
import { Form as FormikForm } from 'formik';

type FormProps = { children: React.ReactNode; 'data-testid'?: string };

export const Form = (props: FormProps) => {
  return (
    <FormikForm autoComplete="off" data-testid={props['data-testid']}>
      {props.children}
    </FormikForm>
  );
};
