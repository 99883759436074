import { useState } from 'react';
import { FormField } from '../../../infrastructure/forms/common/FormField';
import {
  defaultSingleSelectFieldStyles,
  singleSelectFieldTestId,
} from '../../../infrastructure/forms/fields/SingleSelectField';
import { ActionAlert } from '../../../infrastructure/interface/components/ActionAlert';
import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { SingleSelect } from '../../../infrastructure/interface/forms/SingleSelect';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { roleCodeCharactersByRoleName } from '../../authentication/UserRole';
import { CreateEditDashboardFormModel, DashboardLoginRole } from './CreateEditDashboardForm';

export const DashboardLoginRoleSelectField = () => {
  const { translate } = useInternationalisation();
  const [changeGuardIsOpen, setChangeGuardIsOpen] = useState(false);
  const [pendingNewValue, setPendingNewValue] = useState<DashboardLoginRole | null>(null);

  const options: SelectOptions<DashboardLoginRole> = [
    { value: roleCodeCharactersByRoleName.Manager, label: 'Manager' },
    { value: roleCodeCharactersByRoleName.Advisor, label: 'Advisor' },
    { value: roleCodeCharactersByRoleName.Investor, label: 'Investor' },
  ];

  return (
    <FormField<DashboardLoginRole>
      fieldName={loginRoleFieldName}
      label={translate('pages.createDashboard.fieldLabels.loginRole')}
    >
      {({ field, fieldValidity, formikContext }) => {
        const onChange = (newValue: DashboardLoginRole | null) => {
          if (newValue !== field.value) {
            setChangeGuardIsOpen(true);
            setPendingNewValue(newValue);
          }
        };

        const onConfirmChange = () => {
          formikContext.setFieldValue(loginRoleFieldName, pendingNewValue);
          formikContext.setFieldValue(componentsFieldName, []);
          setChangeGuardIsOpen(false);
        };

        const onBlur = () => {
          formikContext.setFieldTouched(loginRoleFieldName);
        };

        return (
          <>
            <SingleSelect<DashboardLoginRole>
              options={options}
              value={field.value}
              onChange={onChange}
              onBlur={onBlur}
              name={field.name}
              disabled={formikContext.isSubmitting}
              customStyles={defaultSingleSelectFieldStyles<DashboardLoginRole>(fieldValidity)}
              data-testid={singleSelectFieldTestId(loginRoleFieldName)}
            />
            <ActionAlert
              alertType="negative"
              isOpen={changeGuardIsOpen}
              onRequestClose={() => setChangeGuardIsOpen(false)}
              title={translate('pages.createDashboard.confirmLoginRoleChangeAlert.title')}
              message={translate('pages.createDashboard.confirmLoginRoleChangeAlert.message')}
              cancelButtonText={translate('actionButtons.cancel')}
              confirmButtonText={translate(
                'pages.createDashboard.confirmLoginRoleChangeAlert.confirmButtonText'
              )}
              onInitiateAction={onConfirmChange}
              actionInProgress={false}
              actionError={null}
            />
          </>
        );
      }}
    </FormField>
  );
};

const loginRoleFieldName: keyof CreateEditDashboardFormModel = 'loginRole';
const componentsFieldName: keyof CreateEditDashboardFormModel = 'components';
