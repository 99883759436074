import React, { useCallback, useContext, useMemo } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { ReportResponse } from '../../Reports';
import { ReportParameterName } from '../ReportParameterName';
import { useReportParameterFieldsContext } from '../ReportParameterFieldsContext';
import { ReportContext } from '../../ReportContext';
import { DatePickerField } from '../../../../infrastructure/forms/fields/DatePickerField';
import { useGetJson } from '../../../../infrastructure/api/useGetJson';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';

type Props = {
  report: ReportResponse;
  fieldName: string;
  label: string;
  onError: (error: string) => void;
};

export const ReportsCompanyFreeValueDateSelectField = <TValues extends FormikValues>({
  report,
  fieldName,
  label,
  onError,
}: Props) => {
  const { setFieldValue, initialValues: initialFormValues } = useFormikContext<TValues>();
  const { setFieldLoaded } = useReportParameterFieldsContext();
  const { selectedCompanyOption } = useContext(ReportContext);
  const { translate } = useInternationalisation();

  const setFieldValueFromDefaults = useCallback(
    (defaultValueDates: GetCompanyFreeValueDateDefaultDateResponse) => {
      if (fieldName === ReportParameterName.RunDate && defaultValueDates.runDate != null) {
        setFieldValue(fieldName, defaultValueDates.runDate);
      }

      if (fieldName === ReportParameterName.PriorDate && defaultValueDates.priorDate != null) {
        setFieldValue(fieldName, defaultValueDates.priorDate);
      }
    },
    [fieldName, setFieldValue]
  );

  const onValueDatesResponse = (response: GetCompanyFreeValueDateDefaultDateResponse) => {
    if (initialFormValues[fieldName] == null) {
      setFieldValueFromDefaults(response);
    }
    setFieldLoaded(fieldName);
  };

  const companyId = selectedCompanyOption?.companyId ?? null;

  const endpointUrl = '/api/reports/GetReportDefaultValueDate';
  const getRequest = useGetJson<
    GetCompanyFreeValueDateDefaultDateQuery,
    GetCompanyFreeValueDateDefaultDateResponse
  >(endpointUrl);

  const makeRequest = () => {
    if (companyId != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
        },
        onSuccess: onValueDatesResponse,
      });
    }
  };

  const { response, inProgress } = getRequest.state;
  const responseNotLoadedOrInProgress = response == null || inProgress;

  const placeholder = useMemo(
    () =>
      responseNotLoadedOrInProgress
        ? translate('form.defaultLoadingPlaceholder')
        : translate('form.select.defaultPlaceholder'),
    [responseNotLoadedOrInProgress, translate]
  );

  useOnMount(() => {
    makeRequest();
  });

  return <DatePickerField placeholder={placeholder} fieldName={fieldName} label={label} />;
};

type GetCompanyFreeValueDateDefaultDateQuery = {
  companyId: number;
};

type GetCompanyFreeValueDateDefaultDateResponse = {
  runDate: Date | null;
  runDateString: string | null;
  priorDate: Date | null;
  priorDateString: string | null;
};
