import React from 'react';
import { ButtonSize } from './BaseButton';
import styled, { css } from 'styled-components/macro';
import { PrimaryButton } from './PrimaryButton';
import { backgroundColours, borderColours, textColours } from '../../../styling/design/colours';

export type RadioButtonGroupValue = number | string | boolean;

export type RadioButtonGroupOption<TValue extends RadioButtonGroupValue> = {
  value: TValue;
  text: string;
};

type Props<TValue extends RadioButtonGroupValue> = {
  className?: string;
  options: Array<RadioButtonGroupOption<TValue>>;
  value: TValue | null;
  onChange: (newValue: TValue) => void;
  size?: ButtonSize;
  disabled?: boolean;
};

export const RadioButtonGroup = <TValue extends RadioButtonGroupValue>(props: Props<TValue>) => {
  const onChange = (selectedOption: RadioButtonGroupOption<TValue>) => {
    if (selectedOption.value !== props.value) {
      props.onChange(selectedOption.value);
    }
  };

  return (
    <Container className={props.className}>
      {props.options.map((option) => (
        <StyledPrimaryButton
          key={`${option.value}`}
          selected={props.value === option.value}
          onClick={() => onChange(option)}
          size={props.size}
          disabled={props.disabled}
        >
          {option.text}
        </StyledPrimaryButton>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const StyledPrimaryButton = styled(PrimaryButton)<{ selected: boolean }>`
  position: relative;
  border-radius: 0;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  &:not(:first-child) {
    margin-left: -1px; // to prevent a double border
  }

  ${(props) =>
    props.selected
      ? css`
          color: ${textColours.selected};
          background-color: ${backgroundColours.selected};
          border: 1px solid ${borderColours.selected};
          cursor: default;

          &:enabled {
            &:hover {
              color: ${textColours.selected};
              background-color: ${backgroundColours.selected};
            }

            &:focus {
              background-color: ${backgroundColours.selected};
            }
          }
        `
      : css`
          color: ${textColours.default};
          background-color: ${backgroundColours.default};
          border: 1px solid ${borderColours.default};

          &:enabled {
            &:hover,
            &:focus {
              color: ${textColours.default};
              background-color: ${backgroundColours.defaultHover};
              border-color: ${borderColours.defaultHover};
              z-index: 1; // show border from hovered item when borders are overlapping
            }
          }
        `}
`;
