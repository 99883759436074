import React from 'react';
import { PaddedPage } from '../../../styling/layout/PaddedPage';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { DesktopConfirmUploadTransactions } from './DesktopConfirmUploadTransactions';
import { MobileConfirmUploadTransactions } from './MobileConfirmUploadTransactions';
import { assertNotNull } from '../../../helpers/nullHelpers';
import { useUploadIdUrlParam } from '../useUploadIdUrlParam';
import { ApiRequestStateWrapper } from '../../../infrastructure/api/ApiRequestStateWrapper';
import { ConfirmUploadTransactionsActionButtons } from './ConfirmUploadTransactionsActionButtons';
import styled from 'styled-components/macro';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import {
  GetBulkTransactionsDetailsResponse,
  useGetBulkTransactionsDetailsRequest,
} from './useGetBulkTransactionsDetailsRequest';
import { ErrorRow } from './UploadTransactions';
import { spacing32 } from '../../../styling/design/spacing';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';

type WarningProps = {
  warningMessages: ErrorRow[];
};

export const ConfirmUploadTransactions = () => {
  const uploadId = assertNotNull(useUploadIdUrlParam(), 'uploadId');

  const dealNumberSearchParams = new URLSearchParams(window.location.search);
  const dealNumberStrings = dealNumberSearchParams.getAll('dealNumber');
  const dealNumbers = dealNumberStrings.map((dealNumber) => parseInt(dealNumber));

  const getBulkTransactionsDetailsRequest = useGetBulkTransactionsDetailsRequest();

  const makeGetBulkTransactionsDetailsRequest = () =>
    getBulkTransactionsDetailsRequest.makeRequest({
      queryParameters: {
        dealNumbers: assertNotNull(dealNumbers, 'dealNumber'),
        uploadId: assertNotNull(uploadId, 'uploadId'),
      },
    });

  useOnMount(() => {
    makeGetBulkTransactionsDetailsRequest();
  });

  return (
    <ApiRequestStateWrapper
      apiRequestState={getBulkTransactionsDetailsRequest.state}
      retry={makeGetBulkTransactionsDetailsRequest}
    >
      {(response) => (
        <ConfirmUploadTransactionsComponent
          transactionDetails={response}
          uploadId={uploadId}
          dealNumbers={dealNumbers}
        />
      )}
    </ApiRequestStateWrapper>
  );
};

type ConfirmTransactionComponentProps = {
  transactionDetails: GetBulkTransactionsDetailsResponse;
  uploadId: number;
  dealNumbers: number[];
};

export const ConfirmUploadTransactionsComponent = ({
  transactionDetails,
  uploadId,
  dealNumbers,
}: ConfirmTransactionComponentProps) => {
  const { translate } = useInternationalisation();

  const warningMessages =
    transactionDetails?.errors != null
      ? transactionDetails?.errors.filter((errorRow) => errorRow.errorMessage !== '')
      : undefined;

  const WarningMessage = ({ warningMessages }: WarningProps) => {
    if (warningMessages.length > 0)
      return (
        <AlertContainer>
          <Alert
            alertType="warning"
            header={translate('pages.uploadTransactions.warnings.beAware')}
          >
            {warningMessages?.map((errorRow) => (
              <div>
                {translate('pages.uploadTransactions.warnings.lineNumber')} {errorRow.index + 1}:{' '}
                {errorRow.errorMessage}
              </div>
            ))}
          </Alert>
        </AlertContainer>
      );

    return null;
  };

  return (
    <PaddedPage>
      <DesktopOnly>
        <DesktopConfirmUploadTransactions transactionsDetails={transactionDetails} />
      </DesktopOnly>
      <MobileOnly>
        <MobileConfirmUploadTransactions transactionsDetails={transactionDetails} />
      </MobileOnly>
      {warningMessages && <WarningMessage warningMessages={warningMessages} />}
      {transactionDetails.shareRegistryTradingMessageDisclaimer && (
        <Alert alertType="notice" withMarginTop={true} formatHtml={true}>
          {transactionDetails.shareRegistryTradingMessageDisclaimer}
        </Alert>
      )}
      <ButtonAndNoteContainer>
        <ConfirmUploadTransactionsActionButtons
          investorIds={transactionDetails.transactions.map((transaction) => {
            return transaction.investorId;
          })}
          uploadId={uploadId}
          dealNumbers={dealNumbers}
        />
      </ButtonAndNoteContainer>
    </PaddedPage>
  );
};

const ButtonAndNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-left: auto;
`;

const AlertContainer = styled.div`
  margin-top: ${spacing32};
`;
