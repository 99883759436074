import React from 'react';
import { useNavigate } from 'react-router';
import { ChevronLeftIcon } from '../../../icons/icons';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { colourPrimary05 } from '../../../styling/design/colours';
import styled from 'styled-components/macro';
import { ButtonIcon } from '../buttons/ButtonIcon';
import { fontSizeCss, fontWeightBold } from '../../../styling/design/fonts';

type Props = {
  className?: string;
};

export const BackButton = ({ className }: Props) => {
  const navigate = useNavigate();
  const { translate } = useInternationalisation();

  return (
    <Container className={className} onClick={() => navigate(-1)}>
      <ButtonIcon icon={<ChevronLeftIcon />} position="left" size="medium" />
      {translate('navigation.back')}
    </Container>
  );
};

const Container = styled.button`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  color: ${colourPrimary05};
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: ${fontWeightBold};
  border: none;
  padding: 0;
  ${fontSizeCss('medium')}
`;
