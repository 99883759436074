import React from 'react';
import { Highcharts } from './Highcharts';
import { Chart, ChartDataPoint, mapChartDataPointToPointOptions } from './Chart';
import { baseBarChartOptions, BaseBarChartProps } from './BarChart';

type Props = BaseBarChartProps & {
  dataPoints: Array<ChartDataPoint>;
};

export const StackedBarChart = (props: Props) => {
  const chartOptions: Highcharts.Options = {
    ...baseBarChartOptions(props),
    xAxis: {
      type: 'category',
      ...props.xAxisOptions,
    },
    tooltip: {
      shared: false,
      ...props.tooltipOptions,
    },
    series: props.dataPoints.map((dataPoint) => ({
      name: dataPoint.label,
      type: 'column',
      data: [mapChartDataPointToPointOptions(dataPoint, props.onClickDataPoint)],
      linkedTo: ':previous',
    })),
    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
  };

  return <Chart data-testid={props['data-testid'] ?? 'stacked-bar-chart'} options={chartOptions} />;
};
