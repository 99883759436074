import React, { useContext } from 'react';
import { PaginatedTable } from '../../infrastructure/interface/tables/PaginatedTable';
import { TransactionsContext } from './TransactionsContext';
import {
  NoResultsRow,
  ThresholdLimitRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../infrastructure/interface/tables/Table';
import { isEmpty, some } from 'lodash';
import { IfUserHasRole } from '../authentication/UserRoles';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { isNullUndefinedOrBlank } from '../../helpers/stringHelpers';
import { AppLink } from '../../infrastructure/interface/components/AppLink';
import { FileIcon } from '../documents/FileIcon';
import styled from 'styled-components/macro';
import { spacing12, spacing16, spacing32 } from '../../styling/design/spacing';
import { ManagerApproval, TransactionStatus } from './types';
import { FieldLabel } from '../../infrastructure/forms/common/FieldLabel';
import { CompanySelect } from '../metadata/company/CompanySelect';
import { ResetFiltersButton } from '../filters/ResetFiltersButton';
import { EquityAttributionSelect } from '../metadata/equityAttribution/EquityAttributionSelect';
import { DatePicker } from '../../infrastructure/interface/forms/DatePicker';
import { TransactionTypeMultiSelect } from '../metadata/transactions/TransactionTypeMultiSelect';
import { AgentDealerSelect } from '../metadata/AgentDealerSelect';
import { Checkbox } from '../../infrastructure/interface/forms/Checkbox';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import {
  transactionsPageAgentSelectTestId,
  transactionsPageCompanySelectTestId,
  transactionsPageDealerSelectTestId, transactionsPageEmployeeSelectTestId,
  transactionsPageEquityAttributionSelectTestId,
  transactionsPageInvestorSelectTestId,
  transactionsPageTransactionTypeSelectTestId
} from './Transactions';
import { TransactionApproveToggleSwitch } from './TransactionApproveToggleSwitch';
import { InvestorSelectWithSearchModal } from '../metadata/investor/InvestorSelectWithSearchModal';
import { defaultAgentDealerSelectSettings } from '../metadata/AgentDealerSelectSettings';
import { defaultCompanySelectSettings } from '../metadata/company/CompanySelectSettings';
import { defaultEquityAttributionSelectSettings } from '../metadata/equityAttribution/EquityAttributionSelectSettings';
import { defaultTransactionTypeSelectSettings } from '../metadata/transactions/TransactionTypeSelectBase';
import { Tooltip } from '../../infrastructure/interface/components/Tooltip';
import { StatusIndicator } from '../../infrastructure/interface/components/StatusIndicator';
import { PendingTransactionChaserMessage } from './PendingTransactionChaserMessage';
import { Panel } from '../../infrastructure/interface/components/Panel';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { TransactionsActionButtons } from './TransactionsActionButtons';
import { EmployeeSelect } from '../metadata/employees/EmployeeSelect';
import { holdingsPageEmployeeSelectTestId } from '../holdings/Holdings';
import { SortableTh } from '../../infrastructure/interface/tables/SortableTh';
import { toggleSort } from '../../infrastructure/interface/tables/SortDirection';

export const DesktopTransactions = () => {
  const { translate } = useInternationalisation();

  return (
    <>
      <HeaderContainer>
        <Header1>{translate('pages.transactions.header')}</Header1>
        <TransactionsActionButtons />
      </HeaderContainer>
      <FiltersComponent />
      <SearchResultsTableComponent />
    </>
  );
};

const FiltersComponent = () => {
  const { translate } = useInternationalisation();
  const {
    employeeId,
    setEmployeeId,
    investorId,
    setInvestorId,
    companyId,
    setCompanyId,
    equityAttributionId,
    setEquityAttributionId,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    transactionTypes,
    setTransactionTypes,
    agentId,
    setAgentId,
    dealerId,
    setDealerId,
    unapprovedOnly,
    setUnapprovedOnly,
    resetFilters,
    setFilterLoadError,
  } = useContext(TransactionsContext);

  return (
    <FilterComponentsContainer>
      <IfUserHasRole userRole="Advisor">
        <EmployeeSelect
          fieldLabel={translate('pages.holdings.filters.advisor')}
          value={employeeId}
          onChange={setEmployeeId}
          data-testid={transactionsPageEmployeeSelectTestId}
          onError={setFilterLoadError}
          clearable={true}
          wrapInDiv={true}
        />
      </IfUserHasRole>
      
      <IfUserHasRole userRole={['Manager', 'Advisor', 'Consolidated Investor']}>
        <InvestorSelectWithSearchModal
          fieldLabel={translate('pages.transactions.filters.investor')}
          settings={{ forTransacting: false, employeeId: employeeId }}
          value={investorId}
          onChange={setInvestorId}
          data-testid={transactionsPageInvestorSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <CompanySelect
        fieldLabel={translate('pages.transactions.filters.company')}
        settings={{ ...defaultCompanySelectSettings, includeAllFunds: true, includeBlank: true, employeeCounterpartId: employeeId }}
        value={companyId}
        onChange={setCompanyId}
        data-testid={transactionsPageCompanySelectTestId}
        onError={setFilterLoadError}
        defaultToFirstOption={true}
        wrapInDiv={true}
      />

      <EquityAttributionSelect
        fieldLabel={translate('pages.transactions.filters.equityAttribution')}
        settings={{ ...defaultEquityAttributionSelectSettings, companyId, employeeCounterpartId: employeeId }}
        value={equityAttributionId}
        onChange={setEquityAttributionId}
        data-testid={transactionsPageEquityAttributionSelectTestId}
        onError={setFilterLoadError}
        defaultToFirstOption={true}
        wrapInDiv={true}
      />

      <div>
        <FieldLabel>{translate('pages.transactions.filters.startDate')}</FieldLabel>
        <DatePicker value={startDate} onChange={setStartDate} clearable />
      </div>

      <div>
        <FieldLabel>{translate('pages.transactions.filters.endDate')}</FieldLabel>
        <DatePicker value={endDate} onChange={setEndDate} clearable />
      </div>

      <div>
        <FieldLabel>{translate('pages.transactions.filters.transactionType')}</FieldLabel>
        <TransactionTypeMultiSelect
          settings={{ ...defaultTransactionTypeSelectSettings, includeBlank: false }}
          values={transactionTypes}
          onChange={setTransactionTypes}
          data-testid={transactionsPageTransactionTypeSelectTestId}
          onError={setFilterLoadError}
          clearable={true}
        />
      </div>

      <IfUserHasRole userRole={'Manager'}>
        <AgentDealerSelect
          fieldLabel={translate('pages.transactions.filters.agent')}
          settings={{ ...defaultAgentDealerSelectSettings, isAgent: true }}
          value={agentId}
          onChange={setAgentId}
          data-testid={transactionsPageAgentSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          wrapInDiv={true}
        />

        <AgentDealerSelect
          fieldLabel={translate('pages.transactions.filters.dealer')}
          settings={{ ...defaultAgentDealerSelectSettings, isAgent: false }}
          value={dealerId}
          onChange={setDealerId}
          data-testid={transactionsPageDealerSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          wrapInDiv={true}
        />

        <div>
          <FieldLabel>{translate('pages.transactions.filters.unapprovedOnly')}</FieldLabel>
          <Checkbox
            checked={unapprovedOnly ?? false}
            onChange={setUnapprovedOnly}
            fieldAlignment="row"
          />
        </div>
      </IfUserHasRole>

      <ResetFiltersButton resetFilters={resetFilters} />
    </FilterComponentsContainer>
  );
};

const SearchResultsTableComponent = () => {
  const { pageNumber, setPageNumber, resultsPerPage, setResultsPerPage, response, inProgress } =
    useContext(TransactionsContext);

  const showContractNoteColumn = some(
    response?.transactions,
    (transaction) => transaction.contractNoteDocumentId !== null
  );

  const showApprovalColumn = some(response?.transactions, (transaction) =>
    [ManagerApproval.Approved, ManagerApproval.Unapproved].includes(transaction.managerApproval)
  );

  return (
    <Panel>
      <PaginatedTable
        showLoadingOverlay={inProgress}
        resultsPerPage={resultsPerPage}
        totalResultsCount={response?.total || 0}
        onChangeResultsPerPage={setResultsPerPage}
        currentPageNumber={pageNumber}
        onChangeCurrentPageNumber={setPageNumber}
      >
        <Table>
          <SearchResultsTableHeaderComponent
            showContractNoteColumn={showContractNoteColumn}
            showApprovalColumn={showApprovalColumn}
          />
          <SearchResultsTableBodyComponent
            showContractNoteColumn={showContractNoteColumn}
            showApprovalColumn={showApprovalColumn}
          />
        </Table>
      </PaginatedTable>
    </Panel>
  );
};

type SearchResultsTableProps = {
  showContractNoteColumn: boolean;
  showApprovalColumn: boolean;
};
const SearchResultsTableHeaderComponent = ({
  showContractNoteColumn,
  showApprovalColumn,
}: SearchResultsTableProps) => {
  const { translate } = useInternationalisation();

  const {sort,setSort,dir,setDir} =useContext(TransactionsContext);

  const sortClick = (column: string) => {
    toggleSort(column, sort, dir, setSort, setDir);
  };
  
  return (
    <THead>
      <Tr>
        <IfUserHasRole userRole={['Manager', 'Advisor', 'Consolidated Investor']}>
          <SortableTh dir={dir} sort={sort} columnName={'investor'} onClickSortIcon={sortClick}>{translate('pages.transactions.tables.searchResults.headings.investor')}</SortableTh>
        </IfUserHasRole>
        <SortableTh dir={dir} sort={sort} columnName={'fund'} onClickSortIcon={sortClick}>{translate('pages.transactions.tables.searchResults.headings.fund')}</SortableTh>
        <SortableTh dir={dir} sort={sort} columnName={'shareSeries'} onClickSortIcon={sortClick}>{translate('pages.transactions.tables.searchResults.headings.shareSeries')}</SortableTh>
        <SortableTh dir={dir} sort={sort} columnName={'date'} onClickSortIcon={sortClick}>{translate('pages.transactions.tables.searchResults.headings.date')}</SortableTh>
        <SortableTh dir={dir} sort={sort} columnName={'type'} onClickSortIcon={sortClick}>{translate('pages.transactions.tables.searchResults.headings.type')}</SortableTh>
        <SortableTh dir={dir} sort={sort} columnName={'units'} onClickSortIcon={sortClick} align={'right'}>
          {translate('pages.transactions.tables.searchResults.headings.units')}
        </SortableTh>
        <SortableTh dir={dir} sort={sort} columnName={'price'} onClickSortIcon={sortClick} align={'right'}>
          {translate('pages.transactions.tables.searchResults.headings.price')}
        </SortableTh>
        <SortableTh align={'center'} dir={dir} sort={sort} columnName={'currency'} onClickSortIcon={sortClick}>
          {translate('pages.transactions.tables.searchResults.headings.currency')}
        </SortableTh>
        <SortableTh align="right" dir={dir} sort={sort} columnName={'value'} onClickSortIcon={sortClick}>{translate('pages.transactions.tables.searchResults.headings.value')}</SortableTh>
        {showContractNoteColumn && (
          <SortableTh dir={dir} sort={sort} columnName={'cn'} onClickSortIcon={sortClick}>{translate('pages.transactions.tables.searchResults.headings.cn')}</SortableTh>
        )}
        <IfUserHasRole userRole={'Manager'}>
          {showApprovalColumn && (
            <SortableTh dir={dir} sort={sort} columnName={'approve'} onClickSortIcon={sortClick}>{translate('pages.transactions.tables.searchResults.headings.approve')}</SortableTh>
          )}
        </IfUserHasRole>
        <SortableTh dir={dir} sort={sort} columnName={'status'} onClickSortIcon={sortClick}>{translate('pages.transactions.tables.searchResults.headings.status')}</SortableTh>
      </Tr>
    </THead>
  );
};

const SearchResultsTableBodyComponent = ({
  showContractNoteColumn,
  showApprovalColumn,
}: SearchResultsTableProps) => {
  const { formatDate, formatNumber } = useInternationalisation();
  const authenticationContext = useContext(AuthenticationContext);
  const { response, loadData } = useContext(TransactionsContext);

  if (!response || isEmpty(response?.transactions)) {
    if (response?.total === -1) {
      return (
        <TBody>
          <ThresholdLimitRow
            colSpan={authenticationContext.getUser().role === 'Investor' ? 10 : 11}
          />
        </TBody>
      );
    } else {
      return (
        <TBody>
          <NoResultsRow colSpan={authenticationContext.getUser().role === 'Investor' ? 10 : 11} />
        </TBody>
      );
    }
  }

  return (
    <TBody>
      {response.transactions.map((transaction, index) => (
        <Tr key={index}>
          <IfUserHasRole userRole={['Manager', 'Advisor', 'Consolidated Investor']}>
            <Td>
              {transaction.showInvestorDetailsLink ? (
                <AppLink to={transaction.currentInvestorHideId ? `/investor-details/` : `/investor-details/${transaction.investorId}`}>
                  {transaction.investorName}
                  {!isNullUndefinedOrBlank(transaction.investorNumber) &&
                    ` (${transaction.investorNumber})`}
                </AppLink>
              ) : (
                <>
                  {transaction.investorName}
                  {!isNullUndefinedOrBlank(transaction.investorNumber) &&
                    ` (${transaction.investorNumber})`}
                </>
              )}
            </Td>
          </IfUserHasRole>
          <Td>{transaction.fund}</Td>
          <Td>{transaction.shareSeries}</Td>
          <Td>{transaction.date != null ? formatDate(transaction.date.toString()) : ''}</Td>
          <Td>{transaction.type}</Td>
          <Td align={'right'}>
            {transaction.units != null
              ? formatNumber(transaction.units, { decimalPlaces: transaction.shareDecimals })
              : ''}
          </Td>
          <Td align={'right'}>
            {transaction.price != null
              ? formatNumber(transaction.price, { decimalPlaces: transaction.priceDecimals })
              : ''}
          </Td>
          <Td align={'center'}>{transaction.currency}</Td>
          <Td align={'right'}>
            {formatNumber(transaction.value, { decimalPlaces: transaction.currencyDecimals })}
          </Td>
          {showContractNoteColumn && (
            <Td>
              {transaction.contractNoteDocumentId !== null && (
                <AppLink
                  to={`/api/documents/DownloadDocument?documentId=${transaction.contractNoteDocumentId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileIconContainer>
                    <FileIcon fileName={transaction.contractNoteDocumentName ?? ''} />
                  </FileIconContainer>
                </AppLink>
              )}
            </Td>
          )}
          <IfUserHasRole userRole={'Manager'}>
            {showApprovalColumn && (
              <Td>
                <TransactionApproveToggleSwitch
                  managerApproval={transaction.managerApproval}
                  dealNumber={transaction.dealNumber}
                  onApprovalToggled={loadData}
                />
              </Td>
            )}
          </IfUserHasRole>
          <Td>
            <TransactionStatusComponent
              status={transaction.status}
              chaserMessagesTranslationKeys={transaction.chaserMessagesTranslationKeys}
            />
          </Td>
        </Tr>
      ))}
    </TBody>
  );
};

type TransactionStatusProps = {
  status: TransactionStatus;
  chaserMessagesTranslationKeys: Array<string>;
};

export const TransactionStatusComponent = ({
  status,
  chaserMessagesTranslationKeys,
}: TransactionStatusProps) => {
  const { translate } = useInternationalisation();

  if (status === 'Pending') {
    if (chaserMessagesTranslationKeys.length > 0) {
      return (
        <Tooltip
          content={
            <PendingTransactionChaserMessage translationKeys={chaserMessagesTranslationKeys} />
          }
          horizontalAlignment="right"
        >
          <StatusIndicator type="pending" text={translate('pages.transactions.status.pending')} />
        </Tooltip>
      );
    } else
      return (
        <StatusIndicator type="pending" text={translate('pages.transactions.status.pending')} />
      );
  } else if (status === 'Confirmed') {
    return (
      <StatusIndicator type="positive" text={translate('pages.transactions.status.confirmed')} />
    );
  } else {
    return null;
  }
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing16};

  ${Header1} {
    margin: 0;
  }
`;

const FilterComponentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spacing16};
  margin-bottom: ${spacing32};
`;

const FileIconContainer = styled.div`
  display: inline-block;
  margin-right: ${spacing12};
  position: relative;

  svg {
    position: relative;
    height: ${spacing16};
    width: ${spacing16};
    top: 1px;
  }
`;
