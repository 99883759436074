import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { ComponentResponse } from '../DashboardComponent';
import { useDashboardFilters } from '../DashboardFiltersContext';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  EquityAttributionSelect,
  EquityAttributionSelectOptionValue,
} from '../../metadata/equityAttribution/EquityAttributionSelect';
import { InvalidEquityAttributionId } from '../../metadata/idConstants';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { mapEquityAttributionDropdownOptionsResponseToSelectOptions } from '../../metadata/equityAttribution/GetEquityAttributionDropdownOptionsResponse';
import { FieldError } from '../../../infrastructure/forms/common/FieldError';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { Table, TBody, Td, Th, Tr } from '../../../infrastructure/interface/tables/Table';
import { camelCase } from 'lodash';
import { fadeIn } from '../../../styling/design/transitions';
import styled from 'styled-components/macro';
import { spacing32 } from '../../../styling/design/spacing';
import { AlertHeader, AlertIcon } from '../../../infrastructure/interface/components/Alert';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { SecondaryButton } from '../../../infrastructure/interface/buttons/SecondaryButton';

export const keyRatiosComponentName = 'Key Indicators';

export const KeyRatios = () => {
  const getRequest = useGetJson<GetDataForKeyRatiosQuery, GetDataForKeyRatiosResponse>(
    'api/dashboards/GetDataForKeyRatios'
  );

  const { companyId, fromDate, employeeId } = useDashboardFilters();
  const [equityAttributionId, setEquityAttributionId] =
    useState<EquityAttributionSelectOptionValue>(null as EquityAttributionSelectOptionValue);
  const [selectError, setSelectError] = useState<string | null>(null);

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
          equityAttributionId: equityAttributionId ?? InvalidEquityAttributionId,
          employeeId: employeeId,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate, equityAttributionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <KeyRatiosComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
          equityAttributionId={equityAttributionId}
          companyId={companyId}
          setEquityAttributionId={setEquityAttributionId}
          selectError={selectError}
          setSelectError={setSelectError}
          requestError={getRequest.state.error}
          retry={makeRequest}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForKeyRatiosResponse;
  showLoadingOverlay: boolean;
  equityAttributionId: EquityAttributionSelectOptionValue;
  setEquityAttributionId: (value: EquityAttributionSelectOptionValue) => void;
  companyId: number | null;
  selectError: string | null;
  setSelectError: (value: string | null) => void;
  requestError: string | null;
  retry?: () => void;
};

const KeyRatiosComponent = (props: ComponentProps) => {
  const { translate } = useInternationalisation();

  if (props.requestError !== null) {
    return (
      <RequestFailedContainer>
        <AlertHeader alertType="negative">
          <AlertIcon alertType="negative" />
          {translate('errors.apology')}
        </AlertHeader>
        {props.requestError}
        {props.retry != null && (
          <ButtonRow withMarginTop={true}>
            <SecondaryButton size="small" onClick={props.retry}>
              {translate('errors.tryAgain')}
            </SecondaryButton>
          </ButtonRow>
        )}
      </RequestFailedContainer>
    );
  }

  const filterSetContent: ReactNode = (
    <div>
      <EquityAttributionSelect
        settings={{
          employeeCounterpartId: null,
          companyId: props.companyId,
          includeBlank: false,
        }}
        value={props.equityAttributionId}
        onChange={props.setEquityAttributionId}
        onError={props.setSelectError}
        onLoaded={(response) => {
          props.setSelectError(null);
          props.setEquityAttributionId(
            mapEquityAttributionDropdownOptionsResponseToSelectOptions(response)[0]?.value ?? null
          );
        }}
      />
      {props.selectError && <FieldError fieldName={'KeyRatios'} children={props.selectError} />}
    </div>
  );

  return (
    <DashboardComponentLayout
      headerText={translate('pages.dashboard.components.keyRatios.title')}
      showLoadingOverlay={props.showLoadingOverlay}
      filterSet={filterSetContent}
      showNoDataMessage={props.response.noDataMessage}
    >
      <Table compact={true}>
        <TBody>
          <Tr>
            <Th>{translate('pages.dashboard.components.keyRatios.headerNames.ratioLabel')}</Th>
            <Th>{translate('pages.dashboard.components.keyRatios.headerNames.fundInception')}</Th>
            <Th>
              {translate('pages.dashboard.components.keyRatios.headerNames.benchmarkInception')}
            </Th>
          </Tr>

          {props.response.resultRows.map((row) => (
            <Tr key={row.ratioLabel}>
              <Td>
                {fieldNamesWithTranslations.includes(row.ratioLabel)
                  ? translate(
                      `pages.dashboard.components.keyRatios.fieldNames.${camelCase(row.ratioLabel)}`
                    )
                  : row.ratioLabel}
              </Td>
              <Td>{row.fundInception}</Td>
              <Td>{row.benchmarkInception}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </DashboardComponentLayout>
  );
};

const fieldNamesWithTranslations = [
  'Annualized Return (ROR)',
  'Standard Deviation',
  'Sharpe',
  'Skewness',
  'Kurtosis',
  'Sortino',
  'Highest Monthly Gain',
  'Highest Monthly Loss',
  'Downside Risk',
  'Jensen Alpha',
  'Beta',
  'Correlation',
  'Safety First Ratio',
  'Treynor Ratio',
  'Maximum Drawdown',
];

type GetDataForKeyRatiosQuery = {
  companyId: number;
  runDate: string;
  equityAttributionId: number;
  employeeId: number | null;
};

type GetDataForKeyRatiosResponse = ComponentResponse & {
  resultRows: Array<resultRow>;
};

type resultRow = {
  ratioLabel: string;
  fundInception: number | null;
  benchmarkInception: number | null;
};

const RequestFailedContainer = styled.div`
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 0.25s ease;
  padding: ${spacing32};
`;
