import {
  clearSessionStorage,
  readSessionStorageNumber,
  sessionStorageCodes,
  writeSessionStorage
} from './useSessionStorage';


export const useSessionEmployeeId = () => {
  const sessionEmployeeId: number | null = readSessionStorageNumber(sessionStorageCodes.employeeId);

  const setStoredEmployeeId = (employeeId: number | null | undefined): void => {
    if (employeeId != null && employeeId > 0) {
      writeSessionStorage(sessionStorageCodes.employeeId, employeeId.toString());
    } else {
      clearSessionStorage(sessionStorageCodes.employeeId);
    }
  };

  return { sessionEmployeeId, setStoredEmployeeId };
};
