import React from 'react';
import styled from 'styled-components/macro';
import { PieChartIcon, PlusIcon } from '../../icons/icons';
import { ButtonWithIcon } from '../../infrastructure/interface/buttons/ButtonWithIcon';
import { DeleteButton } from '../../infrastructure/interface/buttons/DeleteButton';
import { Header4 } from '../../infrastructure/interface/components/Headers';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { backgroundColours, colourGrey04, textColours } from '../../styling/design/colours';
import {
  spacing128,
  spacing8,
  spacing24,
  spacing256,
  spacing16,
} from '../../styling/design/spacing';
import { DashboardComponentLayout } from './DashboardComponentLayout';
import {
  DashboardComponentColumnIndex,
  DashboardComponentResponse,
  validDashboardComponentColumnIndexes,
} from './GetDashboardsForCurrentUserResponse';
import { dashboardGridGapInPx, dashboardGridRowHeightInPx } from './Dashboard';
import { shadow1, shadow3 } from '../../styling/design/shadows';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { cloneDeep, isEqual } from 'lodash';
import { DashboardComponentsModalOption } from './DashboardComponentsModal';

type Props = {
  components: Array<DashboardComponentResponse>;
  setComponents: (value: Array<DashboardComponentResponse>) => void;
  error: string | null;
  onAddComponentButtonClick: () => void;
};

// Due to limitations with beautiful-react-dnd we must simulate the 2-column grid layout with two
// flex columns.
export const DashboardLayoutEditor = (props: Props) => {
  if (props.components.length === 0) {
    return (
      <EmptyLayoutPlaceholder
        error={props.error}
        onAddComponentClick={props.onAddComponentButtonClick}
      />
    );
  }

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, props.components, props.setComponents)}
    >
      {props.error && <ErrorContainer>{props.error}</ErrorContainer>}
      <GridColumnsContainer>
        {validDashboardComponentColumnIndexes.map((columnIndex) => (
          <DroppableGridColumn columnIndex={columnIndex} key={columnIndex}>
            {getSortedComponentsForColumn(columnIndex, props.components).map((component, index) => (
              <DraggableDashboardComponentCard
                component={component}
                indexInColumn={index}
                onDelete={() => deleteComponent(component, props.components, props.setComponents)}
                key={component.dashboardComponentId}
              />
            ))}
          </DroppableGridColumn>
        ))}
      </GridColumnsContainer>
    </DragDropContext>
  );
};

type DroppableGridColumnProps = {
  columnIndex: DashboardComponentColumnIndex;
  children: React.ReactNode;
};

const DroppableGridColumn = ({ columnIndex, children }: DroppableGridColumnProps) => {
  return (
    <Droppable droppableId={columnIndex.toString()}>
      {(provided) => (
        <GridColumn {...provided.droppableProps} ref={provided.innerRef}>
          {children}
          <div>{provided.placeholder}</div>
        </GridColumn>
      )}
    </Droppable>
  );
};

type DraggableDashboardComponentCardProps = {
  component: DashboardComponentResponse;
  indexInColumn: number;
  onDelete: () => void;
};

const DraggableDashboardComponentCard = (props: DraggableDashboardComponentCardProps) => {
  return (
    <Draggable
      draggableId={props.component.dashboardComponentId.toString()}
      index={props.indexInColumn}
    >
      {(provided, snapshot) => (
        <DashboardComponentCard
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          component={props.component}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <DashboardComponentLayout
            headerText={props.component.name}
            filterSet={
              <DeleteButton
                onClick={props.onDelete}
                data-testid={deleteComponentButtonTestId(props.component.dashboardComponentId)}
              />
            }
          />
        </DashboardComponentCard>
      )}
    </Draggable>
  );
};

type EmptyLayoutPlaceholderProps = {
  error: string | null;
  onAddComponentClick: () => void;
};

const EmptyLayoutPlaceholder = ({ error, onAddComponentClick }: EmptyLayoutPlaceholderProps) => {
  const { translate } = useInternationalisation();

  return (
    <EmptyLayoutPlaceholderContainer>
      <PieChartIconContainer>
        <PieChartIcon />
      </PieChartIconContainer>
      <EmptyLayoutHeader>
        {translate('pages.createDashboard.noComponentsMessage.header')}
      </EmptyLayoutHeader>
      {error ? (
        <ErrorContainer>{error}</ErrorContainer>
      ) : (
        <EmptyLayoutTextContainer>
          {translate('pages.createDashboard.noComponentsMessage.text')}
        </EmptyLayoutTextContainer>
      )}
      <ButtonWithIcon icon={<PlusIcon />} onClick={onAddComponentClick}>
        {translate('pages.createDashboard.addComponentButtonText')}
      </ButtonWithIcon>
    </EmptyLayoutPlaceholderContainer>
  );
};

const getSortedComponentsForColumn = (
  columnIndex: DashboardComponentColumnIndex,
  components: Array<DashboardComponentResponse>
) =>
  components.filter((c) => c.columnIndex === columnIndex).sort((a, b) => a.rowIndex - b.rowIndex);

const recalculateRowIndexes = (sortedComponentsInColumn: Array<DashboardComponentResponse>) =>
  sortedComponentsInColumn.reduce((currentRowIndex, component) => {
    component.rowIndex = currentRowIndex;
    return currentRowIndex + component.height;
  }, 0);

export const deleteComponent = (
  component: DashboardComponentResponse,
  components: Array<DashboardComponentResponse>,
  setComponents: (value: Array<DashboardComponentResponse>) => void
) => {
  const componentsCopy = cloneDeep(components);

  const componentsCopyWithComponentRemoved = componentsCopy.filter(
    (c) => c.dashboardComponentId !== component.dashboardComponentId
  );

  for (const columnIndex of validDashboardComponentColumnIndexes) {
    const newSortedComponentsInColumn = getSortedComponentsForColumn(
      columnIndex,
      componentsCopyWithComponentRemoved
    );

    recalculateRowIndexes(newSortedComponentsInColumn);
  }

  setComponents(componentsCopyWithComponentRemoved);
};

export const onDragEnd = (
  result: DropResult,
  components: Array<DashboardComponentResponse>,
  setComponents: (value: Array<DashboardComponentResponse>) => void
) => {
  const { source, destination, draggableId } = result;

  if (!destination || isEqual(source, destination)) {
    return;
  }

  const componentsCopy = cloneDeep(components);

  const draggedComponent = componentsCopy.find(
    (c) => c.dashboardComponentId.toString() === draggableId
  )!;

  const sortedFirstColumnCopy = getSortedComponentsForColumn(0, componentsCopy);
  const sortedSecondColumnCopy = getSortedComponentsForColumn(1, componentsCopy);

  const sortedSourceColumnCopy =
    source.droppableId === '0' ? sortedFirstColumnCopy : sortedSecondColumnCopy;

  const sortedDestinationColumnCopy =
    destination.droppableId === '0' ? sortedFirstColumnCopy : sortedSecondColumnCopy;

  sortedSourceColumnCopy.splice(source.index, 1);

  // Insert dragged component with incorrect row indexes, as these will be re-calculated next
  sortedDestinationColumnCopy.splice(destination.index, 0, {
    ...draggedComponent,
    columnIndex: parseInt(destination.droppableId) as DashboardComponentColumnIndex,
  });

  // re-calculate row indexes
  recalculateRowIndexes(sortedFirstColumnCopy);
  recalculateRowIndexes(sortedSecondColumnCopy);

  setComponents([...sortedFirstColumnCopy, ...sortedSecondColumnCopy]);
};

export const addComponentToBottomOfDashboard = (
  newComponent: DashboardComponentsModalOption,
  currentComponents: Array<DashboardComponentResponse>
) => {
  const getHeightOfColumn = (componentsInColumn: Array<DashboardComponentResponse>) => {
    const lastComponentInColumn: DashboardComponentResponse | null = componentsInColumn.reduce(
      (x, y) => (x.rowIndex > y.rowIndex ? x : y),
      componentsInColumn[0] ?? null
    );

    return lastComponentInColumn == null
      ? 0
      : lastComponentInColumn.rowIndex + lastComponentInColumn.height;
  };

  const componentsInFirstColumn = currentComponents.filter((c) => c.columnIndex === 0);
  const firstColumnHeight = getHeightOfColumn(componentsInFirstColumn);

  const componentsInSecondColumn = currentComponents.filter((c) => c.columnIndex === 1);
  const secondColumnHeight = getHeightOfColumn(componentsInSecondColumn);

  const newComponentRowIndex = Math.min(firstColumnHeight, secondColumnHeight);
  const newComponentColumnIndex = firstColumnHeight <= secondColumnHeight ? 0 : 1;

  const newComponentResponse: DashboardComponentResponse = {
    dashboardComponentId: newComponent.dashboardComponentId,
    name: newComponent.name,
    width: newComponent.width,
    height: newComponent.height,
    rowIndex: newComponentRowIndex,
    columnIndex: newComponentColumnIndex,
  };

  return [...currentComponents, newComponentResponse];
};

export const addMultipleComponentsToBottomOfDashboard = (
  newComponents: Array<DashboardComponentsModalOption>,
  currentComponents: Array<DashboardComponentResponse>,
  setCurrentComponents: (value: Array<DashboardComponentResponse>) => void
) => {
  const updatedCurrentComponents = newComponents.reduce(
    (temporaryComponents, newComponent) =>
      addComponentToBottomOfDashboard(newComponent, temporaryComponents),
    currentComponents
  );

  setCurrentComponents(updatedCurrentComponents);
};

const PieChartIconContainer = styled.div`
  color: ${colourGrey04};

  svg {
    width: ${spacing128};
    height: ${spacing128};
  }
`;

const EmptyLayoutHeader = styled(Header4)`
  margin-bottom: 0;
`;

const EmptyLayoutTextContainer = styled.div`
  margin-top: ${spacing8};
  margin-bottom: ${spacing24};
`;

const ErrorContainer = styled.div`
  color: ${textColours.negative};
  margin-bottom: ${spacing16};
  text-align: center;
`;

const EmptyLayoutPlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${spacing256};
`;

const GridColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${dashboardGridGapInPx}px;
`;

const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DashboardComponentCard = styled.div<{
  component: DashboardComponentResponse;
  isDragging: boolean;
}>`
  height: ${(props) =>
    props.component.height * dashboardGridRowHeightInPx +
    (props.component.height - 1) * dashboardGridGapInPx}px;
  margin-bottom: ${dashboardGridGapInPx}px;

  background: ${backgroundColours.default};
  border-radius: 10px;
  box-shadow: ${(props) => (props.isDragging ? shadow3 : shadow1)};
  overflow: hidden;
`;

export const deleteComponentButtonTestId = (componentId: number) =>
  `delete-component-${componentId}-button`;
