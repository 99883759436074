// These values should be kept in sync with `DealingTransactionType.cs`
export const dealingTransactionType = {
  FullRedemption: 'F',
  Issue: 'I',
  Redeem: 'R',
  Switch: 'S',
  SwitchFull: 'X',
} as const;

export type DealingTransactionType = keyof typeof dealingTransactionType;

export type DealingTransactionTypeCode = typeof dealingTransactionType[DealingTransactionType];
