import React from 'react';
import {
  NoResultsRow,
  Table,
  TableLoadingOverlay,
  TBody,
  Td,
  Th,
  THead,
  Tr
} from '../../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import styled from 'styled-components/macro';
import { spacing16, spacing32 } from '../../../styling/design/spacing';
import { isEmpty } from 'lodash';
import { useInvestorBankingDetailsContext } from './InvestorBankingDetailsContext';
import { EditBankingDetailsButton } from './createEdit/edit/EditBankingDetailsButton';
import { DeleteBankingDetailsButton } from './delete/DeleteBankingDetailsButton';
import { Alert } from '../../../infrastructure/interface/components/Alert';

type Props = {
  canEdit: boolean;
};

export const DesktopInvestorBankingDetails = ({ canEdit }: Props) => {
  const { translate } = useInternationalisation();
  const colSpan = canEdit ? 7 : 6;

  const {
    bankingDetailsRequestInProgress,
    latestBankingDetailsResponse,
    permissionsRequestInProgress,
    permissionsResponse
  } = useInvestorBankingDetailsContext();

  if (permissionsResponse?.canViewBankingDetails === false) {
    return (
      <Container>
        <Alert alertType="warning" header={translate('pages.bankingDetails.permissionDeniedTitle')}>
          {translate('pages.bankingDetails.permissionDenied')}
        </Alert>
      </Container>
    );
  }

  return (
    <TableLoadingOverlay
      showOverlay={bankingDetailsRequestInProgress || permissionsRequestInProgress}
    >
      <Table>
        <THead>
          <Tr>
            <Th>{translate('pages.bankingDetails.fieldNames.product')}</Th>
            <Th>{translate('pages.bankingDetails.fieldNames.bankName')}</Th>
            <Th>{translate('pages.bankingDetails.fieldNames.accountName')}</Th>
            <Th>{translate('pages.bankingDetails.fieldNames.accountNumber')}</Th>
            <Th>{translate('pages.bankingDetails.fieldNames.fundName')}</Th>
            <Th>{translate('pages.bankingDetails.fieldNames.currency')}</Th>
            {canEdit && (<Th />)}
          </Tr>
        </THead>
        <TBody>
          {latestBankingDetailsResponse == null ||
          isEmpty(latestBankingDetailsResponse.bankingDetails) ? (
            <NoResultsRow colSpan={colSpan} />
          ) : (
            latestBankingDetailsResponse.bankingDetails.map((bankingDetail) => (
              <Tr key={bankingDetail.id}>
                <Td>{bankingDetail.product}</Td>
                <Td>{bankingDetail.bankName}</Td>
                <Td>{bankingDetail.accountName}</Td>
                <Td>{bankingDetail.accountNumber}</Td>
                <Td>{bankingDetail.fundName}</Td>
                <Td>{bankingDetail.currencyCode}</Td>
                {canEdit && (
                  <Td align="right">
                    <ActionButtonsContainer
                      data-testid={bankingDetailsActionButtonsContainerTestId(
                        permissionsResponse != null
                      )}
                    >
                      <EditBankingDetailsButton bankingDetail={bankingDetail} />
                      <DeleteBankingDetailsButton bankingDetail={bankingDetail} />
                    </ActionButtonsContainer>
                  </Td>
                )}
              </Tr>
            ))
          )}
        </TBody>
      </Table>
    </TableLoadingOverlay>
  );
};

const Container = styled.div`
  margin-top: ${spacing32};
`;

const ActionButtonsContainer = styled.div`
  display: inline-flex;
  gap: ${spacing16};
`;

export const bankingDetailsActionButtonsContainerTestId = (permissionRequestIsLoaded: boolean) =>
  `banking-details-action-buttons-container-${permissionRequestIsLoaded}`;
