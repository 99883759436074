import styled from 'styled-components/macro';
import { MailToLink } from '../../../infrastructure/interface/components/ExternalLink';
import { Card } from '../../../infrastructure/interface/components/Card';
import { PaginatedTable } from '../../../infrastructure/interface/tables/PaginatedTable';
import { Table, TBody, Td, Th, THead, Tr } from '../../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { investorDetailsComponentResultsPerPageOptions } from '../InvestorDetails';
import { useCommunicationCopies } from './CommunicationCopiesContext';

export const DesktopCommunicationCopiesList = () => {
  const { translate } = useInternationalisation();
  const { blocked, latestResponse, requestInProgress } = useCommunicationCopies();

  const initialRequestIsInProgress = latestResponse == null && requestInProgress;

  return (
    <Card
      header={
        blocked
          ? translate('pages.investorDetailsPage.communicationCopies.blockedHeader')
          : translate('pages.investorDetailsPage.communicationCopies.header')
      }
      cardType="expandable"
      startExpanded={false}
      contentIsLoading={initialRequestIsInProgress}
    >
      <CommunicationCopiesTable />
    </Card>
  );
};

const CommunicationCopiesTable = () => {
  const { translate } = useInternationalisation();
  const translateFieldName = (fieldName: string) =>
    translate(`pages.investorDetailsPage.communicationCopies.fieldNames.${fieldName}`);

  const {
    blocked,
    latestResponse,
    resultsPerPage,
    setResultsPerPage,
    pageNumber,
    setPageNumber,
    requestInProgress,
  } = useCommunicationCopies();

  if (latestResponse == null) {
    return null;
  }

  if (latestResponse.totalResultsCount === 0) {
    return (
      <NoCommunicationCopiesContainer>
        {blocked
          ? translate('pages.investorDetailsPage.communicationCopies.noBlockedCommunicationCopies')
          : translate('pages.investorDetailsPage.communicationCopies.noCommunicationCopies')}
      </NoCommunicationCopiesContainer>
    );
  }

  return (
    <PaginatedTable
      currentPageNumber={pageNumber}
      onChangeCurrentPageNumber={setPageNumber}
      resultsPerPage={resultsPerPage}
      onChangeResultsPerPage={setResultsPerPage}
      resultsPerPageOptions={investorDetailsComponentResultsPerPageOptions}
      totalResultsCount={latestResponse.totalResultsCount}
      showLoadingOverlay={requestInProgress}
    >
      <Table>
        <THead>
          <Tr>
            <Th>{translateFieldName('counterpartName')}</Th>
            <Th>
              {blocked ? translateFieldName('companyName') : translateFieldName('emailAddress')}
            </Th>
          </Tr>
        </THead>
        <TBody>
          {latestResponse.communicationCopies.map((copy, index) => (
            <Tr key={index}>
              <Td>{copy.counterpartName}</Td>
              <Td>
                {blocked ? (
                  copy.companyName
                ) : copy.emailAddress == null ? null : (
                  <MailToLink emailAddress={copy.emailAddress} />
                )}
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </PaginatedTable>
  );
};

export const NoCommunicationCopiesContainer = styled.div`
  text-align: center;
`;
