import React from 'react';
import styled from 'styled-components/macro';
import { AlertType, getAlertBorderColour, getAlertTextColour } from './Alert';
import { GhostButton } from '../buttons/GhostButton';
import { spacing16, spacing8 } from '../../../styling/design/spacing';
import { ErrorMessage } from './ErrorMessage';
import { WaitingButton } from '../buttons/WaitingButton';
import { DesktopModalSize, Modal, ModalButtonRow, ModalHeader } from './Modal';
import { Header3 } from './Headers';

type Props = {
  alertType: AlertType;
  isOpen: boolean;
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  cancelButtonText: string;
  onRequestClose: () => void;
  confirmButtonText: string;
  onInitiateAction: () => void;
  actionInProgress: boolean;
  actionError: string | null;
  desktopSize?: DesktopModalSize;
  cancelButtonTestId?: string;
  confirmButtonTestId?: string;
};

export const ActionAlert = (props: Props) => {
  const {
    alertType,
    isOpen,
    title,
    message,
    cancelButtonText,
    onRequestClose,
    confirmButtonText,
    onInitiateAction,
    actionInProgress,
    actionError,
    desktopSize,
    cancelButtonTestId,
    confirmButtonTestId,
  } = props;

  return (
    <StyledModal
      alertType={alertType}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      desktopSize={desktopSize}
    >
      <StyledModalHeader
        alertType={alertType}
        title={title}
        withCloseButton={true}
        onRequestClose={onRequestClose}
      />
      <Message>{message}</Message>
      <ModalButtonRow withMarginBottom={actionError != null}>
        <GhostButton
          data-testid={cancelButtonTestId}
          onClick={onRequestClose}
          disabled={actionInProgress}
        >
          {cancelButtonText}
        </GhostButton>
        <WaitingButton
          data-testid={confirmButtonTestId}
          buttonStyle={alertType}
          onClick={onInitiateAction}
          waitingText={confirmButtonText}
          isWaiting={actionInProgress}
        >
          {confirmButtonText}
        </WaitingButton>
      </ModalButtonRow>
      {actionError != null && <ErrorMessage>{actionError}</ErrorMessage>}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)<{ alertType: AlertType }>`
  border-left-style: solid;
  border-left-width: ${spacing8};
  border-left-color: ${(props) => getAlertBorderColour(props.alertType)};
`;

const StyledModalHeader = styled(ModalHeader)<{ alertType: AlertType }>`
  margin-bottom: ${spacing16};

  ${Header3} {
    color: ${(props) => getAlertTextColour(props.alertType)};
  }
`;

const Message = styled.div``;
