import React from 'react';
import { GenericSearchModal } from './GenericSearchModal';
import { Tr, Td } from '../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';
import { ItemDetailsCard } from '../../infrastructure/interface/components/ItemDetailsCard';
import { FieldAndValue } from '../../infrastructure/interface/components/FieldAndValue';
import styled from 'styled-components/macro';
import { spacing16 } from '../../styling/design/spacing';

type UsernameValue = string;

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onSelectUsername: (username: UsernameValue) => void;
};

export const UserSearchModal = ({ isOpen, onRequestClose, onSelectUsername }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <GenericSearchModal<GetUserSearchResultsQuery, UserSearchResult>
      title={translate('searchModals.user.title')}
      inputPlaceholder={translate('searchModals.user.placeholder')}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      dataEndpointUrl="/api/users/GetUserSearchResults"
      getQueryParameters={(inputs) => inputs}
      desktopHeaders={[
        translate('searchModals.user.headings.username'),
        translate('searchModals.user.headings.investor'),
        translate('searchModals.user.headings.externalCode'),
      ]}
      renderDesktopRow={(result) => (
        <DesktopRow key={result.username} result={result} onSelectUsername={onSelectUsername} />
      )}
      renderMobileItem={(result) => (
        <MobileItemCard key={result.username} result={result} onSelectUsername={onSelectUsername} />
      )}
    />
  );
};

type ResultProps = {
  result: UserSearchResult;
  onSelectUsername: (username: UsernameValue) => void;
};

const DesktopRow = ({ result, onSelectUsername }: ResultProps) => (
  <Tr>
    <Td>
      <MinimalButton onClick={() => onSelectUsername(result.username)} textAlign={'left'}>
        {result.username}
      </MinimalButton>
    </Td>
    <Td>{result.counterpartCode}</Td>
    <Td>{result.externalCode}</Td>
  </Tr>
);

const MobileItemCard = ({ result, onSelectUsername }: ResultProps) => {
  const { translate } = useInternationalisation();

  return (
    <ItemDetailsCard>
      <MobileInvestorNameButton
        onClick={() => onSelectUsername(result.username)}
        size="large"
        textAlign="left"
      >
        {result.username}
      </MobileInvestorNameButton>
      <FieldAndValue
        fieldLabel={translate('searchModals.user.headings.investor')}
        type="text"
        value={result.counterpartCode}
      />
      <FieldAndValue
        fieldLabel={translate('searchModals.user.headings.externalCode')}
        type="text"
        value={result.externalCode}
      />
    </ItemDetailsCard>
  );
};

const MobileInvestorNameButton = styled(MinimalButton)`
  margin-bottom: ${spacing16};
`;

type GetUserSearchResultsQuery = {
  searchTerm: string | null;
  pageNumber: number;
  resultsPerPage: number;
};

type UserSearchResult = {
  username: string;
  counterpartCode: string | null;
  externalCode: string | null;
};
