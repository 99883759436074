import React from 'react';
import { PaddedPage } from '../styling/layout/PaddedPage';
import { Header1 } from '../infrastructure/interface/components/Headers';
import { useInternationalisation } from '../internationalisation/hooks/useInternationalisation';
import { useWindowTitle } from '../infrastructure/hooks/useWindowTitle';

export const NotFound = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.notFound.title'));

  return (
    <PaddedPage>
      <Header1>{translate('pages.notFound.header')}</Header1>
      <p>{translate('pages.notFound.description')}</p>
    </PaddedPage>
  );
};
