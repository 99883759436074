import styled from 'styled-components/macro';
import { IsoDatestamp } from '../../helpers/dateTimeHelpers';
import { ApiRequestStateWrapper } from '../../infrastructure/api/ApiRequestStateWrapper';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { Modal, ModalHeader } from '../../infrastructure/interface/components/Modal';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { CounterpartDetails } from './CounterpartDetails';
import { BaseCounterpartDetails } from './GetInvestorDetailsResponse';
import { useInvestorDetailsContext } from './InvestorDetailsContext';
import { useInvestorIdUrlParam } from './useInvestorIdUrlParam';

export const AgentOrBeneficialOwnerDetailsModal = () => {
  const { translate } = useInternationalisation();

  const {
    agentOrBeneficialOwnerDetailsModalState: { isOpen, counterpartId, optionCode },
    setAgentOrBeneficialOwnerDetailsModalState,
  } = useInvestorDetailsContext();

  const closeModal = () =>
    setAgentOrBeneficialOwnerDetailsModalState((previous) => ({ ...previous, isOpen: false }));

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader
        title={
          optionCode === getAgentOrBeneficialOwnerDetailsOptions.agent
            ? translate('pages.investorDetailsPage.agentBeneficialOwnerDetailsModal.agentTitle')
            : translate(
                'pages.investorDetailsPage.agentBeneficialOwnerDetailsModal.beneficialOwnerTitle'
              )
        }
        withCloseButton={true}
        onRequestClose={closeModal}
      />
      <AgentOrBeneficialOwnerDetailsModalContent
        counterpartId={counterpartId}
        optionCode={optionCode}
      />
    </Modal>
  );
};

type AgentOrBeneficialOwnerDetailsModalContentProps = {
  counterpartId: number | null;
  optionCode: GetAgentOrBeneficialOwnerDetailsOptionCode | null;
};

const AgentOrBeneficialOwnerDetailsModalContent = (
  props: AgentOrBeneficialOwnerDetailsModalContentProps
) => {
  const investorId = useInvestorIdUrlParam();
  const { setInnerComponentApiError } = useInvestorDetailsContext();

  const getRequest = useGetJson<
    GetAgentOrBeneficialOwnerDetailsQuery,
    GetAgentOrBeneficialOwnerDetailsResponse
  >('/api/investors/GetAgentOrBeneficialOwnerDetails');

  const makeRequest = () => {
    if (investorId != null && props.counterpartId != null && props.optionCode != null) {
      getRequest.makeRequest({
        queryParameters: {
          option: props.optionCode,
          investorId,
          agentOrBeneficialOwnerId: props.counterpartId,
        },
        onFailure: setInnerComponentApiError,
      });
    }
  };

  useOnMount(() => {
    makeRequest();
  });

  return (
    <ApiRequestStateWrapper apiRequestState={getRequest.state} retry={makeRequest}>
      {(response) => (
        <CounterpartDetailsContainer>
          <CounterpartDetails counterpartDetails={response} />
        </CounterpartDetailsContainer>
      )}
    </ApiRequestStateWrapper>
  );
};

type GetAgentOrBeneficialOwnerDetailsQuery = {
  option: GetAgentOrBeneficialOwnerDetailsOptionCode;
  investorId: number;
  agentOrBeneficialOwnerId: number;
};

export type GetAgentOrBeneficialOwnerDetailsResponse = BaseCounterpartDetails & {
  dobDateStamp: IsoDatestamp | null;
  webAccess: string | null;
  webUserName: string | null;
};

export const getAgentOrBeneficialOwnerDetailsOptions = {
  agent: 'A',
  beneficialOwner: 'B',
} as const;

export type GetAgentOrBeneficialOwnerDetailsOptionCode =
  typeof getAgentOrBeneficialOwnerDetailsOptions[keyof typeof getAgentOrBeneficialOwnerDetailsOptions];

const CounterpartDetailsContainer = styled.div``;
