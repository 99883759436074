import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  ApiRequestState,
  requestFailed,
  requestIsInProgress,
  requestNotInitialised,
} from '../../infrastructure/api/ApiRequestState';
import { ButtonRow } from '../../infrastructure/interface/buttons/ButtonRow';
import { SecondaryButton } from '../../infrastructure/interface/buttons/SecondaryButton';
import { AlertHeader, AlertIcon } from '../../infrastructure/interface/components/Alert';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { colourBlack, colourGrey01 } from '../../styling/design/colours';
import { spacing32 } from '../../styling/design/spacing';
import { fadeIn } from '../../styling/design/transitions';
import { noop } from 'lodash';

type Props<TResponse> = {
  apiRequestState: ApiRequestState<TResponse>;
  children: (
    response: TResponse,
    showComponentLoadingIndicator: boolean,
    refreshData: () => void
  ) => React.ReactNode;
  refreshData?: () => void;
  retry?: () => void;
};

export const DashboardComponentApiRequestStateWrapper = <TResponse extends unknown>(
  props: Props<TResponse>
) => {
  const { translate } = useInternationalisation();

  // Once component has been rendered we hand over loading indicator responsibility to the component
  const [componentHasBeenRendered, setComponentHasBeenRendered] = useState<boolean>(false);

  // Store the latest non-null response to provide component while further requests are being made
  const [cachedResponse, setCachedResponse] = useState<TResponse | null>(null);

  const requestState = props.apiRequestState;
  const response = requestState.response;

  useEffect(() => {
    if (response != null) {
      setCachedResponse(response);
    }
  }, [response]);

  if (requestFailed(requestState) && !componentHasBeenRendered) {
    return (
      <RequestFailedContainer>
        <AlertHeader alertType="negative">
          <AlertIcon alertType="negative" />
          {translate('errors.apology')}
        </AlertHeader>
        {requestState.error}
        {props.retry != null && (
          <ButtonRow withMarginTop={true}>
            <SecondaryButton size="small" onClick={props.retry}>
              {translate('errors.tryAgain')}
            </SecondaryButton>
          </ButtonRow>
        )}
      </RequestFailedContainer>
    );
  }

  if (requestNotInitialised(requestState) && !componentHasBeenRendered) {
    return null;
  }

  if (requestIsInProgress(requestState) && !componentHasBeenRendered) {
    return (
      <SpinnerContainer>
        <CentredSpinner size="xlarge" colour={colourBlack} />
      </SpinnerContainer>
    );
  }

  // If we are here at least one succesfull response has been returned, so going forward we can
  // assume either cachedResponse, or response is non-null
  if (!componentHasBeenRendered) {
    setComponentHasBeenRendered(true);
  }

  // React effects occur after render so we cannot rely on cachedResponse to be updated to current
  // value of response.
  const responseToRender = response ?? cachedResponse!;

  // If request has failed here, that means we have rendered component and then done a further
  // failed request. We can just show loading indicator in component. This way component will still
  // be interactable and the user can attempt to make further requests
  const showComponentLoadingIndicator =
    requestIsInProgress(requestState) ||
    requestFailed(requestState) ||
    requestNotInitialised(requestState);

  return (
    <ComponentContainer>
      {props.children(responseToRender, showComponentLoadingIndicator, props.refreshData ?? noop)}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 0.25s ease;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colourGrey01};
  animation: ${fadeIn} 0.25s ease;
`;

const RequestFailedContainer = styled.div`
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 0.25s ease;
  padding: ${spacing32};
`;
