import React, { useContext } from 'react';
import { ReportParameterResponse } from '../../Report';
import { EquityAttributionSelectField } from '../../../metadata/equityAttribution/EquityAttributionSelectField';
import { defaultEquityAttributionSelectSettings } from '../../../metadata/equityAttribution/EquityAttributionSelectSettings';
import { useReportParameterFieldsContext } from '../ReportParameterFieldsContext';
import { ReportContext } from '../../ReportContext';

type Props = {
  equityAttributionParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsEquityAttributionSelectField = ({
  equityAttributionParameter,
  onError,
}: Props) => {
  const { setFieldLoaded } = useReportParameterFieldsContext();
  const { selectedCompanyOption } = useContext(ReportContext);

  return (
    <EquityAttributionSelectField
      settings={{
        ...defaultEquityAttributionSelectSettings,
        companyId: selectedCompanyOption?.companyId ?? null,
        includeBlank: true,
      }}
      fieldName={equityAttributionParameter.name}
      label={equityAttributionParameter.displayText}
      onError={onError}
      onLoaded={() => setFieldLoaded(equityAttributionParameter.name)}
    />
  );
};
