import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { useGroupIdQueryParam } from '../branding/useGroupIdQueryParam';
import { useState } from 'react';
import { Modal, ModalHeader } from '../../infrastructure/interface/components/Modal';
import styled from 'styled-components/macro';
import { colourPrimary05 } from '../design/colours';

export const SiteDisclaimers = () => {
  const groupId = useGroupIdQueryParam();

  const [disclaimers, setDisclaimers] = useState<Response | null>(null);

  const getRequest = useGetJson<Request, Response>('/api/authentication/GetSignInDisclaimers');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeDisclaimer, setActiveDisclaimer] = useState<ActiveDisclaimer>();

  useOnMount(() => {
    getRequest.makeRequest({
      queryParameters: { groupId },
      onSuccess: (response) => {
        setDisclaimers(response);
      },
    });
  });

  const handleDisclaimerClick = (disclaimer: string | null): void => {
    if (disclaimer == null) return;
    setIsModalOpen(true);

    switch (disclaimer) {
      case disclaimers?.disclaimer1Title:
        setActiveDisclaimer({
          title: disclaimers?.disclaimer1Title,
          body: disclaimers?.disclaimer1Body,
        });
        break;
      case disclaimers?.disclaimer2Title:
        setActiveDisclaimer({
          title: disclaimers?.disclaimer2Title,
          body: disclaimers?.disclaimer2Body,
        });
        break;
    }
  };

  if (disclaimers == null) return <></>;

  return (
    <>
      <DisclaimerLink onClick={() => handleDisclaimerClick(disclaimers.disclaimer1Title)}>
        {disclaimers?.disclaimer1Title}
      </DisclaimerLink>
      <DisclaimerLink onClick={() => handleDisclaimerClick(disclaimers.disclaimer2Title)}>
        {disclaimers?.disclaimer2Title}
      </DisclaimerLink>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
      >
        <ModalHeader title={activeDisclaimer?.title} />
        <div dangerouslySetInnerHTML={{ __html: activeDisclaimer?.body ?? '' }} />
      </Modal>
    </>
  );
};

type ActiveDisclaimer = {
  title: string | null | undefined;
  body: string | null | undefined;
};
type Request = {
  groupId: number | null;
};
type Response = {
  disclaimer1Title: string | null;
  disclaimer1Body: string | null;
  disclaimer2Title: string | null;
  disclaimer2Body: string | null;
};

const DisclaimerLink = styled.div`
  color: ${colourPrimary05};
  transition: opacity 0.25s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;
