import React, { useContext } from 'react';
import queryString from 'query-string';
import styled from 'styled-components/macro';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../AuthenticationContext';
import { spacing32 } from '../../../styling/design/spacing';
import { EnterUsernameAndPasswordForm } from './EnterUsernameAndPasswordForm';
import { UserDetails } from '../UserDetails';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { EnterMultiFactorAuthenticationCodeForm } from './EnterMultiFactorAuthenticationCodeForm';
import { fontWeightBold } from '../../../styling/design/fonts';
import { isMultiFactorAuthenticationSetupRequired } from '../AuthenticationResult';
import { MultiFactorAuthenticationPrompt } from '../multiFactorAuthentication/MultiFactorAuthenticationPrompt';
import { SetUpMultiFactorAuthentication } from '../multiFactorAuthentication/SetUpMultiFactorAuthentication';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { NotificationResponse } from '../../../styling/layout/sidebar/notifications/NotificationResponse';
import { NotificationsContext } from '../../../styling/layout/sidebar/notifications/NotificationsContext';
import { Logo } from '../../../styling/branding/Logo';
import { assertNotNull } from '../../../helpers/nullHelpers';
import { useSignInApiRequest } from './hooks/useSignInApiRequest';
import { useMfaTokenSignInApiRequest } from './hooks/useMfaTokenSignInApiRequest';
import { LoginTitle } from './LoginTitle';

export const Login = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.login.title'));
  const navigate = useNavigate();
  const authenticationContext = useContext(AuthenticationContext);
  const { setNotifications } = useContext(NotificationsContext);
  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);
  const sessionExpired = parsedQuery['sessionExpired'] || false;
  const showMessage = parsedQuery['message'] || false;
  const message = showMessage ? (parsedQuery['message'] as string) : '';

  /**
   * Handler - Updates the authentication context of the signed in user and redirects the browser if required.
   * @param userDetails
   * @param notifications
   */
  const onSignedIn = (userDetails: UserDetails, notifications: Array<NotificationResponse>) => {
    authenticationContext.setSignedIn();
    authenticationContext.setUser(userDetails);
    setNotifications(notifications);

    //check current url query params for a path to redirect to, if not - they will be navigated to the default route '/'
    const relativePathToReturnTo = decodeURIComponent((parsedQuery['returnTo'] as string) || '/');
    navigate('..' + relativePathToReturnTo);
  };

  const {
    signInWithUsernameAndPassword,
    requiresPasswordReset,
    requiresUsernameChange,
    requiresFirstTimePasswordReset,
    authenticationResult,
    setAuthenticationResult,
    signInRequestError,
  } = useSignInApiRequest(onSignedIn);
  const { onSubmitMfaToken, mfaRequestError } = useMfaTokenSignInApiRequest(onSignedIn);

  const requiresMfaSetup = isMultiFactorAuthenticationSetupRequired(authenticationResult);
  const onCancelMfaSetup = () => navigate('../');

  return (
    <>
      <Logo />
      <LoginTitle />
      {authenticationResult == null ? (
        <>
          {sessionExpired && (
            <HelpText data-testid={sessionExpiredHelpTextTestId}>
              {translate('pages.login.helpText.sessionExpired')}
            </HelpText>
          )}
          {showMessage && <HelpText>{translate(message)}</HelpText>}
          <EnterUsernameAndPasswordForm
            onSubmit={signInWithUsernameAndPassword}
            error={signInRequestError}
            requiresFirstTimePasswordReset={requiresFirstTimePasswordReset}
            requiresPasswordReset={requiresPasswordReset}
            requiresUsernameChange={requiresUsernameChange}
          />
        </>
      ) : requiresMfaSetup ? (
        <SetUpMultiFactorAuthentication
          authenticationResult={authenticationResult}
          onTwilioAuthySetupCompleted={() => setAuthenticationResult('TwilioAuthyMfaRequired')}
          onGoogleAuthenticatorSetupCompleted={(userDetails, notifications) =>
            onSignedIn(
              assertNotNull(userDetails, 'userDetails'),
              assertNotNull(notifications, 'notifications')
            )
          }
          cancel={onCancelMfaSetup}
        />
      ) : (
        <>
          <StyledMultiFactorAuthenticationPrompt authenticationResult={authenticationResult} />
          <EnterMultiFactorAuthenticationCodeForm
            onSubmit={onSubmitMfaToken}
            error={mfaRequestError}
          />
        </>
      )}
    </>
  );
};
export const sessionExpiredHelpTextTestId = 'session-expired';
const HelpText = styled.div`
  font-weight: ${fontWeightBold};
  margin-top: -${spacing32};
  margin-bottom: ${spacing32};
`;

const StyledMultiFactorAuthenticationPrompt = styled(MultiFactorAuthenticationPrompt)`
  margin-top: -${spacing32};
  margin-bottom: ${spacing32};
`;
