import React, { FC, useContext } from 'react';
import {
  ChildTableContainerTd,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../infrastructure/interface/tables/Table';
import { ChevronDownIcon, ChevronUpIcon } from '../../icons/icons';
import styled from 'styled-components/macro';
import { FundType } from './enums/FundType';
import { colourPrimary05 } from '../../styling/design/colours';
import { fontWeightBold } from '../../styling/design/fonts';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import {
  PrivateEquityFund,
  PrivateEquityHolding,
  TraditionalFund,
  TraditionalHolding,
} from './types';
import { IfUserHasRole } from '../authentication/UserRoles';
import { spacing16, spacing4 } from '../../styling/design/spacing';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { isNullUndefinedOrBlank } from '../../helpers/stringHelpers';
import { AppLink } from '../../infrastructure/interface/components/AppLink';
import { useTraditionalFundHoldingUrls } from './hooks/UseTraditionalFundHoldingUrls';
import { useTraditionalHoldingFormattedData } from './hooks/UseTraditionalHoldingFormattedData';
import { usePrivateEquityHoldingUrls } from './hooks/UsePrivateEquityHoldingUrls';
import { usePrivateEquityFormattedData } from './hooks/UsePrivateEquityFormattedData';

const headingKeys = {
  [FundType.TraditionalFund]: ['series', 'units', 'price', 'value', 'laterTransactions', 'total'],
  [FundType.PrivateEquityFund]: [
    'series',
    'committedCapital',
    'accumulatedCashFlow',
    'irr',
    'value',
  ],
};

export const isTraditionalFund = (fund: TraditionalFund | PrivateEquityFund) =>
  fund.type === FundType.TraditionalFund;

export const DesktopFund = ({ fund, expanded, onToggle }: Props) => {
  return (
    <>
      <CollapsedTableComponent fund={fund} expanded={expanded} onToggle={onToggle} />
      {expanded && <ExpandedTableComponent fund={fund} />}
    </>
  );
};

const CollapsedTableComponent: FC<Props> = ({ fund, expanded, onToggle }: Props) => {
  const { formatDate, formatNumber } = useInternationalisation();

  return (
    <Tr>
      <Td>
        <span onClick={onToggle}>
          <CollapsibleChevronComponent expanded={expanded} />
        </span>
      </Td>
      <IfUserHasRole userRole={['Manager', 'Consolidated Investor', 'Advisor']}>
        <Td>
          {fund.showInvestorDetailsLink ? (
            <AppLink to={fund.currentInvestorHideId ? `/investor-details/` : `/investor-details/${fund.investorId}`}>
              {fund.investorName}
              {!isNullUndefinedOrBlank(fund.investorNumber) && ` (${fund.investorNumber})`}
            </AppLink>
          ) : (
            <>
              {fund.investorName}
              {!isNullUndefinedOrBlank(fund.investorNumber) && ` (${fund.investorNumber})`}
            </>
          )}
        </Td>
      </IfUserHasRole>
      <Td>{fund.name}</Td>
      <Td>{formatDate(fund?.navDate ? fund.navDate.toString() : '')}</Td>
      <Td align="right">
        {fund.currency} {formatNumber(fund.total, { decimalPlaces: fund.currencyDecimals })}
      </Td>
    </Tr>
  );
};

const CollapsibleChevronComponent = ({ expanded }: { expanded: boolean }) => {
  return (
    <CollapsibleChevronContainer>
      {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </CollapsibleChevronContainer>
  );
};

const ExpandedTableComponent = ({ fund }: HoldingProps) => {
  const { getUser } = useContext(AuthenticationContext);
  const isInvestor = getUser().role === 'Investor';
  const colspan = isInvestor ? 4 : 5;

  return (
    <Tr>
      <ChildTableContainerTd colSpan={colspan}>
        <HoldingTableComponent fund={fund} />
      </ChildTableContainerTd>
    </Tr>
  );
};

const HoldingTableComponent = ({ fund }: HoldingProps) => {
  return (
    <Table>
      <HoldingTableHeaderComponent fund={fund} />
      <HoldingTableBodyComponent fund={fund} />
    </Table>
  );
};

const shouldDisplayIrrColumnForFund = (fund: TraditionalFund | PrivateEquityFund): boolean => {
  if (fund.type === FundType.TraditionalFund) return false;

  const privateEquityFund = fund as PrivateEquityFund;
  //check that the fund has at least one holding which has an Irr value defined.
  const peHoldingsWithIrr = privateEquityFund.holdings.filter(
    (holding) => holding.irr != null && !Number.isNaN(holding.irr)
  );

  return privateEquityFund.displayIrr && peHoldingsWithIrr.length > 0;
};

const HoldingTableHeaderComponent = ({ fund }: HoldingProps) => {
  const { translate } = useInternationalisation();
  const getPrefix = () => (isTraditionalFund(fund) ? 'traditional' : 'privateEquity');

  let headings = headingKeys[fund.type];
  if (!isTraditionalFund(fund) && !shouldDisplayIrrColumnForFund(fund)) {
    headings = headings.filter((h) => h !== 'irr');
  }

  return (
    <THead>
      <Tr>
        {headings.map((headingKey, i) => (
          <Th key={headingKey} style={{ width: `${100 / headings.length}%` }}>
            {translate(`pages.holdings.tables.${getPrefix()}Holdings.headings.${headingKey}`)}
          </Th>
        ))}
      </Tr>
    </THead>
  );
};

const HoldingTableBodyComponent = ({ fund }: HoldingProps) => {
  return (
    <TBody>
      {fund.holdings.map((holding, index) => {
        if (isTraditionalFund(fund)) {
          const traditionalHolding = holding as TraditionalHolding;
          const traditionalFund = fund as TraditionalFund;

          return (
            <TraditionalHoldingTableRowComponent
              key={index}
              holding={traditionalHolding}
              fund={traditionalFund}
            />
          );
        }

        const privateEquityHolding = holding as PrivateEquityHolding;
        const privateEquityFund = fund as PrivateEquityFund;
        return (
          <PrivateEquityHoldingTableRowComponent
            key={index}
            holding={privateEquityHolding}
            fund={privateEquityFund}
          />
        );
      })}
    </TBody>
  );
};

const PrivateEquityHoldingTableRowComponent = ({
  holding,
  fund,
}: PrivateEquityHoldingTableRowProps) => {
  const { valueUrl } = usePrivateEquityHoldingUrls(holding, fund);
  const { committedCapital, accumulatedCashFlow, value, irrPercentage } =
    usePrivateEquityFormattedData(holding);

  if (holding === null || fund === null) return null;
  return (
    <HoldingTr>
      <Td>{holding.series}</Td>
      <Td>{committedCapital}</Td>
      <Td>{accumulatedCashFlow}</Td>
      {shouldDisplayIrrColumnForFund(fund) ? (
        <Td>{holding.irr == null || Number.isNaN(holding.irr) ? '' : irrPercentage}</Td>
      ) : null}
      <Td>
        <AppLink to={valueUrl}>{value}</AppLink>
      </Td>
    </HoldingTr>
  );
};

const TraditionalHoldingTableRowComponent = ({
  holding,
  fund,
}: TraditionalHoldingTableRowProps) => {
  const { valueUrl, laterTransactionsUrl, totalUrl } = useTraditionalFundHoldingUrls(holding, fund);
  const { units, price, value, laterTransactions, total } = useTraditionalHoldingFormattedData(
    holding,
    fund
  );

  if (holding === null || fund === null) return null;

  return (
    <HoldingTr>
      <Td>{holding.series}</Td>
      <Td>{units}</Td>
      <Td>{price}</Td>
      <Td>
        <AppLink to={valueUrl}> {value}</AppLink>
        {holding.hasEqualisationAdjustment && '*'}
      </Td>
      <Td>
        <AppLink to={laterTransactionsUrl}> {laterTransactions}</AppLink>
      </Td>
      <Td>
        <AppLink to={totalUrl}> {total}</AppLink>
      </Td>
    </HoldingTr>
  );
};

/* Types */
type TraditionalHoldingTableRowProps = {
  holding: TraditionalHolding;
  fund: TraditionalFund;
};

type PrivateEquityHoldingTableRowProps = {
  holding: PrivateEquityHolding;
  fund: PrivateEquityFund;
};

export type Props = {
  fund: TraditionalFund | PrivateEquityFund;
  expanded: boolean;
  onToggle: () => void;
};

type HoldingProps = {
  fund: TraditionalFund | PrivateEquityFund;
};

/* Styled Divs */
const CollapsedTotal = styled.span`
  color: ${colourPrimary05};
  font-weight: ${fontWeightBold};
`;

const CollapsibleChevronContainer = styled.span`
  float: left;
  margin-right: ${spacing4};
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.75;
  }

  svg {
    position: relative;
    top: 2px;
    height: ${spacing16};
    width: ${spacing16};
  }
`;

const HoldingTr = styled(Tr)`
  border-left: none;
  border-right: none;

  &:last-of-type {
    border-bottom: none;
  }

  ${Td} {
    &:first-of-type {
      border-left: none;
    }

    &:last-of-type {
      border-right: none;
    }
  }
`;
