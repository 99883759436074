import React, { useState } from 'react';
import { useDeleteJson } from '../../../infrastructure/api/useDeleteJson';
import { ButtonSize } from '../../../infrastructure/interface/buttons/BaseButton';
import { DeleteButton } from '../../../infrastructure/interface/buttons/DeleteButton';
import { ActionAlert } from '../../../infrastructure/interface/components/ActionAlert';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';

type Props = {
  dashboardId: number;
  onDashboardDeleted: () => void;
  size: ButtonSize;
};

export const DashboardDeleteButton = ({ dashboardId, onDashboardDeleted, size }: Props) => {
  const { translate } = useInternationalisation();

  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const openAlert = () => setAlertIsOpen(true);
  const closeAlert = () => setAlertIsOpen(false);

  const apiRequest = useDeleteJson<DeleteDashboardCommand>(`/api/dashboards/DeleteDashboard`);

  const deleteDocument = () => {
    apiRequest.makeRequest({
      requestBody: { dashboardId },
      onSuccess: () => {
        closeAlert();
        onDashboardDeleted();
      },
    });
  };

  const { inProgress, error } = apiRequest.state;

  return (
    <>
      <DeleteButton
        onClick={openAlert}
        type="button"
        size={size}
        data-testid={dashboardDeleteButtonTestId(dashboardId)}
      />
      <ActionAlert
        alertType="negative"
        isOpen={alertIsOpen}
        onRequestClose={closeAlert}
        title={translate('pages.dashboards.deleteConfirmationAlert.title')}
        message={translate('pages.dashboards.deleteConfirmationAlert.message')}
        cancelButtonText={translate('actionButtons.cancel')}
        confirmButtonText={translate('actionButtons.delete')}
        onInitiateAction={deleteDocument}
        actionInProgress={inProgress}
        actionError={error}
      />
    </>
  );
};

type DeleteDashboardCommand = {
  dashboardId: number;
};

export const dashboardDeleteButtonTestId = (dashboardId: number) =>
  `delete-dashboard-${dashboardId}-button`;
