import { useQueryParam } from '../../infrastructure/hooks/useQueryParam';
import {
  readSessionStorageNumber,
  sessionStorageCodes,
} from '../../infrastructure/hooks/useSessionStorage';
import queryString from 'query-string';

export const groupIdQueryParam = 'GroupId';

export const useGroupIdQueryParam = (): number | null => {
  let valueAsNumber: number = Number.parseInt(useQueryParam(groupIdQueryParam));
  if (Number.isNaN(valueAsNumber)) {
    //check value from session storage.
    let valueFromSessionStorage: number | null = readSessionStorageNumber(
      sessionStorageCodes.groupId
    );
    if (valueFromSessionStorage != null) return valueFromSessionStorage;
    else return null;
  }

  //store the value in sessionStorage.
  sessionStorage.setItem(sessionStorageCodes.groupId, valueAsNumber.toString());

  return valueAsNumber;
};

export const siteIdQueryParam = 'SiteId';
export const useSiteIdQueryParam = (): number | null => {
  // Unfortunately this has to be handled manually and not using the location/useQueryParam hook as SiteContextProvider
  // is not contained within a Router component.
  const queryStringFromUrl = window.location.search;
  const parsedSearch = queryString.parse(queryStringFromUrl);
  const siteIdFromUrl = parsedSearch[siteIdQueryParam];
  const siteIdAsStringOrNull = Array.isArray(siteIdFromUrl) ? siteIdFromUrl[0] : siteIdFromUrl;

  let valueAsNumber: number = Number.parseInt(siteIdAsStringOrNull ?? '');
  if (Number.isNaN(valueAsNumber)) {
    //check value from session storage.
    let valueFromSessionStorage: number | null = readSessionStorageNumber(
      sessionStorageCodes.siteId
    );
    if (valueFromSessionStorage != null) return valueFromSessionStorage;
    else return null;
  }

  //store the value in sessionStorage.
  sessionStorage.setItem(sessionStorageCodes.siteId, valueAsNumber.toString());
  console.log(`Site Id: ${valueAsNumber}`);
  return valueAsNumber;
};
