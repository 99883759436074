import React, { useContext, useState } from 'react';
import queryString from 'query-string';
import { Header2 } from '../../infrastructure/interface/components/Headers';
import { Formik, FormikHelpers } from 'formik';
import { InputField } from '../../infrastructure/forms/fields/InputField';
import { SubmitButton } from '../../infrastructure/forms/common/SubmitButton';
import { Form } from '../../infrastructure/forms/common/Form';
import { useLocation } from 'react-router-dom';
import { usePostJson } from '../../infrastructure/api/usePostJson';
import { Validator } from 'fluentvalidation-ts';
import { ErrorMessage } from '../../infrastructure/interface/components/ErrorMessage';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { SuccessMessage } from '../../infrastructure/interface/components/SuccessMessage';
import { AuthenticationResult } from './AuthenticationResult';
import { MultiFactorAuthenticationPrompt } from './multiFactorAuthentication/MultiFactorAuthenticationPrompt';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { notBeEmpty } from '../validation/stringValidation';
import { AppLink } from '../../infrastructure/interface/components/AppLink';
import { AuthenticationContext } from './AuthenticationContext';
import { MinimalCountdownButton } from '../../infrastructure/interface/buttons/MinimalCountdownButton';

export const RequestPasswordReset = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.requestPasswordReset.title'));

  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);
  const authenticationContext = useContext(AuthenticationContext);
  const currentUser = authenticationContext.isAuthenticated()
    ? authenticationContext.getUser()
    : null;
  let usernamevar = decodeURIComponent((parsedQuery['username'] as string) || '');

  if (usernamevar === '' && currentUser) {
    usernamevar = currentUser.name;
  }

  const [username, setUsername] = useState(usernamevar);

  const apiRequest = usePostJson<RequestPasswordResetCommand, RequestPasswordResetResponse>(
    '/api/authentication/RequestPasswordReset'
  );

  const [success, setSuccess] = useState(false);
  const [usernameChanged, setUsernameChanged] = useState(false);
  const [authenticationResult, setAuthenticationResult] = useState<AuthenticationResult | null>(
    null
  );



  const onSubmit = (
    formModel: RequestPasswordResetFormModel,
    formikHelpers: FormikHelpers<RequestPasswordResetFormModel>
  ) => {
    apiRequest.makeRequest({
      requestBody: getRequestPasswordResetCommand(formModel),
      onSuccess: (response) => {
        if (response.authenticationResult === 'UsernameChangedRemovedSpaces') {
          setUsernameChanged(true);
        }

        if (!response.multiFactorAuthenticationRequired) {
          setSuccess(true);
        } else if (response.multiFactorAuthenticationRequired) {
          setAuthenticationResult(response.authenticationResult);
          formikHelpers.setSubmitting(false);
        }
      },
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  const requiresMultiFactorAuthentication = authenticationResult != null;

  const requestPasswordResetFormModelValidator = new RequestPasswordResetFormModelValidator(
    translate,
    requiresMultiFactorAuthentication
  );

  const resendOtpRequest = () => {
    apiRequest.makeRequest({
      requestBody: {
        username: username,
        multiFactorAuthenticationToken: null,
      }
    })
  };

  return (
    <>
      <Header2>{translate('pages.requestPasswordReset.header')}</Header2>
      {!success && (
        <>
          {!requiresMultiFactorAuthentication && (
            <>
              <p>{translate('pages.requestPasswordReset.helpText.enterYourUsername')}</p>
              <p>{translate('pages.requestPasswordReset.helpText.weWillEmailYou')}</p>
            </>
          )}
          {requiresMultiFactorAuthentication && (
            <MultiFactorAuthenticationPrompt authenticationResult={authenticationResult} />
          )}
          <Formik<RequestPasswordResetFormModel>
            initialValues={{ username, multiFactorAuthenticationToken: '' }}
            onSubmit={onSubmit}
            validate={requestPasswordResetFormModelValidator.validate}
          >
            <Form data-testid={requestPasswordResetFormTestId}>
              <InputField
                fieldName="username"
                label={translate('pages.requestPasswordReset.labels.username')}
                disabled={success || requiresMultiFactorAuthentication}
                onFieldValueChange={setUsername}
              />
              {authenticationResult && (
                <InputField
                  type="phone" // Gives a better UX on mobile
                  fieldName="multiFactorAuthenticationToken"
                  label={translate(
                    'pages.requestPasswordReset.labels.multiFactorAuthenticationCode'
                  )}
                  disabled={success}
                />
              )}
              <SubmitButton
                submittingText={translate('pages.requestPasswordReset.button.waitingText')}
                stretch={true}
                disabled={success}
              >
                {translate('pages.requestPasswordReset.button.text')}
              </SubmitButton>
              {authenticationResult && (
                <MinimalCountdownButton
                  buttonText={translate('authentication.resendOTP')}
                  countdownInSeconds= {30}
                  clickRequest={resendOtpRequest}
                ></MinimalCountdownButton>
              )}
              {apiRequest.state.error != null && (
                <ErrorMessage>{apiRequest.state.error}</ErrorMessage>
              )}
            </Form>
          </Formik>
        </>
      )}
      {success && usernameChanged && <>{translate('pages.login.usernameChangeRequired')}</>}
      {success && !usernameChanged && (
        <>
          <SuccessMessage>{translate('pages.requestPasswordReset.successMessage')}</SuccessMessage>
          {currentUser == null && (
            <AppLink to="../login">{translate('pages.requestPasswordReset.loginLink')}</AppLink>
          )}
        </>
      )}
    </>
  );
};

export const requestPasswordResetFormTestId = 'request-password-reset-form';

export type RequestPasswordResetFormModel = {
  username: string;
  multiFactorAuthenticationToken: string;
};

class RequestPasswordResetFormModelValidator extends Validator<RequestPasswordResetFormModel> {
  constructor(translate: TranslateFunction, requiresMultiFactorAuthentication: boolean) {
    super();

    this.ruleFor('username').must(notBeEmpty(translate));

    this.ruleFor('multiFactorAuthenticationToken')
      .must(notBeEmpty(translate))
      .when(() => requiresMultiFactorAuthentication);
  }
}

export type RequestPasswordResetCommand = {
  username: string;
  multiFactorAuthenticationToken: string | null;
};

export type RequestPasswordResetResponse = {
  multiFactorAuthenticationRequired: boolean;
  authenticationResult: AuthenticationResult | null;
};

const getRequestPasswordResetCommand = (
  formModel: RequestPasswordResetFormModel
): RequestPasswordResetCommand => ({
  username: formModel.username,
  multiFactorAuthenticationToken: formModel.multiFactorAuthenticationToken || null,
});
