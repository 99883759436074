import { parse, stringify, ParsedQuery } from 'query-string';
import { isArray } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce, isEqual } from 'lodash';
import { useIsMounted } from './useIsMounted';

export type UrlStateValueType = {
  type: 'string' | 'string[]' | 'number' | 'number[]' | 'boolean';
  isNullable?: boolean;
};

type UrlStateValueTypes<TValue extends object> = Record<keyof TValue, UrlStateValueType>;

export const useUrlState = <TValue extends object>(
  defaultValue: TValue,
  valueTypes: UrlStateValueTypes<TValue>
) => {
  const isMounted = useIsMounted();

  const location = useLocation();

  const queryString = location.search;
  const parsedQuery = parse(queryString);

  const derivedState = mapParsedQueryToValue(defaultValue, valueTypes, parsedQuery);

  const [state, setState] = useState<TValue>(derivedState);

  const updateState = useCallback(
    (partialState: Partial<TValue>) => {
      if (isMounted.current) {
        setState((previous) => ({
          ...previous,
          ...partialState,
        }));
      }
    },
    [setState, isMounted]
  );

  const navigate = useNavigate();

  // Simultaneous updates to the internal state can cause race conditions which
  // lead the URL state to get out of sync. To mitigate this issue we slightly
  // debounce any URL updates.
  const debouncedUpdateUrl = useRef(
    debounce(
      (newState) => {
        navigate(location.pathname + '?' + stringify(newState));
      },
      100,
      { leading: false }
    )
  );

  // Update the URL in response to internal state updates
  useEffect(() => {
    if (!isEqual(state, derivedState)) {
      debouncedUpdateUrl.current(state);
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the internal state in response to URL changes
  useEffect(() => {
    if (!isEqual(state, derivedState)) {
      setState(derivedState);
    }
  }, [queryString]); // eslint-disable-line react-hooks/exhaustive-deps

  const useUrlStateValue = <TKey extends keyof TValue>(key: TKey) =>
    [
      state[key] as TValue[TKey],
      (newValue: TValue[TKey]) => updateState({ [key]: newValue } as any as Partial<TValue>),
    ] as [TValue[TKey], (newValue: TValue[TKey]) => void];

  return { state, setState, updateState, useUrlStateValue };
};

const mapParsedQueryToValue = <TValue extends object>(
  defaultValue: TValue,
  valueTypes: UrlStateValueTypes<TValue>,
  parsedQuery: ParsedQuery
): TValue => {
  const value: TValue = { ...defaultValue };

  for (const key of Object.keys(defaultValue)) {
    const valueType: UrlStateValueType = (valueTypes as any)[key];
    const queryValue = key in parsedQuery ? (parsedQuery[key] as string | null) : undefined;
    const parsedQueryValue = parseQueryValue(queryValue, valueType);

    if (parsedQueryValue !== undefined) {
      (value as any)[key] = parsedQueryValue;
    }
  }

  return value;
};

export const parseQueryValue = (
  queryValue: string | null | undefined,
  valueType: UrlStateValueType
) => {
  if (queryValue === undefined) {
    return undefined;
  }

  if (queryValue == null && valueType.isNullable) {
    return null;
  }

  switch (valueType.type) {
    case 'string':
      return queryValue;
    case 'string[]':
      return isArray(queryValue) ? queryValue : [queryValue];
    case 'number':
      return Number(queryValue);
    case 'number[]':
      return isArray(queryValue) ? queryValue.map(Number) : [Number(queryValue)];
    case 'boolean':
      return queryValue === 'true';
    default:
      throw new Error(`Encountered unrecognised URL state value type: ${valueType.type}`);
  }
};
