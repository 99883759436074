import React, { ErrorInfo } from 'react';
import styled from 'styled-components/macro';
import {
  colourGrey01,
  colourGrey03,
  colourGrey05,
  colourGrey06,
  colourGrey07,
  colourGrey09,
  colourWhite,
} from '../../styling/design/colours';
import { spacing4, spacing8, spacing16, spacing24 } from '../../styling/design/spacing';
import { logClientError } from './errorHelpers';

type Props = {
  children?: React.ReactNode;
};

type State = {
  details: { error: Error; errorInfo: ErrorInfo } | null;
  showDetails: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = { details: null, showDetails: false };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ details: { error, errorInfo } });

    const errorMessage = error.message;
    const stackTrace = errorInfo.componentStack;
    logClientError(errorMessage, stackTrace, /* translate: */ null, /* siteId: */ null);
  }

  toggleShowDetails = () => this.setState((state) => ({ showDetails: !state.showDetails }));

  render() {
    if (this.state.details != null) {
      return (
        <Page>
          <Header>Sorry, something went wrong</Header>
          <p>An unexpected error has caused the application to crash.</p>
          <p>Please refresh the page and try again.</p>
          <RefreshButton onClick={() => window.location.reload()}>Refresh</RefreshButton>
          <ToggleDetailsButton onClick={this.toggleShowDetails}>
            {this.state.showDetails ? 'Hide' : 'Show'} details
          </ToggleDetailsButton>
          {this.state.showDetails && (
            <DetailsPanel data-testid={errorBoundaryDetailsPaneTestId}>
              <pre>{this.state.details.error.toString()}</pre>
              <pre>{this.state.details.errorInfo.componentStack}</pre>
            </DetailsPanel>
          )}
        </Page>
      );
    }

    return this.props.children;
  }
}

const Page = styled.div`
  background-color: ${colourWhite};
  padding: 20px;
`;

const Header = styled.h1`
  margin-top: 0;
`;

const ToggleDetailsButton = styled.button`
  width: 110px;
  outline: none;
  background: ${colourWhite};
  color: ${colourGrey05};
  border: solid 1px ${colourGrey03};
  border-radius: ${spacing4};
  margin-top: ${spacing16};
  padding: ${spacing8};
  cursor: pointer;
  transition: color 0.25s ease, border-color 0.25s ease;

  &:hover {
    color: ${colourGrey09};
    border-color: ${colourGrey09};
  }
`;

const DetailsPanel = styled.div`
  padding: ${spacing24};
  margin-top: ${spacing16};
  background-color: ${colourGrey01};
  overflow-x: scroll;

  pre {
    margin: 0;
  }
`;

const RefreshButton = styled.button`
  outline: none;
  background: ${colourGrey07};
  color: ${colourGrey01};
  border: solid 1px ${colourGrey07};
  border-radius: ${spacing4};
  margin-top: ${spacing16};
  padding: ${spacing8};
  cursor: pointer;
  transition: background-color 0.25s ease, border-color 0.25s ease;
  margin-right: ${spacing8};

  &:hover {
    border-color: ${colourGrey06};
    background: ${colourGrey06};
  }
`;

export const errorBoundaryDetailsPaneTestId = 'error-boundary-details-pane';
