import React, { useState } from 'react';
import { CompanyDropdownOptionResponse } from '../metadata/company/GetCompanyDropdownOptionsResponse';
import { CompanyReportGroupDropdownOptionResponse } from '../metadata/companyReportGroup/GetCompanyReportGroupDropdownOptionsResponse';

type ReportContextValue = {
  selectedCompanyOption: CompanyDropdownOptionResponse | null;
  setSelectedCompanyOption: (option: CompanyDropdownOptionResponse | null) => void;
  selectedCompanyReportGroupOption: CompanyReportGroupDropdownOptionResponse | null;
  setSelectedCompanyReportGroupOption: (
    option: CompanyReportGroupDropdownOptionResponse | null
  ) => void;
};

const throwNotInitialisedError = () => {
  throw new Error('ReportContext has not yet been initialised');
};

export const ReportContext = React.createContext<ReportContextValue>({
  selectedCompanyOption: null,
  setSelectedCompanyOption: throwNotInitialisedError,
  selectedCompanyReportGroupOption: null,
  setSelectedCompanyReportGroupOption: throwNotInitialisedError,
});

type Props = {
  children: React.ReactNode;
};

export const ReportContextProvider = ({ children }: Props) => {
  const [selectedCompanyOption, setSelectedCompanyOption] =
    useState<CompanyDropdownOptionResponse | null>(null);

  const [selectedCompanyReportGroupOption, setSelectedCompanyReportGroupOption] =
    useState<CompanyReportGroupDropdownOptionResponse | null>(null);

  return (
    <ReportContext.Provider
      value={{
        selectedCompanyOption,
        setSelectedCompanyOption,
        selectedCompanyReportGroupOption,
        setSelectedCompanyReportGroupOption,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
