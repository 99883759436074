import { css } from 'styled-components/macro';
import { onDesktop, onMobile } from '../layout/screenBreakpoints';

export const fontFamily = `var(--font-family), 'Lato', sans-serif`;

export const fontWeightLight = 300;
export const fontWeightMedium = 400;
export const fontWeightBold = 700;

export const lineHeightMedium = 1.6;

export const fontSizeCss = (fontScale: FontScale) => css`
  ${onDesktop(css`
    font-size: ${fontSizes[fontScale].desktop};
  `)}

  ${onMobile(css`
    font-size: ${fontSizes[fontScale].mobile};
  `)}
`;

export const fontSizes: Record<FontScale, ResponsiveFontSize> = {
  xxsmall: {
    desktop: '10px',
    mobile: '10px',
  },
  xsmall: {
    desktop: '12px',
    mobile: '12px',
  },
  small: {
    desktop: '14px',
    mobile: '14px',
  },
  medium: {
    desktop: '16px',
    mobile: '16px',
  },
  large: {
    desktop: '18px',
    mobile: '18px',
  },
  xlarge: {
    desktop: '20px',
    mobile: '20px',
  },
  xxlarge: {
    desktop: '28px',
    mobile: '22px',
  },
  xxxlarge: {
    desktop: '38px',
    mobile: '24px',
  },
  xxxxlarge: {
    desktop: '46px',
    mobile: '26px',
  },
};

type ResponsiveFontSize = {
  desktop: string;
  mobile: string;
};

export type FontScale =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
