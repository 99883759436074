import React from 'react';
import { DeleteIcon } from '../../../icons/icons';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { ButtonSize, NativeButtonProps } from './BaseButton';
import { MinimalButton } from './MinimalButton';

type Props = NativeButtonProps & { size?: ButtonSize };

export const DeleteButton = ({ size = 'medium', ...nativeButtonProps }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <MinimalButton size={size} icon={<DeleteIcon />} buttonStyle="negative" {...nativeButtonProps}>
      {translate('actionButtons.delete')}
    </MinimalButton>
  );
};
