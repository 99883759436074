import React from 'react';
import { InvestorSelect, InvestorSelectOptionValue } from './InvestorSelect';
import { InvestorSelectSettings } from './InvestorSelectSettings';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';
import { GetInvestorDropdownOptionsResponse } from './GetInvestorDropdownOptionsResponse';

export type InvestorSelectFieldProps = MetadataSingleSelectFieldComponentProps<
  InvestorSelectOptionValue,
  InvestorSelectSettings,
  GetInvestorDropdownOptionsResponse
>;

export const InvestorSelectField = (props: InvestorSelectFieldProps) => (
  <MetadataSingleSelectField<
    InvestorSelectOptionValue,
    InvestorSelectSettings,
    GetInvestorDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => <InvestorSelect {...metadataSingleSelectProps} />}
  />
);
