import { createGlobalStyle } from 'styled-components/macro';
import { fontFamily, fontSizeCss, lineHeightMedium } from './design/fonts';
import { textColours } from './design/colours';

export const GlobalStyles = createGlobalStyle`
  * {
      font-family: ${fontFamily};
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  body {
      margin: 0;
      padding: 0;
      ${fontSizeCss('medium')};
      color: ${textColours.default};
  }

  pre {
      font-family: monospace;
      ${fontSizeCss('small')};
  }
  
  p, div {
      line-height: ${lineHeightMedium};
  }
`;
