import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { RequiresUserRole } from '../../authentication/UserRoles';
import styled from 'styled-components/macro';
import { spacing32 } from '../../../styling/design/spacing';
import { CentredPaddedPage } from '../../../styling/layout/PaddedPage';
import { Header1 } from '../../../infrastructure/interface/components/Headers';
import { BackButton } from '../../../infrastructure/interface/components/BackButton';
import { CreateUserForm } from './CreateUserForm';

export const CreateUser = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.createUser.title'));
  return (
    <RequiresUserRole userRole="Administrator">
      <CreateUserComponent />
    </RequiresUserRole>
  );
};

const CreateUserComponent = () => {
  const { translate } = useInternationalisation();

  return (
    <CentredPaddedPage>
      <Header1>{translate('pages.createUser.header')}</Header1>
      <BackButton />
      <FormContainer>
        <CreateUserForm />
      </FormContainer>
    </CentredPaddedPage>
  );
};

const FormContainer = styled.div`
  padding: ${spacing32} 0;
`;
