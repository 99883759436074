import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetCounterpartDropdownOptionsResponse = {
  options: Array<CounterpartDropdownOptionResponse>;
};

export type CounterpartDropdownOptionResponse = {
  counterpartId: number;
  counterpartCode: string;
};

export const mapCounterpartDropdownOptionsResponseToSelectOptions = (
  response: GetCounterpartDropdownOptionsResponse | null
): SelectOptions<number> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.counterpartId,
        label: option.counterpartCode,
      }));
