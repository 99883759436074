import React, { useContext, useEffect, useState } from 'react';
import { Panel } from '../../infrastructure/interface/components/Panel';
import styled from 'styled-components/macro';
import { spacing16, spacing32 } from '../../styling/design/spacing';
import { Header2 } from '../../infrastructure/interface/components/Headers';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { Input } from '../../infrastructure/interface/forms/Input';
import {
  defaultInitialResultsPerPageOption,
  PaginatedTable,
} from '../../infrastructure/interface/tables/PaginatedTable';
import { GetUsersQuery, GetUsersResponse } from './Users';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import {
  NoResultsRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../infrastructure/interface/tables/Table';
import { isEmpty } from 'lodash';
import { LockClosedIcon, LockOpenIcon } from '../../icons/icons';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { DeleteUserButton } from './DeleteUserButton';
import { RequestFailedAlert } from '../../infrastructure/api/RequestFailedAlert';
import { AppLink } from '../../infrastructure/interface/components/AppLink';
import { colourBlack, colourGrey02 } from '../../styling/design/colours';

export const ViewUsers = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(defaultInitialResultsPerPageOption);
  const [inputBoundSearchString, setInputBoundSearchString] = useState('');
  const onChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageNumber(1);
    setInputBoundSearchString(event.target.value);
  };
  const [latestResponse, setLatestResponse] = useState<GetUsersResponse | null>(null);
  const getUsersApiRequest = useGetJson<GetUsersQuery, GetUsersResponse>(
    '/api/users/GetDataForUsersPage'
  );

  const { error, inProgress } = getUsersApiRequest.state;

  const loadData = () => {
    const query: GetUsersQuery = {
      pageNumber,
      resultsPerPage,
      filter: inputBoundSearchString || '',
    };

    getUsersApiRequest.makeRequest({
      queryParameters: query,
      onSuccess: setLatestResponse,
    });
  };
  useEffect(
    () => {
      loadData();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageNumber, resultsPerPage, inputBoundSearchString]
  );

  if (error) {
    return <RequestFailedAlert error={getUsersApiRequest.state.error} retry={loadData} />;
  }

  return (
    <Panel>
      <FilterComponent
        inputBoundSearchString={inputBoundSearchString}
        onChangeSearchInput={onChangeSearchInput}
      />
      <TableComponent
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        resultsPerPage={resultsPerPage}
        setResultsPerPage={setResultsPerPage}
        inProgress={inProgress}
        response={latestResponse}
        loadData={loadData}
      />
    </Panel>
  );
};

type FilterComponentProps = {
  inputBoundSearchString: string;
  onChangeSearchInput(event: React.ChangeEvent<HTMLInputElement>): void;
};
const FilterComponent = ({ inputBoundSearchString, onChangeSearchInput }: FilterComponentProps) => {
  const { translate } = useInternationalisation();

  return (
    <PanelHeaderRow>
      <SearchInput
        onChange={onChangeSearchInput}
        value={inputBoundSearchString}
        placeholder={translate('pages.users.filters.searchString')}
        data-testid={usersPageSearchInputTestId}
      />
    </PanelHeaderRow>
  );
};

type TableComponentProps = {
  pageNumber: number;
  setPageNumber(pageNumber: number): void;
  resultsPerPage: number;
  setResultsPerPage(resultsPerPage: number): void;
  inProgress: boolean;
  response: GetUsersResponse | null;
  loadData(): void;
};
const TableComponent = ({
  pageNumber,
  setPageNumber,
  resultsPerPage,
  setResultsPerPage,
  response,
  inProgress,
  loadData,
}: TableComponentProps) => {
  return (
    <PaginatedTable
      showLoadingOverlay={inProgress}
      resultsPerPage={resultsPerPage}
      totalResultsCount={response?.total || 0}
      onChangeResultsPerPage={setResultsPerPage}
      currentPageNumber={pageNumber}
      onChangeCurrentPageNumber={setPageNumber}
    >
      <Table>
        <TableHeaderComponent />
        <TableBodyComponent response={response} loadData={loadData} />
      </Table>
    </PaginatedTable>
  );
};

const TableHeaderComponent = () => {
  const { translate } = useInternationalisation();

  return (
    <THead>
      <Tr>
        <Th>{translate('pages.users.table.viewUsers.headings.username')}</Th>
        <Th>{translate('pages.users.table.viewUsers.headings.counterpartId')}</Th>
        <Th>{translate('pages.users.table.viewUsers.headings.counterpart')}</Th>
        <Th>{translate('pages.users.table.viewUsers.headings.role')}</Th>
        <Th>{translate('pages.users.table.viewUsers.headings.accessGroup')}</Th>
        <Th>{translate('pages.users.table.viewUsers.headings.creationDate')}</Th>
        <Th>{translate('pages.users.table.viewUsers.headings.lastLogin')}</Th>
        <Th>{translate('pages.users.table.viewUsers.headings.lockedOut')}</Th>
        <Th>&nbsp;</Th>
      </Tr>
    </THead>
  );
};

type TableBodyComponentProps = {
  response: GetUsersResponse | null;
  loadData(): void;
};
const TableBodyComponent = ({ response, loadData }: TableBodyComponentProps) => {
  const { formatDate } = useInternationalisation();
  const { getUser } = useContext(AuthenticationContext);

  if (!response || isEmpty(response?.users)) {
    return (
      <TBody>
        <NoResultsRow colSpan={9} />
      </TBody>
    );
  }

  return (
    <TBody>
      {response.users.map((user) => (
        <Tr key={user.userId}>
          <Td>
            <AppLink to={`/users/${user.userId}/edit`}>{user.username}</AppLink>
          </Td>
          <Td>{user.counterpartId}</Td>
          <Td>{user.counterpart}</Td>
          <Td>{user.role}</Td>
          <Td>{user.accessGroup}</Td>
          <Td>{formatDate(user.creationDate.toString())}</Td>
          <Td>{user.lastLogin != null && formatDate(user.lastLogin.toString())}</Td>
          <Td>
            <LockedOutIconContainer disabled={!user.isLockedOut}>
              {user.isLockedOut ? <LockClosedIcon /> : <LockOpenIcon />}
            </LockedOutIconContainer>
          </Td>
          <Td>
            {user.username !== getUser().name && user.username.toLowerCase() !== 'admin' && (
              <DeleteUserButton userId={user.userId} onUserDeleted={loadData} />
            )}
          </Td>
        </Tr>
      ))}
    </TBody>
  );
};

const PanelHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${spacing32};

  ${Header2} {
    margin: 0;
  }
`;

const SearchInput = styled(Input)`
  width: 300px;
`;

const LockedOutIconContainer = styled.span<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? colourGrey02 : colourBlack)};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  svg {
    height: ${spacing16};
    width: ${spacing16};
  }
`;

export const usersPageSearchInputTestId = 'users-page-viewUsers-search-input';
