import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { CustomReportParameter, ReportViewer, ReportViewerProps } from './ReportViewer';
import { Form } from '../../infrastructure/forms/common/Form';
import { SubmitButton } from '../../infrastructure/forms/common/SubmitButton';
import styled from 'styled-components/macro';
import { spacing16, spacing32 } from '../../styling/design/spacing';
import { Header2 } from '../../infrastructure/interface/components/Headers';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { ReportResponse } from './Reports';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { usePostJson } from '../../infrastructure/api/usePostJson';
import {
  ReportParameterTypeCode,
  reportParameterTypeCodesByType,
} from './parameters/ReportParameterType';
import { Validator } from 'fluentvalidation-ts';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { ReportParameterFields } from './parameters/ReportParameterFields';
import { ReportsPlaceholder } from './ReportsPlaceholder';
import { usePrevious } from '../../infrastructure/hooks/usePrevious';
import { Tabs } from '../../infrastructure/interface/components/Tabs';
import { ReportCharts } from './charts/ReportCharts';
import { notBeNull } from '../validation/commonValidation';
import { ReportContext } from './ReportContext';
import {
  mapFormModelToUserDefinedReportParameters,
  UserDefinedReportParameterValues,
} from './userDefinedReportHelpers';
import { useReportParametersInitialValues } from './parameters/useReportParamtersInitialValues';
import { EmptyFieldLabel } from '../../infrastructure/forms/common/FieldLabel';

export const reportTestId = (report: ReportResponse) => `report-${report.id}`;

type ReportProps = {
  report: ReportResponse;
  onSubmit?: (formModel: FormikValues) => void;
  isTest?: boolean; // The 'BoldReportViewerComponent' doesn't play well with jest tests, so we provide the option to prevent it rendering
};

export const Report = ({ report, onSubmit, isTest }: ReportProps) => {
  const { translate } = useInternationalisation();
  const previousReportId = usePrevious(report.id);

  const {
    makeRequest,
    state: { response, inProgress, error },
  } = usePostJson<GetReportParametersRequest, GetReportParametersResponse>(
    '/api/reports/GetReportParameters'
  );

  useEffect(() => {
    if (report.id !== previousReportId) {
      makeRequest({ requestBody: { reportId: report.id } });
    }
  }, [report, previousReportId, makeRequest]);

  if (error) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')} withMarginTop={true}>
        {error}
      </Alert>
    );
  }

  if (response == null || inProgress) {
    return (
      <SpinnerContainer>
        <CentredSpinner size="xlarge" />
      </SpinnerContainer>
    );
  }

  return (
    <ReportComponent
      report={report}
      parameters={response.parameters}
      onSubmit={onSubmit}
      isTest={isTest}
    />
  );
};

type ReportComponentProps = {
  report: ReportResponse;
  parameters: Array<ReportParameterResponse>;
  onSubmit?: (formModel: FormikValues) => void;
  isTest?: boolean;
};

export const ReportComponent = ({ report, parameters, onSubmit, isTest }: ReportComponentProps) => {
  const { translate, formatDate } = useInternationalisation();
  const [reportIsLoading, setReportIsLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [customReportParameters, setCustomReportParameters] = useState<
    Array<CustomReportParameter>
  >([]);

  const { selectedCompanyOption, selectedCompanyReportGroupOption } = useContext(ReportContext);
  const [userDefinedReportParameters, setUserDefinedReportParameters] =
    useState<UserDefinedReportParameterValues | null>(null);

  const initialValues = useReportParametersInitialValues(parameters);
  const validator = useMemo(
    () => new ReportValidator(translate, parameters),
    [translate, parameters]
  );

  const onSubmitWrapper = (formModel: FormikValues) => {
    setReportIsLoading(true);
    setCustomReportParameters(mapFormModelToCustomReportParameters(formModel));
    setUserDefinedReportParameters(
      mapFormModelToUserDefinedReportParameters(
        formModel,
        selectedCompanyOption,
        selectedCompanyReportGroupOption,
        parameters,
        formatDate
      )
    );
    if (onSubmit != null) {
      onSubmit(formModel);
    }
    // Force the report to re-render and refresh the data.
    setShowReport(false);
    setShowReport(true);
  };

  const onLoadingComplete = (formikProps: FormikProps<FormikValues>) => {
    setReportIsLoading(false);
    formikProps.setSubmitting(false);
  };

  const getReportViewerProps = useCallback(
    (formikProps: FormikProps<FormikValues>): ReportViewerProps => ({
      report,
      customReportParameters,
      userDefinedReportParameters,
      onLoadingComplete: () => onLoadingComplete(formikProps),
    }),
    [report, customReportParameters, userDefinedReportParameters]
  );

  return (
    <div data-testid={reportTestId(report)}>
      <Header>{report.displayName}</Header>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitWrapper}
        validate={validator.validate}
      >
        {(formikProps) => (
          <>
            <FormContainer>
              <Form>
                <FormComponentsContainer>
                  <ParameterFieldsContainer>
                    <ReportParameterFields report={report} parameters={parameters} />
                    <RunButtonContainer>
                      <EmptyFieldLabel />
                      <RunReportButton
                        submittingText={translate('pages.reports.runButton.waitingText')}
                      >
                        {translate('pages.reports.runButton.text')}
                      </RunReportButton>
                    </RunButtonContainer>
                  </ParameterFieldsContainer>
                </FormComponentsContainer>
              </Form>
            </FormContainer>
            {!isTest && showReport ? (
              report.includesCharts ? (
                <Tabs
                  tabs={[
                    {
                      title: translate('pages.reports.tabs.reportTabTitle'),
                      content: <ReportViewer {...getReportViewerProps(formikProps)} />,
                    },
                    {
                      title: translate('pages.reports.tabs.chartsTabTitle'),
                      content: (
                        <ReportCharts
                          report={report}
                          customReportParameters={customReportParameters}
                        />
                      ),
                      disabled: reportIsLoading,
                    },
                  ]}
                />
              ) : (
                <ReportViewer {...getReportViewerProps(formikProps)} />
              )
            ) : (
              <ReportsPlaceholder />
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

const SpinnerContainer = styled.div`
  height: 150px;
`;

const Header = styled(Header2)`
  margin-top: ${spacing16};
`;

const FormContainer = styled.div`
  margin-bottom: ${spacing32};
`;

const FormComponentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;

const ParameterFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spacing16};
  align-items: flex-start;

  > {
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
`;

const RunButtonContainer = styled.div``;

const RunReportButton = styled(SubmitButton)`
  margin: 0;
`;

export type GetReportParametersRequest = {
  reportId: number;
};

export type GetReportParametersResponse = {
  reportId: number;
  parameters: Array<ReportParameterResponse>;
};

export type ReportParameterResponse = {
  displaySequence: number;
  displayText: string;
  parameterTypeCode: ReportParameterTypeCode;
  name: string;
  isRequired: boolean;
  includeAllRecord: boolean;
};

class ReportValidator extends Validator<FormikValues> {
  constructor(translate: TranslateFunction, parameters: Array<ReportParameterResponse>) {
    super();

    parameters.forEach((parameter) => {
      if (parameter.isRequired) {
        this.ruleFor(parameter.name).must(notBeNull(translate));

        this.ruleFor(parameter.name)
          .must((value) => (value as string).trim() !== '')
          .withMessage(translate('validation.requiredField'))
          .when((_) => parameter.parameterTypeCode === reportParameterTypeCodesByType.freeText);
      }
    });
  }
}

const mapFormModelToCustomReportParameters = (
  formModel: FormikValues
): Array<CustomReportParameter> => {
  const customReportParameters: Array<CustomReportParameter> = [];
  for (const [key, value] of Object.entries(formModel)) {
    customReportParameters.push({ name: key, value: value?.toString() });
  }
  return customReportParameters;
};
