import React from 'react';
import {
  AccessGroupSelectOptionValue,
  AccessGroupSelect,
  AccessGroupSelectSettings,
} from './AccessGroupSelect';
import { GetAccessGroupDropdownOptionsResponse } from './GetAccessGroupDropdownOptionsResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  AccessGroupSelectOptionValue,
  AccessGroupSelectSettings,
  GetAccessGroupDropdownOptionsResponse
>;

export const AccessGroupSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    AccessGroupSelectOptionValue,
    AccessGroupSelectSettings,
    GetAccessGroupDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <AccessGroupSelect {...metadataSingleSelectProps} />
    )}
  />
);
