import React from 'react';
import styled, { css } from 'styled-components/macro';
import { spacing16, spacing48, spacing80 } from '../design/spacing';
import { onMobile } from './screenBreakpoints';

export const mobilePagePadding = spacing16;
export const PaddedPage = styled.div.attrs((props: { mobilePaddingOverride?: string }) => props)`
  height: fit-content;
  width: 100%;
  padding: ${spacing48} ${spacing80};
  ${(props) =>
    onMobile(css`
      padding: 0 ${props.mobilePaddingOverride || mobilePagePadding}
        ${props.mobilePaddingOverride || mobilePagePadding}
        ${props.mobilePaddingOverride || mobilePagePadding};
    `)}
`;

type CentredPaddedPageProps = {
  maxWidthPixels?: number;
  children: React.ReactNode;
  'data-testid'?: string;
};

const defaultMaxWidthPixels = 714;

export const CentredPaddedPage = (props: CentredPaddedPageProps) => (
  <CentredContentPaddedPage data-testid={props['data-testid']}>
    <ContentContainer maxWidthPixels={props.maxWidthPixels ?? defaultMaxWidthPixels}>
      {props.children}
    </ContentContainer>
  </CentredContentPaddedPage>
);

const CentredContentPaddedPage = styled(PaddedPage)`
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div<{ maxWidthPixels: number }>`
  width: 100%;
  max-width: ${(props) => props.maxWidthPixels}px;
`;
