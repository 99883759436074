import { UserRole } from '../../authentication/UserRole';
import { without } from 'lodash';

export const placeTransactionsUserRoles: Array<UserRole> = [
  'Investor',
  'Consolidated Investor',
  'Advisor',
];

export const placeTransactionInvestorFieldUserRoles: Array<UserRole> = without(
  placeTransactionsUserRoles,
  'Investor'
);
