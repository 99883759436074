import React from 'react';
import styled, { css } from 'styled-components/macro';
import { spacing16, spacing32, spacing48 } from '../../../styling/design/spacing';
import { onDesktop, onMobile } from '../../../styling/layout/screenBreakpoints';
import { BackButton } from './BackButton';

type Props = {
  withBackButton: boolean;
  withMarginTop?: boolean;
  withMarginBottom?: boolean;
  children?: React.ReactNode;
};

export const PageHeaderActions = (props: Props) => (
  <Container {...props} actions={props.children}>
    {props.withBackButton && <BackButton />}
    {props.children && <ActionsContainer>{props.children}</ActionsContainer>}
  </Container>
);

type ContainerProps = Omit<Props, 'children'> & { actions: React.ReactNode };

const Container = styled.div<ContainerProps>`
  margin-top: ${(props) => (props.withMarginTop === true ? spacing32 : 0)};

  ${(props) =>
    onDesktop(css`
      display: flex;
      align-items: center;
      justify-content: ${props.withBackButton ? 'space-between' : 'flex-end'};
      margin-bottom: ${props.withMarginBottom === false ? 0 : spacing32};
    `)}

  ${(props) =>
    onMobile(css`
      display: flex;
      flex-direction: column;
      margin-bottom: ${props.actions ? 0 : props.withMarginBottom === false ? 0 : spacing32};
    `)}
`;

export const ActionsContainer = styled.div`
  ${onDesktop(css`
    display: flex;
    align-items: center;
    gap: ${spacing32};
  `)}

  ${onMobile(css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${spacing48} 0;
    gap: ${spacing16};
  `)}
`;
