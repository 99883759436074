import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import {
  GetFileUploadRoleDropdownOptionsResponse,
  mapGetFileUploadRoleDropdownOptionsResponseToDropdownOptions,
} from './GetFileUploadRoleDropdownOptionsResponse';
import { MetadataSingleSelect } from '../MetadataSingleSelect';

export type FileUploadRoleSelectOptionValue = string | null;

type Props = Omit<
  MetadataSingleSelectComponentProps<
    FileUploadRoleSelectOptionValue,
    {},
    GetFileUploadRoleDropdownOptionsResponse
  >,
  'settings'
>;

export const FileUploadRoleSelect = ({ ...metadataSingleSelectProps }: Props) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.fileUploadRoleSelect.placeholder');
  const loadingPlaceholder = translate('metadata.fileUploadRoleSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      FileUploadRoleSelectOptionValue,
      {},
      {},
      GetFileUploadRoleDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetFileUploadRoleDropdownOptions"
      settings={{}}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapGetFileUploadRoleDropdownOptionsResponseToDropdownOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={250}
      {...metadataSingleSelectProps}
    />
  );
};
