import { useUserActivityContext } from './UserActivityContext';
import { useMemo } from 'react';
import { differenceInSeconds } from 'date-fns';
import { useCurrentTime } from '../../infrastructure/hooks/useCurrentTime';

export const useSecondsSinceLastUserActivity = () => {
  const { lastActivityDate } = useUserActivityContext();
  const currentTime = useCurrentTime();

  return useMemo(
    () => differenceInSeconds(currentTime, lastActivityDate),
    [currentTime, lastActivityDate]
  );
};
