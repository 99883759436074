import React from 'react';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { LoadingOverlay } from '../../infrastructure/interface/components/LoadingOverlay';
import { Header2 } from '../../infrastructure/interface/components/Headers';
import styled from 'styled-components/macro';
import { Panel } from '../../infrastructure/interface/components/Panel';
import { spacing32 } from '../../styling/design/spacing';
import { useIsOpen } from '../../infrastructure/hooks/useIsOpen';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';
import { Modal, ModalHeader } from '../../infrastructure/interface/components/Modal';
import { InfoCircleSolidIcon } from '../../icons/icons';

type Props = {
  disclaimerTextAsHtml: string;
  inProgress: boolean;
  withMarginTop?: boolean;
};

export const Disclaimer = (props: Props) => (
  <>
    <DesktopOnly>
      <DesktopDisclaimer {...props} />
    </DesktopOnly>
    <MobileOnly>
      <MobileDisclaimer {...props} />
    </MobileOnly>
  </>
);

const DesktopDisclaimer = ({ disclaimerTextAsHtml, inProgress, withMarginTop }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <StyledPanel withMarginTop={withMarginTop}>
      <LoadingOverlay showOverlay={inProgress}>
        <Header2>{translate('disclaimer.header')}</Header2>
        <div dangerouslySetInnerHTML={{ __html: disclaimerTextAsHtml }} />
      </LoadingOverlay>
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)<{ withMarginTop?: boolean }>`
  margin-top: ${(props) => (props.withMarginTop ? spacing32 : 0)};
`;

const MobileDisclaimer = ({ disclaimerTextAsHtml, inProgress }: Props) => {
  const { translate } = useInternationalisation();
  const { isOpen: modalIsOpen, open: openModal, close: closeModal } = useIsOpen(false);

  return (
    <>
      <MinimalButton icon={<InfoCircleSolidIcon />} onClick={openModal} disabled={inProgress}>
        {translate('disclaimer.button')}
      </MinimalButton>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <ModalHeader
          title={translate('disclaimer.header')}
          withCloseButton={true}
          onRequestClose={closeModal}
        />
        <div dangerouslySetInnerHTML={{ __html: disclaimerTextAsHtml }} />
      </Modal>
    </>
  );
};
