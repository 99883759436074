import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useInvestorDetailsContext } from '../InvestorDetailsContext';
import { GetInvestorCommunicationCopiesResponse } from './GetInvestorCommunicationCopiesResponse';
import { useInvestorIdUrlParam } from '../useInvestorIdUrlParam';
import { investorDetailsComponentResultsPerPageOptions } from '../InvestorDetails';

type CommunicationCopiesContextValue = {
  pageNumber: number;
  setPageNumber: (value: number) => void;
  resultsPerPage: number;
  setResultsPerPage: (value: number) => void;
  latestResponse: GetInvestorCommunicationCopiesResponse | null;
  requestInProgress: boolean;
  blocked: boolean;
};

const CommunicationCopiesContext = createContext<CommunicationCopiesContextValue | undefined>(
  undefined
);

export const useCommunicationCopies = () => {
  const contextValue = useContext(CommunicationCopiesContext);

  if (!contextValue) {
    throw new Error(
      'useCommunicationCopies must be used within a CommunicationCopiesContextProvider'
    );
  }

  return contextValue;
};

type Props = {
  children: React.ReactNode;
  blocked: boolean;
};

export const CommunicationCopiesContextProvider = ({ blocked, children }: Props) => {
  const { setInnerComponentApiError } = useInvestorDetailsContext();
  const investorId = useInvestorIdUrlParam();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(
    investorDetailsComponentResultsPerPageOptions[0]
  );
  const [latestResponse, setLatestResponse] =
    useState<GetInvestorCommunicationCopiesResponse | null>(null);

  const getRequest = useGetJson<
    GetInvestorCommunicationCopiesQuery,
    GetInvestorCommunicationCopiesResponse
  >('/api/investors/GetInvestorCommunicationCopies');

  const { inProgress: requestInProgress } = getRequest.state;

  useEffect(() => {
    getRequest.makeRequest({
      queryParameters: {
        investorId,
        pageNumber,
        resultsPerPage,
        blocked,
      },
      onSuccess: (response) => setLatestResponse(response),
      onFailure: (error) => setInnerComponentApiError(error),
    });
  }, [pageNumber, resultsPerPage, investorId, blocked]); // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue: CommunicationCopiesContextValue = {
    pageNumber,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    latestResponse,
    requestInProgress,
    blocked,
  };

  return (
    <CommunicationCopiesContext.Provider value={contextValue}>
      {children}
    </CommunicationCopiesContext.Provider>
  );
};

type GetInvestorCommunicationCopiesQuery = {
  investorId: number | null;
  blocked: boolean;
  pageNumber: number;
  resultsPerPage: number;
};
