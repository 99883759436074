import { useInternationalisation } from '../../../../../internationalisation/hooks/useInternationalisation';
import { useWindowTitle } from '../../../../../infrastructure/hooks/useWindowTitle';
import { CentredPaddedPage } from '../../../../../styling/layout/PaddedPage';
import { RequiresUserRole } from '../../../../authentication/UserRoles';
import { investorUserRoles } from '../../../../authentication/UserRole';
import { useLocalClearingDetails } from '../useLocalClearingDetails';
import { useInvestorIdUrlParam } from '../../../useInvestorIdUrlParam';
import { useGetJson } from '../../../../../infrastructure/api/useGetJson';
import { GetDataForEditBankingDetailsResponse } from './GetDataForEditBankingDetailsResponse';
import React, { useCallback, useState } from 'react';
import { useBankingDetailIdUrlParam } from '../../useBankingDetailIdUrlParam';
import { useOnMount } from '../../../../../infrastructure/hooks/useOnMount';
import { ApiRequestStateWrapper } from '../../../../../infrastructure/api/ApiRequestStateWrapper';
import { Header1 } from '../../../../../infrastructure/interface/components/Headers';
import { BackButton } from '../../../../../infrastructure/interface/components/BackButton';
import { Alert } from '../../../../../infrastructure/interface/components/Alert';
import {
  BankingDetailsActionStatusCode,
  bankingDetailsActionStatusesByCode,
} from '../../BankingDetailsActionStatus';
import styled from 'styled-components/macro';
import { spacing32 } from '../../../../../styling/design/spacing';
import { ShowLocalClearingDetailsToggleSwitch } from '../ShowLocalClearingDetailsToggleSwitch';
import { EditBankingDetailsForm } from './EditBankingDetailsForm';

export const EditBankingDetails = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.bankingDetails.edit.title'));

  return (
    <CentredPaddedPage data-testid={editBankingDetailsPageTestId}>
      <RequiresUserRole userRole={investorUserRoles}>
        <EditBankingDetailsComponent />
      </RequiresUserRole>
    </CentredPaddedPage>
  );
};

export const editBankingDetailsPageTestId = 'edit-banking-details-page';

const EditBankingDetailsComponent = () => {
  const { translate } = useInternationalisation();

  const investorId = useInvestorIdUrlParam();
  const isOwnBankingDetails = investorId == null;

  const bankingDetailId = useBankingDetailIdUrlParam();

  const {
    localClearingDetailsOnly,
    setLocalClearingDetailsOnly,
    showLocalClearingDetailsToggle,
    updateLocalClearingSettingsFromDefaultCode,
  } = useLocalClearingDetails();

  const [formLoadError, setFormLoadError] = useState<string | null>(null);

  const [successStatusCode, setSuccessStatusCode] = useState<BankingDetailsActionStatusCode | null>(
    null
  );

  const getDataForEditBankingDetailsRequest = useGetJson<
    GetDataForEditBankingDetailsQuery,
    GetDataForEditBankingDetailsResponse
  >('/api/banking-details/GetDataForEditBankingDetails');

  const makeGetDataRequest = useCallback(() => {
    return getDataForEditBankingDetailsRequest.makeRequest({
      queryParameters: { investorId, bankingDetailId: bankingDetailId ?? '' },
      onSuccess: (response) =>
        updateLocalClearingSettingsFromDefaultCode(response.localBankDetailsDefaultCode),
    });
  }, [investorId, bankingDetailId]); // eslint-disable-line react-hooks/exhaustive-deps

  useOnMount(() => {
    makeGetDataRequest();
  });

  return (
    <ApiRequestStateWrapper
      apiRequestState={getDataForEditBankingDetailsRequest.state}
      retry={makeGetDataRequest}
    >
      {(dataForEditBankingDetailsResponse: GetDataForEditBankingDetailsResponse) => (
        <>
          <Header1>{`${translate('pages.bankingDetails.edit.header')}${
            isOwnBankingDetails ? '' : `: ${dataForEditBankingDetailsResponse.investorName}`
          }`}</Header1>
          <HeaderActionsContainer>
            <BackButton />
            {successStatusCode == null && showLocalClearingDetailsToggle && (
              <ShowLocalClearingDetailsToggleSwitch
                checked={localClearingDetailsOnly}
                onChange={setLocalClearingDetailsOnly}
              />
            )}
          </HeaderActionsContainer>
          {formLoadError != null ? (
            <Alert alertType="negative" header={translate('errors.apology')}>
              {formLoadError}
            </Alert>
          ) : successStatusCode != null ? (
            <Alert
              alertType="positive"
              header={translate('pages.bankingDetails.edit.successMessage.header')}
            >
              {successStatusCode === bankingDetailsActionStatusesByCode.Authorised
                ? translate('pages.bankingDetails.edit.successMessage.authorised')
                : translate('pages.bankingDetails.edit.successMessage.pending')}
            </Alert>
          ) : (
            <EditBankingDetailsForm
              dataForInitialValues={dataForEditBankingDetailsResponse}
              localClearingDetailsOnly={localClearingDetailsOnly}
              onFormLoadError={setFormLoadError}
              onSuccess={setSuccessStatusCode}
            />
          )}
        </>
      )}
    </ApiRequestStateWrapper>
  );
};

const HeaderActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing32};
`;

type GetDataForEditBankingDetailsQuery = {
  investorId: number | null;
  bankingDetailId: string;
};
