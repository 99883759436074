import styled from 'styled-components/macro';
import { textColours } from '../../../styling/design/colours';
import { fontSizeCss, fontWeightBold } from '../../../styling/design/fonts';
import { spacing16 } from '../../../styling/design/spacing';

export const ErrorMessage = styled.div`
  color: ${textColours.negative};
  font-weight: ${fontWeightBold};
  ${fontSizeCss('small')};
  margin-top: ${spacing16};
  white-space: pre-wrap;
`;
