import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { AccessGroupSelectOptionValue } from './AccessGroupSelect';

export type GetAccessGroupDropdownOptionsResponse = {
  options: Array<AccessGroupDropdownOptionResponse>;
};

type AccessGroupDropdownOptionResponse = {
  accessGroupId: number;
  description: string;
};

export const blankAccessGroupDropdownOptionId = 0;

export const mapAccessGroupDropdownOptionsResponseToSelectOptions = (
  response: GetAccessGroupDropdownOptionsResponse | null,
  translate: TranslateFunction
): SelectOptions<AccessGroupSelectOptionValue> =>
  !response
    ? []
    : response.options.map((option) => ({
        value: option.accessGroupId,
        label: option.description,
        ariaLabel:
          option.accessGroupId === blankAccessGroupDropdownOptionId
            ? translate('metadata.accessGroupSelect.blankOptionDescription')
            : undefined,
      }));
