import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { spacing16, spacing24, spacing32, spacing8 } from '../../../styling/design/spacing';
import { colourNotice05 } from '../../../styling/design/colours';

// From https://tobiasahlin.com/spinkit/

type Props = {
  size: 'small' | 'medium' | 'large' | 'xlarge';
  colour?: string;
  'data-testid'?: string;
};

export const CentredSpinner = (props: Props) => (
  <CentredSpinnerContainer>
    <Spinner {...props} />
  </CentredSpinnerContainer>
);

const CentredSpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = (props: Props) => {
  const { size, colour = colourNotice05 } = props;

  return (
    <SpinnerContainer size={size} colour={colour} data-testid={props['data-testid']}>
      <SpinnerCircle className="sk-circle1" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle2" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle3" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle4" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle5" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle6" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle7" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle8" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle9" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle10" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle11" size={size} colour={colour} />
      <SpinnerCircle className="sk-circle12" size={size} colour={colour} />
    </SpinnerContainer>
  );
};

const getSpinnerSize = (size: Props['size']) => {
  switch (size) {
    case 'small':
      return spacing8;
    case 'medium':
      return spacing16;
    case 'large':
      return spacing24;
    case 'xlarge':
      return spacing32;
    default:
      return spacing16;
  }
};

const circleBounceDelay = keyframes`
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const SpinnerContainer = styled.div<Props>`
  width: ${(props) => getSpinnerSize(props.size)};
  height: ${(props) => getSpinnerSize(props.size)};
  position: relative;
`;

export const SpinnerCircle = styled.div<Props>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${(props) => props.colour};
    border-radius: 100%;
    -webkit-animation: ${circleBounceDelay} 1.2s infinite ease-in-out both;
    animation: ${circleBounceDelay} 1.2s infinite ease-in-out both;
  }

  &.sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  &.sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  &.sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  &.sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  &.sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  &.sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  &.sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  &.sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  &.sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  &.sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  &.sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  &.sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  &.sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  &.sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  &.sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  &.sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  &.sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  &.sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  &.sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  &.sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  &.sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  &.sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
`;
