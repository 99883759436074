export type ClearingCodeValidationState = 'checkCodeRequired' | 'invalid' | 'valid';

export const clearingCodeValidationStateType: Record<
  ClearingCodeValidationState,
  ClearingCodeValidationState
> = {
  checkCodeRequired: 'checkCodeRequired',
  invalid: 'invalid',
  valid: 'valid',
};
