import { makeRequest } from '../api/makeRequest';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';

export const logClientError = (
  errorMessage: string,
  stackTrace: string,
  translate: TranslateFunction | null,
  siteId: number | null
) => {
  makeRequest(
    {
      method: 'post',
      endpointUrl: '/api/monitoring/LogClientError',
      requestBody: { errorMessage, stackTrace },
    },
    translate,
    siteId
  ).catch((_) => {
    // Swallow the error - we don't care too much if the request fails
  });
};

export const unknownErrorToStringOrNull = (exception: unknown): string | null => {
  if (typeof exception === 'string') {
    return exception.toUpperCase();
  }
  if (exception instanceof Error) {
    return exception.message;
  }
  return null;
};
