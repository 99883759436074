import React, { useRef } from 'react';
import { FormField } from '../common/FormField';
import { DatePicker } from '../../interface/forms/DatePicker';
import { FieldValidityInput } from './InputField';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { CalendarIcon } from '../../../icons/icons';
import ReactDatePicker from 'react-datepicker';

export const datePickerFieldTestId = (fieldName: string) => `datepicker-field-${fieldName}`;

export const datePickerFieldInputTestId = (fieldName: string) =>
  `datepicker-field-input-${fieldName}`;

type Props = {
  fieldName: string;
  placeholder?: string;
  minDate?: IsoDatestamp;
  maxDate?: IsoDatestamp;
  label?: string;
  helpText?: string;
  disabled?: boolean;
  clearable?: boolean;
  highlight?: boolean;
  customInput?: React.ReactNode;
};

export const DatePickerField = ({
  fieldName,
  placeholder,
  minDate,
  maxDate,
  label,
  helpText,
  disabled,
  clearable,
  highlight,
  customInput,
}: Props) => {
  const datePickerRef = useRef<ReactDatePicker>(null);

  return (
    <FormField<IsoDatestamp> fieldName={fieldName} label={label} helpText={helpText}>
      {({ field, fieldValidity, formikContext }) => {
        const onCalendarIconClick = () => {
          datePickerRef.current?.setOpen(true);
        };

        const onChange = (newDate: IsoDatestamp | null) => {
          formikContext.setFieldTouched(field.name);
          formikContext.setFieldValue(field.name, newDate);
        };

        const onBlur = () => {
          formikContext.setFieldTouched(field.name);
        };

        return (
          <DatePicker
            data-testid={datePickerFieldTestId(fieldName)}
            ref={datePickerRef}
            value={field.value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            minDate={minDate}
            maxDate={maxDate}
            name={field.name}
            disabled={disabled || formikContext.isSubmitting}
            clearable={clearable}
            highlight={highlight}
            customInput={
              customInput != null ? (
                customInput
              ) : (
                <FieldValidityInput
                  icon={{ icon: <CalendarIcon />, onClick: onCalendarIconClick }}
                  data-testid={datePickerFieldInputTestId(fieldName)}
                  fieldValidity={fieldValidity}
                  highlight={highlight}
                />
              )
            }
          />
        );
      }}
    </FormField>
  );
};
