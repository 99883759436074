import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useDashboardFilters } from '../DashboardFiltersContext';
import React, { ReactNode, useEffect, useState } from 'react';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import {
  IsoDatestamp,
  toDateFromIsoDatestamp,
  toIsoDatestampFromDate,
} from '../../../helpers/dateTimeHelpers';
import {
  EquityAttributionSelect,
  EquityAttributionSelectOptionValue,
} from '../../metadata/equityAttribution/EquityAttributionSelect';
import { mapEquityAttributionDropdownOptionsResponseToSelectOptions } from '../../metadata/equityAttribution/GetEquityAttributionDropdownOptionsResponse';
import { InvalidEquityAttributionId } from '../../metadata/idConstants';
import { FieldError } from '../../../infrastructure/forms/common/FieldError';
import {
  MultiLineGraph,
  MultiLineGraphDataPoint,
} from '../../../infrastructure/charts/MultiLineGraph';
import { ComponentResponse } from '../DashboardComponent';

export const rollingPerformanceChartComponentName = 'Rolling performance';

export const RollingPerformanceChart = () => {
  const getRequest = useGetJson<
    GetDataForRollingPerformanceChartQuery,
    GetDataForRollingPerformanceChartResponse
  >('api/dashboards/GetDataForRollingPerformanceChart');

  const { companyId, fromDate } = useDashboardFilters();
  const [equityAttributionId, setEquityAttributionId] =
    useState<EquityAttributionSelectOptionValue>(null as EquityAttributionSelectOptionValue);
  const [selectError, setSelectError] = useState<string | null>(null);

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
          equityAttributionId: equityAttributionId ?? InvalidEquityAttributionId,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate, equityAttributionId]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <RollingPerformanceChartComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
          equityAttributionId={equityAttributionId}
          companyId={companyId}
          setEquityAttributionId={setEquityAttributionId}
          selectError={selectError}
          setSelectError={setSelectError}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForRollingPerformanceChartResponse;
  showLoadingOverlay: boolean;
  equityAttributionId: EquityAttributionSelectOptionValue;
  setEquityAttributionId: (value: EquityAttributionSelectOptionValue) => void;
  companyId: number | null;
  selectError: string | null;
  setSelectError: (value: string | null) => void;
};

const RollingPerformanceChartComponent = (props: ComponentProps) => {
  const { translate, formatNumber, formatDate } = useInternationalisation();
  const PerformanceData: Array<MultiLineGraphDataPoint> = props.response.performanceDataPoints.map(
    (dataPoint) => ({
      x: toDateFromIsoDatestamp(dataPoint.date),
      y: dataPoint.value,
    })
  );

  // get performance name from first raw ea-code column
  const [performanceNameFirstElement] = props.response.performanceDataPoints;
  const performanceName =
    typeof performanceNameFirstElement != 'undefined' ? performanceNameFirstElement.name : '';

  //get Benchmark1 name  form first raw benchmark_1_sname column
  const [Benchmark1NameFirstElement] = props.response.benchmark1DataPoints;
  const Benchmark1Name =
    typeof Benchmark1NameFirstElement != 'undefined' ? Benchmark1NameFirstElement.name : '';

  //get Benchmark2 name from first raw benchmark_2_sname column
  const [Benchmark2NameFirstElement] = props.response.benchmark2DataPoints;
  const Benchmark2Name =
    typeof Benchmark2NameFirstElement != 'undefined' ? Benchmark2NameFirstElement.name : '';

  const Benchmark1GraphData: Array<MultiLineGraphDataPoint> =
    props.response.benchmark1DataPoints.map((dataPoint) => ({
      x: toDateFromIsoDatestamp(dataPoint.date),
      y: dataPoint.value,
    }));

  const Benchmark2GraphData: Array<MultiLineGraphDataPoint> =
    props.response.benchmark2DataPoints.map((dataPoint) => ({
      x: toDateFromIsoDatestamp(dataPoint.date),
      y: dataPoint.value,
    }));

  const filterSetContent: ReactNode = (
    <div>
      <EquityAttributionSelect
        settings={{
          employeeCounterpartId: null,
          companyId: props.companyId,
          includeBlank: false,
        }}
        value={props.equityAttributionId}
        onChange={props.setEquityAttributionId}
        onError={props.setSelectError}
        onLoaded={(response) => {
          props.setSelectError(null);
          props.setEquityAttributionId(
            mapEquityAttributionDropdownOptionsResponseToSelectOptions(response)[0]?.value ?? null
          );
        }}
        hideIfEmptyOrSingleOption={true}
      />
      {props.selectError && (
        <FieldError fieldName={'RollingPerformanceChart'} children={props.selectError} />
      )}
    </div>
  );

  return (
    <DashboardComponentLayout
      headerText={translate('pages.dashboard.components.rollingPerformanceChart.title', {
        managerGraphMonths: props.response.managerGraphMonths,
      })}
      showLoadingOverlay={props.showLoadingOverlay}
      filterSet={filterSetContent}
      showNoDataMessage={props.response.noDataMessage}
    >
      <MultiLineGraph
        exportFilename={translate('pages.dashboard.components.rollingPerformanceChart.exportName')}
        companyID={props.companyId}
        dataPoints={PerformanceData}
        dataPoints1={Benchmark1GraphData}
        dataPoints2={Benchmark2GraphData}
        dataPointsName={performanceName}
        dataPoints1Name={Benchmark1Name}
        dataPoints2Name={Benchmark2Name}
        height="stretch"
        xAxisType="datetime"
        yAxisOptions={{
          labels: {
            formatter() {
              return `${this.value}%`;
            },
          },
        }}
        tooltipOptions={{
          headerFormat: '',
          pointFormatter() {
            const formattedX = formatDate(toIsoDatestampFromDate(new Date(this.x)));
            const formattedY =
              this.y === undefined ? '' : formatNumber(this.y, { decimalPlaces: 2 });
            return `<b>${formattedX}</b><br/>${formattedY}%`;
          },
        }}
      />
    </DashboardComponentLayout>
  );
};

type GetDataForRollingPerformanceChartQuery = {
  companyId: number;
  runDate: string;
  equityAttributionId: number;
};

export type GetDataForRollingPerformanceChartResponse = ComponentResponse & {
  managerGraphMonths: number;
  performanceDataPoints: Array<{
    name: string;
    value: number;
    date: IsoDatestamp;
  }>;
  benchmark1DataPoints: Array<{
    name: string;
    value: number;
    date: IsoDatestamp;
  }>;
  benchmark2DataPoints: Array<{
    name: string;
    value: number;
    date: IsoDatestamp;
  }>;
};
