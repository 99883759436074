import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetShareholderDropdownOptionsResponse = {
  options: Array<ShareholderOptionResponse>;
};

export type ShareholderOptionResponse = {
  counterpartCode: string;
  counterpartId: number;
  isInactive: boolean;
};

export const mapShareholderDropdownOptionsResponseToSelectOptions = (
  response: GetShareholderDropdownOptionsResponse | null
): SelectOptions<number | null> =>
  !response
    ? []
    : response.options.map((option) => ({
        value: option.counterpartId,
        label: option.counterpartCode,
      }));
