import React, { useContext, useEffect } from 'react';
import { GetInvestorDetailsResponse } from './GetInvestorDetailsResponse';
import styled, { css } from 'styled-components/macro';
import { spacing8, spacing32 } from '../../styling/design/spacing';
import { useInvestorDetailsContext } from './InvestorDetailsContext';
import { CommunicationCopies } from './communicationCopies/CommunicationCopies';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import { useInvestorIdUrlParam } from './useInvestorIdUrlParam';
import { BeneficialOwners } from './beneficialOwners/BeneficialOwners';
import { IfUserHasRole } from '../authentication/UserRoles';
import { AgentOrBeneficialOwnerDetailsModal } from './AgentOrBeneficialOwnerDetailsModal';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { Panel } from '../../infrastructure/interface/components/Panel';
import { onMobile } from '../../styling/layout/screenBreakpoints';
import { Header3 } from '../../infrastructure/interface/components/Headers';
import { CounterpartDetails } from './CounterpartDetails';
import { useGetInvestorDetailsRequest } from './useGetInvestorDetailsRequest';
import { PencilIcon } from '../../icons/icons';
import { AppLinkWithIcon } from '../../infrastructure/interface/components/AppLink';
import { BankingDetailsManagerView } from './bankingDetails/BankingDetailsManagerView';
import { InvestorBankingDetailsContextProvider } from './bankingDetails/InvestorBankingDetailsContext';
import { UserDetails } from '../authentication/UserDetails';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { DistributionReinvestmentsContextProvider } from './distributionReinvestments/DistributionReinvestmentsContext';
import { DistributionReinvestmentsManagerView } from './distributionReinvestments/DistributionReinvestmentsManagerView';

export const InvestorDetails = () => {
  const { setInnerComponentApiError } = useInvestorDetailsContext();
  const investorId = useInvestorIdUrlParam();
  const getInvestorDetailsRequest = useGetInvestorDetailsRequest();
  const authenticationContext = useContext(AuthenticationContext);

  const { response, inProgress } = getInvestorDetailsRequest.state;

  useEffect(() => {
    getInvestorDetailsRequest.makeRequest({
      queryParameters: { investorId },
      onFailure: (error) => setInnerComponentApiError(error)
    });
  }, [investorId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (inProgress) {
    return <CentredSpinner size="xlarge" />;
  }

  if (response == null) {
    return null;
  }
  
  
  const user: UserDetails = authenticationContext.getUser();
  
  return (
    <InvestorDetailsContainer>
      <CounterpartDetailsPanel counterpartDetails={response} />
      <CommunicationCopies blocked={false} />
      <CommunicationCopies blocked={true} />
      <IfUserHasRole userRole="Manager">
        <BeneficialOwners />
        {user.permissions.canViewBankingDetails ? (
          <InvestorBankingDetailsContextProvider>
            <BankingDetailsManagerView />
          </InvestorBankingDetailsContextProvider>
        ) : null}
        {user.permissions.canViewDistributionReinvestments ? (
          <DistributionReinvestmentsContextProvider>
            <DistributionReinvestmentsManagerView/>
          </DistributionReinvestmentsContextProvider>
        ) : null}
      </IfUserHasRole>
      <AgentOrBeneficialOwnerDetailsModal />
    </InvestorDetailsContainer>
  );
};

type CounterpartDetailsPanelProps = {
  counterpartDetails: GetInvestorDetailsResponse;
};

const CounterpartDetailsPanel = ({ counterpartDetails }: CounterpartDetailsPanelProps) => {
  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();

  const editInvestorDetailsPath =
    '/investor-details/' + (investorId == null ? '' : `${investorId}/`) + 'edit';

  return (
    <CounterpartDetailsPanelContainer>
      <CounterpartDetailsPanelHeader>
        <Header3>{translate('pages.investorDetailsPage.counterpartDetails.header')}</Header3>
        {counterpartDetails.editable ? (
          <AppLinkWithIcon icon={<PencilIcon />} to={editInvestorDetailsPath}>
            {translate('pages.investorDetailsPage.counterpartDetails.editDetails')}
          </AppLinkWithIcon>
        ) : null}
      </CounterpartDetailsPanelHeader>
      <CounterpartDetails counterpartDetails={counterpartDetails} />
    </CounterpartDetailsPanelContainer>
  );
};

export const investorDetailsComponentResultsPerPageOptions = [3, 6];

const InvestorDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing32};
`;

const CounterpartDetailsPanelContainer = styled(Panel)`
  ${onMobile(css`
    box-shadow: none;
    border-radius: 0;
    padding: ${spacing8};
  `)}
`;

const CounterpartDetailsPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
