import React from 'react';
import { FormField } from '../common/FormField';
import { Checkbox } from '../../interface/forms/Checkbox';
import styled from 'styled-components/macro';
import { backgroundColours, borderColours } from '../../../styling/design/colours';
import { FieldValidity } from '../common/FieldValidity';

export const checkboxFieldTestId = (fieldName: string) => `checkbox-field-${fieldName}`;

type FieldAlignment = 'row' | 'column';

type CheckboxFieldProps = {
  fieldName: string;
  label?: string;
  helpText?: string;
  disabled?: boolean;
  fieldAlignment?: FieldAlignment;
};

export const CheckboxField = ({
  fieldName,
  label,
  helpText,
  disabled,
  fieldAlignment = 'column',
}: CheckboxFieldProps) => (
  <FormField<boolean> fieldName={fieldName} label={label} helpText={helpText}>
    {({ field, fieldValidity, formikContext }) => {
      const onChange = (isChecked: boolean) => {
        formikContext.setFieldValue(field.name, isChecked);
      };

      return (
        <FieldValidityCheckbox
          data-testid={checkboxFieldTestId(fieldName)}
          checked={field.value}
          name={field.name}
          onChange={onChange}
          onBlur={field.onBlur}
          disabled={disabled || formikContext.isSubmitting}
          fieldValidity={fieldValidity}
          fieldAlignment={fieldAlignment}
        />
      );
    }}
  </FormField>
);

const FieldValidityCheckbox = styled(Checkbox)<{
  fieldValidity: FieldValidity;
}>`
  outline-color: ${(props) =>
    props.disabled
      ? borderColours.disabled
      : props.fieldValidity === 'Valid'
      ? borderColours.positive
      : props.fieldValidity === 'Invalid'
      ? borderColours.negative
      : borderColours.default};

  background-color: ${(props) =>
    props.checked
      ? props.disabled
        ? backgroundColours.selectedDisabled
        : props.fieldValidity === 'Valid'
        ? backgroundColours.positive
        : props.fieldValidity === 'Invalid'
        ? backgroundColours.negative
        : backgroundColours.selected
      : props.disabled
      ? backgroundColours.disabled
      : backgroundColours.default};
`;
