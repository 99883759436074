import React, { useEffect } from 'react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import styled from 'styled-components/macro';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { fontSizeCss } from '../../../styling/design/fonts';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { SubmitButton } from '../../../infrastructure/forms/common/SubmitButton';
import { Validator } from 'fluentvalidation-ts';
import { Form } from '../../../infrastructure/forms/common/Form';
import { ErrorMessage } from '../../../infrastructure/interface/components/ErrorMessage';
import { notBeEmpty } from '../../validation/stringValidation';
import { AppLink } from '../../../infrastructure/interface/components/AppLink';
import { useUserActivityContext } from '../UserActivityContext';
import { useSessionTimeoutState } from '../useSessionTimeoutState';

type Props = {
  onSubmit: (
    formModel: EnterUsernameAndPasswordFormModel,
    formikHelpers: FormikHelpers<EnterUsernameAndPasswordFormModel>
  ) => void;
  error: string | null;
  requiresFirstTimePasswordReset: boolean;
  requiresPasswordReset: boolean;
  requiresUsernameChange: boolean;
};

export const EnterUsernameAndPasswordForm = (props: Props) => {
  const { translate } = useInternationalisation();
  const formModelValidator = new EnterUsernameAndPasswordFormModelValidator(translate);

  return (
    <Formik<EnterUsernameAndPasswordFormModel>
      initialValues={initialFormModel}
      onSubmit={props.onSubmit}
      validate={formModelValidator.validate}
    >
      <FormComponent {...props} />
    </Formik>
  );
};

const FormComponent = ({
  error,
  requiresFirstTimePasswordReset,
  requiresPasswordReset,
  requiresUsernameChange,
}: Props) => {
  const { translate } = useInternationalisation();
  const userActivityContext = useUserActivityContext();
  const formikContext = useFormikContext<EnterUsernameAndPasswordFormModel>();

  const { sessionHasExpired } = useSessionTimeoutState();

  const { username, password } = formikContext.values;
  useEffect(() => {
    userActivityContext.recordUserActivity();
  }, [username, password]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // "If I stay on the login page for at least X minutes (where X is the
    //  session lifetime) without interacting with the page at all, then the
    //  username and password fields will be automatically cleared."
    if (sessionHasExpired) {
      formikContext.setFieldValue('username', '');
      formikContext.setFieldValue('password', '');
      userActivityContext.recordUserActivity();
    }
  }, [sessionHasExpired]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form data-testid={enterUsernameAndPasswordFormTestId}>
      <InputField
        label={translate('pages.login.labels.username')}
        fieldName="username"
        disabled={requiresFirstTimePasswordReset || requiresPasswordReset || requiresUsernameChange}
      />
      <InputField
        label={translate('pages.login.labels.password')}
        fieldName="password"
        type="password"
        disabled={requiresFirstTimePasswordReset || requiresPasswordReset || requiresUsernameChange}
        highlight={requiresFirstTimePasswordReset || requiresPasswordReset}
      />
      {requiresFirstTimePasswordReset ? (
        <ResetPasswordPrompt>
          {translate('pages.login.firstTimeResetPasswordPrompt.preamble')}
          <AppLink
            to={`../forgot-password?username=${encodeURIComponent(formikContext.values.username)}`}
          >
            {translate('pages.login.firstTimeResetPasswordPrompt.resetYourPassword')}
          </AppLink>
          .
        </ResetPasswordPrompt>
      ) : requiresPasswordReset ? (
        <ResetPasswordPrompt>
          {translate('pages.login.resetPasswordPrompt.preamble')}
          <AppLink
            to={`../forgot-password?username=${encodeURIComponent(formikContext.values.username)}`}
          >
            {translate('pages.login.resetPasswordPrompt.resetYourPassword')}
          </AppLink>
          .
        </ResetPasswordPrompt>
      ) : requiresUsernameChange ? (
        <>{translate('pages.login.usernameChangeRequired')}</>
      ) : (
        <ResetPasswordLink to="../forgot-password">
          {translate('pages.login.forgotPassword')}
        </ResetPasswordLink>
      )}
      <SubmitButton
        submittingText={translate('pages.login.button.waitingText')}
        stretch={true}
        disabled={requiresPasswordReset || requiresUsernameChange}
      >
        {translate('pages.login.button.text')}
      </SubmitButton>
      {error != null && <ErrorMessage>{error}</ErrorMessage>}
    </Form>
  );
};

export const enterUsernameAndPasswordFormTestId = 'enter-username-and-password-form';

const ResetPasswordLink = styled(AppLink)`
  ${fontSizeCss('xsmall')};
`;

const ResetPasswordPrompt = styled.div`
  ${fontSizeCss('small')};

  ${AppLink} {
    ${fontSizeCss('small')};
  }
`;

export type EnterUsernameAndPasswordFormModel = {
  username: string;
  password: string;
};

const initialFormModel: EnterUsernameAndPasswordFormModel = {
  username: '',
  password: '',
};

class EnterUsernameAndPasswordFormModelValidator extends Validator<EnterUsernameAndPasswordFormModel> {
  constructor(translate: TranslateFunction) {
    super();
    this.ruleFor('username').must(notBeEmpty(translate));
    this.ruleFor('password').must(notBeEmpty(translate));
  }
}
