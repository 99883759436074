import { useJsonApiRequest } from './useJsonApiRequest';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';

type MakeRequestParameters<TRequestBody extends object, TResponse extends object> = {
  requestBody: TRequestBody;
  onSuccess?: (response: TResponse) => void;
  onFailure?: (error: string, response?: any) => void;
};

export const usePutJson = <TRequestBody extends object, TResponse extends object>(
  endpointUrl: string
) => {
  const { translate } = useInternationalisation();
  return usePutJsonCore<TRequestBody, TResponse>(endpointUrl, translate);
};

export const usePutJsonWithoutTranslation = <TRequestBody extends object, TResponse extends object>(
  endpointUrl: string
) => {
  const translate: TranslateFunction | null = null;
  return usePutJsonCore<TRequestBody, TResponse>(endpointUrl, translate);
};

export const usePutJsonCore = <TRequestBody extends object, TResponse extends object>(
  endpointUrl: string,
  translate: TranslateFunction | null
) => {
  const apiRequest = useJsonApiRequest<TRequestBody, TResponse>({
    method: 'put',
    endpointUrl,
    translate,
  });

  const makeRequest = ({
    requestBody,
    onSuccess,
    onFailure,
  }: MakeRequestParameters<TRequestBody, TResponse>) => {
    apiRequest.makeRequest({
      requestBody,
      onSuccess,
      onFailure,
    });
  };

  return {
    makeRequest,
    cancelRequest: apiRequest.cancelRequest,
    state: apiRequest.state,
    resetState: apiRequest.resetState,
  };
};
