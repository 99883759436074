import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { TenantSelect } from '../metadata/tenant/TenantSelect';
import React, { useState } from 'react';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { useSiteContext } from './SiteContext';

type Props = {
  onLoaded: () => void;
};

export const TenantSelector = ({ onLoaded }: Props) => {
  const { translate } = useInternationalisation();
  const { setSiteId, siteId } = useSiteContext();

  const showTenantSelectorKeyRequest = useGetJson<
    ShowTenantSelectorRequest,
    ShowTenantSelectorResponse
  >('/api/multiTenancy/ShowTenantSelector');

  const [filterLoadError, setFilterLoadError] = useState<string | null>(null);
  const [showSelector, setShowSelector] = useState<boolean>(false);

  useOnMount(() => {
    showTenantSelectorKeyRequest.makeRequest({
      onSuccess: (response: ShowTenantSelectorResponse) => {
        setShowSelector(response.show);
      },
    });
  });

  return (
    <>
      {showSelector && (
        <TenantSelect
          value={siteId ?? 1}
          fieldLabel={translate('pages.users.filters.tenantSelect')}
          onChange={(value) => {
            setSiteId(value);
          }}
          onError={setFilterLoadError}
          onLoaded={onLoaded}
          clearable={false}
          wrapInDiv={true}
        />
      )}
    </>
  );
};

export type ShowTenantSelectorResponse = {
  show: boolean;
};

type ShowTenantSelectorRequest = {};
