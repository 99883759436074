import React from 'react';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  cannotMapSettingsToValidQueryParameters,
  MetadataSingleSelect,
} from '../MetadataSingleSelect';
import { CapitalCallSelectSettings } from './CapitalCallSelectSettings';
import {
  GetCapitalCallDropdownOptionsResponse,
  mapGetCapitalCallDropdownOptionsResponseToSelectOptions,
} from './GetCapitalCallDropdownOptionsResponse';

export type CapitalCallSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  CapitalCallSelectOptionValue,
  CapitalCallSelectSettings,
  GetCapitalCallDropdownOptionsResponse
>;

export const CapitalCallSelect = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('pages.reports.parameters.capitalCallSelect.placeholder'); // TODO PC-120: Change this
  const loadingPlaceholder = translate('form.defaultLoadingPlaceholder');

  return (
    <MetadataSingleSelect<
      CapitalCallSelectOptionValue,
      CapitalCallSelectSettings,
      GetCapitalCallDropdownOptionsRequest,
      GetCapitalCallDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetCapitalCallDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={({ companyId, ...otherSettings }) =>
        companyId == null
          ? cannotMapSettingsToValidQueryParameters
          : {
              companyId,
              ...otherSettings,
            }
      }
      mapResponseToOptions={mapGetCapitalCallDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={250}
      {...metadataSingleSelectProps}
    />
  );
};

export type GetCapitalCallDropdownOptionsRequest = {
  companyId: number;
  includeBlank: boolean;
  employeeCounterpartId: number | null;
};
