import React, { useState } from 'react';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { ActionAlert } from '../../infrastructure/interface/components/ActionAlert';
import { useDeleteJson } from '../../infrastructure/api/useDeleteJson';
import { DeleteButton } from '../../infrastructure/interface/buttons/DeleteButton';
import { ButtonSize } from '../../infrastructure/interface/buttons/BaseButton';

type Props = {
  documentId: number;
  onDocumentDeleted: () => void;
  size: ButtonSize;
};

export const DocumentDeleteButton = ({ documentId, onDocumentDeleted, size }: Props) => {
  const { translate } = useInternationalisation();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const openAlert = () => setIsAlertOpen(true);
  const closeAlert = () => setIsAlertOpen(false);

  const apiRequest = useDeleteJson<DeleteDocumentCommand>(`api/documents/DeleteDocument`);

  const deleteDocument = () => {
    apiRequest.makeRequest({
      requestBody: { documentId },
      onSuccess: () => {
        closeAlert();
        onDocumentDeleted();
      },
    });
  };

  const { inProgress, error } = apiRequest.state;

  return (
    <>
      <DeleteButton onClick={openAlert} type="button" size={size} />
      <ActionAlert
        alertType="warning"
        isOpen={isAlertOpen}
        onRequestClose={closeAlert}
        title={translate('pages.documents.delete.confirmationAlert.title')}
        message={translate('pages.documents.delete.confirmationAlert.message')}
        cancelButtonText={translate('actionButtons.cancel')}
        confirmButtonText={translate('actionButtons.delete')}
        onInitiateAction={deleteDocument}
        actionInProgress={inProgress}
        actionError={error}
      />
    </>
  );
};

type DeleteDocumentCommand = {
  documentId: number;
};
