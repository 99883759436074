import React, { useEffect } from 'react';
import { IsoDatestamp, toDateFromIsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { LineGraph, LineGraphDataPoint } from '../../../infrastructure/charts/LineGraph';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { useDashboardFilters } from '../DashboardFiltersContext';
import { ComponentResponse } from '../DashboardComponent';

export const aumHistoryGraphComponentName = 'AUM History Graph';

export const AumHistoryGraph = () => {
  const endpointUrl = 'api/dashboards/GetDataForAumHistoryGraph';
  const getRequest = useGetJson<GetDataForAumHistoryGraphQuery, GetDataForAumHistoryGraphResponse>(
    endpointUrl
  );

  const { companyId, fromDate } = useDashboardFilters();

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <AumHistoryGraphComponent response={response} showLoadingOverlay={showLoadingOverlay} />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForAumHistoryGraphResponse;
  showLoadingOverlay: boolean;
};

const AumHistoryGraphComponent = ({ response, showLoadingOverlay }: ComponentProps) => {
  const { translate } = useInternationalisation();

  const graphData: Array<LineGraphDataPoint> = response.dataPoints.map((dataPoint) => ({
    x: toDateFromIsoDatestamp(dataPoint.date),
    y: dataPoint.value,
  }));

  const headerText =
    translate('pages.dashboard.components.aumHistoryGraph.title') +
      response.dataPoints[0]?.localCurrencyCode ?? 'Local Currency';

  return (
    <DashboardComponentLayout
      headerText={headerText}
      showLoadingOverlay={showLoadingOverlay}
      showNoDataMessage={response.noDataMessage}
    >
      <LineGraph
        dataPoints={graphData}
        exportFilename={translate('pages.dashboard.components.aumHistoryGraph.exportName')}
        height="stretch"
        xAxisType="datetime"
      />
    </DashboardComponentLayout>
  );
};

type GetDataForAumHistoryGraphQuery = {
  companyId: number;
  runDate: string;
};

export type GetDataForAumHistoryGraphResponse = ComponentResponse & {
  dataPoints: Array<{
    value: number;
    date: IsoDatestamp;
    localCurrencyCode: string | null;
  }>;
};
