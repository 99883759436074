import React from 'react';
import styled, { css } from 'styled-components/macro';
import { DashboardComponentResponse } from './GetDashboardsForCurrentUserResponse';
import { shadow1 } from '../../styling/design/shadows';
import { spacing32 } from '../../styling/design/spacing';
import { onDesktop, onNarrowDesktop, onMobile } from '../../styling/layout/screenBreakpoints';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import {
  TopTenPositionsChart,
  TopTenPositionsChartComponentName,
} from './components/TopTenPositionsChart';
import { AumHistoryGraph, aumHistoryGraphComponentName } from './components/AumHistoryGraph';
import {
  InvestorPerformanceGraph,
  investorPerformanceGraphComponentName,
} from './components/InvestorPerformanceGraph';
import {
  PrivateEquityFundSummary,
  privateEquityFundSummaryComponentName,
} from './components/PrivateEquityFundSummary';
import {
  RollingPerformanceChart,
  rollingPerformanceChartComponentName,
} from './components/RollingPerformanceChart';
import { ExposureChart, exposureChartComponentName } from './components/ExposureChart';
import { SecurityCostChart, securityCostChartComponentName } from './components/SecurityCostChart';
import { colourWhite } from '../../styling/design/colours';
import { AumHistoryTable, aumHistoryTableName } from './components/AumHistoryTable';
import { TopTenInvestors, TopTenInvestorsComponentName } from './components/TopTenInvestors';
import {
  TopTwentyInvestors,
  TopTwentyInvestorsComponentName,
} from './components/TopTwentyInvestors';
import { SecurityPositions, securityPositionsComponentName } from './components/SecurityPositions';
import { ManagerGraph, ManagerGraphComponentName } from './components/ManagerGraph';

import {
  SecuritiesUnrealised,
  SecuritiesUnrealisedComponentName,
} from './components/SecuritiesUnrealised';
import {
  FundPerformanceGraph,
  fundPerformanceGraphComponentName,
} from './components/FundPerformanceGraph';

import {
  InvestmentAllocationGraph,
  investmentAllocationGraphComponentName,
} from './components/InvestmentAllocationGraph';
import { KeyRatios, keyRatiosComponentName } from './components/KeyRatios';

type Props = {
  component: DashboardComponentResponse;
};

export const DashboardComponent = ({ component }: Props) => {
  const { translate } = useInternationalisation();

  let componentContent: React.ReactNode;

  switch (component.name) {
    case TopTenPositionsChartComponentName:
      componentContent = <TopTenPositionsChart />;
      break;
    case aumHistoryGraphComponentName:
      componentContent = <AumHistoryGraph />;
      break;
    case investorPerformanceGraphComponentName:
      componentContent = <InvestorPerformanceGraph />;
      break;
    case privateEquityFundSummaryComponentName:
      componentContent = <PrivateEquityFundSummary />;
      break;
    case exposureChartComponentName:
      componentContent = <ExposureChart />;
      break;
    case securityPositionsComponentName:
      componentContent = <SecurityPositions />;
      break;
    case securityCostChartComponentName:
      componentContent = <SecurityCostChart />;
      break;
    case rollingPerformanceChartComponentName:
      componentContent = <RollingPerformanceChart />;
      break;
    case aumHistoryTableName:
      componentContent = <AumHistoryTable />;
      break;
    case TopTenInvestorsComponentName:
      componentContent = <TopTenInvestors />;
      break;
    case TopTwentyInvestorsComponentName:
      componentContent = <TopTwentyInvestors />;
      break;
    case ManagerGraphComponentName:
      componentContent = <ManagerGraph />;
      break;
    case SecuritiesUnrealisedComponentName:
      componentContent = <SecuritiesUnrealised />;
      break;
    case fundPerformanceGraphComponentName:
      componentContent = <FundPerformanceGraph />;
      break;
    case investmentAllocationGraphComponentName:
      componentContent = <InvestmentAllocationGraph />;
      break;
    case keyRatiosComponentName:
      componentContent = <KeyRatios />;
      break;
    default:
      componentContent = translate('pages.dashboard.componentNotRecognised');
      break;
  }

  return (
    <DashboardComponentGridCard component={component}>
      {componentContent}
    </DashboardComponentGridCard>
  );
};

const DashboardComponentGridCard = styled.div<{ component: DashboardComponentResponse }>`
  background: ${colourWhite};
  border-radius: 10px;
  box-shadow: ${shadow1};
  overflow: hidden;
  min-height: 400px;

  ${(props) =>
    onDesktop(css`
      grid-column-start: ${props.component.columnIndex + 1};
      grid-column-end: span ${props.component.width};
      grid-row-start: ${props.component.rowIndex + 1};
      grid-row-end: span ${props.component.height};
    `)};
    
   ${onNarrowDesktop(css`
    &:not(:last-of-type) {
      margin-bottom: ${spacing32};
    }
  `)};

  ${onMobile(css`
    &:not(:last-of-type) {
      margin-bottom: ${spacing32};
    }
  `)};
`;

export type ComponentResponse = {
  noDataMessage?: string | null;
};
