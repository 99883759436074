export const getHumanReadableFileSize = (sizeInBytes: number): string => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInBytes < 1024 * 1024) {
    const sizeInKb = (sizeInBytes / 1024).toFixed(0);
    return `${sizeInKb} KB`;
  } else {
    const sizeInMb = (sizeInBytes / 1024 / 1024).toFixed(0);
    return `${sizeInMb} MB`;
  }
};
