import { IsoDatestamp } from '../../../../helpers/dateTimeHelpers';
import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import { FormatDateFunction } from '../../../../internationalisation/types/InternationalisationContextValue';
import { DealingDateSelectOptionValue } from './DealingDateSelect';

export type GetTransactionDealingDateDropdownOptionsResponse = {
  result: GetTransactionDealingDateDropdownOptionsResult;
  options: Array<TransactionDealingDateDropdownOption>;
};

export type GetTransactionDealingDateDropdownOptionsResult =
  | 'NoDealingDates'
  | 'NextAvailable'
  | 'WithOptions';

export type TransactionDealingDateDropdownOption = {
  dealingDate: IsoDatestamp | null;
};

export const mapGetTransactionDealingDateDropdownOptionsResponseToSelectOptions = (
  response: GetTransactionDealingDateDropdownOptionsResponse | null,
  dateFormatter: FormatDateFunction
): SelectOptions<DealingDateSelectOptionValue> =>
  !response
    ? []
    : response.options.map((option) => ({
        value: option.dealingDate,
        label: option.dealingDate == null ? '' : dateFormatter(option.dealingDate),
      }));
