import React from 'react';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../../MetadataSelectProps';
import { IsoDatestamp } from '../../../../helpers/dateTimeHelpers';
import { EquityAttributionSelectForTransactionsSettings } from './EquityAttributionSelectForTransactionsSettings';
import {
  GetTransactionEquityAttributionDropdownOptionsResponse,
  mapTransactionEquityAttributionDropdownOptionsResponseToSelectOptions,
} from './GetTransactionEquityAttributionDropdownOptionsResponse';
import { DealingTransactionTypeCode } from '../../transactions/DealingTransactionType';
import { MetadataSingleSelect } from '../../MetadataSingleSelect';

export type TransactionEquityAttributionSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  TransactionEquityAttributionSelectOptionValue,
  EquityAttributionSelectForTransactionsSettings,
  GetTransactionEquityAttributionDropdownOptionsResponse
>;

export const EquityAttributionSelectForTransactions = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.transactionEquityAttributionSelect.placeholder');
  const loadingPlaceholder = translate(
    'metadata.transactionEquityAttributionSelect.loadingPlaceholder'
  );

  return (
    <MetadataSingleSelect<
      TransactionEquityAttributionSelectOptionValue,
      EquityAttributionSelectForTransactionsSettings,
      GetTransactionEquityAttributionDropdownOptionsQuery,
      GetTransactionEquityAttributionDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetEquityAttributionDropdownOptionsForTransactions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapTransactionEquityAttributionDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={350}
      {...metadataSingleSelectProps}
    />
  );
};

export type GetTransactionEquityAttributionDropdownOptionsQuery = {
  investorId: number | null;
  companyId: number;
  transactionType: DealingTransactionTypeCode;
  dealingDate: IsoDatestamp;
};
