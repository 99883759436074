import { ApiRequestStateWrapper } from '../../../../infrastructure/api/ApiRequestStateWrapper';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { useWindowTitle } from '../../../../infrastructure/hooks/useWindowTitle';
import { Header1 } from '../../../../infrastructure/interface/components/Headers';
import { PageHeaderActions } from '../../../../infrastructure/interface/components/PageHeaderActions';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { CentredPaddedPage } from '../../../../styling/layout/PaddedPage';
import { RequiresUserRole } from '../../../authentication/UserRoles';
import { useInvestorIdUrlParam } from '../../useInvestorIdUrlParam';
import { DistributionReinvestmentActionResponse } from '../DistributionReinvestmentActionResponse';
import { ReinvestmentPreference } from '../DistributionReinvestmentsContext';
import {
  CreateEditDistributionReinvestmentForm,
  CreateEditDistributionReinvestmentFormModel,
} from './CreateEditDistributionReinvestmentForm';
import {
  GetCreateEditDistributionReinvestmentFormDefaultsResponse,
  useGetCreateEditDistributionReinvestmentFormDefaults,
} from './useGetCreateEditDistributionReinvestmentFormDefaults';

export const CreateDistributionReinvestment = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.createEditDistributionReinvestment.createTitle'));

  return (
    <RequiresUserRole userRole={['Investor', 'Consolidated Investor', 'Advisor']}>
      <CreateDistributionReinvestmentApiRequestStateWrapper />
    </RequiresUserRole>
  );
};

const CreateDistributionReinvestmentApiRequestStateWrapper = () => {
  const investorId = useInvestorIdUrlParam();
  const getFormDefaultsRequest = useGetCreateEditDistributionReinvestmentFormDefaults();

  const makeRequest = () =>
    getFormDefaultsRequest.makeRequest({
      queryParameters: {
        counterpartId: investorId,
        distributionReinvestmentId: null,
      },
    });

  useOnMount(() => {
    makeRequest();
  });

  return (
    <ApiRequestStateWrapper apiRequestState={getFormDefaultsRequest.state} retry={makeRequest}>
      {(response) => <CreateDistributionReinvestmentComponent formDefaultsResponse={response} />}
    </ApiRequestStateWrapper>
  );
};

type CreateDistributionReinvestmentComponentProps = {
  formDefaultsResponse: GetCreateEditDistributionReinvestmentFormDefaultsResponse;
};

const CreateDistributionReinvestmentComponent = (
  props: CreateDistributionReinvestmentComponentProps
) => {
  const { reinvestmentDefaultIsAll, ...initialFormValues } = props.formDefaultsResponse;

  const investorId = useInvestorIdUrlParam();
  const { translate } = useInternationalisation();

  const createDistributionReinvestmentRequest = usePostJson<
    CreateDistributionReinvestmentCommand,
    DistributionReinvestmentActionResponse
  >('/api/distribution-reinvestments/CreateDistributionReinvestment');

  const onConfirmAction = (
    formModel: CreateEditDistributionReinvestmentFormModel,
    closeConfirmationModal: () => void
  ) => {
    createDistributionReinvestmentRequest.makeRequest({
      requestBody: {
        counterpartId: investorId,
        companyId: formModel.companyId,
        reinvestmentPreference: formModel.reinvestmentPreference,
      },
      onSuccess: () => closeConfirmationModal(),
      onFailure: () => closeConfirmationModal(),
    });
  };

  return (
    <CentredPaddedPage>
      <Header1>{translate('pages.createEditDistributionReinvestment.createHeader')}</Header1>
      <PageHeaderActions withBackButton={true} />
      <CreateEditDistributionReinvestmentForm
        initialValues={initialFormValues}
        reinvestmentDefaultIsAll={reinvestmentDefaultIsAll}
        onConfirmAction={onConfirmAction}
        actionRequestState={createDistributionReinvestmentRequest.state}
      />
    </CentredPaddedPage>
  );
};

export type CreateDistributionReinvestmentCommand = {
  counterpartId: number | null;
  companyId: number;
  reinvestmentPreference: ReinvestmentPreference;
};
