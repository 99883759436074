import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import { GeneralLedgerCounterpartSelectOptionValue } from './GeneralLedgerCounterpartSelect';

export type GetGeneralLedgerCounterpartDropdownOptionsResponse = {
  options: Array<GeneralLedgerCounterpartDropdownOption>;
};

type GeneralLedgerCounterpartDropdownOption = {
  counterpartId: number;
  label: string;
};

export const mapGeneralLedgerCounterpartDropdownOptionsResponseToSelectOptions = (
  response: GetGeneralLedgerCounterpartDropdownOptionsResponse | null
): SelectOptions<GeneralLedgerCounterpartSelectOptionValue> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.counterpartId,
        label: option.label,
      }));
