import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';

export type GetCompanyDropdownOptionsForTransactionsResponse = {
  options: Array<CompanyDropdownOptionForTransactionsResponse>;
};

export type CompanyDropdownOptionForTransactionsResponse = {
  companyId: number;
  label: string;
};

export const mapCompanyDropdownOptionsForTransactionsResponseToSelectOptions = (
  response: GetCompanyDropdownOptionsForTransactionsResponse | null
): SelectOptions<number> =>
  !response
    ? []
    : response.options.map((option) => ({
        value: option.companyId,
        label: option.label,
      }));
