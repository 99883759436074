import { Route } from 'react-router-dom';
import { InvestorBankingDetailsLayout } from './InvestorBankingDetailsLayout';
import { InvestorBankingDetails } from './InvestorBankingDetails';
import { investorIdUrlParamName } from '../useInvestorIdUrlParam';
import { CreateBankingDetails } from './createEdit/create/CreateBankingDetails';
import { bankingDetailIdUrlParamName } from './useBankingDetailIdUrlParam';
import { EditBankingDetails } from './createEdit/edit/EditBankingDetails';
import React from 'react';

export const BankingDetailsRoutes = () => [
  <Route path="/banking-details" element={<InvestorBankingDetailsLayout />}>
    <Route index element={<InvestorBankingDetails />} />
    <Route path={`:${investorIdUrlParamName}`} element={<InvestorBankingDetails />} />
  </Route>,
  <Route path="/banking-details/create" element={<CreateBankingDetails />} />,
  <Route
    path={`/banking-details/:${investorIdUrlParamName}/create`}
    element={<CreateBankingDetails />}
  />,
  <Route
    path={`/banking-details/:${bankingDetailIdUrlParamName}/edit`}
    element={<EditBankingDetails />}
  />,
  <Route
    path={`/banking-details/:${investorIdUrlParamName}/:${bankingDetailIdUrlParamName}/edit`}
    element={<EditBankingDetails />}
  />,
];
