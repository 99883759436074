import React from 'react';
import styled from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { ItemDetailsCard } from '../../../infrastructure/interface/components/ItemDetailsCard';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import { fontSizeCss, fontWeightBold } from '../../../styling/design/fonts';
import { IfUserHasRole } from '../../authentication/UserRoles';
import { transactionLockCode } from '../../metadata/transactions/TransactionLock';
import {
  BulkTransactionDetailResponse,
  GetBulkTransactionsDetailsResponse,
} from './useGetBulkTransactionsDetailsRequest';

export type MobileConfirmationUploadTransactionsProps = {
  transactionsDetails: GetBulkTransactionsDetailsResponse;
};

export const MobileConfirmUploadTransactions = ({
  transactionsDetails,
}: MobileConfirmationUploadTransactionsProps) => {
  return (
    <>
      <ResultsContainer>
        {transactionsDetails.transactions?.map((transaction, index) => (
          <TransactionConfirmationCardComponent index={index} transactionRow={transaction} />
        ))}
      </ResultsContainer>
    </>
  );
};

type TransactionConfirmationCardComponentProps = {
  transactionRow: BulkTransactionDetailResponse;
  index: number;
};

const TransactionConfirmationCardComponent = ({
  transactionRow,
  index,
}: TransactionConfirmationCardComponentProps) => {
  const { formatDate, translate } = useInternationalisation();
  const getFieldLabel = (translationKey: string) =>
    translate(`pages.confirmTransaction.fieldLabels.${translationKey}`);

  return (
    <ItemDetailsCard
      subtitle={formatDate(
        transactionRow?.dealingDate ? transactionRow.dealingDate.toString() : ''
      )}
    >
      <TransactionHeadingComponent
        investor={transactionRow.investorName || null}
        fund={transactionRow.fundName}
      />
      <IfUserHasRole userRole={['Advisor', 'Consolidated Investor']}>
        <FieldAndValue
          type="text"
          fieldLabel={translate('pages.uploadTransactions.confirmation.transaction.fund')}
          value={transactionRow.fundName}
        />
      </IfUserHasRole>
      <FieldAndValue
        type="number"
        fieldLabel={translate('pages.uploadTransactions.confirmation.transaction.fileRow')}
        value={index + 2}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.uploadTransactions.confirmation.transaction.transactionType')}
        value={transactionRow.transactionTypeCode}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate(
          'pages.uploadTransactions.confirmation.transaction.shareClassOrSeries'
        )}
        value={transactionRow.shareClassSeries}
      />
      {transactionRow.transactionLock === transactionLockCode.Amount &&
        transactionRow.amount != null && (
          <FieldAndValue
            type="number"
            fieldLabel={translate('pages.uploadTransactions.confirmation.transaction.amount', {
              currencyCode: transactionRow.shareClassSeriesCurrencyCode,
            })}
            value={transactionRow.amount}
          />
        )}
      {transactionRow.transactionLock === transactionLockCode.Shares &&
        transactionRow.shares != null && (
          <FieldAndValue
            type="number"
            fieldLabel={translate('pages.uploadTransactions.confirmation.transaction.shares')}
            value={transactionRow.shares}
            options={{ decimalPlaces: transactionRow.shareDecimals }}
          />
        )}
      {transactionRow.feesString !== '' && (
        <FieldAndValue
          type="text"
          fieldLabel={translate('pages.uploadTransactions.confirmation.transaction.fees')}
          value={transactionRow.feesString}
        />
      )}
      {transactionRow.companySwitch && (
        <FieldAndValue
          type="text"
          fieldLabel={translate('pages.uploadTransactions.confirmation.transaction.switchToFund')}
          value={transactionRow.companySwitch}
        />
      )}
      {transactionRow.equityAttributionSwitch && (
        <FieldAndValue
          type="text"
          fieldLabel={translate(
            'pages.uploadTransactions.confirmation.transaction.switchToShareClassOrSeries'
          )}
          value={transactionRow.equityAttributionSwitch}
        />
      )}
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.uploadTransactions.confirmation.transaction.yourReference')}
        value={transactionRow.transactionReference}
      />
    </ItemDetailsCard>
  );
};

const TransactionHeadingComponent = ({ investor, fund }: TransactionHeadingProps) => {
  return (
    <>
      <IfUserHasRole userRole={['Advisor', 'Consolidated Investor']}>
        <TransactionFundContainer>{investor}</TransactionFundContainer>
      </IfUserHasRole>
      <IfUserHasRole userRole={'Investor'}>
        <TransactionFundContainer>{fund}</TransactionFundContainer>
      </IfUserHasRole>
    </>
  );
};

type TransactionHeadingProps = {
  investor: string | null;
  fund: string | null;
};

const ResultsContainer = styled.div`
  margin-top: ${spacing16};
`;

const TransactionFundContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${fontSizeCss('large')};
  font-weight: ${fontWeightBold};
`;
