import { SecondaryButton } from '../interface/buttons/SecondaryButton';
import styled from 'styled-components/macro';
import { ErrorMessage } from '../interface/components/ErrorMessage';
import { spacing12, spacing16, spacing8 } from '../../styling/design/spacing';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';

type Props = {
  error: string;
  retry?: () => void;
  className?: string;
};

export const InlineRequestFailedErrorMessage = ({ error, retry, className }: Props) => {
  const { translate } = useInternationalisation();

  return (
    <ErrorContainer className={className}>
      <StyledErrorMessage>{error}</StyledErrorMessage>
      {retry != null && (
        <SecondaryButton size="xsmall" onClick={retry}>
          {translate('errors.tryAgain')}
        </SecondaryButton>
      )}
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: ${spacing12} ${spacing16};
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 0 ${spacing8} 0 0;
`;
