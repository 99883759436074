import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { UserRoleCodeCharacter } from '../../authentication/UserRole';

export type GetRoleDropdownOptionsResponse = {
  options: Array<RoleDropdownOptionResponse>;
};

export type RoleDropdownOptionResponse = {
  codeCharacter: UserRoleCodeCharacter;
  name: string;
};

export const mapGetRoleDropdownOptionsResponseToDropdownOptions = (
  response: GetRoleDropdownOptionsResponse | null
): SelectOptions<UserRoleCodeCharacter> => {
  if (response == null) return [];

  return response.options.map((option) => ({
    value: option.codeCharacter,
    label: option.name,
  }));
};
