import React from 'react';
import { CompanySelectForTransactionsField } from '../../../metadata/company/forTransactions/CompanySelectForTransactionsField';
import { companySelectForTransactionsContextCode } from '../../../metadata/company/forTransactions/CompanySelectForTransactionsSettings';
import {
  dealingTransactionType,
  DealingTransactionTypeCode,
} from '../../../metadata/transactions/DealingTransactionType';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';

type Props = {
  transactionType: DealingTransactionTypeCode;
  onError: (error: string) => void;
  investorId: number | null;
};

export const PlaceTransactionSwitchToFundField = ({
  transactionType,
  onError,
  investorId,
}: Props) => {
  const { translate } = useInternationalisation();

  return shouldShowSwitchToFundField(transactionType) ? (
    <CompanySelectForTransactionsField
      data-testid={placeTransactionSwitchToFundSelectTestId}
      fieldName="switchToCompanyId"
      label={translate('pages.placeTransaction.labels.switchToFund')}
      onError={onError}
      settings={{
        investorId,
        context: companySelectForTransactionsContextCode.Switch,
      }}
    />
  ) : null;
};

export const shouldShowSwitchToFundField = (transactionType: DealingTransactionTypeCode) => {
  const validTypes: Array<DealingTransactionTypeCode> = [
    dealingTransactionType.Switch,
    dealingTransactionType.SwitchFull,
  ];

  return validTypes.includes(transactionType);
};

export const placeTransactionSwitchToFundSelectTestId = 'place-transaction-switch-to-fund-select';
