import { Route } from 'react-router-dom';
import { Dashboard } from './Dashboard';
import { EditMyDashboard } from './EditMyDashboard';
import { Dashboards } from './admin/Dashboards';
import { CreateDashboard } from './admin/CreateDashboard';
import { EditDashboard } from './admin/EditDashboard';
import React from 'react';

export const DashboardRoutes = () => [
  <Route path="/dashboard" element={<Dashboard />} />,
  <Route path="/dashboard/edit-my-dashboard" element={<EditMyDashboard />} />,
  <Route path="/dashboards" element={<Dashboards />} />,
  <Route path="/dashboards/create" element={<CreateDashboard />} />,
  <Route path="/dashboards/edit/:dashboardId" element={<EditDashboard />} />,
];
