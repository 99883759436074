import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useInternationalisation } from './hooks/useInternationalisation';
import { spacing8 } from '../styling/design/spacing';
import {
  PopoverMenu,
  PopoverMenuContainer,
  PopoverMenuItem,
} from '../infrastructure/interface/components/PopoverMenu';
import { useOnClickOutside } from '../infrastructure/hooks/useOnClickOutside';
import { MinimalButton } from '../infrastructure/interface/buttons/MinimalButton';

export const LanguageSelect = () => {
  const { getAvailableLanguages, getSelectedLanguage, setSelectedLanguage } =
    useInternationalisation();

  const availableLanguages = getAvailableLanguages();
  const selectedLanguage = getSelectedLanguage();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(menuRef, () => setIsMenuOpen(false));

  if (availableLanguages.length === 1) {
    return <></>;
  }
  return (
    <StyledPopoverMenuContainer ref={menuRef}>
      <MinimalButton buttonStyle="black" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Flag languageName={selectedLanguage} />
        <LanguageName>{selectedLanguage}</LanguageName>
      </MinimalButton>
      {isMenuOpen && (
        <PopoverMenu verticalPlacement="top" horizontalPlacement="left">
          {availableLanguages.map((languageName) => (
            <PopoverMenuItem key={languageName} onClick={() => setSelectedLanguage(languageName)}>
              <Flag languageName={languageName} />
              <LanguageName>{languageName}</LanguageName>
            </PopoverMenuItem>
          ))}
        </PopoverMenu>
      )}
    </StyledPopoverMenuContainer>
  );
};

const StyledPopoverMenuContainer = styled(PopoverMenuContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type FlagProps = { languageName: string };

const Flag = ({ languageName }: FlagProps) => (
  <FlagContainer>
    <img src={`/translations/${languageName}.svg`} alt={languageName} />
  </FlagContainer>
);

const FlagContainer = styled.div`
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 100%;

  img {
    height: 100%;
    width: 100%;
  }
`;

const LanguageName = styled.div`
  white-space: nowrap;
  margin-left: ${spacing8};
`;
