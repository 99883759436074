import React from 'react';
import styled from 'styled-components/macro';
import { ButtonSize } from './BaseButton';
import { spacing12, spacing16, spacing8 } from '../../../styling/design/spacing';
import { Spinner } from '../components/Spinner';
import { colourWhite } from '../../../styling/design/colours';

type Props = {
  size: ButtonSize;
};

export const ButtonSpinner = (props: Props) => (
  <ButtonSpinnerContainer {...props}>
    <Spinner size={getSpinnerSize(props.size)} colour={colourWhite} />
  </ButtonSpinnerContainer>
);

const ButtonSpinnerContainer = styled.div<Props>`
  margin-right: ${(props) => getMarginSize(props.size)};
  margin-left: ${(props) => getNegativeMarginSize(props.size)};
`;

const getSpinnerSize = (size: ButtonSize) => {
  switch (size) {
    case 'xsmall':
    case 'small':
      return 'small';
    case 'medium':
      return 'medium';
    case 'large':
    case 'xlarge':
      return 'large';
    default:
      return 'medium';
  }
};

const getMarginSize = (size: ButtonSize) => {
  switch (size) {
    case 'xsmall':
    case 'small':
      return spacing12;
    case 'medium':
      return spacing12;
    case 'large':
    case 'xlarge':
      return spacing16;
    default:
      return spacing12;
  }
};

const getNegativeMarginSize = (size: ButtonSize) => {
  switch (size) {
    case 'xsmall':
    case 'small':
    case 'medium':
      return 0;
    case 'large':
    case 'xlarge':
      return '-' + spacing8;
    default:
      return 0;
  }
};
