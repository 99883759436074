import { Validator } from 'fluentvalidation-ts';
import { Form, Formik, FormikHelpers } from 'formik';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import styled, { css } from 'styled-components/macro';
import { IsoDatestamp } from '../../../../helpers/dateTimeHelpers';
import { ApiRequestState } from '../../../../infrastructure/api/ApiRequestState';
import { RequestFailedAlert } from '../../../../infrastructure/api/RequestFailedAlert';
import { FormActionButtons } from '../../../../infrastructure/forms/common/FormActionButtons';
import { SingleSelectField } from '../../../../infrastructure/forms/fields/SingleSelectField';
import { useIsOpen } from '../../../../infrastructure/hooks/useIsOpen';
import { ActionAlert } from '../../../../infrastructure/interface/components/ActionAlert';
import { Alert } from '../../../../infrastructure/interface/components/Alert';
import { FieldAndValue } from '../../../../infrastructure/interface/components/FieldAndValue';
import { Panel } from '../../../../infrastructure/interface/components/Panel';
import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { TranslateFunction } from '../../../../internationalisation/types/InternationalisationContextValue';
import { onMobile } from '../../../../styling/layout/screenBreakpoints';
import { CompanySelectField } from '../../../metadata/company/CompanySelectField';
import { defaultCompanySelectSettings } from '../../../metadata/company/CompanySelectSettings';
import { useInvestorIdUrlParam } from '../../useInvestorIdUrlParam';
import { DistributionReinvestmentActionResponse } from '../DistributionReinvestmentActionResponse';
import { ReinvestmentPreference } from '../DistributionReinvestmentsContext';
import { useSessionEmployeeId } from '../../../../infrastructure/hooks/useSessionEmployeeId';

type Props = {
  onConfirmAction: (
    formModel: CreateEditDistributionReinvestmentFormModel,
    closeConfirmationModal: () => void
  ) => void;
  initialValues: CreateEditDistributionReinvestmentFormModel;
  reinvestmentDefaultIsAll: boolean;
  actionRequestState: ApiRequestState<DistributionReinvestmentActionResponse>;
};

export const CreateEditDistributionReinvestmentForm = (props: Props) => {
  const investorId = useInvestorIdUrlParam();
  const { translate } = useInternationalisation();
  const navigate = useNavigate();
  const { sessionEmployeeId } = useSessionEmployeeId();

  const [formLoadError, setFormLoadError] = useState<string | null>(null);
  const confirmationModalState = useIsOpen(false);

  const validator = useMemo(
    () => new CreateEditDistributionReinvestmentFormValidator(translate),
    [translate]
  );

  const onSubmit = (
    formModel: CreateEditDistributionReinvestmentFormModel,
    formikHelpers: FormikHelpers<CreateEditDistributionReinvestmentFormModel>
  ) => {
    confirmationModalState.open();
    formikHelpers.setSubmitting(false);
  };

  const reinvestmentPreferenceOptions: SelectOptions<ReinvestmentPreference> = [
    { value: 'Fully reinvest', label: translate('Fully reinvest') },
    { value: "Don't reinvest", label: translate("Don't reinvest") },
    ...(props.initialValues.reinvestmentPreference === 'Partially reinvest'
      ? [{ value: 'Partially reinvest' as ReinvestmentPreference, label: 'Partially reinvest' }]
      : []),
  ];

  if (formLoadError) {
    return <RequestFailedAlert error={formLoadError} />;
  }

  return (
    <Formik<CreateEditDistributionReinvestmentFormModel>
      initialValues={props.initialValues}
      onSubmit={onSubmit}
      validate={validator.validate}
    >
      {(formikProps) => (
        <>
          <Form>
            <StyledPanel>
              {props.reinvestmentDefaultIsAll ? (
                <FieldAndValue
                  type="text"
                  fieldLabel={translate(
                    'pages.createEditDistributionReinvestment.fieldLabels.fundName'
                  )}
                  value={translate('pages.createEditDistributionReinvestment.allInvestedFunds')}
                />
              ) : (
                <CompanySelectField
                  fieldName="companyId"
                  settings={{ ...defaultCompanySelectSettings, includeAllInvestedFunds: true, employeeCounterpartId: sessionEmployeeId }}
                  label={translate('pages.createEditDistributionReinvestment.fieldLabels.fundName')}
                  onError={setFormLoadError}
                />
              )}
              <FieldAndValue
                type="date"
                fieldLabel={translate(
                  'pages.createEditDistributionReinvestment.fieldLabels.validFrom'
                )}
                value={formikProps.values.validFrom}
              />
              <SingleSelectField
                fieldName="reinvestmentPreference"
                label={translate(
                  'pages.createEditDistributionReinvestment.fieldLabels.reinvestmentPreference'
                )}
                options={reinvestmentPreferenceOptions}
              />
              {props.actionRequestState.error && (
                <Alert alertType="negative" withMarginTop={true}>
                  {props.actionRequestState.error}
                </Alert>
              )}
              {props.actionRequestState.response?.status === 'pendingApproval' && (
                <Alert alertType="positive" withMarginTop={true}>
                  {translate('pages.createEditDistributionReinvestment.pendingApprovalMessage')}
                </Alert>
              )}
              {props.actionRequestState.response?.status === 'successful' && (
                <Alert alertType="positive" withMarginTop={true}>
                  {translate('pages.createEditDistributionReinvestment.successfulMessage')}
                </Alert>
              )}
            </StyledPanel>
            <FormActionButtons
              onCancel={() => navigate(`/distribution-reinvestments/${investorId}`)}
              cancelButtonText={translate('actionButtons.cancel')}
              submitButtonText={translate('actionButtons.save')}
              submitButtonSubmittingText={translate('actionButtons.saving')}
            />
          </Form>
          <ActionAlert
            alertType="warning"
            isOpen={confirmationModalState.isOpen}
            title={translate('pages.createEditDistributionReinvestment.confirmationModalTitle')}
            message={translate('pages.createEditDistributionReinvestment.confirmationModalMessage')}
            cancelButtonText={translate('actionButtons.cancel')}
            onRequestClose={confirmationModalState.close}
            confirmButtonText={translate('actionButtons.save')}
            confirmButtonTestId={confirmCreateEditDistributionReinvestmentButtonTestId}
            onInitiateAction={() =>
              props.onConfirmAction(formikProps.values, confirmationModalState.close)
            }
            actionInProgress={props.actionRequestState.inProgress}
            actionError={null}
          />
        </>
      )}
    </Formik>
  );
};

const StyledPanel = styled(Panel)`
  ${onMobile(css`
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  `)};
`;

class CreateEditDistributionReinvestmentFormValidator extends Validator<CreateEditDistributionReinvestmentFormModel> {
  constructor(translate: TranslateFunction) {
    super();

    this.ruleFor('reinvestmentPreference')
      .must((reinvestmentPreference) => reinvestmentPreference !== 'Partially reinvest')
      .withMessage(
        translate('pages.createEditDistributionReinvestment.partialReinvestmentErrorMessage')
      );
  }
}

export type CreateEditDistributionReinvestmentFormModel = {
  companyId: number;
  reinvestmentPreference: ReinvestmentPreference;
  validFrom: IsoDatestamp;
};

export const confirmCreateEditDistributionReinvestmentButtonTestId =
  'confirm-create-edit-dist-reinvestment';
