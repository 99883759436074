import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetCapitalCallDropdownOptionsResponse = {
  options: Array<GetCapitalCallDropdownOptionResponse>;
};

export type GetCapitalCallDropdownOptionResponse = {
  callId: number;
  callName: string;
};

export const mapGetCapitalCallDropdownOptionsResponseToSelectOptions = (
  response: GetCapitalCallDropdownOptionsResponse | null
): SelectOptions<number | null> =>
  !response
    ? []
    : response.options.map((option) => ({ value: option.callId, label: option.callName }));
