import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetTenantDropdownOptionsResponse = {
  options: Array<TenantDropdownOptionResponse>;
};

export type TenantDropdownOptionResponse = {
  id: number;
  code: string;
  description: string;
};

export const mapTenantDropdownOptionsResponseToSelectOptions = (
  response: GetTenantDropdownOptionsResponse | null
): SelectOptions<number> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.id,
        label: option.description,
      }));
