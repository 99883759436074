import React from 'react';
import { CreateEditBankingDetailsFormModel } from '../CreateEditBankingDetailsFormFields';
import { FormikHelpers, FormikProps } from 'formik';
import { GetCreateBankingDetailsDefaultsResponse } from './GetCreateBankingDetailsDefaultsResponse';
import { useInvestorIdUrlParam } from '../../../useInvestorIdUrlParam';
import { BankingDetailsActionResponse } from '../../BankingDetailsActionResponse';
import { MfaModalFormModel } from '../../../../authentication/multiFactorAuthentication/MultiFactorAuthenticationModal';
import { BankingDetailsActionStatusCode } from '../../BankingDetailsActionStatus';
import { clearingCodeValidationStateType } from '../ClearingCodeValidationState';
import { CreateEditBankingDetailsForm } from '../CreateEditBankingDetailsForm';
import {
  CreateEditBankingDetailsRequest,
  toCreateEditBankingDetailsRequest,
} from '../CreateEditBankingDetailsRequest';
import { usePostJson } from '../../../../../infrastructure/api/usePostJson';
import { useLogoutDueToLockOut } from '../../../../../infrastructure/hooks/useLogoutDueToLockOut';

type Props = {
  formDefaults: GetCreateBankingDetailsDefaultsResponse;
  localClearingDetailsOnly: boolean;
  onFormLoadError: (error: string) => void;
  onSuccess: (statusCode: BankingDetailsActionStatusCode) => void;
};

export const CreateBankingDetailsForm = ({
  formDefaults,
  localClearingDetailsOnly,
  onFormLoadError,
  onSuccess,
}: Props) => {
  const { logoutDueToLockOut } = useLogoutDueToLockOut();

  const investorId = useInvestorIdUrlParam();

  const createBankingDetailsRequest = usePostJson<
    CreateEditBankingDetailsRequest,
    BankingDetailsActionResponse
  >('/api/banking-details/CreateBankingDetails');

  const onMfaFormSubmit = (
    createEditBankingDetailsFormikProps: FormikProps<CreateEditBankingDetailsFormModel>,
    mfaToken: string,
    mfaFormFormikHelpers: FormikHelpers<MfaModalFormModel>
  ) => {
    createEditBankingDetailsFormikProps.setSubmitting(true);

    createBankingDetailsRequest.makeRequest({
      requestBody: toCreateEditBankingDetailsRequest(
        createEditBankingDetailsFormikProps.values,
        mfaToken,
        investorId,
        localClearingDetailsOnly
      ),
      onSuccess: (response) => {
        createEditBankingDetailsFormikProps.setSubmitting(false);
        mfaFormFormikHelpers.setSubmitting(false);
        onSuccess(response.statusCode);
      },
      onFailure: (error, response) => {
        if (response.translationKey === 'errors.authentication.accountIsLockedOut') {
          //user has been locked out, so force logout
          logoutDueToLockOut(response.translationKey);
        }

        createEditBankingDetailsFormikProps.setSubmitting(false);
        mfaFormFormikHelpers.setSubmitting(false);
      },
    });
  };

  return (
    <CreateEditBankingDetailsForm<BankingDetailsActionResponse>
      formAction="Create"
      initialFormValues={getInitialFormValues(formDefaults)}
      localClearingDetailsOnly={localClearingDetailsOnly}
      onFormLoadError={onFormLoadError}
      onMfaFormSubmit={onMfaFormSubmit}
      createEditRequestState={createBankingDetailsRequest.state}
    />
  );
};

const getInitialFormValues = (
  formDefaults: GetCreateBankingDetailsDefaultsResponse
): CreateEditBankingDetailsFormModel => ({
  productCode: formDefaults.productCode,
  companyId: null,
  currencyId: formDefaults.currencyId,
  accountName: formDefaults.accountName,
  bankId: null,
  bankName: '',
  bankSwiftCode: '',
  bankAccountNumber: '',
  clearingCodeTypeId: formDefaults.clearingCodeTypeId,
  clearingCode: '',
  clearingCodeValidationState: clearingCodeValidationStateType.checkCodeRequired,
  iban: '',
  otherIdentifier: '',
  beneficiaryAddress: formDefaults.beneficiaryAddress,
  paymentReference: '',
  intermediaryBankId: null,
  intermediaryBankName: '',
  intermediaryBankSwiftCode: '',
});
