import { useRef } from 'react';
import { useOnUnmount } from './useOnUnmount';

export const useIsMounted = () => {
  const isMounted = useRef(true);

  useOnUnmount(() => {
    isMounted.current = false;
  });

  // It's important to return the actual ref rather than the value of the
  // `current` property, since otherwise we'll always just be passing down the
  // initial value of `false`.
  return isMounted;
};
