import { usePostJson } from '../../../../infrastructure/api/usePostJson';

export const BatchCreateUsersRequest = (accessGroupId: number) => {
  const batchDeleteUsersRequest = usePostJson<BatchUsersCreateCommand, BatchUsersCreateResponse>(
    '/api/users/CreateUsersBatch'
  );

  const submit = (
    usersToCreate: Array<UserToCreate>,
    setSubmitting: (isSubmitting: boolean) => void,
    onSuccess: (response: BatchUsersCreateResponse) => void
  ) => {
    batchDeleteUsersRequest.makeRequest({
      requestBody: {
        accessGroupId: accessGroupId,
        newUsers: usersToCreate,
      },
      onSuccess: (response) => {
        setSubmitting(false);
        onSuccess(response);
      },
      onFailure: () => setSubmitting(false),
    });
  };

  return {
    submit,
  };
};

export type UserToCreate = {
  counterpartId: number;
  username: string;
};

type BatchUsersCreateCommand = {
  accessGroupId: number;
  newUsers: Array<UserToCreate>;
};

export type UsersCreated = {
  username: string;
  counterpart: string;
  counterpartId: number;
  emailAddress: string;
  role: string;
  accessGroup: string;
  status: string;
  errorMessage?: string;
};

type BatchUsersCreateResponse = {
  createdUsers: Array<UsersCreated>;
};
