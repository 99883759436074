import React, { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { RequiresUserRole } from '../authentication/UserRoles';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import {
  GetDataForTransactionsQuery,
  GetDataForTransactionsResponse,
  TransactionsFilterState
} from './types';
import { defaultTransactionsFilterState, TransactionsContext } from './TransactionsContext';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { MobileTransactions } from './MobileTransactions';
import { DesktopTransactions } from './DesktopTransactions';
import { useUrlState } from '../../infrastructure/hooks/useUrlState';
import { IsoDatestamp } from '../../helpers/dateTimeHelpers';
import { RequestFailedPage } from '../../infrastructure/api/RequestFailedPage';
import { useSessionCompanyId } from '../../infrastructure/hooks/useSessionCompanyId';
import { useSessionEmployeeId } from '../../infrastructure/hooks/useSessionEmployeeId';
import { RequiresMenuOption } from '../authentication/UserMenuOptions';
import { SortDirection } from '../../infrastructure/interface/tables/SortDirection';

export const Transactions = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.transactions.title'));

  return (
    <RequiresUserRole userRole={allNonAdminUserRoles}>
      <RequiresMenuOption menuOption={'transactions'}>
        <TransactionsComponent />
      </RequiresMenuOption>
    </RequiresUserRole>
  );
};

const TransactionsComponent = () => {
  const getRequest = useGetJson<GetDataForTransactionsQuery, GetDataForTransactionsResponse>(
    '/api/transactions/GetDataForTransactionsPage'
  );

  const {
    state: filterState,
    setState: setFilterState,
    updateState,
    useUrlStateValue,
  } = useUrlState<TransactionsFilterState>(defaultTransactionsFilterState, {
    pageNumber: { type: 'number' },
    resultsPerPage: { type: 'number' },
    sort: { type: 'string', isNullable: true },
    dir: { type: 'string', isNullable: true },
    employeeId: { type: 'number', isNullable: true },
    investorId: { type: 'number', isNullable: true },
    unapprovedOnly: { type: 'boolean', isNullable: true },
    startDate: { type: 'string', isNullable: true },
    endDate: { type: 'string', isNullable: true },
    transactionTypes: { type: 'string[]', isNullable: true },
    companyId: { type: 'number', isNullable: true },
    equityAttributionId: { type: 'number', isNullable: true },
    agentId: { type: 'number', isNullable: true },
    dealerId: { type: 'number', isNullable: true },
  });

  const [pageNumber, setPageNumber] = useUrlStateValue('pageNumber');
  const [response, setResponse] = useState<GetDataForTransactionsResponse | null>(null);
  const [inProgress, setInProgress] = useState<boolean>(getRequest.state.inProgress);
  const [filterLoadError, setFilterLoadError] = useState<string | null>(null);

  const resultsPerPage = filterState.resultsPerPage;
  const setResultsPerPage = (resultsPerPage: number) =>
    updateState({ resultsPerPage, pageNumber: 1 });

  const sort = filterState.sort;
  const setSort = (sort: string) => updateState({ sort, pageNumber: 1 });
  
  const dir = filterState.dir;
  const setDir = (dir: SortDirection) => updateState({ dir, pageNumber: 1 });
  const onResponseChanged = (transactionsResponse: GetDataForTransactionsResponse | null) => {
    setInProgress(false);
    setResponse(transactionsResponse);
  };

  const { sessionEmployeeId } = useSessionEmployeeId();
  const employeeId = filterState.employeeId ?? sessionEmployeeId;
  const setEmployeeId = (employeeId: number | null) => updateState({ employeeId, pageNumber: 1 });
  
  const investorId = filterState.investorId;
  const setInvestorId = (investorId: number | null) => updateState({ investorId, pageNumber: 1 });

  const { sessionCompanyId } = useSessionCompanyId();
  const companyId = filterState.companyId ?? sessionCompanyId;

  const setCompanyId = (companyId: number | null) => updateState({ companyId, pageNumber: 1 });

  const equityAttributionId = filterState.equityAttributionId;
  const setEquityAttributionId = (equityAttributionId: number | null) =>
    updateState({ equityAttributionId, pageNumber: 1 });

  const startDate = filterState.startDate;
  const setStartDate = (startDate: IsoDatestamp | null) =>
    updateState({ startDate, pageNumber: 1 });

  const endDate = filterState.endDate;
  const setEndDate = (endDate: IsoDatestamp | null) => updateState({ endDate, pageNumber: 1 });

  const transactionTypes = filterState.transactionTypes;
  const setTransactionTypes = (transactionTypes: Array<string | null>) =>
    updateState({ transactionTypes, pageNumber: 1 });

  const agentId = filterState.agentId;
  const setAgentId = (agentId: number | null) => updateState({ agentId, pageNumber: 1 });

  const dealerId = filterState.dealerId;
  const setDealerId = (dealerId: number | null) => updateState({ dealerId, pageNumber: 1 });

  const unapprovedOnly = filterState.unapprovedOnly;
  const setUnapprovedOnly = (unapprovedOnly: boolean | null) =>
    updateState({ unapprovedOnly, pageNumber: 1 });

  const resetFilters = () => {
    if (!isEqual(filterState, defaultTransactionsFilterState)) {
      setFilterState(defaultTransactionsFilterState);
      setResponse(null);
    }
  };

  const makeRequest = useCallback(
    () => {
      getRequest.makeRequest({
        queryParameters: {
          pageNumber,
          resultsPerPage,
          sort,
          dir,
          investorId,
          unapprovedOnly,
          startDate,
          endDate,
          transactionTypes,
          companyId,
          equityAttributionId,
          agentId,
          dealerId,
          employeeId,
        },
        onSuccess: setResponse,
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pageNumber,
      resultsPerPage,
      sort,
      dir,
      startDate,
      endDate,
      investorId,
      transactionTypes,
      companyId,
      equityAttributionId,
      agentId,
      dealerId,
      unapprovedOnly,
      employeeId,
    ]
  );

  useEffect(() => {
    setInProgress(getRequest.state.inProgress);
  }, [getRequest.state.inProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    makeRequest();
  }, [makeRequest]);

  if (filterLoadError) {
    return <RequestFailedPage error={filterLoadError} />;
  }

  if (getRequest.state.error) {
    return <RequestFailedPage error={getRequest.state.error} retry={() => makeRequest()} />;
  }

  return (
    <PaddedPage>
      <TransactionsContext.Provider
        value={{
          pageNumber,
          setPageNumber,
          resultsPerPage,
          setResultsPerPage,
          sort,
          setSort,
          dir,
          setDir,
          response,
          setResponse: onResponseChanged,
          inProgress,
          setInProgress,
          employeeId,
          setEmployeeId,
          investorId,
          setInvestorId,
          companyId,
          setCompanyId,
          equityAttributionId,
          setEquityAttributionId,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
          transactionTypes,
          setTransactionTypes,
          agentId,
          setAgentId,
          dealerId,
          setDealerId,
          unapprovedOnly,
          setUnapprovedOnly,
          resetFilters,
          filterLoadError,
          setFilterLoadError,
          loadData: makeRequest,
        }}
      >
        <DesktopOnly>
          <DesktopTransactions />
        </DesktopOnly>
        <MobileOnly>
          <MobileTransactions />
        </MobileOnly>
      </TransactionsContext.Provider>
    </PaddedPage>
  );
};

export const transactionsPageEmployeeSelectTestId = 'transactions-page-employee-select';
export const transactionsPageInvestorSelectTestId = 'transactions-page-investor-select';
export const transactionsPageCompanySelectTestId = 'transactions-page-company-select';
export const transactionsPageEquityAttributionSelectTestId =
  'transactions-page-equityAttribution-select';
export const transactionsPageTransactionTypeSelectTestId =
  'transactions-page-transactionType-select';
export const transactionsPageAgentSelectTestId = 'transactions-page-agent-select';
export const transactionsPageDealerSelectTestId = 'transactions-page-dealer-select';
