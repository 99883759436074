import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { EquityAttributionSelectOptionValue } from './EquityAttributionSelect';

export type GetEquityAttributionDropdownOptionsResponse = {
  options: Array<EquityAttributionDropdownOptionResponse>;
};

export type EquityAttributionDropdownOptionResponse = {
  equityAttributionId: number;
  description: string;
};

export const mapEquityAttributionDropdownOptionsResponseToSelectOptions = (
  response: GetEquityAttributionDropdownOptionsResponse | null
): SelectOptions<EquityAttributionSelectOptionValue> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.equityAttributionId,
        label: option.description,
      }));
