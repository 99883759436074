const blankStringErrorMessage = 'please provide a valid value. Value must be a non-empty string';

export const isNullUndefinedOrBlank = (stringValue?: string | null): boolean => {
  return stringValue == null || isBlank(stringValue);
};

export const isBlank = (stringValue: string): boolean => {
  return stringValue.trim() === '';
};

export const assertNotBlank = (stringValue: string, errorMessage?: string): void => {
  if (isBlank(stringValue)) {
    throw new Error(errorMessage ? errorMessage : blankStringErrorMessage);
  }
};

export const nullIfBlank = (string: string): string | null => (isBlank(string) ? null : string);

export const parseIntOrNull = (
  numberString: string | null | undefined,
  radix?: number
): number | null => {
  const parsedValue = parseInt(numberString ?? '', radix);
  return isNaN(parsedValue) ? null : parsedValue;
};

export const toAbsoluteLink = (link: string) =>
  link.startsWith('http') ? link : `https://${link}`;

/**
 * Converts snake_case or kebab-case string to camelCase string
 * @param value
 */
export const snakeOrKebabCaseToCamelCase = (value: string): string => {
  return value.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());
};
/**
 * Converts camelCase string to snake_case string.
 * @param value
 */
export const camelCaseToSnakeCase = (value: string): string => {
  return value.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};
