import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';
import {
  GetCompanyValueDateDropdownOptionsResponse,
  mapGetCompanyValueDateDropdownOptionsResponseToSelectOptions,
} from '../companyValueDate/GetCompanyValueDateDropdownOptionsResponse';
import { CompanyReportGroupValueDateSelectSettings } from './CompanyReportGroupValueDateSelectSettings';

export type CompanyReportGroupValueDateSelectOptionValue = IsoDatestamp | null;

type Props = MetadataSingleSelectComponentProps<
  CompanyReportGroupValueDateSelectOptionValue,
  CompanyReportGroupValueDateSelectSettings,
  GetCompanyValueDateDropdownOptionsResponse
>;

export const CompanyReportGroupValueDateSelect = ({
  settings,
  ...metadataSingleSelectProps
}: Props) => {
  const { translate, formatDate } = useInternationalisation();

  const placeholder = translate('metadata.companyValueDateSelect.placeholder');
  const loadingPlaceholder = translate('metadata.companyValueDateSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      CompanyReportGroupValueDateSelectOptionValue,
      CompanyReportGroupValueDateSelectSettings,
      GetCompanyReportGroupValueDateDropdownOptionsRequest,
      GetCompanyValueDateDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetCompanyReportGroupValueDateDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={(response) =>
        mapGetCompanyValueDateDropdownOptionsResponseToSelectOptions(response, formatDate)
      }
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={200}
      {...metadataSingleSelectProps}
    />
  );
};

export type GetCompanyReportGroupValueDateDropdownOptionsRequest = {
  companyReportGroupId: number;
  plusOne: boolean;
  reportId: number | null;
  employeeCounterpartId: number | null;
};
