import { useGetJson } from '../../../../infrastructure/api/useGetJson';

export const GetUsersForBatchCreateRequest = (
  setLatestResponse:
    | React.Dispatch<React.SetStateAction<GetUsersForBatchCreateResponse | null>>
    | ((response: any) => void)
) => {
  const request = useGetJson<GetUsersForBatchCreateQuery, GetUsersForBatchCreateResponse>(
    '/api/users/GetUsersForBatchCreate'
  );

  const get = (query: GetUsersForBatchCreateQuery) => {
    request.makeRequest({
      queryParameters: query,
      onSuccess: setLatestResponse,
    });
  };

  return {
    get,
    state: request.state,
  };
};

type GetUsersForBatchCreateQuery = {
  pageNumber: number;
  resultsPerPage: number;
  accessGroupId: number;
};

export type GetUsersForBatchCreateResponse = {
  users: Array<UserToCreateResponse>;
  count: number;
};

export type UserToCreateResponse = {
  suggestedUsername: string;
  counterpart: string;
  counterpartId: number;
  emailAddress: string;
  role: string;
  accessGroup: string;
  status: string;
};
