import styled from 'styled-components/macro';
import { fontWeightBold } from '../../../styling/design/fonts';

export const MessageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: ${fontWeightBold};
`;
