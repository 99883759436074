export const shadow1 = '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)';
export const shadow2 = '0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)';
export const shadow3 = '0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1)';
export const shadow4 = '0 15px 25px rgba(0, 0, 0, 0.15), 0 5px 10px rgba(0, 0, 0, 0.05)';
export const shadow5 = '0 20px 40px rgba(0, 0, 0, 0.2)';

export const insetTopShadow = 'inset 0 11px 8px -10px rgba(0, 0, 0, 0.2)';
export const insetBottomShadow = 'inset 0 -11px 8px -10px rgba(0, 0, 0, 0.2)';
export const insetRightShadow = 'inset -10px 0 8px -11px rgba(0, 0, 0, 0.2);';
export const insetLeftShadow = 'inset 10px 0 8px -11px rgba(0, 0, 0, 0.2);';
