import React from 'react';
import {
  FieldAndValueWithSeparatorsContainer,
  FieldAndValue,
} from '../../infrastructure/interface/components/FieldAndValue';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { HasUserRole, IfUserHasRole } from '../authentication/UserRoles';
import {
  communicationPreferencesByCode,
  GetInvestorDetailsResponse, ManagerOnlyInvestorDetails
} from './GetInvestorDetailsResponse';
import {
  getAgentOrBeneficialOwnerDetailsOptions,
  GetAgentOrBeneficialOwnerDetailsResponse,
} from './AgentOrBeneficialOwnerDetailsModal';
import { useInvestorDetailsContext } from './InvestorDetailsContext';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';

type Props = {
  counterpartDetails: GetInvestorDetailsResponse | GetAgentOrBeneficialOwnerDetailsResponse;
};

export const CounterpartDetails = ({ counterpartDetails }: Props) => {
  const { translate } = useInternationalisation();
  const { setAgentOrBeneficialOwnerDetailsModalState } = useInvestorDetailsContext();

  const translateFieldName = (fieldName: string) =>
    translate(`pages.investorDetailsPage.counterpartDetails.fieldNames.${fieldName}`);

  const isInvestorDetailsResponse = (
    details: GetInvestorDetailsResponse | GetAgentOrBeneficialOwnerDetailsResponse
  ): details is GetInvestorDetailsResponse => {
    return 'taxNumber' in details;
  };

  return (
    <FieldAndValueWithSeparatorsContainer>
      <FieldAndValue
        fieldLabel={translateFieldName('name')}
        type="text"
        value={counterpartDetails.name}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('alternativeName')}
        type="text"
        value={counterpartDetails.alternativeName}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('contactPerson')}
        type="text"
        value={counterpartDetails.contactPerson}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('phoneNumber1')}
        type="text"
        value={counterpartDetails.phoneNumber1}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('phoneNumber2')}
        type="text"
        value={counterpartDetails.phoneNumber2}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('mobileNumber')}
        type="text"
        value={counterpartDetails.mobileNumber}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('email')}
        type="text"
        value={counterpartDetails.emailAddress}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('registeredAddress')}
        type="text"
        value={[
          counterpartDetails.addressLine1,
          counterpartDetails.addressLine2,
          counterpartDetails.addressLine3,
          counterpartDetails.addressLine4,
        ]}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('mailingAddress')}
        type="text"
        value={[
          counterpartDetails.mailingAddressLine1,
          counterpartDetails.mailingAddressLine2,
          counterpartDetails.mailingAddressLine3,
          counterpartDetails.mailingAddressLine4,
        ]}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('fax')}
        type="text"
        value={counterpartDetails.faxNumber}
      />
      <IfUserHasRole userRole="Manager">
        {(counterpartDetails as GetInvestorDetailsResponse).dobDisplay && (
          <FieldAndValue
            fieldLabel={translateFieldName('dob')}
            type="date"
            value={counterpartDetails.dobDateStamp}
            options={{ dateStyle: 'medium' }}
          />
        )}
        {counterpartDetails.webUserName != null && (
          <FieldAndValue
            fieldLabel={translateFieldName('username')}
            type="text"
            value={counterpartDetails.webUserName}
          />
        )}
        <FieldAndValue
          fieldLabel={translateFieldName('webAccess')}
          type="text"
          value={counterpartDetails.webAccess}
        />
      </IfUserHasRole>
      {isInvestorDetailsResponse(counterpartDetails) && (
        <IfUserHasRole userRole={['Investor', 'Advisor', 'Consolidated Investor']}>
          <NonManagerInvestorDetails
            investorDetails={counterpartDetails}
            translateFieldName={translateFieldName}
          />
        </IfUserHasRole>
      )}
      <FieldAndValue
        fieldLabel={translateFieldName('preferredReportingCurrency')}
        type="text"
        value={counterpartDetails.preferredReportingCurrency}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('investorId')}
        type="text"
        value={counterpartDetails.counterpartId.toString()}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('investorNumber')}
        type="text"
        value={counterpartDetails.investorNumber}
      />
      <FieldAndValue
        fieldLabel={translateFieldName('commsPreference')}
        type="text"
        value={communicationPreferencesByCode[counterpartDetails.communicationPreferenceCode]}
      />
      <IfUserHasRole userRole="Manager">
        {(counterpartDetails as ManagerOnlyInvestorDetails).holdMail && (
          <FieldAndValue
            fieldLabel={translateFieldName('holdMail')}
            type="text"
            value={(counterpartDetails as ManagerOnlyInvestorDetails).holdMail}
          />
        )}
      </IfUserHasRole>
      <HasUserRole userRole="Manager">
        {(userIsManager) => (
          <FieldAndValue
            fieldLabel={translateFieldName('agent')}
            type="custom"
            value={
              userIsManager &&
              isInvestorDetailsResponse(counterpartDetails) &&
              counterpartDetails.agentId != null ? (
                <MinimalButton
                  textAlign="left"
                  onClick={() =>
                    setAgentOrBeneficialOwnerDetailsModalState({
                      isOpen: true,
                      counterpartId: counterpartDetails.agentId,
                      optionCode: getAgentOrBeneficialOwnerDetailsOptions.agent,
                    })
                  }
                >
                  {counterpartDetails.agent}
                </MinimalButton>
              ) : (
                counterpartDetails.agent
              )
            }
          />
        )}
      </HasUserRole>
      <FieldAndValue
        fieldLabel={translateFieldName('agentWebAccess')}
        type="text"
        value={counterpartDetails.agentWebAccess}
      />
    </FieldAndValueWithSeparatorsContainer>
  );
};

type NonManagerInvestorDetailsProps = {
  investorDetails: GetInvestorDetailsResponse;
  translateFieldName: (fieldName: string) => string;
};

const NonManagerInvestorDetails = ({
  investorDetails,
  translateFieldName,
}: NonManagerInvestorDetailsProps) => (
  <>
    <FieldAndValue
      fieldLabel={translateFieldName('taxNumber')}
      type="text"
      value={investorDetails.taxNumber}
    />
    <FieldAndValue
      fieldLabel={translateFieldName('usTaxNumber')}
      type="text"
      value={investorDetails.usTaxNumber}
    />
    <FieldAndValue
      fieldLabel={translateFieldName('companyNumber')}
      type="text"
      value={investorDetails.companyNumber}
    />
    <FieldAndValue
      fieldLabel={translateFieldName('businessNumber')}
      type="text"
      value={investorDetails.businessNumber}
    />
    {investorDetails.showFatcaCrsStatus && (
      <>
        <FieldAndValue
          fieldLabel={translateFieldName('fatcaStatus')}
          type="text"
          value={investorDetails.fatcaStatus}
        />
        <FieldAndValue
          fieldLabel={translateFieldName('crsStatus')}
          type="text"
          value={investorDetails.crsStatus}
        />
      </>
    )}
  </>
);
