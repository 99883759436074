import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import * as spacing from '../../../styling/design/spacing';
import { spacing16 } from '../../../styling/design/spacing';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InfoCircleSolidIcon,
  TimesCircleSolidIcon,
  TimesSolidIcon,
} from '../../../icons/icons';
import {
  backgroundColours,
  borderColours,
  colourGrey08,
  textColours,
} from '../../../styling/design/colours';
import { shadow1 } from '../../../styling/design/shadows';
import { fontWeightBold } from '../../../styling/design/fonts';

export type AlertType = 'notice' | 'positive' | 'negative' | 'warning';

const HTMLWrapper = ({ html }: { html: any } ) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

type Props = {
  alertType: AlertType;
  header?: string | React.ReactNode;
  children: React.ReactNode;
  isDismissible?: boolean;
  withMarginTop?: boolean;
  withMarginBottom?: boolean;
  formatHtml?: boolean;
};

export const Alert = ({
  alertType,
  header,
  children,
  isDismissible,
  withMarginTop,
  withMarginBottom,
  formatHtml
}: Props) => {
  const [isDismissed, setIsDismissed] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const dismiss = () => {
    if (!isHidden) {
      setIsHidden(true);
    }
  };

  useEffect(() => {
    if (isHidden) {
      const timeoutId = window.setTimeout(
        () => setIsDismissed(true),
        alertDismissTransitionDurationInMilliseconds
      );
      return () => window.clearTimeout(timeoutId);
    }
  }, [isHidden, setIsDismissed]);

  if (isDismissed) {
    return null;
  }

  return (
    <AlertCard
      alertType={alertType}
      isHidden={isHidden}
      withMarginTop={withMarginTop}
      withMarginBottom={withMarginBottom}
    >
      {isDismissible && !isHidden && (
        <DismissButton onClick={dismiss}>
          <TimesSolidIcon />
        </DismissButton>
      )}
      {header && (
        <AlertHeader alertType={alertType}>
          <AlertIcon alertType={alertType} />
          {header}
        </AlertHeader>
      )}
          
      <AlertBody>
        {formatHtml && (
          <HTMLWrapper html={children} />
        )}
        {!formatHtml && (
          children
        )}
      </AlertBody>
     
    </AlertCard>
  );
};

const alertDismissTransitionDurationInMilliseconds = 500;

export const getAlertBorderColour = (alertType: AlertType) => {
  switch (alertType) {
    case 'notice':
      return borderColours.notice;
    case 'positive':
      return borderColours.positive;
    case 'negative':
      return borderColours.negative;
    case 'warning':
      return borderColours.warning;
    default:
      return null;
  }
};

const AlertCard = styled.div<{
  alertType: AlertType;
  isHidden: boolean;
  withMarginTop?: boolean;
  withMarginBottom?: boolean;
}>`
  background: ${backgroundColours.default};
  position: relative;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  transform: scale(${(props) => (props.isHidden ? '90%' : '100%')});
  transition: transform ${alertDismissTransitionDurationInMilliseconds}ms ease,
    opacity ${alertDismissTransitionDurationInMilliseconds}ms ease;
  padding: ${spacing.spacing24} ${spacing.spacing32};
  box-shadow: ${shadow1};
  border-radius: ${spacing.spacing8};
  border-left-style: solid;
  border-left-width: ${spacing.spacing8};
  border-left-color: ${(props) => getAlertBorderColour(props.alertType)};
  margin-top: ${(props) => (props.withMarginTop ? spacing16 : 0)};
  margin-bottom: ${(props) => (props.withMarginBottom ? spacing16 : 0)};
`;

export const AlertIcon = (props: { alertType: AlertType }) => (
  <AlertIconContainer>{getIcon(props.alertType)}</AlertIconContainer>
);

const getIcon = (alertType: AlertType) => {
  switch (alertType) {
    case 'notice':
      return <InfoCircleSolidIcon />;
    case 'positive':
      return <CheckCircleIcon />;
    case 'negative':
      return <TimesCircleSolidIcon />;
    case 'warning':
      return <ExclamationCircleIcon />;
    default:
      return null;
  }
};

const AlertIconContainer = styled.div`
  height: 20px;
  width: 20px;
  margin-right: ${spacing.spacing16};
`;

export const getAlertTextColour = (alertType: AlertType) => {
  switch (alertType) {
    case 'notice':
      return textColours.notice;
    case 'positive':
      return textColours.positive;
    case 'negative':
      return textColours.negative;
    case 'warning':
      return textColours.warning;
    default:
      return null;
  }
};

export const AlertHeader = styled.div<{ alertType: AlertType }>`
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing16};
  align-items: center;
  justify-content: flex-start;
  font-weight: ${fontWeightBold};
  color: ${(props) => getAlertTextColour(props.alertType)};
`;

const AlertBody = styled.div`
  margin-bottom: 0;
`;

const DismissButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  outline: none;
  border: none;
  color: ${colourGrey08};
  cursor: pointer;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.75;
  }

  svg {
    height: ${spacing.spacing16};
    width: ${spacing.spacing16};
  }
`;
