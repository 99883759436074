import React from 'react';
import styled from 'styled-components/macro';
import {
  BaseButton,
  BaseButtonProps,
  NativeButtonProps,
  ButtonStyle,
  ButtonSize,
} from './BaseButton';
import { ButtonIcon } from './ButtonIcon';
import { spacing48 } from '../../../styling/design/spacing';

type Props = NativeButtonProps & {
  icon: React.ReactNode;
  buttonStyle?: ButtonStyle;
  size?: 'small' | 'medium' | 'large';
};

export const IconButton = ({ icon, buttonStyle, size, ...rest }: Props) => (
  <StyledButton buttonStyle={buttonStyle || 'primary'} size={size || 'medium'} {...rest}>
    <ButtonIcon icon={icon} position="middle" size={size || 'medium'} />
  </StyledButton>
);

const StyledButton = styled(BaseButton)<BaseButtonProps>`
  border-radius: 100px;
  width: ${(props) => getButtonSize(props.size || 'medium')};
  height: ${(props) => getButtonSize(props.size || 'medium')};

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const getButtonSize = (size: ButtonSize) => {
  switch (size) {
    case 'xsmall':
    case 'small':
      return '26px';
    case 'medium':
      return '36px';
    case 'large':
    case 'xlarge':
      return spacing48;
  }
};
