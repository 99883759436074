import React from 'react';
import { AuthenticationResult } from '../AuthenticationResult';
import { ActivateTwilioAuthy } from './ActivateTwilioAuthy';
import { SetUpGoogleAuthenticator } from './SetUpGoogleAuthenticator';
import { UserDetails } from '../UserDetails';
import { NotificationResponse } from '../../../styling/layout/sidebar/notifications/NotificationResponse';

type Props = {
  authenticationResult: AuthenticationResult;
  onTwilioAuthySetupCompleted: () => void;
  onGoogleAuthenticatorSetupCompleted: (
    userDetails: UserDetails | null,
    notifications: Array<NotificationResponse> | null
  ) => void;
  cancel: () => void;
};

export const SetUpMultiFactorAuthentication = ({
  authenticationResult,
  onTwilioAuthySetupCompleted,
  onGoogleAuthenticatorSetupCompleted,
  cancel,
}: Props) => {
  switch (authenticationResult) {
    case 'TwilioAuthyRegistrationRequired':
      return <ActivateTwilioAuthy onCancel={cancel} onActivated={onTwilioAuthySetupCompleted} />;
    case 'GoogleAuthenticatorSetupRequired':
      return (
        <SetUpGoogleAuthenticator
          onCancel={cancel}
          onSetupComplete={onGoogleAuthenticatorSetupCompleted}
        />
      );
    default:
      return null;
  }
};
