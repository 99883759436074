import React from 'react';
import { FormField } from '../common/FormField';
import {
  RadioButtonGroup,
  RadioButtonGroupOption,
  RadioButtonGroupValue,
} from '../../interface/buttons/RadioButtonGroup';
import { ButtonSize } from '../../interface/buttons/BaseButton';

type Props<TValue extends RadioButtonGroupValue> = {
  fieldName: string;
  options: Array<RadioButtonGroupOption<TValue>>;
  label?: string;
  helpText?: string;
  size?: ButtonSize;
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
};

export const RadioButtonGroupField = <TValue extends RadioButtonGroupValue>(
  props: Props<TValue>
) => {
  return (
    <FormField<TValue> fieldName={props.fieldName} label={props.label} helpText={props.helpText}>
      {({ field, formikContext }) => {
        const onChange = (newValue: TValue) => {
          formikContext.setFieldValue(field.name, newValue);
        };

        return (
          <RadioButtonGroup<TValue>
            data-testid={props['data-testid'] ?? radioButtonGroupFieldTestId(field.name)}
            className={props.className}
            options={props.options}
            value={field.value}
            onChange={onChange}
            size={props.size}
            disabled={props.disabled || formikContext.isSubmitting}
          />
        );
      }}
    </FormField>
  );
};

export const radioButtonGroupFieldTestId = (fieldName: string) =>
  `radio-button-group-field-${fieldName}`;
