import styled, { css } from 'styled-components/macro';
import { spacing16, spacing24, spacing32, spacing8 } from '../../../styling/design/spacing';
import {
  backgroundColours,
  borderColours,
  colourGrey01,
  textColours,
} from '../../../styling/design/colours';
import {
  fontFamily,
  fontSizeCss,
  fontWeightBold,
  fontWeightMedium,
} from '../../../styling/design/fonts';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  insetBottomShadow,
  insetLeftShadow,
  insetRightShadow,
  insetTopShadow,
} from '../../../styling/design/shadows';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { onMobile } from '../../../styling/layout/screenBreakpoints';

export const THead = styled.thead``;

export const Th = styled.th<{ align?: 'left' | 'right' | 'center' }>`
  font-family: ${fontFamily};
  padding: ${spacing16};
  text-align: ${(props) => props.align ?? 'left'};
`;

export const Td = styled.td<{ align?: 'left' | 'right' | 'center' }>`
  font-family: ${fontFamily};
  padding: ${spacing16};
  text-align: ${(props) => props.align ?? 'left'};
`;

export const THeadMobile = styled(THead)`
  ${onMobile(css`
    display: none !important;
  `)}
`;
export const TdMobile = styled(Td)`
  ${onMobile(css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    border-top: 1px solid ${borderColours.default} !important;
    border-left: 1px solid ${borderColours.default} !important;
    border-right: 1px solid ${borderColours.default} !important;
    border-bottom: none !important;

    border-radius: 0 !important;

    &:last-child {
      margin-bottom: ${spacing24};
      border-bottom: 3px solid ${borderColours.default} !important;
      box-shadow: 0px 9px 5px -7px #00000042;
    }

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
  `)}
`;

export const MobileHeaderCell = styled(TdMobile)`
  background-color: ${backgroundColours.disabled} !important;
  &::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const ChildTableContainerTd = styled(Td)`
  padding: 0;
`;

export const Tr = styled.tr`
  ${Th} {
    color: ${textColours.default};
    font-weight: ${fontWeightBold};
    ${fontSizeCss('medium')};
    background-color: ${colourGrey01};
    border-top: solid 1px ${borderColours.default};

    &:first-of-type {
      border-top-left-radius: 5px;
      border-left: solid 1px ${borderColours.default};
    }

    &:last-of-type {
      border-top-right-radius: 5px;
      border-right: solid 1px ${borderColours.default};
    }
  }

  ${Td} {
    color: ${textColours.default};
    font-weight: ${fontWeightMedium};
    ${fontSizeCss('medium')};
    background-color: ${backgroundColours.default};
    border-bottom: solid 1px ${borderColours.default};

    &:first-of-type {
      border-left: solid 1px ${borderColours.default};
    }

    &:last-of-type {
      border-right: solid 1px ${borderColours.default};
    }
  }

  &:last-of-type {
    ${Th}, ${Td} {
      border-bottom: solid 1px ${borderColours.default};
    }

    ${Td} {
      &:first-of-type {
        border-bottom-left-radius: 5px;
      }

      &:last-of-type {
        border-bottom-right-radius: 5px;
      }
    }
  }
`;

export const TBody = styled.tbody`
  &:first-child {
    ${Tr}:first-of-type {
      ${Td} {
        border-top: solid 1px ${borderColours.default};

        &:first-of-type {
          border-top-left-radius: 5px;
          border-left: solid 1px ${borderColours.default};
        }

        &:last-of-type {
          border-top-right-radius: 5px;
          border-right: solid 1px ${borderColours.default};
        }
      }
    }
  }
`;

type NoResultsRowProps = {
  colSpan: number;
  message?: string;
};

export const NoResultsRow = (props: NoResultsRowProps) => {
  const { translate } = useInternationalisation();

  return (
    <Tr>
      <NoResultsRowTd colSpan={props.colSpan}>
        {props.message || translate('tables.noResults')}
      </NoResultsRowTd>
    </Tr>
  );
};

const NoResultsRowTd = styled(Td)`
  text-align: center;
  padding: ${spacing32} ${spacing16};
  background-color: ${colourGrey01} !important;
  opacity: 0.6;
`;

type ThresholdLimitRowProps = {
  colSpan: number;
  message?: string;
};

export const ThresholdLimitRow = (props: ThresholdLimitRowProps) => {
  const { translate } = useInternationalisation();

  return (
    <Tr>
      <ThresholdLimitRowTd colSpan={props.colSpan}>
        {props.message || translate('tables.thresholdLimit')}
      </ThresholdLimitRowTd>
    </Tr>
  );
};

const ThresholdLimitRowTd = styled(Td)`
  text-align: center;
  padding: ${spacing32} ${spacing16};
  background-color: ${colourGrey01} !important;
  opacity: 0.6;
`;

export const Table = styled.table<{ compact?: boolean; headerPadding?: string }>`
  width: 100%;
  border-spacing: 0;
  ${(props) =>
    props.compact &&
    css`
      ${Th} {
        padding: ${props.headerPadding ?? spacing8};
        ${fontSizeCss('small')}
      }

      ${Td} {
        padding: ${spacing8};
        ${fontSizeCss('small')}
      }
    `}

  table {
    ${THead} {
      ${Tr} {
        ${Th} {
          border: none;
          border-bottom: 1px solid ${borderColours.default};
          border-radius: 0;
          box-shadow: ${insetTopShadow};

          &:first-of-type {
            box-shadow: ${insetTopShadow}, ${insetLeftShadow};
          }

          &:last-of-type {
            box-shadow: ${insetTopShadow}, ${insetRightShadow};
          }
        }
      }
    }

    ${TBody} {
      &:first-child {
        ${Tr}:first-of-type {
          ${Td} {
            border: none;
            border-bottom: 1px solid ${borderColours.default};
            border-radius: 0;
            box-shadow: ${insetTopShadow};

            &:first-of-type {
              box-shadow: ${insetTopShadow}, ${insetLeftShadow}, ${insetBottomShadow};
            }

            &:last-of-type {
              box-shadow: ${insetTopShadow}, ${insetRightShadow}, ${insetBottomShadow};
            }
          }
        }
      }

      ${Tr}:not(:last-of-type) {
        ${Td} {
          border: none;
          border-bottom: 1px solid ${borderColours.default};

          &:first-of-type {
            box-shadow: ${insetLeftShadow};
          }

          &:last-of-type {
            box-shadow: ${insetRightShadow};
          }
        }
      }

      ${Tr}:last-of-type {
        ${Td} {
          border: none;
          border-radius: 0;
          box-shadow: ${insetBottomShadow};

          &:first-of-type {
            box-shadow: ${insetBottomShadow}, ${insetLeftShadow};
          }

          &:last-of-type {
            box-shadow: ${insetBottomShadow}, ${insetRightShadow};
          }
        }
      }
    }
  }
`;

// To allow scroll overflow when the table is too wide to fit on the page.
export const TableLoadingOverlay = styled(LoadingOverlay)`
  overflow-x: auto;
`;
