import styled from 'styled-components/macro';
import { colourBlack } from '../../../styling/design/colours';
import { TimesSolidIcon } from '../../../icons/icons';
import React from 'react';
import { spacing16 } from '../../../styling/design/spacing';

type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
};

export const DismissButton = ({ onClick, disabled, className }: Props) => {
  return (
    <StyledButton className={className} onClick={onClick} disabled={disabled}>
      <TimesSolidIcon />
    </StyledButton>
  );
};

const iconHeight = spacing16;

export const StyledButton = styled.button`
  background: none;
  outline: none;
  border: none;
  padding: 0;
  height: ${iconHeight};
  width: ${iconHeight};
  color: ${colourBlack};
  cursor: pointer;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }

  svg {
    height: ${iconHeight};
    width: ${iconHeight};
  }
`;
