import React from 'react';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';
import {
  ClearingCodeTypeSelect,
  ClearingCodeTypeSelectOptionValue,
} from './ClearingCodeTypeSelect';
import { ClearingCodeTypeSelectSettings } from './ClearingCodeTypeSelectSettings';
import { GetClearingCodeTypeDropdownOptionsResponse } from './GetClearingCodeTypeDropdownOptionsResponse';

type Props = MetadataSingleSelectFieldComponentProps<
  ClearingCodeTypeSelectOptionValue,
  ClearingCodeTypeSelectSettings,
  GetClearingCodeTypeDropdownOptionsResponse
>;

export const ClearingCodeTypeSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    ClearingCodeTypeSelectOptionValue,
    ClearingCodeTypeSelectSettings,
    GetClearingCodeTypeDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <ClearingCodeTypeSelect {...metadataSingleSelectProps} />
    )}
  />
);
