import React, { useContext } from 'react';
import { HoldingsContext } from './HoldingsContext';
import { Disclaimer } from '../disclaimers/Disclaimer';
import { isNullUndefinedOrBlank } from '../../helpers/stringHelpers';
import { assertNotNull } from '../../helpers/nullHelpers';

export const HoldingsDisclaimer = () => {
  const { latestResponse, inProgress } = useContext(HoldingsContext);

  if (isNullUndefinedOrBlank(latestResponse?.disclaimer)) {
    return null;
  }

  return (
    <Disclaimer
      disclaimerTextAsHtml={assertNotNull(
        latestResponse?.disclaimer ?? null,
        'latestResponse.disclaimer'
      )}
      inProgress={inProgress}
      withMarginTop={true}
    />
  );
};
