import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../../MetadataSelectProps';
import { IsoDatestamp } from '../../../../helpers/dateTimeHelpers';
import { DealingDateSelectSettings } from './DealingDateSelectSettings';
import {
  GetTransactionDealingDateDropdownOptionsResponse,
  GetTransactionDealingDateDropdownOptionsResult,
  mapGetTransactionDealingDateDropdownOptionsResponseToSelectOptions,
} from './GetTransactionDealingDateDropdownOptionsResponse';
import { DealingTransactionTypeCode } from '../DealingTransactionType';
import { ErrorMessage } from '../../../../infrastructure/interface/components/ErrorMessage';
import { MetadataSingleSelect } from '../../MetadataSingleSelect';

export type DealingDateSelectOptionValue = IsoDatestamp | null;

type Props = MetadataSingleSelectComponentProps<
  DealingDateSelectOptionValue,
  DealingDateSelectSettings,
  GetTransactionDealingDateDropdownOptionsResponse
>;

export const DealingDateSelect = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate, formatDate } = useInternationalisation();

  const [result, setResult] = useState<GetTransactionDealingDateDropdownOptionsResult | null>(null);

  const onLoaded = (response: GetTransactionDealingDateDropdownOptionsResponse) => {
    if (response.result === 'NextAvailable') {
      metadataSingleSelectProps.onChange(response.options[0].dealingDate);
    }

    setResult(response.result);

    if (metadataSingleSelectProps.onLoaded != null) {
      metadataSingleSelectProps.onLoaded(response);
    }
  };

  const onError = (error: string) => {
    setResult(null);

    if (metadataSingleSelectProps.onError != null) {
      metadataSingleSelectProps.onError(error);
    }
  };

  const placeholder = translate('metadata.transactionDealingDateSelect.placeholder');
  const loadingPlaceholder = translate('metadata.transactionDealingDateSelect.loadingPlaceholder');

  if (result === 'NoDealingDates') {
    return (
      <ErrorMessage data-testid={dealingDateSelectNoDealingDatesErrorTestId}>
        {translate('metadata.transactionDealingDateSelect.noDealingDatesError')}
      </ErrorMessage>
    );
  }

  if (result === 'NextAvailable') {
    return (
      <NextAvailableMessage data-testid={dealingDateSelectNextAvailableMessageTestId}>
        {translate('metadata.transactionDealingDateSelect.nextAvailableMessage')}
      </NextAvailableMessage>
    );
  }

  return (
    <MetadataSingleSelect<
      DealingDateSelectOptionValue,
      DealingDateSelectSettings,
      GetTransactionDealingDateDropdownOptionsQuery,
      GetTransactionDealingDateDropdownOptionsResponse
    >
      {...metadataSingleSelectProps}
      endpointUrl="/api/transactions/GetTransactionDealingDateDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={(response) =>
        mapGetTransactionDealingDateDropdownOptionsResponseToSelectOptions(response, formatDate)
      }
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={200}
      onLoaded={onLoaded}
      onError={onError}
    />
  );
};

const NextAvailableMessage = styled.div``;

export type GetTransactionDealingDateDropdownOptionsQuery = {
  companyId: number;
  transactionType: DealingTransactionTypeCode;
};

export const dealingDateSelectNoDealingDatesErrorTestId =
  'dealing-date-select-no-dealing-dates-error';

export const dealingDateSelectNextAvailableMessageTestId =
  'dealing-date-select-next-available-message';

export const dealingDateSelectTestId = 'dealing-date-select';
