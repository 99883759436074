import React, { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { ReportParameterResponse } from '../Report';
import { ReportsCompanyValueDateSelectField } from './companyValueDate/ReportsCompanyValueDateSelectField';
import {
  ReportParameterType,
  reportParameterTypeCodesByType,
  reportParameterTypesByCode,
} from './ReportParameterType';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { ReportsCompanySelectField } from './company/ReportsCompanySelectField';
import { ReportsCapitalCallSelectField } from './capitalCall/ReportsCapitalCallSelectField';
import { DatePickerField } from '../../../infrastructure/forms/fields/DatePickerField';
import { ReportResponse } from '../Reports';
import { useReportParameterInteractions } from './useReportParameterInteractions';
import { ReportsSingleSelectField } from './singleSelect/ReportsSingleSelectField';
import { ReportsShareholderSelectField } from './shareholder/ReportsShareholderSelectField';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { ReportsEquityAttributionSelectField } from './equityAttribution/ReportsEquityAttributionSelectField';
import { SecurityTypeSelectField } from './securityType/SecurityTypeSelectField';
import { CheckboxField } from '../../../infrastructure/forms/fields/CheckboxField';
import { ReportsCompanyReportGroupSelectField } from './companyReportGroup/ReportsCompanyReportGroupSelectField';
import {
  ReportParameterFieldsContextProvider,
  useReportParameterFieldsContext,
} from './ReportParameterFieldsContext';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { ReportsGeneralLedgerBankSelectField } from './generalLedger/bank/ReportsGeneralLedgerBankSelectField';
import { ReportsGeneralLedgerCounterpartSelectField } from './generalLedger/counterpart/ReportsGeneralLedgerCounterpartSelectField';
import { ReportsGeneralLedgerClassSelectField } from './generalLedger/class/ReportsGeneralLedgerClassSelectField';
import { ReportsGeneralLedgerSubclassSelectField } from './generalLedger/subclass/ReportsGeneralLedgerSubclassSelectField';
import { ReportsCompanyFreeValueDateSelectField } from './companyFreeValueDate/ReportsCompanyFreeValueDateSelectField';
import { ReportParameterName } from './ReportParameterName';

type ReportParameterFieldsProps = {
  report: ReportResponse;
  parameters: Array<ReportParameterResponse>;
};

export const ReportParameterFields = ({ report, parameters }: ReportParameterFieldsProps) => {
  const { translate } = useInternationalisation();
  useReportParameterInteractions(parameters);
  const [error, setError] = useState<string | null>(null);

  const sortedParameters = useMemo(
    () => sortBy(parameters, (p) => p.displaySequence),
    [parameters]
  );

  if (error) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')} withMarginTop={true}>
        {error}
      </Alert>
    );
  }

  return (
    <ReportParameterFieldsContextProvider>
      {sortedParameters.map((parameter) => (
        <ReportParameterField
          key={parameter.name}
          report={report}
          parameter={parameter}
          onError={(error: string) => setError(error)}
        />
      ))}
    </ReportParameterFieldsContextProvider>
  );
};

type ReportParameterFieldProps = {
  report: ReportResponse;
  parameter: ReportParameterResponse;
  onError: (error: string) => void;
};

const ReportParameterField = ({ report, parameter, onError }: ReportParameterFieldProps) => {
  const { translate } = useInternationalisation();
  const { setFieldLoaded } = useReportParameterFieldsContext();

  useOnMount(() => {
    // Must be called async as updating another component's state while rendering is forbidden
    if (fieldShouldLoadImmediatelyByType[reportParameterTypesByCode[parameter.parameterTypeCode]]) {
      setFieldLoaded(parameter.name);
    }
  });

  switch (parameter.parameterTypeCode) {
    case reportParameterTypeCodesByType.generalLedgerBank:
      return (
        <ReportsGeneralLedgerBankSelectField
          generalLedgerBankReportParameter={parameter}
          onError={onError}
        />
      );
    case reportParameterTypeCodesByType.capitalCall:
      return <ReportsCapitalCallSelectField capitalCallParameter={parameter} onError={onError} />;
    case reportParameterTypeCodesByType.companyReportGroup:
      return (
        <ReportsCompanyReportGroupSelectField
          companyReportGroupParameter={parameter}
          onError={onError}
        />
      );
    case reportParameterTypeCodesByType.company:
      return <ReportsCompanySelectField companyParameter={parameter} onError={onError} />;
    case reportParameterTypeCodesByType.generalLedgerCounterpart:
      return (
        <ReportsGeneralLedgerCounterpartSelectField
          generalLedgerCounterpartReportParameter={parameter}
          onError={onError}
        />
      );
    case reportParameterTypeCodesByType.genericDropdown:
      return <ReportsSingleSelectField report={report} parameter={parameter} onError={onError} />;
    case reportParameterTypeCodesByType.equityAttribution:
      return (
        <ReportsEquityAttributionSelectField
          equityAttributionParameter={parameter}
          onError={onError}
        />
      );
    case reportParameterTypeCodesByType.freeDate:
      return parameter.name === ReportParameterName.RunDate ||
        parameter.name === ReportParameterName.PriorDate ? (
        <ReportsCompanyFreeValueDateSelectField
          fieldName={parameter.name}
          label={parameter.displayText}
          report={report}
          onError={onError}
        />
      ) : (
        <DatePickerField fieldName={parameter.name} label={parameter.displayText} />
      );
    case reportParameterTypeCodesByType.freeText:
      return <InputField fieldName={parameter.name} label={parameter.displayText} />;
    case reportParameterTypeCodesByType.generalLedgerClass:
      return (
        <ReportsGeneralLedgerClassSelectField
          generalLedgerClassReportParameter={parameter}
          onError={onError}
        />
      );
    case reportParameterTypeCodesByType.generalLedgerSubclass:
      return (
        <ReportsGeneralLedgerSubclassSelectField
          generalLedgerSubclassReportParameter={parameter}
          onError={onError}
        />
      );
    case reportParameterTypeCodesByType.shareholder:
      return <ReportsShareholderSelectField shareholderParameter={parameter} onError={onError} />;
    case reportParameterTypeCodesByType.securityType:
      return <SecurityTypeSelectField securityTypeParameter={parameter} onError={onError} />;
    case reportParameterTypeCodesByType.valueDatePlusOne:
      return (
        <ReportsCompanyValueDateSelectField
          report={report}
          plusOne={true}
          valueDateParameter={parameter}
          onError={onError}
        />
      );
    case reportParameterTypeCodesByType.valueDate:
      return (
        <ReportsCompanyValueDateSelectField
          report={report}
          plusOne={false}
          valueDateParameter={parameter}
          onError={onError}
        />
      );
    case reportParameterTypeCodesByType.boolean:
      return (
        <CheckboxField
          fieldName={parameter.name}
          label={parameter.displayText}
          fieldAlignment="row"
        />
      );
    case reportParameterTypeCodesByType.subLedger:
    case reportParameterTypeCodesByType.currency:
      return <></>;
    default:
      onError(
        translate('pages.reports.unrecognisedParameterError', {
          parameterType: parameter.parameterTypeCode,
        })
      );
      return null;
  }
};

const fieldShouldLoadImmediatelyByType: Record<ReportParameterType, boolean> = {
  generalLedgerBank: false,
  capitalCall: false,
  companyReportGroup: false,
  company: false,
  generalLedgerCounterpart: false,
  genericDropdown: false,
  equityAttribution: false,
  freeDate: true,
  freeText: true,
  generalLedgerClass: false,
  generalLedgerSubclass: false,
  shareholder: false,
  securityType: false,
  valueDatePlusOne: false,
  valueDate: false,
  boolean: true,
  subLedger: false,
  currency: false,
};
