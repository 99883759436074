import React from 'react';
import styled from 'styled-components/macro';
import { PlusIcon } from '../../../icons/icons';
import { RequestFailedPage } from '../../../infrastructure/api/RequestFailedPage';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { AppLink } from '../../../infrastructure/interface/components/AppLink';
import { Header1 } from '../../../infrastructure/interface/components/Headers';
import { CentredSpinner } from '../../../infrastructure/interface/components/Spinner';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { PaddedPage } from '../../../styling/layout/PaddedPage';
import { RequiresUserRole } from '../../authentication/UserRoles';
import { DashboardsStateContextProvider, useDashboardsState } from './DashboardsStateContext';
import { DesktopDashboardsList } from './DesktopDashboardsList';
import { MobileDashboardsList } from './MobileDashboardsList';

export const Dashboards = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.dashboards.title'));

  return (
    <RequiresUserRole userRole="Administrator">
      <DashboardsStateContextProvider>
        <DashboardsComponent />
      </DashboardsStateContextProvider>
    </RequiresUserRole>
  );
};

const DashboardsComponent = () => {
  const { translate } = useInternationalisation();

  const { error, loadData, latestResponse, requestInProgress } = useDashboardsState();

  const isPerformingIntialRequest = latestResponse === null && requestInProgress;

  if (error != null) {
    return <RequestFailedPage retry={loadData} error={error} />;
  }

  if (isPerformingIntialRequest) {
    return <CentredSpinner size="xlarge" />;
  }

  if (latestResponse === null) {
    // Request is not initialised
    return null;
  } else {
    return (
      <PaddedPage>
        <HeaderContainer>
          <Header1>{translate('pages.dashboards.header')}</Header1>
          <DesktopOnly>
            <AppLink to="create">
              <PlusIcon />
              {translate('pages.dashboards.addButtonText')}
            </AppLink>
          </DesktopOnly>
        </HeaderContainer>
        <DesktopOnly>
          <DesktopDashboardsList />
        </DesktopOnly>
        <MobileOnly>
          <MobileDashboardsList />
        </MobileOnly>
      </PaddedPage>
    );
  }
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
