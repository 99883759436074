import React from 'react';
import { InternationalisationContext } from './InternationalisationContext';
import { useInternationalisationContextValue } from './hooks/useInternationalisationContextValue';
import { ErrorSplashScreen, LoadingSplashScreen } from '../features/SplashScreen';

type Props = {
  children?: React.ReactNode;
};

export const InternationalisationContextProvider = (props: Props) => {
  const internationalisationContext = useInternationalisationContextValue();

  if (internationalisationContext.failedToLoad) {
    // NOTE: We obviously can't use the translations here, so we just have to hard-code an error message.
    return (
      <ErrorSplashScreen
        error="An unexpected error has occurred. Please refresh the page to try again."
        translate={null}
      />
    );
  }

  if (!internationalisationContext.hasLoaded) {
    return <LoadingSplashScreen translate={null} />;
  }

  return (
    <InternationalisationContext.Provider value={internationalisationContext}>
      <div dir={internationalisationContext.getTextDirection()}>{props.children}</div>
    </InternationalisationContext.Provider>
  );
};
