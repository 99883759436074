import React from 'react';
import {
  GetEquityAttributionDropdownOptionsResponse,
  mapEquityAttributionDropdownOptionsResponseToSelectOptions,
} from './GetEquityAttributionDropdownOptionsResponse';
import { EquityAttributionSelectSettings } from './EquityAttributionSelectSettings';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  cannotMapSettingsToValidQueryParameters,
  MetadataSingleSelect,
} from '../MetadataSingleSelect';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';

export type EquityAttributionSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  EquityAttributionSelectOptionValue,
  EquityAttributionSelectSettings,
  GetEquityAttributionDropdownOptionsResponse
>;

export const EquityAttributionSelect = ({ settings, ...metadataSingleSelectProps }: Props) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.equityAttributionSelect.placeholder');
  const loadingPlaceholder = translate('metadata.equityAttributionSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      EquityAttributionSelectOptionValue,
      EquityAttributionSelectSettings,
      GetEquityAttributionDropdownOptionsQuery,
      GetEquityAttributionDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetEquityAttributionDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) =>
        settings.companyId == null ? cannotMapSettingsToValidQueryParameters : settings
      }
      mapResponseToOptions={mapEquityAttributionDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      showPlaceholderIfCannotLoad={true}
      minWidthInPixels={300}
      {...metadataSingleSelectProps}
    />
  );
};

export type GetEquityAttributionDropdownOptionsQuery = {
  companyId: number | null;
  includeBlank: boolean;
  employeeCounterpartId: number | null;
};
