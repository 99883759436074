import React, { useMemo } from 'react';
import { ReportParameterResponse } from '../../Report';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { ReportResponse } from '../../Reports';
import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import styled from 'styled-components/macro';
import { SingleSelectField } from '../../../../infrastructure/forms/fields/SingleSelectField';
import { useReportParameterFieldsContext } from '../ReportParameterFieldsContext';

type Props = {
  report: ReportResponse;
  parameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsSingleSelectField = ({ report, parameter, onError }: Props) => {
  const { translate } = useInternationalisation();
  const { setFieldLoaded } = useReportParameterFieldsContext();

  const getReportParameterValuesRequest = usePostJson<
    GetReportParameterValuesRequest,
    GetReportParameterValuesResponse
  >('/api/reports/GetReportParameterValues');

  const { response, inProgress } = getReportParameterValuesRequest.state;
  const responseNotLoadedOrInProgress = response == null || inProgress;

  useOnMount(() =>
    getReportParameterValuesRequest.makeRequest({
      requestBody: {
        reportId: report.id,
        parameterName: parameter.name,
        employeeCounterpartId: null,
      },
      onFailure: onError,
      onSuccess: () => setFieldLoaded(parameter.name),
    })
  );

  const placeholder = useMemo(
    () =>
      responseNotLoadedOrInProgress
        ? translate('form.defaultLoadingPlaceholder')
        : translate('form.select.defaultPlaceholder'),
    [responseNotLoadedOrInProgress, translate]
  );

  const options: SelectOptions<string> = useMemo(
    () => responseToSelectOptions(response),
    [response]
  );

  return (
    <StyledSelectField<string>
      fieldName={parameter.name}
      label={parameter.displayText}
      placeholder={placeholder}
      options={options}
      disabled={responseNotLoadedOrInProgress}
    />
  );
};

const StyledSelectField = styled(SingleSelectField)`
  min-width: 250px;
` as typeof SingleSelectField;

export type GetReportParameterValuesRequest = {
  reportId: number;
  parameterName: string;
  employeeCounterpartId: number | null;
};

export type GetReportParameterValuesResponse = {
  parameterValues: Array<ReportParameterValueResponse>;
};

export type ReportParameterValueResponse = {
  displayText: string;
  value: string;
};

const responseToSelectOptions = (
  response: GetReportParameterValuesResponse | null
): SelectOptions<string> =>
  !response
    ? []
    : response.parameterValues.map((parameterValue) => ({
        value: parameterValue.value,
        label: parameterValue.displayText,
      }));
