import { Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import styled, { css } from 'styled-components/macro';
import { ApiRequestStateWrapper } from '../../../infrastructure/api/ApiRequestStateWrapper';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { FormActionButtons } from '../../../infrastructure/forms/common/FormActionButtons';
import { FilePickerField } from '../../../infrastructure/forms/fields/FilePickerField';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { Header1 } from '../../../infrastructure/interface/components/Headers';
import { PageHeaderActions } from '../../../infrastructure/interface/components/PageHeaderActions';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { CentredPaddedPage } from '../../../styling/layout/PaddedPage';
import { onMobile } from '../../../styling/layout/screenBreakpoints';
import { RequiresUserRole } from '../../authentication/UserRoles';
import { placeTransactionsUserRoles } from '../placeTransaction/placeTransactionUserRoles';
import { DownloadTransactionsUploadTemplateButton } from './DownloadTransactionsUploadTemplateButton';
import { RequiresMenuOption } from '../../authentication/UserMenuOptions';
import { assertNotNull } from '../../../helpers/nullHelpers';
import { useMultipartApiRequest } from '../../../infrastructure/api/useMultipartApiRequest';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { spacing32 } from '../../../styling/design/spacing';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { Validator } from 'fluentvalidation-ts';
import { haveAllowedFileExtension, notExceedSizeInBytes } from '../../validation/fileValidation';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import { AuthenticationContext } from '../../authentication/AuthenticationContext';
import { DefaultTransactionsPageLink } from '../defaultTransactionsPageLink.function';

export const UploadTransactions = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.uploadTransactions.title'));
  const { getUser } = useContext(AuthenticationContext);
  const { permissions } = getUser();

  return permissions.canCreateTransactions ? (
    <RequiresUserRole userRole={placeTransactionsUserRoles}>
      <RequiresMenuOption menuOption={'transactions'}>
        <UploadTransactionsComponentApiRequestStateWrapper />
      </RequiresMenuOption>
    </RequiresUserRole>
  ) : (
    <Alert alertType="negative" header={translate('errors.generic')}>
      {translate('pages.uploadTransactions.accessError')}
    </Alert>
  );
};

const UploadTransactionsComponentApiRequestStateWrapper = () => {
  const getTemplateOptionsRequest = useGetJson<
    undefined,
    GetTransactionsUploadTemplateOptionsResponse
  >('/api/transactions/GetTransactionsUploadTemplateOptions');

  useOnMount(() => {
    getTemplateOptionsRequest.makeRequest();
  });

  return (
    <ApiRequestStateWrapper
      apiRequestState={getTemplateOptionsRequest.state}
      retry={getTemplateOptionsRequest.makeRequest}
    >
      {(response) => <UploadTransactionsComponent templates={response.templates} />}
    </ApiRequestStateWrapper>
  );
};

type UploadTransactionsComponentProps = {
  templates: Array<TransactionsUploadTemplateInfo>;
};

type ErrorWarningProps = {
  errorMessages: ErrorRow[];
};

const UploadTransactionsComponent = ({ templates }: UploadTransactionsComponentProps) => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();

  const formValidator = useMemo(() => new UploadTransactionsFormValidator(translate), [translate]);

  const initialFormValues: UploadTransactionsFormModel = {
    file: [],
  };

  const uploadRequest = useMultipartApiRequest<
    UploadTransactionCsvCommand,
    UploadTransactionCsvResponse
  >('/api/transactions/CreateBulkTransactions');

  const [successfulResponse, setSuccessfulResponse] = useState<UploadTransactionCsvResponse>();
  const { error } = uploadRequest.state;

  const onSubmit = (
    formModel: UploadTransactionsFormModel,
    formikHelpers: FormikHelpers<UploadTransactionsFormModel>
  ) => {
    const command: UploadTransactionCsvCommand = {
      documentFile: assertNotNull(formModel.file[0], 'file'),
    };

    uploadRequest.makeRequest({
      request: command,
      onSuccess: (response) => {
        formikHelpers.setSubmitting(false);
        setSuccessfulResponse(response);
        if (isEmpty(response.errors)) {
          const dealNumbersQueryString = queryString.stringify({
            dealNumber: response.dealNumbers,
          });
          navigate(`/transactions/${response.uploadId}/confirm?${dealNumbersQueryString}`);
        }
      },
      onFailure: (c) => formikHelpers.setSubmitting(false),
    });
  };

  const errorMessages =
    successfulResponse?.errors != null
      ? successfulResponse?.errors.filter((errorRow) => errorRow.errorMessage !== '')
      : undefined;

  const ErrorWarning = ({ errorMessages }: ErrorWarningProps) => {
    if (errorMessages.length > 0)
      return (
        <AlertContainer>
          <Alert
            alertType="negative"
            header={translate('pages.uploadTransactions.errors.correctErrors')}
          >
            {errorMessages?.map((errorRow) => (
              <div>
                {translate('pages.uploadTransactions.errors.lineNumber')} {errorRow.index + 1}:{' '}
                {errorRow.errorMessage}.
              </div>
            ))}
          </Alert>
        </AlertContainer>
      );

    return null;
  };

  return (
    <CentredPaddedPage>
      <Header1>{translate('pages.uploadTransactions.header')}</Header1>
      <PageHeaderActions withBackButton={true}>
        {templates.map((templateInfo) => (
          <DownloadTransactionsUploadTemplateButton
            key={templateInfo.templateId}
            templateInfo={templateInfo}
          />
        ))}
      </PageHeaderActions>
      <Formik<UploadTransactionsFormModel>
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidator.validate}
      >
        <Form>
          <FormFieldsContainer>
            <FilePickerField
              fieldName="file"
              allowMultiple={false}
              allowedFileTypes={UploadTransactionsFormValidator.allowedFileExtensions}
              label={translate('pages.uploadTransactions.filePickerLabel')}
            />
          </FormFieldsContainer>
          <FormActionButtons
            onCancel={() => navigate(DefaultTransactionsPageLink())}
            cancelButtonText={translate('actionButtons.cancel')}
            submitButtonText={translate('pages.uploadTransactions.submitButtonText')}
            submitButtonSubmittingText={translate(
              'pages.uploadTransactions.submitButtonSubmittingText'
            )}
          />
        </Form>
      </Formik>
      {error != null ? (
        <AlertContainer>
          <Alert alertType="negative">{error}</Alert>
        </AlertContainer>
      ) : null}
      {errorMessages && error == null ? <ErrorWarning errorMessages={errorMessages} /> : null}
    </CentredPaddedPage>
  );
};

class UploadTransactionsFormValidator extends Validator<UploadTransactionsFormModel> {
  static allowedFileExtensions = ['.xlsx', '.csv', '.xls'];
  static maximumFileSizeInBytes = 4 * 1024 * 1024; //4 MB

  constructor(translate: TranslateFunction) {
    super();

    this.ruleFor('file')
      .must((files) => files.length === 1)
      .withMessage(translate('validation.requiredField'))
      .must(
        haveAllowedFileExtension(translate, UploadTransactionsFormValidator.allowedFileExtensions)
      )
      .must(
        notExceedSizeInBytes(translate, UploadTransactionsFormValidator.maximumFileSizeInBytes)
      );
  }
}

const FormFieldsContainer = styled(Panel)`
  ${onMobile(css`
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  `)}
`;

const AlertContainer = styled.div`
  margin-top: ${spacing32};
`;

type UploadTransactionsFormModel = {
  file: Array<File>; // Needs to be an array as that's what the file picker exposes
};

export type GetTransactionsUploadTemplateOptionsResponse = {
  templates: Array<TransactionsUploadTemplateInfo>;
};

export type TransactionsUploadTemplateInfo = {
  templateId: number;
  templateName: string;
};

type UploadTransactionCsvCommand = {
  documentFile: File;
};

type UploadTransactionCsvResponse = {
  uploadId: number;
  dealNumbers: number[];
  errors: ErrorRow[];
};

export type ErrorRow = {
  index: number;
  errorMessage: string;
};
