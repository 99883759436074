import React from 'react';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';
import { CurrencySelect, CurrencySelectOptionValue } from './CurrencySelect';
import { CurrencySelectSettings } from './CurrencySelectSettings';
import { GetCurrencyDropdownOptionsResponse } from './GetCurrencyDropdownOptionsResponse';

export type InvestorSelectFieldProps = MetadataSingleSelectFieldComponentProps<
  CurrencySelectOptionValue,
  CurrencySelectSettings,
  GetCurrencyDropdownOptionsResponse
>;

export const CurrencySelectField = (props: InvestorSelectFieldProps) => (
  <MetadataSingleSelectField<
    CurrencySelectOptionValue,
    CurrencySelectSettings,
    GetCurrencyDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => <CurrencySelect {...metadataSingleSelectProps} />}
  />
);
