import { css, FlattenSimpleInterpolation } from 'styled-components/macro';

export const narrowDesktopMaxScreenWidth = 1800;

export const wideDesktopMinScreenWidth = narrowDesktopMaxScreenWidth + 1;

export const desktopMinScreenWidth = 1024;

export const mobileMaxScreenWidth = desktopMinScreenWidth - 1;

export const mobileNavbarHeight = '100px';

export const allDevices = ['desktop', 'mobile'] as const;
export type Device = typeof allDevices[number];

export const onWideDesktop = (styles: FlattenSimpleInterpolation) => css`
  @media (min-width: ${wideDesktopMinScreenWidth}px)  {
    ${styles};
  }
`;

export const onNarrowDesktop = (styles: FlattenSimpleInterpolation) => css`
  @media (min-width: ${desktopMinScreenWidth}px) and (max-width: ${narrowDesktopMaxScreenWidth}px)  {
    ${styles};
  }
`;

export const onDesktop = (styles: FlattenSimpleInterpolation) => css`
  @media (min-width: ${desktopMinScreenWidth}px) {
    ${styles};
  }
`;

export const onMobile = (styles: FlattenSimpleInterpolation) => css`
  @media (max-width: ${mobileMaxScreenWidth}px) {
    ${styles};
  }
`;
