import { SelectOptions } from '../../../../infrastructure/interface/forms/BaseSelect';
import { GeneralLedgerSubclassSelectOptionValue } from './GeneralLedgerSubclassSelect';

export type GetGeneralLedgerSubclassDropdownOptionsResponse = {
  options: Array<GeneralLedgerClassDropdownOption>;
};

type GeneralLedgerClassDropdownOption = {
  generalLedgerSubclassId: number;
  label: string;
};

export const mapGeneralLedgerSubclassDropdownOptionsResponseToSelectOptions = (
  response: GetGeneralLedgerSubclassDropdownOptionsResponse | null
): SelectOptions<GeneralLedgerSubclassSelectOptionValue> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.generalLedgerSubclassId,
        label: option.label,
      }));
