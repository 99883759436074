import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetTransactionTypeDropdownOptionsResponse = {
  options: Array<TransactionTypeDropdownOptionResponse>;
};

export type TransactionTypeDropdownOptionResponse = {
  value: string;
  text: string;
};

export const mapTransactionTypeDropdownOptionsResponseToSelectOptions = (
  response: GetTransactionTypeDropdownOptionsResponse | null
): SelectOptions<string> =>
  !response
    ? []
    : response.options.map((option) => ({
        value: option.value,
        label: option.text,
      }));
