import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { CompanyReportGroupSelectOptionValue } from './CompanyReportGroupSelect';

export type GetCompanyReportGroupDropdownOptionsResponse = {
  options: Array<CompanyReportGroupDropdownOptionResponse>;
};

export type CompanyReportGroupDropdownOptionResponse = {
  companyReportGroupId: number;
  companyReportGroupName: string;
};

export const mapCompanyReportGroupDropdownOptionsResponseToSelectOptions = (
  response: GetCompanyReportGroupDropdownOptionsResponse | null
): SelectOptions<CompanyReportGroupSelectOptionValue> =>
  !response
    ? []
    : response.options.map((option) => ({
        value: option.companyReportGroupId,
        label: option.companyReportGroupName || '—',
      }));
