import { useContext } from 'react';
import { PiggyBankIcon, UploadIcon } from '../../icons/icons';
import { AppLinkWithIcon } from '../../infrastructure/interface/components/AppLink';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { IfUserHasRole } from '../authentication/UserRoles';
import { ExportTransactionsToExcelButton } from './ExportTransactionsToExcelButton';
import { placeTransactionsUserRoles } from './placeTransaction/placeTransactionUserRoles';
import { ActionsContainer } from '../../infrastructure/interface/components/PageHeaderActions';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';

export const TransactionsActionButtons = () => {
  const { translate } = useInternationalisation();
  const { getUser } = useContext(AuthenticationContext);
  const { permissions } = getUser();

  return (
    <ActionsContainer>
      <IfUserHasRole userRole={placeTransactionsUserRoles}>
        {permissions.canCreateTransactions ? (
          <>
            <AppLinkWithIcon
              icon={<UploadIcon />}
              to="/transactions/upload"
              data-testid={uploadTransactionsLinkTestId}
            >
              {translate('pages.transactions.uploadTransactionsButton')}
            </AppLinkWithIcon>
            <AppLinkWithIcon
              icon={<PiggyBankIcon />}
              to="/transactions/place-transaction"
              data-testid={placeTransactionLinkTestId}
            >
              {translate('pages.transactions.placeTransactionButton')}
            </AppLinkWithIcon>
          </>
        ) : null}
      </IfUserHasRole>
      <DesktopOnly>
        <ExportTransactionsToExcelButton />
      </DesktopOnly>
    </ActionsContainer>
  );
};

export const placeTransactionLinkTestId = 'place-transaction-link';
export const uploadTransactionsLinkTestId = 'upload-transactions-link';
