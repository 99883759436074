import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { FieldValidity, getFieldValidity } from '../common/FieldValidity';
import { borderColours } from '../../../styling/design/colours';
import { FilePicker } from '../../interface/forms/FilePicker';
import { useField, useFormikContext } from 'formik';
import { FieldLabel } from '../common/FieldLabel';
import { FieldError } from '../common/FieldError';

export const filePickerFieldInputTestId = (fieldName: string) =>
  `file-picker-field-input-${fieldName}`;

type Props = {
  fieldName: string;
  label?: string;
  disabled?: boolean;
  allowMultiple?: boolean;
  allowedFileTypes?: Array<string>;
};

export const FilePickerField = ({
  fieldName,
  label,
  disabled,
  allowMultiple,
  allowedFileTypes,
}: Props) => {
  const [field, meta] = useField(fieldName);
  const fieldValue = field.value;

  const { validateField, setFieldTouched, setFieldValue, isSubmitting } = useFormikContext();

  const onChange = (newValue: Array<File>) => {
    setFieldValue(fieldName, newValue);
  };

  useEffect(() => {
    validateField(fieldName);

    if (fieldValue.length > 0) {
      setFieldTouched(fieldName, true);
    }
  }, [fieldValue, fieldName, validateField, setFieldTouched]);

  const fieldValidity = getFieldValidity(meta);
  const showError = fieldValidity === 'Invalid';

  return (
    <>
      {label != null && <FieldLabel>{label}</FieldLabel>}
      <FieldValidityFilePicker
        onChange={onChange}
        data-testid={filePickerFieldTestId(fieldName)}
        fieldValidity={fieldValidity}
        disabled={disabled || isSubmitting}
        allowMultiple={allowMultiple}
        allowedFileTypes={allowedFileTypes}
        hiddenInputTestId={filePickerFieldInputTestId(fieldName)}
      />
      {showError && <FieldError fieldName={fieldName}>{meta.error}</FieldError>}
    </>
  );
};

export const filePickerFieldTestId = (fieldName: string) => `file-picker-field-${fieldName}`;

export const FieldValidityFilePicker = styled(FilePicker)<{
  fieldValidity: FieldValidity;
  disabled: boolean;
}>`
  border-color: ${(props) =>
    props.disabled
      ? borderColours.disabled
      : props.fieldValidity === 'Valid'
      ? borderColours.positive
      : props.fieldValidity === 'Invalid'
      ? borderColours.negative
      : borderColours.default};
`;
