import styled, { css } from 'styled-components/macro';
import { fontWeightBold } from '../../../styling/design/fonts';
import { colourPrimary05 } from '../../../styling/design/colours';
import React from 'react';

export const linkStyles = css`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${fontWeightBold};
  text-decoration: none;
  color: ${colourPrimary05};

  &:hover {
    opacity: 0.75;
  }

  transition: opacity 0.25s ease;
`;

export const ExternalLink = styled.a`
  ${linkStyles};
`;

type MailToLinkProps = {
  emailAddress: string;
};

export const MailToLink = ({ emailAddress }: MailToLinkProps) => (
  <ExternalLink href={`mailto:${emailAddress}`}>{emailAddress}</ExternalLink>
);
