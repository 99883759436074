import React from 'react';
import { MetadataSingleSelectComponentProps } from '../../MetadataSelectProps';
import { GeneralLedgerClassSelectSettings } from './GeneralLedgerClassSelectSettings';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelect } from '../../MetadataSingleSelect';
import {
  GetGeneralLedgerClassDropdownOptionsResponse,
  mapGeneralLedgerClassDropdownOptionsResponseToSelectOptions,
} from './GetGeneralLedgerClassDropdownOptionsResponse';

export type GeneralLedgerClassSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  GeneralLedgerClassSelectOptionValue,
  GeneralLedgerClassSelectSettings,
  GetGeneralLedgerClassDropdownOptionsResponse
>;

export const GeneralLedgerClassSelect = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('form.select.defaultPlaceholder');
  const loadingPlaceholder = translate('form.defaultLoadingPlaceholder');

  return (
    <MetadataSingleSelect<
      GeneralLedgerClassSelectOptionValue,
      GeneralLedgerClassSelectSettings,
      GetGeneralLedgerClassDropdownOptionsQuery,
      GetGeneralLedgerClassDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetGeneralLedgerClassDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapGeneralLedgerClassDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={250}
      {...metadataSingleSelectProps}
    />
  );
};

type GetGeneralLedgerClassDropdownOptionsQuery = {
  includeAll: boolean;
  includeBlank: boolean;
};
