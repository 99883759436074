import { useState } from 'react';
import { ShieldIcon } from '../../../icons/icons';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { MinimalButton } from '../../../infrastructure/interface/buttons/MinimalButton';
import { ActionAlert } from '../../../infrastructure/interface/components/ActionAlert';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';

type Props = {
  userId: string;
};

export const ResetMfaKeyButton = ({ userId }: Props) => {
  const { translate } = useInternationalisation();
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const resetMfaKeyRequest = usePostJson<ResetMfaKeyCommand, {}>('/api/authentication/ResetMfaKey');

  const onInitiateAction = () => {
    resetMfaKeyRequest.makeRequest({
      requestBody: { userId },
      onSuccess: () => setAlertIsOpen(false),
    });
  };

  return (
    <>
      <MinimalButton icon={<ShieldIcon />} onClick={() => setAlertIsOpen(true)}>
        {translate('pages.editUser.resetMfaKey')}
      </MinimalButton>
      <ActionAlert
        alertType="warning"
        isOpen={alertIsOpen}
        title={translate('pages.editUser.resetMfaKeyAlert.title')}
        message={translate('pages.editUser.resetMfaKeyAlert.message')}
        cancelButtonText={translate('actionButtons.cancel')}
        onRequestClose={() => setAlertIsOpen(false)}
        confirmButtonText={translate('pages.editUser.resetMfaKeyAlert.confirmButtonText')}
        onInitiateAction={onInitiateAction}
        actionInProgress={resetMfaKeyRequest.state.inProgress}
        actionError={resetMfaKeyRequest.state.error}
      />
    </>
  );
};

type ResetMfaKeyCommand = {
  userId: string;
};
