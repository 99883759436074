import React, { useEffect, useState } from 'react';
import { Panel } from '../../../../infrastructure/interface/components/Panel';
import styled from 'styled-components/macro';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { RequestFailedAlert } from '../../../../infrastructure/api/RequestFailedAlert';
import { BatchDeleteUserFormModel, BatchDeleteUsersRequest } from './BatchDeleteUsersRequest';
import { Header2 } from '../../../../infrastructure/interface/components/Headers';
import { defaultInitialResultsPerPageOption } from '../../../../infrastructure/interface/tables/PaginatedTable';
import { NoResultsRow, Td, Th, Tr } from '../../../../infrastructure/interface/tables/Table';
import { spacing16, spacing32 } from '../../../../styling/design/spacing';
import {
  GetUsersForBatchDeleteRequest,
  GetUsersForBatchDeleteResponse,
} from './GetUsersForBatchDeleteRequest';
import { BatchTableComponent } from '../BatchTableComponent';
import {
  AccessGroupSelect,
  defaultAccessGroupSelectSettings,
} from '../../../metadata/accessGroup/AccessGroupSelect';
import { SubmitButton } from '../../../../infrastructure/forms/common/SubmitButton';
import { Checkbox } from '../../../../infrastructure/interface/forms/Checkbox';
import { PrimaryButton } from '../../../../infrastructure/interface/buttons/PrimaryButton';

export const BatchDeleteUsers = () => {
  const { translate } = useInternationalisation();

  //Data states
  const [pageNumber, setPageNumber] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(defaultInitialResultsPerPageOption);
  const [latestResponse, setLatestResponse] = useState<GetUsersForBatchDeleteResponse | null>(null);
  const [accessGroupId, setAccessGroupId] = useState<number | null>(null);
  const { usersToDelete, isUserChecked, handleCheckboxChanged, setUsersToDelete } =
    useUsersToDelete();

  //Requests
  const usersToDeleteRequest = GetUsersForBatchDeleteRequest(setLatestResponse);
  const batchDeleteUsersRequest = BatchDeleteUsersRequest(accessGroupId ?? 1);

  const getUsers = () => {
    //clear the current usersToDelete array when a new batch of users is fetched/access group changes.
    setUsersToDelete([]);

    if (accessGroupId === null) return;

    usersToDeleteRequest.get({
      pageNumber,
      resultsPerPage,
      accessGroupId,
    });
  };

  useEffect(() => getUsers(), [pageNumber, resultsPerPage, accessGroupId]);
  const submitForm = (formikHelpers: FormikHelpers<BatchDeleteUserFormModel>) => {
    batchDeleteUsersRequest.submit(
      usersToDelete.map((value) => {
        return { username: value.username };
      }),
      (isSubmitting) => formikHelpers.setSubmitting(isSubmitting),
      (response) => {
        usersToDelete.splice(0);
        getUsers();
      }
    );
  };

  const handleCheckAllButton = () => {
    if (latestResponse?.users == null) return;

    let arrayChanges = [];

    for (const user of latestResponse?.users) {
      arrayChanges.push(user);
    }

    setUsersToDelete(arrayChanges);
  };
  const handleUncheckAllButton = () => setUsersToDelete([]);

  if (usersToDeleteRequest.state.error) {
    return <RequestFailedAlert error={usersToDeleteRequest.state.error} retry={getUsers} />;
  }

  const resultsWereEmpty: boolean = !latestResponse || isEmpty(latestResponse?.users);

  return (
    <Panel>
      {/*filters*/}
      <PanelHeaderRow>
        <AccessGroupSelect
          value={accessGroupId}
          onChange={(value) => setAccessGroupId(value!)}
          onError={() => null}
          settings={{ ...defaultAccessGroupSelectSettings, includeBlank: false }}
        />
        <ButtonContainer>
          <SelectButton onClick={handleCheckAllButton}>
            {translate('pages.users.batchCreate.checkAll')}
          </SelectButton>
          <PrimaryButton onClick={handleUncheckAllButton}>
            {translate('pages.users.batchCreate.uncheckAll')}
          </PrimaryButton>
        </ButtonContainer>
      </PanelHeaderRow>

      {/*table*/}
      <Formik<BatchDeleteUserFormModel>
        initialValues={initialFormModel}
        onSubmit={(values, formikHelpers) => submitForm(formikHelpers)}
      >
        <Form>
          <BatchTableComponent
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            resultsPerPage={resultsPerPage}
            setResultsPerPage={setResultsPerPage}
            totalResultsCount={latestResponse?.count}
            inProgress={usersToDeleteRequest.state.inProgress}
            response={latestResponse}
            loadData={getUsers}
            headers={
              <Tr>
                <Th>{translate('pages.users.table.deleteUsers.headings.delete')}</Th>
                <Th>{translate('pages.users.table.deleteUsers.headings.username')}</Th>
                <Th>{translate('pages.users.table.deleteUsers.headings.counterpartId')}</Th>
                <Th>{translate('pages.users.table.deleteUsers.headings.counterpart')}</Th>
                <Th>{translate('pages.users.table.deleteUsers.headings.emailAddress')}</Th>
                <Th>{translate('pages.users.table.deleteUsers.headings.role')}</Th>
                <Th>{translate('pages.users.table.deleteUsers.headings.accessGroup')}</Th>
                <Th>{translate('pages.users.table.deleteUsers.headings.status')}</Th>
              </Tr>
            }
            body={
              resultsWereEmpty ? (
                <NoResultsRow colSpan={8} />
              ) : (
                <>
                  {latestResponse?.users.map((user, index) => (
                    <Tr key={user.username}>
                      <Td>
                        <Checkbox
                          checked={isUserChecked(user)}
                          onChange={(isChecked) => handleCheckboxChanged(isChecked, user)}
                        />
                      </Td>
                      <Td>{user.username}</Td>
                      <Td>{user.counterpart}</Td>
                      <Td>{user.counterpartId}</Td>
                      <Td>{user.emailAddress}</Td>
                      <Td>{user.role}</Td>
                      <Td>{user.accessGroup}</Td>
                      <Td>{user.status}</Td>
                    </Tr>
                  ))}
                </>
              )
            }
          />
          <SubmitButton
            disabled={usersToDelete.length < 1}
            submittingText={translate('pages.users.batchDelete.buttonSubmittingText')}
            stretch={true}
          >
            {translate('pages.users.batchDelete.buttonSubmitText', {
              noOfUsers: usersToDelete.length,
            })}
          </SubmitButton>
        </Form>
      </Formik>
    </Panel>
  );
};

const useUsersToDelete = () => {
  const [usersToDelete, setUsersToDelete] = useState<any[]>([]);
  const handleCheckboxChanged = (isChecked: boolean, user: any) => {
    if (isChecked) {
      setUsersToDelete([...usersToDelete, user]);
    } else {
      setUsersToDelete(usersToDelete.filter((u) => u.username !== user.username));
    }
  };
  const isUserChecked = (user: any) =>
    usersToDelete.find((u) => u.username === user.username) != null;

  return { usersToDelete, isUserChecked, handleCheckboxChanged, setUsersToDelete };
};

const PanelHeaderRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing32};

  ${Header2} {
    margin: 0;
  }
`;

const initialFormModel: BatchDeleteUserFormModel = {
  usersToDelete: [],
};

const ButtonContainer = styled.div`
  display: flex;
`;

const SelectButton = styled(PrimaryButton)`
  margin-right: ${spacing16};
`;
