export const colourPrimary01 = 'var(--colour-primary-01)';
export const colourPrimary02 = 'var(--colour-primary-02)';
export const colourPrimary03 = 'var(--colour-primary-03)';
export const colourPrimary04 = 'var(--colour-primary-04)';
export const colourPrimary05 = 'var(--colour-primary-05)';
export const colourPrimary06 = 'var(--colour-primary-06)';
export const colourPrimary07 = 'var(--colour-primary-07)';
export const colourPrimary08 = 'var(--colour-primary-08)';
export const colourPrimary09 = 'var(--colour-primary-09)';

export const colourSecondary01 = 'var(--colour-secondary-01)';
export const colourSecondary02 = 'var(--colour-secondary-02)';
export const colourSecondary03 = 'var(--colour-secondary-03)';
export const colourSecondary04 = 'var(--colour-secondary-04)';
export const colourSecondary05 = 'var(--colour-secondary-05)';
export const colourSecondary06 = 'var(--colour-secondary-06)';
export const colourSecondary07 = 'var(--colour-secondary-07)';
export const colourSecondary08 = 'var(--colour-secondary-08)';
export const colourSecondary09 = 'var(--colour-secondary-09)';

export const colourTertiary01 = 'var(--colour-tertiary-01)';
export const colourTertiary02 = 'var(--colour-tertiary-02)';
export const colourTertiary03 = 'var(--colour-tertiary-03)';
export const colourTertiary04 = 'var(--colour-tertiary-04)';
export const colourTertiary05 = 'var(--colour-tertiary-05)';
export const colourTertiary06 = 'var(--colour-tertiary-06)';
export const colourTertiary07 = 'var(--colour-tertiary-07)';
export const colourTertiary08 = 'var(--colour-tertiary-08)';
export const colourTertiary09 = 'var(--colour-tertiary-09)';

export const colourGrey01 = 'var(--colour-grey-01)';
export const colourGrey02 = 'var(--colour-grey-02)';
export const colourGrey03 = 'var(--colour-grey-03)';
export const colourGrey04 = 'var(--colour-grey-04)';
export const colourGrey05 = 'var(--colour-grey-05)';
export const colourGrey06 = 'var(--colour-grey-06)';
export const colourGrey07 = 'var(--colour-grey-07)';
export const colourGrey08 = 'var(--colour-grey-08)';
export const colourGrey09 = 'var(--colour-grey-09)';

export const colourWhite = 'var(--colour-white)';
export const colourBlack = 'var(--colour-black)';

export const colourNotice01 = 'var(--colour-notice-01)';
export const colourNotice02 = 'var(--colour-notice-02)';
export const colourNotice03 = 'var(--colour-notice-03)';
export const colourNotice04 = 'var(--colour-notice-04)';
export const colourNotice05 = 'var(--colour-notice-05)';
export const colourNotice06 = 'var(--colour-notice-06)';
export const colourNotice07 = 'var(--colour-notice-07)';
export const colourNotice08 = 'var(--colour-notice-08)';
export const colourNotice09 = 'var(--colour-notice-09)';

export const colourPositive01 = 'var(--colour-positive-01)';
export const colourPositive02 = 'var(--colour-positive-02)';
export const colourPositive03 = 'var(--colour-positive-03)';
export const colourPositive04 = 'var(--colour-positive-04)';
export const colourPositive05 = 'var(--colour-positive-05)';
export const colourPositive06 = 'var(--colour-positive-06)';
export const colourPositive07 = 'var(--colour-positive-07)';
export const colourPositive08 = 'var(--colour-positive-08)';
export const colourPositive09 = 'var(--colour-positive-09)';

export const colourNegative01 = 'var(--colour-negative-01)';
export const colourNegative02 = 'var(--colour-negative-02)';
export const colourNegative03 = 'var(--colour-negative-03)';
export const colourNegative04 = 'var(--colour-negative-04)';
export const colourNegative05 = 'var(--colour-negative-05)';
export const colourNegative06 = 'var(--colour-negative-06)';
export const colourNegative07 = 'var(--colour-negative-07)';
export const colourNegative08 = 'var(--colour-negative-08)';
export const colourNegative09 = 'var(--colour-negative-09)';

export const colourWarning01 = 'var(--colour-warning-01)';
export const colourWarning02 = 'var(--colour-warning-02)';
export const colourWarning03 = 'var(--colour-warning-03)';
export const colourWarning04 = 'var(--colour-warning-04)';
export const colourWarning05 = 'var(--colour-warning-05)';
export const colourWarning06 = 'var(--colour-warning-06)';
export const colourWarning07 = 'var(--colour-warning-07)';
export const colourWarning08 = 'var(--colour-warning-08)';
export const colourWarning09 = 'var(--colour-warning-09)';

export const colourChart01 = 'var(--colour-chart-01)';
export const colourChart02 = 'var(--colour-chart-02)';
export const colourChart03 = 'var(--colour-chart-03)';
export const colourChart04 = 'var(--colour-chart-04)';
export const colourChart05 = 'var(--colour-chart-05)';
export const colourChart06 = 'var(--colour-chart-06)';
export const colourChart07 = 'var(--colour-chart-07)';
export const colourChart08 = 'var(--colour-chart-08)';
export const colourChart09 = 'var(--colour-chart-09)';
export const colourChart10 = 'var(--colour-chart-10)';
export const colourChart11 = 'var(--colour-chart-11)';
export const colourChart12 = 'var(--colour-chart-12)';
export const colourChart13 = 'var(--colour-chart-13)';
export const colourChart14 = 'var(--colour-chart-14)';
export const colourChart15 = 'var(--colour-chart-15)';
export const colourChart16 = 'var(--colour-chart-16)';
export const colourChart17 = 'var(--colour-chart-17)';
export const colourChart18 = 'var(--colour-chart-18)';
export const colourChart19 = 'var(--colour-chart-19)';
export const colourChart20 = 'var(--colour-chart-20)';

export const textColours = {
  default: colourBlack,
  primary: colourPrimary05,
  disabled: colourGrey06,
  selected: colourWhite,
  notice: colourNotice06,
  positive: colourPositive06,
  warning: colourWarning06,
  negative: colourNegative06,
};

export const backgroundColours = {
  default: colourWhite,
  defaultHover: colourGrey01,
  disabled: colourGrey01,
  selected: colourBlack,
  selectedDisabled: colourGrey05,
  notice: colourNotice03,
  positive: colourPositive03,
  warning: colourWarning03,
  negative: colourNegative03,
};

export const borderColours = {
  default: colourGrey04,
  defaultHover: colourBlack,
  primary: colourPrimary05,
  primaryHover: colourPrimary07,
  focus: colourBlack,
  highlight: colourNotice05,
  disabled: colourGrey04,
  selected: colourBlack,
  notice: colourNotice06,
  noticeHover: colourNotice08,
  positive: colourPositive05,
  positiveHover: colourPositive07,
  warning: colourWarning05,
  warningHover: colourWarning07,
  negative: colourNegative05,
  negativeHover: colourNegative08,
};
