import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { SelectOptions } from '../../infrastructure/interface/forms/BaseSelect';
import { MetadataSingleSelectComponentProps } from './MetadataSelectProps';
import { QueryParameters } from '../../infrastructure/api/makeRequest';
import { SingleSelect } from '../../infrastructure/interface/forms/SingleSelect';
import { onDesktop } from '../../styling/layout/screenBreakpoints';
import { FieldLabel } from '../../infrastructure/forms/common/FieldLabel';

export type ValidMetadataSelectOptionValue = string | number | null;

export type ValidMetadataSelectSettings = Record<
  string,
  string | number | boolean | null | undefined
>;

// It won't always be possible to map settings to a valid set of query parameters,
// for example if a required query parameter is optional in the settings object.
// To indicate this, a consumer can return `cannotMapSettingsToValidQueryParameters`
// in the `mapSettingsToQueryParameters` prop. The code in `MetadataSingleSelect`
// that deals with making the API request is then aware of when there isn't enough
// information to load the data from the back-end.
export const cannotMapSettingsToValidQueryParameters = 'CannotMapSettingsToQueryParameters';
type CannotMapSettingsToValidQueryParameters = typeof cannotMapSettingsToValidQueryParameters;

type MetadataSingleSelectProps<
  TSelectOptionValue,
  TSettings extends ValidMetadataSelectSettings,
  TQueryParameters,
  TResponse
> = {
  endpointUrl: string;
  mapSettingsToQueryParameters: (
    settings: TSettings
  ) => TQueryParameters | CannotMapSettingsToValidQueryParameters;
  mapResponseToOptions: (response: TResponse) => SelectOptions<TSelectOptionValue>;
  placeholder: string;
  loadingPlaceholder: string;
  showPlaceholderIfCannotLoad?: boolean;
  minWidthInPixels: number;
} & MetadataSingleSelectComponentProps<TSelectOptionValue, TSettings, TResponse>;

export const MetadataSingleSelect = <
  TSelectOptionValue extends ValidMetadataSelectOptionValue,
  TSettings extends ValidMetadataSelectSettings,
  TQueryParameters extends QueryParameters,
  TResponse extends object
>(
  props: MetadataSingleSelectProps<TSelectOptionValue, TSettings, TQueryParameters, TResponse>
) => {
  const {
    endpointUrl,
    settings,
    mapSettingsToQueryParameters,
    mapResponseToOptions,
    placeholder,
    loadingPlaceholder,
    showPlaceholderIfCannotLoad,
    minWidthInPixels,
    defaultToFirstOption,
    defaultToOnlyOption,
    onLoaded,
    value,
    onChange,
    onError,
    ...selectProps
  } = props;

  const getDataRequest = useGetJson<TQueryParameters, TResponse>(endpointUrl);

  const { response, inProgress } = getDataRequest.state;
  const responseNotLoadedOrInProgress = response == null || inProgress;

  const queryParameters = mapSettingsToQueryParameters(settings);
  const canLoad = queryParameters !== cannotMapSettingsToValidQueryParameters;

  useEffect(() => {
    if (canLoad) {
      getDataRequest.makeRequest({
        queryParameters,
        onFailure: onError,
        onSuccess: (response) => {
          const options = mapResponseToOptions(response);

          // Clear the selected value if it's not a valid option
          if (value != null && !options.find((option) => option.value === value)) {
            onChange(null as TSelectOptionValue);
          }

          // Select the first option if no value is already selected
          if (value == null && defaultToFirstOption) {
            onChange(options[0]?.value ?? (null as TSelectOptionValue));
          }

          // Select the only option when there is only one possible
          if (value == null && defaultToOnlyOption && options.length === 1) {
            onChange(options[0]?.value ?? (null as TSelectOptionValue));
          }

          if (onLoaded != null) {
            onLoaded(response);
          }
        },
      });
    }
  }, Object.values(settings)); // eslint-disable-line react-hooks/exhaustive-deps

  const options: SelectOptions<TSelectOptionValue> = useMemo(
    () => (response == null ? [] : mapResponseToOptions(response)),
    [response, mapResponseToOptions]
  );

  const placeholderToUse =
    !canLoad && !showPlaceholderIfCannotLoad
      ? '—'
      : responseNotLoadedOrInProgress
      ? loadingPlaceholder
      : placeholder;

  const renderContent = () => {
    return (
      <>
        {props.fieldLabel != null ? <FieldLabel>{props.fieldLabel}</FieldLabel> : null}
        <StyledSingleSelect<TSelectOptionValue>
          {...selectProps}
          options={options}
          value={value}
          onChange={onChange}
          placeholder={placeholderToUse}
          disabled={responseNotLoadedOrInProgress || selectProps.disabled}
          {...{ width: `${props.minWidthInPixels}px` }}
        />
      </>
    );
  };

  if (props.hideIfEmptyOrSingleOption === true && options.length < 2) return null;

  return <>{props.wrapInDiv ? <div>{renderContent()}</div> : renderContent()}</>;
};

const StyledSingleSelect = styled(SingleSelect)<{ width: string }>`
  width: auto;

  ${(props) =>
    onDesktop(css`
      min-width: ${props.width};
    `)};
` as typeof SingleSelect;
