import axios, { CancelTokenSource } from 'axios';
import { ApiResponse } from './ApiResponse';
import { getSuccessResponse } from './SuccessResponse';
import { getErrorResponse } from './ErrorResponse';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';

// This can be extended if needed
export type MultipartRequestValue = string | number | boolean | File | null | undefined;

export type MultipartRequest = Record<string, MultipartRequestValue>;

export type MultipartRequestParameters<TRequest extends MultipartRequest> = {
  request: TRequest;
  endpointUrl: string;
  cancelTokenSource?: CancelTokenSource;
};

export const makeMultipartRequest = <
  TRequest extends MultipartRequest,
  TResponse extends object = {}
>(
  { request, endpointUrl, cancelTokenSource }: MultipartRequestParameters<TRequest>,
  translate: TranslateFunction | null
): Promise<ApiResponse<TResponse>> => {
  const formData = new FormData();

  for (const key of Object.keys(request)) {
    const rawValue = request[key as keyof TRequest];
    const formValue = getFormValue(rawValue);

    if (formValue != null) {
      formData.append(key, formValue);
    }
  }

  return axios({
    method: 'post',
    url: endpointUrl,
    data: formData,
    cancelToken: cancelTokenSource?.token,
    timeout: 60 * 1000, // 1 minute
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((axiosResponse) => getSuccessResponse(axiosResponse))
    .catch((error) => getErrorResponse(error, translate));
};

const getFormValue = (value: MultipartRequestValue): string | File | null => {
  if (isFile(value)) {
    return value;
  }

  if (value == null) {
    return null;
  }

  return value.toString();
};

const isFile = (value: unknown): value is File => value instanceof File;
