import React, { useContext } from 'react';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import styled, { css } from 'styled-components/macro';
import { spacing16, spacing64, spacing8 } from '../../styling/design/spacing';
import { MobilePaginationControls } from '../../infrastructure/interface/components/MobilePaginationControls';
import { TransactionsContext } from './TransactionsContext';
import { LoadingOverlay } from '../../infrastructure/interface/components/LoadingOverlay';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { isEmpty } from 'lodash';
import { Transaction } from './types';
import { AppLink } from '../../infrastructure/interface/components/AppLink';
import { fontSizeCss, fontWeightBold } from '../../styling/design/fonts';
import { borderColours, colourPrimary05 } from '../../styling/design/colours';
import { isNullUndefinedOrBlank } from '../../helpers/stringHelpers';
import { IfUserHasRole } from '../authentication/UserRoles';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { UserRole } from '../authentication/UserRole';
import { ResetFiltersButton } from '../filters/ResetFiltersButton';
import { FieldLabel } from '../../infrastructure/forms/common/FieldLabel';
import { AgentDealerSelect } from '../metadata/AgentDealerSelect';
import {
  transactionsPageAgentSelectTestId,
  transactionsPageCompanySelectTestId,
  transactionsPageDealerSelectTestId, transactionsPageEmployeeSelectTestId,
  transactionsPageEquityAttributionSelectTestId,
  transactionsPageInvestorSelectTestId,
  transactionsPageTransactionTypeSelectTestId
} from './Transactions';
import { DatePicker } from '../../infrastructure/interface/forms/DatePicker';
import { CompanySelect } from '../metadata/company/CompanySelect';
import { EquityAttributionSelect } from '../metadata/equityAttribution/EquityAttributionSelect';
import { TransactionTypeMultiSelect } from '../metadata/transactions/TransactionTypeMultiSelect';
import { Checkbox } from '../../infrastructure/interface/forms/Checkbox';
import { TransactionApproveToggleSwitch } from './TransactionApproveToggleSwitch';
import {
  ItemDetailsCard,
  MobileNoResultsCard,
  MobileThresholdLimitCard,
} from '../../infrastructure/interface/components/ItemDetailsCard';
import { MobileFilterSet } from '../filters/MobileFilterSet';
import { FieldAndValue } from '../../infrastructure/interface/components/FieldAndValue';
import { InvestorSelectWithSearchModal } from '../metadata/investor/InvestorSelectWithSearchModal';
import { defaultAgentDealerSelectSettings } from '../metadata/AgentDealerSelectSettings';
import { defaultCompanySelectSettings } from '../metadata/company/CompanySelectSettings';
import { defaultEquityAttributionSelectSettings } from '../metadata/equityAttribution/EquityAttributionSelectSettings';
import { defaultTransactionTypeSelectSettings } from '../metadata/transactions/TransactionTypeSelectBase';
import { TransactionStatusComponent } from './DesktopTransactions';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { TransactionsActionButtons } from './TransactionsActionButtons';
import { onMobile } from '../../styling/layout/screenBreakpoints';
import { EmployeeSelect } from '../metadata/employees/EmployeeSelect';

const nonInvestorRoles: Array<UserRole> = ['Manager', 'Advisor', 'Consolidated Investor'];

export const MobileTransactions = () => {
  const { translate } = useInternationalisation();

  return (
    <>
      <Header1>{translate('pages.transactions.header')}</Header1>
      <Filters />
      <TransactionsActionButtons />
      <TransactionCardsComponent />
    </>
  );
};

const Filters = () => {
  const { translate } = useInternationalisation();
  const {
    employeeId,
    setEmployeeId,
    investorId,
    setInvestorId,
    companyId,
    setCompanyId,
    equityAttributionId,
    setEquityAttributionId,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    transactionTypes,
    setTransactionTypes,
    agentId,
    setAgentId,
    dealerId,
    setDealerId,
    unapprovedOnly,
    setUnapprovedOnly,
    resetFilters,
    setFilterLoadError,
  } = useContext(TransactionsContext);

  return (
    <MobileFilterSet>
      <IfUserHasRole userRole="Advisor">
        <EmployeeSelect
          fieldLabel={translate('pages.holdings.filters.advisor')}
          value={employeeId}
          onChange={setEmployeeId}
          data-testid={transactionsPageEmployeeSelectTestId}
          onError={setFilterLoadError}
          clearable={true}
          wrapInDiv={true}
        />
      </IfUserHasRole>
      
      <IfUserHasRole userRole={['Manager', 'Advisor', 'Consolidated Investor']}>
        <InvestorSelectWithSearchModal
          fieldLabel={translate('pages.transactions.filters.investor')}
          settings={{ forTransacting: false, employeeId: employeeId }}
          value={investorId}
          onChange={setInvestorId}
          data-testid={transactionsPageInvestorSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <CompanySelect
        fieldLabel={translate('pages.transactions.filters.company')}
        settings={{ ...defaultCompanySelectSettings, includeAllFunds: true, includeBlank: true, employeeCounterpartId: employeeId  }}
        value={companyId}
        onChange={setCompanyId}
        data-testid={transactionsPageCompanySelectTestId}
        onError={setFilterLoadError}
        defaultToFirstOption={true}
        wrapInDiv={true}
      />

      <EquityAttributionSelect
        fieldLabel={translate('pages.transactions.filters.equityAttribution')}
        settings={{ ...defaultEquityAttributionSelectSettings, companyId, employeeCounterpartId: employeeId  }}
        value={equityAttributionId}
        onChange={setEquityAttributionId}
        data-testid={transactionsPageEquityAttributionSelectTestId}
        onError={setFilterLoadError}
        defaultToFirstOption={true}
        wrapInDiv={true}
      />

      <div>
        <FieldLabel>{translate('pages.transactions.filters.startDate')}</FieldLabel>
        <DatePicker value={startDate} onChange={setStartDate} clearable />
      </div>

      <div>
        <FieldLabel>{translate('pages.transactions.filters.endDate')}</FieldLabel>
        <DatePicker value={endDate} onChange={setEndDate} clearable />
      </div>

      <div>
        <FieldLabel>{translate('pages.transactions.filters.transactionType')}</FieldLabel>
        <TransactionTypeMultiSelect
          settings={{ ...defaultTransactionTypeSelectSettings, includeBlank: false }}
          values={transactionTypes}
          onChange={setTransactionTypes}
          data-testid={transactionsPageTransactionTypeSelectTestId}
          onError={setFilterLoadError}
          clearable={true}
        />
      </div>

      <IfUserHasRole userRole={'Manager'}>
        <AgentDealerSelect
          fieldLabel={translate('pages.transactions.filters.agent')}
          settings={{ ...defaultAgentDealerSelectSettings, isAgent: true }}
          value={agentId}
          onChange={setAgentId}
          data-testid={transactionsPageAgentSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          wrapInDiv={true}
        />

        <AgentDealerSelect
          fieldLabel={translate('pages.transactions.filters.dealer')}
          settings={{ ...defaultAgentDealerSelectSettings, isAgent: false }}
          value={dealerId}
          onChange={setDealerId}
          data-testid={transactionsPageDealerSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          wrapInDiv={true}
        />

        <div>
          <FieldLabel>{translate('pages.transactions.filters.unapprovedOnly')}</FieldLabel>
          <Checkbox checked={unapprovedOnly ?? false} onChange={setUnapprovedOnly} />
        </div>
      </IfUserHasRole>

      <ResetFiltersButton resetFilters={resetFilters} />
    </MobileFilterSet>
  );
};

const TransactionCardsComponent = () => {
  const { pageNumber, setPageNumber, resultsPerPage, setResultsPerPage, response, inProgress } =
    useContext(TransactionsContext);

  if (response == null && inProgress) {
    return (
      <SpinnerContainer>
        <CentredSpinner size="xlarge" />
      </SpinnerContainer>
    );
  }

  if (response == null || isEmpty(response?.transactions)) {
    if (response?.total === -1) {
      return <MobileThresholdLimitCard />;
    } else {
      return <MobileNoResultsCard />;
    }
  }

  return (
    <>
      <ResultsContainer>
        <LoadingOverlay showOverlay={inProgress}>
          {response?.transactions.map((transaction, index) => (
            <TransactionCardComponent key={index} transaction={transaction} />
          ))}
        </LoadingOverlay>
      </ResultsContainer>
      <MobilePaginationControls
        resultsPerPage={resultsPerPage}
        totalResultsCount={response?.total || 0}
        onChangeResultsPerPage={setResultsPerPage}
        currentPageNumber={pageNumber}
        onChangeCurrentPageNumber={setPageNumber}
      />
    </>
  );
};

const SpinnerContainer = styled.div`
  height: ${spacing64};
`;

const ResultsContainer = styled.div`
  margin-top: ${spacing16};
`;

type TransactionCardProps = {
  transaction: Transaction;
};

const TransactionCardComponent = ({ transaction }: TransactionCardProps) => {
  const { formatDate, formatNumber, translate } = useInternationalisation();
  const { loadData } = useContext(TransactionsContext);
  const showContractNoteColumn = transaction.contractNoteDocumentId !== null;
  return (
    <ItemDetailsCard subtitle={formatDate(transaction?.date ? transaction.date.toString() : '')}>
      <TransactionHeadingComponent
        fund={transaction.fund}
        investorName={transaction.investorName}
        investorNumber={transaction.investorNumber}
        investorId={transaction.investorId}
        showInvestorDetailsLink={transaction.showInvestorDetailsLink}
        currentInvestorHideId={transaction.currentInvestorHideId}
      />
      <IfUserHasRole userRole={nonInvestorRoles}>
        <FieldAndValue
          type="text"
          fieldLabel={translate('pages.transactions.tables.searchResults.headings.fund')}
          value={transaction.fund}
        />
      </IfUserHasRole>
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.transactions.tables.searchResults.headings.shareSeries')}
        value={transaction.shareSeries}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.transactions.tables.searchResults.headings.type')}
        value={transaction.type}
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.transactions.tables.searchResults.headings.units')}
        value={
          transaction.units != null
            ? formatNumber(transaction.units, { decimalPlaces: transaction.shareDecimals })
            : ''
        }
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.transactions.tables.searchResults.headings.price')}
        value={
          transaction.price != null
            ? formatNumber(transaction.price, { decimalPlaces: transaction.priceDecimals })
            : ''
        }
      />
      <FieldAndValue
        type="text"
        fieldLabel={translate('pages.transactions.tables.searchResults.headings.currency')}
        value={transaction.currency}
      />
      <FieldAndValue
        type="custom"
        fieldLabel={translate('pages.transactions.tables.searchResults.headings.value')}
        value={
          <TransactionValueWrapper>
            {formatNumber(transaction.value, { decimalPlaces: transaction.currencyDecimals })}
          </TransactionValueWrapper>
        }
      />
      {showContractNoteColumn && (
        <FieldAndValue
          type="custom"
          fieldLabel={translate('pages.transactions.tables.searchResults.headings.cn')}
          value={
            <AppLink to={`/documents/${transaction.contractNoteDocumentId}`}>
              {transaction.contractNoteDocumentName}
            </AppLink>
          }
        />
      )}
      <IfUserHasRole userRole={'Manager'}>
        <FieldAndValue
          type="custom"
          fieldLabel={translate('pages.transactions.tables.searchResults.headings.approve')}
          value={
            <TransactionApproveToggleSwitch
              managerApproval={transaction.managerApproval}
              dealNumber={transaction.dealNumber}
              onApprovalToggled={loadData}
            />
          }
        />
      </IfUserHasRole>
      <FieldAndValue
        type="custom"
        fieldLabel={translate('pages.transactions.tables.searchResults.headings.status')}
        value={
          <TransactionStatusComponent
            status={transaction.status}
            chaserMessagesTranslationKeys={transaction.chaserMessagesTranslationKeys}
          />
        }
      />
    </ItemDetailsCard>
  );
};

type TransactionHeadingProps = {
  fund: string | null;
  investorName: string | null;
  investorNumber: string | null;
  investorId: number | null;
  showInvestorDetailsLink: boolean;
  currentInvestorHideId: boolean | false;
};

const TransactionHeadingComponent = ({
  fund,
  investorName,
  investorNumber,
  investorId,
  showInvestorDetailsLink, 
  currentInvestorHideId,
}: TransactionHeadingProps) => {
  const authenticationContext = useContext(AuthenticationContext);

  const isNonInvestor = nonInvestorRoles.some(
    (userRole) => authenticationContext.getUser().role === userRole
  );

  return isNonInvestor ? (
    <IfUserHasRole userRole={nonInvestorRoles}>
      {showInvestorDetailsLink ? (
        <AppLink to={currentInvestorHideId ? `/investor-details/` : `/investor-details/${investorId}`}>
          <TransactionFundContainer>
            {investorName}
            {!isNullUndefinedOrBlank(investorNumber) && ` (${investorNumber})`}
          </TransactionFundContainer>
        </AppLink>
      ) : (
        <TransactionFundContainer>
          {investorName}
          {!isNullUndefinedOrBlank(investorNumber) && ` (${investorNumber})`}
        </TransactionFundContainer>
      )}
    </IfUserHasRole>
  ) : (
    <TransactionFundContainer>{fund}</TransactionFundContainer>
  );
};

const TransactionFundContainer = styled.div`
  ${fontSizeCss('large')};
  font-weight: ${fontWeightBold};

  ${onMobile(css`
    border-bottom: ${borderColours.default} 1px solid;
    padding-bottom: ${spacing8};
    margin-bottom: ${spacing8};
  `)}
`;

const TransactionValueWrapper = styled.span`
  color: ${colourPrimary05};
`;
