import {
  clearSessionStorage,
  readSessionStorageNumber,
  sessionStorageCodes,
  writeSessionStorage,
} from './useSessionStorage';

export const useSessionCompanyId = () => {
  const sessionCompanyId: number | null = readSessionStorageNumber(sessionStorageCodes.companyId);

  const setStoredCompanyId = (companyId: number | null | undefined): void => {
    if (companyId != null && companyId > 0) {
      writeSessionStorage(sessionStorageCodes.companyId, companyId.toString());
    } else {
      clearSessionStorage(sessionStorageCodes.companyId);
    }
  };

  return { sessionCompanyId, setStoredCompanyId };
};
