import React from 'react';
import {
  InvestorBankingDetailsError,
  useInvestorBankingDetailsContext
} from './InvestorBankingDetailsContext';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { DesktopInvestorBankingDetails } from './DesktopInvestorBankingDetails';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { MobileInvestorBankingDetails } from './MobileInvestorBankingDetails';
import { Card } from '../../../infrastructure/interface/components/Card';
import { ExpandableWithHeader } from '../../../infrastructure/interface/components/Expandable';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';

export const BankingDetailsManagerView = () => {
  const { translate } = useInternationalisation();
  
  const {
    bankingDetailsRequestError,
    permissionsRequestError,
  } = useInvestorBankingDetailsContext();

  if (bankingDetailsRequestError || permissionsRequestError) {
    return <InvestorBankingDetailsError />;
  }

  return (
    <>
        <DesktopOnly>
          <Card
            header={translate('pages.bankingDetails.title')}
            cardType="expandable"
            startExpanded={false}
          >
            <DesktopInvestorBankingDetails canEdit={false} />
          </Card>
        </DesktopOnly>
        <MobileOnly>
          <ExpandableWithHeader
            headerText={translate('pages.bankingDetails.title')}
            startExpanded={false}
          >
            <MobileInvestorBankingDetails canEdit={false}/>
          </ExpandableWithHeader>
        </MobileOnly>
    </>
  );
};
