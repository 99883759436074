import styled from 'styled-components/macro';
import { MailToLink } from '../../../infrastructure/interface/components/ExternalLink';
import { ExpandableWithHeader } from '../../../infrastructure/interface/components/Expandable';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import { ItemDetailsCard } from '../../../infrastructure/interface/components/ItemDetailsCard';
import { LoadingOverlay } from '../../../infrastructure/interface/components/LoadingOverlay';
import { MobilePaginationControls } from '../../../infrastructure/interface/components/MobilePaginationControls';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { spacing16 } from '../../../styling/design/spacing';
import { investorDetailsComponentResultsPerPageOptions } from '../InvestorDetails';
import { useCommunicationCopies } from './CommunicationCopiesContext';
import { NoCommunicationCopiesContainer } from './DesktopCommunicationCopiesList';

export const MobileCommunicationCopiesList = () => {
  const { translate } = useInternationalisation();
  const { blocked, latestResponse, requestInProgress } = useCommunicationCopies();

  const initialRequestIsInProgress = latestResponse == null && requestInProgress;

  return (
    <ExpandableWithHeader
      headerText={
        blocked
          ? translate('pages.investorDetailsPage.communicationCopies.blockedHeader')
          : translate('pages.investorDetailsPage.communicationCopies.header')
      }
      startExpanded={false}
      contentIsLoading={initialRequestIsInProgress}
    >
      <ExpandableContentContainer>
        <CommunicationCopiesCards />
      </ExpandableContentContainer>
    </ExpandableWithHeader>
  );
};

const CommunicationCopiesCards = () => {
  const { translate } = useInternationalisation();

  const translateFieldName = (fieldName: string) =>
    translate(`pages.investorDetailsPage.communicationCopies.fieldNames.${fieldName}`);

  const {
    blocked,
    latestResponse,
    resultsPerPage,
    setResultsPerPage,
    pageNumber,
    setPageNumber,
    requestInProgress,
  } = useCommunicationCopies();

  if (latestResponse == null) {
    return null;
  }

  if (latestResponse.totalResultsCount === 0) {
    return (
      <NoCommunicationCopiesContainer>
        {blocked
          ? translate('pages.investorDetailsPage.communicationCopies.noBlockedCommunicationCopies')
          : translate('pages.investorDetailsPage.communicationCopies.noCommunicationCopies')}
      </NoCommunicationCopiesContainer>
    );
  }

  return (
    <>
      <LoadingOverlay showOverlay={requestInProgress}>
        {latestResponse.communicationCopies.map((copy, index) => (
          <ItemDetailsCard key={index}>
            <FieldAndValue
              type="text"
              fieldLabel={translateFieldName('counterpartName')}
              value={copy.counterpartName}
            />
            {blocked ? (
              <FieldAndValue
                type="text"
                fieldLabel={translateFieldName('companyName')}
                value={copy.companyName}
              />
            ) : copy.emailAddress == null ? null : (
              <FieldAndValue
                type="custom"
                fieldLabel={translateFieldName('emailAddress')}
                value={<MailToLink emailAddress={copy.emailAddress} />}
              />
            )}
          </ItemDetailsCard>
        ))}
      </LoadingOverlay>
      <MobilePaginationControls
        currentPageNumber={pageNumber}
        onChangeCurrentPageNumber={setPageNumber}
        resultsPerPage={resultsPerPage}
        onChangeResultsPerPage={setResultsPerPage}
        totalResultsCount={latestResponse.totalResultsCount}
        resultsPerPageOptions={investorDetailsComponentResultsPerPageOptions}
      />
    </>
  );
};

const ExpandableContentContainer = styled.div`
  padding-top: ${spacing16};
`;
