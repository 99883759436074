import React, { useEffect, useState } from 'react';
import { kebabCase } from 'lodash';
import { LoadingSplashScreen } from '../../features/SplashScreen';
import { logClientError } from '../../infrastructure/errors/errorHelpers';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { useSiteContext } from '../../features/multiTenancy/SiteContext';
import { useBrandingResourceUrl } from './useBrandingResourceUrl';

const customStylesDatasetKey = 'isCustomStylesLink';

type Props = {
  children: React.ReactNode;
};

export const CustomStyles = ({ children }: Props) => {
  const siteId = useSiteContext().siteId;

  const customStylesResourceUrl = useBrandingResourceUrl('/api/branding/GetCustomStyleSheet');

  const { translate } = useInternationalisation();
  const [isLoading, setIsLoading] = useState(true);

  const getExistingCustomStyleLinkElement = (): HTMLLinkElement | null =>
    document.head.querySelector(`link[data-${kebabCase(customStylesDatasetKey)}="true"]`);

  const customStyleLinkElementExists = () => getExistingCustomStyleLinkElement() != null;

  const safeRemoveCustomStyleLinkElement = () => {
    const existingStyleLinkElement = getExistingCustomStyleLinkElement();

    if (existingStyleLinkElement != null) {
      document.head.removeChild(existingStyleLinkElement);
    }
  };

  const createCustomStyleLinkElement = () => {
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = customStylesResourceUrl;
    linkElement.onload = onStylesheetLoaded;
    linkElement.onerror = () => onStylesheetLoadError(linkElement);
    linkElement.dataset[customStylesDatasetKey] = 'true';
    return linkElement;
  };

  const onStylesheetLoaded = () => {
    setIsLoading(false);
  };

  const onStylesheetLoadError = (newLinkElement: HTMLLinkElement) => {
    setIsLoading(false);

    const errorMessage = `Failed to load custom styles from '${newLinkElement.href}'`;
    const stackTrace = '';

    console.error(errorMessage);
    logClientError(errorMessage, stackTrace, translate, siteId);
  };

  useEffect(() => {
    setIsLoading(true);
    safeRemoveCustomStyleLinkElement();

    const newCustomStyleLinkElement = createCustomStyleLinkElement();

    if (!customStyleLinkElementExists()) {
      document.head.appendChild(newCustomStyleLinkElement);
    }

    return () => {
      setIsLoading(false); // prevent state change after unmount.
      safeRemoveCustomStyleLinkElement();
    };
  }, [customStylesResourceUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoadingSplashScreen translate={translate} />;
  }

  return <>{children}</>;
};
