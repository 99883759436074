import { css } from 'styled-components/macro';
import { spacing16, spacing32, spacing4, spacing48, spacing8 } from '../../design/spacing';
import { colourGrey09 } from '../../design/colours';
import { onMobile } from '../screenBreakpoints';

export const sidebarWidth = 300;

export const menuItemStyles = css`
  width: 100%;
  height: ${spacing32};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: ${colourGrey09};
  transition: opacity 0.25s ease;
  margin: ${spacing16} 0;
  padding-left: ${spacing48};
  border-left-style: solid;
  border-left-width: ${spacing8};
  border-left-color: transparent;

  &:hover {
    opacity: 1;
  }

  svg {
    position: relative;
    height: ${spacing16};
    width: ${spacing16};
    margin-right: ${spacing16};
    top: 1px;
  }

  ${onMobile(css`
    padding-left: ${spacing32};
  `)};
`;
