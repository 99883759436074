import { useGetJson } from '../../infrastructure/api/useGetJson';
import { IsoDatestamp } from '../../helpers/dateTimeHelpers';
import { DealingTransactionTypeCode } from '../metadata/transactions/DealingTransactionType';
import { TransactionLockCode } from '../metadata/transactions/TransactionLock';

export const useGetTransactionDetailRequest = () =>
  useGetJson<GetTransactionDetailQuery, GetTransactionDetailResponse>(
    '/api/transactions/GetTransactionDetail'
  );

type GetTransactionDetailQuery = {
  dealNumber: number;
  uploadId: number;
};

export type GetTransactionDetailResponse = {
  investorName: string;
  investorId: number | null;
  fundName: string;
  fundId: number;
  transactionTypeString: string;
  transactionTypeCode: DealingTransactionTypeCode;
  dealingDate: IsoDatestamp;
  shareClassSeries: string;
  shareClassSeriesId: number;
  transactionLock: TransactionLockCode;
  shareClassSeriesCurrencyCode: string;
  amount: number | null;
  shares: number | null;
  shareDecimals: number | null;
  fees: number;
  feesString: string;
  showEqualisationDeficitCreditMessage: boolean;
  transactionReference: string;
  additionalInstructions: string;
  shareRegistryTradingMessageDisclaimer: string;
  companyIdSwitch: number | null;
  companySwitch: string | null;
  equityAttributionIdSwitch: number | null;
  equityAttributionSwitch: string | null;
  warnings: Array<string>;
};
