import { useParams } from 'react-router';
import { parseIntOrNull } from '../../../../helpers/stringHelpers';

export const distributionReinvestmentIdUrlParamName = 'distributionReinvestmentId';
type DistributionReinvestmentIdUrlParam = typeof distributionReinvestmentIdUrlParamName;

export const useDistributionReinvestmentIdUrlParam = () => {
  const { distributionReinvestmentId: isFromRouteParams } =
    useParams<DistributionReinvestmentIdUrlParam>();

  return parseIntOrNull(isFromRouteParams);
};
