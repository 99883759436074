import { getIsoDatestampToday, IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { useGetJson } from '../../../infrastructure/api/useGetJson';

export const useMaxDateForCompanyRequest = (setMaxDate: (date: IsoDatestamp) => void) => {
  const maxDateRequest = useGetJson<MaxDateForCompanyQuery, MaxDateForCompanyResponse>(
    'api/dashboards/GetMaxDateForCompany'
  );

  const getMaxCompanyDate = (companyId: number) => {
    maxDateRequest.makeRequest({
      queryParameters: {
        companyId: companyId,
        date: getIsoDatestampToday(),
      },
      onSuccess: (response) => {
        setMaxDate(response.maxDate);
      },
    });
  };

  return { maxDateRequest, getMaxCompanyDate };
};

/* types */
type MaxDateForCompanyQuery = {
  companyId: number | null;
  date: IsoDatestamp;
};

export type MaxDateForCompanyResponse = {
  maxDate: IsoDatestamp;
};
