import React, { useState } from 'react';
import queryString from 'query-string';
import { Formik, FormikHelpers } from 'formik';
import { Header2 } from '../../infrastructure/interface/components/Headers';
import { Form } from '../../infrastructure/forms/common/Form';
import { InputField } from '../../infrastructure/forms/fields/InputField';
import { SubmitButton } from '../../infrastructure/forms/common/SubmitButton';
import { Validator } from 'fluentvalidation-ts';
import { useLocation } from 'react-router-dom';
import { usePutJson } from '../../infrastructure/api/usePutJson';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import {
  containDigitCharacter,
  containLowercaseCharacter,
  containMinimumUniqueCharacters,
  containNonAlphanumericCharacter,
  containUppercaseCharacter,
  notBeEmpty,
} from '../validation/stringValidation';
import { ErrorMessage } from '../../infrastructure/interface/components/ErrorMessage';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { SuccessMessage } from '../../infrastructure/interface/components/SuccessMessage';
import { spacing16, spacing32 } from '../../styling/design/spacing';
import styled from 'styled-components/macro';
import { borderColours } from '../../styling/design/colours';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { AppLink } from '../../infrastructure/interface/components/AppLink';

export const ResetPassword = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.resetPassword.title'));

  const location = useLocation();

  const parsedQuery = queryString.parse(location.search);
  const usernameFromQueryString = decodeURIComponent((parsedQuery['username'] as string) || '');
  const passwordResetToken = decodeURIComponent((parsedQuery['token'] as string) || '');

  const [success, setSuccess] = useState(false);

  const resetPasswordApiRequest = usePutJson<ResetPasswordCommand, ResetPasswordResponse>(
    '/api/authentication/ResetPassword'
  );

  const getPasswordRequirementsApiRequest = useGetJson<undefined, GetPasswordRequirementsResponse>(
    '/api/authentication/GetPasswordRequirements'
  );

  useOnMount(() => {
    getPasswordRequirementsApiRequest.makeRequest();
  });

  const onSubmit = (
    formModel: ResetPasswordFormModel,
    formikHelpers: FormikHelpers<ResetPasswordFormModel>
  ) => {
    resetPasswordApiRequest.makeRequest({
      requestBody: getResetPasswordCommand(formModel, passwordResetToken),
      onSuccess: () => setSuccess(true),
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  if (getPasswordRequirementsApiRequest.state.error) {
    return <ErrorMessage>{getPasswordRequirementsApiRequest.state.error}</ErrorMessage>;
  }

  const passwordRequirementsResponse = getPasswordRequirementsApiRequest.state.response;

  if (getPasswordRequirementsApiRequest.state.inProgress || passwordRequirementsResponse == null) {
    return <CentredSpinner size="xlarge" />;
  }

  const resetPasswordFormModelValidator = new ResetPasswordFormModelValidator(
    passwordRequirementsResponse,
    translate
  );

  return (
    <>
      <Header2>{translate('pages.resetPassword.header')}</Header2>
      {!success && (
        <Formik<ResetPasswordFormModel>
          initialValues={getInitialFormModel(usernameFromQueryString)}
          onSubmit={onSubmit}
          validate={resetPasswordFormModelValidator.validate}
        >
          <Form data-testid={resetPasswordFormTestId}>
            <InputField
              label={translate('pages.resetPassword.labels.username')}
              fieldName="username"
              readOnly={usernameFromQueryString !== ''}
            />
            <InputField
              label={translate('pages.resetPassword.labels.password')}
              fieldName="password"
              type="password"
            />
            <InputField
              label={translate('pages.resetPassword.labels.confirmPassword')}
              fieldName="confirmPassword"
              type="password"
            />
            <SubmitButton
              submittingText={translate('pages.resetPassword.button.waitingText')}
              stretch={true}
            >
              {translate('pages.resetPassword.button.text')}
            </SubmitButton>
            {resetPasswordApiRequest.state.error != null && (
              <ErrorMessage>{resetPasswordApiRequest.state.error}</ErrorMessage>
            )}
            <BackToLoginLink to="../login">
              {translate('pages.resetPassword.backToLogin')}
            </BackToLoginLink>
          </Form>
        </Formik>
      )}
      {success && (
        <>
          <SuccessMessage>{translate('pages.resetPassword.successMessage')}</SuccessMessage>
          <AppLink to="../login">{translate('pages.resetPassword.loginLink')}</AppLink>
        </>
      )}
    </>
  );
};

const BackToLoginLink = styled(AppLink)`
  display: block;
  margin-top: ${spacing32};
  padding-top: ${spacing16};
  border-top: solid 1px ${borderColours.default};
`;

export const resetPasswordFormTestId = 'reset-password-form';

export type GetPasswordRequirementsResponse = {
  requiredLength: number;
  requiredUniqueChars: number;
  requireNonAlphanumeric: boolean;
  requireLowercase: boolean;
  requireUppercase: boolean;
  requireDigit: boolean;
};

export type ResetPasswordFormModel = {
  username: string;
  password: string;
  confirmPassword: string;
};

const getInitialFormModel = (username: string): ResetPasswordFormModel => ({
  username,
  password: '',
  confirmPassword: '',
});

class ResetPasswordFormModelValidator extends Validator<ResetPasswordFormModel> {
  constructor(passwordRequirements: GetPasswordRequirementsResponse, translate: TranslateFunction) {
    super();

    this.ruleFor('username').must(notBeEmpty(translate));

    this.ruleFor('password')
      .must(notBeEmpty(translate))
      .minLength(passwordRequirements.requiredLength)
      .withMessage(
        translate('validation.text.minimumLength', {
          minimumLength: passwordRequirements.requiredLength,
        })
      )
      .must(containMinimumUniqueCharacters(passwordRequirements.requiredUniqueChars, translate))
      .must(containNonAlphanumericCharacter(passwordRequirements.requireNonAlphanumeric, translate))
      .must(containLowercaseCharacter(passwordRequirements.requireLowercase, translate))
      .must(containUppercaseCharacter(passwordRequirements.requireUppercase, translate))
      .must(containDigitCharacter(passwordRequirements.requireDigit, translate));

    this.ruleFor('confirmPassword')
      .must(notBeEmpty(translate))
      .must((value, model) => value === model.password)
      .withMessage(translate('pages.requestPasswordReset.validation.passwordsMustMatch'));
  }
}

export type ResetPasswordCommand = {
  username: string;
  passwordResetToken: string;
  newPassword: string;
};

export type ResetPasswordResponse = {};

const getResetPasswordCommand = (
  formModel: ResetPasswordFormModel,
  passwordResetToken: string
): ResetPasswordCommand => ({
  username: formModel.username,
  newPassword: formModel.password,
  passwordResetToken,
});
