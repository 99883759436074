import React, { useContext, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  PopoverMenu,
  PopoverMenuContainer,
  PopoverMenuItem,
} from '../../../../infrastructure/interface/components/PopoverMenu';
import { useOnClickOutside } from '../../../../infrastructure/hooks/useOnClickOutside';
import { CircleSolidIcon, NotificationsIcon, TimesSolidIcon } from '../../../../icons/icons';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { menuItemStyles, sidebarWidth } from '../SidebarStyles';
import { spacing12, spacing16, spacing8 } from '../../../design/spacing';
import { NotificationResponse } from './NotificationResponse';
import { notificationType } from './NotificationType';
import { useNavigate } from 'react-router-dom';
import { onMobile } from '../../screenBreakpoints';
import { useDevice } from '../../../../infrastructure/hooks/useDevice';
import { textColours } from '../../../design/colours';
import { ErrorMessage } from '../../../../infrastructure/interface/components/ErrorMessage';
import { NotificationsContext } from './NotificationsContext';
import { Spinner } from '../../../../infrastructure/interface/components/Spinner';

export const notificationsMenuButtonTestId = 'notifications-manu-button';
export const unclearedNotificationsIconContainerTestId = 'uncleared-notifications-icon';
export const clearNotificationButtonTestId = (notificationId: number) =>
  `clear-notification-button-${notificationId}`;

type NotificationsProps = {
  closeSidebar: () => void;
};

export const Notifications = ({ closeSidebar }: NotificationsProps) => {
  const { translate } = useInternationalisation();
  const { isMobile } = useDevice();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(menuRef, () => setIsMenuOpen(false));

  const {
    notifications,
    clearNotificationById,
    clearRequestInProgress,
    clearRequestInProgressIds,
    clearRequestError,
  } = useContext(NotificationsContext);

  const showUnclearedNotifications = clearRequestError != null || notifications.length > 0;

  return (
    <PopoverMenuContainer ref={menuRef}>
      <MenuButton
        data-testid={notificationsMenuButtonTestId}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <IconsContainer>
          <NotificationsIcon />
          {showUnclearedNotifications && (
            <UnclearedNotificationsIconContainer
              data-testid={unclearedNotificationsIconContainerTestId}
            >
              <CircleSolidIcon />
            </UnclearedNotificationsIconContainer>
          )}
        </IconsContainer>
        {translate('sidebar.notifications.menuTitle')}
      </MenuButton>
      {isMenuOpen && (
        <StyledPopoverMenu verticalPlacement={isMobile ? 'top' : 'bottom'}>
          {notifications.length === 0 ? (
            <NoNotificationsContainer>
              {translate('sidebar.notifications.empty')}
            </NoNotificationsContainer>
          ) : (
            notifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                clearNotification={clearNotificationById}
                clearRequestInProgress={
                  clearRequestInProgress && clearRequestInProgressIds.includes(notification.id)
                }
                closeNotificationsMenu={() => setIsMenuOpen(false)}
                closeSidebar={closeSidebar}
              />
            ))
          )}
          {clearRequestError != null && (
            <ErrorContainer>
              {`${translate('sidebar.notifications.clearErrorPreamble')}: ${clearRequestError}`}
            </ErrorContainer>
          )}
        </StyledPopoverMenu>
      )}
    </PopoverMenuContainer>
  );
};

const MenuButton = styled.div`
  ${menuItemStyles};
  opacity: 0.85;
  cursor: pointer;
  user-select: none;
`;

const IconsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const UnclearedNotificationsIconContainer = styled.div`
  color: ${textColours.negative};

  svg {
    position: absolute;
    top: -3px;
    left: 12px;
    height: ${spacing8};
    width: ${spacing8};
  }
`;

const StyledPopoverMenu = styled(PopoverMenu)`
  width: ${sidebarWidth * 0.8}px;
  overflow-y: auto;
  max-height: 400px;

  ${onMobile(css`
    max-height: 250px;
  `)};
`;

const ErrorContainer = styled(ErrorMessage)`
  margin: 0;
  padding: ${spacing16};
  text-align: center;
`;

const NoNotificationsContainer = styled.div`
  padding: ${spacing16};
  text-align: center;
`;

type NotificationProps = {
  notification: NotificationResponse;
  clearNotification: (notificationId: number) => void;
  clearRequestInProgress: boolean;
  closeNotificationsMenu: () => void;
  closeSidebar: () => void;
};

const Notification = ({
  notification,
  clearNotification,
  clearRequestInProgress,
  closeNotificationsMenu,
  closeSidebar,
}: NotificationProps) => {
  const navigate = useNavigate();

  const onClick: (() => void) | undefined = useMemo(() => {
    switch (notification.type) {
      case notificationType.Document:
        return () => {
          clearNotification(notification.id);
          navigate({ pathname: '/documents', search: '?documentCategory=0' });
          closeNotificationsMenu();
          closeSidebar();
        };
      default:
        return undefined;
    }
  }, [notification, clearNotification, navigate, closeNotificationsMenu, closeSidebar]);

  const onDismiss = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!clearRequestInProgress) {
      clearNotification(notification.id);
    }
  };

  return (
    <StyledPopoverMenuItem onClick={onClick} withOnClick={onClick != null}>
      <NotificationText>{notification.text}</NotificationText>
      <Dismiss data-testid={clearNotificationButtonTestId(notification.id)} onClick={onDismiss}>
        {clearRequestInProgress ? <Spinner size="medium" /> : <TimesSolidIcon />}
      </Dismiss>
    </StyledPopoverMenuItem>
  );
};

const StyledPopoverMenuItem = styled(PopoverMenuItem)<{ withOnClick: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: ${(props) => (props.withOnClick ? 'pointer' : 'default')};
`;

const NotificationText = styled.div`
  margin-right: ${spacing16};
`;

const Dismiss = styled.div`
  cursor: pointer;
  transition: opacity 0.25s ease;
  display: flex;
  margin-top: 5px;

  &:hover {
    opacity: 0.75;
  }

  svg {
    height: ${spacing12};
    width: ${spacing12};
    margin: 0;
  }
`;
