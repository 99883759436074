import React, { useContext } from 'react';
import { AuthenticationContext } from './AuthenticationContext';
import { userHasRole } from './UserRoles';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { Alert } from '../../infrastructure/interface/components/Alert';

type HasMenuOptionProps = {
  menuOption: string;
  children: (hasMenuOption: boolean) => React.ReactNode;
};

export const HasMenuOption = ({ menuOption, children }: HasMenuOptionProps) => {
  let hasMenuOption = true; //default
  const user = useContext(AuthenticationContext).getUser();

  if (user.permissions.canOnlyViewResourcesMenu) {
    switch (menuOption) {
      case 'holdings':
      case 'transactions':
        hasMenuOption = false;
        break;
      case 'dashboard':
        if (!userHasRole(user, 'Manager')) {
          hasMenuOption = false;
        }
        break;
    }
  }

  return <>{children(hasMenuOption)}</>;
};

type menuOptionsProps = {
  menuOption: string;
  children: React.ReactNode;
};

export const RequiresMenuOption = ({ menuOption, children }: menuOptionsProps) => (
  <HasMenuOption menuOption={menuOption}>
    {(hasMenuOption) =>
      hasMenuOption ? (
        children
      ) : (
        <PaddedPage>
          <InvalidMenuOptionError />
        </PaddedPage>
      )
    }
  </HasMenuOption>
);

const InvalidMenuOptionError = () => {
  const { translate } = useInternationalisation();
  return (
    <Alert alertType="negative" header={translate('errors.generic')}>
      {translate('errors.authentication.invalidUserRole')}
    </Alert>
  );
};
