import { useGetJson } from '../../infrastructure/api/useGetJson';

export const useGetInvestorDetailsCounterpartDropdownOptionsRequest = () =>
  useGetJson<
    GetInvestorDetailsCounterpartDropdownOptionsQuery,
    GetInvestorDetailsCounterpartDropdownOptionsResponse
  >('/api/investors/GetInvestorDetailsCounterpartDropdownOptions');

type GetInvestorDetailsCounterpartDropdownOptionsQuery = {
  selectedCounterpartId: number | null;
  includeBeneficialOwners: boolean;
};

export type GetInvestorDetailsCounterpartDropdownOptionsResponse = {
  options: Array<InvestorDetailsCounterpartDropdownOption>;
};

export type InvestorDetailsCounterpartDropdownOption = {
  counterpartId: number | null;
  label: string;
};
