import React, { ForwardedRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { spacing12, spacing16 } from '../../../styling/design/spacing';
import { fontSizeCss } from '../../../styling/design/fonts';
import { IconOptions } from '../../../icons/icons';
import { backgroundColours, borderColours, textColours } from '../../../styling/design/colours';

export const inputTestId = 'input';
export const inputIconTestId = 'input-icon';

export type InputIconProps = { icon?: IconOptions & { onClick?: () => void; disabled?: boolean } };

type InputProps = InputIconProps & {
  highlight?: boolean;
  disabled?: boolean;
} & Omit<React.HTMLProps<HTMLInputElement>, 'as' | 'disabled' | 'ref'>;

const InputComponent = (props: InputProps, ref?: ForwardedRef<HTMLInputElement>) => {
  const { disabled, ...rest } = props;

  const icon = props.icon?.icon;
  const iconColour = props.icon?.colour;
  const iconDisabled = disabled || props.icon?.disabled;
  const onIconClick = props.icon?.onClick;

  const onIconClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onIconClick && !iconDisabled) {
      onIconClick();
    }
  };

  return (
    <Container>
      <StyledInput
        data-testid={inputTestId}
        {...rest}
        ref={ref}
        disabled={disabled}
        withIcon={icon != null}
      />
      {icon != null && (
        <IconContainer
          data-testid={inputIconTestId}
          onClick={onIconClickHandler}
          colour={iconColour}
          disabled={iconDisabled}
          withOnClick={onIconClick != null}
        >
          {icon}
        </IconContainer>
      )}
    </Container>
  );
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(InputComponent);

const Container = styled.div`
  position: relative;
`;

const iconSize = spacing16;
const iconPadding = spacing16;

export const inputHeightPixels = 45;

export const StyledInput = styled.input<{ highlight?: boolean; withIcon?: boolean }>`
  width: 100%;
  height: ${inputHeightPixels}px;
  outline: none;
  border: solid 1px
    ${(props) => (props.highlight ? borderColours.highlight : borderColours.default)};
  border-radius: 5px;
  padding: ${spacing12} ${spacing16};
  transition: all 0.25s ease;
  ${fontSizeCss('medium')};

  ${(props) =>
    props.withIcon &&
    css`
      padding-right: calc(${iconSize} + (2 * ${iconPadding}));
    `};

  &:focus {
    border-color: ${borderColours.focus};
  }

  &:disabled,
  &:read-only {
    background-color: ${backgroundColours.disabled};
    border-color: ${(props) =>
      props.highlight ? borderColours.highlight : borderColours.disabled};
    color: ${textColours.disabled};
    cursor: not-allowed;
  }

  // Hide the arrows for number inputs
  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }
`;

const IconContainer = styled.div<{ colour?: string; disabled?: boolean; withOnClick?: boolean }>`
  svg {
    position: absolute;
    top: 50%;
    right ${iconPadding};
    transform: translateY(-50%);
    height: ${iconSize};
    width: ${iconSize};
  }

  color: ${(props) =>
    props.disabled ? textColours.disabled : props.colour != null ? props.colour : 'inherit'};

  ${(props) =>
    !props.disabled &&
    props.withOnClick &&
    css`
      &:hover {
        opacity: 0.75;
      }
    `}

  transition: opacity 0.25s ease;

  cursor: ${(props) =>
    props.disabled ? 'not-allowed' : props.withOnClick ? 'pointer' : 'default'};
`;
