import React from 'react';
import { Highcharts } from './Highcharts';
import {
  BaseChartProps,
  Chart,
  ChartDataPoint,
  baseChartOptions,
  mapChartDataPointToPointOptions,
} from './Chart';
import { XAxisOptions, YAxisOptions } from 'highcharts';

export type BaseBarChartProps = BaseChartProps & {
  xAxisOptions?: XAxisOptions;
  yAxisOptions?: YAxisOptions;
};

type Props = BaseBarChartProps & {
  dataPoints: Array<ChartDataPoint>;
};

export const BarChart = (props: Props) => {
  const chartOptions: Highcharts.Options = {
    ...baseBarChartOptions(props),
    xAxis: {
      type: 'linear',
      ...props.xAxisOptions,
    },

    plotOptions: {
      column: {
        groupPadding: 0,
      },
    },
    series: props.dataPoints.map((dataPoint) => ({
      name: dataPoint.label,
      type: 'column',
      data: [mapChartDataPointToPointOptions(dataPoint, props.onClickDataPoint)],
    })),
  };

  return <Chart data-testid={props['data-testid'] ?? 'bar-chart'} options={chartOptions} />;
};

export const baseBarChartOptions = (props: BaseBarChartProps): Highcharts.Options => ({
  ...baseChartOptions(props),
  chart: {
    ...baseChartOptions(props).chart,
    type: 'column',
  },
  xAxis: {
    visible: false,
    ...props.xAxisOptions,
  },
  yAxis: {
    title: { text: '' },
    ...props.yAxisOptions,
  },
});
