import React, { useState } from 'react';
import { InvestorSelect, InvestorSelectProps } from './InvestorSelect';
import { AddressBookIcon } from '../../../icons/icons';
import { colourGrey08 } from '../../../styling/design/colours';
import { InvestorSearchModal } from '../../search/InvestorSearchModal';
import { InputOrSelectOverlaidActionIcon } from '../../../infrastructure/interface/forms/InputOrSelectOverlaidActionIcon';

type Props = Omit<InvestorSelectProps, 'dropdownIcon'>;

export const InvestorSelectWithSearchModal = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSelectCounterpartId = (counterpartId: number) => {
    props.onChange((counterpartId === 0) ? null : counterpartId);
    setIsModalOpen(false);
  };

  return (
    <>
      <InputOrSelectOverlaidActionIcon
        icon={<AddressBookIcon />}
        onClickIcon={() => setIsModalOpen(true)}
        iconColour={colourGrey08}
      >
        <InvestorSelect {...props} dropdownIcon={{ icon: <div /> }} />
      </InputOrSelectOverlaidActionIcon>
      {isModalOpen && (
        <InvestorSearchModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          onSelectCounterpartId={onSelectCounterpartId}
        />
      )}
    </>
  );
};
