import React, { ForwardedRef, useState } from 'react';
import { Validator } from 'fluentvalidation-ts';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useMemo } from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { haveMaxLength, notBeEmpty } from '../../validation/stringValidation';
import { GetUserResponse } from './EditUser';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import {
  getRoleCodeCharacter,
  getRoleFromCodeCharacter,
  UserRoleCodeCharacter,
} from '../../authentication/UserRole';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { notBeNull } from '../../validation/commonValidation';
import { AccessGroupSelectField } from '../../metadata/accessGroup/AccessGroupSelectField';
import { defaultAccessGroupSelectSettings } from '../../metadata/accessGroup/AccessGroupSelect';
import { RequestFailedAlert } from '../../../infrastructure/api/RequestFailedAlert';
import { LockedOutField } from './LockedOutField';
import styled from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';
import { MobileNumberInputField } from '../MobileNumberInputField';
import { usePutJson } from '../../../infrastructure/api/usePutJson';
import { userMobileNumberMaxLength } from '../userValidationConstants';
import { rootAccessGroupId } from '../../metadata/idConstants';

type Props = {
  initialUserDetails: GetUserResponse;
  onEditUserError: (error: string) => void;
  onEditUserSuccess: () => void;
};

const EditAdminUserFormComponent = (
  props: Props,
  forwardedRef: ForwardedRef<FormikProps<EditAdminUserFormModel>>
) => {
  const { translate } = useInternationalisation();
  const [fieldLoadError, setFieldLoadError] = useState<string | null>(null);

  const editAdminUserRequest = usePutJson<EditAdminUserCommand, {}>('/api/users/EditAdminUser');

  const initialFormValues = mapGetUserResponseToFormModel(props.initialUserDetails);

  const validator = useMemo(
    () => new EditAdminUserFormValidator(translate, props.initialUserDetails.roleCodeCharacter),
    [translate, props.initialUserDetails.roleCodeCharacter]
  );

  const onSubmit = (
    formValues: EditAdminUserFormModel,
    formikHelpers: FormikHelpers<EditAdminUserFormModel>
  ) => {
    editAdminUserRequest.makeRequest({
      requestBody: {
        userId: props.initialUserDetails.userId,
        ...formValues,
      },
      onSuccess: () => {
        formikHelpers.setSubmitting(false);
        props.onEditUserSuccess();
      },
      onFailure: (error: string) => {
        formikHelpers.setSubmitting(false);
        props.onEditUserError(error);
      },
    });
  };

  if (fieldLoadError != null) {
    return <RequestFailedAlert error={fieldLoadError} />;
  }

  return (
    <Formik<EditAdminUserFormModel>
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      validate={validator.validate}
      innerRef={forwardedRef}
    >
      {(formikProps) => (
        <Form>
          <FormFieldsContainer>
            <FieldAndValue
              type="text"
              value={props.initialUserDetails.username}
              fieldLabel={translate('pages.editUser.fieldLabels.username')}
            />
            <FieldAndValue
              type="text"
              value={getRoleFromCodeCharacter(props.initialUserDetails.roleCodeCharacter)}
              fieldLabel={translate('pages.editUser.fieldLabels.role')}
            />
            {props.initialUserDetails.roleCodeCharacter === getRoleCodeCharacter('View As') && (
              <AccessGroupSelectField
                settings={{ ...defaultAccessGroupSelectSettings, includeBlank: false }}
                fieldName="viewAsAccessGroupId"
                label={translate('pages.createUser.labels.accessGroup')}
                onError={setFieldLoadError}
              />
            )}
            <InputField
              fieldName="emailAddress"
              label={translate('pages.editUser.fieldLabels.emailAddress')}
            />
            <MobileNumberInputField
              fieldName="mobileNumber"
              fieldLabel={translate('pages.editUser.fieldLabels.mobileNumber')}
              accessGroupId={rootAccessGroupId}
            />
            <LockedOutField fieldName="isLockedOut" />
          </FormFieldsContainer>
        </Form>
      )}
    </Formik>
  );
};

export const EditAdminUserForm = React.forwardRef<FormikProps<EditAdminUserFormModel>, Props>(
  EditAdminUserFormComponent
);

class EditAdminUserFormValidator extends Validator<EditAdminUserFormModel> {
  constructor(translate: TranslateFunction, roleCodeCharacter: UserRoleCodeCharacter) {
    super();

    this.ruleFor('emailAddress')
      .must(notBeEmpty(translate))
      .emailAddress()
      .withMessage(translate('validation.validEmail'));

    this.ruleFor('mobileNumber')
      .must(notBeEmpty(translate))
      .must(haveMaxLength(userMobileNumberMaxLength, translate));

    if (roleCodeCharacter === getRoleCodeCharacter('View As')) {
      this.ruleFor('viewAsAccessGroupId')
        .must(notBeNull(translate))
        .withMessage(translate('pages.createUser.validation.accessGroup'));
    }
  }
}

const mapGetUserResponseToFormModel = (response: GetUserResponse): EditAdminUserFormModel => ({
  emailAddress: response.emailAddress,
  mobileNumber: response.mobileNumber,
  viewAsAccessGroupId:
    response.roleCodeCharacter === getRoleCodeCharacter('Administrator')
      ? null
      : response.accessGroupId,
  isLockedOut: response.isLockedOut,
});

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing16};
`;

export type EditAdminUserFormModel = {
  emailAddress: string;
  mobileNumber: string;
  viewAsAccessGroupId: number | null;
  isLockedOut: boolean;
};

export type EditAdminUserCommand = {
  userId: string;
  isLockedOut: boolean;
  mobileNumber: string;
  emailAddress: string;
  viewAsAccessGroupId: number | null;
};
