import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { ClearingCodeTypeSelectField } from '../../../metadata/bankingDetails/ClearingCodeTypeSelectField';
import { InputField } from '../../../../infrastructure/forms/fields/InputField';
import { onDesktop, onMobile } from '../../../../styling/layout/screenBreakpoints';
import { spacing16 } from '../../../../styling/design/spacing';
import { EmptyFieldLabel, FieldLabel } from '../../../../infrastructure/forms/common/FieldLabel';
import { Input } from '../../../../infrastructure/interface/forms/Input';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { useGetJson } from '../../../../infrastructure/api/useGetJson';
import { FormikValues, useFormikContext } from 'formik';
import { isNullUndefinedOrBlank } from '../../../../helpers/stringHelpers';
import { WaitingButton } from '../../../../infrastructure/interface/buttons/WaitingButton';
import {
  CreateEditBankingDetailsFormModel,
  createEditBankingDetailsFormModelField,
} from './CreateEditBankingDetailsFormFields';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  IconOptions,
  TimesCircleSolidIcon,
} from '../../../../icons/icons';
import { textColours } from '../../../../styling/design/colours';
import {
  ClearingCodeValidationState,
  clearingCodeValidationStateType,
} from './ClearingCodeValidationState';

type Props = {
  codeFieldName: string;
  localClearingDetailsOnly: boolean;
  onLoadError: (error: string) => void;
  codeTypeSelectTestId?: string;
  codeInputTestId?: string;
};

export const ClearingCodeTypeAndValueInputField = ({
  codeFieldName,
  localClearingDetailsOnly,
  onLoadError,
  codeTypeSelectTestId,
  codeInputTestId,
}: Props) => {
  const { translate } = useInternationalisation();

  const [hintText, setHintText] = useState<string>();

  const {
    values: { currencyId, clearingCodeTypeId, clearingCode, clearingCodeValidationState },
    setFieldValue,
  } = useFormikContext<CreateEditBankingDetailsFormModel>();

  const canCheckClearingCode =
    currencyId != null && clearingCodeTypeId != null && !isNullUndefinedOrBlank(clearingCode);

  const checkCodeRequest = useGetJson<
    GetBankClearingCodeSearchResultQuery,
    GetBankClearingCodeSearchResultResponse
  >('/api/banking-details/GetBankClearingCodeSearchResult');

  const clearingCodeTypeDetails = useGetJson<
    GetBankClearingCodeTypeResultQuery,
    GetBankClearingCodeTypeResultResponse
  >('/api/banking-details/GetBankClearingCodeTypeResult');

  const formikContext = useFormikContext<FormikValues>();

  const onClearingCodeInputChange = () => {
    if (formikContext.values[codeFieldName] === formikContext.initialValues[codeFieldName]) {
      //initial load, trigger clearing code check
      onCheckCode();
    }

    checkCodeRequest.resetState();
    setFieldValue(
      createEditBankingDetailsFormModelField.clearingCodeValidationState,
      clearingCodeValidationStateType.checkCodeRequired
    );
  };

  const onCheckCodeSuccess = (response: GetBankClearingCodeSearchResultResponse) => {
    const clearingCodeValid = response.bankId != null;

    // When not local the user can still submit the form with an invalid clearing code,
    // so in that case we don't want to reset the bank details because the user may
    // have already manually entered them.
    // If it is local, we DO want to set / reset the bank details because they have to be
    // based on a valid clearing code and can't be manually entered.
    if (localClearingDetailsOnly || clearingCodeValid) {
      setFieldValue(createEditBankingDetailsFormModelField.bankId, response.bankId);
      setFieldValue(createEditBankingDetailsFormModelField.bankName, response.bankName ?? '');
      setFieldValue(createEditBankingDetailsFormModelField.bankSwiftCode, response.swiftCode ?? '');
    }

    setFieldValue(
      createEditBankingDetailsFormModelField.clearingCodeValidationState,
      clearingCodeValid
        ? clearingCodeValidationStateType.valid
        : clearingCodeValidationStateType.invalid
    );
  };

  const onCheckCode = () => {
    if (canCheckClearingCode) {
      checkCodeRequest.makeRequest({
        queryParameters: { currencyId, clearingCodeTypeId, clearingCode },
        onSuccess: onCheckCodeSuccess,
        onFailure: onLoadError,
      });
    }
  };

  const clearingCodeTypeDetailsSuccess = (response: GetBankClearingCodeTypeResultResponse) => {
    const hintDescription = response.hintDescription;
    if (hintDescription != null) {
      //set hint
      setHintText(hintDescription);
    } else {
      //clear hint
      setHintText(undefined);
    }
  };

  const onClearingCodeTypeChange = () => {
    if (clearingCodeTypeId != null && clearingCodeTypeId > 0) {
      clearingCodeTypeDetails.makeRequest({
        queryParameters: { clearingCodeTypeId },
        onSuccess: clearingCodeTypeDetailsSuccess,
        onFailure: onLoadError,
      });
    } else {
      //clear hint
      setHintText(undefined);
    }
  };

  const showSuccess = clearingCodeValidationState === 'valid';
  const showWarning = !localClearingDetailsOnly && clearingCodeValidationState === 'invalid';
  const showError = localClearingDetailsOnly && clearingCodeValidationState === 'invalid';

  const getStatusIconOptions = (): IconOptions => {
    const dataTestId = clearingCodeValidityTestId(clearingCodeValidationState);

    return {
      icon: showSuccess ? (
        <CheckCircleIcon data-testid={dataTestId} />
      ) : showWarning ? (
        <ExclamationCircleIcon data-testid={dataTestId} />
      ) : (
        <TimesCircleSolidIcon data-testid={dataTestId} />
      ),
      colour: showSuccess
        ? textColours.positive
        : showWarning
        ? textColours.warning
        : showError
        ? textColours.negative
        : 'transparent',
    };
  };

  return (
    <>
      <ClearingCodeTypeFieldsContainer>
        <StyledClearingCodeTypeSelectField
          data-testid={codeTypeSelectTestId ?? clearingCodeInputFieldTypeSelectTestId}
          label={translate('pages.bankingDetails.createEdit.labels.clearingCodeType')}
          fieldName={createEditBankingDetailsFormModelField.clearingCodeTypeId}
          onError={onLoadError}
          settings={{ currencyId, includeBlank: false }}
          clearable={true}
          onFieldValueChange={onClearingCodeTypeChange}
        />
        <BranchFieldContainer>
          <FieldLabel>{translate('pages.bankingDetails.createEdit.labels.branch')}</FieldLabel>
          <Input
            data-testid={branchInputTestId}
            value={checkCodeRequest.state.response?.branchName ?? ''}
            readOnly={true}
            tabIndex={-1}
          />
        </BranchFieldContainer>
      </ClearingCodeTypeFieldsContainer>
      <ClearingCodeFieldsContainer>
        <ClearingCodeInputField
          data-testid={codeInputTestId ?? clearingCodeInputFieldCodeInputTestId}
          label={translate('pages.bankingDetails.createEdit.labels.clearingCode')}
          fieldName={codeFieldName}
          onFieldValueChange={onClearingCodeInputChange}
          icon={getStatusIconOptions()}
          warningText={
            showWarning
              ? translate('pages.bankingDetails.createEdit.validation.clearingCodeInvalid.nonLocal')
              : undefined
          }
          helpText={hintText}
        />
        <CheckCodeButtonContainer>
          <EmptyFieldLabel />
          <WaitingButton
            stretch={true}
            buttonStyle="secondary"
            isWaiting={checkCodeRequest.state.inProgress}
            waitingText={translate(
              'pages.bankingDetails.createEdit.clearingCode.checkCodeButton.waitingText'
            )}
            onClick={onCheckCode}
            disabled={!canCheckClearingCode}
          >
            {translate('pages.bankingDetails.createEdit.clearingCode.checkCodeButton.text')}
          </WaitingButton>
        </CheckCodeButtonContainer>
      </ClearingCodeFieldsContainer>
    </>
  );
};

const ClearingCodeTypeFieldsContainer = styled.div`
  display: flex;

  ${onDesktop(css`
    flex-direction: row;
    justify-content: space-between;

    > * {
      flex-grow: 1;
      flex-basis: 0;
    }
  `)}

  ${onMobile(css`
    flex-direction: column;
  `)}
`;

const StyledClearingCodeTypeSelectField = styled(ClearingCodeTypeSelectField)`
  ${onDesktop(css`
    max-width: 200px;
  `)};
`;

const BranchFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing16};
  height: fit-content;

  ${onDesktop(css`
    margin-left: ${spacing16};
  `)}
`;

const ClearingCodeFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ClearingCodeInputField = styled(InputField)`
  flex-grow: 1;
` as typeof InputField;

const CheckCodeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing16};
  height: fit-content;
  max-width: 200px;
  margin-left: ${spacing16};
`;

type GetBankClearingCodeSearchResultQuery = {
  currencyId: number;
  clearingCodeTypeId: number;
  clearingCode: string;
};

export type GetBankClearingCodeSearchResultResponse = {
  clearingCode: string;
  bankId: number | null;
  branchName: string | null;
  bankName: string | null;
  swiftCode: string | null;
};

type GetBankClearingCodeTypeResultQuery = {
  clearingCodeTypeId: number | null;
};

export type GetBankClearingCodeTypeResultResponse = {
  clearingCodeTypeId: number | null;
  clearingCodeType: string | null;
  clearingCodeDescription: string | null;
  codeMask: string | null;
  countryId: number | null;
  hintDescription: string | null;
};

const clearingCodeInputFieldTypeSelectTestId = 'clearing-code-input-field-type-select';
export const branchInputTestId = 'branch-input';
const clearingCodeInputFieldCodeInputTestId = 'clearing-code-input-field-code-input';
export const clearingCodeValidityTestId = (state: ClearingCodeValidationState) =>
  `clearing-code-validity-${state}`;
