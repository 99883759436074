import React, { useEffect, useMemo, useState } from 'react';
import { find, sortBy } from 'lodash';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GroupedSelectOptions,
  SelectOption,
  SelectOptions,
} from '../../infrastructure/interface/forms/BaseSelect';
import { SingleSelect } from '../../infrastructure/interface/forms/SingleSelect';
import { spacing16 } from '../../styling/design/spacing';
import { ApiRequestStateWrapper } from '../../infrastructure/api/ApiRequestStateWrapper';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { Report } from './Report';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { RequiresUserRole } from '../authentication/UserRoles';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { ReportsPlaceholder } from './ReportsPlaceholder';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { ReportContextProvider } from './ReportContext';

export const Reports = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.reports.title'));

  return (
    <RequiresUserRole userRole={allNonAdminUserRoles}>
      <DesktopOnly>
        <ReportsApiRequestWrapper />
      </DesktopOnly>
      <MobileOnly>
        <MobileAndTabletReports />
      </MobileOnly>
    </RequiresUserRole>
  );
};

const MobileAndTabletReports = () => {
  const { translate } = useInternationalisation();

  return (
    <PaddedPage>
      <Header1>{translate('pages.reports.header')}</Header1>
      <Alert alertType="notice" header={translate('pages.reports.mobileMessage.header')}>
        {translate('pages.reports.mobileMessage.text')}
      </Alert>
    </PaddedPage>
  );
};

const ReportsApiRequestWrapper = () => {
  const getReportsRequest = useGetJson<undefined, GetReportsResponse>(
    '/api/reports/GetReportsForCurrentUser'
  );
  useOnMount(() => getReportsRequest.makeRequest());

  return (
    <ApiRequestStateWrapper<GetReportsResponse>
      apiRequestState={getReportsRequest.state}
      retry={() => getReportsRequest.makeRequest()}
    >
      {(response) => <ReportsComponent getReportsResponse={response} />}
    </ApiRequestStateWrapper>
  );
};

type ReportsComponentProps = {
  getReportsResponse: GetReportsResponse;
};

const ReportsComponent = ({ getReportsResponse }: ReportsComponentProps) => {
  const { translate } = useInternationalisation();

  const { reportId } = useParams<ReportsUrlParams>();
  const navigate = useNavigate();

  const [report, setReport] = useState<ReportResponse | null>(() =>
    getReportFromIdUrlParam(reportId, getReportsResponse)
  );

  useEffect(() => {
    setReport(getReportFromIdUrlParam(reportId, getReportsResponse));
  }, [reportId, getReportsResponse]);

  const reportOptions = useMemo(
    () => getGroupedReportSelectOptions(getReportsResponse),
    [getReportsResponse]
  );

  const onChange = (newReport: ReportResponse | null) => {
    if (!newReport) {
      navigate('/reports');
    } else {
      navigate(`/reports/${newReport.id}`);
    }
  };

  return (
    <PaddedPage>
      <HeaderContainer>
        <Header1>{translate('pages.reports.header')}</Header1>
        <ReportSelect
          options={reportOptions}
          onChange={onChange}
          value={report}
          placeholder={translate('pages.reports.reportSelectPlaceholder')}
        />
      </HeaderContainer>
      <ReportContextProvider>
        {report != null ? <Report report={report} /> : <ReportsPlaceholder />}
      </ReportContextProvider>
    </PaddedPage>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ReportSelect = styled(SingleSelect)`
  margin-left: ${spacing16};
  margin-bottom: ${spacing16};
  max-width: 350px;
` as typeof SingleSelect;

export type GetReportsResponse = {
  reports: Array<ReportResponse>;
  groups: Array<ReportGroupResponse>;
};

export type ReportResponse = {
  id: number;
  name: string;
  displayName: string;
  filename: string | null;
  isUserDefined: boolean;
  groupId: number;
  includesCharts: boolean;
};

export type ReportGroupResponse = {
  id: number;
  displayName: string;
};

type ReportsUrlParams = 'reportId';

const getReportFromIdUrlParam = (
  reportIdUrlParam: string | undefined,
  getReportsResponse: GetReportsResponse
): ReportResponse | null =>
  find(getReportsResponse.reports, (report) => report.id === Number(reportIdUrlParam)) ?? null;

const getGroupedReportSelectOptions = ({
  reports,
  groups,
}: GetReportsResponse): GroupedSelectOptions<ReportResponse> =>
  groups
    .map((group) => {
      const groupSelectOptions: SelectOptions<ReportResponse> = reports
        .filter((report) => report.groupId === group.id)
        .map(reportResponseToSelectOption);

      return {
        label: group.displayName,
        options: sortBy(groupSelectOptions, (option) => option.label.toLowerCase()),
      };
    })
    .filter((groupedSelectOption) => groupedSelectOption.options.length > 0);

const reportResponseToSelectOption = (
  reportResponse: ReportResponse
): SelectOption<ReportResponse> => ({
  value: reportResponse,
  label: reportResponse.displayName,
});
