import React from 'react';
import {
  CompanyReportGroupSelect,
  CompanyReportGroupSelectOptionValue,
} from './CompanyReportGroupSelect';
import { CompanyReportGroupSelectSettings } from './CompanyReportGroupSelectSettings';
import { GetCompanyReportGroupDropdownOptionsResponse } from './GetCompanyReportGroupDropdownOptionsResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  CompanyReportGroupSelectOptionValue,
  CompanyReportGroupSelectSettings,
  GetCompanyReportGroupDropdownOptionsResponse
>;

export const CompanyReportGroupSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    CompanyReportGroupSelectOptionValue,
    CompanyReportGroupSelectSettings,
    GetCompanyReportGroupDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <CompanyReportGroupSelect {...metadataSingleSelectProps} />
    )}
  />
);
