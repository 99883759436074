import React from 'react';
import { Highcharts } from './Highcharts';
import { Chart, ChartDataPoint, mapChartDataPointToPointOptions } from './Chart';
import { baseBarChartOptions, BaseBarChartProps } from './BarChart';

export type GroupedBarChartDataPoint = {
  label: string;
  dataPoints: Array<ChartDataPoint>;
};

type Props = BaseBarChartProps & {
  dataPoints: Array<GroupedBarChartDataPoint>;
  groupCategories?: Array<string>;
};

export const GroupedBarChart = (props: Props) => {
  const chartOptions: Highcharts.Options = {
    ...baseBarChartOptions(props),
    xAxis: {
      visible: props.groupCategories != null,
      categories: props.groupCategories,
      ...props.xAxisOptions,
    },
    tooltip: {
      shared: true,
      ...props.tooltipOptions,
    },
    series: props.dataPoints.map((groupedDataPoint) => ({
      name: groupedDataPoint.label,
      type: 'column',
      data: groupedDataPoint.dataPoints.map((dataPoint) =>
        mapChartDataPointToPointOptions(dataPoint, props.onClickDataPoint)
      ),
    })),
  };

  return <Chart data-testid={props['data-testid'] ?? 'grouped-bar-chart'} options={chartOptions} />;
};
