import React, { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from './AuthenticationContext';
import { Modal, ModalButtonRow } from '../../infrastructure/interface/components/Modal';
import { noop } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import { useStartupData } from '../../startup/StartupDataContext';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { PrimaryButton } from '../../infrastructure/interface/buttons/PrimaryButton';
import { SecondaryButton } from '../../infrastructure/interface/buttons/SecondaryButton';
import { usePostJson } from '../../infrastructure/api/usePostJson';
import { useSessionTimeoutState } from './useSessionTimeoutState';

export const AutoLogoutModal = () => {
  const { sessionTimeoutSettings } = useStartupData();

  const { isInSessionTimeoutWarningPeriod, sessionHasExpired, totalSecondsUntilSessionExpires } =
    useSessionTimeoutState();

  const authenticationContext = useContext(AuthenticationContext);

  const { translate } = useInternationalisation();
  const navigate = useNavigate();

  const [shouldShowWarning, setShouldShowWarning] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const logOut = (options: { dueToSessionExpiry: boolean }) => {
    setShouldShowWarning(false);
    setIsLoggingOut(true);
    authenticationContext.logOut({
      onSuccess: () =>
        navigate({
          pathname: '/login',
          search: options.dueToSessionExpiry ? '?sessionExpired=true' : undefined,
        }),
      onFailure: () => setIsLoggingOut(false),
    });
  };

  useEffect(() => {
    if (sessionHasExpired) {
      logOut({ dueToSessionExpiry: true });
    } else if (isInSessionTimeoutWarningPeriod) {
      setShouldShowWarning(true);
    }
  }, [sessionHasExpired, isInSessionTimeoutWarningPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  const minutesUntilSessionExpires = Math.floor(totalSecondsUntilSessionExpires / 60);
  const secondsUntilSessionExpires = totalSecondsUntilSessionExpires % 60;

  const showModal =
    authenticationContext.isAuthenticated() &&
    shouldShowWarning &&
    totalSecondsUntilSessionExpires >= 0;

  const timeoutWarningMessage = sessionTimeoutSettings.sessionTimeoutWarningMessage
    .replace(
      '[COUNTDOWN_MINUTES]',
      translate('autoLogoutModal.minutesText', { minutes: minutesUntilSessionExpires })
    )
    .replace(
      '[COUNTDOWN_SECONDS]',
      translate('autoLogoutModal.secondsText', { seconds: secondsUntilSessionExpires })
    );

  const keepSessionAliveRequest = usePostJson('/api/authentication/KeepSessionAlive');

  const keepSessionAlive = () => {
    keepSessionAliveRequest.makeRequest({ requestBody: {} });
    setShouldShowWarning(false);
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={noop} // Prevent the modal being closed
    >
      <div data-testid={autoLogoutModalTestId}>
        {isLoggingOut ? (
          <CentredSpinner size="xlarge" />
        ) : (
          <>
            <p>{timeoutWarningMessage}</p>
            <p>{translate('autoLogoutModal.question')}</p>
            <ModalButtonRow>
              <SecondaryButton onClick={() => logOut({ dueToSessionExpiry: false })}>
                {translate('autoLogoutModal.cancelButtonText')}
              </SecondaryButton>
              <PrimaryButton onClick={keepSessionAlive}>
                {translate('autoLogoutModal.confirmButtonText')}
              </PrimaryButton>
            </ModalButtonRow>
          </>
        )}
      </div>
    </Modal>
  );
};

export const autoLogoutModalTestId = 'auto-logout-modal';
