import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { BankingProductCode } from './BankingProduct';
import { BankingProductsSelectOptionValue } from './BankingProductsSelect';

export type GetBankingProductsDropdownOptionsResponse = {
  options: Array<BankingProductsDropdownOptionResponse>;
};

export type BankingProductsDropdownOptionResponse = {
  value: BankingProductCode;
  text: string;
};

export const mapBankingProductsDropdownOptionsResponseToSelectOptions = (
  response: GetBankingProductsDropdownOptionsResponse | null
): SelectOptions<BankingProductsSelectOptionValue> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.value,
        label: option.text,
      }));
