import React from 'react';
import { MetadataSingleSelectComponentProps } from '../../MetadataSelectProps';
import { GeneralLedgerBankSelectSettings } from './GeneralLedgerBankSelectSettings';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import {
  cannotMapSettingsToValidQueryParameters,
  MetadataSingleSelect,
} from '../../MetadataSingleSelect';
import {
  GetGeneralLedgerBankDropdownOptionsResponse,
  mapGeneralLedgerBankDropdownOptionsResponseToSelectOptions,
} from './GetGeneralLedgerBankDropdownOptionsResponse';

export type GeneralLedgerBankSelectOptionValue = number | null;

type Props = MetadataSingleSelectComponentProps<
  GeneralLedgerBankSelectOptionValue,
  GeneralLedgerBankSelectSettings,
  GetGeneralLedgerBankDropdownOptionsResponse
>;

export const GeneralLedgerBankSelect = (props: Props) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('form.select.defaultPlaceholder');
  const loadingPlaceholder = translate('form.defaultLoadingPlaceholder');

  return (
    <MetadataSingleSelect<
      GeneralLedgerBankSelectOptionValue,
      GeneralLedgerBankSelectSettings,
      GetGeneralLedgerBankDropdownOptionsQuery,
      GetGeneralLedgerBankDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetGeneralLedgerBankDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={({ companyId, companyReportGroupId, ...otherSettings }) =>
        companyId != null
          ? {
              companyId,
              companyReportGroupId: null,
              ...otherSettings,
            }
          : companyReportGroupId != null
          ? {
              companyId: null,
              companyReportGroupId,
              ...otherSettings,
            }
          : cannotMapSettingsToValidQueryParameters
      }
      mapResponseToOptions={mapGeneralLedgerBankDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={250}
      {...metadataSingleSelectProps}
    />
  );
};

type GetGeneralLedgerBankDropdownOptionsQuery = (
  | { companyId: number; companyReportGroupId: null }
  | { companyId: null; companyReportGroupId: number }
) & {
  includeAll: boolean;
  includeBlank: boolean;
};
