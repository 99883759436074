import React from 'react';
import { ApiRequestState } from './ApiRequestState';
import { ActionAlert } from '../interface/components/ActionAlert';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';

type Props<TResponse> = {
  isOpen: boolean;
  apiRequestState: ApiRequestState<TResponse>;
  onRequestClose: () => void;
  retry: () => void;
};

export const ApiRequestErrorModal = <TResponse extends unknown>({
  isOpen,
  apiRequestState,
  onRequestClose,
  retry,
}: Props<TResponse>) => {
  const { translate } = useInternationalisation();

  return (
    <ActionAlert
      alertType="negative"
      isOpen={isOpen}
      title={translate('errors.apology')}
      message={apiRequestState.error}
      cancelButtonText={translate('actionButtons.cancel')}
      confirmButtonText={translate('errors.tryAgain')}
      onRequestClose={onRequestClose}
      onInitiateAction={retry}
      actionInProgress={apiRequestState.inProgress}
      actionError={null}
    />
  );
};
