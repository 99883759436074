import { UserDetails } from '../../UserDetails';
import { NotificationResponse } from '../../../../styling/layout/sidebar/notifications/NotificationResponse';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { EnterMultiFactorAuthenticationCodeFormModel } from '../EnterMultiFactorAuthenticationCodeForm';
import { FormikHelpers } from 'formik';

/**
 * Submits the MFA token entered by the user as a POST request to the API to sign them in.
 * @param onSignedIn callback function called when a success response is received from the API.
 */
export const useMfaTokenSignInApiRequest = (
  onSignedIn: (userDetails: UserDetails, notifications: Array<NotificationResponse>) => void
) => {
  const request = usePostJson<SignInWithMfaTokenCommand, SignInWithMfaTokenResponse>(
    '/api/authentication/SignInWithMultiFactorAuthenticationToken'
  );

  const onSubmitMfaToken = (
    formModel: EnterMultiFactorAuthenticationCodeFormModel,
    formikHelpers: FormikHelpers<EnterMultiFactorAuthenticationCodeFormModel>
  ) => {
    request.makeRequest({
      requestBody: getSignInWithMultiFactorAuthenticationCommand(formModel),
      onSuccess: (response) => {
        onSignedIn(response.user, response.notificationsSinceLastLogin);
      },
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  return {
    onSubmitMfaToken,
    mfaRequestError: request.state.error,
  };
};

export type SignInWithMfaTokenCommand = {
  multiFactorAuthenticationToken: string;
};

const getSignInWithMultiFactorAuthenticationCommand = (
  formModel: EnterMultiFactorAuthenticationCodeFormModel
): SignInWithMfaTokenCommand => ({
  multiFactorAuthenticationToken: formModel.multiFactorAuthenticationToken,
});

export type SignInWithMfaTokenResponse = {
  user: UserDetails;
  notificationsSinceLastLogin: Array<NotificationResponse>;
};
