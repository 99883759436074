import React from 'react';
import {
  TransactionTypeSelectOptionValue,
  TransactionTypeSelectSettings,
} from './TransactionTypeSelectBase';
import { TransactionTypeSelect } from './TransactionTypeSelect';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';
import { GetTransactionTypeDropdownOptionsResponse } from './GetTransactionTypeDropdownOptionsResponse';

type Props = MetadataSingleSelectFieldComponentProps<
  TransactionTypeSelectOptionValue,
  TransactionTypeSelectSettings,
  GetTransactionTypeDropdownOptionsResponse
>;

export const TransactionTypeSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    TransactionTypeSelectOptionValue,
    TransactionTypeSelectSettings,
    GetTransactionTypeDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <TransactionTypeSelect {...metadataSingleSelectProps} />
    )}
  />
);
