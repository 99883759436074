import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { AlertType, getAlertBorderColour, getAlertTextColour } from './Alert';
import { BaseButton } from '../buttons/BaseButton';
import { spacing16, spacing8 } from '../../../styling/design/spacing';
import { DesktopModalSize, Modal, ModalButtonRow, ModalHeader } from './Modal';
import { Header3 } from './Headers';

type Props = {
  alertType: AlertType;
  isOpen: boolean;
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  closeButtonText: string;
  onRequestClose: () => void;
  desktopSize?: DesktopModalSize;
  closeButtonTestId?: string;
  children?: ReactNode;
};

export const AlertModal = (props: Props) => {
  const {
    alertType,
    isOpen,
    title,
    message,
    closeButtonText,
    onRequestClose,
    desktopSize,
    closeButtonTestId,
  } = props;

  return (
    <StyledModal
      alertType={alertType}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      desktopSize={desktopSize}
    >
      <StyledModalHeader alertType={alertType} title={title} withCloseButton={false} />
      <Message>{message}</Message>
      <ModalButtonRow>
        <BaseButton
          data-testid={closeButtonTestId}
          buttonStyle={alertType}
          onClick={onRequestClose}
          disabled={false}
        >
          {closeButtonText}
        </BaseButton>
      </ModalButtonRow>
      {props.children}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)<{ alertType: AlertType }>`
  border-left-style: solid;
  border-left-width: ${spacing8};
  border-left-color: ${(props) => getAlertBorderColour(props.alertType)};
`;

const StyledModalHeader = styled(ModalHeader)<{ alertType: AlertType }>`
  margin-bottom: ${spacing16};

  ${Header3} {
    color: ${(props) => getAlertTextColour(props.alertType)};
  }
`;

const Message = styled.div``;
