import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { AuthenticationResult } from '../AuthenticationResult';

export const useInitiateMfaFlowForLoggedInUserRequest = () =>
  usePostJson<{}, InitiateMfaFlowForLoggedInUserResponse>(
    '/api/authentication/InitiateMfaFlowForLoggedInUser'
  );

export type InitiateMfaFlowForLoggedInUserResponse = {
  authenticationResult: AuthenticationResult;
};
