import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { BeneficialOwnersContextProvider } from './BeneficialOwnersContext';
import { DesktopBeneficialOwnersList } from './DesktopBeneficialOwnersList';
import { MobileBeneficialOwnersList } from './MobileBeneficialOwnersList';

export const BeneficialOwners = () => (
  <BeneficialOwnersContextProvider>
    <DesktopOnly>
      <DesktopBeneficialOwnersList />
    </DesktopOnly>
    <MobileOnly>
      <MobileBeneficialOwnersList />
    </MobileOnly>
  </BeneficialOwnersContextProvider>
);
