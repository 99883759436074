import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { AvailableCurrency, Investment, TotalInvestment } from './types';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { textColours } from '../../styling/design/colours';
import { spacing16, spacing32, spacing8 } from '../../styling/design/spacing';
import { fontSizeCss, fontWeightBold } from '../../styling/design/fonts';
import { find, isEmpty } from 'lodash';
import { MiniSelect } from '../../infrastructure/interface/forms/MiniSelect';
import { HoldingsContext } from './HoldingsContext';
import { ItemDetailsCard } from '../../infrastructure/interface/components/ItemDetailsCard';
import { FieldAndValue } from '../../infrastructure/interface/components/FieldAndValue';
import {
  findCurrencyIdByCode,
  getAvailableCurrencies,
  getDefaultTotalInvestment,
  getAvailableCurrencySelectOptions,
  hideTotalInvestments,
} from './holdingsHelper';

type TotalInvestmentComponentProps = {
  defaultValue: TotalInvestment;
  totalInvestments: Array<TotalInvestment>;
  availableCurrencies: Array<AvailableCurrency>;
};
type InvestmentsComponentProps = {
  investments: Array<Investment>;
};

export const MobileSummary = () => {
  const { latestResponse } = useContext(HoldingsContext);
  if (!latestResponse || isEmpty(latestResponse.totalInvestments)) {
    return null;
  }

  const availableCurrencies = getAvailableCurrencies(
    latestResponse.availableCurrencies,
    latestResponse.totalInvestments
  );
  const defaultTotalInvestment = getDefaultTotalInvestment(
    latestResponse.totalInvestments,
    latestResponse.availableCurrencies
  );

  return (
    <MobileSummaryWrapper>
      <ItemDetailsCard>
        <TotalInvestmentComponent
          defaultValue={defaultTotalInvestment}
          totalInvestments={latestResponse.totalInvestments}
          availableCurrencies={availableCurrencies}
        />
        {!hideTotalInvestments(latestResponse.totalInvestments, latestResponse.investments) && (
          <InvestmentsComponent investments={latestResponse.investments} />
        )}
      </ItemDetailsCard>
    </MobileSummaryWrapper>
  );
};

const TotalInvestmentComponent = ({
  defaultValue,
  totalInvestments,
  availableCurrencies,
}: TotalInvestmentComponentProps) => {
  const { formatNumber, translate } = useInternationalisation();
  const [totalInvestment, setTotalInvestment] = useState<TotalInvestment>(defaultValue);

  const selectOptions = useMemo(
    () => getAvailableCurrencySelectOptions(availableCurrencies),
    [availableCurrencies]
  );
  const selectedCurrencyId = useMemo(
    () => findCurrencyIdByCode(totalInvestment.currency, availableCurrencies),
    [totalInvestment, availableCurrencies]
  );

  useEffect(() => {
    setTotalInvestment(defaultValue);
  }, [defaultValue]);

  const onChange = (newCurrencyId: number | null) => {
    const newCurrency = find(
      availableCurrencies,
      (availableCurrency) => availableCurrency.currencyId === newCurrencyId
    );
    const newTotalInvestment = find(
      totalInvestments,
      (totalInvestment) => totalInvestment.currency === newCurrency?.currencyCode
    );
    if (newCurrency && newTotalInvestment) {
      setTotalInvestment(newTotalInvestment);
    }
  };

  const renderCurrencySelect = () => {
    if (isEmpty(availableCurrencies) || !selectedCurrencyId) {
      return null;
    }

    if (availableCurrencies.length === 1) {
      return <LabelAndCurrency>{availableCurrencies[0].currencyCode}</LabelAndCurrency>;
    }

    return (
      <MiniSelect<number> options={selectOptions} value={selectedCurrencyId} onChange={onChange} />
    );
  };

  return (
    <>
      <LabelAndCurrencyMobile>
        <span>{translate('pages.holdings.cards.totalInvestments.label')}</span>
        {renderCurrencySelect()}
      </LabelAndCurrencyMobile>
      <TotalInvestmentValueMobile>
        {formatNumber(totalInvestment.total, { decimalPlaces: totalInvestment.decimalPlaces })}
      </TotalInvestmentValueMobile>
    </>
  );
};

const InvestmentsComponent = ({ investments }: InvestmentsComponentProps) => {
  const { formatNumber, translate } = useInternationalisation();
  return (
    <>
      {investments &&
        investments.map((investment, index) => (
          <FieldAndValue
            key={index}
            type="custom"
            fieldLabel={`${translate('pages.holdings.cards.investments.label')} ${
              investment.currency
            }`}
            value={formatNumber(investment.total, { decimalPlaces: investment.decimalPlaces })}
          />
        ))}
    </>
  );
};

const MobileSummaryWrapper = styled.div`
  margin-top: ${spacing16};
  margin-bottom: ${spacing32};
`;

const LabelAndCurrency = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LabelAndCurrencyMobile = styled(LabelAndCurrency)`
  margin-top: ${spacing8};
  ${fontSizeCss('small')};
`;

const TotalInvestmentValueMobile = styled.div`
  color: ${textColours.notice};
  ${fontSizeCss('xlarge')};
  font-weight: ${fontWeightBold};
  margin-top: ${spacing16};
  margin-bottom: ${spacing16};
`;
