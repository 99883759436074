import styled from 'styled-components/macro';
import { fontSizeCss } from '../../../styling/design/fonts';
import { spacing4 } from '../../../styling/design/spacing';

export const FieldLabel = styled.label`
  display: block;
  margin-bottom: ${spacing4};
  ${fontSizeCss('small')};
`;

// Can be used to line up an inline field without a label with other fields that
// do have a label.
export const EmptyFieldLabel = () => <FieldLabel>&nbsp;</FieldLabel>;
