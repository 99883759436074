import React, { useContext } from 'react';
import { AuthenticationContext } from './features/authentication/AuthenticationContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './features/authentication/login/Login';
import { RequestPasswordReset } from './features/authentication/RequestPasswordReset';
import { ResetPassword } from './features/authentication/ResetPassword';
import { AuthenticatedLayout } from './styling/layout/AuthenticatedLayout';
import { Holdings } from './features/holdings/Holdings';
import { NotFound } from './features/NotFound';
import { UnauthenticatedLayout } from './styling/layout/UnauthenticatedLayout';
import { EmailSettings } from './features/emailSettings/EmailSettings';
import { Reports } from './features/reports/Reports';
import { Documents } from './features/documents/Documents';
import { UploadDocument } from './features/documents/UploadDocument';
import { Users } from './features/users/Users';
import { CreateUser } from './features/users/create/CreateUser';
import { CustomStyles } from './styling/branding/CustomStyles';
import { ImpersonateUser } from './features/impersonation/ImpersonateUser';
import { AcceptTermsAndConditions } from './features/termsAndConditions/AcceptTermsAndConditions';
import { investorIdUrlParamName } from './features/investorDetails/useInvestorIdUrlParam';
import {
  DistributionReinvestments,
  DistributionReinvestmentsLayout,
} from './features/investorDetails/distributionReinvestments/DistributionReinvestments';
import { ContactUs } from './features/contactUs/ContactUs';
import { ViewTermsAndConditions } from './features/termsAndConditions/ViewTermsAndConditions';
import { EditUser, userIdUrlParamName } from './features/users/edit/EditUser';
import { CreateDistributionReinvestment } from './features/investorDetails/distributionReinvestments/createEdit/CreateDistributionReinvestment';
import { distributionReinvestmentIdUrlParamName } from './features/investorDetails/distributionReinvestments/createEdit/useDistributionReinvestmentIdUrlParam';
import { EditDistributionReinvestment } from './features/investorDetails/distributionReinvestments/createEdit/EditDistributionReinvestment';
import { CentredPaddedPage } from './styling/layout/PaddedPage';
import { getReactRouterBasename } from './configuration/routeConfig';
import { useSiteContext } from './features/multiTenancy/SiteContext';
import { BankingDetailsRoutes } from './features/investorDetails/bankingDetails/BankingDetails.routes';
import { TransactionRoutes } from './features/transactions/Transaction.routes';
import { DashboardRoutes } from './features/dashboards/Dashboard.routes';
import { InvestorDetailsRoutes } from './features/investorDetails/InvestorDetails.routes';

export const UnauthenticatedRoutes = () => {
  const { siteId } = useSiteContext();
  const urlIncludingSiteIdQueryParam =
    siteId === null || siteId === 1 ? '/login' : `/login?SiteId=${siteId}`;
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<RequestPasswordReset />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="*" element={<Navigate replace={true} to={urlIncludingSiteIdQueryParam} />} />
    </Routes>
  );
};

export const DistributionReinvestmentRoutes = () => [
  <Route path="/distribution-reinvestments" element={<DistributionReinvestmentsLayout />}>
    <Route index element={<DistributionReinvestments />} />
    <Route path={`:${investorIdUrlParamName}`} element={<DistributionReinvestments />} />
  </Route>,
  <Route path="/distribution-reinvestments/create" element={<CreateDistributionReinvestment />} />,
  <Route
    path={`/distribution-reinvestments/:${investorIdUrlParamName}/create`}
    element={<CreateDistributionReinvestment />}
  />,
  <Route
    path={`/distribution-reinvestments/:${distributionReinvestmentIdUrlParamName}/edit`}
    element={<EditDistributionReinvestment />}
  />,
  <Route
    path={`/distribution-reinvestments/:${investorIdUrlParamName}/:${distributionReinvestmentIdUrlParamName}/edit`}
    element={<EditDistributionReinvestment />}
  />,
];

export const AppRoutes = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const isAuthenticated = authenticationContext.isAuthenticated();

  return (
    <BrowserRouter basename={getReactRouterBasename()}>
      <CustomStyles>
        {!isAuthenticated ? (
          <UnauthenticatedLayout>
            <UnauthenticatedRoutes />
          </UnauthenticatedLayout>
        ) : (
          <AcceptTermsAndConditions>
            <AuthenticatedLayout>
              <Routes>
                <Route path="/" element={<RedirectToHomepage />} />
                <Route path="/login" element={<RedirectToHomepage />} />
                <Route path="/impersonate-user" element={<ImpersonateUser />} />
                <Route path="/users" element={<Users />} />
                <Route
                  path="/forgot-password"
                  element={
                    <CentredPaddedPage>
                      <RequestPasswordReset />
                    </CentredPaddedPage>
                  }
                />
                <Route path={`/users/:${userIdUrlParamName}/edit`} element={<EditUser />} />
                <Route path="/users/new" element={<CreateUser />} />
                {DashboardRoutes()}
                <Route path="/holdings" element={<Holdings />} />
                {TransactionRoutes()}
                <Route path="/reports">
                  <Route index element={<Reports />} />
                  <Route path=":reportId" element={<Reports />} />
                </Route>
                <Route path="/email-settings" element={<EmailSettings />} />
                {InvestorDetailsRoutes()}
                <Route path="/documents" element={<Documents />} />
                <Route path="/documents/upload" element={<UploadDocument />} />
                {BankingDetailsRoutes()}
                {DistributionReinvestmentRoutes()}
                <Route path="/terms-and-conditions" element={<ViewTermsAndConditions />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AuthenticatedLayout>
          </AcceptTermsAndConditions>
        )}
      </CustomStyles>
    </BrowserRouter>
  );
};

const RedirectToHomepage = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const user = authenticationContext.getUser();

  if (user.role === 'Administrator') {
    return <Navigate to="/users" />;
  }

  if (user.role === 'View As') {
    return <Navigate to="/impersonate-user" />;
  }

  if (user.permissions.canOnlyViewResourcesMenu && user.role !== 'Manager') {
    return <Navigate to="/documents" />;
  }

  const navURI = '/'+(user.permissions.defaultLandingPage || 'dashboard');
  return <Navigate to={navURI} />;
};
