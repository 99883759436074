import React from 'react';
import { RoleSelect, RoleSelectOptionValue } from './RoleSelect';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';
import { GetRoleDropdownOptionsResponse } from './GetRoleDropdownOptionsResponse';

type Props = Omit<
  MetadataSingleSelectFieldComponentProps<
    RoleSelectOptionValue,
    {},
    GetRoleDropdownOptionsResponse
  >,
  'settings'
>;

export const RoleSelectField = (props: Props) => (
  <MetadataSingleSelectField<RoleSelectOptionValue, {}, GetRoleDropdownOptionsResponse>
    {...props}
    settings={{}}
    renderSelect={(metadataSingleSelectProps) => <RoleSelect {...metadataSingleSelectProps} />}
  />
);
