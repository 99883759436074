import React, { useState } from 'react';
import { Header1, Header3 } from '../../infrastructure/interface/components/Headers';
import { CentredPaddedPage } from '../../styling/layout/PaddedPage';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { ApiRequestStateWrapper } from '../../infrastructure/api/ApiRequestStateWrapper';
import {
  FieldAndValue,
  FieldAndValueWithSeparatorsContainer,
} from '../../infrastructure/interface/components/FieldAndValue';
import { usePostJson } from '../../infrastructure/api/usePostJson';
import { Formik, FormikHelpers } from 'formik';
import { Validator } from 'fluentvalidation-ts';
import { InputField } from '../../infrastructure/forms/fields/InputField';
import { Panel } from '../../infrastructure/interface/components/Panel';
import styled from 'styled-components/macro';
import { spacing16, spacing32 } from '../../styling/design/spacing';
import { SubmitButton } from '../../infrastructure/forms/common/SubmitButton';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { Form } from '../../infrastructure/forms/common/Form';
import { RequiresUserRole } from '../authentication/UserRoles';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { ButtonRow } from '../../infrastructure/interface/buttons/ButtonRow';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { notBeEmpty } from '../validation/stringValidation';

const EmailSettingsComponent = () => {
  const { translate } = useInternationalisation();

  const getSmtpCredentialsRequest = useGetJson<undefined, GetSmtpCredentialsResponse>(
    '/api/emails/GetSmtpCredentialsForCurrentUser'
  );

  useOnMount(() => getSmtpCredentialsRequest.makeRequest());

  const sendTestEmailPostRequest = usePostJson<SendTestEmailCommand, {}>(
    '/api/emails/SendTestEmail'
  );

  const [success, setSuccess] = useState(false);

  const onSubmit = (
    formModel: SendTestEmailFormModel,
    formikHelpers: FormikHelpers<SendTestEmailFormModel>
  ) => {
    setSuccess(false);
    const command = mapFromFormModelToCommand(formModel);

    sendTestEmailPostRequest.makeRequest({
      requestBody: command,
      onSuccess: (_) => {
        setSuccess(true);
        formikHelpers.setSubmitting(false);
      },
      onFailure: (_) => formikHelpers.setSubmitting(false),
    });
  };

  const sendTestEmailValidator = new SendTestEmailValidator(translate);

  return (
    <ApiRequestStateWrapper<GetSmtpCredentialsResponse>
      apiRequestState={getSmtpCredentialsRequest.state}
      retry={() => getSmtpCredentialsRequest.makeRequest()}
    >
      {(response) => (
        <CentredPaddedPage>
          <Header1>{translate('pages.emailSettings.header')}</Header1>
          <p>{translate('pages.emailSettings.description')}</p>
          <SmtpCredentialsPanel>
            <Header3>{translate('pages.emailSettings.smtpCredentials.header')}</Header3>
            <FieldAndValueWithSeparatorsContainer>
              <FieldAndValue
                fieldLabel={translate('pages.emailSettings.smtpCredentials.senderEmailAddress')}
                type="text"
                value={response.senderEmail}
              />
              <FieldAndValue
                fieldLabel={translate('pages.emailSettings.smtpCredentials.smtpServer')}
                type="text"
                value={response.smtpServer}
              />
              <FieldAndValue
                fieldLabel={translate('pages.emailSettings.smtpCredentials.requiresAuthentication')}
                type="boolean"
                value={response.requiresAuthentication}
              />
              <FieldAndValue
                fieldLabel={translate('pages.emailSettings.smtpCredentials.username')}
                type="text"
                value={response.username}
              />
              <FieldAndValue
                fieldLabel={translate('pages.emailSettings.smtpCredentials.connectionType')}
                type="text"
                value={response.connectionTypeName}
              />
              <FieldAndValue
                fieldLabel={translate('pages.emailSettings.smtpCredentials.port')}
                type="number"
                value={response.port}
              />
            </FieldAndValueWithSeparatorsContainer>
          </SmtpCredentialsPanel>
          <Formik<SendTestEmailFormModel>
            initialValues={initialFormModel}
            onSubmit={onSubmit}
            validate={sendTestEmailValidator.validate}
          >
            <Form data-testid={sendTestEmailFormTestId}>
              <EmailDetailsPanel>
                <Header3>{translate('pages.emailSettings.form.header')}</Header3>
                <InputField
                  fieldName="toEmail"
                  label={translate('pages.emailSettings.form.emailTo')}
                />
              </EmailDetailsPanel>
              <ButtonRow rightAligned={true}>
                <SubmitButton
                  submittingText={translate('pages.emailSettings.submitButton.waitingText')}
                >
                  {translate('pages.emailSettings.submitButton.text')}
                </SubmitButton>
              </ButtonRow>
              {sendTestEmailPostRequest.state.error != null && (
                <Alert
                  alertType="negative"
                  header={translate('pages.emailSettings.errorMessage.header')}
                  isDismissible={true}
                  withMarginTop={true}
                >
                  {sendTestEmailPostRequest.state.error}
                </Alert>
              )}
              {success && (
                <Alert
                  alertType="positive"
                  header={translate('pages.emailSettings.successMessage.header')}
                  isDismissible={true}
                  withMarginTop={true}
                >
                  {translate('pages.emailSettings.successMessage.text')}
                </Alert>
              )}
            </Form>
          </Formik>
        </CentredPaddedPage>
      )}
    </ApiRequestStateWrapper>
  );
};

export const sendTestEmailFormTestId = 'send-test-email-form';

const SmtpCredentialsPanel = styled(Panel)`
  margin-top: ${spacing32};
  margin-bottom: ${spacing32};
`;

const EmailDetailsPanel = styled(Panel)`
  margin-bottom: ${spacing16};
`;

export type GetSmtpCredentialsResponse = {
  senderEmail: string;
  smtpServer: string;
  requiresAuthentication: boolean;
  username: string;
  connectionTypeName: string;
  port: number;
};

export type SendTestEmailCommand = {
  toEmail: string;
};

export type SendTestEmailFormModel = {
  toEmail: string;
};

const initialFormModel: SendTestEmailFormModel = {
  toEmail: '',
};

const mapFromFormModelToCommand = (formModel: SendTestEmailFormModel): SendTestEmailCommand => ({
  toEmail: formModel.toEmail,
});

class SendTestEmailValidator extends Validator<SendTestEmailFormModel> {
  constructor(translate: TranslateFunction) {
    super();

    this.ruleFor('toEmail')
      .must(notBeEmpty(translate))
      .emailAddress()
      .withMessage(translate('validation.validEmail'));
  }
}

export const EmailSettings = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.emailSettings.title'));

  return (
    <RequiresUserRole userRole="Administrator">
      <EmailSettingsComponent />
    </RequiresUserRole>
  );
};
