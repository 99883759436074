import { isEqual } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components/macro';
import { FloppyDiskIcon, PlusIcon, TimesSolidIcon } from '../../icons/icons';
import { ApiRequestStateWrapper } from '../../infrastructure/api/ApiRequestStateWrapper';
import { RequestFailedPage } from '../../infrastructure/api/RequestFailedPage';
import { usePostJson } from '../../infrastructure/api/usePostJson';
import { useIsOpen } from '../../infrastructure/hooks/useIsOpen';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { ButtonWithIcon } from '../../infrastructure/interface/buttons/ButtonWithIcon';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';
import { ActionAlert } from '../../infrastructure/interface/components/ActionAlert';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { LoadingOverlay } from '../../infrastructure/interface/components/LoadingOverlay';
import { NoMobileContentAlert } from '../../infrastructure/interface/components/NoMobileContentAlert';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { spacing16, spacing32 } from '../../styling/design/spacing';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { RequiresMenuOption } from '../authentication/UserMenuOptions';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { RequiresUserRole } from '../authentication/UserRoles';
import { myDashboardDashboardId } from '../metadata/idConstants';
import { DashboardComponentCommand } from './admin/CreateEditDashboardForm';
import { DashboardComponentsModal } from './DashboardComponentsModal';
import {
  addMultipleComponentsToBottomOfDashboard,
  DashboardLayoutEditor,
} from './DashboardLayoutEditor';
import { GetDashboardResponse, useGetDashboard } from './useGetDashboard';

export const EditMyDashboard = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.editMyDashboard.title'));

  return (
    <RequiresUserRole userRole={allNonAdminUserRoles}>
      <RequiresMenuOption menuOption={'dashboard'}>
        <MobileOnly>
          <MobileEditMyDashboardComponent />
        </MobileOnly>
        <DesktopOnly>
          <DesktopEditMyDashboardApiRequestStateWrapper />
        </DesktopOnly>
      </RequiresMenuOption>
    </RequiresUserRole>
  );
};

const MobileEditMyDashboardComponent = () => {
  const { translate } = useInternationalisation();

  return (
    <PaddedPage>
      <Header1>{translate('pages.editMyDashboard.header')}</Header1>
      <NoMobileContentAlert header={translate('pages.editMyDashboard.mobileAlertHeader')} />
    </PaddedPage>
  );
};

const DesktopEditMyDashboardApiRequestStateWrapper = () => {
  const getDashboardRequest = useGetDashboard();

  const fetchInitialData = () =>
    getDashboardRequest.makeRequest({
      queryParameters: { dashboardId: myDashboardDashboardId },
    });

  useOnMount(() => {
    fetchInitialData();
  });

  return (
    <ApiRequestStateWrapper apiRequestState={getDashboardRequest.state} retry={fetchInitialData}>
      {(initialDashboardResponse) => (
        <DesktopEditMyDashboardComponent initialMyDashboardResponse={initialDashboardResponse} />
      )}
    </ApiRequestStateWrapper>
  );
};

type DesktopEditMyDashboardComponentProps = {
  initialMyDashboardResponse: GetDashboardResponse;
};

const DesktopEditMyDashboardComponent = (props: DesktopEditMyDashboardComponentProps) => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();

  const [renderedComponents, setRenderedComponents] = useState(
    props.initialMyDashboardResponse.components
  );
  const navigationGuardModalState = useIsOpen(false);
  const dashboardComponentsModalState = useIsOpen(false);
  const [innerComponentApiError, setInnerComponentApiError] = useState<string | null>(null);

  const returnToDashboard = () => navigate('/dashboard');

  const editMyDashboardRequest = usePostJson<EditMyDashboardCommand, {}>(
    '/api/dashboards/EditMyDashboard'
  );

  const onCancelClick = () => {
    if (isEqual(renderedComponents, props.initialMyDashboardResponse.components)) {
      returnToDashboard();
    } else {
      navigationGuardModalState.open();
    }
  };

  const onSaveClick = () =>
    editMyDashboardRequest.makeRequest({
      requestBody: { components: renderedComponents },
      onSuccess: returnToDashboard,
    });

  if (innerComponentApiError != null) {
    return <RequestFailedPage error={innerComponentApiError} />;
  }

  return (
    <>
      <PaddedPage>
        <HeaderContainer>
          <Header1>{translate('pages.editMyDashboard.header')}</Header1>
          <ActionButtonsContainer>
            <MinimalButton icon={<TimesSolidIcon />} buttonStyle="black" onClick={onCancelClick}>
              {translate('actionButtons.cancel')}
            </MinimalButton>
            <MinimalButton icon={<FloppyDiskIcon />} onClick={onSaveClick}>
              {translate('pages.createDashboard.saveDashboard')}
            </MinimalButton>
          </ActionButtonsContainer>
        </HeaderContainer>
        {editMyDashboardRequest.state.error && (
          <Alert
            alertType="negative"
            header={translate('errors.apology')}
            isDismissible={true}
            withMarginBottom={true}
          >
            {editMyDashboardRequest.state.error}
          </Alert>
        )}
        <LoadingOverlay showOverlay={editMyDashboardRequest.state.inProgress}>
          <AddComponentButtonContainer>
            <ButtonWithIcon icon={<PlusIcon />} onClick={dashboardComponentsModalState.open}>
              {translate('pages.createDashboard.addComponentButtonText')}
            </ButtonWithIcon>
          </AddComponentButtonContainer>
          <DashboardComponentsModal
            isOpen={dashboardComponentsModalState.isOpen}
            onRequestClose={dashboardComponentsModalState.close}
            onAddComponents={(selectedComponents) =>
              addMultipleComponentsToBottomOfDashboard(
                selectedComponents,
                renderedComponents,
                setRenderedComponents
              )
            }
            onError={setInnerComponentApiError}
            componentIdsToIgnore={renderedComponents.map((c) => c.dashboardComponentId)}
          />
          <DashboardLayoutEditor
            components={renderedComponents}
            setComponents={setRenderedComponents}
            error={null}
            onAddComponentButtonClick={dashboardComponentsModalState.open}
          />
        </LoadingOverlay>
      </PaddedPage>
      <ActionAlert
        alertType="negative"
        isOpen={navigationGuardModalState.isOpen}
        onRequestClose={navigationGuardModalState.close}
        title={translate('pages.editMyDashboard.navigationGuardModal.title')}
        message={translate('pages.editMyDashboard.navigationGuardModal.message')}
        cancelButtonText={translate('actionButtons.cancel')}
        confirmButtonText={translate('pages.editMyDashboard.navigationGuardModal.confirmText')}
        onInitiateAction={returnToDashboard}
        actionInProgress={false}
        actionError={null}
      />
    </>
  );
};

type EditMyDashboardCommand = {
  components: Array<DashboardComponentCommand>;
};

const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: ${spacing16};
`;

const AddComponentButtonContainer = styled.div`
  margin-bottom: ${spacing32};
`;
