// Changes here should also be made in LocalBankDetailsDefault.cs
export const localBankDetailsDefaultCodesByName = {
  Full: 'F',
  LocalEdit: 'E',
  LocalRestrict: 'L',
} as const;

export type LocalBankDetailsDefault = keyof typeof localBankDetailsDefaultCodesByName;
export type LocalBankDetailsDefaultCode =
  typeof localBankDetailsDefaultCodesByName[LocalBankDetailsDefault];
