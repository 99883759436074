export const spacing4 = '4px';
export const spacing8 = '8px';
export const spacing12 = '12px';
export const spacing16 = '16px';
export const spacing24 = '24px';
export const spacing32 = '32px';
export const spacing48 = '48px';
export const spacing64 = '64px';
export const spacing80 = '80px';
export const spacing96 = '96px';
export const spacing128 = '128px';
export const spacing160 = '160px';
export const spacing200 = '200px';
export const spacing256 = '256px';
