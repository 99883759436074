import React, { useContext } from 'react';
import { ReportParameterResponse } from '../../../Report';
import { useReportParameterFieldsContext } from '../../ReportParameterFieldsContext';
import { GeneralLedgerBankSelectField } from '../../../../metadata/generalLedger/bank/GeneralLedgerBankSelectField';
import { ReportContext } from '../../../ReportContext';

type Props = {
  generalLedgerBankReportParameter: ReportParameterResponse;
  onError: (error: string) => void;
};

export const ReportsGeneralLedgerBankSelectField = ({
  generalLedgerBankReportParameter,
  onError,
}: Props) => {
  const { setFieldLoaded } = useReportParameterFieldsContext();
  const { selectedCompanyOption, selectedCompanyReportGroupOption } = useContext(ReportContext);

  return (
    <GeneralLedgerBankSelectField
      fieldName={generalLedgerBankReportParameter.name}
      label={generalLedgerBankReportParameter.displayText}
      onError={onError}
      settings={{
        companyId: selectedCompanyOption?.companyId ?? null,
        companyReportGroupId: selectedCompanyReportGroupOption?.companyReportGroupId ?? null,
        includeAll: true,
        includeBlank: false,
      }}
      defaultToFirstOption={true}
      onLoaded={() => setFieldLoaded(generalLedgerBankReportParameter.name)}
    />
  );
};
