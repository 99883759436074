import React from 'react';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../../MetadataSingleSelectField';
import {
  GeneralLedgerClassSelect,
  GeneralLedgerClassSelectOptionValue,
} from './GeneralLedgerClassSelect';
import { GeneralLedgerClassSelectSettings } from './GeneralLedgerClassSelectSettings';
import { GetGeneralLedgerClassDropdownOptionsResponse } from './GetGeneralLedgerClassDropdownOptionsResponse';

type Props = MetadataSingleSelectFieldComponentProps<
  GeneralLedgerClassSelectOptionValue,
  GeneralLedgerClassSelectSettings,
  GetGeneralLedgerClassDropdownOptionsResponse
>;

export const GeneralLedgerClassSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    GeneralLedgerClassSelectOptionValue,
    GeneralLedgerClassSelectSettings,
    GetGeneralLedgerClassDropdownOptionsResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <GeneralLedgerClassSelect {...metadataSingleSelectProps} />
    )}
  />
);
