import styled from 'styled-components/macro';
import { fontSizeCss } from '../../../styling/design/fonts';
import { spacing4 } from '../../../styling/design/spacing';
import { colourBlack } from '../../../styling/design/colours';

export const FieldHelpText = styled.div`
  display: block;
  ${fontSizeCss('xsmall')};
  margin-top: ${spacing4};
  color: ${colourBlack};
`;
