import { useGetJson } from '../../infrastructure/api/useGetJson';

export const useGetDisclaimersForCompanyRequest = () =>
  useGetJson<GetDisclaimersForCompanyQuery, GetDisclaimersForCompanyResponse>(
    '/api/disclaimers/GetDisclaimersForCompany'
  );

type GetDisclaimersForCompanyQuery = {
  companyId: number | null;
};

export type GetDisclaimersForCompanyResponse = {
  companyDisclaimer: string | null;
  groupDisclaimer: string | null;
  investorDashboardDisclaimer: string | null;
};
