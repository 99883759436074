import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useDashboardFilters } from '../DashboardFiltersContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { ChartDataPoint } from '../../../infrastructure/charts/Chart';
import { useDevice } from '../../../infrastructure/hooks/useDevice';
import { BarChart } from '../../../infrastructure/charts/BarChart';
import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { FieldLabel } from '../../../infrastructure/forms/common/FieldLabel';
import { SingleSelect } from '../../../infrastructure/interface/forms/SingleSelect';
import { ComponentResponse } from '../DashboardComponent';

export const securityPositionsComponentName = 'Securities Position';

export const SecurityPositions = () => {
  const endpointUrl = 'api/dashboards/GetDataForSecurityPositions';
  const getRequest = useGetJson<
    GetDataForSecurityPositionsQuery,
    GetDataForSecurityPositionsResponse
  >(endpointUrl);

  const { companyId, fromDate } = useDashboardFilters();
  const [filterType, setFilterType] = useState<SecurityPositionsFilterType>('currency');

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
          filterType: filterType,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate, filterType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <SecurityPositionsComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
          filterType={filterType}
          setFilterType={setFilterType}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForSecurityPositionsResponse;
  showLoadingOverlay: boolean;
  filterType: SecurityPositionsFilterType;
  setFilterType: (value: SecurityPositionsFilterType) => void;
};

const SecurityPositionsComponent = (props: ComponentProps) => {
  const { translate, formatNumber } = useInternationalisation();
  const { isMobile } = useDevice();

  const graphData: Array<ChartDataPoint> = props.response.dataPoints.map((dataPoint) => ({
    label: dataPoint.label,
    value: dataPoint.value,
  }));
  return (
    <DashboardComponentLayout
      headerText={
        translate('pages.dashboard.components.securityPositions.title') +
        translate(`pages.dashboard.components.securityPositions.filterType.${props.filterType}`)
      }
      showLoadingOverlay={props.showLoadingOverlay}
      showNoDataMessage={props.response.noDataMessage}
      headerOrientation="row"
      filterSet={<FilterTypeSelect value={props.filterType} onChange={props.setFilterType} />}
    >
      <BarChart
        exportFilename={
          translate('pages.dashboard.components.securityPositions.exportName') +
          translate(`pages.dashboard.components.securityPositions.filterType.${props.filterType}`)
        }
        dataPoints={graphData}
        height="stretch"
        responsiveOptions={{
          condition: {
            callback: () => isMobile,
          },
        }}
        tooltipOptions={{
          headerFormat: `${translate(
            `pages.dashboard.components.securityPositions.filterType.${props.filterType}`
          )} <br />`,
          pointFormatter() {
            return `<b><span style="color: ${this.color}">${
              this.name
            }&nbsp;</span>:&nbsp;${formatNumber(this.y ?? 0, { decimalPlaces: 2 })}</b>`;
          },
        }}
      />
    </DashboardComponentLayout>
  );
};

type FilterTypeProps = {
  value: SecurityPositionsFilterType;
  onChange: (value: SecurityPositionsFilterType) => void;
};

const FilterTypeSelect = (props: FilterTypeProps) => {
  const { translate } = useInternationalisation();

  const translateOptionLabel = useCallback(
    (optionValue: string) =>
      translate(`pages.dashboard.components.securityPositions.filterType.${optionValue}`),
    [translate]
  );

  const options: SelectOptions<SecurityPositionsFilterType> = useMemo(
    () => [
      { label: translateOptionLabel('currency'), value: 'currency' },
      { label: translateOptionLabel('securityType'), value: 'securityType' },
      { label: translateOptionLabel('longShort'), value: 'longShort' },
    ],
    [translateOptionLabel]
  );

  return (
    <div>
      <FieldLabel>
        {translate(`pages.dashboard.components.securityCostChart.fieldLabel`)}
      </FieldLabel>
      <SingleSelect
        options={options}
        value={props.value}
        onChange={(value) => props.onChange(value!)}
      />
    </div>
  );
};

type GetDataForSecurityPositionsQuery = {
  companyId: number;
  runDate: string;
  filterType: string;
};

export type GetDataForSecurityPositionsResponse = ComponentResponse & {
  dataPoints: Array<{
    value: number;
    label: string;
  }>;
};

type SecurityPositionsFilterType = 'currency' | 'securityType' | 'longShort';
