import React, { useMemo } from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import styled from 'styled-components/macro';
import { spacing16, spacing32 } from '../../../styling/design/spacing';
import { PaginationControl } from '../tables/PaginationControl';
import { MiniSelect } from '../forms/MiniSelect';
import { SelectOptions } from '../forms/BaseSelect';
import { resultsPerPageOptionsToSelectOptions } from '../tables/PaginatedTable';

type Props = {
  resultsPerPageOptions?: Array<number>;
  resultsPerPage: number;
  totalResultsCount: number;
  onChangeResultsPerPage: (resultsPerPage: number) => void;
  currentPageNumber: number;
  onChangeCurrentPageNumber: (currentPageNumber: number) => void;
};

export const MobilePaginationControls = ({
  resultsPerPageOptions,
  resultsPerPage,
  totalResultsCount,
  onChangeResultsPerPage,
  currentPageNumber,
  onChangeCurrentPageNumber,
}: Props) => {
  const { translate } = useInternationalisation();

  const totalPageCount = Math.ceil(totalResultsCount / resultsPerPage);

  const resultsPerPageSelectOptions: SelectOptions<number> = useMemo(
    () => resultsPerPageOptionsToSelectOptions(resultsPerPageOptions),
    [resultsPerPageOptions]
  );

  const onResultsPerPageSelectChange = (newValue: number) => {
    onChangeResultsPerPage(newValue);
    onChangeCurrentPageNumber(1);
  };

  return (
    <PaginationControlsContainer>
      <PaginationControl
        totalPages={totalPageCount}
        currentPage={currentPageNumber}
        setCurrentPage={onChangeCurrentPageNumber}
      />
      <ResultsPerPageSection>
        <ResultsPerPageText>{translate('tables.pagination.resultsPerPage')}</ResultsPerPageText>
        <MiniSelect<number>
          options={resultsPerPageSelectOptions}
          value={resultsPerPage}
          onChange={(newValue) => onResultsPerPageSelectChange(newValue!)}
        />
      </ResultsPerPageSection>
    </PaginationControlsContainer>
  );
};

const PaginationControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: ${spacing16} 0;
`;

const ResultsPerPageSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing32};
`;

const ResultsPerPageText = styled.div`
  margin-right: ${spacing16};
`;
