import { usePostJson } from '../../../../infrastructure/api/usePostJson';

export const BatchDeleteUsersRequest = (accessGroupId: number) => {
  const batchDeleteUsersRequest = usePostJson<BatchDeleteUsersCommand, BatchDeleteUsersResponse>(
    '/api/users/DeleteUsersBatch'
  );

  const submit = (
    usersToDelete: Array<UserToDelete>,
    setSubmitting: (isSubmitting: boolean) => void,
    onSuccess: (response: BatchDeleteUsersResponse) => void
  ) => {
    batchDeleteUsersRequest.makeRequest({
      requestBody: {
        accessGroupId: accessGroupId,
        usersToDelete: usersToDelete,
      },
      onSuccess: (response) => {
        setSubmitting(false);
        onSuccess(response);
      },
      onFailure: () => setSubmitting(false),
    });
  };

  return {
    submit,
  };
};

type UserToDelete = {
  username: string;
};

export type BatchDeleteUserFormModel = {
  usersToDelete: Array<UserToDelete>;
};

export type BatchDeleteUsersCommand = {
  accessGroupId: number;
  usersToDelete: Array<UserToDelete>;
};

export type BatchDeleteUsersResponse = {
  deletedUsers: Array<DeletedUser>;
};

type DeletedUser = {
  username: string;
  status: string;
};
