import { reverseRecord } from '../../../helpers/typeHelpers';

// Changes here should also be made in ReportParameterType.cs
export const reportParameterTypeCodesByType = {
  generalLedgerBank: 'BR',
  capitalCall: 'CL',
  companyReportGroup: 'CG',
  company: 'CO',
  generalLedgerCounterpart: 'CP',
  genericDropdown: 'DD',
  equityAttribution: 'EA',
  freeDate: 'FD',
  freeText: 'FT',
  generalLedgerClass: 'GC',
  generalLedgerSubclass: 'GS',
  shareholder: 'IN',
  securityType: 'ST',
  valueDatePlusOne: 'V1',
  valueDate: 'VD',
  boolean: 'YN',
  subLedger: 'SL',
  currency: 'CC',
} as const;

export type ReportParameterType = keyof typeof reportParameterTypeCodesByType;
export type ReportParameterTypeCode = typeof reportParameterTypeCodesByType[ReportParameterType];

export const reportParameterTypesByCode = reverseRecord(reportParameterTypeCodesByType);
