export type EquityAttributionSelectSettings = {
  companyId: number | null;
  includeBlank: boolean;
  employeeCounterpartId: number | null;
};

export const defaultEquityAttributionSelectSettings: EquityAttributionSelectSettings = {
  companyId: null,
  includeBlank: true,
  employeeCounterpartId: null,
};
