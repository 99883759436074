import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { PlaceTransactionFormModel } from './PlaceTransaction';
import { DealingTransactionTypeCode } from '../../metadata/transactions/DealingTransactionType';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { TransactionLockCode } from '../../metadata/transactions/TransactionLock';
import { assertNotNull } from '../../../helpers/nullHelpers';
import { nullIfBlank } from '../../../helpers/stringHelpers';

export const useCreateTransactionRequest = () =>
  usePostJson<CreateTransactionRequest, CreateTransactionResponse>(
    '/api/transactions/CreateTransaction'
  );

type CreateTransactionRequest = {
  investorId: number | null;
  companyId: number;
  transactionType: DealingTransactionTypeCode;
  dealingDate: IsoDatestamp;
  equityAttributionId: number | null;
  switchToCompanyId: number | null;
  switchToEquityAttributionId: number | null;
  transactionLock: TransactionLockCode;
  amountOrShares: number | null;
  transactionReference: string | null;
  additionalInstructions: string | null;
};

type CreateTransactionResponse = {
  uploadId: number;
  dealNumber: number;
  errors: Array<string>;
};

export const toCreateTransactionRequest = (
  formModel: PlaceTransactionFormModel
): CreateTransactionRequest => ({
  investorId: formModel.investorId,
  companyId: assertNotNull(formModel.companyId, 'formModel.companyId'),
  transactionType: assertNotNull(formModel.transactionType, 'formModel.transactionType'),
  dealingDate: assertNotNull(formModel.dealingDate, 'formModel.dealingDate'),
  equityAttributionId: formModel.equityAttributionId,
  switchToCompanyId: formModel.switchToCompanyId,
  switchToEquityAttributionId: formModel.switchToEquityAttributionId,
  transactionLock: formModel.transactionLock,
  amountOrShares: formModel.amountOrShares,
  transactionReference: nullIfBlank(formModel.transactionReference),
  additionalInstructions: nullIfBlank(formModel.additionalInstructions),
});
