import { useGetJson } from '../../infrastructure/api/useGetJson';
import { DashboardLoginRole } from './admin/CreateEditDashboardForm';
import { DashboardComponentResponse } from './GetDashboardsForCurrentUserResponse';

export type GetDashboardQuery = {
  dashboardId: number;
};

export type GetDashboardResponse = {
  dashboardId: number;
  description: string;
  loginRole: DashboardLoginRole;
  accessGroupId: number;
  components: Array<DashboardComponentResponse>;
};

export const useGetDashboard = () =>
  useGetJson<GetDashboardQuery, GetDashboardResponse>('/api/dashboards/GetDashboard');
