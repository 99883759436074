import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetInvestorDropdownOptionsResponse = {
  options: Array<InvestorDropdownOptionResponse>;
};

export type InvestorDropdownOptionResponse = {
  counterpartId: number; // i.e. "Investor ID"
  investorName: string;
};

export const mapInvestorDropdownOptionsResponseToSelectOptions = (
  response: GetInvestorDropdownOptionsResponse | null
): SelectOptions<number> =>
  response == null
    ? []
    : response.options.map((option) => ({
        value: option.counterpartId,
        label: option.investorName,
      }));
