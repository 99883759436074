import React from 'react';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  GetRoleDropdownOptionsResponse,
  mapGetRoleDropdownOptionsResponseToDropdownOptions,
} from './GetRoleDropdownOptionsResponse';
import { MetadataSingleSelect } from '../MetadataSingleSelect';
import { UserRoleCodeCharacter } from '../../authentication/UserRole';

export type RoleSelectOptionValue = UserRoleCodeCharacter | null;

type Props = MetadataSingleSelectComponentProps<
  RoleSelectOptionValue,
  {},
  GetRoleDropdownOptionsResponse
>;

export const RoleSelect = ({ ...metadataSingleSelectProps }: Props) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.roleSelect.placeholder');
  const loadingPlaceholder = translate('metadata.roleSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<RoleSelectOptionValue, {}, {}, GetRoleDropdownOptionsResponse>
      endpointUrl="/api/metadata/GetRoleDropdownOptions"
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapGetRoleDropdownOptionsResponseToDropdownOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={250}
      {...metadataSingleSelectProps}
    />
  );
};
