import React from 'react';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { ApiRequestStateWrapper } from '../../infrastructure/api/ApiRequestStateWrapper';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import {
  DashboardResponse,
  GetDashboardsForCurrentUserResponse,
} from './GetDashboardsForCurrentUserResponse';
import { ErrorMessage } from '../../infrastructure/interface/components/ErrorMessage';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { RequiresUserRole } from '../authentication/UserRoles';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { DashboardFiltersContextProvider, useDashboardFilters } from './DashboardFiltersContext';
import { DashboardFilters } from './DashboardFilters';
import { onMobile, onNarrowDesktop, onWideDesktop } from '../../styling/layout/screenBreakpoints';
import styled, { css } from 'styled-components/macro';
import { spacing32 } from '../../styling/design/spacing';
import { DashboardComponent } from './DashboardComponent';
import { RequestFailedPage } from '../../infrastructure/api/RequestFailedPage';
import { DashboardDisclaimer } from './DashboardDisclaimer';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { ActionsContainer } from '../../infrastructure/interface/components/PageHeaderActions';
import { myDashboardDashboardId } from '../metadata/idConstants';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';
import { PencilIcon } from '../../icons/icons';
import { useNavigate } from 'react-router';
import { RequiresMenuOption } from '../authentication/UserMenuOptions';

export const Dashboard = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.dashboard.title'));

  return (
    <RequiresUserRole userRole={allNonAdminUserRoles}>
      <RequiresMenuOption menuOption={'dashboard'}>
        <DashboardFiltersContextProvider>
          <DashboardApiRequestWrapper />
        </DashboardFiltersContextProvider>
      </RequiresMenuOption>
    </RequiresUserRole>
  );
};

const DashboardApiRequestWrapper = () => {
  const { setDashboardId, filterLoadError } = useDashboardFilters();

  const getDashboardsApiRequest = useGetJson<undefined, GetDashboardsForCurrentUserResponse>(
    '/api/dashboards/GetDashboardsForCurrentUser'
  );

  const onRequestSuccess = (response: GetDashboardsForCurrentUserResponse) => {
    const myDashboardReturned = response.dashboards.some(
      (d) => d.dashboardId === myDashboardDashboardId
    );

    setDashboardId(
      myDashboardReturned ? myDashboardDashboardId : response.dashboards[0]?.dashboardId
    );
  };

  const makeRequest = () => getDashboardsApiRequest.makeRequest({ onSuccess: onRequestSuccess });

  useOnMount(() => {
    makeRequest();
  });

  if (filterLoadError) {
    return <RequestFailedPage error={filterLoadError} />;
  }

  return (
    <ApiRequestStateWrapper apiRequestState={getDashboardsApiRequest.state} retry={makeRequest}>
      {(response) => <DashboardContent dashboards={response.dashboards} />}
    </ApiRequestStateWrapper>
  );
};

const DashboardContent = ({ dashboards }: { dashboards: Array<DashboardResponse> }) => {
  const { translate } = useInternationalisation();
  const { dashboardId } = useDashboardFilters();
  const navigate = useNavigate();

  const selectedDashboard = dashboards.find((dashboard) => dashboard.dashboardId === dashboardId);

  if (selectedDashboard == null) {
    return (
      <PaddedPage>
        <ErrorMessage>{translate('pages.dashboard.noDashboardsError')}</ErrorMessage>
      </PaddedPage>
    );
  }

  return (
    <PaddedPage>
      <HeaderContainer>
        <Header1>{translate('pages.dashboard.header')}</Header1>
        <DesktopOnly>
          {dashboardId === myDashboardDashboardId && (
            <MinimalButton
              icon={<PencilIcon />}
              onClick={() => navigate('/dashboard/edit-my-dashboard')}
            >
              {translate('pages.dashboard.editDashboard')}
            </MinimalButton>
          )}
        </DesktopOnly>
      </HeaderContainer>
      <DashboardFilters dashboardOptions={dashboards} />
      <MobileOnly>
        <ActionsContainer>
          <DashboardDisclaimer />
        </ActionsContainer>
      </MobileOnly>
      <Grid>
        {selectedDashboard.components.map((component) => (
          <DashboardComponent key={component.dashboardComponentId} component={component} />
        ))}
      </Grid>
      <DesktopOnly>
        <DashboardDisclaimer />
      </DesktopOnly>
    </PaddedPage>
  );
};

export const dashboardGridRowHeightInPx = 400;
export const dashboardGridGapInPx = 32;

// For a good primer on CSS grid see the following guide:
// https://css-tricks.com/snippets/css/complete-guide-grid/
const Grid = styled.div`
  ${onWideDesktop(css`
    padding: ${spacing32} 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${dashboardGridGapInPx}px;
  `)};
  
  ${onNarrowDesktop(css`
    padding: ${spacing32};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  `)};

  ${onMobile(css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  `)};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
