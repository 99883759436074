import React, { useContext, useMemo, useState } from 'react';
import { CentredPaddedPage } from '../../styling/layout/PaddedPage';
import { usePostJson } from '../../infrastructure/api/usePostJson';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { useNavigate } from 'react-router-dom';
import { AuthenticationResult } from '../authentication/AuthenticationResult';
import { RequiresUserRole } from '../authentication/UserRoles';
import { Panel } from '../../infrastructure/interface/components/Panel';
import { Validator } from 'fluentvalidation-ts';
import { notBeNull } from '../validation/commonValidation';
import { notBeEmpty } from '../validation/stringValidation';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { Form } from '../../infrastructure/forms/common/Form';
import { InputField } from '../../infrastructure/forms/fields/InputField';
import { SubmitButton } from '../../infrastructure/forms/common/SubmitButton';
import { Formik, FormikHelpers } from 'formik';
import { UserSearchModal } from '../search/UserSearchModal';
import { AddressBookIcon } from '../../icons/icons';
import { colourGrey08 } from '../../styling/design/colours';
import {
  MfaModalFormModel,
  MultiFactorAuthenticationModal,
} from '../authentication/multiFactorAuthentication/MultiFactorAuthenticationModal';
import {
  InitiateMfaFlowForLoggedInUserResponse,
  useInitiateMfaFlowForLoggedInUserRequest,
} from '../authentication/multiFactorAuthentication/useInitiateMfaFlowForLoggedInUserRequest';
import { ButtonRow } from '../../infrastructure/interface/buttons/ButtonRow';
import { useLogoutDueToLockOut } from '../../infrastructure/hooks/useLogoutDueToLockOut';

export const ImpersonateUser = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.impersonateUser.title'));

  const [usernameToImpersonate, setUsernameToImpersonate] = useState('');

  const [mfaAuthenticationResult, setMfaAuthenticationResult] =
    useState<AuthenticationResult | null>(null);

  return (
    <RequiresUserRole userRole="View As">
      <CentredPaddedPage>
        <Header1>{translate('pages.impersonateUser.header')}</Header1>
        <ImpersonateUserForm
          setUsernameToImpersonate={setUsernameToImpersonate}
          onInitiateMfa={(response) => setMfaAuthenticationResult(response.authenticationResult)}
        />
      </CentredPaddedPage>
      <ImpersonateUserMfaModal
        usernameToImpersonate={usernameToImpersonate}
        mfaAuthenticationResult={mfaAuthenticationResult}
        setMfaAuthenticationResult={setMfaAuthenticationResult}
      />
    </RequiresUserRole>
  );
};

type ImpersonateUserFormProps = {
  setUsernameToImpersonate: (usernameToImpersonate: string) => void;
  onInitiateMfa: (response: InitiateMfaFlowForLoggedInUserResponse) => void;
};

const ImpersonateUserForm = ({
  setUsernameToImpersonate,
  onInitiateMfa,
}: ImpersonateUserFormProps) => {
  const { translate } = useInternationalisation();

  const initiateMfaFlowRequest = useInitiateMfaFlowForLoggedInUserRequest();
  const initiateMfaFlowError = initiateMfaFlowRequest.state.error;

  const onSubmitImpersonateUserForm = (
    formModel: ImpersonateUserFormModel,
    formikHelpers: FormikHelpers<ImpersonateUserFormModel>
  ) => {
    setUsernameToImpersonate(formModel.username);

    initiateMfaFlowRequest.makeRequest({
      requestBody: {},
      onSuccess: (response) => {
        onInitiateMfa(response);
        formikHelpers.setSubmitting(false);
      },
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  const impersonateUserFormValidator = useMemo(
    () => new ImpersonateUserFormValidator(translate),
    [translate]
  );

  const [isSearchModalOpen, setIsSearchModelOpen] = useState(false);
  const openSearchModal = () => setIsSearchModelOpen(true);
  const closeSearchModal = () => setIsSearchModelOpen(false);

  return (
    <>
      <Formik<ImpersonateUserFormModel>
        onSubmit={onSubmitImpersonateUserForm}
        validate={impersonateUserFormValidator.validate}
        initialValues={{ username: '' }}
      >
        {(formikProps) => (
          <Form>
            <Panel>
              <InputField
                fieldName="username"
                label={translate('pages.impersonateUser.usernameLabel')}
                icon={{
                  icon: <AddressBookIcon />,
                  colour: colourGrey08,
                  onClick: openSearchModal,
                }}
              />
            </Panel>
            {isSearchModalOpen && (
              <UserSearchModal
                isOpen={isSearchModalOpen}
                onRequestClose={closeSearchModal}
                onSelectUsername={(username) => {
                  formikProps.setFieldValue('username', username);
                  closeSearchModal();
                }}
              />
            )}
            <ButtonRow withMarginTop={true} rightAligned={true}>
              <SubmitButton
                submittingText={translate('pages.impersonateUser.impersonateButtonText')}
              >
                {translate('pages.impersonateUser.impersonateButtonText')}
              </SubmitButton>
            </ButtonRow>
          </Form>
        )}
      </Formik>
      {initiateMfaFlowError != null && (
        <Alert alertType="negative" withMarginTop={true}>
          {initiateMfaFlowError}
        </Alert>
      )}
    </>
  );
};

export type ImpersonateUserFormModel = {
  username: string;
};

class ImpersonateUserFormValidator extends Validator<ImpersonateUserFormModel> {
  constructor(translate: TranslateFunction) {
    super();

    this.ruleFor('username').must(notBeNull(translate)).must(notBeEmpty(translate));
  }
}

type ImpersonateUserMfaModalProps = {
  usernameToImpersonate: string;
  mfaAuthenticationResult: AuthenticationResult | null;
  setMfaAuthenticationResult: (result: AuthenticationResult | null) => void;
};

const ImpersonateUserMfaModal = ({
  usernameToImpersonate,
  mfaAuthenticationResult,
  setMfaAuthenticationResult,
}: ImpersonateUserMfaModalProps) => {
  const navigate = useNavigate();
  const authenticationContext = useContext(AuthenticationContext);

  const { logoutDueToLockOut } = useLogoutDueToLockOut();

  const impersonateUserRequest = usePostJson<ImpersonateUserCommand, {}>(
    '/api/authentication/ImpersonateUser'
  );

  const onSubmit = (
    formModel: MfaModalFormModel,
    formikHelpers: FormikHelpers<MfaModalFormModel>
  ) => {
    impersonateUserRequest.makeRequest({
      requestBody: {
        username: usernameToImpersonate,
        multiFactorAuthenticationToken: formModel.mfaCode,
      },
      onSuccess: () => {
        authenticationContext.refresh();
        navigate('/');
      },
      onFailure: (error, response) => {
        formikHelpers.setSubmitting(false);

        if (response.translationKey === 'errors.authentication.accountIsLockedOut') {
          //user has been locked out, so force logout
          logoutDueToLockOut(response.translationKey);
        }
      },
    });
  };

  const onCancel = () => {
    impersonateUserRequest.resetState();
    setMfaAuthenticationResult(null);
  };

  return (
    <MultiFactorAuthenticationModal
      data-testid={impersonateUserMfaFormTestId}
      mfaAuthenticationResult={mfaAuthenticationResult}
      setMfaAuthenticationResult={setMfaAuthenticationResult}
      onSubmit={onSubmit}
      onCancel={onCancel}
      mfaRequiredActionRequestState={impersonateUserRequest.state}
    />
  );
};

export const impersonateUserMfaFormTestId = 'impersonate-user-mfa-form';

export type ImpersonateUserCommand = {
  username: string;
  multiFactorAuthenticationToken: string;
};
