import React from 'react';
import { CounterpartSelect, CounterpartSelectOptionValue } from './CounterpartSelect';
import { CounterpartSelectSettings } from './CounterpartSelectSettings';
import { GetCounterpartDropdownOptionsResponse } from './GetCounterpartDropdownOptionsResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../../metadata/MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  CounterpartSelectOptionValue,
  CounterpartSelectSettings,
  GetCounterpartDropdownOptionsResponse
>;

export const CounterpartSelectField = (props: Props) => (
  <MetadataSingleSelectField<
    CounterpartSelectOptionValue,
    CounterpartSelectSettings,
    GetCounterpartDropdownOptionsResponse
  >
    {...props}
    renderSelect={({ ...metadataSingleSelectProps }) => (
      <CounterpartSelect {...metadataSingleSelectProps} settings={props.settings} />
    )}
  />
);
