import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import React, { useContext } from 'react';
import { HoldingsContext } from './HoldingsContext';
import { MobileFilterSet } from '../filters/MobileFilterSet';
import { IfUserHasRole } from '../authentication/UserRoles';
import { FieldLabel } from '../../infrastructure/forms/common/FieldLabel';
import { CompanySelect } from '../metadata/company/CompanySelect';
import {
  holdingsPageAgentSelectTestId,
  holdingsPageCompanySelectTestId,
  holdingsPageDealerSelectTestId,
  holdingsPageEmployeeSelectTestId,
  holdingsPageEndDatePickerTestId,
  holdingsPageEquityAttributionSelectTestId,
  holdingsPageInvestorSelectTestId,
} from './Holdings';
import { EquityAttributionSelect } from '../metadata/equityAttribution/EquityAttributionSelect';
import { AgentDealerSelect } from '../metadata/AgentDealerSelect';
import { EmployeeSelect } from '../metadata/employees/EmployeeSelect';
import { DatePicker } from '../../infrastructure/interface/forms/DatePicker';
import { ResetFiltersButton } from '../filters/ResetFiltersButton';
import { MobileSummary } from './MobileSummary';
import { MobileFund } from './MobileFund';
import { MobilePaginationControls } from '../../infrastructure/interface/components/MobilePaginationControls';
import { Header1, Header2 } from '../../infrastructure/interface/components/Headers';
import { LoadingOverlay } from '../../infrastructure/interface/components/LoadingOverlay';
import { CentredSpinner } from '../../infrastructure/interface/components/Spinner';
import styled from 'styled-components/macro';
import { spacing64 } from '../../styling/design/spacing';
import { InvestorSelectWithSearchModal } from '../metadata/investor/InvestorSelectWithSearchModal';
import { defaultAgentDealerSelectSettings } from '../metadata/AgentDealerSelectSettings';
import { defaultCompanySelectSettings } from '../metadata/company/CompanySelectSettings';
import { defaultEquityAttributionSelectSettings } from '../metadata/equityAttribution/EquityAttributionSelectSettings';
import { HoldingsDisclaimer } from './HoldingsDisclaimer';
import { ActionsContainer } from '../../infrastructure/interface/components/PageHeaderActions';

export const MobileHoldings = () => {
  const { translate } = useInternationalisation();

  return (
    <>
      <Header1>{translate('pages.holdings.header')}</Header1>
      <FiltersComponent />
      <MobileHoldingsActions />
      <ResultsComponent />
    </>
  );
};

const FiltersComponent = () => {
  const { translate } = useInternationalisation();
  const {
    companyId,
    setCompanyId,
    investorId,
    setInvestorId,
    equityAttributionId,
    setEquityAttributionId,
    agentId,
    setAgentId,
    dealerId,
    setDealerId,
    employeeId,
    setEmployeeId,
    endDate,
    setEndDate,
    setFilterLoadError,
    resetFilters,
    setCompanySelectLoaded,
    setEquityAttributionSelectLoaded,
    setInvestorSelectLoaded,
    setAgentSelectLoaded,
    setDealerSelectLoaded,
    setEmployeeSelectLoaded,
  } = useContext(HoldingsContext);

  const onCompanySelectLoaded = () => {
    setCompanySelectLoaded(true);
  };

  const onEmployeeSelectLoaded = () => {
    setEmployeeSelectLoaded(true);
  };

  const onInvestorSelectLoaded = () => {
    setInvestorSelectLoaded(true);
  };

  const onEquityAttributionSelectLoaded = () => {
    setEquityAttributionSelectLoaded(true);
  };

  const onAgentSelectLoaded = () => {
    setAgentSelectLoaded(true);
  };

  const onDealerSelectLoaded = () => {
    setDealerSelectLoaded(true);
  };

  return (
    <MobileFilterSet>
      <IfUserHasRole userRole="Manager">
        <CompanySelect
          fieldLabel={translate('pages.holdings.filters.company')}
          settings={{
            ...defaultCompanySelectSettings,
            includeAllFunds: true,
            includeBlank: true,
          }}
          value={companyId}
          onChange={setCompanyId}
          data-testid={holdingsPageCompanySelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onCompanySelectLoaded}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <IfUserHasRole userRole="Advisor">
        <EmployeeSelect
          fieldLabel={translate('pages.holdings.filters.advisor')}
          value={employeeId}
          onChange={setEmployeeId}
          data-testid={holdingsPageEmployeeSelectTestId}
          onError={setFilterLoadError}
          clearable={true}
          onLoaded={onEmployeeSelectLoaded}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <IfUserHasRole userRole={['Manager', 'Advisor', 'Consolidated Investor']}>
        <InvestorSelectWithSearchModal
          fieldLabel={translate('pages.holdings.filters.investor')}
          settings={{ forTransacting: false, employeeId }}
          value={investorId}
          onChange={setInvestorId}
          data-testid={holdingsPageInvestorSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onInvestorSelectLoaded}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <IfUserHasRole userRole="Manager">
        <EquityAttributionSelect
          fieldLabel={translate('pages.holdings.filters.equityAttribution')}
          settings={{ ...defaultEquityAttributionSelectSettings, companyId }}
          value={equityAttributionId}
          onChange={setEquityAttributionId}
          data-testid={holdingsPageEquityAttributionSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onEquityAttributionSelectLoaded}
          wrapInDiv={true}
        />
        <AgentDealerSelect
          fieldLabel={translate('pages.holdings.filters.agent')}
          settings={{ ...defaultAgentDealerSelectSettings, isAgent: true }}
          value={agentId}
          onChange={setAgentId}
          data-testid={holdingsPageAgentSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onAgentSelectLoaded}
          wrapInDiv={true}
        />
        <AgentDealerSelect
          fieldLabel={translate('pages.holdings.filters.dealer')}
          settings={{ ...defaultAgentDealerSelectSettings, isAgent: false }}
          value={dealerId}
          onChange={setDealerId}
          data-testid={holdingsPageDealerSelectTestId}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
          onLoaded={onDealerSelectLoaded}
          wrapInDiv={true}
        />
      </IfUserHasRole>

      <div>
        <FieldLabel>{translate('pages.holdings.filters.endDate')}</FieldLabel>
        <DatePicker
          value={endDate}
          onChange={setEndDate}
          data-testid={holdingsPageEndDatePickerTestId}
          clearable={true}
        />
      </div>

      <ResetFiltersButton resetFilters={resetFilters} />
    </MobileFilterSet>
  );
};

const MobileHoldingsActions = () => (
  <ActionsContainer>
    <HoldingsDisclaimer />
  </ActionsContainer>
);

const ResultsComponent = () => {
  const {
    pageNumber,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    latestResponse,
    inProgress,
    showEqualisationAdjustmentMessage,
  } = useContext(HoldingsContext);
  const { translate } = useInternationalisation();

  if (latestResponse == null && inProgress) {
    return (
      <SpinnerContainer>
        <CentredSpinner size="xlarge" />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <LoadingOverlay showOverlay={inProgress}>
        <MobileSummary />
        <Header2>{translate('pages.holdings.cards.investmentDetails.header')}</Header2>
        <SearchResultsTableComponent />
      </LoadingOverlay>
      <MobilePaginationControls
        resultsPerPage={resultsPerPage}
        totalResultsCount={latestResponse?.total || 0}
        onChangeResultsPerPage={setResultsPerPage}
        currentPageNumber={pageNumber}
        onChangeCurrentPageNumber={setPageNumber}
      />
      {showEqualisationAdjustmentMessage &&
        translate('pages.holdings.tables.funds.equalisationAdjustmentMessage')}
    </>
  );
};

const SpinnerContainer = styled.div`
  height: ${spacing64};
`;

const SearchResultsTableComponent = () => {
  const { latestResponse } = useContext(HoldingsContext);

  if (!latestResponse) {
    return null;
  }

  const allFunds = [...latestResponse.privateEquityFunds, ...latestResponse.traditionalFunds];

  return (
    <>
      {allFunds.map((fund, index) => (
        <MobileFund fund={fund} key={index} />
      ))}
    </>
  );
};
