import { useGetJson } from '../../../infrastructure/api/useGetJson';

export const useGetCurrencyDetailsRequest = () =>
  useGetJson<GetCurrencyDetailsQuery, GetCurrencyDetailsResponse>(
    '/api/metadata/GetCurrencyDetails'
  );

type GetCurrencyDetailsQuery = {
  currencyId: number;
};

export type GetCurrencyDetailsResponse = {
  currencyId: number;
  currencyCode: string;
  description: string;
  decimalPlaces: number;
  bankAccountOtherIdentifierName: string;
};
