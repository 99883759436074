import React, { createContext, useContext, useState } from 'react';
import { getIsoDatestampToday, IsoDatestamp } from '../../helpers/dateTimeHelpers';
import { useMaxDateForCompanyRequest } from './requests/UseMaxDateForCompanyRequest';
import { useSessionCompanyId } from '../../infrastructure/hooks/useSessionCompanyId';
import { Session } from 'inspector';
import { useSessionEmployeeId } from '../../infrastructure/hooks/useSessionEmployeeId';

export const DashboardFiltersContext = createContext<DashboardFiltersContextValue | undefined>(
  undefined
);

export type DashboardFiltersContextValue = {
  employeeId: number | null;
  setEmployeeId(employeeId: number | null): void;
  investorId: number | null;
  setInvestorId: (value: number | null) => void;
  companyId: number | null;
  setCompanyId: (value: number | null) => void;
  handleCompanyChanged: (value: number | null) => void;
  maxDate: IsoDatestamp | null;
  fromDate: IsoDatestamp | null;
  setFromDate: (value: IsoDatestamp | null) => void;
  dashboardId: number | null;
  setDashboardId: (value: number | null) => void;
  filterLoadError: string | null;
  setFilterLoadError: (value: string | null) => void;
};

export const useDashboardFilters = (): DashboardFiltersContextValue => {
  const contextValue = useContext(DashboardFiltersContext);

  if (!contextValue) {
    throw new Error('useDashboardFilters must be used with DashboardFiltersContextProvider');
  }

  return contextValue;
};

type Props = {
  children?: React.ReactNode;
};

export const DashboardFiltersContextProvider = (props: Props) => {
  const [employeeId, setEmployeeId] = useState<number | null>(null);
  const [investorId, setInvestorId] = useState<number | null>(null);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [fromDate, setFromDate] = useState<IsoDatestamp | null>(getIsoDatestampToday());
  const [maxDate, setMaxDate] = useState<IsoDatestamp | null>(getIsoDatestampToday());
  const [dashboardId, setDashboardId] = useState<number | null>(null);
  const [filterLoadError, setFilterLoadError] = useState<string | null>(null);
  const { getMaxCompanyDate } = useMaxDateForCompanyRequest(setMaxDate);
  
  const handleCompanyChanged = (value: number | null) => {
    setCompanyId(value);
    getMaxCompanyDate(value as number);
  };

  const { sessionEmployeeId } = useSessionEmployeeId();
  if( employeeId==null && sessionEmployeeId !=null) setEmployeeId(sessionEmployeeId);
  
  const { sessionCompanyId } = useSessionCompanyId();
  if (companyId == null && sessionCompanyId != null) handleCompanyChanged(sessionCompanyId);

  const value = {
    employeeId,
    setEmployeeId,
    investorId,
    setInvestorId,
    companyId,
    setCompanyId,
    fromDate,
    setFromDate,
    dashboardId,
    setDashboardId,
    filterLoadError,
    setFilterLoadError,
    handleCompanyChanged,
    maxDate,
  };

  return (
    <DashboardFiltersContext.Provider value={value}>
      {props.children}
    </DashboardFiltersContext.Provider>
  );
};
