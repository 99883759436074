import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { Alert } from '../interface/components/Alert';
import { ButtonRow } from '../interface/buttons/ButtonRow';
import { SecondaryButton } from '../interface/buttons/SecondaryButton';

type RequestFailedAlertProps = {
  retry?: () => void;
  error: string;
  withMarginTop?: boolean;
  withMarginBottom?: boolean;
};

export const RequestFailedAlert = ({
  retry,
  error,
  withMarginTop,
  withMarginBottom,
}: RequestFailedAlertProps) => {
  const { translate } = useInternationalisation();

  return (
    <Alert
      alertType="negative"
      header={translate('errors.apology')}
      withMarginTop={withMarginTop}
      withMarginBottom={withMarginBottom}
    >
      {error}
      {retry != null && (
        <ButtonRow withMarginTop={true}>
          <SecondaryButton size="small" onClick={retry}>
            {translate('errors.tryAgain')}
          </SecondaryButton>
        </ButtonRow>
      )}
    </Alert>
  );
};
