import React, { useMemo } from 'react';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { BarChart } from '../../../../infrastructure/charts/BarChart';
import { getIsoDatestampFromUnknownFormatDateTimeString } from '../../../../helpers/dateTimeHelpers';
import { ChartDataPoint } from '../../../../infrastructure/charts/Chart';
import { isBlank } from '../../../../helpers/stringHelpers';

export const portfolioExposureGeographicalSummaryChartTestId =
  'portfolio-exposure-geographical-summary-chart';

type Props = {
  geographicalChartResponse: PortfolioExposureGeographicalSummaryChartResponse;
};

export const PortfolioExposureGeographicalSummaryChart = ({ geographicalChartResponse }: Props) => {
  const { formatDate, translate, formatNumber } = useInternationalisation();

  const title = useMemo(() => {
    const prefix = translate(
      'pages.reports.charts.portfolioExposureSummary.geographical.titlePrefix'
    );
    const dateInLocalFormat = isBlank(geographicalChartResponse.valueDate)
      ? ''
      : formatDate(geographicalChartResponse.valueDate);

    return `${prefix} ${dateInLocalFormat}`;
  }, [translate, formatDate, geographicalChartResponse]);

  const tooltipDataPointLabel = useMemo(
    () =>
      translate('pages.reports.charts.portfolioExposureSummary.geographical.tooltipDataPointLabel'),
    [translate]
  );

  const exportFilename = useMemo(() => {
    const prefix = translate(
      'pages.reports.charts.portfolioExposureSummary.geographical.exportFilenamePrefix'
    );
    const dateInIsoDateFormat = isBlank(geographicalChartResponse.valueDate)
      ? ''
      : getIsoDatestampFromUnknownFormatDateTimeString(geographicalChartResponse.valueDate);

    return `${prefix}_${dateInIsoDateFormat}`;
  }, [translate, geographicalChartResponse]);

  const dataPoints: Array<ChartDataPoint> = useMemo(
    () =>
      geographicalChartResponse.dataPoints.map((dataPoint) => ({
        label: dataPoint.groupDescription,
        value: dataPoint.navPercent,
      })),
    [geographicalChartResponse]
  );

  return (
    <BarChart
      data-testid={portfolioExposureGeographicalSummaryChartTestId}
      title={title}
      subtitle={geographicalChartResponse.companyName}
      exportFilename={exportFilename}
      yAxisOptions={{
        labels: {
          formatter() {
            return `${formatNumber(this.value, { decimalPlaces: 2 })}%`;
          },
        },
      }}
      tooltipOptions={{
        pointFormatter() {
          const formattedNavPercent =
            this.y === undefined ? '' : formatNumber(this.y, { decimalPlaces: 4 });
          return `<span style="color:${this.color}">●</span> ${tooltipDataPointLabel}: <b>${formattedNavPercent}%</b>`;
        },
      }}
      dataPoints={dataPoints}
    />
  );
};

export type PortfolioExposureGeographicalSummaryChartResponse = {
  companyName: string;
  valueDate: string;
  dataPoints: Array<PortfolioExposureGeographicalSummaryChartDataPoint>;
};

type PortfolioExposureGeographicalSummaryChartDataPoint = {
  groupDescription: string;
  navPercent: number;
};
