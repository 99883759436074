import React, { useMemo } from 'react';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { ReportResponse } from '../../Reports';
import { CustomReportParameter } from '../../ReportViewer';
import { Alert } from '../../../../infrastructure/interface/components/Alert';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { CentredSpinner } from '../../../../infrastructure/interface/components/Spinner';
import { getIsoDatestampFromUnknownFormatDateTimeString } from '../../../../helpers/dateTimeHelpers';
import { PieChart } from '../../../../infrastructure/charts/PieChart';
import { ChartDataPoint } from '../../../../infrastructure/charts/Chart';
import { isBlank } from '../../../../helpers/stringHelpers';

export const shareholdingForAShareholderChartTestId = 'shareholding-for-a-shareholder-chart';

type Props = {
  report: ReportResponse;
  customReportParameters: Array<CustomReportParameter>;
};

export const ShareholdingForAShareholderChart = ({ report, customReportParameters }: Props) => {
  const { formatDate, translate, formatNumber } = useInternationalisation();

  const {
    makeRequest,
    state: { response, inProgress, error },
  } = usePostJson<
    DataForShareholdingForAShareholderChartRequest,
    DataForShareholdingForAShareholderChartResponse
  >('/api/reports/GetDataForShareholdingForAShareholderChart');

  useOnMount(() => makeRequest({ requestBody: { reportId: report.id, customReportParameters } }));

  const title = useMemo(() => {
    if (response != null) {
      const prefix = translate('pages.reports.charts.shareholdingForAShareholder.titlePrefix');
      const localFormatValueDate = isBlank(response.valueDate)
        ? ''
        : formatDate(response.valueDate);

      return `${prefix} ${localFormatValueDate}`;
    }
  }, [translate, formatDate, response]);

  const exportFilename = useMemo(() => {
    if (response != null) {
      const prefix = translate(
        'pages.reports.charts.shareholdingForAShareholder.exportFilenamePrefix'
      );
      const valueDateInIsoDateFormat = isBlank(response.valueDate)
        ? ''
        : getIsoDatestampFromUnknownFormatDateTimeString(response.valueDate);

      return `${prefix}_${valueDateInIsoDateFormat}`;
    }
  }, [translate, response]);

  const dataPoints: Array<ChartDataPoint> = useMemo(
    () =>
      response != null
        ? response.dataPoints.map((dataPoint) => ({
            label: dataPoint.shareholderName,
            value: dataPoint.total,
          }))
        : [],
    [response]
  );

  if (error) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')}>
        {error}
      </Alert>
    );
  }

  if (response == null || inProgress) {
    return <CentredSpinner size="xlarge" />;
  }

  return (
    <PieChart
      data-testid={shareholdingForAShareholderChartTestId}
      title={title ?? ''}
      subtitle={response.companyName}
      exportFilename={exportFilename ?? ''}
      tooltipOptions={{
        pointFormatter() {
          const formattedTotal =
            this.y === undefined ? '' : formatNumber(this.y, { decimalPlaces: 2 });
          return `<span style="color:${this.color}">●</span> <b>${response.currencyCode} ${formattedTotal}</b>`;
        },
      }}
      dataPoints={dataPoints}
    />
  );
};

export type DataForShareholdingForAShareholderChartRequest = {
  reportId: number;
  customReportParameters: Array<CustomReportParameter>;
};

export type DataForShareholdingForAShareholderChartResponse = {
  companyName: string;
  valueDate: string;
  currencyCode: string;
  dataPoints: Array<ShareholdingForAShareholderChartDataPoint>;
};

type ShareholdingForAShareholderChartDataPoint = {
  shareholderName: string;
  total: number;
};
