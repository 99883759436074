import React from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Validator } from 'fluentvalidation-ts';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { useMemo } from 'react';
import { haveMaxLength } from '../../validation/stringValidation';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { ActionAlert } from '../../../infrastructure/interface/components/ActionAlert';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { WaitingButton } from '../../../infrastructure/interface/buttons/WaitingButton';
import styled, { css } from 'styled-components/macro';
import { spacing16 } from '../../../styling/design/spacing';
import { EditInvestorDetailsFormFields } from './EditInvestorDetailsFormFields';
import { onMobile } from '../../../styling/layout/screenBreakpoints';
import { BlockedField } from '../GetInvestorDetailsResponse';

type Props = {
  initialValues: EditInvestorDetailsFormModel;
  onSubmit: (
    formModel: EditInvestorDetailsFormModel,
    formikHelpers: FormikHelpers<EditInvestorDetailsFormModel>
  ) => void;
  investorNumber: string | null;
  status: EditInvestorDetailsFormStatus;
  setStatus: (value: EditInvestorDetailsFormStatus) => void;
  error: string | null;
  blockedFields: Array<BlockedField>;
  mobilePhoneTooltip: string | undefined;
};

export const EditInvestorDetailsForm = (props: Props) => {
  const { translate } = useInternationalisation();

  const validator = useMemo(() => new EditInvestorDetailsFormValidator(translate), [translate]);

  const onConfirmIgnoreSync = (formikProps: FormikProps<EditInvestorDetailsFormModel>) => {
    props.setStatus('IgnoreSyncConfirmed');
    formikProps.submitForm();
  };

  return (
    <Formik<EditInvestorDetailsFormModel>
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validate={validator.validate}
    >
      {(formikProps) => (
        <>
          <Form>
            <StyledPanel>
              {props.investorNumber && props.investorNumber?.length > 0 && (
                <FieldAndValue
                  type="text"
                  fieldLabel={translate(
                    `pages.investorDetailsPage.counterpartDetails.fieldNames.investorNumber`
                  )}
                  value={props.investorNumber}
                />
              )}
              <EditInvestorDetailsFormFields blockedFields={props.blockedFields} mobilePhoneTooltip={props.mobilePhoneTooltip} />
              <SuccessfulEditRequestAlert formStatus={props.status} />
              {props.error != null && <Alert alertType="negative">{props.error}</Alert>}
            </StyledPanel>
            <ButtonRow rightAligned={true} withMarginTop={true}>
              <SubmitButton formStatus={props.status} />
            </ButtonRow>
          </Form>
          <ActionAlert
            alertType="warning"
            isOpen={props.status === 'IgnoreSyncConfirmationNeeded'}
            title={translate('pages.editInvestorDetails.ignoreSyncConfirmationAlert.title')}
            message={translate('pages.editInvestorDetails.ignoreSyncConfirmationAlert.message')}
            cancelButtonText={translate(
              'pages.editInvestorDetails.ignoreSyncConfirmationAlert.cancelButtonText'
            )}
            onRequestClose={() => props.setStatus('NotSubmitted')}
            confirmButtonText={translate(
              'pages.editInvestorDetails.ignoreSyncConfirmationAlert.confirmButtonText'
            )}
            onInitiateAction={() => onConfirmIgnoreSync(formikProps)}
            actionInProgress={props.status === 'IgnoreSyncConfirmed'}
            actionError={null}
          />
        </>
      )}
    </Formik>
  );
};

type SuccessfulEditRequestAlertProps = {
  formStatus: EditInvestorDetailsFormStatus;
};

const SuccessfulEditRequestAlert = ({ formStatus }: SuccessfulEditRequestAlertProps) => {
  const { translate } = useInternationalisation();

  let AlertComponent: React.ReactNode;

  switch (formStatus) {
    case 'Authorized':
      AlertComponent = (
        <Alert alertType="positive">
          {translate('pages.editInvestorDetails.modifiedSuccessfully')}
        </Alert>
      );
      break;
    case 'PendingApproval':
      AlertComponent = (
        <Alert alertType="positive">{translate('pages.editInvestorDetails.pendingApproval')}</Alert>
      );
      break;
    default:
      AlertComponent = null;
  }

  return <>{AlertComponent}</>;
};

type SubmitButtonProps = {
  formStatus: EditInvestorDetailsFormStatus;
};

const SubmitButton = ({ formStatus }: SubmitButtonProps) => {
  const { translate } = useInternationalisation();

  return (
    <WaitingButton
      waitingText={translate('pages.editInvestorDetails.submittingText')}
      isWaiting={formStatus === 'Submitting' || formStatus === 'IgnoreSyncConfirmationNeeded'}
      type="submit"
    >
      {translate('pages.editInvestorDetails.submitButtonText')}
    </WaitingButton>
  );
};

const StyledPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  gap: ${spacing16};

  ${onMobile(css`
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  `)};
`;

class EditInvestorDetailsFormValidator extends Validator<EditInvestorDetailsFormModel> {
  constructor(translate: TranslateFunction) {
    super();

    for (const fieldName of editInvestorDetailsFormFieldNames) {
      const maxFieldLength = editInvestorDetailsFormMaxFieldLengths[fieldName];

      if (maxFieldLength != null) {
        this.ruleFor(fieldName).must(haveMaxLength(maxFieldLength, translate));
      }
    }
  }
}

export const editInvestorDetailsFormFieldNames = [
  'contactPerson',
  'phoneNumber1',
  'phoneNumber2',
  'mobileNumber',
  'emailAddress',
  'faxNumber',
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressLine4',
  'mailingAddressLine1',
  'mailingAddressLine2',
  'mailingAddressLine3',
  'mailingAddressLine4',
  'companyNumber',
  'businessNumber',
  'taxNumber',
  'usTaxNumber',
  'communicationPreferenceCode',
] as const;

type EditInvestorDetailsFormFieldName = typeof editInvestorDetailsFormFieldNames[number];

export type EditInvestorDetailsFormModel = Record<EditInvestorDetailsFormFieldName, string>;

const editInvestorDetailsFormMaxFieldLengths: Record<
  EditInvestorDetailsFormFieldName,
  number | null
> = {
  contactPerson: 100,
  phoneNumber1: 25,
  phoneNumber2: 25,
  mobileNumber: 25,
  emailAddress: 255,
  faxNumber: 25,
  addressLine1: 153,
  addressLine2: 153,
  addressLine3: 153,
  addressLine4: 153,
  mailingAddressLine1: 153,
  mailingAddressLine2: 153,
  mailingAddressLine3: 153,
  mailingAddressLine4: 153,
  companyNumber: 20,
  businessNumber: 20,
  taxNumber: 25,
  usTaxNumber: 25,
  communicationPreferenceCode: null,
};

export type EditInvestorDetailsFormStatus =
  | 'NotSubmitted'
  | 'Submitting'
  | 'IgnoreSyncConfirmationNeeded'
  | 'IgnoreSyncConfirmed'
  | 'Authorized'
  | 'PendingApproval';
