export type GetDashboardsForCurrentUserResponse = {
  dashboards: Array<DashboardResponse>;
};

export type DashboardResponse = {
  dashboardId: number;
  description: string;
  components: Array<DashboardComponentResponse>;
};

// Each component can only have a height of 1 or 2 and a width of 1 or 2
// The x coordinate can only be 0 or 1 - i.e. there are just 2 columns of component
export type DashboardComponentResponse = {
  dashboardComponentId: number;
  name: string;
  columnIndex: DashboardComponentColumnIndex;
  rowIndex: number;
  height: DashboardComponentHeight;
  width: DashboardComponentWidth;
};

export const validDashboardComponentColumnIndexes = [0, 1] as const;

export type DashboardComponentColumnIndex = typeof validDashboardComponentColumnIndexes[number];

export type DashboardComponentHeight = 1 | 2;

// Leaving this property in incase components of width 2 are supported in the future. The difficult
// step here will be the drag and drop grid in DashboardLayoutEditor, which will need to be converted
// to a grid layout, and thus can't utilise react-beautiful-dnd. In-fact, seeing as most drag-and-drop
// libraries store their data as ordered 1-dimensional lists, I suspect implementing this will involve
// re-factoring how we store dashboard layouts, and just storing a list of components that get placed
// in order by css, rather than explicitly storing x and y coordinates. This would also allow us to utilise
// css-grids 'dense' packing algorithm.
export type DashboardComponentWidth = 1;
