import React from 'react';
import { DocumentTypeSelect, DocumentTypeSelectOptionValue } from './DocumentTypeSelect';
import { DocumentTypeSelectSettings } from './DocumentTypeSelectSettings';
import { GetDocumentTypesResponse } from './GetDocumentTypesResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';

type DocumentSelectProps = MetadataSingleSelectFieldComponentProps<
  DocumentTypeSelectOptionValue,
  DocumentTypeSelectSettings,
  GetDocumentTypesResponse
>;

export const DocumentTypeSelectField = (props: DocumentSelectProps) => (
  <MetadataSingleSelectField<
    DocumentTypeSelectOptionValue,
    DocumentTypeSelectSettings,
    GetDocumentTypesResponse
  >
    {...props}
    renderSelect={({ ...metadataSingleSelectProps }) => (
      <DocumentTypeSelect {...metadataSingleSelectProps} />
    )}
  />
);
