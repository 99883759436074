import styled, { css } from 'styled-components/macro';
import { colourBlack, textColours } from '../../../styling/design/colours';
import {
  fontFamily,
  fontSizeCss,
  fontSizes,
  fontWeightBold,
  lineHeightMedium,
} from '../../../styling/design/fonts';
import { ButtonSize, NativeButtonProps } from './BaseButton';
import { spacing8 } from '../../../styling/design/spacing';
import { onDesktop, onMobile } from '../../../styling/layout/screenBreakpoints';
import React from 'react';

export type MinimalButtonProps = NativeButtonProps & {
  size?: ButtonSize;
  buttonStyle?: MinimalButtonStyle;
  textAlign?: TextAlignOption;
  icon?: React.ReactNode;
  'data-testid'?: string;
};

type MinimalButtonStyle = 'primary' | 'negative' | 'black';

type TextAlignOption = 'left' | 'center' | 'right';

export const MinimalButton = ({
  size = 'medium',
  buttonStyle = 'primary',
  textAlign = 'center',
  icon,
  children,
  type = 'button',
  ...rest
}: MinimalButtonProps) => (
  <StyledButton {...rest} size={size} buttonStyle={buttonStyle} textAlign={textAlign} type={type}>
    {icon != null && icon}
    {children}
  </StyledButton>
);

const StyledButton = styled.button<{
  size: ButtonSize;
  buttonStyle: MinimalButtonStyle;
  textAlign: TextAlignOption;
}>`
  display: inline-flex;
  position: relative;
  align-items: center;
  outline: none;
  border: none;
  background: none;
  font-family: ${fontFamily};
  font-weight: ${fontWeightBold};
  color: ${(props) => minimalButtonColourSettingsByStyle[props.buttonStyle].textColour};
  cursor: pointer;
  transition: opacity 0.25s ease;
  ${(props) => fontSizeCss(props.size)};
  text-align: ${(props) => props.textAlign};
  line-height: ${lineHeightMedium};
  padding: 0;

  &:hover:not(:disabled) {
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    color: ${(props) =>
      minimalButtonColourSettingsByStyle[props.buttonStyle].disabledColour ??
      minimalButtonColourSettingsByStyle[props.buttonStyle].textColour};
  }

  svg {
    position: relative;
    top: 1px;
    margin-right: ${spacing8};
    ${(props) => iconSizeCss(props.size ?? 'medium')}
  }
`;

type MinimalButtonColourSettings = {
  textColour: string;
  disabledColour?: string;
};

export const minimalButtonColourSettingsByStyle: Record<
  MinimalButtonStyle,
  MinimalButtonColourSettings
> = {
  primary: {
    textColour: textColours.primary,
    disabledColour: textColours.disabled,
  },
  negative: {
    textColour: textColours.negative,
  },
  black: {
    textColour: colourBlack,
  },
};

const iconSizeCss = (buttonSize: ButtonSize) => css`
  ${onDesktop(css`
    width: ${fontSizes[buttonSize].desktop};
    height: ${fontSizes[buttonSize].desktop};
  `)}

  ${onMobile(css`
    width: ${fontSizes[buttonSize].mobile};
    height: ${fontSizes[buttonSize].mobile};
  `)}
`;
