import React from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  GetInvestorDropdownOptionsResponse,
  mapInvestorDropdownOptionsResponseToSelectOptions,
} from './GetInvestorDropdownOptionsResponse';
import { InvestorSelectSettings } from './InvestorSelectSettings';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';

export type InvestorSelectOptionValue = number | null;

export type InvestorSelectProps = MetadataSingleSelectComponentProps<
  InvestorSelectOptionValue,
  InvestorSelectSettings,
  GetInvestorDropdownOptionsResponse
>;

export const InvestorSelect = (props: InvestorSelectProps) => {
  const { settings, ...metadataSingleSelectProps } = props;

  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.investorSelect.placeholder');
  const loadingPlaceholder = translate('metadata.investorSelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      InvestorSelectOptionValue,
      InvestorSelectSettings,
      GetInvestorDropdownOptionsQuery,
      GetInvestorDropdownOptionsResponse
    >
      endpointUrl="/api/metadata/GetInvestorDropdownOptions"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapInvestorDropdownOptionsResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={300}
      {...metadataSingleSelectProps}
    />
  );
};

type GetInvestorDropdownOptionsQuery = {
  forTransacting?: boolean;
};
