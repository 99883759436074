import Countdown, {CountdownRenderProps} from 'react-countdown';
import {MinimalButton} from "./MinimalButton";
import React, {useState} from "react";

type Props = {
    countdownInSeconds: number;
    buttonText: string;
    clickRequest: () => void;
};

export const MinimalCountdownButton = ({countdownInSeconds, buttonText, clickRequest}: Props) => {

    const [countdownDate, setTime] = useState(Date.now() + countdownInSeconds * 1000);

    const resend = () => {
        clickRequest();
        setTime(Date.now() + countdownInSeconds * 1000);
    };

    const renderer = ({seconds, completed}: CountdownRenderProps) => {
        if (completed) {
            return (
                <MinimalButton
                    onClick={resend}>
                    {buttonText}
                </MinimalButton>
            );
        } else {
            return (
                <MinimalButton
                    disabled={true}>
                    {buttonText} ( {seconds} )
                </MinimalButton>
            );
        }
    };

    return (
        <Countdown
            date={countdownDate}
            key={countdownDate}
            renderer={renderer}
            autoStart={true}
        />
    );
}