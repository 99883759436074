import React from 'react';
import styled from 'styled-components/macro';
import { FieldValidity } from '../common/FieldValidity';
import { borderColours } from '../../../styling/design/colours';
import { TextArea } from '../../interface/forms/TextArea';
import { FormField } from '../common/FormField';

export const textAreaFieldTestId = (fieldName: string) => `text-area-field-${fieldName}`;

type Props = {
  fieldName: string;
  label?: string;
  helpText?: string;
  disabled?: boolean;
  readOnly?: boolean;
  'data-testid'?: string;
};

export const TextAreaField = (props: Props) => {
  const { fieldName, label, helpText, disabled, readOnly } = props;

  return (
    <FormField<string> fieldName={fieldName} label={label} helpText={helpText}>
      {({ field, fieldValidity, formikContext }) => (
        <FieldValidityTextArea
          {...field}
          data-testid={props['data-testid'] ?? textAreaFieldTestId(fieldName)}
          fieldValidity={fieldValidity}
          disabled={disabled || formikContext.isSubmitting}
          readOnly={readOnly}
        />
      )}
    </FormField>
  );
};

export const FieldValidityTextArea = styled(TextArea)<{ fieldValidity: FieldValidity }>`
  border-color: ${(props) =>
    props.fieldValidity === 'Valid'
      ? borderColours.positive
      : props.fieldValidity === 'Invalid'
      ? borderColours.negative
      : borderColours.default};
`;
