import React from 'react';
import {
  MinimalButton,
  minimalButtonColourSettingsByStyle,
  MinimalButtonProps,
} from './MinimalButton';
import { ButtonSpinner } from './ButtonSpinner';
import { waitingTextTestId } from './WaitingButton';
import styled from 'styled-components/macro';
import { SpinnerCircle } from '../components/Spinner';

type Props = MinimalButtonProps & {
  waitingText: string | React.ReactNode;
  isWaiting: boolean;
};

export const MinimalWaitingButton = ({
  waitingText,
  isWaiting,
  icon,
  size = 'medium',
  disabled,
  children,
  ...rest
}: Props) => (
  <StyledMinimalButton
    {...rest}
    size={size}
    disabled={disabled || isWaiting}
    icon={isWaiting ? undefined : icon}
  >
    {isWaiting ? (
      <>
        <ButtonSpinner size={size} />
        <WaitingText data-testid={waitingTextTestId}>{waitingText}</WaitingText>
      </>
    ) : (
      children
    )}
  </StyledMinimalButton>
);

const WaitingText = styled.div``;

const StyledMinimalButton = styled(MinimalButton)<MinimalButtonProps>`
  ${SpinnerCircle}:before {
    background-color: ${(props) =>
      minimalButtonColourSettingsByStyle[props.buttonStyle ?? 'primary'].textColour};
  }
`;
